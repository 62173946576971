import { ILiveSheetState } from './ILiveSheetState';
import { IOpenProductActionPayload } from '../project/projectActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISheet } from '../project/ProductState';

/**
 * Reacts to the {@link openProductInternal} action by loading the currently active sheet into the
 * live sheet.
 */
export function applyOpenProductToLayersReducer(
  state: ILiveSheetState,
  action: PayloadAction<IOpenProductActionPayload>,
): ILiveSheetState {
  const { product } = action.payload;

  state.transform = null;
  state.layers = [];

  let sheetToImportFrom: ISheet | undefined;
  if (product.activeSheetId) {
    sheetToImportFrom = product.sheets.find(($) => $.id === product.activeSheetId);
  }
  if (!sheetToImportFrom) {
    sheetToImportFrom = product.sheets[0];
  }
  if (sheetToImportFrom) {
    state.layers = sheetToImportFrom.layers;
  }
  return state;
}
