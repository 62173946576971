import React from 'react';
import styles from './RadioGroup.module.css';
import RadioButton from '../radioButton/RadioButton';
import classNames from 'classnames';
import { IOption } from '../../../@types/IOption';

export type RadioValueType = string | number | boolean;

export interface IRadioGroupProps<VAL extends RadioValueType> {
  value: VAL;
  name: string;
  options: IOption<VAL>[];
  labelHidden?: boolean;
  vertical?: boolean;
  onChange?: (value: VAL) => unknown;
}

export const RadioGroup = <VAL extends RadioValueType>({
  onChange,
  options,
  name,
  labelHidden,
  vertical,
  value,
}: IRadioGroupProps<VAL>) => {
  return (
    <div
      className={classNames({
        [styles.vertical]: vertical,
        [styles.wrapper]: true,
      })}>
      {options.map((option) => (
        <RadioButton
          key={String(option.value)}
          name={name}
          checked={typeof value === 'undefined' ? undefined : option.value === value}
          labelHidden={labelHidden}
          label={option.label as unknown as string /* TODO: Accept ReactNode as label */}
          onChange={() => onChange && onChange(option.value)}
        />
      ))}
    </div>
  );
};
