import { AppThunk } from '../redux/store';
import { treditionApi } from '../api/treditionApi';
import { IsoLanguageCode } from './language';
import i18n from './i18n';
import { getLocaleSlice } from './localeSelectors';
import { setAppError } from '../app/appSlice';
import { setLanguageResourceLoaded } from './localeSlice';
import { setYupTranslations } from './setYupMessages';

export const loadLanguageResources =
  (languageCode: IsoLanguageCode): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const loadedOnce = getLocaleSlice(getState()).languageResourceLoaded;
    let languageResource: Record<string, string>;

    try {
      languageResource = await dispatch(
        treditionApi.endpoints.getLanguageResources.initiate(languageCode),
      ).unwrap();
    } catch (error) {
      if (loadedOnce) {
        console.error('Could not load language resources: ', error);
      } else {
        dispatch(setAppError({ message: 'Tredition Server ist zurzeit nicht erreichbar.' }));
      }
      return;
    }
    try {
      i18n.addResourceBundle(languageCode, 'backend', languageResource, true, true);
      i18n.setDefaultNamespace('backend');
      await i18n.changeLanguage(languageCode);
      setYupTranslations();
      dispatch(setLanguageResourceLoaded(true));
    } catch (error) {
      console.error('Could not apply language resources: ', error);
    }
  };
