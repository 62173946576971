import React, { useEffect } from 'react';
import styles from './ToggleButton.module.css';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import classNames from 'classnames';
import { noop } from '../../lib/noop';

export interface IToggleButton {
  label: string;
  labelHidden?: boolean;
  yesText: string;
  noText: string;
  value?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   *
   */
  onChange?: (checked: boolean) => void;

  checked?: boolean | null;
  /**
   * Invert the toggle button order, so "no" is left and "yes" is right
   */
  invert?: boolean;

  /**
   * Disable the toggle button
   */
  disabled?: boolean;
}

export const ToggleButton = ({
  label,
  labelHidden = false,
  yesText,
  noText,
  onChange = noop,
  onBlur,
  checked,
  value,
  disabled,
  invert = false,
  ...rest
}: IToggleButton) => {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value === undefined || value === null) {
      if (ref.current) {
        ref.current.indeterminate = true;
      }
    }
  }, [ref, value]);

  return (
    <div
      style={labelHidden ? {} : { height: '60px' }}
      className={classNames(styles.wrapper, {
        [styles.disabled]: disabled,
      })}>
      {labelHidden ? (
        <VisuallyHidden>{t(label)}</VisuallyHidden>
      ) : (
        <span className={styles.labelStyle}>{t(label)}</span>
      )}
      <label>
        <input
          ref={ref}
          checked={value || false}
          readOnly
          {...rest}
          type="checkbox"
          className={styles.toggleButton}
        />
        {invert ? (
          <>
            <span
              onClick={disabled ? noop : () => onChange(false)}
              className={classNames(styles.left, styles.option, {
                [styles.active]: value === false, // Because value can be null to show indeterminate state
              })}>
              {t(noText)}
            </span>
            <span
              onClick={disabled ? noop : () => onChange(true)}
              className={classNames(styles.right, styles.option, { [styles.active]: value })}>
              {t(yesText)}
            </span>
          </>
        ) : (
          <>
            <span
              onClick={disabled ? noop : () => onChange(true)}
              className={classNames(styles.left, styles.option, { [styles.active]: value })}>
              {t(yesText)}
            </span>
            <span
              onClick={disabled ? noop : () => onChange(false)}
              className={classNames(styles.right, styles.option, {
                [styles.active]: value === false, // Because value can be null to show indeterminate state
              })}>
              {t(noText)}
            </span>
          </>
        )}
      </label>
    </div>
  );
};
