import { FC, useEffect, useState } from 'react';
import { ILoaderProps, Loader } from './Loader';

export interface ILazyLoaderProps extends ILoaderProps {
  delay?: number;
}

export const LazyLoader: FC<ILazyLoaderProps> = ({ delay = 250, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <Loader {...props} /> : null;
};
