import { Draft } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';

/**
 * Assigns the depth based on the array element sequence, meaning elements coming first
 * in the array will be below others.
 */
export function recalculateDepthReducer(state: Draft<ILiveSheetState>) {
  state.layers.forEach((layer, index) => {
    const oldDepth = layer.depth;
    layer.depth = index;
    if (oldDepth !== layer.depth) {
      layer.updatedAt = Date.now();
    }
  });
}
