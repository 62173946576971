import { FC, useEffect, useState } from 'react';
import styles from './CoverImage.module.css';
import { useTranslation } from 'react-i18next';

export interface ICoverImageProps {
  /**
   * Absolute url to the cover image
   */
  url: string | null;
}

export const CoverImage: FC<ICoverImageProps> = ({ url }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
  }, [url]);

  return (
    <div className={styles.wrapper}>
      {url && !error ? (
        <img className={styles.img} src={url} alt="" onError={() => setError(true)} />
      ) : (
        <span className={styles.placeholder}>{t('Cover')}</span>
      )}
    </div>
  );
};
