import { IHistoryRecord, IHistoryState } from './IHistoryState';
import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { setHistoryIndex } from './historySlice';
import { setHistoryIndexReducer } from './setHistoryIndexReducer';

export interface IRestoreFromHistoryActionPayload {
  /**
   * The product id which contains the given record
   */
  productId: string;

  /**
   * The index of the given history record
   */
  index: number;

  /**
   * The actual history record to restore from
   */
  record: IHistoryRecord;
}

/**
 * Writes the given index to the history state which **must** have been validated beforehand.
 *
 * @internal Do not dispatch from React. Use {@link restoreProductFromHistoryRecord} instead.
 */
export function restoreFromHistoryReducer(
  state: Draft<IHistoryState>,
  action: PayloadAction<IRestoreFromHistoryActionPayload>,
) {
  const { productId, index } = action.payload;
  setHistoryIndexReducer(state, setHistoryIndex({ productId, index }));
  ++state.revision;
}
