export enum ManipulationMenuEnumeration {
  NONE,
  TOC,
  HEADLINES,
  BODY_TEXT,
  HEADER,
  FOOTER,
  PAGE_MARGINS,
  TEMPLATES,
  HISTORY,
}
