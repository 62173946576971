import { DragEvent as ReactDragEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useAppDispatch } from '../../redux/hooks';
import { addImageLayer, addPlainLayer, addTextLayer } from '../layer/liveSheetSlice';
import { useDrag } from '../drag/dragSelectors';
import { DragContextType, dragEnd } from '../drag/dragSlice';
import { loadImageIntoProject } from '../project/loadImageIntoProject';
import { getSheetOffset } from '../sheet/getSheetOffset';
import { useZoom } from '../view/viewSelectors';
import { toast } from 'react-hot-toast';
import { noop } from '../../lib/noop';
import { useTranslation } from 'react-i18next';
import { getDynamicTextContent } from '../layer/getDynamicTextContent';
import { useProject } from '../project/projectSelectors';

export const WorkSpaceDropzone: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selfRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const drag = useDrag();
  const zoomFactor = useZoom();
  const project = useProject();
  const classesRoot = classNames('dropzone', { active: isActive });

  useEffect(() => {
    function onDragStart(/* e: DragEvent */) {
      setIsActive(true);
    }

    function onDragEnd(/* e: DragEvent */) {
      setIsActive(false);
      dispatch(dragEnd());
    }

    document.addEventListener('dragstart', onDragStart);
    document.addEventListener('dragend', onDragEnd);
    return () => {
      document.removeEventListener('dragstart', onDragStart);
      document.removeEventListener('dragend', onDragEnd);
    };
  }, [dispatch]);

  const onDrop = useCallback(
    (e: ReactDragEvent<HTMLElement>) => {
      e.preventDefault();
      if (!drag || !project) {
        return;
      }
      setIsActive(false);
      const sheetOffset = getSheetOffset();
      const x = (e.clientX - sheetOffset.x) / zoomFactor;
      const y = (e.clientY - sheetOffset.y) / zoomFactor;
      const wrapTextWithNotoSerifFont = (ts: string) =>
        `<p><span style="font-family:'Noto Serif'">${ts}</span></p>`;

      switch (drag.type) {
        case DragContextType.DesignerText:
          dispatch(
            addTextLayer({
              x,
              y,
              // it would be better to add an effect to the TextLayer to determine its initial
              // dimensions based on its initial content instead of hard coding this
              // but this must be done using redux layer data so this does not resize existing
              // layers loaded from a persisted product
              width: 150,
              height: 24,
              content: wrapTextWithNotoSerifFont(
                getDynamicTextContent(drag.templateType, project) || t('NewTextObject'),
              ),
            }),
          );
          return;
        case DragContextType.DesignerImage: {
          const importTask = dispatch(loadImageIntoProject(drag.fileId, drag.source));
          toast
            .promise(importTask, {
              error: t('ImportImageError'),
              loading: t('ImportImageLoading'),
              success: t('ImportImageSuccess'),
            })
            .then((fileId) => {
              dispatch(addImageLayer({ x, y, fileId }));
            })
            .catch(noop);
          return;
        }
        case DragContextType.DesignerPlain:
          dispatch(addPlainLayer({ x, y }));
          return;
        default:
      }
    },
    [dispatch, drag, t, project, zoomFactor],
  );

  return (
    <div
      ref={selfRef}
      className={classesRoot}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
    />
  );
};
