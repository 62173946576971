import { AppThunk } from '../../redux/store';
import { activateSheet } from '../project/activateSheet';
import { getProjectSlice } from '../project/projectSelectors';
import { getActiveSheetId } from '../project/productSelectors';
import { getDesignerInput } from '../input/inputSelectors';
import { setSelection } from '../view/viewSlice';
import { deselectLayers } from '../layer/liveSheetSlice';
import { workSpaceClassName } from './WorkSpace';
import { layersClassName } from '../layer/LayerRenderer';

/**
 * Fires regardless whether the work space is active or not
 */
export const handleWorkSpacePointerDown =
  (sheetId: string, e: PointerEvent): AppThunk =>
  (dispatch, getState) => {
    if (e.button !== 0) {
      return;
    }
    const state = getState();
    const project = getProjectSlice(state);
    if (!project || project.isReadonly) {
      return;
    }
    const activeSheetId = getActiveSheetId(state);
    if (sheetId !== activeSheetId) {
      dispatch(activateSheet(sheetId));
      e.stopPropagation();
      return;
    }
    const {
      mouse: { workSpace: start },
    } = getDesignerInput(state);

    if (e.target) {
      const targetClass = (e.target as HTMLElement).className;
      if ([workSpaceClassName, layersClassName].some(($) => targetClass.includes($))) {
        dispatch(deselectLayers());
      }
    }
    dispatch(setSelection({ start, end: start }));
  };
