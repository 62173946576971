import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserState } from './IUserState';

export const userSlice = createSlice({
  initialState: null as IUserState,
  name: 'user',
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => action.payload,
  },
});

export const { setUser } = userSlice.actions;
