import { MenuButtonDesigner } from '../../uiComponents/MenuButtonDesigner';
import { Icon } from '../../icons/Icon';
import React, { useCallback, useRef, useState } from 'react';
import { useDesignerMode, useDesignerView } from '../view/viewSelectors';
import styled from 'styled-components/macro';
import { SubMenu } from './SubMenu';
import { useTranslation } from 'react-i18next';
import { toggleOverflow, toggleProblems, toggleScaffold } from '../view/viewSlice';
import { useAppDispatch } from '../../redux/hooks';
import { IDropDown } from './DesignerMenu';
import { useClickOutside } from '../../lib/useClickOutside';
import { useActiveProduct } from '../project/productSelectors';
import { DesignerMode } from '../designerMode';
import { ProductType } from '../project/ProductState';
import { CoverPreview } from '../coverPreview/CoverPreview';

export interface IViewControl {
  dropDown: IDropDown;
  setDropDown: React.Dispatch<React.SetStateAction<IDropDown>>;
}

export const ViewControl: React.FC<IViewControl> = ({ dropDown, setDropDown }) => {
  const { t } = useTranslation();
  const { showScaffold, showOverflow, showProblems } = useDesignerView();
  const dispatch = useAppDispatch();
  const viewControlElementRef = useRef<HTMLDivElement>(null);

  const product = useActiveProduct();
  const mode = useDesignerMode();
  const [showCoverPreview, setShowCoverPreview] = useState<boolean>(false);
  const supports3DPreview = mode === DesignerMode.Cover && product?.type !== ProductType.Ebook;

  const printView = !showScaffold && !showOverflow && !showProblems;
  const printViewMarker = showScaffold && !showOverflow && showProblems;
  const fullView = showScaffold && showOverflow && showProblems;

  const handleDropDown = useCallback(
    (showDropdown: boolean) => setDropDown({ snapControl: false, viewControl: showDropdown }),
    [setDropDown],
  );

  const onClickOutside = useCallback(() => {
    handleDropDown(false);
  }, [handleDropDown]);

  useClickOutside(viewControlElementRef.current, onClickOutside, dropDown.viewControl);

  const onClickShowCoverPreview = useCallback(() => {
    setShowCoverPreview(true);
  }, []);

  const onClick3DView = useCallback(() => {
    onClickShowCoverPreview();
    handleDropDown(false);
  }, [onClickShowCoverPreview, handleDropDown]);

  const onClickPrintView = useCallback(() => {
    dispatch(toggleScaffold(false));
    dispatch(toggleOverflow(false));
    dispatch(toggleProblems(false));
    handleDropDown(false);
  }, [handleDropDown, dispatch]);

  const onClickPrintViewMarker = useCallback(() => {
    dispatch(toggleScaffold(true));
    dispatch(toggleOverflow(false));
    dispatch(toggleProblems(true));
    handleDropDown(false);
  }, [handleDropDown, dispatch]);

  const onClickFullView = useCallback(() => {
    dispatch(toggleScaffold(true));
    dispatch(toggleOverflow(true));
    dispatch(toggleProblems(true));
    handleDropDown(false);
  }, [handleDropDown, dispatch]);

  return (
    <Wrapper ref={viewControlElementRef}>
      <MenuButtonDesigner
        onClick={() => handleDropDown(!dropDown.viewControl)}
        title={t('ShowMarker')}>
        <DropdownControl>
          <Icon name="ansehen-control" />
          <Icon name="dropdown-control" />
        </DropdownControl>
        <span className="designer-menu-label">{t('PrintView')}</span>
      </MenuButtonDesigner>
      {dropDown.viewControl && (
        <SubMenu onClose={() => handleDropDown(false)}>
          <StyledLabel isActive={fullView}>
            <RadioButton type="radio" name="view" checked={fullView} onChange={onClickFullView} />
            <span>{t('TotalWorkSurface')}</span>
          </StyledLabel>
          <StyledLabel isActive={printViewMarker}>
            <RadioButton
              type="radio"
              name="view"
              checked={printViewMarker}
              onChange={onClickPrintViewMarker}
            />
            <span>{t('PrintLayoutWith')}</span>
          </StyledLabel>
          <StyledLabel isActive={printView}>
            <RadioButton type="radio" name="view" checked={printView} onChange={onClickPrintView} />
            <span>{t('PrintLayoutWithout')}</span>
          </StyledLabel>
          {supports3DPreview && (
            <StyledLabel isActive={showCoverPreview} onClick={onClick3DView} title={t('3DView')}>
              <span>{t('3DView')}</span>
            </StyledLabel>
          )}
        </SubMenu>
      )}
      {showCoverPreview && <CoverPreview onRequestClose={() => setShowCoverPreview(false)} />}
    </Wrapper>
  );
};

interface IStyledLabel {
  isActive: boolean;
}

const Wrapper = styled.div`
  position: relative;
`;

const DropdownControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  & > .tredition-icon:first-child {
    font-size: 1.2rem;
  }

  & > .tredition-icon:last-child {
    padding-top: 2px;
    font-size: 10px;
  }
`;

const StyledLabel = styled.label.attrs<IStyledLabel>((props) => ({
  style: {
    background: props.isActive ? 'var(--color-cta)' : 'white',
    fontWeight: props.isActive ? '600' : '400',
  },
}))<IStyledLabel>`
  font-size: 0.9rem;
  height: 33px;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-standard);
  align-items: center;
  border-radius: var(--border-radius);
  cursor: pointer;

  & > span {
    width: max-content;
  }

  & svg {
    height: 0.875rem;
  }

  &:hover {
    background: var(--color-cta-light) !important;
  }
`;

const RadioButton = styled.input`
  display: none;
`;
