import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ICoverTemplateState } from './ContentTemplateState';
import { ICSSObject } from '../components/form_constant/CSSObject';

export interface ISetCoverTemplateActionPayload {
  template: string;
  templateLabel: string;
  values: ICSSObject;
}

export const setContentTemplateReducer = (
  state: Draft<ICoverTemplateState>,
  action: PayloadAction<ISetCoverTemplateActionPayload>,
) => {
  state.selectedTemplate = action.payload.template;
  state.templateValues = action.payload.values;
  state.templateLabel = action.payload.templateLabel;
};
