import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { filterLayers, ILayerFilter } from './layerFilter';
import { modDeg } from '../../lib/modDeg';
import { ILiveSheetState } from './ILiveSheetState';

export interface IRotateLayersActionPayload {
  /**
   * Rotation in degrees
   */
  degrees: number;

  /**
   * A filter which will determine which layers should be rotated.
   *
   * Note that layers which do not support rotations will be skipped in all cases.
   */
  filter: ILayerFilter;
}

export function rotateLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IRotateLayersActionPayload>,
) {
  const { filter, degrees } = action.payload;

  filterLayers(state.layers, filter).forEach((layer) => {
    if (layer.isRotatable) {
      layer.transform.rotate = modDeg(degrees);
      layer.display.rotation = layer.transform.rotate;
      layer.updatedAt = Date.now();
    }
  });
}
