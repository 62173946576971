import { DeepPartial, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerGridState, IDesignerViewState } from './IDesignerViewState';

export function setGridReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<DeepPartial<IDesignerGridState>>,
) {
  const { size, isEnabled } = action.payload;

  if (typeof size === 'object' && size) {
    if (typeof size.width === 'number' && !Number.isNaN(size.width)) {
      state.grid.size.width = Math.max(size.width, 1);
    }
    if (typeof size.height === 'number' && !Number.isNaN(size.height)) {
      state.grid.size.height = Math.max(size.height, 1);
    }
  }
  if (typeof isEnabled === 'boolean') {
    state.grid.isEnabled = isEnabled;
  }
}
