import { ChangeEvent, FC, useCallback } from 'react';
import { ColorPicker } from './ColorPicker';
import { PalettePreview } from './PalettePreview';
import {
  PaletteCreatorActionType,
  PaletteCreatorBias,
  usePaletteCreatorState,
} from './usePaletteCreatorState';
import { ISaveTreditionPalette, ITreditionPalette } from './TreditionPalette';
import { rgbToHex } from '../../lib/rgbToHex';
import { IColor } from './IColor';
import styled from 'styled-components/macro';
import { Button } from '../../uiComponents/Button';
import { useTranslation } from 'react-i18next';

export interface IPaletteCreatorProps {
  /**
   * Invoked when the palette creation should be cancelled
   */
  onCancel: () => unknown;

  /**
   * Invoked when the user wants to create the palette
   */
  onCreate: (palette: ISaveTreditionPalette) => unknown;

  /**
   * Optionally preloads the creator with the given palette
   */
  initialPalette?: ITreditionPalette | null;
}

export const PaletteCreator: FC<IPaletteCreatorProps> = ({
  onCancel,
  onCreate,
  initialPalette,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = usePaletteCreatorState(initialPalette);
  const hasPalette = !!state.palette.length;

  const startPicking = useCallback(() => {
    dispatch({ type: PaletteCreatorActionType.SetColorPickTarget, index: 0 });
  }, [dispatch]);

  const onSwapColors = useCallback(
    (a: number, b: number) => {
      dispatch({ type: PaletteCreatorActionType.SwapColors, a, b });
    },
    [dispatch],
  );

  const onPickColor = useCallback(
    ({ rgba }: IColor) => {
      if (state.editingColor === null) {
        return;
      }
      dispatch({
        type: PaletteCreatorActionType.SetColorAt,
        color: rgba,
        index: state.editingColor,
      });
    },
    [dispatch, state.editingColor],
  );

  const onCancelPickColor = useCallback(() => {
    dispatch({ type: PaletteCreatorActionType.SetColorPickTarget, index: null });
  }, [dispatch]);

  const onChangeIntensity = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const intensity: number = Number.parseInt(e.currentTarget.value);
      if (Number.isNaN(intensity)) {
        return;
      }
      dispatch({ type: PaletteCreatorActionType.SetIntensity, intensity });
    },
    [dispatch],
  );

  const onChangeBias = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const bias: PaletteCreatorBias = Number.parseInt(e.currentTarget.value);
      if (Number.isNaN(bias)) {
        return;
      }
      dispatch({ type: PaletteCreatorActionType.SetBias, bias });
    },
    [dispatch],
  );

  const onClickMix = useCallback(() => {
    dispatch({ type: PaletteCreatorActionType.Mix });
  }, [dispatch]);

  const onTargetColor = useCallback(
    (index: number) => {
      dispatch({ type: PaletteCreatorActionType.SetColorPickTarget, index });
    },
    [dispatch],
  );

  const onChangeName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: PaletteCreatorActionType.SetName, name: e.target.value });
    },
    [dispatch],
  );

  const onClickCreate = useCallback(() => {
    if (state.palette.length !== 6) {
      return;
    }
    onCreate({
      id: state.id,
      name: state.name,
      color1: rgbToHex(state.palette[0], true),
      color2: rgbToHex(state.palette[1], true),
      color3: rgbToHex(state.palette[2], true),
      color4: rgbToHex(state.palette[3], true),
      color5: rgbToHex(state.palette[4], true),
      color6: rgbToHex(state.palette[5], true),
    });
  }, [state, onCreate]);

  if (state.editingColor !== null) {
    return (
      <ColorPicker
        onCancel={onCancelPickColor}
        onPick={onPickColor}
        initialColor={{ rgba: state.palette[state.editingColor], paletteIndex: null }}
      />
    );
  }

  return (
    <Wrapper>
      <label>
        <div>{t('Name')}</div>
        <StyledInput type="text" value={state.name} onChange={onChangeName} />
      </label>
      {!hasPalette && <Button onClick={startPicking}>{t('ChoosePrimaryColor')}</Button>}
      <PalettePreview
        colors={state.palette}
        onClickColor={onTargetColor}
        onSwapColors={onSwapColors}
      />
      {hasPalette && (
        <Grid>
          <header>
            <span>{t('ColorPaletteMixer')}</span>
          </header>
          <Grid className="mixer-options">
            <StyledLabel>
              <span>{t('Intensity')}</span>
              <select onChange={onChangeIntensity} value={state.intensity}>
                <option value="1">1 ({t('Weak')})</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5 ({t('Strong')})</option>
              </select>
            </StyledLabel>
            <StyledLabel>
              <span>{t('Balance')}</span>
              <select onChange={onChangeBias} value={state.bias}>
                <option value={PaletteCreatorBias.None}>{t('Balanced')}</option>
                <option value={PaletteCreatorBias.MoreRed}>{t('MoreRed')}</option>
                <option value={PaletteCreatorBias.MoreGreen}>{t('MoreGreen')}</option>
                <option value={PaletteCreatorBias.MoreBlue}>{t('MoreBlue')}</option>
              </select>
            </StyledLabel>
          </Grid>
          <Button onClick={onClickMix}>{t('GenerateNewScheme')}</Button>
        </Grid>
      )}
      <Footer>
        <Button cancel onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={onClickCreate} disabled={!state.palette.length}>
          {t('OK')}
        </Button>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  min-height: 200px;
  grid-template-rows: min-content;
  gap: 1rem;
`;

const Footer = styled.footer`
  align-self: end;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  padding-top: 2rem;
`;

const StyledInput = styled.input`
  &:focus {
    outline-color: var(--color-cta);
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
`;
