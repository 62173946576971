import { AppThunk } from '../../redux/store';
import { ISaveCoverDesignAsTemplateArg, treditionApi } from '../../api/treditionApi';
import { getActiveProductId } from './productSelectors';
import { getSaveDesignProjectData } from './getSaveDesignProjectData';
import { getCurrentProjectId } from './projectSelectors';

export const saveAsTemplate =
  (name: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const projectId = getCurrentProjectId(getState());
    const productId = getActiveProductId(getState());
    if (!projectId || !productId) {
      return;
    }
    const saveData = await dispatch(getSaveDesignProjectData());
    if (!saveData) {
      return;
    }
    const payload: ISaveCoverDesignAsTemplateArg = {
      products: saveData.products,
      productId,
      projectId,
      name,
    };
    return dispatch(treditionApi.endpoints.saveCoverAsTemplate.initiate(payload)).unwrap();
  };
