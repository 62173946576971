import { ChangeEvent, FC } from 'react';
import { ICMYK, IHSV, IRGBA } from '../../lib/Color';
import { clamp } from '../../lib/clamp';

export interface IColorInputProps {
  /**
   * RGBA value of the currently selected color
   */
  rgba: IRGBA;

  /**
   * HSV value of the currently selected color
   */
  hsv: IHSV;

  /**
   * CMYK value of the currently selected color
   */
  cmyk: ICMYK;

  /**
   * Hex-String representation of the currently selected color
   */
  hex: string;

  /**
   * Fired when the user changes the R, G, B or A input value
   */
  onChangeRgba: (rgba: IRGBA) => void;

  /**
   * Fired when the user changes the C, M, Y or K input value
   */
  onChangeCmyk: (cmyk: ICMYK) => void;

  /**
   * Fired when the user changes the H, S or V input value
   */
  onChangeHsv: (hsv: IHSV) => void;

  /**
   * Fired when the user changes the hex input value
   */
  onChangeHex: (hex: string) => void;
}

export const ColorInput: FC<IColorInputProps> = ({
  rgba,
  hsv,
  cmyk,
  hex,
  onChangeHex,
  onChangeCmyk,
  onChangeHsv,
  onChangeRgba,
}) => {
  return (
    <div className="color-input">
      <div className="hex">
        <label>
          #
          <input
            type="text"
            value={hex}
            onChange={(e) => {
              onChangeHex((e.target.value || '').replace(/^#/, '').substring(0, 8));
            }}
          />
        </label>
      </div>
      <div className="hsv">
        <label>
          H
          <input
            type="number"
            min={0}
            max={100}
            value={Math.round(hsv.h * 360)}
            onChange={pipeChange(0, 360, (h) => onChangeHsv({ ...hsv, h }))}
          />
        </label>
        <label>
          S
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(hsv.s * 100)}
            onChange={pipeChange(0, 100, (s) => onChangeHsv({ ...hsv, s }))}
          />
        </label>
        <label>
          V
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(hsv.v * 100)}
            onChange={pipeChange(0, 100, (v) => onChangeHsv({ ...hsv, v }))}
          />
        </label>
      </div>
      <div className="rgba">
        <label>
          R
          <input
            type="number"
            step={1}
            min={0}
            max={255}
            value={Math.round(rgba.r * 255)}
            onChange={pipeChange(0, 255, (r) => onChangeRgba({ ...rgba, r }))}
          />
        </label>
        <label>
          G
          <input
            type="number"
            step={1}
            min={0}
            max={255}
            value={Math.round(rgba.g * 255)}
            onChange={pipeChange(0, 255, (g) => onChangeRgba({ ...rgba, g }))}
          />
        </label>
        <label>
          B
          <input
            type="number"
            step={1}
            min={0}
            max={255}
            value={Math.round(rgba.b * 255)}
            onChange={pipeChange(0, 255, (b) => onChangeRgba({ ...rgba, b }))}
          />
        </label>
        <label>
          A
          <input
            type="number"
            step={1}
            min={0}
            max={255}
            value={Math.round(rgba.a * 255)}
            onChange={pipeChange(0, 255, (a) => onChangeRgba({ ...rgba, a }))}
          />
        </label>
      </div>
      <div className="cmyk">
        <label>
          C
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(cmyk.c * 100)}
            onChange={pipeChange(0, 100, (c) => onChangeCmyk({ ...cmyk, c }))}
          />
        </label>
        <label>
          M
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(cmyk.m * 100)}
            onChange={pipeChange(0, 100, (m) => onChangeCmyk({ ...cmyk, m }))}
          />
        </label>
        <label>
          Y
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(cmyk.y * 100)}
            onChange={pipeChange(0, 100, (y) => onChangeCmyk({ ...cmyk, y }))}
          />
        </label>
        <label>
          K
          <input
            type="number"
            step={1}
            min={0}
            max={100}
            value={Math.round(cmyk.k * 100)}
            onChange={pipeChange(0, 100, (k) => onChangeCmyk({ ...cmyk, k }))}
          />
        </label>
      </div>
    </div>
  );
};

function pipeChange(min: number, max: number, cb: (v: number) => void) {
  return (e: ChangeEvent<HTMLInputElement>) => {
    const n = clamp(Number.parseInt(e.target.value), min, max) / max;
    if (Number.isNaN(n)) {
      return;
    }
    cb(n);
  };
}
