import { FC, useMemo } from 'react';
import { IHistoryRecord } from '../../history/IHistoryState';
import { HistoryRecordType } from '../../history/historySlice';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../icons/Icon';

export interface IHistoryEntryProps {
  /**
   * Whether the project was saved at this history entry
   */
  isSaved: boolean;

  /**
   * The history record to visualize
   */
  record: IHistoryRecord;
}

export const HistoryEntry: FC<IHistoryEntryProps> = ({ isSaved, record }) => {
  const { t } = useTranslation();

  const description = useMemo<string>(() => {
    switch (record.type) {
      case HistoryRecordType.Unknown:
      default:
        return t('DesignHistoryTypeUnknown');
      case HistoryRecordType.ProjectInitialized:
        return t('DesignHistoryTypeProjectInitialized');
      case HistoryRecordType.ImageReplaced:
        return t('DesignHistoryTypeImageReplaced');
      case HistoryRecordType.LayerMoved:
        return t('DesignHistoryTypeLayerMoved');
      case HistoryRecordType.LayerDeleted:
        return t('DesignHistoryTypeLayerDeleted');
      case HistoryRecordType.LayerCreated:
        return t('DesignHistoryTypeLayerCreated');
      case HistoryRecordType.LayerScaled:
        return t('DesignHistoryTypeLayerScaled');
      case HistoryRecordType.LayerRotated:
        return t('DesignHistoryTypeLayerRotated');
      case HistoryRecordType.LayerChanged:
        return t('DesignHistoryTypeLayerStyleChanged');
      case HistoryRecordType.ProductImported:
        return t('DesignHistoryTypeProductImported');
      case HistoryRecordType.PaletteChanged:
        return t('DesignHistoryTypePaletteChanged');
      case HistoryRecordType.LayerDepthChanged:
        return t('DesignHistoryTypeLayerDepthChanged');
      case HistoryRecordType.LayerPasted:
        return t('DesignHistoryTypeLayerPasted');
      case HistoryRecordType.SheetReordered:
        return t('DesignHistoryTypeSheetReordered');
      case HistoryRecordType.SheetCreated:
        return t('DesignHistoryTypeSheetCreated');
      case HistoryRecordType.SheetDeleted:
        return t('DesignHistoryTypeSheetDeleted');
      case HistoryRecordType.TemplateApplied:
        return t('DesignHistoryTypeTemplateApplied');
    }
  }, [record.type, t]);
  const time = new Date(record.createdAt).toLocaleTimeString();
  return (
    <Wrapper>
      <div>{isSaved && <Icon name="speichern" title={t('DesignHistorySavedHere')} />}</div>
      <Time>{time}</Time>
      <div>{description}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  grid-template-columns: auto auto;
  grid-template-rows: min-content auto;
  gap: 0.5rem;
  user-select: none;

  & > :first-child {
    align-self: flex-start;
    grid-area: 1 / 1 / span 1 / span 1;
  }

  & > :nth-child(2) {
    justify-self: end;
    grid-area: 1 / 2 / span 1 / span 1;
  }

  & > :last-child {
    grid-area: 2 / 1 / span 1 / span 2;
  }
`;

const Time = styled.span`
  font-weight: 300;
  color: var(--color-secondary);
`;
