import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';
import { IFormHistoryEntry } from '../model/FormHistoryEntry';
import { detailedDiff } from 'deep-object-diff';
import { historyDiffToString, ICssSubset } from '../services/historyDiffToString';
import { ICSSObject } from '../components/form_constant/CSSObject';

export interface IDiff {
  updated: ICssSubset;
}

export interface ISetFormHistoryEntryActionPayload {
  /**
   * form values
   */
  formValues: ICSSObject;
  /**
   * time when the history was triggered
   */
  timeStamp: number;
}

/**
 * Sets the time machine entry
 */
export const setFormHistoryEntryReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetFormHistoryEntryActionPayload>,
) => {
  const diffValue = detailedDiff(state.formValues, action.payload.formValues) as IDiff;
  let diff;
  try {
    diff = historyDiffToString(diffValue.updated, state.formValues);
  } catch (error) {
    return state;
  }
  if (!diff) return;
  const newEntry: IFormHistoryEntry = {
    formValues: state.formValues,
    diff,
    timeStamp: action.payload.timeStamp,
  };
  state.formHistory = [newEntry, ...state.formHistory].slice(0, 50);
};
