import { ICMYK, IRGB } from './Color';

/**
 * Converts a CMYK color to RGB
 */
export function cmykToRgb({ c, m, y, k }: ICMYK): IRGB {
  const invK = 1 - k;
  return {
    r: (1 - c) * invK,
    g: (1 - m) * invK,
    b: (1 - y) * invK,
  };
}
