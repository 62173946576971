export enum PageMarginsFormEnumeration {
  DISTANCE_TOP = 'distance-top',
  DISTANCE_BOTTOM = 'distance-bottom',
  DISTANCE_INNER = 'distance-inner',
  DISTANCE_OUTER = 'distance-outer',
}

export type IPageMarginsForm = {
  [PageMarginsFormEnumeration.DISTANCE_INNER]: number;
  [PageMarginsFormEnumeration.DISTANCE_OUTER]: number;
  [PageMarginsFormEnumeration.DISTANCE_TOP]: number;
  [PageMarginsFormEnumeration.DISTANCE_BOTTOM]: number;
};
