import { Plugin } from "@ckeditor/ckeditor5-core";
import { View, ViewCollection } from "ckeditor5/src/ui";

export const BLACK_IN_HSLA = "hsla(0turn,0%,0%,1)";
export const TRANSPARENT_IN_HSLA = "hsla(0turn,0%,0%,0)";

export class CustomFontColorEditing extends Plugin {
  currentColor = BLACK_IN_HSLA;

  init() {
    this.editor.ui.componentFactory.add("customFontColorEditing", (locale) => {
      const editor = this.editor;
      const t = editor.t;

      const command = editor.commands.get("fontColor");

      // Use original fontFamily button - we only changes its behavior.
      const dropdownView = editor.ui.componentFactory.create("fontColor");

      const getValueOrDefault = (value) => value || BLACK_IN_HSLA;

      // Show label on dropdown's button.
      dropdownView.buttonView.set("withText", true);

      dropdownView.bind("isOpen", "false");

      const wrapperView = new View(locale);
      wrapperView.setTemplate({
        tag: "div",
        attributes: {
          class: ["ck ck-character-spacing-wrapper"],
        },
        children: [
          {
            text: "Hallo",
          },
        ],
      });

      const eventOptions = editor.config.get("fontColor.eventOptions") || {};

      if (eventOptions && eventOptions.emitEventOnDialogOpen) {
        const eventName =
          eventOptions.eventName || "ckeditor:custom:openDialog";
        dropdownView.on("change:isOpen", (evt, name, isVisible) => {
          // prevent dialog to open up
          if (eventOptions.preventDialogOpening) {
            dropdownView.isOpen = false;
          }
          if (isVisible) {
            const event = new CustomEvent(eventName, {
              detail: {
                component: "fontColor",
                color: this.currentColor,
              },
            });
            document.dispatchEvent(event);
          }
        });
      }

      // Bind dropdown's button label to fontFamily value.
      dropdownView.buttonView
        .bind("labelStyle")
        .to(command, "value", (value) => {
          // If no value is set on the command show 'Default' text.
          // Use t() method to make that string translatable.
          return `background-color:${getValueOrDefault(value)} !important`;
        });

      // Bind dropdown's button label to fontFamily value.
      dropdownView.buttonView.bind("label").to(command, "value", (value) => {
        // If no value is set on the command show 'Default' text.
        // Use t() method to make that string translatable.
        const color = getValueOrDefault(value);
        this.currentColor = color;
        return color;
      });

      return dropdownView;
    });
  }
}
