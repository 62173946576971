import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../icons/Icon';

interface IBackButton {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const BackButton: React.FC<IBackButton> = ({ children, onClick }) => {
  return (
    <BackButtonStyled onClick={onClick}>
      <Icon name="pfeillinks2" />
      {children}
    </BackButtonStyled>
  );
};

const BackButtonStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: black;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  cursor: pointer;

  & > svg {
    width: 0.75rem;
    transform: rotate(-90deg);
  }
`;
