import { IPoint } from '../../../@types/IPoint';
import { rotatePointDeg } from '../../lib/rotatePointDeg';

/**
 * As scaling is performed around the origin but rotation around the layer's center point (rot. pivot) the layer will
 * shift because its pivot moves as it is scaled. This function returns the shift by simulating a rotation in degrees of the
 * origin around the layer's pivot before and after the scale and then subtracts the difference.
 *
 * You need to subtract the calculated difference from your layer's position whenever a rotated layer is being scaled.
 */
export function calculateRotationShift(
  oldPos: IPoint,
  newPos: IPoint,
  oldPivot: IPoint,
  newPivot: IPoint,
  rotation: number,
): IPoint {
  const rotatedOrigin = rotatePointDeg(oldPos, oldPivot, rotation);
  const rotatedOriginAfterScale = rotatePointDeg(oldPos, newPivot, rotation);
  return {
    x: rotatedOriginAfterScale.x - rotatedOrigin.x,
    y: rotatedOriginAfterScale.y - rotatedOrigin.y,
  };
}
