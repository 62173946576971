import React from 'react';
import styles from './MainBookWizardLayout.module.css';
import { InfoButton } from '../../buttons/info/InfoButton';
import { GlobalError, IGlobalErrorProps } from '../../editor/GlobalError';
import { Spacer } from '../../shared/spacer/Spacer';

interface IMainBookWizardLayout extends IGlobalErrorProps {
  headline: string;
  infoText?: string;
  children: React.ReactNode;
  subHeading?: React.ReactNode;
}

export const MainBookWizardLayout = ({
  headline,
  infoText = '',
  children,
  subHeading,
  formik,
  customErrors,
}: IMainBookWizardLayout) => {
  const hasInfoText = infoText !== '';
  const hasErrors =
    (formik && formik.formikErrors && Object.keys(formik?.formikErrors)?.length > 0) ||
    customErrors;
  return (
    <main className={styles.wrapper}>
      <div className={styles.heading}>
        <>
          <div className={styles.flex}>
            <h1>{headline}</h1>
            {hasInfoText && <InfoButton infoText={infoText} />}
          </div>
          {hasErrors && (
            <>
              <Spacer size={1} />
              <GlobalError customErrors={customErrors} formik={formik} />
              <Spacer size={1} />
            </>
          )}
          {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
        </>
      </div>
      <div className={styles.container}>{children}</div>
    </main>
  );
};
