import yup from '../../validator';

export const createEditorSchema = () => {
  return yup.object().shape({
    title: yup.string().defined().optional(),
    firstName: yup.string().when('isCompany', {
      is: true,
      then: () => yup.string().strip(),
      otherwise: () => yup.string().min(2).max(200).required(),
    }),
    lastName: yup.string().when('isCompany', {
      is: true,
      then: () => yup.string().strip(),
      otherwise: () => yup.string().defined().min(2).max(200).required(),
    }),
    companyName: yup.string().when('isCompany', {
      is: true,
      then: () => yup.string().defined().required(),
      otherwise: () => yup.string().strip(),
    }),
    isCompany: yup.boolean().defined(),
    website: yup.string().defined().url().optional(),
  });
};
