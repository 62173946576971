import { EditorStepKey } from '../EditorStepKey';

export interface IMenuStepConfig {
  key: EditorStepKey;
  label: string;
}

export interface IMenuGroupConfig {
  key: string;
  label: string;
  steps: IMenuStepConfig[];
}

export interface IMenuViewConfig {
  groups: IMenuGroupConfig[];
}

export const MenuViewConfig: IMenuViewConfig = {
  groups: [
    // 1
    {
      key: 'settings',
      label: 'BEPMenuSettings',
      steps: [
        {
          key: EditorStepKey.Category,
          label: 'BEPMenuGenre',
        },
        {
          key: EditorStepKey.ProjectData,
          label: 'BEPMenuProjectData',
        },
        {
          key: EditorStepKey.Format,
          label: 'BEPMenuFormat',
        },
        {
          key: EditorStepKey.Contributors,
          label: 'BEPMenuProjectContributor',
        },
        {
          key: EditorStepKey.Isbn,
          label: 'BEPMenuISBN',
        },
      ],
    },
    // 2
    {
      key: 'content',
      label: 'BEPMenuInnerPart',
      steps: [
        {
          key: EditorStepKey.PrepareContentFile,
          label: 'BEPMenuInnerCheck',
        },
        {
          key: EditorStepKey.UploadContentFile,
          label: 'BEPMenuInnerUpload',
        },
        {
          key: EditorStepKey.ViewContentFile,
          label: 'BEPMenuInnerResult',
        },
      ],
    },
    // 3
    {
      key: 'print',
      label: 'BEPMenuPrinting',
      steps: [
        {
          key: EditorStepKey.ColorPages,
          label: 'BEPMenuColorPages',
        },
        {
          key: EditorStepKey.Material,
          label: 'BEPMenuMaterial',
        },
      ],
    },
    // 4
    {
      key: 'cover',
      label: 'BEPMenuCover',
      steps: [
        {
          key: EditorStepKey.CoverTool,
          label: 'BEPMenuCoverBasics',
        },
        {
          key: EditorStepKey.CoverDesigner,
          label: 'BEPMenuCoverDesigner',
        },
        {
          key: EditorStepKey.PrepareCoverFile,
          label: 'BEPMenuCoverCheck',
        },
        {
          key: EditorStepKey.UploadCoverFile,
          label: 'BEPMenuCoverUpload',
        },
        {
          key: EditorStepKey.ViewCoverFile,
          label: 'BEPMenuCoverResult',
        },
      ],
    },
    // 5
    {
      key: 'publishing',
      label: 'BEPMenuSales',
      steps: [
        {
          key: EditorStepKey.Provision,
          label: 'BEPMenuProvision',
        },
        {
          key: EditorStepKey.ProvisionContribution,
          label: 'BEPMenuProvisionContribution',
        },
        {
          key: EditorStepKey.SearchAndFind,
          label: 'BEPMenuSearchAndFind',
        },
        {
          key: EditorStepKey.BookInterview,
          label: 'BEPMenuBookInterview',
        },
        {
          key: EditorStepKey.AuthorProfile,
          label: 'BEPMenuAuthorPortrait',
        },
        {
          key: EditorStepKey.ShopView,
          label: 'BEPMenuShopView',
        },
      ],
    },
  ],
};
