/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useMemo } from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import styles from './Select.module.css';
import { useTranslation } from 'react-i18next';
import { Label } from '../forms/label/Label';
import VisuallyHidden from '../shared/visuallyHidden/VisuallyHidden';
import { IOption, IOptionGroup, OptionLike } from '../../@types/IOption';
import { emptyArray } from '../lib/emptyArray';
import { deriveReactSelectValue } from './deriveReactSelectValue';
import { CompareValuesFn } from './compareValues';

type InferValueType<OptionType, IsMulti> = IsMulti extends true
  ? InferValueTypeByOptionType<OptionType>[]
  : InferValueTypeByOptionType<OptionType> | null;

type InferValueTypeByOptionType<OptionType> = OptionType extends IOption<infer V>
  ? V
  : OptionType extends IOptionGroup<infer V>
  ? V
  : never;

/**
 * Note: at the time of writing, this cannot be inlined otherwise Jetbrains'
 *       inspection on required props will bug out
 */
type PrunedReactSelectProps<OptionType extends OptionLike<any>, IsMulti extends boolean> = Omit<
  ReactSelectProps<OptionType, IsMulti, any>,
  'options' | 'onChange' | 'value'
>;

export interface ISelectProps<OptionType extends OptionLike<any>, IsMulti extends boolean>
  extends PrunedReactSelectProps<OptionType, IsMulti> {
  options: OptionType[];
  onChange?: (value: InferValueType<OptionType, IsMulti>) => unknown;
  value?: InferValueType<OptionType, IsMulti>;
  hasError?: boolean;
  label?: string;
  labelHidden?: boolean;
  className?: string;

  /**
   * A value comparison function to use when comparing values to determine if
   * they are equal. Defaults to a simple === check.
   *
   * You **MUST** provide a custom compareValues function if you are using
   * complex types (non-primitive types) as values otherwise the default ===
   * might fail on objects.
   */
  compareValues?: CompareValuesFn<InferValueType<OptionType, IsMulti>>;
}

const getOptionValue = <ValueType,>(o: IOption<ValueType>): ValueType => o.value;
const getOptionLabel = (o: IOption<any>): IOption<any>['label'] => o.label;

/**
 * Provides a bridge to react-select which solves some common pitfalls
 *
 * Note: the typing of react-select is beyond broken. This component takes care
 *       of all typing issues and exposes the correct interface
 */
export const Select = <OptionType extends OptionLike<any>, IsMulti extends boolean = false>(
  props: ISelectProps<OptionType, IsMulti>,
) => {
  const { t } = useTranslation();
  const { compareValues, onChange, isMulti, options, value } = props;

  const flattenedOptions = useMemo<IOption<any>[]>(() => {
    if (!Array.isArray(options) || !options.length) {
      return emptyArray;
    }
    if (!Array.isArray((options[0] as unknown as IOptionGroup<any>).options)) {
      return options;
    }
    return options.flatMap((o) => (o as unknown as IOptionGroup<any>).options);
  }, [options]);

  const reactSelectValue = useMemo<unknown>(() => {
    return deriveReactSelectValue(value, flattenedOptions, Boolean(isMulti), compareValues as any);
  }, [value, compareValues, flattenedOptions, isMulti]);

  const onChangeInternal = useCallback(
    (optionOrOptions: unknown /* actionMeta */) => {
      if (!onChange) {
        return;
      }
      if (isMulti) {
        /* optionOrOptions is IOption<ValueType>[] */
        const optionsToMap = (optionOrOptions || emptyArray) as any;
        onChange(optionsToMap.map(getOptionValue) as any /* TS BUG? */);
        return;
      }
      // optionOrOptions is IOption<ValueType> | null
      onChange(optionOrOptions ? getOptionValue(optionOrOptions as any) : (null as any));
    },
    [onChange, isMulti],
  );
  return (
    <div className={props.className}>
      <Label>
        {props.labelHidden ? (
          <VisuallyHidden>{props.label}</VisuallyHidden>
        ) : (
          <span className={styles.label}>{props.label}</span>
        )}
      </Label>
      <ReactSelect
        {...(props as any)}
        onChange={onChangeInternal as any}
        className={`${styles.select} ${props.className ?? ''}`}
        classNamePrefix="react-select"
        getOptionLabel={getOptionLabel}
        getOptionValue={
          getOptionValue as any /* TYPING ERROR IN REACT SELECT: hardcoded to string*/
        }
        placeholder={props.placeholder ?? t('Select')}
        styles={{
          menu: (base) => ({ ...base, width: 'max-content', minWidth: '100%' }),
        }}
        value={reactSelectValue as any}
        isMulti={isMulti}
        noOptionsMessage={() => t('NoOptions')}
      />
    </div>
  );
};
