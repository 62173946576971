import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryState } from './IHistoryState';

export interface ISetHistoryIndexActionPayload {
  /**
   * The productId owning the history page
   */
  productId: string;

  /**
   * The new index to assign
   */
  index: number;
}

/**
 * Updates the index of the project's history page. This does not restore the undo point, but
 * merely updates the pointer.
 */
export function setHistoryIndexReducer(
  state: Draft<IHistoryState>,
  action: PayloadAction<ISetHistoryIndexActionPayload>,
) {
  const { productId, index } = action.payload;
  const page = state.byProductId[productId];
  if (page && index < page.records.length && index >= -1) {
    page.index = index;
  }
}
