import { RootState } from '../redux/store';
import { userSlice } from './userSlice';
import { useAppSelector } from '../redux/hooks';

/**
 * Returns the entire auth state slice
 */
export const getUserSlice = (state: RootState) => state[userSlice.name];

/**
 * Returns the current user or null if not logged in
 */
export const getUser = (state: RootState) => getUserSlice(state);

/**
 * Provides the current user (or null if not logged in) to a React component
 */
export const useUser = () => useAppSelector(getUser);
