import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILocaleState } from './ILocaleState';
import { IConfig } from '../config/IConfig';
import { legacyLanguageCodeToIso } from './language';

export function applyConfigureToLocaleReducer(
  state: Draft<ILocaleState>,
  action: PayloadAction<IConfig>,
) {
  try {
    state.languageCode = legacyLanguageCodeToIso(action.payload.languageCode);
  } catch (error) {
    return; // do not change anything if the language code is faulty
  }
  state.legacyLanguageCode = action.payload.languageCode;
}
