import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { ILiveSheetState } from './ILiveSheetState';
import { LayerType } from './LayersState';

export interface ISetImageDimensionsActionPayload {
  /**
   * The id of the image layer
   */
  id: string;

  /**
   * The width of the image after it has been loaded
   */
  width: number;

  /**
   * The height of the image after it has been loaded
   */
  height: number;
}

export function setImageDimensionsReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetImageDimensionsActionPayload>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (!layer || layer.type !== LayerType.Image) {
    return;
  }
  layer.width = action.payload.width;
  layer.height = action.payload.height;
  layer.hasDimensions = true;
  layer.display.width = layer.width;
  layer.display.height = layer.height;
  layer.updatedAt = Date.now();
}
