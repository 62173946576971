import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryRecord, IHistoryState } from './IHistoryState';

export interface IRecordActionPayload {
  /**
   * The target where this record was created
   */
  productId: string;

  /**
   * The record to store
   */
  record: IHistoryRecord;
}

/**
 * Writes a history record which belongs to a project.
 *
 * If the user restored back to a previous history index this will cause all subsequent entries to be discarded.
 * The new entry will always be appended.
 */
export function recordReducer(
  state: Draft<IHistoryState>,
  action: PayloadAction<IRecordActionPayload>,
) {
  const { productId, record } = action.payload;
  if (!state.byProductId[productId]) {
    state.byProductId[productId] = { records: [record], index: 0, savedAtIndex: 0 };
    return;
  }
  ++state.revision; // only write if page already exists (essentially ignores the initialization action)
  const page = state.byProductId[productId]!;
  page.records.splice(page.index + 1, page.records.length, record);
  page.index = page.records.length - 1;
}
