import React from 'react';
import styles from './Link.module.css';
import classNames from 'classnames';

export interface ILinkProps {
  href: string;
  target?: '_blank' | '_self';
  children: React.ReactNode;
  simple?: boolean;
}

export const Link: React.FC<ILinkProps> = ({
  href,
  target = '_blank',
  children,
  simple = false,
}) => {
  return (
    <a
      target={target}
      href={href}
      className={classNames(styles.wrapper, { [styles.underline]: !simple })}>
      {children}
    </a>
  );
};
