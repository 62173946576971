export function createObjectUrlFromFile(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (!reader.result) {
          reject(new Error('Could not read image'));
        } else {
          resolve(reader.result as string);
        }
      },
      false,
    );
    reader.readAsDataURL(file);
  });
}
