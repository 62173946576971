import { ILayer } from '../../layer/LayersState';
import { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { moveLayers, rotateLayers } from '../../layer/liveSheetSlice';
import { NumberInputWrapper } from '../../../uiComponents/NumberInputWrapper';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../uiComponents/Input';

export interface ISingleMoverProps {
  layer: ILayer;
}

export const SingleMover: FC<ISingleMoverProps> = ({ layer }) => {
  const { t } = useTranslation();
  const xRef = useRef<HTMLInputElement>(null);
  const yRef = useRef<HTMLInputElement>(null);
  const rotRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const onBlurXInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const x = Number.parseInt(e.target.value);
      if (Number.isNaN(x)) {
        return;
      }
      dispatch(moveLayers({ x, isRelative: false }));
    },
    [dispatch],
  );

  const onBlurYInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const y = Number.parseInt(e.target.value);
      if (Number.isNaN(y)) {
        return;
      }
      dispatch(moveLayers({ y, isRelative: false }));
    },
    [dispatch],
  );

  const onBlurRotInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const degrees = Number.parseInt(e.target.value);
      if (!Number.isNaN(degrees)) {
        dispatch(rotateLayers({ degrees, filter: { selected: true } }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (xRef.current) {
      xRef.current.value = Math.floor(layer.x).toString();
    }
    if (yRef.current) {
      yRef.current.value = Math.floor(layer.y).toString();
    }
    if (rotRef.current) {
      rotRef.current.value = layer.transform.rotate.toString();
    }
  }, [layer.x, layer.y, layer.transform.rotate, xRef, yRef, rotRef]);

  return (
    <Wrapper>
      <NumberInputWrapper>
        {t('Horizontal')}
        <Input
          type="number"
          step={1}
          ref={xRef}
          onBlur={onBlurXInput}
          defaultValue={Math.floor(layer.x)}
          addendum={t('Pixel')}
        />
      </NumberInputWrapper>
      <NumberInputWrapper>
        {t('Vertical')}
        <Input
          type="number"
          step={1}
          ref={yRef}
          onBlur={onBlurYInput}
          defaultValue={Math.floor(layer.y)}
          addendum={t('Pixel')}
        />
      </NumberInputWrapper>
      <NumberInputWrapper>
        {t('Rotation')}
        <Input
          type="number"
          step={1}
          min={0}
          max={360}
          ref={rotRef}
          onBlur={onBlurRotInput}
          defaultValue={layer.transform.rotate}
          addendum={t('Degree')}
        />
      </NumberInputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  padding-right: 1rem;
  gap: 0.5rem;
`;
