import { ITreditionPalette } from '../palette/TreditionPalette';
import { IProductBarcode, IProductMeasures, IRegion } from './ProductState';

export type IApiRegion = IRegion;

export interface IApiSheet {
  id: string;

  objects: ITemplateObject[];
}

export interface IDesignProduct {
  id: string;

  type: number;

  regions: IApiRegion[];

  sheets: IApiSheet[];

  measures: IProductMeasures;

  palette: ITreditionPalette | null;

  barcode: IProductBarcode | null;
}

export enum DesignProductType {
  None = 0,
  SoftCover = 1,
  HardCover = 2,
  Ebook = 3,
  LargePrint = 4,
}

export interface ITemplateObject {
  _id: string;
  objectType: TemplateObjectType;
  left: number;
  top: number;
  width: number;
  height: number;
  // these must be equal to the 'non-absolute' properties, but they are required otherwise stuff in the backend breaks :^)
  absoluteWidth: number;
  absoluteHeight: number;
  absoluteLeft: number;
  absoluteTop: number;
  zIndex: number;

  /**
   * Not sure about the purpose of this one and when it happens but this completely removes the
   * object from any kind of view
   */
  hidden: boolean;
  transformMatrix: string | null; // ex.: matrix(-0.999993, 0.00320082, -0.00320082, -0.999993,0,0)
  aspectRatio: number;
  backgroundColor: ITreditionColorDesc;
  blur: number;
  shadow: number;
  sepia: number;
  padding: number;

  /**
   * Note: for some reason 1 - 10, i.e. 1 is 10% and 10 is 100%
   */
  transparency: number;
  hue: number;
  grayScale: number;
  borderColor: ITreditionColorDesc;
  borderWidth: number;
}

export enum TextTemplateObjectAlignment {
  Top,
  Center,
  Bottom,
}

/**
 * Legacy color struct, can be converted to {@link IColor} using the {@link ColorCompat} converter
 */
export interface ITreditionColorDesc {
  /**
   * Non-zero based number referencing a palette field - 0 if no palette field is referenced
   */
  paletteNumber: number;

  /**
   * Color as a hex string (prefixed with '#')
   */
  color: string | null;
  /*
  These are frontend-only (color picker)
  colorIndex: number;
  darkness: number;
  brightness: number;
  transparencyIndex: number;
  */
}

export interface IImageTemplateObject extends ITemplateObject {
  strokeWidth: number;
  objectType: TemplateObjectType.Image;
  imageFilename: string;
  imageMode: TemplateObjectImageMode;
  innerSize: number;
  innerRotationDegrees: number;
  replacementFillColors: IReplacementColor[];
  replacementStrokeColors: IReplacementColor[];
}

export interface IReplacementColor {
  originalColor: string;
  newColor: ITreditionColorDesc;
  /*
  These are frontend-only or leftovers from previous implementations which are not needed server-side
  hasNewColor: true;
  number: 0;
  */
}

export interface ITextTemplateObject extends ITemplateObject {
  text: string | null;
  /**
   * If the `text` is empty the current cover designer iterates this list to find a potential placeholder to display
   */
  placeholderDescriptions: {
    languageCode: string;
    text: string;
    // note: string | null // unused
  }[];
  fontName?: string;
  fontSize: number;
  justification: TemplateObjectJustificationType;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  uppercase: boolean;
  rotateForBookBack: boolean;
  textColor: ITreditionColorDesc;
  textBackgroundColor: ITreditionColorDesc;
  alignment: TextTemplateObjectAlignment;
  /**
   * Not sure why but the value is multiplied by 1.2 when applying the CSS attribute in the legacy customer
   */
  lineHeight: number;

  /**
   * This is a percentage value, i.e. 100 → 100%
   */
  charSpacing: number;
}

export enum TemplateObjectJustificationType {
  None,
  Left,
  Center,
  Right,
  Block,
}

export type IBarcodeTemplateObject = ITemplateObject;

export enum TemplateObjectType {
  Image = 0,
  Text = 1,
  Placeholder = 2,
  Barcode = 3,
  TitleTextField = 4,
  SubtitleTextField = 5,
  AuthorTextField = 6,
  AuthorProfileTextField = 7,
  ImprintTextField = 9,
  Box = 11,
}

export enum TemplateObjectImageMode {
  FreeScale,
  Proportional,
  Repeat,
}
