import { FC, useEffect, useReducer } from 'react';
import styled from 'styled-components/macro';
import { useActiveProduct } from '../../project/productSelectors';
import { px } from '../../../lib/px';
import { useAppDispatch } from '../../../redux/hooks';
import { useDesignerInput } from '../../input/inputSelectors';
import { IPoint } from '../../../../@types/IPoint';
import {
  BarcodeExtensionActionType,
  barcodeExtensionInitializer,
  barcodeExtensionReducer,
} from './useBarcodeExtensionState';
import { RegionId } from '../../project/ProductState';
import { useTreditionApiUrl } from '../../../config/configSelectors';
import { ISize } from '../../../../@types/ISize';
import { repositionBarcode } from '../../project/projectSlice';
import { isPointEqual } from '../../../lib/isPointEqual';
import { invalidateSaveIndices } from '../../history/historySlice';

export const BarcodeExtension: FC = () => {
  const reduxDispatch = useAppDispatch();
  const product = useActiveProduct();
  const input = useDesignerInput();
  const apiUrl = useTreditionApiUrl();
  const [state, dispatch] = useReducer(
    barcodeExtensionReducer,
    barcodeExtensionInitializer(product),
  );

  useEffect(() => {
    // reinitialize boundary based on product
    dispatch({ type: BarcodeExtensionActionType.CreateBoundary, product });
  }, [dispatch, product]);

  useEffect(() => {
    // move cancelling
    if (!state.isMoving) {
      return;
    }

    function onUp() {
      dispatch({ type: BarcodeExtensionActionType.StopMoving });
    }

    document.addEventListener('pointerup', onUp);
    return () => {
      document.removeEventListener('pointerup', onUp);
    };
  }, [state.isMoving, dispatch]);

  useEffect(() => {
    // move based on designer input
    dispatch({ type: BarcodeExtensionActionType.Move, pointerPos: input.mouse.sheet });
  }, [dispatch, input]);

  useEffect(() => {
    // persist to project after move is complete
    if (
      !state.isMoving &&
      product &&
      product.barcode &&
      !isPointEqual(product.barcode, state.position)
    ) {
      reduxDispatch(repositionBarcode(state.position));
      // the barcode is not a layer so the history does not track it
      // this is a workaround so a project will be considered unsaved if the barcode was moved
      reduxDispatch(invalidateSaveIndices());
    }
  }, [reduxDispatch, state.isMoving, state.position, product]);

  if (!product || !product.regions.some(($) => $.id === RegionId.CoverBack)) {
    return null;
  }
  return (
    <Barcode
      imageUrl={`${apiUrl}product/${product.id}/barcode`}
      size={state.size}
      position={state.position}
      onPointerDown={(e) => {
        if (e.button !== 0) {
          return;
        }
        e.stopPropagation();
        dispatch({
          type: BarcodeExtensionActionType.StartMoving,
          pointerPos: input.mouse.sheet,
        });
      }}
    />
  );
};

export interface IBarcodeDiv {
  size: ISize;
  position: IPoint;
  imageUrl: string;
}

const Barcode = styled.div.attrs<IBarcodeDiv>(
  ({ size: { width, height }, position: { x, y }, imageUrl }) => ({
    style: {
      position: 'absolute',
      left: px(x),
      top: px(y),
      width: px(width),
      height: px(height),
      backgroundColor: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'center',
      backgroundImage: `url(${imageUrl})`,
    },
  }),
)<IBarcodeDiv>`
  cursor: move;
`;
