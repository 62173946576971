import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';

export function setActiveProductReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<string>,
) {
  if (state?.products.some(($) => $.id === action.payload)) {
    state.activeProduct = action.payload;
  }
}
