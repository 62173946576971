import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import styles from './FormatPage.module.css';
import {
  formatPageSchema,
  FormatProductTypeSchema,
  FormatSetting,
  IFormatPageValueType,
} from './FormatPage.schema';
import { useGetFormatAndTypeQuery, useSaveFormatAndTypeMutation } from '../../api/treditionApi';
import { ProductType } from './constants/ProductType';
import { FormatPageForm } from './FormatPageForm';
import { IRawFormatResponse } from './interfaces/IRawFormatResponse';
import { IFormatPageRequest } from './interfaces/IFormatPageRequest';
import { useFormikStep } from '../useFormikStep';
import { IEditorStepPageProps } from '../getStepComponent';
import { LazyLoader } from '../../loader/LazyLoader';
import {
  KnownMeasurements,
  MaxSizeOfMeasurements,
} from '../../measures/BookMeasurementProportions';

const defaultProductTypeData = Object.freeze<FormatProductTypeSchema>({
  enabled: false,
  missing: false,
  sizeInMM: { width: 0, height: 0 },
  format: FormatSetting.None,
  outdated: false,
});

const defaultInitialValues: IFormatPageValueType = {
  [ProductType.Ebook]: { ...defaultProductTypeData },
  [ProductType.HardCover]: { ...defaultProductTypeData },
  [ProductType.LargePrint]: { ...defaultProductTypeData },
  [ProductType.SoftCover]: { ...defaultProductTypeData },
};

const transformResponse = (data: IRawFormatResponse): IFormatPageValueType => {
  const createFormat = (
    product: Exclude<ProductType, ProductType.None>,
  ): FormatProductTypeSchema => {
    if (!data[product]) {
      return { ...defaultProductTypeData, missing: true };
    }
    return {
      missing: false,
      enabled: data[product].enabled,
      format: data[product].type,
      sizeInMM: {
        width: data[product].format.width,
        height: data[product].format.height,
      },
      outdated: data[product].outdated,
    };
  };

  const ebookFormat = createFormat(ProductType.Ebook);
  ebookFormat.sizeInMM = null;
  ebookFormat.format = FormatSetting.None;

  return {
    [ProductType.SoftCover]: createFormat(ProductType.SoftCover),
    [ProductType.HardCover]: createFormat(ProductType.HardCover),
    [ProductType.Ebook]: ebookFormat,
    [ProductType.LargePrint]: createFormat(ProductType.LargePrint),
  };
};

const transformRequest = (values: IFormatPageValueType, projectId: string): IFormatPageRequest => {
  const createFormat = (product: Exclude<ProductType, ProductType.None>) => {
    const { sizeInMM } = values[product];

    if (sizeInMM == null) {
      return {
        enabled: values[product].enabled,
        format: MaxSizeOfMeasurements[KnownMeasurements.DinA4],
      };
    }
    return {
      enabled: values[product].enabled,
      format: {
        width: sizeInMM.width,
        height: sizeInMM.height,
      },
    };
  };

  return {
    projectId,
    bookTypes: {
      [ProductType.SoftCover]: createFormat(ProductType.SoftCover),
      [ProductType.HardCover]: createFormat(ProductType.HardCover),
      [ProductType.LargePrint]: createFormat(ProductType.LargePrint),
      [ProductType.Ebook]: {
        enabled: values[ProductType.Ebook].enabled,
      },
    },
  };
};

export const FormatPage: FC<IEditorStepPageProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const [saveFormatAndTypeMutation] = useSaveFormatAndTypeMutation();
  const { data, isLoading } = useGetFormatAndTypeQuery({ projectId });
  const onSubmit = useCallback(
    async (values: IFormatPageValueType) => {
      if (!values) return false;
      const payload = transformRequest(values, projectId);
      await saveFormatAndTypeMutation(payload);
      return true;
    },
    [saveFormatAndTypeMutation, projectId],
  );

  const transformedData: IFormatPageValueType | null = useMemo(() => {
    if (!data) return null;
    return transformResponse(data);
  }, [data]);

  const { formik, editor } = useFormikStep({
    formikConfig: {
      initialValues: transformedData || defaultInitialValues,
      validationSchema: formatPageSchema,
      enableReinitialize: true,
    },
    onSubmit,
  });

  if (isLoading || !transformedData) return <LazyLoader />;

  return (
    <MainBookWizardLayout
      formik={{
        formikErrors: formik.errors,
        submitCount: formik.submitCount,
      }}
      infoText={t('FormatInfo')}
      headline={t('FormatTitle')}>
      <div className={styles.wrapper}>
        <FormatPageForm formik={formik} />
      </div>
    </MainBookWizardLayout>
  );
};
