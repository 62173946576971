import React, { useId } from 'react';
import styles from './CheckBox.module.css';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import { Label } from '../label/Label';
import classNames from 'classnames';

export interface ICheckBox {
  label: React.ReactNode;
  labelHidden?: boolean;
  disabled?: boolean;
  mood?: 'primary' | 'secondary';
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  value?: string;
  name?: string;
}

export enum CheckBoxMood {
  Primary = 'primary',
  Secondary = 'secondary',
}

const CheckBox = ({
  label,
  labelHidden,
  disabled,
  checked,
  mood = CheckBoxMood.Primary,
  children,
  value,
  name,
  ...rest
}: ICheckBox) => {
  const id = useId();

  return (
    <div className={styles.wrapper}>
      <input
        checked={checked}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        className={classNames(styles.input, styles[mood])}
        {...rest}
        type="checkbox"
      />
      <Label htmlFor={id} className={styles.label}>
        {children != null ? (
          children
        ) : labelHidden ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          label
        )}
      </Label>
    </div>
  );
};

export default CheckBox;
