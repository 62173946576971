import {
  FC,
  PointerEvent as ReactPointerEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { clamp } from '../../lib/clamp';
import { getCssRgba, IRGBA } from '../../lib/Color';

export interface IAlphaSliderProps {
  /**
   * Fired when the user changes the alpha
   */
  onChangeAlpha: (value: number) => void;

  /**
   * The RGBA value of the currently selected color
   */
  rgba: IRGBA;
}

/**
 * This component expects its value to be controlled from the parent component
 */
export const AlphaSlider: FC<IAlphaSliderProps> = ({ onChangeAlpha, rgba }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSliding, setIsSliding] = useState<boolean>(false);

  const onPointerDown = useCallback(
    (e: ReactPointerEvent<HTMLElement>) => {
      if (e.button === 0 && ref.current) {
        setIsSliding(true);
        onChangeAlpha(determineAlpha(e.clientY, ref.current));
      }
    },
    [onChangeAlpha],
  );

  useEffect(() => {
    if (!isSliding) {
      return;
    }

    function onPointerMove(e: PointerEvent) {
      if (ref.current) {
        onChangeAlpha(determineAlpha(e.clientY, ref.current));
      }
    }

    function onPointerUp() {
      setIsSliding(false);
    }

    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
    return () => {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [isSliding, onChangeAlpha]);

  const gradientTopColor = getCssRgba({ ...rgba, a: 1 });
  const gradientBottomColor = getCssRgba({ ...rgba, a: 0 });

  return (
    <div ref={ref} className="alpha-slider fill-checkerboard" onPointerDown={onPointerDown}>
      <div
        className="alpha-gradient"
        style={{
          background: `linear-gradient(to bottom, ${gradientTopColor} 0%, ${gradientBottomColor} 100%)`,
        }}
      />
      <div className="indicator" style={{ top: `${100 - rgba.a * 100}%` }} />
    </div>
  );
};

/**
 * Returns a value from 0 to 1 based on the pointer's y coordinate (clientY) and the slider's element position.
 */
function determineAlpha(pointerY: number, elem: HTMLElement): number {
  if (!elem) {
    return 0;
  }
  const rect = elem.getBoundingClientRect();
  return 1 - clamp(pointerY - rect.y, 0, rect.height) / rect.height;
}
