import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { IDeleteSheetActionPayload } from './projectActions';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';

export function deleteSheetReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IDeleteSheetActionPayload>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product) {
    return;
  }
  const { sheetIndex, nextActiveSheetId } = action.payload;
  const sheetToDelete = product.sheets[sheetIndex];
  if (!sheetToDelete) {
    return;
  }
  product.sheets.splice(sheetIndex, 1);
  // a new sheet id was explicitly mentioned which must be reassigned (it may be null)
  if (typeof nextActiveSheetId !== 'undefined') {
    product.activeSheetId = nextActiveSheetId;
  }
}
