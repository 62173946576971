import { AppThunk } from '../../redux/store';
import { setApplyId } from './templateSlice';
import { getLiveSheetSlice } from '../layer/liveSheetSelectors';
import { getActiveProduct } from '../project/productSelectors';

/**
 * Produces a new designer template state, writing the `applyId` and `confirm` flag accordingly
 * depending on whether the product design (merge of live sheet + persisted product) is considered
 * empty. If empty the {@link TemplateController} will not ask for confirmation before applying the
 * template.
 */
export const applyTemplateWithConfirm =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const liveSheet = getLiveSheetSlice(state);
    const product = getActiveProduct(state);
    if (!product) {
      return;
    }
    // check if the live sheet combined with the other sheets would produce an empty design (i.e. contain no layers)
    const confirm = Boolean(
      liveSheet.layers.length ||
        product.sheets.some(($) => !$.layers.length && $.id !== product.activeSheetId),
    );
    dispatch(setApplyId({ id, confirm }));
  };
