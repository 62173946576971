import { emptyArray } from '../lib/emptyArray';
import { IOption } from '../../@types/IOption';
import { CompareValuesFn, defaultCompareValues } from './compareValues';

/**
 * Derives a react-select value from a value and a list of options.
 *
 * These options must be flattened already. The flattened options should be
 * cached by a memo in the select component.
 */
export function deriveReactSelectValue<ValueType>(
  value: unknown,
  options: Readonly<IOption<unknown>[]>,
  isMulti: boolean,
  compareValues: CompareValuesFn<unknown> = defaultCompareValues,
): Readonly<IOption<unknown>[] | IOption<unknown>> | null | undefined {
  if (typeof value === 'undefined') {
    return undefined;
  }
  if (isMulti) {
    if (!Array.isArray(value) || !Array.isArray(options)) {
      return emptyArray;
    }
    return options.filter((o) => value.some((v) => compareValues(o.value, v)));
  }
  if (value === null || !Array.isArray(options)) {
    return null;
  }
  return options.find((o) => compareValues(o.value, value)) || null;
}
