import React from 'react';
import styles from './Label.module.css';

export interface ILabel {
  children: React.ReactNode;
  htmlFor?: string;
  className?: string;
  style?: React.CSSProperties;

  dangerouslySetInnerHTML?: boolean;
}

export const Label = ({
  children,
  className,
  htmlFor,
  style,
  dangerouslySetInnerHTML = false,
  ...rest
}: ILabel) => {
  return (
    <label {...rest} style={style} htmlFor={htmlFor} className={`${styles.wrapper} ${className}`}>
      {children}
    </label>
  );
};
