import React from 'react';
import { useSelectedLayers } from '../layer/liveSheetSelectors';
import './ContextMenu.css';
import { LayerType } from '../layer/LayersState';
import { TextLayerMenu } from './TextLayerMenu';
import { ImageLayerMenu } from './ImageLayerMenu';
import { LayerMenu } from './LayerMenu';
import styled from 'styled-components/macro';

export interface IContextMenuProps {}

export const LayerMenuComponents: { [k: string | number]: React.ElementType } = {
  [LayerType.Text]: TextLayerMenu,
  [LayerType.Image]: ImageLayerMenu,
};

export const ContextMenu: React.FC<IContextMenuProps> = () => {
  const selectedLayers = useSelectedLayers();
  const type = selectedLayers[0]?.type;
  const selectedLayersHaveSameType = selectedLayers?.every(($) => $.type === type);
  let MenuComponent = selectedLayersHaveSameType ? LayerMenuComponents[type] : undefined;
  if (!MenuComponent) {
    MenuComponent = LayerMenu;
  }
  return (
    <Wrapper role="menu" aria-roledescription="Adjust the currently selected layer">
      <MenuComponent layers={selectedLayers} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 2;
  height: 40px;
  padding: var(--spacing-small);
  background-color: white;
  border: 1px solid var(--color-border);
  border-top: none;
  display: flex;
  align-items: center;
`;
