import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { IActivateSheetActionPayload } from './projectActions';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';

export function activateSheetReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IActivateSheetActionPayload>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product) {
    return;
  }
  if (product.sheets.some(($) => $.id === action.payload.sheetId)) {
    product.activeSheetId = action.payload.sheetId;
  }
}
