import {
  Action,
  addListener as rtkAddListener,
  configureStore,
  createListenerMiddleware,
  ListenerEffect,
  ThunkAction,
  TypedAddListener,
} from '@reduxjs/toolkit';
import { IHistoryContext } from 'redux-first-history';
import { treditionApi } from '../api/treditionApi';
import { localeSlice } from '../locale/localeSlice';
import { previewerReducer } from '../textDesigner/redux/previewerSlice';
import { templateReducer } from '../textDesigner/redux/contentTemplateSlice';
import { manipulationMenuReducer } from '../textDesigner/redux/manipulationMenuSlice';
import { designerReducer } from '../designer/designerSlice';
import { configSlice } from '../config/configSlice';
import { nullRouterReducer } from '../router/nullRouterReducer';
import { authSlice } from '../auth/authSlice';
import { appSlice } from '../app/appSlice';
import { userSlice } from '../user/userSlice';

export interface ICreateStoreOptions {
  /**
   * If given, connects the react-router to the store. Expects react-router and redux-first-history to be installed
   */
  reduxHistoryContext?: IHistoryContext | null;
}

export const defaultCreateStoreOptions: ICreateStoreOptions = {
  reduxHistoryContext: null,
};

export function createStore(options: ICreateStoreOptions = defaultCreateStoreOptions) {
  const listenerMiddleware = createListenerMiddleware();

  const store = configureStore({
    reducer: {
      [appSlice.name]: appSlice.reducer,
      [authSlice.name]: authSlice.reducer,
      [configSlice.name]: configSlice.reducer,
      [treditionApi.reducerPath]: treditionApi.reducer,
      [localeSlice.name]: localeSlice.reducer,
      router: options.reduxHistoryContext
        ? options.reduxHistoryContext.routerReducer
        : nullRouterReducer,
      designer: designerReducer,
      [userSlice.name]: userSlice.reducer,
      // TEXT DESIGNER
      previewer: previewerReducer,
      template: templateReducer,
      manipulationMenu: manipulationMenuReducer,
    },
    middleware: (getDefaultMiddleware) => {
      const middlewares = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(listenerMiddleware.middleware);
      middlewares.push(treditionApi.middleware);
      if (options.reduxHistoryContext) {
        middlewares.push(options.reduxHistoryContext.routerMiddleware);
      }
      return middlewares;
    },
  });

  if (options.reduxHistoryContext) {
    options.reduxHistoryContext.createReduxHistory(store);
  }

  return store;
}

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppListenerEffect = ListenerEffect<Action<string>, RootState, AppDispatch>;

export const addListener = rtkAddListener as TypedAddListener<RootState, AppDispatch>;
