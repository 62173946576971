import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getLegacyLanguageCode } from '../locale/localeSelectors';
import { RootState } from '../redux/store';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getConfig } from '../config/configSelectors';
import { getJwt } from '../auth/authSelectors';

/**
 * OPTIMIZE: find a better way to have a configurable base url. As of writing this comment, this
 *           is the only presented solution.
 */
export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const baseUrl = getConfig(api.getState() as RootState).treditionServiceUrl;
  return createQueryFn(baseUrl)(args, api, extraOptions);
};

function createQueryFn(baseUrl: string) {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = getJwt(state);
      if (token) {
        headers.append('Authorization', `Bearer ${token}`);
      }
      headers.append('Accept', 'application/json');
      headers.append('X-LANG', getLegacyLanguageCode(state));
      return headers;
    },
  });
}
