import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface ISetZoomActionPayload {
  zoom: number;
}

export const setZoomReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetZoomActionPayload>,
) => {
  const { zoom } = action.payload;
  if (zoom <= 50 && zoom >= 200) {
    return state;
  }
  state.zoom = zoom;
};
