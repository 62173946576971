import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorContext } from '../EditorContext';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import { ContributorListEntry } from './ContributorListEntry';
import styles from './ContributorsPage.module.css';
import { NewContributorModal } from './NewContributorModal';
import { ContributorRole, IContributor } from './interfaces/IContributor';
import {
  useGetAllUserContributorsQuery,
  useGetProjectContributorsQuery,
  useLinkProjectContributorsMutation,
  useUnlinkProjectContributorMutation,
} from '../../api/treditionApi';
import { IUserContributor } from './interfaces/IUserContributor';
import { IProjectContributor } from './interfaces/IProjectContributor';
import { localeCompare } from '../../lib/localeCompare';
import { LazyLoader } from '../../loader/LazyLoader';
import { noop } from 'lodash';
import { emptyArray, ensureArray } from '../../lib/emptyArray';
import { Spacer } from '../../shared/spacer/Spacer';
import { BasicButton, ButtonType } from '../../buttons/basic/BasicButton';
import { AddContributorModal } from './AddContributorModal';
import { toast } from 'react-hot-toast';

enum ModalType {
  None,
  NewContributor,
  AddContributor,
}

const getUnselectedContributors = (
  allContributors: IUserContributor[] = [],
  projectContributors: IProjectContributor[] = [],
) => {
  if (!allContributors.length) return [];

  return allContributors.filter((contributor) => {
    const isContributorInProject = projectContributors.some(
      (projectContributor) =>
        projectContributor.profileId != null && projectContributor.profileId === contributor.id,
    );
    return !isContributorInProject;
  });
};

type ModalState = {
  contributor: IContributor | null;
  isValid: boolean;
};

export const ContributorsPage: FC = () => {
  const { registerStep } = useContext(EditorContext);
  const onSubmit = useCallback(async () => true, []);
  const [contributors, setContributors] = useState<IContributor[]>([]);

  const {
    state: { projectId },
  } = useContext(EditorContext);

  const [globalErrors, setGlobalErrors] = useState<string[] | null>(null);

  const { data: fetchedProjectContributors = [], isLoading: projectContributorsLoading } =
    useGetProjectContributorsQuery({
      projectId,
    });

  useEffect(() => {
    setContributors(fetchedProjectContributors);
  }, [fetchedProjectContributors]);

  const onValidate = useCallback(async () => {
    const hasAuthor = fetchedProjectContributors.some(
      (contributor) => contributor.role === ContributorRole.Author,
    );
    if (!hasAuthor) {
      setGlobalErrors(['AuthorMissing']);
    } else {
      setGlobalErrors(emptyArray);
    }
    return Promise.resolve(hasAuthor);
  }, [fetchedProjectContributors]);

  useEffect(() => {
    registerStep(onSubmit, onValidate, () => false);
  }, [registerStep, onSubmit, onValidate]);

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState<ModalType>(ModalType.None);

  const { data: userContributors, isLoading: userContributorsLoading } =
    useGetAllUserContributorsQuery({
      projectId,
    });

  const hasUserContributor = userContributors && userContributors.length > 0;

  const isLoadingSelect = [userContributorsLoading, projectContributorsLoading].some((v) => v);

  const [linkProjectContributors] = useLinkProjectContributorsMutation();

  const unselectedContributors = useMemo(
    () => getUnselectedContributors(userContributors, fetchedProjectContributors),
    [userContributors, fetchedProjectContributors],
  );

  const context = useContext(EditorContext);
  const {
    state: { isReadonly },
  } = context;

  const handleAddContributor = (contributorIds: string[]) => {
    setModalOpen(ModalType.None);
    setContributors((c) => [
      ...c,
      ...unselectedContributors.filter((c) => contributorIds.includes(c.id)),
    ]);
    linkProjectContributors({
      projectId,
      contributorIds,
    })
      .unwrap()
      .catch((error) => {
        toast(t('AddContributorError'));
        setContributors(fetchedProjectContributors);
      });
  };

  const [unlinkContributor] = useUnlinkProjectContributorMutation();

  const handleUnlinkContributor = (id: string) => {
    if (!id) return;
    setContributors((c) => c.filter((c) => 'profileId' in c && c.profileId !== id));
    unlinkContributor({ contributorId: id, projectId })
      .unwrap()
      .then(() => {
        toast(t('UnlinkContributorSuccess'));
      })
      .catch(() => {
        toast(t('UnlinkContributorError'));
        setContributors(fetchedProjectContributors);
      });
  };

  const Modal = () => (
    <>
      {modalOpen === ModalType.NewContributor && (
        <NewContributorModal
          onCancel={() => setModalOpen(ModalType.None)}
          onAddNewContributor={() => setModalOpen(ModalType.None)}
        />
      )}
      {modalOpen === ModalType.AddContributor && (
        <AddContributorModal
          unselectedContributors={[...unselectedContributors].sort((a, b) =>
            localeCompare(a.role, b.role),
          )}
          onCancel={() => setModalOpen(ModalType.None)}
          onAddContributor={handleAddContributor}
        />
      )}
    </>
  );

  return (
    <MainBookWizardLayout
      infoText={t('ProjectContributorInfo')}
      headline={t('ProjectContributorTitle')}
      customErrors={globalErrors}>
      {isLoadingSelect && <LazyLoader />}
      <div className={styles.wrapper}>
        <div className={styles.control}>
          <span className={styles.text}>{t('SelectContributors')}</span>
          {hasUserContributor && unselectedContributors.length > 0 && (
            <BasicButton
              disabled={isReadonly}
              label={t('AddContributor')}
              mood={ButtonType.Primary}
              onClick={isReadonly ? noop : () => setModalOpen(ModalType.AddContributor)}
            />
          )}
          <BasicButton
            disabled={isReadonly}
            mood={
              hasUserContributor && unselectedContributors.length > 0
                ? ButtonType.Secondary
                : ButtonType.Primary
            }
            label={t('CreateNewContributor')}
            onClick={isReadonly ? noop : () => setModalOpen(ModalType.NewContributor)}
          />
          {Modal()}
        </div>
        <Spacer size={16} />
        {fetchedProjectContributors?.length > 0 && (
          <>
            <h2
              style={{ justifySelf: 'left', fontWeight: 600, fontSize: 'var(--font-size-larger)' }}>
              {t('LinkedProfiles')}
            </h2>

            <div className={styles.container}>
              {[...contributors]
                .sort((a, b) => localeCompare(a.role, b.role))
                .map((contributor) => {
                  const profileId =
                    'profileId' in contributor
                      ? contributor.profileId
                      : 'id' in contributor
                      ? contributor.id
                      : null;
                  if (!profileId) return null;
                  return (
                    <ContributorListEntry
                      onUnlinkContributor={handleUnlinkContributor}
                      isReadonly={isReadonly}
                      contributor={contributor}
                      key={profileId.toString()}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    </MainBookWizardLayout>
  );
};
