import { FormattingEnumeration } from './Formatting';

export enum FooterFormEnumeration {
  FOOTER = 'footer',
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  VIEW = 'view',
  SEPARATOR = 'separator',
  DISTANCE_TEXT = 'distance-text',
  DISTANCE_FOOTER = 'distance-footer',
  FOOTER_CONTENT = 'footer-content',
  FOOTER_CONTENT_INNER = 'footer-content-inner',
  FOOTER_CONTENT_OUTER = 'footer-content-outer',
  FONT_FAMILY_INNER = 'font-family-inner',
  FONT_SIZE_INNER = 'font-size-inner',
  BOLD_INNER = 'bold-inner',
  ITALIC_INNER = 'italic-inner',
  UNDERLINED_INNER = 'underlined-inner',
  FONT_FAMILY_OUTER = 'font-family-outer',
  FONT_SIZE_OUTER = 'font-size-outer',
  BOLD_OUTER = 'bold-outer',
  ITALIC_OUTER = 'italic-outer',
  UNDERLINED_OUTER = 'underlined-outer',
  FREE_TEXT = 'free-text',
  FREE_TEXT_INNER = 'free-text-inner',
  FREE_TEXT_OUTER = 'free-text-outer',
}

export type IFooterForm = {
  [FooterFormEnumeration.FOOTER]: boolean;
  [FooterFormEnumeration.FONT_FAMILY]: string;
  [FooterFormEnumeration.FONT_FAMILY_INNER]: string;
  [FooterFormEnumeration.FONT_FAMILY_OUTER]: string;
  [FooterFormEnumeration.FONT_SIZE]: number;
  [FooterFormEnumeration.FONT_SIZE_INNER]: number;
  [FooterFormEnumeration.FONT_SIZE_OUTER]: number;
  [FooterFormEnumeration.BOLD]: boolean;
  [FooterFormEnumeration.BOLD_INNER]: boolean;
  [FooterFormEnumeration.BOLD_OUTER]: boolean;
  [FooterFormEnumeration.ITALIC]: boolean;
  [FooterFormEnumeration.ITALIC_INNER]: boolean;
  [FooterFormEnumeration.ITALIC_OUTER]: boolean;
  [FooterFormEnumeration.UNDERLINED]: boolean;
  [FooterFormEnumeration.UNDERLINED_INNER]: boolean;
  [FooterFormEnumeration.UNDERLINED_OUTER]: boolean;
  [FooterFormEnumeration.FOOTER_CONTENT]: string;
  [FooterFormEnumeration.FOOTER_CONTENT_INNER]: string;
  [FooterFormEnumeration.FOOTER_CONTENT_OUTER]: string;
  [FooterFormEnumeration.VIEW]: string;
  [FooterFormEnumeration.SEPARATOR]: boolean;
  [FooterFormEnumeration.DISTANCE_TEXT]: number;
  [FooterFormEnumeration.DISTANCE_FOOTER]: number;
  [FooterFormEnumeration.FREE_TEXT]: string;
  [FooterFormEnumeration.FREE_TEXT_INNER]: string;
  [FooterFormEnumeration.FREE_TEXT_OUTER]: string;
};

type IFooterFormatting = {
  [key in FormattingEnumeration]: string;
};

export const footerFormatting: IFooterFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: FooterFormEnumeration.FONT_FAMILY,
  [FormattingEnumeration.FONT_SIZE]: FooterFormEnumeration.FONT_SIZE,
  [FormattingEnumeration.BOLD]: FooterFormEnumeration.BOLD,
  [FormattingEnumeration.ITALIC]: FooterFormEnumeration.ITALIC,
  [FormattingEnumeration.UNDERLINED]: FooterFormEnumeration.UNDERLINED,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};

export const footerFormattingInner: IFooterFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: FooterFormEnumeration.FONT_FAMILY_INNER,
  [FormattingEnumeration.FONT_SIZE]: FooterFormEnumeration.FONT_SIZE_INNER,
  [FormattingEnumeration.BOLD]: FooterFormEnumeration.BOLD_INNER,
  [FormattingEnumeration.ITALIC]: FooterFormEnumeration.ITALIC_INNER,
  [FormattingEnumeration.UNDERLINED]: FooterFormEnumeration.UNDERLINED_INNER,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};

export const footerFormattingOuter: IFooterFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: FooterFormEnumeration.FONT_FAMILY_OUTER,
  [FormattingEnumeration.FONT_SIZE]: FooterFormEnumeration.FONT_SIZE_OUTER,
  [FormattingEnumeration.BOLD]: FooterFormEnumeration.BOLD_OUTER,
  [FormattingEnumeration.ITALIC]: FooterFormEnumeration.ITALIC_OUTER,
  [FormattingEnumeration.UNDERLINED]: FooterFormEnumeration.UNDERLINED_OUTER,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};
