import * as yup from 'yup';
import { ContributorRole, TitleFromApi } from './interfaces/IContributor';
import { oneOfEnum } from '../format/FormatPage.schema';
import { TFunction } from 'react-i18next';

export const createContributorSchema = (t: TFunction) =>
  yup.object().shape({
    role: oneOfEnum(ContributorRole).optional(),
    isPerson: yup.boolean().defined().required(),
    title: oneOfEnum(TitleFromApi),
    firstName: yup.string(),
    lastName: yup.string().when('isPerson', {
      is: true,
      then: () => yup.string().defined().required().min(1).max(100),
      otherwise: () => yup.string().optional(),
    }),
    companyName: yup.string().when('isPerson', {
      is: false,
      then: () => yup.string().defined().required().min(1).max(200),
      otherwise: () => yup.string().optional(),
    }),
    website: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        t('ValidationUrl'),
      ),
    additionalDescription: yup.string().when('role', {
      is: ContributorRole.FreeText,
      then: () => yup.string().defined().required(),
      otherwise: () => yup.string().optional(),
    }),
  });
