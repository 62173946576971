import { createSlice } from '@reduxjs/toolkit';
import { ICoverTemplateState } from './ContentTemplateState';
import { setContentTemplateReducer } from './setContentTemplateReducer';
import { formDefaultValues } from '../components/form_constant/formDefaultValues';

export const createInitialTemplateState = (): ICoverTemplateState => ({
  selectedTemplate: 'default',
  templateLabel: 'Default',
  templateValues: formDefaultValues,
});

export const contentTemplateSlice = createSlice({
  name: 'theme',
  initialState: Object.freeze(createInitialTemplateState()),
  reducers: {
    setContentTemplate: setContentTemplateReducer,
  },
});

export const { setContentTemplate } = contentTemplateSlice.actions;

export const templateReducer = contentTemplateSlice.reducer;
