import { CSSProperties, FC, Fragment, ReactElement, useMemo } from 'react';
import { IRegion, RegionId } from '../project/ProductState';
import { BleedLine } from './BleedLine';
import { SheetRegion } from './SheetRegion';
import { useSnap } from '../layer/liveSheetSelectors';
import { SnapLines } from './SnapLines';
import { px } from '../../lib/px';
import { useGrid } from '../view/viewSelectors';
import { DesignerMode } from '../designerMode';
import { CoverDesignerRegions } from './CoverDesignerRegions';
import { useTranslation } from 'react-i18next';

export interface ISheetScaffold {
  /**
   * Whether the sheet is currently active
   */
  isActive: boolean;

  /**
   * Index of the sheet owning this scaffold
   */
  index: number;

  /**
   * Number of sheets in the product
   */
  sheetCount: number;

  /**
   * Bleed as taken from the product
   */
  bleed: number;

  /**
   * Regions defined by the product
   */
  regions: IRegion[];

  /**
   * Current designer mode which will determine how the scaffold will be rendered
   */
  mode: DesignerMode;
}

// REFACTOR: too complex

export const SheetScaffold: FC<ISheetScaffold> = ({
  bleed,
  isActive,
  mode,
  regions,
  index,
  sheetCount,
}) => {
  const { t } = useTranslation();
  const grid = useGrid();
  const snap = useSnap();
  const snapLines = useMemo(() => {
    return isActive && !grid.isEnabled ? <SnapLines snap={snap} /> : null;
  }, [isActive, snap, grid.isEnabled]);

  const scaffoldStyle = useMemo<CSSProperties | undefined>(() => {
    if (!grid.isEnabled) {
      return undefined;
    }
    const bgImage: string[] = [];
    if (grid.size.width > 1) {
      bgImage.push('repeating-linear-gradient(90deg, #0001 0 1px, transparent 1px 100%)');
    }
    if (grid.size.height > 1) {
      bgImage.push('repeating-linear-gradient(#0001 0 1px, transparent 1px 100%)');
    }
    return {
      backgroundImage: bgImage.join(','),
      backgroundSize: `${px(grid.size.width)} ${px(grid.size.height)}`,
    };
  }, [grid]);

  return useMemo(() => {
    if (mode === DesignerMode.FrontMatter) {
      // special double-page representation where each page (region) has its own bleed line
      return (
        <div className="sheet-scaffold" style={scaffoldStyle}>
          {regions.map((region, regionIndex) => {
            const isFirstLeftPage = region.id === RegionId.PageLeft && index === 0;
            const isLastRightPage = region.id === RegionId.PageRight && index + 1 === sheetCount;

            let content: ReactElement;

            if (isFirstLeftPage) {
              content = <span className="region-text">{t('CoverInnerText')}</span>;
            } else if (isLastRightPage) {
              content = <span className="region-text">{t('FirstPageOfContent')}</span>;
            } else {
              content = (
                <Fragment>
                  <BleedLine bleed={bleed} />
                  <span className="region-label">
                    {t('Page') + ' ' + (index * 2 + regionIndex)}
                  </span>
                </Fragment>
              );
            }

            return (
              <SheetRegion
                key={region.id}
                region={region}
                className={isFirstLeftPage || isLastRightPage ? 'blocked' : undefined}>
                {content}
              </SheetRegion>
            );
          })}
          {snapLines}
        </div>
      );
    }
    return (
      <div className="sheet-scaffold" style={scaffoldStyle}>
        <CoverDesignerRegions />
        <BleedLine bleed={bleed} />
        {snapLines}
      </div>
    );
  }, [mode, bleed, regions, index, sheetCount, snapLines, scaffoldStyle, t]);
};
