/**
 * TODO: this should be part of the regular config instead of a host name lookup
 */
export const getEnvironmentConfig = () => {
  const { host } = window.location;
  switch (host) {
    case 'my.tredition.com':
    case 'manager.tredition.com': {
      return {
        environment: 'production',
        tunnel: 'https://my.tredition.com/tunnel',
      };
    }
    case 'my.qa.tredition.it':
    case 'manager.qa.tredition.it': {
      return {
        environment: 'qa',
        tunnel: 'https://my.qa.tredition.it/tunnel',
      };
    }
    case 'my.staging.tredition.it':
    case 'manager.staging.tredition.it': {
      return {
        environment: 'staging',
        tunnel: 'https://my.staging.tredition.it/tunnel',
      };
    }
    default: {
      return {
        environment: 'development',
        tunnel: undefined,
      };
    }
  }
};
