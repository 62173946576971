import { useReducer } from 'react';
import { ICMYK, IHSV, IRGBA, Rgba } from '../../lib/Color';
import { hsvToRgb } from '../../lib/hsvToRgb';
import { rgbToCmyk } from '../../lib/rgbToCmyk';
import { cmykToRgb } from '../../lib/cmykToRgb';
import { rgbToHsv } from '../../lib/rgbToHsv';
import { hexStringToRgba } from '../../lib/hexToRgba';
import { rgbToHex } from '../../lib/rgbToHex';
import { getRgbaColorAt, ITreditionPalette } from './TreditionPalette';
import { IColor } from './IColor';

export enum ColorPickerActionType {
  SetHue,
  PickColor,
  SetAlpha,
  SetCmyk,
  SetRgba,
  SetHsv,
  SetHex,
  SetFromPalette,
}

export interface ISetHueAction {
  type: ColorPickerActionType.SetHue;

  /**
   * Hue value ranging from 0 to 1
   */
  hue: number;
}

/**
 * Note: as the color picker itself represents the HSV model, the
 *       saturation will be the horizontal coordinate (0..1)
 *       while the value / brightness will be the vertical coordinate (0..1)
 *       in the color gradient.
 */
export interface IPickColorAction {
  type: ColorPickerActionType.PickColor;

  /**
   * The S in HSV
   */
  saturation: number;

  /**
   * The V in HSV or B in HSB
   */
  value: number;
}

export interface ISetAlphaAction {
  type: ColorPickerActionType.SetAlpha;

  /**
   * The alpha value ranging from 0 to 1
   */
  alpha: number;
}

export interface ISetRgbaAction {
  type: ColorPickerActionType.SetRgba;

  /**
   * The new color as RGBA
   */
  rgba: IRGBA;
}

export interface ISetCmykAction {
  type: ColorPickerActionType.SetCmyk;

  /**
   * The new color as CMYK
   */
  cmyk: ICMYK;
}

export interface ISetHsvAction {
  type: ColorPickerActionType.SetHsv;

  /**
   * The new color as HSV
   */
  hsv: IHSV;
}

export interface ISetHexAction {
  type: ColorPickerActionType.SetHex;

  /**
   * The user-provided hex string
   */
  hex: string;
}

export interface ISetFromPaletteAction {
  type: ColorPickerActionType.SetFromPalette;

  /**
   * The index of the currently stored palette
   */
  paletteIndex: number;
}

export type IColorPickerActionUnion =
  | ISetHueAction
  | IPickColorAction
  | ISetAlphaAction
  | ISetRgbaAction
  | ISetCmykAction
  | ISetHsvAction
  | ISetHexAction
  | ISetFromPaletteAction;

export interface IColorPickerState {
  /**
   * RGBA values ranging from 0 to 1
   */
  rgba: IRGBA;

  /**
   * HSV values ranging from 0 to 1
   */
  hsv: IHSV;

  /**
   * CMYK values ranging from 0 to 1
   */
  cmyk: ICMYK;

  /**
   * The value of the hex input field.
   *
   * NOTE: might be invalid and not produce a valid color! This is intentional.
   *       to get rid of this detail, you would need to store the hex input value
   *       separately from the actual valid hex color.
   */
  hex: string;

  /**
   * A palette with additional colors the user can choose from. If not given the palette picker will not be displayed.
   */
  palette: ITreditionPalette | null;

  /**
   * If not null the currently active color state was taken from the {@link palette}. This information is
   * passed to the {@link OnPickFn}.
   */
  paletteIndex: number | null;
}

export interface IColorPickerInitializerOptions {
  /**
   * Optional initial color
   */
  color?: IColor | null;

  /**
   * Optional palette
   */
  palette?: ITreditionPalette | null;
}

const defaultColorPickerInitializerOptions = Object.freeze({
  color: null,
  palette: null,
});

export function colorPickerInitializer(
  options: IColorPickerInitializerOptions = defaultColorPickerInitializerOptions,
): IColorPickerState {
  let initialColor: IColor = { rgba: Rgba.black(), paletteIndex: null };
  if (options.color && options.color.rgba) {
    initialColor = options.color;
  }
  return {
    rgba: initialColor.rgba,
    hsv: rgbToHsv(initialColor.rgba),
    cmyk: rgbToCmyk(initialColor.rgba),
    hex: rgbToHex(initialColor.rgba),
    palette: options.palette || null,
    paletteIndex: initialColor.paletteIndex,
  };
}

export function colorPickerReducer(
  state: IColorPickerState,
  action: IColorPickerActionUnion,
): IColorPickerState {
  switch (action.type) {
    case ColorPickerActionType.SetHue: {
      const hsv: IHSV = { h: action.hue, s: state.hsv.s, v: state.hsv.v };
      const rgba = { ...hsvToRgb(hsv), a: state.rgba.a };
      return {
        ...state,
        hsv,
        rgba,
        cmyk: rgbToCmyk(rgba),
        hex: rgbToHex(rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetAlpha: {
      const rgba = { ...state.rgba, a: action.alpha };
      return {
        ...state,
        rgba,
        hex: rgbToHex(rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.PickColor: {
      const hsv: IHSV = { h: state.hsv.h, s: action.saturation, v: action.value };
      const rgba = { ...hsvToRgb(hsv), a: state.rgba.a };
      return {
        ...state,
        hsv,
        rgba,
        cmyk: rgbToCmyk(rgba),
        hex: rgbToHex(rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetRgba: {
      return {
        ...state,
        hsv: rgbToHsv(action.rgba),
        rgba: action.rgba,
        cmyk: rgbToCmyk(action.rgba),
        hex: rgbToHex(action.rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetHsv: {
      const rgba = { ...hsvToRgb(action.hsv), a: state.rgba.a };
      return {
        ...state,
        hsv: action.hsv,
        rgba,
        cmyk: rgbToCmyk(rgba),
        hex: rgbToHex(rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetCmyk: {
      const rgba = { ...cmykToRgb(action.cmyk), a: state.rgba.a };
      return {
        ...state,
        hsv: rgbToHsv(rgba),
        rgba,
        cmyk: action.cmyk,
        hex: rgbToHex(rgba),
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetHex: {
      const rgba = hexStringToRgba(action.hex);
      if (!rgba) {
        return {
          ...state,
          // only update the string but not the colors, this is important!
          // we allow entering invalid hex strings otherwise the
          // UX will suffer
          hex: action.hex,
        };
      }
      return {
        ...state,
        hex: action.hex,
        cmyk: rgbToCmyk(rgba),
        hsv: rgbToHsv(rgba),
        rgba,
        paletteIndex: null,
      };
    }
    case ColorPickerActionType.SetFromPalette: {
      if (!state.palette || action.paletteIndex === null) {
        return state;
      }
      const rgba = getRgbaColorAt(state.palette, action.paletteIndex);
      if (!rgba) {
        return state;
      }
      return {
        ...state,
        rgba,
        cmyk: rgbToCmyk(rgba),
        hsv: rgbToHsv(rgba),
        hex: rgbToHex(rgba),
        paletteIndex: action.paletteIndex,
      };
    }
    default:
      return state;
  }
}

export function useColorPickerState(
  options: IColorPickerInitializerOptions = defaultColorPickerInitializerOptions,
) {
  return useReducer(colorPickerReducer, colorPickerInitializer(options));
}
