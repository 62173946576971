import { IPoint } from '../../@types/IPoint';
import { modDeg } from './modDeg';
import { radToDeg } from './radToDeg';

/**
 * Returns the counter-clockwise intersection angle `φ` of a line represented by the points `a` & `b` and the x-axis.
 *
 * The angle is returned in degrees from 0 to 359.
 *
 * ```
 *           b
 *         /
 *       /
 *     /
 *   / φ
 * a--------------> x-axis
 * ```
 */
export function pointDirection(a: IPoint, b: IPoint): number {
  return modDeg(-radToDeg(Math.atan2(b.y - a.y, b.x - a.x)));
}
