export enum PageBreakEnumeration {
  NO_PAGE_BREAK = 'none',
  NEXT_PAGE = 'page',
  NEXT_LEFT_PAGE = 'left',
  NEXT_RIGHT_PAGE = 'right',
}

export const PageBreakOptions = [
  {
    value: PageBreakEnumeration.NO_PAGE_BREAK,
    label: 'PageBreakNone',
  },
  {
    value: PageBreakEnumeration.NEXT_PAGE,
    label: 'PageBreakNextPage',
  },
  {
    value: PageBreakEnumeration.NEXT_LEFT_PAGE,
    label: 'PageBreakNextLeftPage',
  },
  {
    value: PageBreakEnumeration.NEXT_RIGHT_PAGE,
    label: 'PageBreakNextRightPage',
  },
];
