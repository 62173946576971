import { useReducer } from 'react';

export interface IUploaderState {
  /**
   * Whether the uploader is currently busy uploading
   */
  isUploading: boolean;

  /**
   * The current file selected by the user
   */
  file: File | null;

  /**
   * The current error state (upload or pre-upload)
   */
  error: Error | null;
  preview: string | null;
}

export enum UploaderActionType {
  SetFile,
  BeginUpload,
  EndUpload,
}

/**
 * Assigns the File selected by the user to the state. May also be used
 * to clear the file.
 */
export interface ISetFileAction {
  type: UploaderActionType.SetFile;

  /**
   * File selected by the user or `null` to clear the file
   */
  file: File | null;
}

/**
 * Signals the upload as started
 */
export interface IBeginUploadAction {
  type: UploaderActionType.BeginUpload;
}

/**
 * Signals the upload as completed
 */
export interface IEndUploadAction {
  type: UploaderActionType.EndUpload;

  /**
   * The error in case upload failed
   */
  error: Error | null;
}

export type IUploaderAction = ISetFileAction | IBeginUploadAction | IEndUploadAction;

export function uploaderReducer(state: IUploaderState, action: IUploaderAction): IUploaderState {
  switch (action.type) {
    case UploaderActionType.SetFile:
      return {
        ...state,
        file: action.file,
        preview: action.file ? URL.createObjectURL(action.file) : null,
      };
    case UploaderActionType.BeginUpload:
      return { ...state, isUploading: true };
    case UploaderActionType.EndUpload:
      return {
        ...state,
        isUploading: false,
        error: action.error,
        file: action.error ? state.file : null,
      };
    default:
      return state;
  }
}

export function uploaderInitializer(): IUploaderState {
  return {
    file: null,
    isUploading: false,
    error: null,
    preview: null,
  };
}

export const useUploaderState = () => {
  return useReducer(uploaderReducer, uploaderInitializer());
};
