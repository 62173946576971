import { ICSSEnumeration, ICSSObject } from '../components/form_constant/CSSObject';
import { TableOfContentFormEnumeration } from '../components/form_constant/TableOfContentForm';
import { HeadlineFormEnumeration } from '../components/form_constant/HeadlineForm';
import { BodyTextFormEnumeration } from '../components/form_constant/BodyTextForm';
import { HeaderFormEnumeration } from '../components/form_constant/HeaderForm';
import { FooterFormEnumeration } from '../components/form_constant/FooterForm';
import { PageMarginsFormEnumeration } from '../components/form_constant/PageMarginsForm';

export interface IHistoryEntry {
  menu: string | number;
  subMenu: string | number;
  oldValue: string | number;
  newValue: string | number;
}

export type ICssSubset = {
  [attr in keyof ICSSObject]: ICSSObject[attr] extends object
    ? {
        [key: string]: string | boolean | number;
      }
    : ICSSObject[attr];
};

const translate = (value: number | string | boolean) => {
  if (typeof value === 'number') {
    return value as number;
  }

  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  if (!isNaN(Number(value)) && value !== '') {
    return value;
  }
  return value;
};

export const historyDiffToString = (
  diffValues: ICssSubset,
  previousFormValues: ICssSubset,
): IHistoryEntry[] => {
  const menusChanged = Object.keys(diffValues) as ICSSEnumeration[];
  return menusChanged
    .map((menuKey) => {
      const subMenuChanged = Object.keys(diffValues[menuKey]) as
        | TableOfContentFormEnumeration[]
        | HeaderFormEnumeration[]
        | HeadlineFormEnumeration[]
        | FooterFormEnumeration[]
        | BodyTextFormEnumeration[]
        | PageMarginsFormEnumeration[];
      return subMenuChanged.map((subMenu) => {
        return {
          menu: translate(menuKey) as string,
          subMenu: translate(subMenu) as string,
          oldValue: translate(previousFormValues[menuKey][subMenu]),
          newValue: translate(diffValues[menuKey][subMenu]),
        };
      });
    })
    .flat(1);
};
