import { RootState } from '../redux/store';
import { configSlice } from './configSlice';
import { useAppSelector } from '../redux/hooks';

/**
 * Returns the entire config state slice which is the current redux configuration
 */
export const getConfig = (state: RootState) => state[configSlice.name];

/**
 * Returns the configured tredition api url
 */
export const getTreditionApiUrl = (state: RootState) => getConfig(state).treditionServiceUrl;

/**
 * Returns the configured blob url
 */
export const getBlobUrl = (state: RootState) => getConfig(state).blobUrl;

/**
 * Provides the configured tredition api url to a React component
 */
export const useTreditionApiUrl = () => useAppSelector(getTreditionApiUrl);

/**
 * Provides the configured blob url to a React component
 */
export const useBlobUrl = () => useAppSelector(getBlobUrl);
