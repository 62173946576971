import { IColor } from '../../palette/IColor';
import { IReplacementColor, ITreditionColorDesc } from '../IDesignProduct';
import { IRGBA, Rgba } from '../../../lib/Color';
import { hexStringToRgba } from '../../../lib/hexToRgba';
import { rgbToHex } from '../../../lib/rgbToHex';
import { IImageLayerReplacement, ImageLayerReplacementTarget } from '../../layer/LayersState';

/**
 * Provides color conversion methods from and to the legacy format.
 *
 * @see IColor
 * @see ITreditionColorDesc
 */
export abstract class ColorCompat {
  /**
   * Converts the legacy {@link ITreditionColorDesc} format to the new format {@link IColor}
   *
   * Returns null if the given color desc is null or the color desc has no color
   */
  public static colorDescToColor(colorDesc: ITreditionColorDesc | null): IColor | null {
    if (!colorDesc || !colorDesc.color) {
      return null;
    }
    // We have two options if rgba parsing fails: either discard the color entirely and lose palette information
    // or render the text in black but keep the palette info. I think the latter is preferable
    // as it is not as much as a data loss than the former.
    const paletteIndex = this.paletteNumberToIndex(colorDesc.paletteNumber);
    const fallback: IRGBA = Rgba.black();
    let rgba: IRGBA | null;
    if (colorDesc.color === 'transparent') {
      rgba = Rgba.devoid();
    } else {
      rgba = hexStringToRgba(colorDesc.color);
    }
    if (!rgba) {
      return { rgba: fallback, paletteIndex };
    }
    return { rgba, paletteIndex };
  }

  /**
   * Converts the given {@link IColor} to the legacy {@link ITreditionColorDesc} format
   */
  public static colorToColorDesc(color: IColor | null): ITreditionColorDesc {
    const desc: ITreditionColorDesc = { color: null, paletteNumber: 0 };
    if (color) {
      desc.color = rgbToHex(color.rgba, true);
      desc.paletteNumber = ColorCompat.paletteIndexToNumber(color.paletteIndex);
    }
    return desc;
  }

  /**
   * Converts the legacy non-zero-based `paletteNumber` to a zero-based `paletteIndex`.
   *
   * Returns null if the paletteNumber is 0 which indicates `no palette index`, otherwise a zero-based index.
   */
  public static paletteNumberToIndex(paletteNumber: number): number | null {
    if (paletteNumber <= 0) {
      return null;
    }
    const index = paletteNumber - 1;
    return Number.isNaN(index) ? null : index;
  }

  /**
   * Converts the given zero-based `paletteIndex` to a non-zero-based `paletteNumber`.
   */
  public static paletteIndexToNumber(paletteIndex: number | null): number {
    if (paletteIndex === null || paletteIndex < 0) {
      return 0;
    }
    const number = paletteIndex + 1;
    return Number.isNaN(number) ? 0 : number;
  }

  /**
   * Converts a legacy {@link IReplacementColor} list to a {@link IImageLayerReplacement} list
   */
  public static replacementsFromLegacy(
    target: ImageLayerReplacementTarget,
    replacementColors: IReplacementColor[],
  ): IImageLayerReplacement[] {
    if (!Array.isArray(replacementColors)) {
      return [];
    }
    return replacementColors
      .filter(($) => $.newColor.color)
      .map<IImageLayerReplacement>(($) => ({
        target,
        colorOld: $.originalColor,
        colorNew: ColorCompat.colorDescToColor($.newColor)!, // ensure nullables are filtered out afterwards!
      }))
      .filter(($) => $.colorNew && $.colorNew.rgba); // ensure color was converted correctly from hex so our `!` above is not a lie
  }

  /**
   * Converts a {@link IImageLayerReplacement} list to a legacy {@link IReplacementColor} list
   */
  public static replacementsToLegacy(
    target: ImageLayerReplacementTarget,
    replacements: IImageLayerReplacement[],
  ): IReplacementColor[] {
    return replacements
      .filter(($) => $.target === target)
      .map(($) => ({
        originalColor: $.colorOld,
        newColor: ColorCompat.colorToColorDesc($.colorNew),
      }))
      .filter(($) => $.newColor.color);
  }
}
