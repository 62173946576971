import styles from './FormatSelection.module.css';
import { Icon } from '../../../icons/Icon';
import {
  BookMeasurementProportions,
  KnownMeasurements,
} from '../../../measures/BookMeasurementProportions';
import { TFunction, useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import RadioButton from '../../../forms/radioButton/RadioButton';
import { FormatProductSizeSchema, FormatSetting, IFormatPageValueType } from '../FormatPage.schema';
import { getFormatProductTypeSchemaAttributeAsString } from './BookFormatElement';
import { useGetBookFormatsQuery } from '../../../api/treditionApi';
import { IBookFormatResponse, IBookFormatSize } from '../interfaces/IBookFormatResponse';
import i18n from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { IOption } from '../../../../@types/IOption';
import { SelectFormik } from '../../../select/Select.formik';
import { NumberInputFormik } from '../../../forms/numberInput/NumberInput.formik';
import { ProductType } from '../constants/ProductType';
import { Spacer } from '../../../shared/spacer/Spacer';

export interface IFormatOption extends IOption<IBookFormatSize> {
  /**
   * The value of the option which is the size of the format
   */
  value: IBookFormatSize;
}

const createOptionsFromResponse = (t: TFunction, response: IBookFormatResponse) => {
  const formatLabel = ({ width, height }: IBookFormatSize) => {
    const toCm = (value: number) => (value === 0 ? value : value / 10);
    const formattedNumber = (value: number) => Intl.NumberFormat(i18n.language).format(toCm(value));
    return `${formattedNumber(width)} x ${formattedNumber(height)} cm`;
  };

  return [
    {
      label: t('DefaultFormat'),
      options: response.IsDefault.map((format) => {
        return {
          label: formatLabel(format),
          value: { width: format.width, height: format.height },
        };
      }),
    },
    {
      label: t('SpecialFormat'),
      options: response.IsSecondary.map((format) => {
        return {
          label: formatLabel(format),
          value: { width: format.width, height: format.height },
        };
      }),
    },
  ];
};

export interface IFormatProps {
  /**
   * If the format is disabled
   */
  disabled: boolean;
  /**
   * The type of the format
   */
  type: Exclude<ProductType, ProductType.None>;
  /**
   * The name of the format
   */
  name: string;
}

const getFormatSettingName = (type: ProductType, key: keyof FormatProductSizeSchema) => {
  return [getFormatProductTypeSchemaAttributeAsString(type, 'sizeInMM'), key].join('.');
};

export const FormatSelection = ({ disabled, type, name }: IFormatProps) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<IFormatPageValueType>();
  const { sizeInMM: currentSize, format: currentFormat } = values[type];

  const { isLoading: bookFormatsLoading, data } = useGetBookFormatsQuery();

  const formatSelectOptions = useMemo(
    () => (bookFormatsLoading || !data ? [] : createOptionsFromResponse(t, data)),
    [bookFormatsLoading, data, t],
  );

  /**
   * When toggling between the standard and custom format and the size is unknown
   * we need to set the size to the first option in the select
   */
  useEffect(() => {
    if (currentFormat !== FormatSetting.Standard) return;
    const opts = Array.isArray(formatSelectOptions)
      ? formatSelectOptions.flatMap((o) => o.options)
      : [];

    // If there are no options we can't set a default
    // for example when loading data
    if (!opts.length || !opts[0]) return;

    const [firstOption] = opts;

    const currentSelection = values[type].sizeInMM;
    if (!currentSelection) {
      setValues((values) => ({
        ...values,
        [type]: {
          ...values[type],
          sizeInMM: firstOption.value,
        },
      }));
      return;
    }

    const isKnownFormat = opts.some(({ value }) => {
      return value.width == currentSelection.width && value.height == currentSelection.height;
    });

    // Known format is a format that is in the select, if the selection is known
    // we can skip setting the default
    if (isKnownFormat) return;

    setValues((values) => ({
      ...values,
      [type]: {
        ...values[type],
        sizeInMM: firstOption.value,
      },
    }));
  }, [formatSelectOptions, setValues, type, currentFormat]);

  return (
    <div className={styles.format}>
      <h3>{t('MeasuresTemplateTotalFormatDescription')}</h3>
      <div className={styles.radioGroup} role={'group'}>
        <Field
          type={'radio'}
          as={RadioButton}
          label={t('DefaultFormat')}
          checked={values[type].format == FormatSetting.Standard}
          name={getFormatProductTypeSchemaAttributeAsString(type, 'format')}
          value={FormatSetting.Standard}
          disabled={disabled}
        />
        <Field
          type={'radio'}
          as={RadioButton}
          disabled={disabled}
          label={t('CustomFormat')}
          checked={values[type].format == FormatSetting.Individual}
          value={FormatSetting.Individual}
          name={`${type}.format`}
        />
      </div>
      <div className={styles.sizeSelection}>
        {values[type].format == FormatSetting.Standard && (
          <div className={styles.selectWrapper}>
            <SelectFormik
              label={t('Format')}
              options={formatSelectOptions}
              isDisabled={disabled}
              name={getFormatProductTypeSchemaAttributeAsString(type, 'sizeInMM')}
              isLoading={bookFormatsLoading}
              isMulti={false}
              compareValues={(a, b) => {
                if (a == null || b == null) return false;
                return a.width === b.width && a.height === b.height;
              }}
            />
          </div>
        )}
        {values[type].format == FormatSetting.Individual && (
          <div className={styles.buttons}>
            <NumberInputFormik
              inputWidth="50px"
              labelPosition="top"
              label={t('Width')}
              disabled={disabled}
              addendum="mm"
              name={getFormatSettingName(type, 'width')}
            />
            <Icon className={styles.icon} name={'schliessen-control'} />
            <NumberInputFormik
              inputWidth="50px"
              labelPosition="top"
              label={t('Height')}
              disabled={disabled}
              addendum="mm"
              name={getFormatSettingName(type, 'height')}
            />
          </div>
        )}
      </div>
      <div className={styles.proportionsWrapper}>
        {!!currentSize && (
          <BookMeasurementProportions
            disabled={disabled}
            format={KnownMeasurements.DinA4}
            label={[t('Your'), name].join(' ')}
            width={currentSize.width}
            height={currentSize.height}
            ensureMeasurementWithinBounds
          />
        )}
      </div>
    </div>
  );
};
