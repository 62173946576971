import { CSSProperties, FC, useContext, useEffect } from 'react';
import { ILayerProps, Layer } from './Layer';
import { ITextLayer } from './LayersState';
import { CKEContext } from '../textEditor/CKEditorProvider';
import { px } from '../../lib/px';
import { getLayerCssFilter } from './getLayerCssFilter';
import classNames from 'classnames';
import { getLayerBackgroundColorCss, getLayerBorderCss } from '../../lib/Color';

export interface ITextLayerProps extends ILayerProps {
  layer: ITextLayer;
}

export function getTextLayerContentStyle(layer: ITextLayer): CSSProperties {
  if (!layer) {
    return {};
  }
  const style: CSSProperties = {
    padding: px(layer.padding),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden !important',
    filter: getLayerCssFilter(layer),
  };
  if (layer.rotateForBookBack) {
    style.transform = 'rotate(-90deg) translateX(-100%)';
    style.transformOrigin = 'left top';
    style.width = px(layer.display.height);
    style.height = px(layer.display.width);
  }
  return style;
}

export const TextLayer: FC<ITextLayerProps> = ({ layer }) => {
  const { layerId, setLayerId } = useContext(CKEContext);

  // if the layer switches from selected to deselected and this layer was currently edited
  // notify the context
  useEffect(() => {
    if (layerId === layer.id && !layer.isSelected) {
      setLayerId(null);
    }
  }, [layerId, layer.id, layer.isSelected, setLayerId]);

  return (
    <Layer
      layer={layer}
      className="text"
      style={{
        backgroundColor: getLayerBackgroundColorCss(layer),
        border: getLayerBorderCss(layer),
        overflow: 'hidden',
      }}>
      {
        // if the layer is being edited, the CKEditor will take over. the ckeditor portal node
        // is rendered in the `TextEditor` component which has to be positioned above the
        // bounding boxes and thus be rendered separately to be usable
        layer.id === layerId ? null : (
          <div
            className={classNames('content')}
            style={getTextLayerContentStyle(layer)}
            dangerouslySetInnerHTML={{ __html: layer.content }}
          />
        )
      }
    </Layer>
  );
};
