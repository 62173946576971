import { FC } from 'react';
import { px } from '../../lib/px';
import { useTranslation } from 'react-i18next';

export interface IBleedLineProps {
  /**
   * Amount of bleed in pixels which can be obtained from the design product's measures
   */
  bleed: number;
}

export const BleedLine: FC<IBleedLineProps> = ({ bleed }) => {
  const { t } = useTranslation();
  if (!bleed) {
    return null;
  }
  return (
    <div
      className="bleed-line"
      style={{
        left: px(bleed),
        top: px(bleed),
        width: `calc(100% - ${2 * bleed}px)`,
        height: `calc(100% - ${2 * bleed}px)`,
      }}>
      <div className="bleed-line-shadow" style={{ outlineWidth: px(bleed) }} />
      <span className="bleed-hint" style={{ top: px(-bleed), lineHeight: px(bleed) }}>
        {t('DesignerBleedHint')}
      </span>
    </div>
  );
};
