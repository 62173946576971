import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Modal } from '../../modal/Modal';
import { useTranslation } from 'react-i18next';
import { ImageCropper } from '../../crop/ImageCropper';
import { useCreatePaletteFromImageMutation } from '../../api/treditionApi';
import { ISaveTreditionPalette, ITreditionPalette } from './TreditionPalette';
import { Palette } from './Palette';
import styled from 'styled-components/macro';
import { Button } from '../../uiComponents/Button';
import { Icon } from '../../icons/Icon';
import { createObjectUrlFromFile } from '../../lib/createObjectUrlFromFile';
import { toast } from 'react-hot-toast';
import { noop } from '../../lib/noop';

export interface IPaletteFromImageModalProps {
  onRequestClose: () => unknown;
  onAcceptPalette: (palette: ISaveTreditionPalette) => unknown;
}

export const PaletteFromImageModal: FC<IPaletteFromImageModalProps> = ({
  onAcceptPalette,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [isBusy, setBusy] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [palette, setPalette] = useState<ITreditionPalette | null>(null);
  const [createPalette] = useCreatePaletteFromImageMutation();

  const onClickAcceptPalette = useCallback(() => {
    if (palette) {
      onAcceptPalette({
        id: null,
        name: '', // no way in current customer to set name either
        color1: palette.color1!,
        color2: palette.color2!,
        color3: palette.color3!,
        color4: palette.color4!,
        color5: palette.color5!,
        color6: palette.color6!,
      });
    }
  }, [onAcceptPalette, palette]);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      setImage(e.target.files[0]);
      createObjectUrlFromFile(e.target.files[0]).then((url) => setImageSrc(url));
    }
  }, []);

  return (
    <Modal title={t('CropImage')} isOpen onRequestClose={onRequestClose}>
      <InputLabel>
        <span>
          <Icon name="upload-control" />
          {t('ChooseFile')}
        </span>
        <input type="file" onChange={onFileChange} accept="image/*" />
      </InputLabel>
      {!!imageSrc && (
        <ImageCropper
          imageSrc={imageSrc}
          onCrop={(cropData) => {
            if (!image) {
              return;
            }
            setBusy(true);

            const createPaletteTask = createPalette({ area: cropData.area, image }).unwrap();

            toast
              .promise(createPaletteTask, {
                error: t('ImageUploadError'),
                loading: t('ImageUploadLoading'),
                success: t('ImageUploadSuccess'),
              })
              .then((palette) => {
                setPalette(palette);
              })
              .catch(noop)
              .finally(() => {
                setBusy(false);
              });
          }}
          renderFooter={({ onSubmitCrop }) => (
            <Footer>
              <CreatePaletteWrapper>
                <Button onClick={onSubmitCrop}>{t('CreateColorPalette')}</Button>
              </CreatePaletteWrapper>
              <PalettePreviewWrapper>
                {isBusy && t('PleaseWaitUpload')}
                {!isBusy && !!palette && <Palette palette={palette} />}
              </PalettePreviewWrapper>
              <SubmitWrapper>
                <Button onClick={onRequestClose} cancel>
                  {t('Cancel')}
                </Button>
                <Button onClick={onClickAcceptPalette} disabled={!palette}>
                  {t('OK')}
                </Button>
              </SubmitWrapper>
            </Footer>
          )}
        />
      )}
    </Modal>
  );
};

const InputLabel = styled.label`
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  & > span {
    padding: var(--spacing-standard);
    margin-bottom: var(--spacing-standard);
    display: grid;
    justify-items: center;
    gap: var(--spacing-medium);
    border: 1px solid var(--color-cta);
    cursor: pointer;
  }

  & > input {
    display: none;
  }
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: min-content auto auto;
  justify-content: space-between;
`;
const CreatePaletteWrapper = styled.div`
  grid-column: 1 / span 1;
`;
const PalettePreviewWrapper = styled.div`
  grid-column: 2 / span 1;
`;
const SubmitWrapper = styled.div`
  grid-column: 3 / span 1;
  display: flex;
  gap: var(--spacing-medium);
`;
