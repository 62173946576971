import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { recalculateDepthReducer } from './recalculateDepthReducer';
import { swap } from '../../lib/swap';
import { ILiveSheetState } from './ILiveSheetState';

export interface IAdjustDepthActionPayload {
  /**
   * The layer which should be moved
   */
  sourceId: string;

  /**
   * The target layer which will be used as a reference when adjusting the depth.
   *
   * Ignored if the {@link mode} is not `AboveTarget` or `BelowTarget`
   *
   * @see AdjustDepthMode.AboveTarget
   * @see AdjustDepthMode.BelowTarget
   */
  targetId?: string;

  /**
   * @see AdjustDepthMode
   */
  mode: AdjustDepthMode;
}

export enum AdjustDepthMode {
  /**
   * The source layer depth should be raised by one
   */
  Raise,

  /**
   * The source layer depth should be lowered by one
   */
  Lower,

  /**
   * The source layer depth should become the highest
   */
  RaiseToTop,

  /**
   * The source layer depth should become the lowest
   */
  LowerToBottom,

  /**
   * The source layer should have a depth higher than the target layer
   */
  AboveTarget,

  /**
   * The source layer should be moved below the target layer
   */
  BelowTarget,
}

export function adjustDepthReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAdjustDepthActionPayload>,
) {
  // NOTE (reminder): higher index (depth) is _above_ lower index' layers
  const sourceIndex = state.layers.findIndex(($) => $.id === action.payload.sourceId);
  let targetIndex = -1;

  switch (action.payload.mode) {
    case AdjustDepthMode.AboveTarget:
    case AdjustDepthMode.BelowTarget: {
      // need to identify this element early otherwise we would stop during the splice
      targetIndex = state.layers.findIndex(($) => $.id === action.payload.targetId);
      if (sourceIndex <= -1 || targetIndex <= -1 || sourceIndex === targetIndex) {
        return;
      }
      // gather the reference
      const sourceItem = state.layers[sourceIndex];
      // remove the element
      state.layers.splice(sourceIndex, 1);
      // as the array has been modified we need to reindex
      targetIndex = state.layers.findIndex(($) => $.id === action.payload.targetId);
      if (action.payload.mode === AdjustDepthMode.AboveTarget) {
        targetIndex = Math.max(targetIndex + 1, 0);
      }
      // insert it at the new index
      state.layers.splice(targetIndex, 0, sourceItem);
      break;
    }
    case AdjustDepthMode.Raise:
      swap(state.layers, sourceIndex, sourceIndex + 1);
      break;
    case AdjustDepthMode.Lower:
      swap(state.layers, sourceIndex, sourceIndex - 1);
      break;
    case AdjustDepthMode.LowerToBottom: {
      const sourceItem = state.layers[sourceIndex];
      state.layers.splice(sourceIndex, 1);
      state.layers.unshift(sourceItem);
      break;
    }
    case AdjustDepthMode.RaiseToTop: {
      const sourceItem = state.layers[sourceIndex];
      state.layers.splice(sourceIndex, 1);
      state.layers.push(sourceItem);
      break;
    }
  }
  // adjust depth values based on new element order
  recalculateDepthReducer(state);
}
