import { useTranslation } from 'react-i18next';
import { TextInputFormik } from '../../../forms/textInput/TextInput.formik';
import React, { FC, useContext } from 'react';
import styles from './IsbnsForm.module.css';
import { formatIsbn } from '../utils/IsbnUtils';
import { EditorContext } from '../../EditorContext';
import { IIsbnProduct } from '../interfaces/IIsbnPageData';

interface IIsbnsFormProps {
  products: IIsbnProduct[];
}

export const IsbnsForm: FC<IIsbnsFormProps> = ({ products }) => {
  const { t } = useTranslation();

  const context = useContext(EditorContext);
  const {
    state: { isReadonly },
  } = context;

  return (
    <div className={styles.wrapper}>
      <h3>{t('ISBN')}</h3>
      {products.map(({ bookType }) => (
        <TextInputFormik
          pasteFormatter={(event) => {
            const text = event.clipboardData.getData('text');
            if (text.length !== 13) return text;
            return formatIsbn(event.clipboardData.getData('text'));
          }}
          disabled={isReadonly}
          key={bookType}
          label={`${t(bookType)}*`}
          name={`customIsbn.${bookType}`}
        />
      ))}
    </div>
  );
};
