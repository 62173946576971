import React from 'react';
import { Icon } from '../icons/Icon';
import styled from 'styled-components/macro';

export interface ICloseIconButton {
  onClick: () => unknown;
  position: IPosition;
}

interface IStyledButton {
  position: IPosition;
}

export interface IPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export const CloseIconButton: React.FC<ICloseIconButton> = ({ onClick, position }) => {
  return (
    <StyledButton onClick={onClick} position={position}>
      <Icon name={'schliessen-control'} />
    </StyledButton>
  );
};

const StyledButton = styled.button.attrs<IStyledButton>((props) => ({
  style: {
    top: typeof props.position.top === 'number' ? `${props.position.top}px` : 'auto',
    right: typeof props.position.right === 'number' ? `${props.position.right}px` : 'auto',
    bottom: typeof props.position.bottom === 'number' ? `${props.position.bottom}px` : 'auto',
    left: typeof props.position.left === 'number' ? `${props.position.left}px` : 'auto',
  },
}))<IStyledButton>`
  border: none;
  padding: 0;
  font-size: 0.8rem;
  line-height: normal;
  position: absolute;
  background-color: transparent;
`;
