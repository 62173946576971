import { ITreditionPalette } from './TreditionPalette';
import { FC, useMemo } from 'react';
import { ColorDisplay } from './ColorDisplay';
import { IRGBA } from '../../lib/Color';
import styled from 'styled-components/macro';

export interface IPaletteProps {
  /**
   * The palette to display as individual swatches
   */
  palette: ITreditionPalette;

  /**
   * Optional callback to invoke when the user clicks on one of the colors.
   */
  onClickColor?: (color: IRGBA, paletteIndex: number) => unknown;

  /**
   * An optional index of the palette to highlight
   */
  selectedIndex?: number | null;
}

export const Palette: FC<IPaletteProps> = ({ palette, onClickColor, selectedIndex }) => {
  const click = useMemo(() => {
    type MaybeFn = ((color: IRGBA) => unknown) | undefined;
    const fnCollection = {
      color1: undefined as MaybeFn,
      color2: undefined as MaybeFn,
      color3: undefined as MaybeFn,
      color4: undefined as MaybeFn,
      color5: undefined as MaybeFn,
      color6: undefined as MaybeFn,
    };
    if (!onClickColor) {
      return fnCollection;
    }
    fnCollection.color1 = (color: IRGBA) => onClickColor(color, 0);
    fnCollection.color2 = (color: IRGBA) => onClickColor(color, 1);
    fnCollection.color3 = (color: IRGBA) => onClickColor(color, 2);
    fnCollection.color4 = (color: IRGBA) => onClickColor(color, 3);
    fnCollection.color5 = (color: IRGBA) => onClickColor(color, 4);
    fnCollection.color6 = (color: IRGBA) => onClickColor(color, 5);
    return fnCollection;
  }, [onClickColor]);

  return (
    <PaletteWrapper className="palette">
      <ColorDisplay hex={palette.color1} onClick={click.color1} isSelected={selectedIndex === 0} />
      <ColorDisplay hex={palette.color2} onClick={click.color2} isSelected={selectedIndex === 1} />
      <ColorDisplay hex={palette.color3} onClick={click.color3} isSelected={selectedIndex === 2} />
      <ColorDisplay hex={palette.color4} onClick={click.color4} isSelected={selectedIndex === 3} />
      <ColorDisplay hex={palette.color5} onClick={click.color5} isSelected={selectedIndex === 4} />
      <ColorDisplay hex={palette.color6} onClick={click.color6} isSelected={selectedIndex === 5} />
    </PaletteWrapper>
  );
};

const PaletteWrapper = styled.div`
  display: flex;
`;
