import { ILayer } from '../../layer/LayersState';
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { scaleLayers } from '../../layer/liveSheetSlice';
import { ScalingOperation } from '../../layer/scaleLayersReducer';
import { NumberInputWrapper } from '../../../uiComponents/NumberInputWrapper';
import styled from 'styled-components/macro';
import { MenuButton } from '../../../uiComponents/MenuButton';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../uiComponents/Input';
import { Icon } from '../../../icons/Icon';

export interface ISingleScalerProps {
  layer: ILayer;
}

export const SingleScaler: FC<ISingleScalerProps> = ({ layer }) => {
  const { t } = useTranslation();
  const widthRef = useRef<HTMLInputElement>(null);
  const heightRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [keepAspectRatio, setKeepAspectRatio] = useState<boolean>(false);

  const onBlurWidthInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const x = Number.parseInt(e.target.value);
      if (Number.isNaN(x)) {
        return;
      }
      dispatch(scaleLayers({ x, operation: ScalingOperation.Set, keepAspectRatio }));
    },
    [dispatch, keepAspectRatio],
  );

  const onBlurHeightInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const y = Number.parseInt(e.target.value);
      if (Number.isNaN(y)) {
        return;
      }
      dispatch(scaleLayers({ y, operation: ScalingOperation.Set, keepAspectRatio }));
    },
    [dispatch, keepAspectRatio],
  );

  useEffect(() => {
    if (widthRef.current && heightRef.current) {
      widthRef.current.value = Math.floor(layer.width).toString();
      heightRef.current.value = Math.floor(layer.height).toString();
    }
  }, [layer.width, layer.height, widthRef, heightRef]);

  return (
    <Wrapper>
      <FlexContainer>
        <Container>
          <NumberInputWrapper>
            {t('Width')}
            <Input
              type="number"
              step={1}
              ref={widthRef}
              onBlur={onBlurWidthInput}
              defaultValue={layer.width}
              addendum={t('Pixel')}
            />
          </NumberInputWrapper>
          <NumberInputWrapper>
            {t('Height')}
            <Input
              type="number"
              step={1}
              ref={heightRef}
              onBlur={onBlurHeightInput}
              defaultValue={layer.height}
              addendum={t('Pixel')}
            />
          </NumberInputWrapper>
        </Container>
        <MenuButton
          isActive={!keepAspectRatio}
          onClick={() => setKeepAspectRatio(!keepAspectRatio)}
          title={t('Proportional')}>
          <Icon name={keepAspectRatio ? 'schlosszu-control' : 'schlossauf-control'} />
        </MenuButton>
      </FlexContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  padding-right: 1rem;
  gap: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  align-content: start;
  gap: 0.5rem;
`;
