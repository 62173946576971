import { RootState } from '../redux/store';
import { authSlice } from './authSlice';
import { useAppSelector } from '../redux/hooks';

/**
 * Returns the entire auth state slice
 */
export const getAuth = (state: RootState) => state[authSlice.name];

/**
 * Returns the current jwt or null if not logged in
 */
export const getJwt = (state: RootState) => getAuth(state).jwt;

/**
 * Returns the current user id or null if not logged in
 */
export const getUserId = (state: RootState) => getAuth(state).userId;

/**
 * Provides the current user id to a React component
 */
export const useUserId = () => useAppSelector(getUserId);

/**
 * Provides the current jwt to a React component
 */
export const useJwt = () => useAppSelector(getJwt);
