import React from 'react';
import styles from './ErrorWrapper.module.css';

export interface IErrorWrapper {
  className?: string;
  children?: React.ReactNode;
}

export const ErrorWrapper: React.FC<IErrorWrapper> = ({ className, children, ...rest }) => (
  <div {...rest} className={`${styles.wrapper} ${className}`}>
    {children}
  </div>
);
