/**
 * Triggers the finish project logic which still resides in the blazor app.
 *
 * Resolves to false if the finish project call failed (due to business logic)
 * or because somehow the interop endpoint was not available.
 */
export function finishProjectBlazor(): Promise<boolean> {
  // inform blazor app about the navigation change for it to rerender properly
  if (typeof window.BLZ_EDITOR_FINISH !== 'function') {
    return Promise.resolve(false);
  }
  return window.BLZ_EDITOR_FINISH();
}
