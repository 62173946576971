import { FC, Fragment, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { IQuery } from '../../@types/IQuery';
import { useTranslation } from 'react-i18next';
import { Button } from '../uiComponents/Button';
import { Alert } from '../alert/Alert';
import { resolveQueryError } from '../error/resolveQueryError';
import { QueryErrorDescription } from '../error/QueryErrorDescription';

export interface IQueryLoaderProps {
  query: IQuery;
  renderError?: (query: IQuery) => ReactElement;
  renderLoading?: (query: IQuery) => ReactNode;
  fetchingIsLoading?: boolean;
}

export const QueryLoader: FC<PropsWithChildren<IQueryLoaderProps>> = ({
  children,
  query,
  renderError,
  renderLoading,
  fetchingIsLoading,
}) => {
  const { t } = useTranslation();
  if (query.error) {
    if (renderError) {
      return renderError(query);
    }
    const errDesc = resolveQueryError(query);
    return (
      <Alert level="error">
        <QueryErrorDescription type={errDesc.type} />
        {errDesc.isRetryable && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
            <Button onClick={query.refetch} disabled={query.isFetching}>
              {t(query.isFetching ? 'PleaseWait' : 'Retry')}
            </Button>
          </div>
        )}
      </Alert>
    );
  }
  if (query.isLoading || (fetchingIsLoading && query.isFetching)) {
    if (renderLoading) {
      return <>{renderLoading(query)}</>;
    }
    return <span>{t('LoaderLoading')}</span>;
  }
  return <Fragment>{children}</Fragment>;
};
