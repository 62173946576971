import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface setShowTemplatesActionPayload {
  showTemplates: boolean;
}

export const setShowTemplatesReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<setShowTemplatesActionPayload>,
) => {
  state.showTemplates = action.payload.showTemplates;
};
