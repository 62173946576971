import React, { FC, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TreditionISBNList } from './components/TreditionISBNList';
import { useReserveIsbnsMutation } from '../../api/treditionApi';
import { toast } from 'react-hot-toast';
import { EditorContext } from '../EditorContext';
import { SanitizedHtmlElement } from '../../shared/translationHtmlWrapper/SanitizedHtmlElement';
import { IIsbnProduct } from './interfaces/IIsbnPageData';

export interface ITreditionIsbnFormProps {
  projectId: string;
  products: IIsbnProduct[];
}

export const TreditionIsbnForm: FC<ITreditionIsbnFormProps> = ({ projectId, products }) => {
  const { t } = useTranslation();
  // whether ISBNs have already been reserved once in this step session
  // null if this state could not yet be determined (due to loading)
  const isbnsReserved = useRef<boolean>(products.every(($) => $.isbn && $.isTreditionIsbn));
  const [reserveIsbnCall] = useReserveIsbnsMutation();

  const context = useContext(EditorContext);
  const {
    state: { isReadonly, isBookUpdate },
  } = context;

  /**
   * Reserve isbn if needed
   */
  useEffect(() => {
    // Skip if isbn is already reserved
    if (isbnsReserved.current || isReadonly || isBookUpdate) return;

    reserveIsbnCall(projectId)
      .unwrap()
      .then(() => {
        isbnsReserved.current = true;
      })
      .catch((e) => {
        toast.error(<SanitizedHtmlElement htmlMessage={t('ErrorReservingISBN')} />);
        console.error(e);
      });
  }, [projectId, reserveIsbnCall, t, isReadonly, isBookUpdate]);

  return <TreditionISBNList products={products} />;
};
