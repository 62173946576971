import uniq from 'lodash/uniq';

export const GOOGLE_FONTS = uniq([
  'Arima',
  'Actor',
  'Acme',
  'Book Antiqua',
  'ABeeZee',
  'Abel',
  'Abhaya Libre',
  'Abril Fatface',
  'Aclonica',
  'Acme',
  'Actor',
  'Adamina',
  'Advent Pro',
  'Aguafina Script',
  'Akaya Kanadaka',
  'Akaya Telivigala',
  'Akronim',
  'Akshar',
  'Aladin',
  'Alata',
  'Alatsi',
  'Albert Sans',
  'Aldrich',
  'Alef',
  'Alegreya',
  'Alegreya SC',
  'Alegreya Sans',
  'Alegreya Sans SC',
  'Aleo',
  'Alex Brush',
  'Alfa Slab One',
  'Alice',
  'Alike',
  'Alike Angular',
  'Allan',
  'Allerta',
  'Allerta Stencil',
  'Allison',
  'Allura',
  'Almarai',
  'Almendra',
  'Almendra Display',
  'Almendra SC',
  'Alumni Sans',
  'Alumni Sans Inline One',
  'Amarante',
  'Amaranth',
  'Amatic SC',
  'Amethysta',
  'Amiko',
  'Amiri',
  'Amita',
  'Anaheim',
  'Andada Pro',
  'Andika',
  'Anek Bangla',
  'Anek Devanagari',
  'Anek Gujarati',
  'Anek Gurmukhi',
  'Anek Kannada',
  'Anek Latin',
  'Anek Malayalam',
  'Anek Odia',
  'Anek Tamil',
  'Anek Telugu',
  'Angkor',
  'Annie Use Your Telescope',
  'Anonymous Pro',
  'Antic',
  'Antic Didone',
  'Antic Slab',
  'Anton',
  'Antonio',
  'Anybody',
  'Arapey',
  'Arbutus',
  'Arbutus Slab',
  'Architects Daughter',
  'Archivo',
  'Archivo Black',
  'Archivo Narrow',
  'Are You Serious',
  'Aref Ruqaa',
  'Arima',
  'Arima Madurai',
  'Arimo',
  'Arizonia',
  'Armata',
  'Arsenal',
  'Artifika',
  'Arvo',
  'Arya',
  'Asap',
  'Asap Condensed',
  'Asar',
  'Asset',
  'Assistant',
  'Astloch',
  'Asul',
  'Athiti',
  'Atkinson Hyperlegible',
  'Atma',
  'Atomic Age',
  'Aubrey',
  'Audiowide',
  'Autour One',
  'Average',
  'Average Sans',
  'Averia Gruesa Libre',
  'Averia Libre',
  'Averia Sans Libre',
  'Averia Serif Libre',
  'Azeret Mono',
  'B612',
  'B612 Mono',
  'BIZ UDGothic',
  'BIZ UDMincho',
  'BIZ UDPGothic',
  'BIZ UDPMincho',
  'Babylonica',
  'Bad Script',
  'Bahiana',
  'Bahianita',
  'Bai Jamjuree',
  'Bakbak One',
  'Ballet',
  'Baloo 2',
  'Baloo Bhai 2',
  'Baloo Bhaijaan 2',
  'Baloo Bhaina 2',
  'Baloo Chettan 2',
  'Baloo Da 2',
  'Baloo Paaji 2',
  'Baloo Tamma 2',
  'Baloo Tammudu 2',
  'Baloo Thambi 2',
  'Balsamiq Sans',
  'Balthazar',
  'Bangers',
  'Barlow',
  'Barlow Condensed',
  'Barlow Semi Condensed',
  'Barriecito',
  'Barrio',
  'Basic',
  'Baskervville',
  'Battambang',
  'Baumans',
  'Bayon',
  'Be Vietnam Pro',
  'Beau Rivage',
  'Bebas Neue',
  'Belgrano',
  'Bellefair',
  'Belleza',
  'Bellota',
  'Bellota Text',
  'BenchNine',
  'Benne',
  'Bentham',
  'Berkshire Swash',
  'Besley',
  'Beth Ellen',
  'Bevan',
  'BhuTuka Expanded One',
  'Big Shoulders Display',
  'Big Shoulders Inline Display',
  'Big Shoulders Inline Text',
  'Big Shoulders Stencil Display',
  'Big Shoulders Stencil Text',
  'Big Shoulders Text',
  'Bigelow Rules',
  'Bigshot One',
  'Bilbo',
  'Bilbo Swash Caps',
  'BioRhyme',
  'BioRhyme Expanded',
  'Birthstone',
  'Birthstone Bounce',
  'Biryani',
  'Bitter',
  'Black And White Picture',
  'Black Han Sans',
  'Black Ops One',
  'Blaka',
  'Blaka Hollow',
  'Blinker',
  'Bodoni Moda',
  'Bokor',
  'Bona Nova',
  'Bonbon',
  'Bonheur Royale',
  'Boogaloo',
  'Bowlby One',
  'Bowlby One SC',
  'Brawler',
  'Bree Serif',
  'Brygada 1918',
  'Bubblegum Sans',
  'Bubbler One',
  'Buenard',
  'Bungee',
  'Bungee Hairline',
  'Bungee Inline',
  'Bungee Outline',
  'Bungee Shade',
  'Butcherman',
  'Butterfly Kids',
  'Cabin',
  'Cabin Condensed',
  'Cabin Sketch',
  'Caesar Dressing',
  'Cagliostro',
  'Cairo',
  'Caladea',
  'Calistoga',
  'Calligraffitti',
  'Cambay',
  'Cambo',
  'Candal',
  'Cantarell',
  'Cantata One',
  'Cantora One',
  'Capriola',
  'Caramel',
  'Carattere',
  'Cardo',
  'Carme',
  'Carrois Gothic',
  'Carrois Gothic SC',
  'Carter One',
  'Castoro',
  'Catamaran',
  'Caudex',
  'Caveat',
  'Caveat Brush',
  'Cedarville Cursive',
  'Ceviche One',
  'Chakra Petch',
  'Changa',
  'Changa One',
  'Chango',
  'Charis SIL',
  'Charm',
  'Charmonman',
  'Chathura',
  'Chau Philomene One',
  'Chela One',
  'Chelsea Market',
  'Cherish',
  'Cherry Cream Soda',
  'Cherry Swash',
  'Chewy',
  'Chicle',
  'Chilanka',
  'Chivo',
  'Chonburi',
  'Cinzel',
  'Cinzel Decorative',
  'Clicker Script',
  'Coda',
  'Codystar',
  'Coiny',
  'Combo',
  'Comfortaa',
  'Comforter',
  'Comforter Brush',
  'Comic Neue',
  'Coming Soon',
  'Commissioner',
  'Concert One',
  'Condiment',
  'Contrail One',
  'Convergence',
  'Cookie',
  'Copse',
  'Corben',
  'Corinthia',
  'Cormorant',
  'Cormorant Garamond',
  'Cormorant Infant',
  'Cormorant SC',
  'Cormorant Unicase',
  'Cormorant Upright',
  'Courgette',
  'Courier Prime',
  'Cousine',
  'Coustard',
  'Covered By Your Grace',
  'Crafty Girls',
  'Creepster',
  'Crete Round',
  'Crimson Pro',
  'Crimson Text',
  'Croissant One',
  'Crushed',
  'Cuprum',
  'Cute Font',
  'Cutive',
  'Cutive Mono',
  'DM Mono',
  'DM Sans',
  'DM Serif Display',
  'DM Serif Text',
  'Damion',
  'Dancing Script',
  'Dangrek',
  'Darker Grotesque',
  'David Libre',
  'Dawning of a New Day',
  'Days One',
  'Dekko',
  'Dela Gothic One',
  'Delius',
  'Delius Swash Caps',
  'Delius Unicase',
  'Della Respira',
  'Denk One',
  'Devonshire',
  'Dhurjati',
  'Didact Gothic',
  'Diplomata',
  'Diplomata SC',
  'Do Hyeon',
  'Dokdo',
  'Domine',
  'Donegal One',
  'Dongle',
  'Doppio One',
  'Dorsa',
  'Dosis',
  'DotGothic16',
  'Dr Sugiyama',
  'Duru Sans',
  'Dynalight',
  'EB Garamond',
  'Eagle Lake',
  'East Sea Dokdo',
  'Eater',
  'Economica',
  'Eczar',
  'Edu NSW ACT Foundation',
  'Edu QLD Beginner',
  'Edu SA Beginner',
  'Edu TAS Beginner',
  'Edu VIC WA NT Beginner',
  'El Messiri',
  'Electrolize',
  'Elsie',
  'Elsie Swash Caps',
  'Emblema One',
  'Emilys Candy',
  'Encode Sans',
  'Encode Sans Condensed',
  'Encode Sans Expanded',
  'Encode Sans SC',
  'Encode Sans Semi Condensed',
  'Encode Sans Semi Expanded',
  'Engagement',
  'Englebert',
  'Enriqueta',
  'Ephesis',
  'Epilogue',
  'Erica One',
  'Esteban',
  'Estonia',
  'Euphoria Script',
  'Ewert',
  'Exo',
  'Exo 2',
  'Expletus Sans',
  'Explora',
  'Fahkwang',
  'Familjen Grotesk',
  'Fanwood Text',
  'Farro',
  'Farsan',
  'Fascinate',
  'Fascinate Inline',
  'Faster One',
  'Fasthand',
  'Fauna One',
  'Faustina',
  'Federant',
  'Federo',
  'Felipa',
  'Fenix',
  'Festive',
  'Finger Paint',
  'Finlandica',
  'Fira Code',
  'Fira Mono',
  'Fira Sans',
  'Fira Sans Condensed',
  'Fira Sans Extra Condensed',
  'Fjalla One',
  'Fjord One',
  'Flamenco',
  'Flavors',
  'Fleur De Leah',
  'Flow Block',
  'Flow Circular',
  'Flow Rounded',
  'Fondamento',
  'Fontdiner Swanky',
  'Forum',
  'Francois One',
  'Frank Ruhl Libre',
  'Fraunces',
  'Freckle Face',
  'Fredericka the Great',
  'Fredoka',
  'Fredoka One',
  'Freehand',
  'Fresca',
  'Frijole',
  'Fruktur',
  'Fugaz One',
  'Fuggles',
  'Fuzzy Bubbles',
  'GFS Didot',
  'GFS Neohellenic',
  'Gabriela',
  'Gaegu',
  'Gafata',
  'Galada',
  'Galdeano',
  'Galindo',
  'Gamja Flower',
  'Gantari',
  'Gayathri',
  'Gelasio',
  'Gemunu Libre',
  'Genos',
  'Gentium Book Basic',
  'Gentium Book Plus',
  'Gentium Plus',
  'Geo',
  'Georama',
  'Geostar',
  'Geostar Fill',
  'Germania One',
  'Gideon Roman',
  'Gidugu',
  'Gilda Display',
  'Girassol',
  'Give You Glory',
  'Glass Antiqua',
  'Glegoo',
  'Gloria Hallelujah',
  'Glory',
  'Gluten',
  'Goblin One',
  'Gochi Hand',
  'Goldman',
  'Gorditas',
  'Gothic A1',
  'Gotu',
  'Goudy Bookletter 1911',
  'Gowun Batang',
  'Gowun Dodum',
  'Graduate',
  'Grand Hotel',
  'Grandstander',
  'Grape Nuts',
  'Gravitas One',
  'Great Vibes',
  'Grechen Fuemen',
  'Grenze',
  'Grenze Gotisch',
  'Grey Qo',
  'Griffy',
  'Gruppo',
  'Gudea',
  'Gugi',
  'Gulzar',
  'Gupter',
  'Gurajada',
  'Gwendolyn',
  'Habibi',
  'Hachi Maru Pop',
  'Hahmlet',
  'Halant',
  'Hammersmith One',
  'Hanalei',
  'Hanalei Fill',
  'Handlee',
  'Hanuman',
  'Happy Monkey',
  'Harmattan',
  'Headland One',
  'Heebo',
  'Henny Penny',
  'Hepta Slab',
  'Herr Von Muellerhoff',
  'Hi Melody',
  'Hina Mincho',
  'Hind',
  'Hind Guntur',
  'Hind Madurai',
  'Hind Siliguri',
  'Hind Vadodara',
  'Holtwood One SC',
  'Homemade Apple',
  'Homenaje',
  'Hubballi',
  'Hurricane',
  'IBM Plex Mono',
  'IBM Plex Sans',
  'IBM Plex Sans Arabic',
  'IBM Plex Sans Condensed',
  'IBM Plex Sans Devanagari',
  'IBM Plex Sans Hebrew',
  'IBM Plex Sans KR',
  'IBM Plex Sans Thai',
  'IBM Plex Sans Thai Looped',
  'IBM Plex Serif',
  'IM Fell DW Pica',
  'IM Fell DW Pica SC',
  'IM Fell Double Pica',
  'IM Fell Double Pica SC',
  'IM Fell English',
  'IM Fell English SC',
  'IM Fell French Canon',
  'IM Fell French Canon SC',
  'IM Fell Great Primer',
  'IM Fell Great Primer SC',
  'Ibarra Real Nova',
  'Iceberg',
  'Iceland',
  'Imbue',
  'Imperial Script',
  'Imprima',
  'Inconsolata',
  'Inder',
  'Indie Flower',
  'Ingrid Darling',
  'Inika',
  'Inknut Antiqua',
  'Inria Sans',
  'Inria Serif',
  'Inspiration',
  'Inter',
  'Irish Grover',
  'Island Moments',
  'Istok Web',
  'Italiana',
  'Italianno',
  'Itim',
  'Jacques Francois',
  'Jacques Francois Shadow',
  'Jaldi',
  'JetBrains Mono',
  'Jim Nightshade',
  'Joan',
  'Jockey One',
  'Jolly Lodger',
  'Jomhuria',
  'Jomolhari',
  'Josefin Sans',
  'Josefin Slab',
  'Jost',
  'Joti One',
  'Jua',
  'Judson',
  'Julee',
  'Julius Sans One',
  'Junge',
  'Jura',
  'Just Another Hand',
  'Just Me Again Down Here',
  'K2D',
  'Kadwa',
  'Kaisei Decol',
  'Kaisei HarunoUmi',
  'Kaisei Opti',
  'Kaisei Tokumin',
  'Kalam',
  'Kameron',
  'Kanit',
  'Kantumruy Pro',
  'Karantina',
  'Karla',
  'Karma',
  'Katibeh',
  'Kaushan Script',
  'Kavivanar',
  'Kavoon',
  'Kdam Thmor Pro',
  'Keania One',
  'Kelly Slab',
  'Kenia',
  'Khand',
  'Khula',
  'Kings',
  'Kirang Haerang',
  'Kite One',
  'Kiwi Maru',
  'Klee One',
  'Knewave',
  'KoHo',
  'Kodchasan',
  'Koh Santepheap',
  'Kolker Brush',
  'Kosugi',
  'Kosugi Maru',
  'Kotta One',
  'Koulen',
  'Kranky',
  'Kreon',
  'Kristi',
  'Krona One',
  'Krub',
  'Kufam',
  'Kulim Park',
  'Kumar One',
  'Kumar One Outline',
  'Kumbh Sans',
  'Kurale',
  'La Belle Aurore',
  'Lacquer',
  'Laila',
  'Lakki Reddy',
  'Lalezar',
  'Lancelot',
  'Langar',
  'Lateef',
  'Lato',
  'Lavishly Yours',
  'League Gothic',
  'League Script',
  'League Spartan',
  'Leckerli One',
  'Ledger',
  'Lekton',
  'Lemon',
  'Lemonada',
  'Lexend',
  'Lexend Deca',
  'Lexend Exa',
  'Lexend Giga',
  'Lexend Mega',
  'Lexend Peta',
  'Lexend Tera',
  'Lexend Zetta',
  'Libre Barcode 128',
  'Libre Barcode 128 Text',
  'Libre Barcode 39',
  'Libre Barcode 39 Extended',
  'Libre Barcode 39 Extended Text',
  'Libre Barcode 39 Text',
  'Libre Barcode EAN13 Text',
  'Libre Baskerville',
  'Libre Bodoni',
  'Libre Caslon Display',
  'Libre Caslon Text',
  'Libre Franklin',
  'Licorice',
  'Life Savers',
  'Lilita One',
  'Lily Script One',
  'Limelight',
  'Linden Hill',
  'Literata',
  'Liu Jian Mao Cao',
  'Livvic',
  'Lobster',
  'Lobster Two',
  'Londrina Outline',
  'Londrina Shadow',
  'Londrina Sketch',
  'Londrina Solid',
  'Long Cang',
  'Lora',
  'Love Light',
  'Love Ya Like A Sister',
  'Loved by the King',
  'Lovers Quarrel',
  'Luckiest Guy',
  'Lusitana',
  'Lustria',
  'Luxurious Roman',
  'Luxurious Script',
  'M PLUS 1',
  'M PLUS 1 Code',
  'M PLUS 1p',
  'M PLUS 2',
  'M PLUS Code Latin',
  'M PLUS Rounded 1c',
  'Ma Shan Zheng',
  'Macondo',
  'Macondo Swash Caps',
  'Mada',
  'Magra',
  'Maiden Orange',
  'Maitree',
  'Major Mono Display',
  'Mako',
  'Mali',
  'Mallanna',
  'Mandali',
  'Manjari',
  'Manrope',
  'Mansalva',
  'Manuale',
  'Marcellus',
  'Marcellus SC',
  'Marck Script',
  'Margarine',
  'Markazi Text',
  'Marko One',
  'Marmelad',
  'Martel',
  'Martel Sans',
  'Marvel',
  'Mate',
  'Mate SC',
  'Maven Pro',
  'McLaren',
  'Mea Culpa',
  'Meddon',
  'MedievalSharp',
  'Medula One',
  'Meera Inimai',
  'Megrim',
  'Meie Script',
  'Meow Script',
  'Merienda',
  'Merienda One',
  'Merriweather',
  'Merriweather Sans',
  'Metal',
  'Metal Mania',
  'Metamorphous',
  'Metrophobic',
  'Michroma',
  'Milonga',
  'Miltonian',
  'Miltonian Tattoo',
  'Mina',
  'Mingzat',
  'Miniver',
  'Miriam Libre',
  'Mirza',
  'Miss Fajardose',
  'Mitr',
  'Mochiy Pop One',
  'Mochiy Pop P One',
  'Modak',
  'Modern Antiqua',
  'Mogra',
  'Mohave',
  'Molengo',
  'Monda',
  'Monofett',
  'Monoton',
  'Monsieur La Doulaise',
  'Montaga',
  'Montagu Slab',
  'MonteCarlo',
  'Montez',
  'Montserrat',
  'Montserrat Alternates',
  'Montserrat Subrayada',
  'Moo Lah Lah',
  'Moon Dance',
  'Moul',
  'Moulpali',
  'Mountains of Christmas',
  'Mouse Memoirs',
  'Mr Bedfort',
  'Mr Dafoe',
  'Mr De Haviland',
  'Mrs Saint Delafield',
  'Mrs Sheppards',
  'Ms Madi',
  'Mukta',
  'Mukta Mahee',
  'Mukta Malar',
  'Mukta Vaani',
  'Mulish',
  'Murecho',
  'MuseoModerno',
  'My Soul',
  'Mystery Quest',
  'NTR',
  'Nanum Brush Script',
  'Nanum Gothic',
  'Nanum Gothic Coding',
  'Nanum Myeongjo',
  'Nanum Pen Script',
  'Neonderthaw',
  'Nerko One',
  'Neucha',
  'Neuton',
  'New Rocker',
  'New Tegomin',
  'News Cycle',
  'Newsreader',
  'Niconne',
  'Niramit',
  'Nixie One',
  'Nobile',
  'Nokora',
  'Norican',
  'Nosifer',
  'Notable',
  'Nothing You Could Do',
  'Noticia Text',
  'Noto Sans',
  'Noto Sans Display',
  'Noto Sans HK',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans Math',
  'Noto Sans Mono',
  'Noto Sans SC',
  'Noto Sans Symbols',
  'Noto Sans TC',
  'Noto Serif',
  'Noto Serif Display',
  'Noto Serif JP',
  'Noto Serif KR',
  'Noto Serif SC',
  'Noto Serif TC',
  'Nova Cut',
  'Nova Flat',
  'Nova Mono',
  'Nova Oval',
  'Nova Round',
  'Nova Script',
  'Nova Slim',
  'Nova Square',
  'Numans',
  'Nunito',
  'Nunito Sans',
  'Nuosu SIL',
  'Odibee Sans',
  'Odor Mean Chey',
  'Offside',
  'Oi',
  'Old Standard TT',
  'Oldenburg',
  'Ole',
  'Oleo Script',
  'Oleo Script Swash Caps',
  'Oooh Baby',
  'Open Sans',
  'Oranienbaum',
  'Orbitron',
  'Oregano',
  'Orelega One',
  'Orienta',
  'Original Surfer',
  'Oswald',
  'Outfit',
  'Over the Rainbow',
  'Overlock',
  'Overlock SC',
  'Overpass',
  'Overpass Mono',
  'Ovo',
  'Oxanium',
  'Oxygen',
  'Oxygen Mono',
  'PT Mono',
  'PT Sans',
  'PT Sans Caption',
  'PT Sans Narrow',
  'PT Serif',
  'PT Serif Caption',
  'Pacifico',
  'Padauk',
  'Palanquin',
  'Palanquin Dark',
  'Pangolin',
  'Paprika',
  'Parisienne',
  'Passero One',
  'Passion One',
  'Passions Conflict',
  'Pathway Gothic One',
  'Patrick Hand',
  'Patrick Hand SC',
  'Pattaya',
  'Patua One',
  'Pavanam',
  'Paytone One',
  'Peddana',
  'Peralta',
  'Permanent Marker',
  'Petemoss',
  'Petit Formal Script',
  'Petrona',
  'Philosopher',
  'Piazzolla',
  'Piedra',
  'Pinyon Script',
  'Pirata One',
  'Plaster',
  'Play',
  'Playball',
  'Playfair Display',
  'Playfair Display SC',
  'Plus Jakarta Sans',
  'Podkova',
  'Poiret One',
  'Poller One',
  'Poly',
  'Pompiere',
  'Pontano Sans',
  'Poor Story',
  'Poppins',
  'Port Lligat Sans',
  'Port Lligat Slab',
  'Potta One',
  'Pragati Narrow',
  'Praise',
  'Prata',
  'Preahvihear',
  'Press Start 2P',
  'Pridi',
  'Princess Sofia',
  'Prociono',
  'Prompt',
  'Prosto One',
  'Proza Libre',
  'Public Sans',
  'Puppies Play',
  'Puritan',
  'Purple Purse',
  'Qahiri',
  'Quando',
  'Quantico',
  'Quattrocento',
  'Quattrocento Sans',
  'Questrial',
  'Quicksand',
  'Quintessential',
  'Qwigley',
  'Qwitcher Grypen',
  'Racing Sans One',
  'Radio Canada',
  'Radley',
  'Rajdhani',
  'Rakkas',
  'Raleway',
  'Raleway Dots',
  'Ramabhadra',
  'Ramaraja',
  'Rambla',
  'Rammetto One',
  'Rampart One',
  'Ranchers',
  'Rancho',
  'Ranga',
  'Rasa',
  'Rationale',
  'Ravi Prakash',
  'Readex Pro',
  'Recursive',
  'Red Hat Display',
  'Red Hat Mono',
  'Red Hat Text',
  'Red Rose',
  'Redacted',
  'Redacted Script',
  'Redressed',
  'Reem Kufi',
  'Reenie Beanie',
  'Reggae One',
  'Revalia',
  'Rhodium Libre',
  'Ribeye',
  'Ribeye Marrow',
  'Righteous',
  'Risque',
  'Road Rage',
  'Roboto',
  'Roboto Condensed',
  'Roboto Flex',
  'Roboto Mono',
  'Roboto Serif',
  'Roboto Slab',
  'Rochester',
  'Rock Salt',
  'RocknRoll One',
  'Rokkitt',
  'Romanesco',
  'Ropa Sans',
  'Rosario',
  'Rosarivo',
  'Rouge Script',
  'Rowdies',
  'Rozha One',
  'Rubik',
  'Rubik Beastly',
  'Rubik Bubbles',
  'Rubik Glitch',
  'Rubik Microbe',
  'Rubik Mono One',
  'Rubik Moonrocks',
  'Rubik Puddles',
  'Rubik Wet Paint',
  'Ruda',
  'Rufina',
  'Ruge Boogie',
  'Ruluko',
  'Rum Raisin',
  'Ruslan Display',
  'Russo One',
  'Ruthie',
  'Rye',
  'STIX Two Text',
  'Sacramento',
  'Sahitya',
  'Sail',
  'Saira',
  'Saira Condensed',
  'Saira Extra Condensed',
  'Saira Semi Condensed',
  'Saira Stencil One',
  'Salsa',
  'Sanchez',
  'Sancreek',
  'Sansita',
  'Sansita Swashed',
  'Sarabun',
  'Sarala',
  'Sarina',
  'Sarpanch',
  'Sassy Frass',
  'Satisfy',
  'Sawarabi Gothic',
  'Sawarabi Mincho',
  'Scada',
  'Scheherazade New',
  'Schoolbell',
  'Scope One',
  'Seaweed Script',
  'Secular One',
  'Sedgwick Ave',
  'Sedgwick Ave Display',
  'Sen',
  'Send Flowers',
  'Sevillana',
  'Seymour One',
  'Shadows Into Light',
  'Shadows Into Light Two',
  'Shalimar',
  'Shanti',
  'Share',
  'Share Tech',
  'Share Tech Mono',
  'Shippori Antique',
  'Shippori Antique B1',
  'Shippori Mincho',
  'Shippori Mincho B1',
  'Shojumaru',
  'Short Stack',
  'Shrikhand',
  'Sigmar One',
  'Signika',
  'Signika Negative',
  'Simonetta',
  'Single Day',
  'Sintony',
  'Sirin Stencil',
  'Six Caps',
  'Skranji',
  'Slabo 13px',
  'Slabo 27px',
  'Slackey',
  'Smokum',
  'Smooch',
  'Smooch Sans',
  'Smythe',
  'Sniglet',
  'Snippet',
  'Snowburst One',
  'Sofadi One',
  'Sofia',
  'Solway',
  'Song Myung',
  'Sonsie One',
  'Sora',
  'Sorts Mill Goudy',
  'Source Code Pro',
  'Source Sans 3',
  'Source Sans Pro',
  'Source Serif 4',
  'Source Serif Pro',
  'Space Grotesk',
  'Space Mono',
  'Special Elite',
  'Spectral',
  'Spectral SC',
  'Spicy Rice',
  'Spinnaker',
  'Spirax',
  'Splash',
  'Spline Sans',
  'Spline Sans Mono',
  'Squada One',
  'Square Peg',
  'Sree Krushnadevaraya',
  'Sriracha',
  'Srisakdi',
  'Staatliches',
  'Stalemate',
  'Stalinist One',
  'Stardos Stencil',
  'Stick',
  'Stick No Bills',
  'Stint Ultra Condensed',
  'Stint Ultra Expanded',
  'Stoke',
  'Strait',
  'Style Script',
  'Stylish',
  'Sue Ellen Francisco',
  'Suez One',
  'Sulphur Point',
  'Sumana',
  'Sunshiney',
  'Supermercado One',
  'Sura',
  'Suranna',
  'Suravaram',
  'Suwannaphum',
  'Swanky and Moo Moo',
  'Syncopate',
  'Syne',
  'Syne Mono',
  'Syne Tactile',
  'Tai Heritage Pro',
  'Tajawal',
  'Tangerine',
  'Tapestry',
  'Taprom',
  'Tauri',
  'Taviraj',
  'Teko',
  'Telex',
  'Tenali Ramakrishna',
  'Tenor Sans',
  'Text Me One',
  'Texturina',
  'Thasadith',
  'The Girl Next Door',
  'The Nautigal',
  'Tienne',
  'Tillana',
  'Timmana',
  'Tinos',
  'Tiro Bangla',
  'Tiro Devanagari Hindi',
  'Tiro Devanagari Marathi',
  'Tiro Devanagari Sanskrit',
  'Tiro Gurmukhi',
  'Tiro Kannada',
  'Tiro Tamil',
  'Tiro Telugu',
  'Titan One',
  'Titillium Web',
  'Tomorrow',
  'Tourney',
  'Trade Winds',
  'Train One',
  'Trirong',
  'Trispace',
  'Trocchi',
  'Trochut',
  'Truculenta',
  'Trykker',
  'Tulpen One',
  'Turret Road',
  'Twinkle Star',
  'Ubuntu',
  'Ubuntu Condensed',
  'Ubuntu Mono',
  'Uchen',
  'Ultra',
  'Uncial Antiqua',
  'Underdog',
  'Unica One',
  'UnifrakturMaguntia',
  'Unkempt',
  'Unlock',
  'Unna',
  'Updock',
  'Urbanist',
  'VT323',
  'Vampiro One',
  'Varela',
  'Varela Round',
  'Varta',
  'Vast Shadow',
  'Vazirmatn',
  'Vesper Libre',
  'Viaoda Libre',
  'Vibes',
  'Vibur',
  'Vidaloka',
  'Viga',
  'Voces',
  'Volkhov',
  'Vollkorn',
  'Vollkorn SC',
  'Voltaire',
  'Vujahday Script',
  'Waiting for the Sunrise',
  'Wallpoet',
  'Walter Turncoat',
  'Warnes',
  'Water Brush',
  'Waterfall',
  'Wellfleet',
  'Wendy One',
  'Whisper',
  'WindSong',
  'Wire One',
  'Work Sans',
  'Xanh Mono',
  'Yaldevi',
  'Yanone Kaffeesatz',
  'Yantramanav',
  'Yatra One',
  'Yellowtail',
  'Yeon Sung',
  'Yeseva One',
  'Yesteryear',
  'Yomogi',
  'Yrsa',
  'Yuji Boku',
  'Yuji Mai',
  'Yuji Syuku',
  'Yusei Magic',
  'ZCOOL KuaiLe',
  'ZCOOL QingKe HuangYou',
  'ZCOOL XiaoWei',
  'Zen Antique',
  'Zen Antique Soft',
  'Zen Dots',
  'Zen Kaku Gothic Antique',
  'Zen Kaku Gothic New',
  'Zen Kurenaido',
  'Zen Loop',
  'Zen Maru Gothic',
  'Zen Old Mincho',
  'Zen Tokyo Zoo',
  'Zeyada',
  'Zhi Mang Xing',
  'Zilla Slab',
  'Zilla Slab Highlight',
])
  // Uncomment for debugging to easily see whether fonts are loaded or not. Falls back to a font that only shows black
  // boxes. See: https://fonts.google.com/specimen/Redacted
  // .map((f) => `${f}, Redacted`)
  .sort();
