import styled from 'styled-components/macro';

interface IMenuButton {
  gap?: number;
  isActive?: boolean;
  className?: string;
}

export const MenuButton = styled.button.attrs<IMenuButton>((props) => ({
  style: {
    gap: `${Number.isFinite(props.gap) ? props.gap : 0.5}rem`,
    backgroundColor: props.isActive ? 'var(--color-cta)' : 'transparent',
  },
}))<IMenuButton>`
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  background: white;

  font-size: 0.9rem;
  line-height: normal;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-cta-light) !important;
  }

  & > .dropdown-control {
    font-size: 10px;
  }
`;
