import React from 'react';
import styles from './RadioButton.module.css';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import { Label } from '../label/Label';
import classNames from 'classnames';

export interface IRadioButton {
  label: string;
  onChange: () => unknown;
  name: string;
  labelHidden?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const RadioButton = ({
  name,
  label,
  onChange,
  labelHidden,
  checked,
  disabled,
  ...rest
}: IRadioButton) => {
  return (
    <div className={styles.wrapper}>
      <Label className={classNames(styles.label, { [styles.disabled]: disabled })}>
        <input
          {...rest}
          checked={checked}
          className={styles.radio}
          name={name}
          onChange={onChange}
          type="radio"
          disabled={disabled}
        />
        {labelHidden ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          <span className={styles.labelText}>{label}</span>
        )}
      </Label>
    </div>
  );
};

export default RadioButton;
