import { Command } from "ckeditor5/src/core";
import {
  VERTICAL_ALIGNMENT,
  VERTICAL_ALIGNMENT_DEFAULT_VALUE,
  VERTICAL_ALIGNMENT_ELEMENT,
} from "./VerticalAlignmentUI";

export default class VerticalAlignmentCommand extends Command {
  constructor(editor) {
    super(editor);
  }

  /**
   * @inheritDoc
   */
  refresh() {
    const model = this.editor.model;
    const doc = model.document;

    const vAlignChild = doc.getRoot().getChild(0);

    if (vAlignChild.name === VERTICAL_ALIGNMENT_ELEMENT) {
      this.value = vAlignChild.getAttribute(VERTICAL_ALIGNMENT);
    } else {
      this.value = VERTICAL_ALIGNMENT_DEFAULT_VALUE;
    }
    this.isEnabled = true;
  }

  /**
   * Executes the command. Applies the `value` of the {@link #attributeKey} to the selection.
   * If no `value` is passed, it removes the attribute from the selection.
   *
   * @protected
   * @param {Object} [options] Options for the executed command.
   * @param {String} [options.value] The value to apply.
   * @fires execute
   */
  execute(options = {}) {
    const model = this.editor.model;
    const root = model.document.getRoot();

    const value = options.value;

    model.change((writer) => {
      const vAlign = writer.createElement(VERTICAL_ALIGNMENT_ELEMENT, {
        [VERTICAL_ALIGNMENT]: value,
      });
      const firstChild = root.getChild(0);
      if (firstChild && firstChild.name === VERTICAL_ALIGNMENT_ELEMENT) {
        writer.setAttribute(VERTICAL_ALIGNMENT, value, firstChild);
      } else {
        writer.wrap(writer.createRangeIn(root), vAlign);
      }
    });
  }
}
