import { FC } from 'react';
import { DatePicker, IDatePickerProps } from './DatePicker';
import { useField } from 'formik';
import { ErrorWrapper, IErrorWrapper } from '../forms/errorWrapper/ErrorWrapper';
import { FormError } from '../forms/formError/FormError';

export interface IDatePickerFormikProps
  extends Omit<IDatePickerProps, 'onChange' | 'selectedDate'>,
    IErrorWrapper {
  name: string;
  className?: string;
  disabled?: boolean;
  onChange?: (value: Date | null) => unknown;
}

export const DatePickerFormik: FC<IDatePickerFormikProps> = ({
  name,
  className,
  onChange,
  disabled = false,
  ...datePickerProps
}) => {
  const [field, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const hasError = !!error && touched;

  return (
    <ErrorWrapper className={className}>
      <DatePicker
        {...datePickerProps}
        disabled={disabled}
        onChange={(date) => {
          helper.setValue(date);
          onChange && onChange(date);
        }}
        onBlur={() => helper.setTouched(true)}
        selectedDate={value}
      />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
