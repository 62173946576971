import React from 'react';
import styles from './CircleButton.module.css';
import { Icon, IIconName } from '../../icons/Icon';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import classNames from 'classnames';

export interface ICircleButton {
  label: string;
  iconName: IIconName;
  onClick: () => unknown;
  actionType?: CircleButtonActionType;
  disabled?: boolean;
  className?: string;
  size?: CircleButtonSize;
  labelHidden?: boolean;
  buttonInnerText?: string;
}

export enum CircleButtonActionType {
  none = 'none',
  next = 'next',
  back = 'back',
  help = 'help',
  save = 'save',
}

export enum CircleButtonSize {
  medium = 'medium',
  large = 'large',
}

export const CircleButton = ({
  label,
  iconName,
  className = '',
  onClick,
  disabled,
  size = CircleButtonSize.medium,
  actionType = CircleButtonActionType.none,
  labelHidden,
  buttonInnerText,
  ...rest
}: ICircleButton) => {
  const sizes: { [key in CircleButtonSize]: string } = {
    medium: styles.medium,
    large: styles.large,
  };

  const actionTypes: { [key in CircleButtonActionType]: string } = {
    none: '',
    next: styles.next,
    back: styles.back,
    help: styles.help,
    save: styles.save,
  };

  return (
    <div>
      <button
        disabled={disabled}
        {...rest}
        onClick={onClick}
        className={classNames(styles.wrapper, className)}>
        <div
          className={`
          ${styles.iconWrapper} 
          ${actionTypes[actionType]}
          ${
            actionType === CircleButtonActionType.none ? sizes[size] : sizes[CircleButtonSize.large]
          }
          `}>
          <Icon name={iconName} />
        </div>
        {labelHidden || actionType !== CircleButtonActionType.none ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          <span className={styles.text}>{label}</span>
        )}
        {buttonInnerText && <span className={styles.buttonInnerText}>{buttonInnerText}</span>}
      </button>
    </div>
  );
};
