import { addListener, AppStore } from '../redux/store';
import { loadImplicitUserEffect } from './loadImplicitUserEffect';
import { setJwt } from '../auth/authSlice';
import { loadImplicitUser } from './loadImplicitUser';

export async function setupUser(store: AppStore): Promise<void> {
  // this is blocking - if a JWT is present, load the user first before doing
  // anything else - if this call fails, the app will evict the user which
  // will cause a redirect to the login page
  await store.dispatch(loadImplicitUser());

  // register listener to update the user every time the jwt changes
  store.dispatch(
    addListener({
      predicate: (action) => action.type === setJwt.type,
      effect: loadImplicitUserEffect,
    }),
  );
}
