import { ILayer, ILayerDisplay } from './LayersState';
import { CSSProperties, FC, PointerEvent, useCallback, useMemo } from 'react';
import { px } from '../../lib/px';
import { stopPropagation } from '../../lib/stopPropagation';
import { modDeg } from '../../lib/modDeg';
import { BoundingBoxAnchor } from './BoundingBoxAnchor';

export interface IBoundingBoxHandleProps {
  /**
   * The layer owning this bounding box
   */
  layer: ILayer;

  /**
   * Invoked when the user clicks on one of the 8 bounding box handles.
   *
   * As layers may be rotated the `anchor` type only constrains on which axes the layer may be scaled, not the
   * actual direction itself.
   */
  onScaleBegin: (anchor: BoundingBoxAnchor) => void;

  /**
   * The anchor this handle refers to
   */
  anchor: BoundingBoxAnchor;

  /**
   * The size in pixels of the handle to display
   */
  size: number;
}

export const BoundingBoxHandle: FC<IBoundingBoxHandleProps> = ({
  layer,
  onScaleBegin,
  anchor,
  size,
}) => {
  const onPointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (e.button !== 0) {
        return;
      }
      e.stopPropagation();
      onScaleBegin(anchor);
    },
    [onScaleBegin, anchor],
  );

  const style = useMemo(
    () => getHandleStyle(anchor, layer.display, size),
    [layer.display, size, anchor],
  );

  return (
    <div
      className="bounding-box-handle"
      role="button"
      onPointerDown={onPointerDown}
      onClick={stopPropagation}
      style={style}
    />
  );
};

function getCursorByAngle(angle: number): string {
  angle = Math.round(modDeg(angle));
  if (angle === 0 || angle === 180) {
    return 'ew-resize';
  }
  if ((angle > 0 && angle < 90) || (angle > 180 && angle < 270)) {
    return 'nesw-resize';
  }
  if (angle === 90 || angle === 270) {
    return 'ns-resize';
  }
  if ((angle > 90 && angle < 180) || (angle > 270 && angle < 360)) {
    return 'nwse-resize';
  }
  return 'nesw-resize';
}

function getHandleStyle(
  anchor: BoundingBoxAnchor,
  { width, height, rotation }: ILayerDisplay,
  handleSize: number,
): CSSProperties {
  switch (anchor) {
    case BoundingBoxAnchor.Top:
      return {
        left: px(width * 0.5 - handleSize * 0.5),
        top: px(-handleSize * 0.5),
        cursor: getCursorByAngle(90 + rotation),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.TopRight:
      return {
        left: px(width - handleSize * 0.5),
        top: px(-handleSize * 0.5),
        cursor: getCursorByAngle(45 + rotation),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.Right:
      return {
        left: px(width - handleSize * 0.5),
        top: px(height * 0.5 - handleSize * 0.5),
        cursor: getCursorByAngle(rotation),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.BottomRight:
      return {
        left: px(width - handleSize * 0.5),
        top: px(height - handleSize * 0.5),
        cursor: getCursorByAngle(rotation - 45),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.Bottom:
      return {
        left: px(width * 0.5 - handleSize * 0.5),
        top: px(height - handleSize * 0.5),
        cursor: getCursorByAngle(rotation - 90),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.BottomLeft:
      return {
        left: px(-handleSize * 0.5),
        top: px(height - handleSize * 0.5),
        cursor: getCursorByAngle(rotation + 225),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.Left:
      return {
        left: px(-handleSize * 0.5),
        top: px(height * 0.5 - handleSize * 0.5),
        cursor: getCursorByAngle(rotation + 180),
        width: handleSize,
        height: handleSize,
      };
    case BoundingBoxAnchor.TopLeft:
    default:
      return {
        left: px(-handleSize * 0.5),
        top: px(-handleSize * 0.5),
        cursor: getCursorByAngle(rotation + 135),
        width: handleSize,
        height: handleSize,
      };
  }
}
