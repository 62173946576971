import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { LayersPanel } from './Layers/LayersPanel';
import { TextLayersPanel } from './TextLayersPanel';
import { GalleryPanel } from './GalleryPanel';
import { EffectsPanel } from './EffectsPanel';
import { PalettesPanel } from './PalettesPanel';
import { ColorsPanel } from './Colors/ColorsPanel';
import { MeasuresPanel } from './Measures/MeasuresPanel';
import { TemplatesPanel } from './TemplatesPanel';
import { PatternsPanel } from './PatternsPanel';
import { ImageTilingPanel } from './ImageTilingPanel';
import { DepthPanel } from './DepthPanel';
import { HistoryPanel } from './History/HistoryPanel';
import { useDesignerMode, useSidePanel } from '../view/viewSelectors';
import { DesignerMode } from '../designerMode';
import { UploadsPanel } from './UploadsPanel';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { SidePanelId } from './sidePanelId';
import { openSidePanel } from '../view/viewSlice';
import { MenuButtonSidePanel } from '../../uiComponents/MenuButtonSidePanel';
import { CloseIconButton } from '../../uiComponents/CloseIconButton';

const ContentMap: Record<SidePanelId, FC | null> = {
  [SidePanelId.None]: null,
  [SidePanelId.Layers]: LayersPanel,
  [SidePanelId.TextLayers]: TextLayersPanel,
  [SidePanelId.Gallery]: GalleryPanel,
  [SidePanelId.ImageTiling]: ImageTilingPanel,
  [SidePanelId.Palettes]: PalettesPanel,
  [SidePanelId.Effects]: EffectsPanel,
  [SidePanelId.Colors]: ColorsPanel,
  [SidePanelId.Measures]: MeasuresPanel,
  [SidePanelId.Templates]: TemplatesPanel,
  [SidePanelId.Patterns]: PatternsPanel,
  [SidePanelId.Depth]: DepthPanel,
  [SidePanelId.History]: HistoryPanel,
  [SidePanelId.Uploads]: UploadsPanel,
};

export const SidePanel: FC = () => {
  const dispatch = useAppDispatch();
  const mode = useDesignerMode();
  const panelId = useSidePanel();
  const PanelContent = panelId ? ContentMap[panelId] : null;
  const { t } = useTranslation();

  const togglePanel = useCallback(
    (targetPanelId: SidePanelId) => {
      dispatch(openSidePanel(targetPanelId));
    },
    [dispatch],
  );

  const closePanel = useCallback(() => {
    dispatch(openSidePanel(SidePanelId.None));
  }, [dispatch]);

  return (
    <Wrapper>
      {!!PanelContent && (
        <PanelContentWrapper className="standalone-100vh .standalone-100vh-small-screen">
          <CloseIconButton onClick={closePanel} position={{ top: 15, right: 25 }} />
          <PanelContent />
        </PanelContentWrapper>
      )}
      <Menu role="toolbar" className="standalone-100vh .standalone-100vh-small-screen">
        <MenuEntry>
          <PlaceholderEntry isBeforeActive={panelId === SidePanelId.Layers} />
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.Layers}
            isBeforeActive={panelId === SidePanelId.TextLayers}
            onClick={() => togglePanel(SidePanelId.Layers)}>
            <Icon name="ebenen" />
            <span>{t('Objects')}</span>
          </MenuButtonSidePanel>
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.TextLayers}
            isBeforeActive={panelId === SidePanelId.Gallery}
            isAfterActive={panelId === SidePanelId.Layers}
            onClick={() => togglePanel(SidePanelId.TextLayers)}>
            <Icon name="textelemente" />
            {t('Texts')}
          </MenuButtonSidePanel>
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.Gallery}
            isBeforeActive={panelId === SidePanelId.Patterns}
            isAfterActive={panelId === SidePanelId.TextLayers}
            onClick={() => togglePanel(SidePanelId.Gallery)}>
            <Icon name="bilder" />
            {t('ImagesAndLogo')}
          </MenuButtonSidePanel>
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.Patterns}
            isBeforeActive={panelId === SidePanelId.Palettes}
            isAfterActive={panelId === SidePanelId.Gallery}
            onClick={() => togglePanel(SidePanelId.Patterns)}>
            <Icon name="elemente" />
            {t('Elements')}
          </MenuButtonSidePanel>
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.Palettes}
            isBeforeActive={panelId === SidePanelId.Uploads}
            isAfterActive={panelId === SidePanelId.Patterns}
            onClick={() => togglePanel(SidePanelId.Palettes)}>
            <Icon name="farbpalette-control" />
            {t('ColorPalettes')}
          </MenuButtonSidePanel>
        </MenuEntry>
        <MenuEntry>
          <MenuButtonSidePanel
            isActive={panelId === SidePanelId.Uploads}
            isBeforeActive={panelId === SidePanelId.Templates}
            isAfterActive={panelId === SidePanelId.Palettes}
            onClick={() => togglePanel(SidePanelId.Uploads)}>
            <Icon name="upload-control" />
            {t('Uploads')}
          </MenuButtonSidePanel>
        </MenuEntry>
        {mode === DesignerMode.Cover && (
          <MenuEntry>
            <MenuButtonSidePanel
              isActive={panelId === SidePanelId.Templates}
              isAfterActive={panelId === SidePanelId.Uploads}
              onClick={() => togglePanel(SidePanelId.Templates)}>
              <Icon name="vorlagen-control" />
              {t('Templates')}
            </MenuButtonSidePanel>
          </MenuEntry>
        )}
        <MenuEntry>
          <PlaceholderEntry isAfterActive={panelId === SidePanelId.Templates} />
        </MenuEntry>
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  max-width: 400px;
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 77px);
  width: 70px;
  overflow: hidden;
  background-color: var(--color-background-accent);

  @media screen and (max-width: 1499px) {
    height: calc(100vh - 77px);
  }
`;

const PanelContentWrapper = styled.div`
  position: relative;
  display: grid;
  align-content: start;
  max-width: 330px;
  width: 330px;
  padding: 2rem 0 1rem 1rem;
  background-color: var(--color-background);
  border-left: 1px solid var(--color-border);
  height: calc(100vh - 77px);
  overflow-y: auto;

  @media screen and (max-width: 1499px) {
    height: calc(100vh - 77px);
  }
`;

const MenuEntry = styled.li`
  background-color: white;
  display: flex;
  align-items: center;
  justify-items: center;

  .tredition-icon {
    font-size: 28px;
    line-height: 10px;
    color: var(--color-cta);
  }
`;

interface IPlaceholderEntry {
  isBeforeActive?: boolean;
  isAfterActive?: boolean;
}

const PlaceholderEntry = styled.div.attrs<IPlaceholderEntry>((props) => ({
  style: {
    borderBottomLeftRadius: props.isBeforeActive ? '1rem' : undefined,
    borderTopLeftRadius: props.isAfterActive ? '1rem' : undefined,
  },
}))<IPlaceholderEntry>`
  background-color: var(--color-background-accent);
  height: 50px;
  width: 70px;

  @media screen and (max-width: 1499px) {
    height: 1rem;
  }
`;
