import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { GOOGLE_FONTS } from './fonts';

export const CK_CONFIG: EditorConfig = {
  fontFamily: {
    options: GOOGLE_FONTS,
  },
  fontColor: {
    eventOptions: {
      emitEventOnDialogOpen: true,
      preventDialogOpening: true,
    },
    columns: 6,
    documentColors: 60,
  },
  fontBackgroundColor: {
    eventOptions: {
      emitEventOnDialogOpen: true,
      preventDialogOpening: true,
    },
    columns: 6,
    documentColors: 60,
  },
  'vertical-alignment': {
    options: ['top', 'center', 'bottom'],
  },
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      'uppercase',
      'fontFamilyDropdown',
      'fontSizeDropdown',
      'customFontColorEditing',
      'customFontBackgroundColorEditing',
      'alignment',
      'vertical-alignment',
      'line-height',
      'letter-spacing',
      '|',
      'removeFormat',
    ],
  },
};
