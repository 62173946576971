import React, { FC, useContext } from 'react';
import styles from './NewContributorModal.module.css';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextInputFormik } from '../../forms/textInput/TextInput.formik';
import { ContributorRole, IContributor, TitleFromApi } from './interfaces/IContributor';
import { TFunction, useTranslation } from 'react-i18next';
import { RadioGroupFormik } from '../../forms/radioGroup/RadioGroup.formik';
import { SelectFormik } from '../../select/Select.formik';
import { createTitleOptions, Title } from '../../user/title';
import { createContributorSchema } from './ContributorSchema';
import { ActionModal } from '../../modal/ActionModal';
import { toast } from 'react-hot-toast';
import { useAddProjectContributorMutation } from '../../api/treditionApi';
import { EditorContext } from '../EditorContext';

const requestTitle: { [key: string]: string | null } = {
  [Title.none]: 'None',
  [Title.professor]: 'Prof',
  [Title.profDr]: 'ProfDr',
  [Title.doctor]: 'Dr',
};

const mapNewContributorToRequest = (contributor: IContributor) => {
  const {
    firstName,
    lastName,
    companyName,
    role,
    title,
    website,
    additionalDescription: additionalDescriptionForm,
  } = contributor;

  if (!['None', 'Prof', 'ProfDr', 'Dr'].includes(title)) {
    toast.error('Invalid title');
    return null;
  }

  const additionalDescription =
    contributor.role === ContributorRole.FreeText ? additionalDescriptionForm : null;

  if (contributor.isPerson) {
    return {
      firstName,
      lastName,
      role,
      title,
      isPerson: true,
      website,
      additionalDescription,
    };
  } else {
    return {
      companyName,
      role,
      title,
      isPerson: false,
      website,
      additionalDescription,
    };
  }
};

export const getFunctionOptions = (t: TFunction) => {
  return [
    {
      value: ContributorRole.Author,
      label: t('RoleAuthor'),
    },
    {
      value: ContributorRole.ComicIllustrator,
      label: t('RoleComicIllustrator'),
    },
    {
      value: ContributorRole.Photographer,
      label: t('RolePhotographer'),
    },
    {
      value: ContributorRole.FreeText,
      label: t('RoleFreeText'),
    },
    {
      value: ContributorRole.Composer,
      label: t('RoleComposer'),
    },
    {
      value: ContributorRole.Lector,
      label: t('RoleLector'),
    },
    {
      value: ContributorRole.Publisher,
      label: t('RolePublisher'),
    },
    {
      value: ContributorRole.Translator,
      label: t('RoleTranslator'),
    },
    {
      value: ContributorRole.Illustrator,
      label: t('RoleIllustrator'),
    },
    {
      value: ContributorRole.CoverDesign,
      label: t('RoleCoverDesign'),
    },
    {
      value: ContributorRole.TypeSetting,
      label: t('RoleTypeSetting'),
    },
  ];
};

export interface IContributorsFormModal {
  onAddNewContributor: (contributorId: string) => void;
  onCancel: () => void;
}

const initialValues: IContributor = {
  firstName: '',
  lastName: '',
  companyName: '',
  website: '',
  role: ContributorRole.Author,
  additionalDescription: '',
  isPerson: true,
  title: TitleFromApi.None,
};

export const NewContributorModal: FC<IContributorsFormModal> = ({
  onAddNewContributor,
  onCancel,
}) => {
  const {
    state: { projectId },
  } = useContext(EditorContext);

  const [addContributor] = useAddProjectContributorMutation();

  const { t } = useTranslation();

  const formik = useFormik<IContributor>({
    initialValues,
    onSubmit: async (values) => {
      const requestContributor = mapNewContributorToRequest(values);

      if (!requestContributor) return;

      return toast
        .promise(addContributor({ projectId, contributor: requestContributor }).unwrap(), {
          loading: t('CreatingContributor'),
          success: t('ContributorCreatedAndAdded'),
          error: t('ContributorCreationFailed'),
        })
        .then((contributor) => {
          onAddNewContributor(contributor.id);
          return true;
        })
        .catch((e) => {
          // TODO: handle error
          console.error(e);
          return false;
        });
    },
    validationSchema: createContributorSchema(t),
  });

  const { values, errors, handleSubmit, isValid } = formik;

  return (
    <ActionModal
      onOk={handleSubmit}
      onCancel={onCancel}
      shouldCloseOnOverlayClick={false}
      isOpen
      onRequestClose={onCancel}
      okButtonDisabled={!isValid}
      title={t('NewProfile')}>
      <div className={styles.wrapper}>
        <FormikProvider value={formik}>
          <Form className={styles.container}>
            <SelectFormik
              label={`${t('Function')}*`}
              className={styles.select}
              options={getFunctionOptions(t)}
              isDisabled={false}
              name={'role'}
              isLoading={false}
              isMulti={false}
            />
            {values.role === ContributorRole.FreeText && (
              <TextInputFormik
                value={values.additionalDescription}
                name="additionalDescription"
                label={`${t('RoleDescription')}*`}
              />
            )}
            {values.role === ContributorRole.Author && <p>{t('AliasDescription')}</p>}
            <div className={styles.flex}>
              <RadioGroupFormik
                name={'isPerson'}
                options={[
                  {
                    value: true,
                    label: t('Person'),
                  },
                  {
                    value: false,
                    label: t('Company'),
                  },
                ]}
              />
            </div>
            {values.isPerson && (
              <div className={styles.address}>
                <SelectFormik
                  label={t('Title')}
                  className={styles.select}
                  options={createTitleOptions(t)}
                  isDisabled={false}
                  name={'title'}
                  isLoading={false}
                  isMulti={false}
                />
                <TextInputFormik
                  value={values.firstName}
                  name="firstName"
                  label={`${t('FirstName')}`}
                />
                <TextInputFormik value={values.lastName} name="lastName" label={`${t('Name')}*`} />
              </div>
            )}
            {!values.isPerson && (
              <TextInputFormik
                value={values.companyName}
                name="companyName"
                label={`${t('Company')}*`}
              />
            )}
            <TextInputFormik
              placeholder={t('URLPlacerholder')}
              value={values.website}
              name="website"
              label={t('Website')}
            />
          </Form>
        </FormikProvider>
      </div>
    </ActionModal>
  );
};
