import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILayerEffect } from './LayersState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetEffectActionPayload {
  /**
   * The effect changes to apply to all layers matching the {@link filter}.
   *
   * Omitted layer effect properties will be disregarded.
   */
  effect: Partial<ILayerEffect>;

  /**
   * Layers which should receive the new effect configuration.
   *
   * Note that layers which do not support effects will be filtered out in all cases.
   */
  filter: ILayerFilter;
}

/**
 * Applies effects to all layers matching the given filter
 */
export function setEffectReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetEffectActionPayload>,
) {
  filterLayers(state.layers, action.payload.filter).forEach((layer) => {
    if (!layer.allowEffects) {
      return;
    }
    for (const k of Object.keys(action.payload.effect) as (keyof ILayerEffect)[]) {
      const value = action.payload.effect[k];
      let wasUpdated = false;
      if (typeof value === 'number' && !Number.isNaN(value)) {
        layer.effect[k] = value;
        wasUpdated = true;
      }
      if (wasUpdated) {
        layer.updatedAt = Date.now();
      }
    }
  });
}
