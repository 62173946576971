import { CSSProperties, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectStatusBadge.module.css';

export interface IProjectStatusBadgeProps {
  /**
   * The status to display as a badge
   */
  status: string;
}

/**
 * Renders a styled badge to display the status of a project
 */
export const ProjectStatusBadge: FC<IProjectStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();
  const { bgColor, color, label } = useMemo(() => {
    const bgColor = 'color-gray-brighter'; // default if not overridden
    const color = 'color-black'; // default if not overridden
    const label = `ProductStatus_${status}`;

    // TODO: → enum to lib

    switch (status) {
      case 'PRINTABLE':
        return { bgColor: 'color-ocean', color: 'color-white', label };
      case 'PUBLISHED':
        return { bgColor: 'color-mint', color: 'color-black', label };
      case 'DELETED':
      case 'DELETEDN':
      case 'SCHEDULEDDELETE':
      case 'BLOCKED':
        return { bgColor: 'color-rubin', color: 'color-white', label };
      case 'SAVED':
        return { bgColor: 'color-light', color: 'color-black', label };
      case 'SUBMITTED':
        return { bgColor: 'color-universe', color: 'color-white', label };
      default:
        return { bgColor, color, label };
    }
  }, [status]);

  return (
    <span
      className={styles.wrapper}
      style={
        {
          ['--bg-color']: `var(--${bgColor})`,
          ['--color']: `var(--${color})`,
        } as CSSProperties
      }>
      {t(label)}
    </span>
  );
};
