import { base64UrlDecode } from '../lib/base64UrlDecode';

export interface IDecodedJwt {
  userId: string; // currently that's all we need
}

/**
 * Decodes a base64-encoded JWT.
 *
 * Returns null if the jwt could not be decoded.
 */
export function decodeJwt(jwt: unknown): IDecodedJwt | null {
  if (typeof jwt !== 'string' || !jwt) {
    return null;
  }
  const parts = jwt.split('.');
  if (parts.length !== 3) {
    return null;
  }
  const [header, claims, sig] = parts;
  try {
    const claimsDecoded = JSON.parse(base64UrlDecode(claims)) as unknown;
    if (typeof claimsDecoded !== 'object' || !claimsDecoded) {
      return null;
    }
    const userId = (claimsDecoded as Record<string, unknown>).UserID || null;
    if (typeof userId !== 'string') {
      return null;
    }
    return {
      userId,
    };
  } catch (error) {
    return null;
  }
}
