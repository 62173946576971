import { IHSV, IRGB } from './Color';

/**
 * @see https://de.wikipedia.org/wiki/HSV-Farbraum → Umrechnung RGB in HSV/HSL
 */
export function hsvToRgb({ h, s, v }: IHSV): IRGB {
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    default:
    case 0:
      return { r: v, g: t, b: p };
    case 1:
      return { r: q, g: v, b: p };
    case 2:
      return { r: p, g: v, b: t };
    case 3:
      return { r: p, g: q, b: v };
    case 4:
      return { r: t, g: p, b: v };
    case 5:
      return { r: v, g: p, b: q };
  }
}
