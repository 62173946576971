import { ILayer } from '../../layer/LayersState';
import { FC, useCallback, useRef, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { scaleLayers } from '../../layer/liveSheetSlice';
import { ScalingOperation } from '../../layer/scaleLayersReducer';
import styled from 'styled-components/macro';
import { NumberInputWrapper } from '../../../uiComponents/NumberInputWrapper';
import { Button } from '../../../uiComponents/Button';
import { AlertBox } from '../../../uiComponents/AlertBox';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../uiComponents/Input';
import { Icon } from '../../../icons/Icon';

export interface IMultiScalerProps {
  selectedLayers: ILayer[];
}

export const MultiScaler: FC<IMultiScalerProps> = ({ selectedLayers }) => {
  const { t } = useTranslation();
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const [isRelative, setIsRelative] = useState<boolean>(true);
  const widthRef = useRef<HTMLInputElement>(null);
  const heightRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const onClickSubmit = useCallback(() => {
    if (!widthRef.current || !heightRef.current) {
      return;
    }
    let width = Number.parseInt(widthRef.current.value);
    let height = Number.parseInt(heightRef.current.value);
    if (Number.isNaN(width) || Number.isNaN(height)) {
      return;
    }
    if (isPercentage) {
      width = Math.max(0, width) / 100;
      height = Math.max(0, height) / 100;
      dispatch(scaleLayers({ x: width, y: height, operation: ScalingOperation.Multiply }));
    } else {
      dispatch(
        scaleLayers({
          x: width,
          y: height,
          operation: isRelative ? ScalingOperation.Add : ScalingOperation.Set,
        }),
      );
    }
  }, [dispatch, widthRef, heightRef, isPercentage, isRelative]);

  return (
    <Wrapper>
      <AlertBox type="warning">
        <Icon name="info-control" />
        <span>️{t('ScalingHint', { 0: selectedLayers.length + 1 })}</span>
      </AlertBox>
      <div>
        <label>
          <input type="radio" checked={!isPercentage} onChange={() => setIsPercentage(false)} />
          {t('Pixel')}
        </label>
        <label>
          <input type="radio" checked={isPercentage} onChange={() => setIsPercentage(true)} />
          {t('Percent')}
        </label>
      </div>
      <div>
        <label>
          <input type="checkbox" checked={isRelative} onChange={() => setIsRelative(!isRelative)} />
          {t('Relative')}
        </label>
      </div>
      <NumberInputWrapper>
        <span>{t('Width')}</span>
        <Input
          type="number"
          ref={widthRef}
          step={1}
          min={isPercentage ? 0 : undefined}
          defaultValue={+isPercentage * 100}
          addendum={isPercentage ? '%' : t('Pixel')}
        />
      </NumberInputWrapper>
      <NumberInputWrapper>
        <span>{t('Height')}</span>
        <Input
          type="number"
          ref={heightRef}
          min={isPercentage ? 0 : undefined}
          step={1}
          defaultValue={+isPercentage * 100}
          addendum={isPercentage ? '%' : t('Pixel')}
        />
      </NumberInputWrapper>
      <FlexContainer>
        <Button onClick={onClickSubmit}>{t('Confirmation')}</Button>
      </FlexContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  padding-right: 1rem;
  gap: 1rem;

  & input {
    accent-color: var(--color-cta);
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
