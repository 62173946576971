import { useDesignerMode } from '../view/viewSelectors';
import { DesignerMode } from '../designerMode';
import { BarcodeExtension } from './barcodeExtension/BarcodeExtension';

/**
 * Renders components which should contribute to the final render of the design project.
 */
export const RenderExtensions = () => {
  const mode = useDesignerMode();
  if (mode === DesignerMode.Cover) {
    return <BarcodeExtension />;
  }
  return null;
};
