import { CSSProperties, FC, Fragment, PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

export interface IPlainModalProps {
  isOpen: boolean;
  onRequestClose?: () => unknown;
}

/**
 * A modal that renders nothing but its children
 *
 * This is currently only used in the template modal and imo should be removed
 */
export const PlainModal: FC<PropsWithChildren<IPlainModalProps>> = ({
  onRequestClose,
  isOpen,
  children,
}) => {
  return (
    <ReactModal
      className="modal-plain"
      style={{
        overlay: {
          zIndex: 9000, // below the toaster please...
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      {children}
    </ReactModal>
  );
};
