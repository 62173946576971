import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';
import styles from './BarcodeImages.module.css';
import { CircleButton } from '../../../buttons/circleButton/CircleButton';
import { InfoButton } from '../../../buttons/info/InfoButton';
import { useTreditionApiUrl } from '../../../config/configSelectors';
import { useFormikContext } from 'formik';
import { IIsbnFormData } from '../IsbnSchema';
import { ProductType } from '../../format/constants/ProductType';
import { normalizeIsbn } from '../utils/IsbnUtils';
import { IIsbnProduct } from '../interfaces/IIsbnPageData';

interface IBarcodeImages {
  products: IIsbnProduct[];
}

export interface IBarcode {
  ean: string;
  bookType: Exclude<ProductType, ProductType.None>;
}

const DEFAULT_BARCODE = '0000000000000';

const Barcode: FC<IBarcode> = ({ ean, bookType }) => {
  const apiUrl = useTreditionApiUrl();

  const { values, errors } = useFormikContext<IIsbnFormData>();

  const eanToUse = useMemo(() => {
    if (values.needIsbn === true) return normalizeIsbn(ean);

    const customIsbn = values.customIsbn[bookType];
    const hasErrors = !!errors?.customIsbn?.[bookType];

    if (hasErrors || !customIsbn || customIsbn.length < 13) return DEFAULT_BARCODE;
    return normalizeIsbn(customIsbn);
  }, [ean, bookType, errors, values.customIsbn, values.needIsbn]);

  if (!eanToUse) return null;

  return <img className={styles.image} src={`${apiUrl}barcode/${eanToUse}`} alt="" />;
};

export const BarcodeSection: React.FC<IBarcodeImages> = ({ products }) => {
  const { t } = useTranslation();
  const apiUrl = useTreditionApiUrl();

  const { values, errors } = useFormikContext<IIsbnFormData>();

  const isDownloadable =
    values.needIsbn === true ||
    !(!!errors.customIsbn && Object.values(errors.customIsbn!).length > 0);

  const downloadBarcodes = () => {
    if (!isDownloadable) return;
    const isbnList = products
      .filter((p) => p.bookType !== ProductType.Ebook)
      .map((p) => normalizeIsbn(p.isbn.trim()));
    const isbnRequestUri = isbnList.map((i) => `eanList=${i}`).join('&');
    window.open(`${apiUrl}barcodes?${isbnRequestUri}`, '_blank');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>
        <h3>{t('Barcodes')}</h3>
        <InfoButton infoText={t('BarcodesInfo')} />
      </div>
      <div className={styles.imageWrapper}>
        {products
          .filter((b) => b.bookType !== ProductType.Ebook)
          .map(({ bookType, isbn }, index) => (
            <Barcode bookType={bookType} key={index} ean={isbn} />
          ))}
      </div>
      <CircleButton
        label={t('Download')}
        iconName="download-control"
        disabled={!isDownloadable}
        onClick={downloadBarcodes}
      />
    </div>
  );
};
