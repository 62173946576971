/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */

import { ChangeEvent, useCallback, useRef } from 'react';
import { UploaderActionType, useUploaderState } from './useUploaderState';
import styled from 'styled-components/macro';
import { Icon } from '../icons/Icon';
import { useTranslation } from 'react-i18next';

// currently only supports single files but may be adapted. if you add multi file support
// make sure to alter the generic interface so the callback types will be correct

export interface IUploaderProps<UploadResult> {
  /**
   * Invoked when the user initiates the upload
   */
  onBeginUpload: (file: File) => Promise<UploadResult>;

  /**
   * Invoked after the upload succeeded
   */
  onUploadSuccess?: (result: UploadResult) => unknown;

  /**
   * Invoked after the upload failed
   */
  onUploadError?: (error: Error) => unknown;
}

export const Uploader = <UploadResult extends unknown>({
  // `extends` is required or else TS parsing will fail
  onUploadError,
  onUploadSuccess,
  onBeginUpload,
}: IUploaderProps<UploadResult>) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useUploaderState();
  const { t } = useTranslation();

  const onChangeFileInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files.length) {
        return;
      }
      dispatch({ type: UploaderActionType.BeginUpload });
      onBeginUpload(e.target.files[0])
        .then((result) => {
          dispatch({ type: UploaderActionType.EndUpload, error: null });
          if (onUploadSuccess) {
            onUploadSuccess(result);
          }
        })
        .catch((error) => {
          dispatch({ type: UploaderActionType.EndUpload, error });
          if (onUploadError) {
            onUploadError(error);
          }
        });
    },
    [dispatch, onBeginUpload, onUploadError, onUploadSuccess],
  );

  return (
    <Wrapper>
      {state.isUploading ? (
        <span>Wird hochgeladen...</span>
      ) : (
        <StyledLabel>
          <input ref={fileInputRef} accept="image/*" type="file" onChange={onChangeFileInput} />
          {!state.file && (
            <FlexWrapper>
              <Circle>
                <Icon name="upload-control" />
              </Circle>
              <StyledText>{t('SelectFile')}</StyledText>
            </FlexWrapper>
          )}
        </StyledLabel>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  gap: 1rem;
  padding-right: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-cta);
  width: 40px;
  height: 40px;
  border-radius: 50%;

  & > svg {
    width: 1rem;
  }
`;

const StyledLabel = styled.label`
  & > input[type='file'] {
    display: none;
  }

  &:hover > ${FlexWrapper} > ${Circle} {
    background-color: var(--color-cta-dark);
  }
`;

const StyledText = styled.span`
  font-weight: 600;
  font-size: 0.9rem;
`;
