import React, { ChangeEvent, useCallback } from 'react';
import { useField } from 'formik';
import CheckBox, { ICheckBox } from './CheckBox';
import { FormError } from '../formError/FormError';
import { ErrorWrapper, IErrorWrapper } from '../errorWrapper/ErrorWrapper';

export interface ICheckBoxFormik extends ICheckBox, IErrorWrapper {
  name: string;
}

export const CheckBoxFormik: React.FC<ICheckBoxFormik> = ({ name, className, ...rest }) => {
  const [, meta, { setValue, setTouched }] = useField(name);
  const { value, error = '', touched } = meta;
  const hasError = !!error && touched;

  const onBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.checked);
    },
    [setValue],
  );

  return (
    <ErrorWrapper data-type="checkBox" className={className}>
      <CheckBox {...rest} onBlur={onBlur} onChange={onChange} checked={value} />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
