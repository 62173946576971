import {
  FC,
  PointerEvent as ReactPointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IPoint } from '../../../@types/IPoint';
import { getCssRgb, IHSV } from '../../lib/Color';
import { hsvToRgb } from '../../lib/hsvToRgb';
import { clamp } from '../../lib/clamp';

export interface ISaturationValueAreaProps {
  /**
   * The current color in HSV
   */
  hsv: IHSV;

  /**
   * Fired when the user clicks on the area.
   *
   * The provided values will be scaled from 0 to 1.
   */
  onPickColor: (saturation: number, value: number) => void;
}

export const SaturationValueArea: FC<ISaturationValueAreaProps> = ({ hsv, onPickColor }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isPicking, setIsPicking] = useState<boolean>(false);

  const background = useMemo<string>(() => {
    return getCssRgb(hsvToRgb({ h: hsv.h, s: 1, v: 1 }));
  }, [hsv.h]);

  const onPointerDown = useCallback(
    (e: ReactPointerEvent<HTMLElement>) => {
      if (!ref.current) {
        return;
      }
      const sv = determineSv(e, ref.current);
      onPickColor(sv.s, sv.v);
      setIsPicking(true);
    },
    [onPickColor],
  );

  useEffect(() => {
    if (!isPicking) {
      return;
    }
    function onPointerMove(e: PointerEvent) {
      if (ref.current) {
        const sv = determineSv(e, ref.current);
        onPickColor(sv.s, sv.v);
      }
    }
    function onPointerUp() {
      setIsPicking(false);
    }
    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
    return () => {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [isPicking, onPickColor]);

  return (
    <div ref={ref} className="saturation-value-area" onPointerDown={onPointerDown}>
      <div className="saturation" style={{ background }}>
        <div className="saturation-white">
          <div className="saturation-black" />
        </div>
      </div>
      <div
        className="indicator"
        style={{
          left: `${hsv.s * 100}%`,
          bottom: `${hsv.v * 100}%`,
        }}
      />
    </div>
  );
};

function determineSv(e: PointerEvent | ReactPointerEvent<HTMLElement>, elem: HTMLElement) {
  // translate click to local coordinates
  const rect = elem.getBoundingClientRect();
  const pos: IPoint = {
    x: Math.round(clamp(e.clientX - rect.x, 0, rect.width)),
    y: Math.round(clamp(e.clientY - rect.y, 0, rect.height)),
  };
  return { s: pos.x / rect.width, v: (rect.height - pos.y) / rect.height };
}
