import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { LayerError } from './LayersState';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetLayerErrorActionPayload {
  /**
   * The layer which the error will be written to
   */
  id: string;

  /**
   * The error code to write
   */
  error: LayerError;
}

export function setLayerErrorReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetLayerErrorActionPayload>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (!layer) {
    return;
  }
  layer.error = action.payload.error;
}
