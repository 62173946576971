import React, { useMemo, useState } from 'react';
import styles from './RegistrationPage.module.css';
import { useTranslation } from 'react-i18next';
import { UnderlinedButton } from '../../buttons/underlined/UnderlinedButton';
import { BasicButton, ButtonSize } from '../../buttons/basic/BasicButton';
import { IconButton } from '../../buttons/icon/IconButton';
import { Form, FormikProvider, useFormik } from 'formik';
import { createRegistrationSchema } from './RegistrationSchema';
import { TextInputFormik } from '../../forms/textInput/TextInput.formik';
import { CheckBoxFormik } from '../../forms/checkBox/CheckBox.formik';
import { Icon } from '../../icons/Icon';
import classNames from 'classnames';
import { useCreateUserMutation } from '../../api/treditionApi';
import { emptyArray } from '../../lib/emptyArray';
import { toast } from 'react-hot-toast';
import { GlobalError } from '../../editor/GlobalError';
import { Spacer } from '../../shared/spacer/Spacer';

export interface IRegistration {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  generalTerms: boolean;
  affiliateId: string | null;
}

const returnToLogin = () => {
  window.open('/login', '_self');
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  emailConfirm: '',
  password: '',
  confirmPassword: '',
  generalTerms: false,
};

type PasswordErrors =
  | 'MinCharacters'
  | 'MinCapitalLetters'
  | 'MinLowerCaseLetters'
  | 'PasswordsNotEqual';

const knownPasswordErrors: { [key in PasswordErrors]: string } = {
  MinCharacters: 'HasMinLength',
  MinCapitalLetters: 'HasUpperCaseLetters',
  MinLowerCaseLetters: 'HasLowerCaseLetters',
  PasswordsNotEqual: 'ConfirmPasswordMatchesPassword',
};

export const RegistrationPage = () => {
  const { t } = useTranslation();
  const [createUser] = useCreateUserMutation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailExists, setEmailExists] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setLoading(true);
      const { emailConfirm, confirmPassword, ...relevantValues } = values;
      setSuccess(false);
      setEmailExists(false);

      const affiliateId = localStorage.getItem('AffiliateId') ?? null;

      const createTask = createUser({ data: { ...relevantValues, affiliateId } }).unwrap();
      toast
        .promise(createTask, {
          loading: t('CreatingUser'),
          error: t('CreateUserError'),
          success: t('CreateUserSuccess'),
        })
        .then(() => {
          setSuccess(true);
        })
        .catch((response) => {
          setEmailExists((response as any)?.status === 409);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
      return createTask;
    },
    validationSchema: createRegistrationSchema(),
    validateOnMount: true,
  });

  const passwordValidation = useMemo(() => {
    const password = formik.values.password;
    const errors: PasswordErrors[] = [];
    if (password.length < 8) {
      errors.push('MinCharacters');
    }
    if (password.match(/(?=.*[A-Z])/) === null) {
      errors.push('MinCapitalLetters');
    }
    if (password.match(/(?=.*[a-z])/) === null) {
      errors.push('MinLowerCaseLetters');
    }
    if (password !== formik.values.confirmPassword) {
      errors.push('PasswordsNotEqual');
    }
    return errors;
  }, [formik.values.confirmPassword, formik.values.password]);

  const { handleSubmit, isValid, setValues } = formik;

  const [firstTextPart, secondTextPart] = t('ConfirmTermsAndConditionsArgs').split('{0}');

  return (
    <div className={styles.wrapper}>
      <div className={styles.wiredGreySpace} />
      {success ? (
        <div className={styles.form}>
          <div className={styles.closeSection}>
            <IconButton
              label={t('Close')}
              iconName={'schliessen-control'}
              onClick={returnToLogin}
              labelHidden
            />
          </div>
          <div className={styles.successWrapper}>
            <h1 className={styles.headline}>{t('RegisterOK')}</h1>
            <p className={styles.text}>{t('RegisterOKDescription')}</p>
          </div>
        </div>
      ) : (
        <div className={styles.form}>
          <div className={styles.closeSection}>
            <IconButton
              label={t('Close')}
              iconName={'schliessen-control'}
              onClick={returnToLogin}
              labelHidden
            />
          </div>
          <div className={styles.inner}>
            <h1>{t('RegisterWelcome')}</h1>
            <div className={styles.returnSection}>
              <span className={styles.text}>{t('HasAlreadyAccount')}</span>
              <UnderlinedButton
                className={styles.backToLogin}
                onClick={returnToLogin}
                label={t('GoToLogin')}
              />
            </div>
            <Spacer size={16} />
            <FormikProvider value={formik}>
              <Form onSubmit={handleSubmit} className={styles.inputs}>
                <div className={styles.flex}>
                  <TextInputFormik
                    className={styles.small}
                    name={'firstName'}
                    label={`${t('FirstName')}*`}
                  />
                  <TextInputFormik
                    className={styles.small}
                    name={'lastName'}
                    label={`${t('LastName')}*`}
                  />
                </div>
                <TextInputFormik name={'email'} label={`${t('EMailAddress')}*`} />
                <TextInputFormik name={'emailConfirm'} label={`${t('ConfirmEMailAddress')}*`} />
                <TextInputFormik password name={'password'} label={`${t('Password')}*`} />
                <TextInputFormik
                  password
                  name={'confirmPassword'}
                  label={`${t('ConfirmPassword')}*`}
                />
                {formik.values.password?.length > 0 && (
                  <div className={styles.passwordChecks}>
                    {Object.entries(knownPasswordErrors).map(([key, translationKey]) => {
                      const valid = !passwordValidation.includes(key as PasswordErrors);
                      const iconName = valid ? 'check-control' : 'schliessen-control';

                      return (
                        <div
                          key={key}
                          className={classNames(styles.check, {
                            [styles.valid]: valid,
                            [styles.invalid]: !valid,
                          })}>
                          <Icon name={iconName} />
                          {t(translationKey)}
                        </div>
                      );
                    })}
                  </div>
                )}
                <CheckBoxFormik
                  name={'generalTerms'}
                  label={''}
                  onChange={(event) => {
                    setValues((v) => ({ ...v, generalTerms: event.target.checked }));
                  }}>
                  {firstTextPart}
                  <a
                    href={'https://tredition.com/agb'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}>
                    {t('ConfirmTermsAndConditionsKeyword')}
                  </a>
                  {secondTextPart}
                </CheckBoxFormik>
                {emailExists && (
                  <GlobalError customErrors={emailExists ? ['UserAlreadyExists'] : emptyArray} />
                )}
                <BasicButton
                  type={'submit'}
                  disabled={loading || !isValid || Object.keys(passwordValidation).length > 0}
                  size={ButtonSize.large}
                  label={t('Register')}
                  className={styles.submit}
                />
              </Form>
            </FormikProvider>
          </div>
        </div>
      )}
      <div className={styles.image} />
    </div>
  );
};
