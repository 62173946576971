import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { getDesignerSlice } from '../designerSelectors';
import { viewSlice } from './viewSlice';

/**
 * Returns the designer's view state
 */
export const getDesignerView = (state: RootState) => getDesignerSlice(state)[viewSlice.name];

/**
 * Returns the designer's current mode
 */
export const getDesignerMode = (state: RootState) => getDesignerView(state).mode;

/**
 * Returns the designer's grid state
 */
export const getGrid = (state: RootState) => getDesignerView(state).grid;

/**
 * Returns the designer's zoom state
 */
export const getZoom = (state: RootState): number => getDesignerView(state).zoom;

/**
 * Returns whether design problems will be shown in the work space
 */
export const getShowProblems = (state: RootState): boolean => getDesignerView(state).showProblems;

/**
 * Returns whether the design modal is visible
 */
export const getShowCopyDesignModal = (state: RootState): boolean =>
  getDesignerView(state).showCopyDesignModal;

/**
 * Returns the selection state of the designer
 */
export const getSelection = (state: RootState) => getDesignerView(state).selection;

/**
 * Returns the `sideMenu` state
 */
export const getSidePanel = (state: RootState) => getDesignerView(state).sidePanel;

/**
 * Provides the selection rect to a React component
 */
export const useSelection = () => useAppSelector(getSelection);

/**
 * Provides the designer's zoom to a React component
 */
export const useZoom = () => useAppSelector(getZoom);

/**
 * Provides the designer's grid state to a React component
 */
export const useGrid = () => useAppSelector(getGrid);

/**
 * Provides the designer's view state to a React component
 */
export const useDesignerView = () => useAppSelector(getDesignerView);

/**
 * Provides the current designer mode to a React component
 */
export const useDesignerMode = () => useAppSelector(getDesignerMode);

/**
 * Provides the `showProblems` state to a React component
 */
export const useShowProblems = () => useAppSelector(getShowProblems);

/**
 * Provides the `showCopyDesignModal` state to a React component
 */
export const useShowCopyDesignModal = () => useAppSelector(getShowCopyDesignModal);

/**
 * Provides the `sideMenu` state to a React component
 */
export const useSidePanel = () => useAppSelector(getSidePanel);
