import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPoint } from '../../../@types/IPoint';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISelectLayersInAreaActionPayload {
  /**
   * The start point spanning the rectangular region
   */
  regionStart: IPoint;

  /**
   * The end point spanning the rectangular region
   */
  regionEnd: IPoint;
}

/**
 * Selects all layers within a rectangular area defined by its diagonal.
 * Note that rotated selections are not supported, so we can deduce all straight rectangle points
 * from only its diagonal.
 *
 * Start and end point may be facing each other in any possible direction.
 */
export function selectLayersInAreaReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISelectLayersInAreaActionPayload>,
) {
  const { regionStart, regionEnd } = action.payload;

  const topLeft = {
    x: Math.min(regionStart.x, regionEnd.x),
    y: Math.min(regionStart.y, regionEnd.y),
  };
  const bottomRight = {
    x: topLeft.x + Math.abs(regionEnd.x - regionStart.x),
    y: topLeft.y + Math.abs(regionEnd.y - regionStart.y),
  };

  state.layers.forEach((layer) => {
    layer.isSelected =
      layer.x >= topLeft.x &&
      layer.x + layer.width <= bottomRight.x &&
      layer.y >= topLeft.y &&
      layer.y + layer.height <= bottomRight.y;
  });
}
