import { Draft } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';

/**
 * Renders all layers deselected
 */
export function deselectLayersReducer(state: Draft<ILiveSheetState>) {
  state.layers.forEach((layer) => {
    layer.isSelected = false;
  });
  state.transform = null;
}
