import { Header, HeaderNavItems } from '../../header/Header';
import styles from './DashboardPage.module.css';
import { CircleButton } from '../../buttons/circleButton/CircleButton';
import { useUser } from '../../user/userSelectors';
import { useTranslation } from 'react-i18next';
import { ProjectsListView } from '../../project/ProjectsListView';
import React, { useCallback } from 'react';
import { ListProjectsOrder, useListProjectsQuery } from '../../api/treditionApi';
import { emptyArray } from '../../lib/emptyArray';
import { push } from 'redux-first-history';
import { useAppDispatch } from '../../redux/hooks';

export const DashboardPage = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const query = useListProjectsQuery({
    order: ListProjectsOrder.Date,
    page: 0,
  });
  const { data: projects = emptyArray } = query;

  const onClickEntry = useCallback(
    (projectId: string) => {
      dispatch(push(`/projects/${projectId}`));
    },
    [dispatch],
  );

  if (!user) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Header />
      <aside className={styles.loginBox}>
        <header className={styles.header}>
          <h1>Hi {user.firstName}, welcome back!</h1>
          <p>Last login: {user.lastLoginAt.toLocaleDateString()}</p>
        </header>
        <img
          src={
            'https://treditionworkerstaging.blob.core.windows.net/pageimages/17045fee-75d1-47d4-b35d-d0a96ff68e9e.png'
          }
          className={styles.image}
          alt="Tredition"
        />
      </aside>
      <div className={styles.content}>
        <div className={styles.navigation}>
          {HeaderNavItems.filter((h) => h.href !== 'dashboard').map(
            ({ external, label, iconName, href }) => {
              return (
                <div
                  key={label}
                  className={styles.button}
                  onClick={() => window.open(href, external ? '_blank' : '_self')}>
                  <div className={styles.iconWrapper}>
                    <CircleButton
                      label={label}
                      labelHidden
                      iconName={iconName}
                      onClick={() => window.open(href, external ? '_blank' : '_self')}
                    />
                  </div>
                  <span>{label}</span>
                </div>
              );
            },
          )}
        </div>
        <div className={styles.currentProjects}>
          <h1 className={styles.headline}>{t('CurrentProjects')}</h1>
          <ProjectsListView entries={projects.slice(0, 3)} onClickEntry={onClickEntry} />
        </div>
      </div>
    </div>
  );
};
