import { Plugin } from "@ckeditor/ckeditor5-core";
import lineHeightIcon from "../../icons/tredition-zeilenabstand-control.svg";
import { createDropdown, View, InputView } from "ckeditor5/src/ui";

const DEFAULT_VALUE = 120;

class LineHeightEditing extends View {
  constructor(locale) {
    super(locale);

    /**
     * The value of the input.
     *
     * @observable
     * @member {String} #value
     * @default ''
     */
    this.set("value", `${DEFAULT_VALUE}`);
    this._dropdownView = this._createDropdownView(locale);

    this.setTemplate({
      tag: "div",
      children: [this._dropdownView],
    });
  }

  _createDropdownView(locale) {
    const dropdownView = createDropdown(locale);
    const t = locale.t;
    const bind = this.bindTemplate;

    dropdownView.class = "ck-dropdown-line-height";

    // Configure dropdown's button properties:
    dropdownView.buttonView.set({
      label: t("Line height"),
      withText: false,
      icon: lineHeightIcon,
      tooltip: true,
    });

    const inputView = new InputView();
    inputView.extendTemplate({
      attributes: {
        class: ["ck", "ck-input-line-height"],
        type: "range",
        min: 1,
        max: 200,
        step: 1,
        value: bind.to("value"),
      },
      on: {
        input: bind.to((evt) => void this.set("value", evt.target.value)),
      },
    });
    inputView.inputMode = "number";
    inputView.value = DEFAULT_VALUE;

    const outputView = new View();
    outputView.setTemplate({
      tag: "span",
      attributes: {
        class: ["ck ck-line-height-value"],
      },
      children: [
        {
          text: bind.to("value"),
        },
      ],
    });

    const panelLabel = new View();
    panelLabel.setTemplate({
      tag: "span",
      attributes: {
        class: ["ck ck-line-height-panel-label"],
      },
      children: [
        {
          text: t("Line height"),
        },
      ],
    });

    const wrapperView = new View(locale);
    wrapperView.setTemplate({
      tag: "div",
      attributes: {
        class: ["ck ck-line-height-wrapper"],
      },
      children: [panelLabel, outputView, inputView],
    });

    dropdownView.panelView.children.add(wrapperView);

    return dropdownView;
  }
}

export default LineHeightEditing;
