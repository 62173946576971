import styles from './AppError.module.css';
import { useAppSelector } from '../redux/hooks';
import { FC, PropsWithChildren } from 'react';

export const AppError: FC<PropsWithChildren> = ({ children }) => {
  // TODO: error boundary impl
  const error = useAppSelector((state) => state.app.error);
  if (error) {
    return (
      <div className={styles.wrapper}>
        <span>{error.message}</span>
        {!!error.details && <code>{error.details}</code>}
      </div>
    );
  }
  return <>{children}</>;
};
