import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { IProduct } from '../project/ProductState';

export function setLayersByProductReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IProduct>,
) {
  state.transform = null;
  const activeSheet = action.payload.sheets.find(($) => $.id === action.payload.activeSheetId);
  state.layers = activeSheet?.layers || [];
}
