import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface ISetContentActionPayload {
  /**
   * HTML book content as string
   */
  bookContent: string;
  tocContent: string;
}

/**
 * Sets the content of a book with the given string
 */
export const setBookContentReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetContentActionPayload>,
) => {
  state.bookContent = action.payload.bookContent;
  state.tocContent = action.payload.tocContent;
};
