import {
  IImageTemplateObject,
  ITemplateObject,
  ITextTemplateObject,
  TemplateObjectImageMode,
  TemplateObjectJustificationType,
  TemplateObjectType,
  TextTemplateObjectAlignment,
} from '../IDesignProduct';
import {
  IImageLayer,
  ILayer,
  ImageLayerReplacementTarget,
  IPlainLayer,
  ITextLayer,
  LayerType,
  TextAlign,
  TextTemplateType,
  TextValign,
} from '../../layer/LayersState';
import { ColorCompat } from './ColorCompat';
import { LayerFactory } from '../../layer/LayerFactory';
import { MatrixCompat } from './MatrixCompat';
import { Rgba } from '../../../lib/Color';

export abstract class LayerCompat {
  public static templateObjectToLayer(o: ITemplateObject): ILayer | null {
    if (o.hidden) {
      return null;
    }
    switch (o.objectType) {
      case TemplateObjectType.Image:
        return LayerCompat.imageObjectToLayer(o as IImageTemplateObject);
      case TemplateObjectType.AuthorTextField:
      case TemplateObjectType.AuthorProfileTextField:
      case TemplateObjectType.TitleTextField:
      case TemplateObjectType.SubtitleTextField:
      case TemplateObjectType.ImprintTextField:
      case TemplateObjectType.Text:
        return LayerCompat.textObjectToLayer(o as ITextTemplateObject);
      case TemplateObjectType.Box:
        return LayerCompat.boxObjectToLayer(o);
      default:
        return null;
    }
  }

  public static layerToTemplateObject(layer: ILayer): ITemplateObject | null {
    switch (layer.type) {
      case LayerType.Image:
        return LayerCompat.imageLayerToObject(layer as IImageLayer);
      case LayerType.Text:
        return LayerCompat.textLayerToObject(layer as ITextLayer);
      case LayerType.Plain:
        return LayerCompat.plainLayerToObject(layer as IPlainLayer);
      default:
        return null;
    }
  }

  private static plainLayerToObject(layer: IPlainLayer): ITemplateObject {
    return {
      ...LayerCompat.layerToObjectBaseProperties(layer),
      objectType: TemplateObjectType.Box,
    };
  }

  private static textLayerToObject(layer: ITextLayer): ITextTemplateObject {
    return {
      ...LayerCompat.layerToObjectBaseProperties(layer),
      text: layer.content,
      fontSize: layer.formatting.fontSize,
      textColor: ColorCompat.colorToColorDesc(layer.formatting.color.foreground),
      textBackgroundColor: ColorCompat.colorToColorDesc(layer.formatting.color.background),
      bold: layer.formatting.bold,
      italic: layer.formatting.italic,
      underline: layer.formatting.underline,
      uppercase: layer.formatting.uppercase,
      rotateForBookBack: layer.rotateForBookBack,
      justification: ((): TemplateObjectJustificationType => {
        switch (layer.formatting.align) {
          case TextAlign.Center:
            return TemplateObjectJustificationType.Center;
          case TextAlign.Justify:
            return TemplateObjectJustificationType.Block;
          case TextAlign.Left:
          default:
            return TemplateObjectJustificationType.Left;
          case TextAlign.Right:
            return TemplateObjectJustificationType.Right;
        }
      })(),
      alignment: ((): TextTemplateObjectAlignment => {
        switch (layer.formatting.valign) {
          case TextValign.Top:
            return TextTemplateObjectAlignment.Top;
          default:
          case TextValign.Center:
            return TextTemplateObjectAlignment.Center;
          case TextValign.Bottom:
            return TextTemplateObjectAlignment.Bottom;
        }
      })(),
      charSpacing: Math.round(layer.formatting.letterSpacing * 100),
      lineHeight: Math.round((layer.formatting.lineHeight / 1.2) * 100),
      placeholderDescriptions: layer.placeholders.map(($) => ({
        text: $.text,
        languageCode: $.languageCode,
      })),
    };
  }

  private static imageLayerToObject(layer: IImageLayer): IImageTemplateObject {
    return {
      ...LayerCompat.layerToObjectBaseProperties(layer),
      objectType: TemplateObjectType.Image,
      innerSize: layer.imageSize,
      innerRotationDegrees: layer.imageRotation,
      imageMode: (() => {
        if (layer.repeat) {
          return 2; // Repeat
        }
        if (layer.keepAspectRatio) {
          return 1; // Proportional
        }
        return 0; // FreeScale
      })(),
      imageFilename: layer.fileId,
      replacementFillColors: ColorCompat.replacementsToLegacy(
        ImageLayerReplacementTarget.FillColor,
        layer.replacements,
      ),
      replacementStrokeColors: ColorCompat.replacementsToLegacy(
        ImageLayerReplacementTarget.StrokeColor,
        layer.replacements,
      ),
      strokeWidth: Math.floor(layer.strokeWidth),
    };
  }

  private static layerToObjectBaseProperties(layer: ILayer): ITemplateObject {
    return {
      _id: layer.id.startsWith('O') ? layer.id : `O${layer.id}`,
      hidden: false,
      objectType: ((): TemplateObjectType => {
        switch (
          layer.type // see `tredition.Models.ObjectType`
        ) {
          case LayerType.Image:
            return 0; // Image
          case LayerType.Text:
            switch ((layer as ITextLayer).template) {
              case TextTemplateType.None:
              default:
                return 1; // Text
              case TextTemplateType.Imprint:
                return 9; // ImprintTextField
              case TextTemplateType.Author:
                return 6; // AuthorTextField
              case TextTemplateType.AuthorProfileText:
                return 7; // AuthorProfileTextField
              case TextTemplateType.BookTitle:
                return 4; // TitleTextField
              case TextTemplateType.BookSubtitle:
                return 5; // SubtitleTextField
            }
          case LayerType.Plain:
          default:
            return 11; // Box
        }
      })(),
      aspectRatio: layer.width === 0 ? 1 : layer.height / layer.width,
      backgroundColor: ColorCompat.colorToColorDesc(layer.background.color),
      borderColor: ColorCompat.colorToColorDesc(layer.border.color),
      borderWidth: layer.border.size,
      hue: layer.effect.hueRotate,
      blur: layer.effect.blur,
      padding: layer.padding,
      width: Math.round(layer.width),
      height: Math.round(layer.height),
      left: Math.round(layer.x),
      top: Math.round(layer.y),
      absoluteWidth: Math.round(layer.width),
      absoluteHeight: Math.round(layer.height),
      absoluteLeft: Math.round(layer.x),
      absoluteTop: Math.round(layer.y),
      sepia: layer.effect.sepia,
      grayScale: layer.effect.grayscale,
      shadow: layer.effect.dropShadow,
      transparency: layer.effect.transparency / 10 || 0,
      transformMatrix: MatrixCompat.layerTransformToMatrix(layer.transform),
      zIndex: layer.depth,
    };
  }

  private static imageObjectToLayer(o: IImageTemplateObject): IImageLayer {
    const replacements = [
      ...ColorCompat.replacementsFromLegacy(
        ImageLayerReplacementTarget.FillColor,
        o.replacementFillColors,
      ),
      ...ColorCompat.replacementsFromLegacy(
        ImageLayerReplacementTarget.StrokeColor,
        o.replacementStrokeColors,
      ),
    ];

    const repeat = o.imageMode === TemplateObjectImageMode.Repeat;
    return LayerFactory.ImageLayer({
      ...this.objectToLayerBaseProperties(o),
      imageSize: repeat ? +o.innerSize : 100, // note: current cover designer only allows a size when ImageMode is `Repeat`
      imageRotation: repeat ? +o.innerRotationDegrees : 0,
      strokeWidth: typeof o.strokeWidth === 'number' ? Math.max(o.strokeWidth, 1) : 1,
      fileId: o.imageFilename,
      replacements,
      repeat,
      hasDimensions: true,
      keepAspectRatio: o.imageMode === TemplateObjectImageMode.Proportional,
      isSelected: false,
    });
  }

  private static textObjectToLayer(o: ITextTemplateObject): ITextLayer {
    const baseProperties = LayerCompat.objectToLayerBaseProperties(o);

    return LayerFactory.TextLayer({
      ...baseProperties,
      content: '', // later assigned in ProjectCompat (because we need the language context for dynamic texts)
      placeholders: (o.placeholderDescriptions || []).map(($) => ({
        text: $.text,
        languageCode: $.languageCode,
      })),
      label: (() => {
        switch (o.objectType) {
          default:
            return 'Text';
          case TemplateObjectType.AuthorTextField:
            return 'AuthorTextField';
          case TemplateObjectType.AuthorProfileTextField:
            return 'AuthorProfileTextField';
          case TemplateObjectType.ImprintTextField:
            return 'ImprintTextField';
          case TemplateObjectType.TitleTextField:
            return 'TitleTextField';
          case TemplateObjectType.SubtitleTextField:
            return 'SubtitleTextField';
        }
      })(),
      template: (() => {
        switch (o.objectType) {
          case TemplateObjectType.AuthorTextField:
            return TextTemplateType.Author;
          case TemplateObjectType.AuthorProfileTextField:
            return TextTemplateType.AuthorProfileText;
          case TemplateObjectType.TitleTextField:
            return TextTemplateType.BookTitle;
          case TemplateObjectType.SubtitleTextField:
            return TextTemplateType.BookSubtitle;
          case TemplateObjectType.ImprintTextField:
            return TextTemplateType.Imprint;
          case TemplateObjectType.Text:
          default:
            return TextTemplateType.None;
        }
      })(),
      rotateForBookBack: o.rotateForBookBack,
      formatting: {
        align: (() => {
          switch (o.justification) {
            case TemplateObjectJustificationType.Left:
            default:
              return TextAlign.Left;
            case TemplateObjectJustificationType.Center:
              return TextAlign.Center;
            case TemplateObjectJustificationType.Right:
              return TextAlign.Right;
            case TemplateObjectJustificationType.Block:
              return TextAlign.Justify;
          }
        })(),
        valign: (() => {
          switch (o.alignment) {
            case TextTemplateObjectAlignment.Top:
            default:
              return TextValign.Top;
            case TextTemplateObjectAlignment.Center:
              return TextValign.Center;
            case TextTemplateObjectAlignment.Bottom:
              return TextValign.Bottom;
          }
        })(),
        color: {
          foreground: ColorCompat.colorDescToColor(o.textColor),
          background: ColorCompat.colorDescToColor(o.textBackgroundColor),
        },
        fontSize: o.fontSize,
        bold: o.bold,
        italic: o.italic,
        underline: o.underline,
        uppercase: o.uppercase,
        lineHeight: (o.lineHeight / 100) * 1.2, // not sure why but the value is multiplied by 1.2 when applying the CSS attribute in the legacy customer
        letterSpacing: +o.charSpacing / 100,
      },
    });
  }

  private static boxObjectToLayer(o: ITemplateObject): IPlainLayer {
    return LayerFactory.PlainLayer(LayerCompat.objectToLayerBaseProperties(o));
  }

  private static objectToLayerBaseProperties(o: ITemplateObject): Omit<Partial<ILayer>, 'type'> {
    const border = {
      color: ColorCompat.colorDescToColor(o.borderColor),
      size: +o.borderWidth,
    };
    // afaik this is only the case for border
    if (border.size > 0 && border.color === null) {
      border.color = { rgba: Rgba.black(), paletteIndex: null };
    }

    return {
      id: o._id,
      x: +o.left,
      y: +o.top,
      width: +o.width,
      height: +o.height,
      depth: +o.zIndex,
      transform: MatrixCompat.matrixToLayerTransform(o.transformMatrix),
      background: {
        color: ColorCompat.colorDescToColor(o.backgroundColor),
      },
      border,
      effect: {
        blur: +o.blur,
        dropShadow: +o.shadow,
        sepia: +o.sepia,
        grayscale: +o.grayScale,
        hueRotate: +o.hue,
        transparency: o.transparency * 10,
      },
      isSelected: false,
      padding: +o.padding,
    };
  }
}
