import debounce from 'lodash/debounce';
import { useGetSvgInfoQuery } from '../../../api/treditionApi';
import { IImageLayer, ImageLayerReplacementTarget } from '../../layer/LayersState';
import { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';
import { useEnsuredProjectId } from '../../project/projectSelectors';
import { QueryLoader } from '../../../loader/QueryLoader';
import { ColorDisplay } from '../../palette/ColorDisplay';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ColorPickerModal } from '../../palette/ColorPickerModal';
import { hexStringToRgba } from '../../../lib/hexToRgba';
import { IColor } from '../../palette/IColor';
import { useActivePalette } from '../../project/productSelectors';
import { useAppDispatch } from '../../../redux/hooks';
import { replaceColor, setImageOptions } from '../../layer/liveSheetSlice';
import { Rgba } from '../../../lib/Color';

export interface ISvgColorOptionsProps {
  layer: IImageLayer;
}

enum ColorCommandType {
  SetFillColor,
  SetStrokeColor,
}

interface IColorCommand {
  type: ColorCommandType;
  originalHex: string;
  currentColor: IColor;
}

// constants as defined by the old cover designer (these are non-technical, arbitrary UI limitations)
const MIN_STROKE_WIDTH = 0;
const MAX_STROKE_WIDTH = 100;

/**
 * NOTE: This component must be rewritten once color management in SVGs is handled properly on the
 *       server side. The model is too rigid to create clean components from so the fill & stroke
 *       code block has been copied.
 */

export const SvgColorOptions: FC<ISvgColorOptionsProps> = ({ layer }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const projectId = useEnsuredProjectId();
  const svgInfoQuery = useGetSvgInfoQuery({ projectId, fileId: layer.fileId });
  const [colorCommand, setColorCommand] = useState<IColorCommand | null>(null);
  const palette = useActivePalette();
  const { id, strokeWidth } = layer;

  const onChangeStrokeWidth = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newStrokeWidth = Number.parseInt(e.target.value);
      if (!Number.isFinite(newStrokeWidth) || newStrokeWidth === strokeWidth) {
        return;
      }
      dispatch(setImageOptions({ filter: { id: layer.id }, strokeWidth: newStrokeWidth }));
    },
    [layer.id, strokeWidth, dispatch],
  );

  const onPickColor = useCallback(
    (color: IColor) => {
      if (!colorCommand) {
        return;
      }
      switch (colorCommand.type) {
        case ColorCommandType.SetFillColor:
        case ColorCommandType.SetStrokeColor:
          dispatch(
            replaceColor({
              filter: { id },
              replacement: {
                target:
                  colorCommand.type === ColorCommandType.SetFillColor
                    ? ImageLayerReplacementTarget.FillColor
                    : ImageLayerReplacementTarget.StrokeColor,
                colorOld: colorCommand.originalHex,
                colorNew: color,
              },
            }),
          );
          break;
        default:
      }
      setColorCommand(null);
    },
    [colorCommand, dispatch, id],
  );

  return (
    <QueryLoader query={svgInfoQuery}>
      <h2>{t('Fill')}</h2>
      {!!svgInfoQuery.data && (
        <ul style={{ opacity: svgInfoQuery.isFetching ? 0.25 : 1 }}>
          {svgInfoQuery.data.fillColors.map((originalHex, index) => {
            const replacement = layer.replacements.find(
              ($) =>
                $.target === ImageLayerReplacementTarget.FillColor && $.colorOld === originalHex,
            );
            const onClickReplacement = () => {
              let currentColor: IColor;
              if (replacement) {
                currentColor = replacement.colorNew;
              } else {
                currentColor = {
                  rgba: hexStringToRgba(originalHex) || Rgba.black(),
                  paletteIndex: null,
                };
              }
              setColorCommand({ type: ColorCommandType.SetFillColor, currentColor, originalHex });
            };

            return (
              <Replacement key={index} role="button" onClick={onClickReplacement}>
                {replacement ? (
                  <ColorDisplay rgba={replacement.colorNew.rgba} />
                ) : (
                  <ColorDisplay hex={originalHex} />
                )}
                <span className="replacement-label">
                  {t('Fill')} {index + 1}
                </span>
              </Replacement>
            );
          })}
        </ul>
      )}
      <h2>{t('Stroke')}</h2>
      {!!svgInfoQuery.data && (
        <Fragment>
          <ul style={{ opacity: svgInfoQuery.isFetching ? 0.25 : 1 }}>
            {svgInfoQuery.data.strokeColors.map((originalHex, index) => {
              const replacement = layer.replacements.find(
                ($) =>
                  $.target === ImageLayerReplacementTarget.StrokeColor &&
                  $.colorOld === originalHex,
              );
              const onClickReplacement = () => {
                let currentColor: IColor;
                if (replacement) {
                  currentColor = replacement.colorNew;
                } else {
                  currentColor = {
                    rgba: hexStringToRgba(originalHex) || Rgba.black(),
                    paletteIndex: null,
                  };
                }
                setColorCommand({
                  type: ColorCommandType.SetStrokeColor,
                  currentColor,
                  originalHex,
                });
              };

              return (
                <Replacement key={index} role="button" onClick={onClickReplacement}>
                  {replacement ? (
                    <ColorDisplay rgba={replacement.colorNew.rgba} />
                  ) : (
                    <ColorDisplay hex={originalHex} />
                  )}
                  <span className="replacement-label">
                    {t('Stroke')} {index + 1}
                  </span>
                </Replacement>
              );
            })}
          </ul>
          {svgInfoQuery.data.strokeColors.length > 0 && (
            <StrokeWidth>
              {t('StrokeWidth')}
              <input
                type="range"
                defaultValue={layer.strokeWidth}
                min={MIN_STROKE_WIDTH}
                max={MAX_STROKE_WIDTH}
                step={1}
                onChange={
                  // FIXME: we only want to rerender when releasing otherwise we spam our server
                  //        on each step of the the slider - this is not implemented consistently
                  //        across the browser vendors, neither in onChange, nor onInput.
                  //        to fix this we need to write our own slider component and not use
                  //        the native event to listen for changes
                  debounce(onChangeStrokeWidth, 500)
                }
              />
            </StrokeWidth>
          )}
        </Fragment>
      )}
      {!!colorCommand && (
        <ColorPickerModal
          onPickColor={onPickColor}
          onCancel={() => setColorCommand(null)}
          initialColor={colorCommand.currentColor}
          palette={palette}
        />
      )}
    </QueryLoader>
  );
};

export const StrokeWidth = styled.label`
  display: grid;

  & > input[type='range'] {
    accent-color: var(--color-cta);
    max-width: 70%;
  }
`;

export const Replacement = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
`;
