import { AppListenerEffect } from '../redux/store';
import { loadImplicitUser } from './loadImplicitUser';

/**
 * This effect is intended to be run every time the JWT changes so the user
 * is re-fetched automatically.
 */
export const loadImplicitUserEffect: AppListenerEffect = async (action, listenerApi) => {
  await listenerApi.dispatch(loadImplicitUser());
};
