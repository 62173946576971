import { FC, useContext } from 'react';
import { CircleButton, CircleButtonActionType } from '../buttons/circleButton/CircleButton';
import { useTranslation } from 'react-i18next';
import { EditorContext } from './EditorContext';
import styles from './ActionBar.module.css';
import { noop } from 'lodash';

export const ActionBar: FC = () => {
  const { t } = useTranslation();
  const {
    state: { step, isReadonly },
    goToPreviousStep,
    goToNextStep,
    finishProject,
  } = useContext(EditorContext);

  return (
    <>
      <div className={styles.left}>
        <CircleButton
          label="?"
          className={styles.faqButton}
          onClick={() => window.open('https://tredition.com/support/faq/')}
          iconName="fragezeichen-control"
          actionType={CircleButtonActionType.help}
        />
      </div>
      <div className={styles.right}>
        <CircleButton
          label={t('GoBack')}
          onClick={goToPreviousStep}
          iconName="pfeillinks-control"
          actionType={CircleButtonActionType.back}
        />
        {step.next ? (
          <CircleButton
            label={t('GoForward')}
            onClick={goToNextStep}
            iconName="pfeilrechts-control"
            actionType={CircleButtonActionType.next}
          />
        ) : (
          <CircleButton
            buttonInnerText={t('BEPFinished')}
            label={t('FinishProject')}
            disabled={isReadonly}
            onClick={isReadonly ? noop : finishProject}
            iconName="check-control"
            actionType={CircleButtonActionType.next}
          />
        )}
      </div>
    </>
  );
};
