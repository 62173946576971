import characterSpacingIcon from "../../icons/tredition-zeichenabstand-control.svg";
import { createDropdown, View, InputView } from "ckeditor5/src/ui";

const DEFAULT_VALUE = 0;

class CharacterSpacingEditing extends View {
  constructor(locale) {
    super(locale);

    /**
     * The value of the input.
     *
     * @observable
     * @member {String} #value
     * @default ''
     */
    this.set("value", `${DEFAULT_VALUE}`);
    this._dropdownView = this._createDropdownView(locale);

    this.setTemplate({
      tag: "div",
      children: [this._dropdownView],
    });
  }

  _createDropdownView(locale) {
    const dropdownView = createDropdown(locale);
    const t = locale.t;
    const bind = this.bindTemplate;

    dropdownView.class = "ck-dropdown-character-spacing";

    // Configure dropdown's button properties:
    dropdownView.buttonView.set({
      label: t("Character spacing"),
      withText: false,
      icon: characterSpacingIcon,
      tooltip: true,
    });

    const inputView = new InputView();
    inputView.extendTemplate({
      attributes: {
        class: ["ck", "ck-input-character-spacing"],
        type: "range",
        min: "0",
        max: "200",
        step: "1",

        name: "ck-input-char",
      },
      value: bind.to("value"),
      on: {
        input: bind.to((evt) => void this.set("value", evt.target.value)),
      },
    });
    inputView.inputMode = "number";
    inputView.value = DEFAULT_VALUE;

    const outputView = new View();
    outputView.setTemplate({
      tag: "span",
      attributes: {
        class: ["ck ck-character-spacing-value"],
      },
      children: [
        {
          text: bind.to("value"),
        },
      ],
    });

    const panelLabel = new View();
    panelLabel.setTemplate({
      tag: "span",
      attributes: {
        class: ["ck ck-character-spacing-panel-label"],
      },
      children: [
        {
          text: t("Character spacing"),
        },
      ],
    });

    const wrapperView = new View(locale);
    wrapperView.setTemplate({
      tag: "div",
      attributes: {
        class: ["ck ck-character-spacing-wrapper"],
      },
      children: [panelLabel, outputView, inputView],
    });

    dropdownView.panelView.children.add(wrapperView);

    return dropdownView;
  }
}

export default CharacterSpacingEditing;
