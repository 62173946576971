import { useEditLayer, useLayerById } from '../layer/liveSheetSelectors';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { CKEContext } from './CKEditorProvider';
import { stopPropagation } from '../../lib/stopPropagation';
import { px } from '../../lib/px';
import { OutPortal } from 'react-reverse-portal';
import { ITextLayer } from '../layer/LayersState';
import classNames from 'classnames';
import { getTextLayerContentStyle } from '../layer/TextLayer';

export function getTextEditorContentStyle(textLayer: ITextLayer | null | undefined): CSSProperties {
  if (!textLayer) {
    return { display: 'none' };
  }
  const { display, border } = textLayer;

  return {
    position: 'absolute',
    left: display.x,
    top: display.y,
    width: display.width,
    height: display.height,
    // important: no transform if the result is a noop otherwise we get subpixel errors
    // transform is not handled by getTextLayerContentStyle() but instead by the layer itself
    transform: display.rotation ? `rotate(-${display.rotation}deg)` : undefined,
    pointerEvents: 'none',
    padding: px(border.size),
    fontFamily: 'Book Antiqua',
  };
}

/**
 * A hovering component which is rendered above all layers and their bounding boxes. This component is always
 * present but only renders the editor if a single text layer is selected.
 */
export const TextEditor: FC = () => {
  const ckeContext = useContext(CKEContext);
  const textLayer = useLayerById(ckeContext.layerId || '') as ITextLayer;

  return (
    <div
      className={classNames('text-editor')}
      style={getTextEditorContentStyle(textLayer)}
      onPointerDown={stopPropagation}>
      <div className="content" style={getTextLayerContentStyle(textLayer)}>
        <OutPortal node={ckeContext.portalNode} />
      </div>
    </div>
  );
};
