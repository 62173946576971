import React from 'react';
import styles from './BasicButton.module.css';
import classNames from 'classnames';

export interface IBasicButton {
  onClick?: () => unknown;
  label: string;
  disabled?: boolean;
  size?: ButtonSize;
  className?: string;
  mood?: ButtonType;
  type?: 'button' | 'submit' | 'reset';
  active?: boolean;
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  wide = 'wide',
  large = 'large',
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',

  Link = 'link',
}

export const BasicButton = ({
  onClick,
  label,
  disabled = false,
  size = ButtonSize.medium,
  mood = ButtonType.Primary,
  type,
  className = '',
  active = false,
  ...rest
}: IBasicButton) => {
  const sizes: { [key in ButtonSize]: string } = {
    [ButtonSize.medium]: styles.medium,
    [ButtonSize.small]: styles.small,
    [ButtonSize.wide]: styles.wide,
    [ButtonSize.large]: styles.large,
  };

  return (
    <button
      {...rest}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.wrapper, sizes[size], styles[mood], className, {
        [styles.active]: active,
      })}>
      <span>{label}</span>
    </button>
  );
};
