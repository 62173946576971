import { AppThunk } from '../../redux/store';
import { treditionApi } from '../../api/treditionApi';
import { getProjectSlice } from '../project/projectSelectors';
import { ProjectCompat } from '../project/compat/ProjectCompat';
import { getLanguageCode } from '../../locale/localeSelectors';
import { applyTemplateInternal } from '../project/projectActions';

export const applyTemplate =
  (templateId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const project = getProjectSlice(state);
    const languageCode = getLanguageCode(state);
    if (!templateId || !project || !project.activeProduct) {
      return;
    }
    const updatedDesignProduct = await dispatch(
      treditionApi.endpoints.applyCoverTemplate.initiate({
        projectId: project.id,
        productId: project.activeProduct,
        templateId,
      }),
    ).unwrap();
    const product = ProjectCompat.designProductToState(project, updatedDesignProduct, languageCode);
    dispatch(applyTemplateInternal(product));
  };
