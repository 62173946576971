import React, {
  FC,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MenuButton } from '../uiComponents/MenuButton';
import { Icon } from '../icons/Icon';
import styled from 'styled-components/macro';

export interface IMenuItemProps {
  /**
   * Invoked when this menu item is clicked on, regardless whether it has a submenu or not.
   */
  onClick?: () => unknown;

  /**
   * Render prop which - if set - must be a function that renders a Menu component
   */
  subMenu?: () => ReactElement; // TS does not allow constraining the type

  /**
   * Additional class names to add to this menu item
   */
  className?: string;

  /**
   * Title of the menu button
   */
  title?: string;

  /**
   * Indicator if the menu is active
   */
  isActive?: boolean;
}

export const MenuItem: FC<PropsWithChildren<IMenuItemProps>> = ({
  className,
  title,
  children,
  onClick,
  subMenu,
  isActive,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
  const menuItemRef = useRef<HTMLDivElement>(null);
  const hasSubMenu = !!subMenu;
  const hasButton = hasSubMenu || !!onClick;
  const onClickInternal = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement>) => {
      if ((hasSubMenu && !isSubMenuOpen) || onClick) {
        e.stopPropagation();
      }
      if (hasSubMenu) {
        setIsSubMenuOpen(!isSubMenuOpen);
      }
      onClick && onClick();
    },
    [isSubMenuOpen, hasSubMenu, onClick],
  );

  useEffect(() => {
    if (!isSubMenuOpen) {
      return;
    }

    function onClickAnywhere(e: MouseEvent) {
      if (!menuItemRef.current?.contains(e.target as Node)) {
        setIsSubMenuOpen(false);
      }
    }

    document.addEventListener('click', onClickAnywhere);
    return () => {
      document.removeEventListener('click', onClickAnywhere);
    };
  }, [isSubMenuOpen]);

  return (
    <Wrapper ref={menuItemRef} className={className} role="presentation">
      {hasButton ? (
        <MenuButton
          title={title}
          isActive={isActive}
          gap={0.1}
          role="menuitem"
          onClick={onClickInternal}>
          {children}
          {hasSubMenu && <Icon className="dropdown-control" name="dropdown-control" />}
        </MenuButton>
      ) : (
        children
      )}
      {hasSubMenu && isSubMenuOpen && (
        <SubMenuWrapper className="sub-menu">{subMenu()}</SubMenuWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const SubMenuWrapper = styled.div`
  position: absolute;
  top: calc(100% + var(--spacing-small));
  left: 0;
  width: max-content;
  background-color: white;
  z-index: 2;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
`;
