import TextInput, { ITextInput } from '../textInput/TextInput';
import React from 'react';
import { useField } from 'formik';
import { ErrorWrapper, IErrorWrapper } from '../errorWrapper/ErrorWrapper';
import { FormError } from '../formError/FormError';
import { INumberInput, NumberInput } from './NumberInput';

export interface INumberInputFormik extends INumberInput, IErrorWrapper {
  name: string;
}

export const NumberInputFormik: React.FC<INumberInputFormik> = ({ name, className, ...rest }) => {
  const [field, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const { setTouched, setValue } = helper;
  const hasError = !!error && touched;
  return (
    <ErrorWrapper data-type="number" className={className}>
      <NumberInput
        {...rest}
        hasError={hasError}
        value={value}
        onNumberChange={setValue}
        onBlur={() => setTouched(true)}
        onFocus={() => setTouched(true)}
      />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
