import { StringSchema } from 'yup';
import { t } from 'i18next';
import { normalizeIsbn } from '../../editor/isbnImprint/utils/IsbnUtils';

const ISBN_TREDITION_GROUPS = ['9783347', '9783384'];

export function isbnCharacters(this: StringSchema) {
  return this.test('isbnCharacter', t('ISBNCharacterFormat'), (isbn?: string) => {
    if (!isbn) {
      return true;
    }
    const normIsbn = isbn.replace(/-|\s/g, '');

    return /^\d+$/.test(normIsbn);
  });
}

export function isbnLength(this: StringSchema) {
  return this.test('isbnLength', t('IsbnLength13Chars'), (isbn?: string) => {
    if (!isbn) {
      return true;
    }
    const normIsbn = isbn.replace(/-|\s/g, '');

    return normIsbn.length === 13;
  });
}

export function isbnValid(this: StringSchema) {
  return this.test('isbnValid', t('ISBNInvalid'), (isbn?: string) => {
    if (!isbn) {
      return true;
    }
    const normIsbn = isbn.replace(/-|\s/g, '');

    let sum = 0;
    for (let i = 0; i < 12; ++i) {
      const intVal = parseInt(normIsbn[i], 10);
      // even (from the right) characters get multiplied by 3
      // add the length to align right
      if ((13 + i) % 2 == 0) sum += intVal * 3;
      else sum += intVal;
    }
    return (10 - (sum % 10)) % 10 === parseInt(normIsbn[12], 10);
  });
}

export function isTreditionIsbn(isbn: string) {
  return ISBN_TREDITION_GROUPS.some((group) => normalizeIsbn(isbn).startsWith(group));
}
