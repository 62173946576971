import { AppThunk } from '../../redux/store';
import { getHistoryPageByProductId } from './historySelectors';
import { getActiveProduct } from '../project/productSelectors';
import { restoreProductFromHistoryRecord } from './restoreProductFromHistoryRecord';

/**
 * Undoes the last undo operation, if existing.
 */
export const redo = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const product = getActiveProduct(state);
  if (!product) {
    return;
  }
  const page = getHistoryPageByProductId(state, product.id);
  if (page) {
    dispatch(restoreProductFromHistoryRecord(page.index + 1));
  }
};
