import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { useSelectedLayers } from '../../layer/liveSheetSelectors';
import { setBackground, setBorder } from '../../layer/liveSheetSlice';
import { consolidate } from '../../../lib/consolidate';
import { useColorPickerModal } from '../../palette/useColorPickerModal';
import { useActivePalette } from '../../project/productSelectors';
import { IImageLayer, ILayerColor, LayerType } from '../../layer/LayersState';
import { IColor } from '../../palette/IColor';
import { useTranslation } from 'react-i18next';
import { SvgColorOptions } from './SvgColorOptions';
import { ColorDisplay } from '../../palette/ColorDisplay';
import styled from 'styled-components/macro';
import { SidePanelContentWrapper } from '../SidePanelContentWrapper';
import { Rgba } from '../../../lib/Color';

export const ColorsPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const colorPickerModal = useColorPickerModal();
  const selectedLayers = useSelectedLayers();
  const consideredLayers = useMemo(
    () => selectedLayers.filter(($) => $.allowEffects),
    [selectedLayers],
  );
  const activePalette = useActivePalette();

  const backgroundColor = consolidate(selectedLayers, ($) => $.background.color);
  const borderSize = consolidate(selectedLayers, ($) => $.border.size);
  const borderColor = consolidate(selectedLayers, ($) => $.border.color);
  const colorFallback: ILayerColor = { rgba: Rgba.devoid(), paletteIndex: null };
  const borderDisplayColor = borderColor.value || colorFallback;

  const onChangeBorderSize = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const size = Number.parseInt(e.target.value);
      if (!Number.isNaN(size)) {
        dispatch(setBorder({ border: { size }, filter: { selected: true } }));
      }
    },
    [dispatch],
  );

  const onChangeBackgroundColor = useCallback(
    (color: IColor) => {
      dispatch(setBackground({ background: { color }, filter: { selected: true } }));
    },
    [dispatch],
  );

  const onChangeBorderColor = useCallback(
    (color: IColor) => {
      dispatch(setBorder({ border: { color }, filter: { selected: true } }));
    },
    [dispatch],
  );

  if (!consideredLayers.length) {
    return null;
  }

  const isSingleSvg =
    selectedLayers[0].type === LayerType.Image &&
    ((selectedLayers[0] as IImageLayer).fileId || '').toUpperCase().endsWith('.SVG');

  return (
    <SidePanelContentWrapper>
      {isSingleSvg && (
        <ContentWrapper>
          <SvgColorOptions layer={selectedLayers[0] as IImageLayer} />
        </ContentWrapper>
      )}
      <ContentWrapper>
        <h2>{t('BackgroundColor')}</h2>
        <ColorDisplay
          className="round"
          rgba={backgroundColor.value?.rgba}
          onClick={() =>
            colorPickerModal.open({
              palette: activePalette,
              initialColor: borderDisplayColor,
              onPick: onChangeBackgroundColor,
            })
          }
        />
      </ContentWrapper>
      <ContentWrapper>
        <h2>{t('Border')}</h2>
        <StyledLabel>
          <Description>
            <span>{t('BorderWidth')}</span>
            <span>{borderSize.value}</span>
          </Description>
          <input
            type="range"
            onChange={onChangeBorderSize}
            min={0}
            max={30}
            step={1}
            value={borderSize.value || '0'}
          />
        </StyledLabel>

        <ColorDisplay
          className="round"
          rgba={borderDisplayColor.rgba}
          onClick={() =>
            colorPickerModal.open({
              palette: activePalette,
              initialColor: borderDisplayColor,
              onPick: onChangeBorderColor,
            })
          }
        />
      </ContentWrapper>
      {colorPickerModal.isOpen && colorPickerModal.render()}
    </SidePanelContentWrapper>
  );
};

const StyledLabel = styled.label`
  display: grid;

  & > input[type='range'] {
    accent-color: var(--color-cta);
    max-width: 70%;
  }
`;

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 70%;
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`;
