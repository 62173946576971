import * as yup from 'yup';
import i18n from '../../locale/i18n';
import { ProductType } from './constants/ProductType';

export enum FormatSetting {
  Standard = 'standard',
  Individual = 'individual',
  None = 'none',
}

export const oneOfEnum = <T extends string>(enumObject: { [s: string]: T } | ArrayLike<T>) =>
  yup.mixed<T>().oneOf(Object.values(enumObject) as T[]);

const productSizeSchema = yup.object().shape({
  width: yup
    .number()
    .min(108, ({ min }) => i18n.t('ValidationSizeWidthMin', { min, unit: 'mm' }))
    .max(210, ({ max }) => i18n.t('ValidationSizeWidthMax', { max, unit: 'mm' }))
    .required(),
  height: yup
    .number()
    .min(170, ({ min }) => i18n.t('ValidationSizeHeightMin', { min, unit: 'mm' }))
    .max(297, ({ max }) => i18n.t('ValidationSizeHeightMax', { max, unit: 'mm' }))
    .required(),
});

const productTypeSchema = yup.object().shape({
  enabled: yup.boolean().required(),
  format: yup.mixed<FormatSetting>().oneOf(Object.values(FormatSetting)).required(),
  sizeInMM: yup.object().when('enabled', {
    is: true,
    then: () => productSizeSchema.nonNullable(),
    otherwise: () => productSizeSchema.strip(),
  }),
});

const createShapeForDesignProduct = () => productTypeSchema;

export const formatPageSchema = yup
  .object()
  .shape({
    [ProductType.SoftCover]: createShapeForDesignProduct(),
    [ProductType.HardCover]: createShapeForDesignProduct(),
    [ProductType.Ebook]: yup.object().shape({
      enabled: yup.boolean().required(),
      format: oneOfEnum([FormatSetting.None]).default(FormatSetting.None).required(),
      sizeInMM: yup.object().nullable(),
    }),
    [ProductType.LargePrint]: createShapeForDesignProduct(),
  })
  .test('AtLeastOneProductTypeMustBeEnabled', (value) => {
    if (!value) {
      return false;
    }
    return Object.values(value).some((product) => product.enabled);
  });

export type FormatProductSizeSchema = {
  width: number;
  height: number;
};

export type FormatProductTypeSchema = {
  enabled: boolean;
  format: FormatSetting;
  sizeInMM: FormatProductSizeSchema | null;
  missing: boolean;
  outdated: boolean;
};

export type IFormatPageValueType = {
  [ProductType.SoftCover]: FormatProductTypeSchema;
  [ProductType.HardCover]: FormatProductTypeSchema;
  [ProductType.Ebook]: FormatProductTypeSchema;
  [ProductType.LargePrint]: FormatProductTypeSchema;
};
