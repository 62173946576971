import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface IRemoveLayersActionPayload {
  filter: Omit<ILayerFilter, 'not'>;
}

export function deleteLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IRemoveLayersActionPayload>,
) {
  const {
    payload: { filter },
  } = action;
  // need to invert because we want to *keep* all elements *not* matching the filter
  state.layers = filterLayers(state.layers, { ...filter, not: true });
}
