import { Plugin } from "@ckeditor/ckeditor5-core";

export class CustomFontFamilyUI extends Plugin {
  init() {
    this.editor.ui.componentFactory.add("fontFamilyDropdown", () => {
      const editor = this.editor;
      const t = editor.t;

      const command = editor.commands.get("fontFamily");

      // Use original fontFamily button - we only changes its behavior.
      const dropdownView = editor.ui.componentFactory.create("fontFamily");

      // Show label on dropdown's button.
      dropdownView.buttonView.set("withText", true);

      // Disable icon on the button.
      dropdownView.buttonView.set("icon", false);

      const getCleanFontName = (value) => {
        // If no value is set on the command show 'Default' text.
        // Use t() method to make that string translatable.

        if (!value) {
          return t("Default");
        }

        if (!value.includes(",") || value.includes(" ")) {
          return value.replaceAll("'", "");
        }

        if (!value.includes(",")) {
          return value;
        }

        // Return correct font if there is a list given, e.g. 'Times New Roman', sans-serif
        const fontName = /^'?(.*?)'?,/;
        const matches = fontName.exec(value);

        if (!matches || !matches[1]) return t("Default");
        return matches[1];
      };

      // Bind dropdown's button label to fontFamily value.
      dropdownView.buttonView
        .bind("label")
        .to(command, "value", getCleanFontName);

      dropdownView.buttonView
        .bind("labelStyle")
        .to(command, "value", (value) => {
          if (!value || value === t("Default")) return "";
          return `font-family:${value}`;
        });

      return dropdownView;
    });
  }
}
