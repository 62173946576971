import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { LayerError } from './LayersState';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { ILiveSheetState } from './ILiveSheetState';

export interface IReloadImageLayerReducer {
  /**
   * The id of an image layer to reload
   */
  id: string;
}

export function reloadImageLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IReloadImageLayerReducer>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (!layer) {
    return;
  }
  layer.hasDimensions = false;
  layer.error = LayerError.None;
}
