import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createHtmlPortalNode, HtmlPortalNode, InPortal } from 'react-reverse-portal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-expect-error: Ignore the error until ckeditor 5 react is fully typed, they are actually migrating to ts
import Editor from '@tredition/ckeditor5';
import { CK_CONFIG } from './getCKEditorDesignerConfig';
import { setTextContent } from '../layer/liveSheetSlice';
import { useAppDispatch } from '../../redux/hooks';
import { activateEditorForLayer } from './activateEditorForLayer';

// TODO: these should be provided by CKE itself
export type CKEditorInstance = {
  execute: (cmdName: string, payload?: any) => void;
  setData: (content: string) => void;
  getData: () => string;
  focus: () => void;
  editing: {
    view: {
      focus: () => void;
      blur: () => void;
      document: {
        isFocused: boolean;
      };
    };
  };
  ui: {
    view: {
      toolbar: {
        element: HTMLElement;
      };
    };
  };
};

type ICKEPortalNode = HtmlPortalNode<typeof CKEditor>;

export interface ICKEditorProviderProps {}

export interface ICKEContext {
  /**
   * Current editor instance. Can be null because it is loaded after the designer mounts.
   */
  editor: CKEditorInstance | null;

  /**
   * Id of a text layer which is currently being edited. Null if not exactly one text layer is selected.
   */
  layerId: string | null;

  /**
   * Assigns the id of the text layer which is currently being edited. You may assign null
   * to indicate no layer is currently being edited.
   */
  setLayerId: (id: string | null) => void;

  /**
   * The portal node which can be given to an `<OutPortal />` component to render the editor
   */
  portalNode: ICKEPortalNode;
}

export const CKEContext = React.createContext<ICKEContext>(undefined as never);

/**
 * Intended to provide a single CKEditor instance to its containing scope since initializing the
 * CKE (by mounting the `CKEditor` component) causes massive lag, so inadvertent reinitialization
 * must be avoided at all costs.
 */
export const CKEditorProvider: FC<PropsWithChildren<ICKEditorProviderProps>> = ({ children }) => {
  const [editor, setEditor] = useState<CKEditorInstance | null>(null);
  const [layerId, setLayerId] = useState<string | null>(null);
  const [portalNode] = useState<ICKEPortalNode>(createHtmlPortalNode());
  const dispatch = useAppDispatch();
  const hasUserChange = useRef<boolean>(false);
  const layerIdRef = useRef<string | null>(null);

  const context = useMemo<ICKEContext>(
    () => ({ portalNode, setLayerId, layerId, editor }),
    [portalNode, setLayerId, layerId, editor],
  );

  // activates the editor if a layer is being double click (the double click is registered in `BoundingBox.tsx`)
  // deactivates the editor and potentially submits updated data if the layer is deselected (see `TextLayer.tsx`)
  useEffect(() => {
    if (editor && layerId && layerId !== layerIdRef.current) {
      dispatch(activateEditorForLayer(editor, layerId));
    } else if (hasUserChange.current && editor && !layerId && layerIdRef.current) {
      hasUserChange.current = false;
      dispatch(setTextContent({ id: layerIdRef.current, content: editor.getData() }));
    }
    layerIdRef.current = layerId;
  }, [dispatch, editor, layerId]);

  // specifically track changes the user made (programmatically setting the editor content also triggers `onChange`)
  const onChangeCke = useCallback(() => {
    if (editor && editor.editing.view.document.isFocused) {
      hasUserChange.current = true;
    }
  }, [editor]);

  // cache the created editor instance - ideally this is only invoked once
  const onReadyCke = useCallback(
    (editor: CKEditorInstance) => {
      if (editor) {
        setEditor(editor);
      }
    },
    [setEditor],
  );

  return (
    <CKEContext.Provider value={context}>
      {children}
      <InPortal node={portalNode}>
        <CKEditor editor={Editor} config={CK_CONFIG} onReady={onReadyCke} onChange={onChangeCke} />
      </InPortal>
    </CKEContext.Provider>
  );
};
