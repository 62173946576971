import { createSlice } from '@reduxjs/toolkit';
import { IDesignerTemplateState } from './IDesignerTemplateState';
import { clearProject, setProject } from '../project/projectActions';
import { toggleTemplateModalReducer } from './toggleTemplateModalReducer';
import { setApplyIdReducer } from './setApplyIdReducer';
import { setFilterReducer } from './setFilterReducer';
import { applyClearProjectToTemplateReducer } from './applyClearProjectToTemplateReducer';
import { applySetProjectToTemplateReducer } from './applySetProjectToTemplateReducer';

export const createInitialTemplateState = (): IDesignerTemplateState => ({
  isTemplateModalOpen: false,
  isTemplateSuggestionEnabled: true,
  applyId: null,
  confirm: false,
  filter: {
    tagIds: [],
    genreIds: [],
  },
});

export const templateSlice = createSlice({
  name: 'template',
  initialState: Object.freeze(createInitialTemplateState()),
  reducers: {
    toggleTemplateModal: toggleTemplateModalReducer,
    setApplyId: setApplyIdReducer,
    setFilter: setFilterReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(clearProject, applyClearProjectToTemplateReducer)
      .addCase(setProject, applySetProjectToTemplateReducer),
});

export const { toggleTemplateModal, setApplyId, setFilter } = templateSlice.actions;
