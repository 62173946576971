import { LegacyLanguageCode } from '../locale/language';
import { IUser } from './IUserState';

export interface IApiUser {
  id: string;
  email: string;
  lastLoginAt: string;
  firstName: string;
  lastName: string;
  roles: string[];
  isRegionFixed: boolean;
  language: LegacyLanguageCode;
}

export function transformApiUser(user: IApiUser | null): IUser | null {
  if (!user) {
    return null;
  }
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    roles: user.roles,
    language: user.language,
    lastLoginAt: new Date(user.lastLoginAt),
    isRegionFixed: user.isRegionFixed,
  };
}
