import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Accordion.module.css';
import { Icon } from '../../icons/Icon';
import classNames from 'classnames';

export interface IAccordion {
  label?: string;
  isOpen?: boolean;
  controlled?: boolean;
  className?: string;
  withMarker?: boolean;
  children: React.ReactNode;
}

export const Accordion = ({
  label = '',
  isOpen = false,
  controlled = false,
  withMarker = false,
  children,
  className,
  ...rest
}: IAccordion) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const heading = controlled ? (
    <h3 className={styles.heading}>{label}</h3>
  ) : (
    <button
      type="button"
      className={`${styles.heading} ${styles.button}`}
      onClick={() => setOpen(!open)}>
      {label}
      <Icon name={open ? 'minus-control' : 'plus-control'} size="12px" />
    </button>
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div className={classNames(styles.wrapper, { [styles.withMarker]: withMarker })} {...rest}>
      {label !== '' && heading}
      <AnimatePresence>
        {open && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ type: 'tween', duration: 0.25 }}
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}>
            <motion.div
              className={className}
              variants={{
                collapsed: { opacity: 0 },
                open: { opacity: 1, transition: { delay: 0.1 } },
              }}
              transition={{ duration: 0 }}>
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
