import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { IAddSheetActionPayload } from './projectActions';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';
import { clamp } from '../../lib/clamp';

export function addSheetReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IAddSheetActionPayload>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product) {
    return;
  }
  product.sheets.splice(
    clamp(action.payload.index, 0, product.sheets.length),
    0,
    action.payload.sheet,
  );
  product.activeSheetId = action.payload.sheet.id;
}
