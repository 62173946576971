import styled from 'styled-components/macro';

export const NumberInputWrapper = styled.label`
  display: grid;
  align-items: center;
  grid-template-columns: minmax(8ch, 14ch) 8ch 7ch;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  & input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
    font-weight: 600;
    width: 10ch;
    height: 1.7rem;
    border: 1px solid var(--color-cta);
    text-align: right;
    padding: 0 0.5rem;
  }

  & input:focus {
    background-color: var(--color-cta-light);
    outline: none;
  }
`;
