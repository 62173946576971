import {
  IEditorStep,
  IEditorStepInfo,
  INavigationPromptData,
  StepDirtyFn,
  StepSubmitFn,
  StepValidateFn,
} from './useEditorState';
import { EditorStepKey } from './EditorStepKey';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';

export interface ISaveStepChangesOptions {
  targetStepKey: EditorStepKey;

  /**
   * Step info from the editor state
   */
  stepInfo: IEditorStepInfo;

  /**
   * The current step function to call to save changes
   */
  onSubmit: StepSubmitFn;

  /**
   * The current step function to call to validate changes
   */
  onValidate: StepValidateFn;

  /**
   * The current step function to call to check if there are changes to save
   */
  onCheckDirty: StepDirtyFn;

  /**
   * The function to call to prompt the user to confirm navigating away
   */
  prompt: (message: INavigationPromptData) => void;
}

/**
 * Invokes validation and the submit functions and returns a boolean indicating
 * whether the user may navigate away from the step.
 *
 * When navigating back, the user will be prompted to confirm navigation if the
 * step is dirty and invalid.
 *
 * Forward navigation will always be blocked if the data is invalid.
 */
export async function saveStepChanges(options: ISaveStepChangesOptions): Promise<boolean> {
  const { stepInfo, targetStepKey } = options;
  const currentIndex = options.stepInfo.sequence.findIndex(($) => $ === stepInfo.current);
  const targetIndex = options.stepInfo.sequence.findIndex(($) => $ === targetStepKey);
  if (currentIndex === targetIndex) {
    return false;
  }
  const currentStep = stepInfo.byKey[stepInfo.current];
  const targetStep = stepInfo.byKey[targetStepKey];
  if (!currentStep || !targetStep) {
    console.warn('Current step or target step was not found - corrupted state?', {
      currentStep,
      targetStep,
    });
    return false;
  }
  if (!targetStep.isVisible) {
    return false;
  }
  if (targetIndex < currentIndex) {
    return handleGoingBack(currentStep, targetStep, options);
  }
  return handleGoingForward(currentStep, targetStep, options);
}

async function handleGoingBack(
  currentStep: IEditorStep,
  targetStep: IEditorStep,
  options: ISaveStepChangesOptions,
): Promise<boolean> {
  if (!failsafeCheckDirty(options)) {
    // nothing to save - can go back regardless of validity
    return true;
  }
  // going back is always possible, but prompt if the current step is invalid and dirty
  // as there might be data loss if the user navigates away
  if (!(await failsafeValidate(options))) {
    options.prompt({
      message: 'EditorPromptNavigateBack',
      targetStep: targetStep.key,
    });
    return false;
  }
  return failsafeSubmit(options);
}

async function handleGoingForward(
  currentStep: IEditorStep,
  targetStep: IEditorStep,
  options: ISaveStepChangesOptions,
): Promise<boolean> {
  if (!(await failsafeValidate(options))) {
    if (currentStep.isValid) {
      options.prompt({
        message: 'EditorPromptNavigateForward',
        targetStep: targetStep.key,
      });
    }
    return false;
  }
  // only trigger submits if data actually changed
  if (failsafeCheckDirty(options)) {
    // save dirty, valid changes and navigate only if submit succeeds
    return await failsafeSubmit(options);
  }
  // nothing to save and step is valid
  return true;
}

function failsafeValidate({ stepInfo, onValidate }: ISaveStepChangesOptions): Promise<boolean> {
  return onValidate().catch((error) => {
    console.error(
      `Uncaught promise rejection in ${stepInfo.current} validation function, falling back to false`,
      error,
    );
    return false;
  });
}

function failsafeCheckDirty({ stepInfo, onCheckDirty }: ISaveStepChangesOptions): boolean {
  try {
    return onCheckDirty();
  } catch (error) {
    console.error(
      `Uncaught promise rejection in ${stepInfo.current} checkDirty function, falling back to false`,
      error,
    );
    // return true in this case because we don't want to lose data in case the user actually made changes
    return true;
  }
}

function failsafeSubmit({ stepInfo, onSubmit }: ISaveStepChangesOptions): Promise<boolean> {
  return onSubmit().catch((error) => {
    toast.error(t('EditorSubmitError'));
    console.error(
      "Uncaught promise rejection in 'onSubmitCurrentStep', falling back to false",
      error,
      stepInfo,
    );
    return false;
  });
}
