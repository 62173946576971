import React from 'react';
import './validator.ts';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';
import GlobalStyles from './globalStyles';
import './locale/i18n';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { Toaster } from 'react-hot-toast';
import { createStore } from './redux/store';
import { IConfig } from './config/IConfig';
import { setupConfig } from './config/setupConfig';
import { Router } from './router/Router';
import './index.css';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { loadLanguageResources } from './locale/loadLanguageResources';
import { IsoLanguageCode, LegacyLanguageCode, legacyLanguageCodeToIso } from './locale/language';
import { LanguageLoader } from './locale/LanguageLoader';
import { AppError } from './app/AppError';
import { setupUser } from './user/setupUser';
import { setupSentry } from './sentry/setupSentry';
import { createTrcUnmount } from './interop/createTrcUnmount';

export async function mount(config?: IConfig) {
  const release = await setupSentry();
  const history = createBrowserHistory();
  const reduxHistoryContext = createReduxHistoryContext({
    history,
    reduxTravelling: true,
  });
  const store = createStore({ reduxHistoryContext });
  config = setupConfig(store, config);
  await setupUser(store);

  const rootElement = document.getElementById(config.reactRoot);
  if (!rootElement) {
    throw new Error('Could not find react root element');
  }
  const root = createRoot(rootElement);
  const portalTarget = document.getElementById('toast-portal');

  Modal.setAppElement(rootElement);

  root.render(
    <Provider store={store}>
      <div className="trc" data-v={release}>
        <AppError>
          <LanguageLoader>
            <GlobalStyles />
            <Router history={history} />
            {portalTarget ? createPortal(<Toaster />, portalTarget) : <Toaster />}
          </LanguageLoader>
        </AppError>
      </div>
    </Provider>,
  );

  try {
    store.dispatch(loadLanguageResources(legacyLanguageCodeToIso(config.languageCode)));
  } catch (error) {
    store.dispatch(loadLanguageResources(IsoLanguageCode.DE));
  }

  window.TRC_SET_LANGUAGE = (languageCode: string) => {
    try {
      languageCode = legacyLanguageCodeToIso(languageCode as LegacyLanguageCode);
    } catch (error) {
      console.warn(`Unexpect legacy language code ${languageCode} - falling back to DE`);
    }
    return store.dispatch(loadLanguageResources(languageCode as IsoLanguageCode));
  };
  window.TRC_UNMOUNT = createTrcUnmount(root);
}

window.TRC_MOUNT = (config: IConfig) => mount(config).catch(console.error);

if (process.env.NODE_ENV === 'development') {
  mount().catch(console.error);
}
