import { FC } from 'react';
import { ITreditionPalette } from './TreditionPalette';
import { Palette } from './Palette';
import './PaletteList.css';
import styled from 'styled-components/macro';
import { MenuButton } from '../../uiComponents/MenuButton';
import { StyledCheckBox } from '../../uiComponents/StyledCheckBox';
import { Icon } from '../../icons/Icon';
import { Menu } from '../../menu/Menu';
import { StyledRadioButton } from '../../uiComponents/StyledRadioButton';

export interface IPaletteListProps {
  /**
   * The id of the currently active palette
   */
  activePaletteId: string | null;

  /**
   * The palettes to render
   */
  palettes: ITreditionPalette[];

  /**
   * Invoked when the user clicks on a palette
   */
  onSelectPalette: (palette: ITreditionPalette) => unknown;

  /**
   * Invoked when the user clicks on the edit button of a palette
   */
  onEditPalette?: (palette: ITreditionPalette) => unknown;

  /**
   * Invoked when the user clicks on the delete button of a palette
   */
  onDeletePalette?: (palette: ITreditionPalette) => unknown;
}

export const PaletteList: FC<IPaletteListProps> = ({
  palettes,
  activePaletteId,
  onSelectPalette,
  onEditPalette,
  onDeletePalette,
}) => {
  return (
    <Wrapper>
      {palettes.map((palette) => {
        const isActive = palette.id === activePaletteId;
        return (
          <StyledListItem key={palette.id}>
            <StyledLabel className={isActive ? 'active' : undefined}>
              <Palette palette={palette} />
              <StyledRadioButton checked={isActive} onChange={() => onSelectPalette(palette)} />
            </StyledLabel>
            {(!!onEditPalette || !!onDeletePalette) && (
              <PaletteOperations>
                {!!onEditPalette && (
                  <MenuButton onClick={() => onEditPalette(palette)}>
                    <Icon name="editieren-control" />
                  </MenuButton>
                )}
                {!!onDeletePalette && (
                  <MenuButton onClick={() => onDeletePalette(palette)}>
                    <Icon name="loeschen" />
                  </MenuButton>
                )}
              </PaletteOperations>
            )}
          </StyledListItem>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &.active > .palette {
    outline: 3px solid var(--color-cta);
  }
`;

const StyledListItem = styled.li`
  display: flex;
  align-content: start;
`;

const PaletteOperations = styled.div`
  display: flex;

  & > ${MenuButton} {
    padding: 0 0.25rem;
  }
`;
