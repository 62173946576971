import { useEffect } from 'react';

/**
 * Registers a global pointerdown event listener which will invoke the `onClickOutside` callback
 * if the click was determined to be outside the given element.
 *
 * Note: **Ensure** you are passing a memoized callback otherwise the event listener will be
 *       registered on each render.
 *
 * @param element - The element which defining the 'inside' area
 * @param onClickOutside - Invoked on `pointerdown` outside the element
 * @param isActive - Additional flag to control whether the global pointer listener should be registered.
 */
export function useClickOutside(
  element: HTMLElement | null,
  onClickOutside: () => void,
  isActive = true,
) {
  useEffect(() => {
    if (!isActive || !element) {
      return;
    }

    function onPointerDownAnywhere(e: MouseEvent) {
      if (!element?.contains(e.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('pointerdown', onPointerDownAnywhere);
    return () => {
      document.removeEventListener('pointerdown', onPointerDownAnywhere);
    };
  }, [element, isActive, onClickOutside]);
}
