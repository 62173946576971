import React, { FC, PropsWithChildren } from 'react';
import ReactModal, { Styles } from 'react-modal';
import styled from 'styled-components/macro';
import { Icon } from '../icons/Icon';

export interface IModalProps {
  /**
   * An optional title to display in the header
   */
  title?: string;

  /**
   * Invoked when the user presses ESC or clicks on the X icon
   */
  onRequestClose?: () => unknown;

  /**
   * Whether the modal is currently opened
   */
  isOpen: boolean;

  /**
   * Whether the modal should close when the user clicks on the overlay
   */
  shouldCloseOnOverlayClick?: boolean;
}

const ReactModalStyles: Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9990, // below the toaster please...
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    filter: 'drop-shadow(2px 4px 6px black)',
    border: '1px solid white',
    borderRadius: '5px',
    padding: '0',
  },
};

/**
 * Provides modal functionality, using react-modal internally.
 *
 * Use this instead of the react-modal directly, so we may later swap out or extend the
 * modal library more easily.
 */
export const Modal: FC<PropsWithChildren<IModalProps>> = ({
  title,
  onRequestClose,
  isOpen,
  children,
  shouldCloseOnOverlayClick = true,
  ...rest
}) => {
  return (
    <ReactModal
      className="tredition-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ReactModalStyles}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...rest}>
      <ModalHeader>
        {!!title && <ModalTitle>{title}</ModalTitle>}
        <button className="close" onClick={onRequestClose}>
          <Icon name="schliessen-control" />
        </button>
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
    </ReactModal>
  );
};

export const ModalHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: center;
  padding: 1rem 2rem;
  gap: 2rem;

  .close {
    background: transparent;
    border: none;
    width: 1rem;
    font-size: 1rem;
    line-height: var(--line-height);
    cursor: pointer;
    font-weight: 500;
    grid-column: 2 / span 1;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
`;

export const ModalContent = styled.div`
  padding: 1rem 2rem;
`;
