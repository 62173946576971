import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';
import { ICSSObject } from '../components/form_constant/CSSObject';

export interface ISetFormValuesActionPayload {
  /**
   * isLoading as a boolean
   */
  formValues: ICSSObject;
}

/**
 * Set the content of formValues
 */
export const setFormValuesReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetFormValuesActionPayload>,
) => {
  state.formValues = action.payload.formValues;
};
