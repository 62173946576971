import { FC } from 'react';
import { resolveQueryError } from '../error/resolveQueryError';
import { IQuery } from '../../@types/IQuery';
import { Alert } from '../alert/Alert';
import { QueryErrorDescription } from '../error/QueryErrorDescription';
import { useTranslation } from 'react-i18next';
import { Button } from '../uiComponents/Button';

export const LoadDesignError: FC<{ query: IQuery }> = ({ query }) => {
  const { t } = useTranslation();
  const { isRetryable, type } = resolveQueryError(query);

  return (
    <Alert level="error">
      {t('DesignerLoadError')}: <QueryErrorDescription type={type} />
      {isRetryable && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
          <Button onClick={query.refetch} disabled={query.isFetching}>
            {t(query.isFetching ? 'PleaseWait' : 'Retry')}
          </Button>
        </div>
      )}
    </Alert>
  );
};
