import {
  DesignProblemLevel,
  DesignProblemType,
  ITextContentClippingDesignProblem,
} from './IDesignerProblem';
import { ITextLayer } from '../layer/LayersState';
import { getLayerDomId } from '../layer/getLayerDomId';

export function checkTextLayerClipping(
  layer: ITextLayer,
): ITextContentClippingDesignProblem | null {
  const content = document.querySelector(`#${getLayerDomId(layer.id)} .content`);
  if (!content) {
    return null;
  }
  if (content.scrollWidth <= content.clientWidth && content.scrollHeight <= content.clientHeight) {
    return null;
  }
  return {
    type: DesignProblemType.TextContentClipping,
    level: DesignProblemLevel.Warning,
  };
}
