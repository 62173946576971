import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useSelectedLayers } from '../layer/liveSheetSelectors';
import { adjustDepth } from '../layer/liveSheetSlice';
import { AdjustDepthMode } from '../layer/adjustDepthReducer';
import { MenuButton } from '../../uiComponents/MenuButton';
import { AlertBox } from '../../uiComponents/AlertBox';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';

export const DepthPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const layers = useSelectedLayers();
  const sourceId = layers[0]?.id || null;

  const onClickRaiseDepth = useCallback(() => {
    sourceId && dispatch(adjustDepth({ sourceId, mode: AdjustDepthMode.Raise }));
  }, [dispatch, sourceId]);

  const onClickLowerDepth = useCallback(() => {
    sourceId && dispatch(adjustDepth({ sourceId, mode: AdjustDepthMode.Lower }));
  }, [dispatch, sourceId]);

  const onClickHighestDepth = useCallback(() => {
    sourceId && dispatch(adjustDepth({ sourceId, mode: AdjustDepthMode.RaiseToTop }));
  }, [dispatch, sourceId]);

  const onClickLowestDepth = useCallback(() => {
    sourceId && dispatch(adjustDepth({ sourceId, mode: AdjustDepthMode.LowerToBottom }));
  }, [dispatch, sourceId]);

  return (
    <SidePanelContentWrapper>
      <h2>{t('Position')}</h2>
      {!layers.length && (
        <AlertBox type="hint">
          <Icon name="info-control" />️ {t('NoLayerSelected')}
        </AlertBox>
      )}
      {layers.length > 1 && (
        <AlertBox type="warning">
          <Icon name="info-control" />️{t('MultipleLayerSelected')}
        </AlertBox>
      )}
      {layers.length === 1 && (
        <div>
          <MenuButton onClick={onClickRaiseDepth}>
            <Icon name="ebenenachvorne-control" />
            {t('Front')}
          </MenuButton>
          <MenuButton onClick={onClickLowerDepth}>
            <Icon name="ebenenachhinten-control" />
            {t('Back')}
          </MenuButton>
          <MenuButton onClick={onClickHighestDepth}>
            <Icon name="ganznachvorne-control" />
            {t('First')}
          </MenuButton>
          <MenuButton onClick={onClickLowestDepth}>
            <Icon name="ganznachhinten-control" />
            {t('Last')}
          </MenuButton>
        </div>
      )}
    </SidePanelContentWrapper>
  );
};
