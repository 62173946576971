import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectsListView.module.css';
import { IListProjectsResult } from '../api/treditionApi';
import { ProjectStatusBadge } from './ProjectStatusBadge';
import { CoverImage } from './CoverImage';
import { motion } from 'framer-motion';
import { popIn } from '../motion/variants';

export interface IProjectListViewProps {
  /**
   * Project list results to display
   */
  entries: IListProjectsResult[];

  /**
   * Callback to invoke when the user clicks on a project entry
   *
   * @param projectId The id of the project that was clicked
   */
  onClickEntry: (projectId: string) => void;
}

/**
 * Renders a list of projects as a list view
 */
export const ProjectsListView: FC<IProjectListViewProps> = ({ entries, onClickEntry }) => {
  const { t } = useTranslation();

  if (entries.length === 0) {
    return <div>{t('ProjectListEmpty')}</div>;
  }

  return (
    <motion.ul
      className={styles.ul}
      animate={'visible'}
      initial="hidden"
      variants={popIn.container}>
      {entries.map((entry) => (
        <motion.li key={entry.id} variants={popIn.element}>
          <ProjectListViewEntry entry={entry} onClick={onClickEntry} />
        </motion.li>
      ))}
    </motion.ul>
  );
};

export interface IProjectListViewEntryProps {
  /**
   * The entry to render
   */
  entry: IListProjectsResult;

  /**
   * Callback to invoke when the user clicks on this entry
   *
   * @param projectId The id of the corresponding project
   */
  onClick: (projectId: string) => void;
}

/**
 * Renders a single project list view entry
 */
export const ProjectListViewEntry: FC<IProjectListViewEntryProps> = ({ entry, onClick }) => {
  const { t } = useTranslation();
  const date = new Date(entry.updatedAt).toLocaleDateString();
  const onClickEntry = useCallback(() => {
    onClick(entry.id);
  }, [entry.id, onClick]);

  return (
    <article className={styles.entry} onClick={onClickEntry} aria-roledescription="button">
      <figure className={styles.figure}>
        <CoverImage url={entry.coverUrl} />
      </figure>
      <div className={styles.meta}>
        <h1 className={styles.title}>{entry.title ?? t('UnnamedProject')}</h1>
        <h2 className={styles.subtitle}>{entry.subtitle}</h2>
        <span>
          {t('Version')} {entry.version}
        </span>
      </div>
      <div className={styles.info}>
        <span>{date}</span>
        <ProjectStatusBadge status={entry.status} />
      </div>
    </article>
  );
};
