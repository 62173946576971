import { Form, FormikProps, FormikProvider } from 'formik';
import styles from './FormatPage.module.css';
import { bookTypes } from './constants/BookTypes';
import { BookFormatElement } from './bookFormat/BookFormatElement';
import React from 'react';
import { IFormatPageValueType } from './FormatPage.schema';
import { useTranslation } from 'react-i18next';

export interface IFormatPageProps {
  formik: FormikProps<IFormatPageValueType>;
}

export const FormatPageForm = ({ formik }: IFormatPageProps) => {
  const { t } = useTranslation();

  return (
    <FormikProvider value={formik}>
      <Form className={styles.form}>
        {bookTypes
          .filter(($) => !formik.values[$.type].missing)
          .map(({ labelKey, name, imageUrl, type }) => (
            <BookFormatElement key={name} type={type} imgSrc={imageUrl} name={t(labelKey)} />
          ))}
      </Form>
    </FormikProvider>
  );
};
