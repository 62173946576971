import { FormattingEnumeration } from './Formatting';

export enum HeadlineFormEnumeration {
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  TEXT_ALIGN = 'text-align',
  PAGE_BREAK = 'page-break',
  SPACING_AFTER = 'spacing-after',
  INDENTION = 'indention',
  SHOW_IN_TOC = 'show-in-toc',
  CONTAIN_HEADLINES = 'contain-headlines',
  HEADLINE_CHARS = 'headline-chars',
}

export type IHeadlineForm = {
  [HeadlineFormEnumeration.FONT_FAMILY]: string;
  [HeadlineFormEnumeration.FONT_SIZE]: number;
  [HeadlineFormEnumeration.BOLD]: boolean;
  [HeadlineFormEnumeration.ITALIC]: boolean;
  [HeadlineFormEnumeration.UNDERLINED]: boolean;
  [HeadlineFormEnumeration.TEXT_ALIGN]: string;
  [HeadlineFormEnumeration.PAGE_BREAK]: string;
  [HeadlineFormEnumeration.SPACING_AFTER]: number;
  [HeadlineFormEnumeration.INDENTION]: number;
  [HeadlineFormEnumeration.SHOW_IN_TOC]: boolean;
  [HeadlineFormEnumeration.CONTAIN_HEADLINES]: boolean;
  [HeadlineFormEnumeration.HEADLINE_CHARS]: number;
};

type IHeadlineFormatting = {
  [key in FormattingEnumeration]: string;
};

export const headlineFormatting: IHeadlineFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: HeadlineFormEnumeration.FONT_FAMILY,
  [FormattingEnumeration.FONT_SIZE]: HeadlineFormEnumeration.FONT_SIZE,
  [FormattingEnumeration.BOLD]: HeadlineFormEnumeration.BOLD,
  [FormattingEnumeration.ITALIC]: HeadlineFormEnumeration.ITALIC,
  [FormattingEnumeration.UNDERLINED]: HeadlineFormEnumeration.UNDERLINED,
  [FormattingEnumeration.TEXT_ALIGN]: HeadlineFormEnumeration.TEXT_ALIGN,
};
