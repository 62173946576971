import { TFunction } from 'react-i18next';
import { TitleFromApi } from '../editor/contributors/interfaces/IContributor';

export function createTitleOptions(t: TFunction) {
  return [
    { value: TitleFromApi.None, label: '' },
    { value: TitleFromApi.Prof, label: t('Prof.') },
    { value: TitleFromApi.ProfDr, label: t('Prof. Dr.') },
    { value: TitleFromApi.Dr, label: t('Dr.') },
  ];
}

export enum Title {
  none = '',
  professor = 'Prof.',
  profDr = 'Prof. Dr.',
  doctor = 'Dr.',
}
