import { AppThunk } from '../../redux/store';
import { grabLayer } from './liveSheetSlice';
import { TransformationType } from './ILiveSheetState';
import { getSheetMousePosition } from '../input/inputSelectors';
import { BoundingBoxAnchor } from './BoundingBoxAnchor';

export const startScaleTransform =
  (id: string, anchor: BoundingBoxAnchor): AppThunk =>
  (dispatch, getState) => {
    const startPoint = getSheetMousePosition(getState());
    dispatch(
      grabLayer({
        id,
        transform: {
          type: TransformationType.Scale,
          grabbedLayerId: id,
          anchor,
          startPoint,
          endPoint: startPoint,
        },
      }),
    );
  };
