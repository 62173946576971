import { createSlice } from '@reduxjs/toolkit';
import { setKeyboardInputReducer } from './setKeyboardInputReducer';
import { IDesignerInputState } from './IDesignerInputState';
import { createOverwriteStateReducer } from '../../lib/createOverwriteStateReducer';

export const createInitialInputState = (): IDesignerInputState => ({
  mouse: {
    sheet: { x: 0, y: 0 },
    workSpace: { x: 0, y: 0 },
    client: { x: 0, y: 0 },
  },
  keyboard: { shift: false, control: false },
});

export const inputSlice = createSlice({
  name: 'input',
  initialState: Object.freeze(createInitialInputState()),
  reducers: {
    setInput: createOverwriteStateReducer<IDesignerInputState>(),
    setKeyboardInput: setKeyboardInputReducer,
  },
});

export const { setInput, setKeyboardInput } = inputSlice.actions;
