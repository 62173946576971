import { FormattingEnumeration } from './Formatting';

export enum TableOfContentFormEnumeration {
  TOC = 'toc',
  LINING = 'lining',
  HEADLINE = 'headline',
  FONT_FAMILY_HEADLINE = 'font-family-headline',
  FONT_SIZE_HEADLINE = 'font-size-headline',
  BOLD_HEADLINE = 'bold-headline',
  ITALIC_HEADLINE = 'italic-headline',
  UNDERLINED_HEADLINE = 'underlined-headline',
  TEXT_ALIGN_HEADLINE = 'text-align-headline',
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  TEXT_ALIGN = 'text-align',
}

export type ITableOfContentForm = {
  [TableOfContentFormEnumeration.TOC]: boolean;
  [TableOfContentFormEnumeration.LINING]: string;
  [TableOfContentFormEnumeration.HEADLINE]: string;
  [TableOfContentFormEnumeration.FONT_FAMILY]: string;
  [TableOfContentFormEnumeration.FONT_FAMILY_HEADLINE]: string;
  [TableOfContentFormEnumeration.FONT_SIZE]: number;
  [TableOfContentFormEnumeration.FONT_SIZE_HEADLINE]: number;
  [TableOfContentFormEnumeration.BOLD]: boolean;
  [TableOfContentFormEnumeration.BOLD_HEADLINE]: boolean;
  [TableOfContentFormEnumeration.ITALIC]: boolean;
  [TableOfContentFormEnumeration.ITALIC_HEADLINE]: boolean;
  [TableOfContentFormEnumeration.UNDERLINED]: boolean;
  [TableOfContentFormEnumeration.UNDERLINED_HEADLINE]: boolean;
  [TableOfContentFormEnumeration.TEXT_ALIGN]: string;
  [TableOfContentFormEnumeration.TEXT_ALIGN_HEADLINE]: string;
};

export enum LiningOptionEnumeration {
  NONE = 'none',
  DOTTED = 'dotted',
}

export const liningOptions = [
  {
    label: 'None',
    value: LiningOptionEnumeration.NONE,
  },
  {
    label: 'Dotted',
    value: LiningOptionEnumeration.DOTTED,
  },
];

type ITableOfContentFormatting = {
  [key in FormattingEnumeration]: string;
};

export const TOCHeadlineFormatting: ITableOfContentFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: TableOfContentFormEnumeration.FONT_FAMILY_HEADLINE,
  [FormattingEnumeration.FONT_SIZE]: TableOfContentFormEnumeration.FONT_SIZE_HEADLINE,
  [FormattingEnumeration.BOLD]: TableOfContentFormEnumeration.BOLD_HEADLINE,
  [FormattingEnumeration.ITALIC]: TableOfContentFormEnumeration.ITALIC_HEADLINE,
  [FormattingEnumeration.UNDERLINED]: TableOfContentFormEnumeration.UNDERLINED_HEADLINE,
  [FormattingEnumeration.TEXT_ALIGN]: TableOfContentFormEnumeration.TEXT_ALIGN_HEADLINE,
};

export const TOCFormatting: ITableOfContentFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: TableOfContentFormEnumeration.FONT_FAMILY,
  [FormattingEnumeration.FONT_SIZE]: TableOfContentFormEnumeration.FONT_SIZE,
  [FormattingEnumeration.BOLD]: TableOfContentFormEnumeration.BOLD,
  [FormattingEnumeration.ITALIC]: TableOfContentFormEnumeration.ITALIC,
  [FormattingEnumeration.UNDERLINED]: TableOfContentFormEnumeration.UNDERLINED,
  [FormattingEnumeration.TEXT_ALIGN]: TableOfContentFormEnumeration.TEXT_ALIGN,
};
