import { ICSSObject } from '../components/form_constant/CSSObject';
import axios from 'axios';

export const updateStyle = async (formValues: ICSSObject) => {
  const baseUrl = process.env.REACT_APP_CONTENT_DESIGNER;
  const query = 'style/update';

  const response = await axios.post(baseUrl + query, formValues);
  return response.data;
};
