import { FC } from 'react';
import { History } from 'history';
import { HistoryRouter } from 'redux-first-history/rr6';
import { Route, Routes } from 'react-router';
import { EditorPage } from '../editor/EditorPage';
import { EditorPageRoute } from '../editor/getEditorRoute';
import { RegionPage } from '../account/region/RegionPage';
import { RegistrationPage } from '../account/register/RegistrationPage';
import { EditorPreflightPage } from '../editor/EditorPreflightPage';
import { ProjectsPage } from '../project/ProjectsPage';
import { DashboardPage } from '../editor/dashboard/DashboardPage';
import { ProjectEditPage } from '../editor/projects/ProjectEditPage';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';

export const Router: FC<{ history: History }> = ({ history }) => (
  <HistoryRouter history={history}>
    <Routes>
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/newbook" element={<EditorPreflightPage />} />
      <Route path={EditorPageRoute} element={<EditorPage />} />
      <Route path="/fixRegion" element={<RegionPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/projects/:projectId" element={<ProjectEditPage />} />
      <Route element={<AuthenticatedRoutes />}>
        <Route path={'/'} element={<DashboardPage />} />
        <Route path={'/dashboard'} element={<DashboardPage />} />
      </Route>
    </Routes>
  </HistoryRouter>
);
