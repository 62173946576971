import { FC, useMemo } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useSelectedLayers } from '../layer/liveSheetSelectors';
import { setEffect } from '../layer/liveSheetSlice';
import { ILayerEffect } from '../layer/LayersState';
import { consolidate } from '../../lib/consolidate';
import styled from 'styled-components/macro';
import { AlertBox } from '../../uiComponents/AlertBox';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';

const Sliders: {
  effectKey: keyof ILayerEffect;
  label: string;
  min: number;
  max: number;
}[] = [
  {
    effectKey: 'blur',
    label: 'Blur',
    min: 0,
    max: 20,
  },
  {
    effectKey: 'dropShadow',
    label: 'DropShadow',
    min: 0,
    max: 50,
  },
  {
    effectKey: 'sepia',
    label: 'Sepia',
    min: 0,
    max: 100,
  },
  {
    effectKey: 'transparency',
    label: 'Transparency',
    min: 0,
    max: 100,
  },
  {
    effectKey: 'hueRotate',
    label: 'Hue',
    min: 0,
    max: 360,
  },
  {
    effectKey: 'grayscale',
    label: 'GrayScale',
    min: 0,
    max: 100,
  },
];

export const EffectsPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedLayers = useSelectedLayers();
  const consideredLayers = useMemo(
    () => selectedLayers.filter(($) => $.allowEffects),
    [selectedLayers],
  );
  if (!consideredLayers.length) {
    return null;
  }
  return (
    <SidePanelContentWrapper>
      <h2>{t('Effects')}</h2>
      {consideredLayers.length !== selectedLayers.length && (
        <AlertBox type={'danger'}>
          <Icon name="achtung-control" /> {t('NotSupportedLayerEffects')}
        </AlertBox>
      )}
      <AlertBox type={'warning'}>
        <Icon name="info-control" />
        <span>️{t('EffectMultiLayer', { 0: consideredLayers.length })}</span>
      </AlertBox>

      {Sliders.map((slider) => {
        const key = slider.effectKey;
        const con = consolidate(consideredLayers, ($) => $.effect[key]);
        return (
          <StyledLabel key={key}>
            <div>
              <span>{t(slider.label)}</span>
              <span>{con.value}%</span>
            </div>
            <input
              type="range"
              step={1}
              value={con.isEqual ? con.value! : 0}
              min={slider.min}
              max={slider.max}
              onChange={(e) => {
                dispatch(
                  setEffect({
                    effect: { [key]: Number.parseInt(e.target.value) || 0 },
                    filter: { selected: true },
                  }),
                );
              }}
            />
          </StyledLabel>
        );
      })}
    </SidePanelContentWrapper>
  );
};

const StyledLabel = styled.label`
  display: grid;

  & > div {
    display: flex;
    justify-content: space-between;
  }
  & > input[type='range'] {
    accent-color: var(--color-cta);
  }
`;
