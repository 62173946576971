import { IImageTileProps, ImageTile } from './ImageTile';
import { motion } from 'framer-motion';
import styles from './ImageTileContainer.module.css';
import React, { ReactNode } from 'react';
import { popIn } from '../motion/variants';

export interface IImageTileContainerProps {
  className?: string;
  children: React.ReactElement<IImageTileProps>[];
}

export const ImageTileContainer = ({ className, children = [] }: IImageTileContainerProps) => {
  return (
    <motion.div
      className={`${styles.container} ${className}`}
      animate={'visible'}
      initial="hidden"
      variants={popIn.container}>
      {children.map((child, index) => (
        <motion.div key={index} className={styles.element} variants={popIn.element}>
          {child}
        </motion.div>
      ))}
    </motion.div>
  );
};
