import { AppThunk } from '../../redux/store';
import { treditionApi } from '../../api/treditionApi';
import { getCurrentProjectId } from './projectSelectors';
import { DesignerImageSource } from '../drag/dragSlice';

export const loadImageIntoProject =
  (fileId: string, source: DesignerImageSource): AppThunk<Promise<string>> =>
  async (dispatch, getState): Promise<string> => {
    const projectId = getCurrentProjectId(getState());
    if (!projectId) {
      throw new Error('No project is currently loaded');
    }
    return dispatch(
      treditionApi.endpoints.loadImageIntoProject.initiate({
        projectId,
        fileId,
        source,
      }),
    )
      .unwrap()
      .then((result) => result.fileId);
  };
