import { AppThunk } from '../../redux/store';
import { restoreFromHistory } from './historySlice';
import { getActiveProduct } from '../project/productSelectors';
import { getHistoryPageByProductId } from './historySelectors';

/**
 * Overwrites the current product with the state stored in the history at the given index
 * by dispatching the {@link restoreFromHistory} action after gathering the necessary data.
 *
 * Does nothing if no product is currently opened, or if the index is out of bounds.
 */
export const restoreProductFromHistoryRecord =
  (index: number): AppThunk =>
  (dispatch, getState) => {
    if (index < 0) {
      return;
    }
    const state = getState();
    const product = getActiveProduct(state);
    if (!product) {
      return;
    }
    const page = getHistoryPageByProductId(state, product.id);
    if (!page) {
      return;
    }
    const record = page.records[index];
    if (!record) {
      return;
    }
    dispatch(restoreFromHistory({ productId: product.id, index, record }));
  };
