import { AppThunk } from '../../redux/store';
import { getSelectedLayers } from '../layer/liveSheetSelectors';
import { addToClipboard, ClipboardDataType } from './clipboardSlice';

export const addSelectionToClipboard = (): AppThunk => (dispatch, getState) => {
  const data = getSelectedLayers(getState());
  if (!data.length) {
    return;
  }
  dispatch(addToClipboard({ dataType: ClipboardDataType.Layer, data }));
};
