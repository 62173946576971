import { FC, useCallback, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { t } from 'i18next';
import { RadioGroupFormik } from '../../forms/radioGroup/RadioGroup.formik';
import { TextInputFormik } from '../../forms/textInput/TextInput.formik';
import { SelectFormik } from '../../select/Select.formik';
import styles from './NewEditor.module.css';
import { createEditorSchema } from './NewEditor.schema';
import { ActionModal } from '../../modal/ActionModal';
import { noop } from '../../lib/noop';
import { useCreateSeriesEditorMutation } from '../../api/treditionApi';
import { toast } from 'react-hot-toast';

export interface INewEditorFormValues {
  isCompany: boolean;
  title: string;
  firstName: string;
  lastName: string;
  companyName: string;
  website: string;
}

const initialValues = Object.freeze<INewEditorFormValues>({
  isCompany: false,
  title: '',
  companyName: '',
  firstName: '',
  lastName: '',
  website: '',
});

export interface INewEditorProps {
  projectId: string;
  onCancel: () => void;
  onEditorCreated: (editorId: string) => void;
}

export const NewEditor: FC<INewEditorProps> = ({ onEditorCreated, onCancel, projectId }) => {
  const [createSeriesEditor, { isLoading }] = useCreateSeriesEditorMutation();

  const validationSchema = useMemo(() => createEditorSchema(), []);
  const titleOptions = useMemo(() => {
    return [
      { value: '', label: '' },
      { value: 'DR', label: t('Dr.') },
      { value: 'PROF', label: t('Prof.') },
      { value: 'PROFDR', label: t('Prof. Dr.') },
    ];
  }, [t]);

  const onSubmit = useCallback(
    (values: INewEditorFormValues): Promise<void> => {
      const task = createSeriesEditor({ projectId, editor: values }).unwrap();
      return toast
        .promise(task, {
          loading: t('CreateSeriesEditorLoading'),
          success: t('CreateSeriesEditorSuccess'),
          error: t('CreateSeriesEditorError'),
        })
        .then((id: string) => {
          onEditorCreated(id);
        })
        .catch((e) => {
          console.error(e, { projectId, values });
        });
    },
    [createSeriesEditor, projectId, onEditorCreated, t],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: noop,
    validationSchema,
  });

  return (
    <ActionModal
      isOpen
      title={t('CreateEditor')}
      onOk={() => {
        onSubmit(formik.values).catch(noop);
      }}
      okButtonDisabled={isLoading || !formik.isValid}
      onCancel={onCancel}>
      <div className={styles.wrapper}>
        <FormikProvider value={formik}>
          <RadioGroupFormik
            name="isCompany"
            options={[
              { value: false, label: t('Person').toString() },
              { value: true, label: t('Company').toString() },
            ]}
          />
          {formik.values.isCompany ? (
            <TextInputFormik name="companyName" label={`${t('Company')}*`} />
          ) : (
            <div className={styles.flex}>
              <SelectFormik name="title" options={titleOptions} label={t('Title')} />
              <TextInputFormik name="firstName" label={`${t('FirstName')}*`} />
              <TextInputFormik name="lastName" label={`${t('Name')}*`} />
            </div>
          )}
          <TextInputFormik name="website" label={t('Website')} />
        </FormikProvider>
      </div>
    </ActionModal>
  );
};
