export interface IConsolidatedResult<T> {
  /**
   * Whether the value is equal across all occurrences
   */
  isEqual: boolean;

  /**
   * The number elements from which this result was consolidated
   */
  count: number;

  /**
   * The value shared across all occurrences or null if differing
   */
  value: T | null;
}

/**
 * Tries to determine a common value of an attribute in multiple entities,
 * used when displaying a form control which would affect an attribute of multiple entities.
 *
 * TODO: needs equalityFn for complex objects
 */
export function consolidate<A, T>(elements: A[], pred: (layer: A) => T): IConsolidatedResult<T> {
  if (!elements.length) {
    return { count: 0, value: null, isEqual: false };
  }
  const first: T = pred(elements[0]);
  if (elements.length === 1) {
    return { count: 1, value: first, isEqual: true };
  }
  if (elements.every(($) => pred($) === first)) {
    return { count: elements.length, value: first, isEqual: true };
  }
  return { count: elements.length, value: null, isEqual: false };
}
