import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface IShortenFormHistoryActionPayload {
  /**
   * index to which was jumped
   */
  index: number;
}

/**
 * Shortens the history to the given index
 */
export const shortenFormHistoryReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<IShortenFormHistoryActionPayload>,
) => {
  state.formHistory = state.formHistory.slice(action.payload.index + 1);
};
