import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface IRemoveLayersActionPayload {
  /**
   * A filter which will determine which layers should have their padding updated.
   */
  filter: ILayerFilter;

  /**
   * Amount of padding in pixels to write to all layers matching the filter
   */
  padding: number;
}

export function setPaddingReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IRemoveLayersActionPayload>,
) {
  const {
    payload: { filter, padding },
  } = action;

  filterLayers(state.layers, filter).forEach((layer) => {
    layer.padding = padding;
    layer.updatedAt = Date.now();
  });
}
