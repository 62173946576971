import { IconButton } from '../../../buttons/icon/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IsbnListElement.module.css';
import { toast } from 'react-hot-toast';
import { copyToClipboard } from '../../../lib/copyToClipboard';
import { formatIsbn } from '../utils/IsbnUtils';

export interface IIsbnProductElement {
  isbn: string;
  bookType: string;
}

export const IsbnListElement: React.FC<IIsbnProductElement> = ({ isbn, bookType }) => {
  const { t } = useTranslation();

  return (
    <li className={styles.wrapper}>
      <span>{t(bookType)}</span>
      <span>{formatIsbn(isbn)}</span>
      <IconButton
        label={'copy'}
        labelHidden
        iconName="kopieren-control"
        onClick={() => {
          copyToClipboard(formatIsbn(isbn)).then(() => toast.success(t('ValueCopied')));
        }}
      />
    </li>
  );
};
