import React from 'react';
import styled from 'styled-components/macro';
import { CloseIconButton } from '../../uiComponents/CloseIconButton';

export interface ISubMenu {
  children: React.ReactNode;
  onClose: () => unknown;
}

export const SubMenu: React.FC<ISubMenu> = ({ onClose, children }) => {
  return (
    <Wrapper>
      <CloseIconButton onClick={onClose} position={{ top: 15, right: 15 }} />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 200px;
  width: max-content;
  position: absolute;
  top: 100%;
  display: grid;
  align-items: center;
  gap: var(--spacing-standard);
  padding: 2rem 2.5rem;
  border: 1px solid var(--color-border);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-color: white;
  z-index: 9990;
`;
