import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { dragSlice } from './dragSlice';
import { getDesignerSlice } from '../designerSelectors';

/**
 * Resolves the drag slice using the RootState
 */
export const getDragSlice = (state: RootState) => getDesignerSlice(state)[dragSlice.name];

/**
 * Returns the drag slice from the store
 */
export const useDrag = () => useAppSelector(getDragSlice);
