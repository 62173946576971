import { RootState } from '../redux/store';
import { useAppSelector } from '../redux/hooks';

/**
 * Returns the entire locale state lice
 */
export const getLocaleSlice = (state: RootState) => state.locale;

/**
 * Returns language code of the currently active language
 */
export const getLanguageCode = (state: RootState) => getLocaleSlice(state).languageCode;

/**
 * Returns the legacy language code of the currently active language
 */
export const getLegacyLanguageCode = (state: RootState) => getLocaleSlice(state).legacyLanguageCode;

/**
 * Provides the language code of the currently active language to a React component
 */
export const useLanguageCode = () => useAppSelector(getLanguageCode);
