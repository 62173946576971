import { IDesignerTemplateState } from './IDesignerTemplateState';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISetProjectActionPayload } from '../project/projectActions';
import { createInitialTemplateState } from './templateSlice';

/**
 * Reacts to {@see setProject} and shows the template modal if the next project state would have
 * an empty design, i.e. all sheets have no layers in them.
 */
export function applySetProjectToTemplateReducer(
  state: IDesignerTemplateState,
  action: PayloadAction<ISetProjectActionPayload>,
): IDesignerTemplateState {
  const { payload: project } = action;
  const nextState = createInitialTemplateState();
  if (!project || !project.products.length) {
    return nextState;
  }
  nextState.isTemplateModalOpen = project.products.every(($) =>
    $.sheets.every((sheet) => !sheet.layers.length),
  );
  return nextState;
}
