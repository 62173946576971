import React from 'react';

export interface ISpacerProps {
  size: number;
  axis?: 'horizontal' | 'vertical';
  style?: React.CSSProperties;
}

/**
 * A component to generate some space between components
 * as default it's a square, with axis you can makle it size by 1 px
 * @param size
 * @param axis
 * @param style
 * @param delegated
 * @constructor
 */
export const Spacer: React.FC<ISpacerProps> = ({ size, axis, style = {}, ...delegated }) => {
  const width = axis === 'vertical' ? 1 : size;
  const height = axis === 'horizontal' ? 1 : size;
  return (
    <span
      style={{
        display: 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
      {...delegated}
    />
  );
};
