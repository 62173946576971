import { FC, useCallback, useContext, useEffect } from 'react';
import { Designer } from './Designer';
import { useAppDispatch } from '../redux/hooks';
import { openProject } from './project/openProject';
import { useGetCoverDesignQuery } from '../api/treditionApi';
import { useLocation, useParams } from 'react-router';
import { QueryLoader } from '../loader/QueryLoader';
import { LoadDesignError } from './LoadDesignError';
import { DesignerMode } from './designerMode';
import { useActiveProductId } from './project/projectSelectors';
import { replace } from 'redux-first-history';
import { setProject } from './project/projectActions';
import { Loader } from '../loader/Loader';
import { EditorContext } from '../editor/EditorContext';
import { saveProject } from './project/saveProject';

// saving the design should always be possible
const onValidate = () => Promise.resolve(true);
// saveProject currently has the "onCheckDirty" baked in due to its previous
// implementation with the legacy customer. this should be refactored
// so the checking is done in onCheckDirty
const onCheckDirty = () => true;

export const CoverDesignerPage: FC = () => {
  const {
    registerStep,
    state: { projectId },
  } = useContext(EditorContext);
  const dispatch = useAppDispatch();
  const productId = useActiveProductId();
  const loc = useLocation();
  const query = useGetCoverDesignQuery({ projectId });
  const { isSuccess, data } = query;

  const onSubmit = useCallback(() => {
    return dispatch(saveProject(true));
  }, [dispatch]);

  useEffect(() => {
    registerStep(onSubmit, onValidate, onCheckDirty);
  }, [registerStep, onSubmit, onCheckDirty, projectId]);

  // reset the project state to null as this page unmounts
  useEffect(() => {
    return () => {
      dispatch(setProject(null));
    };
  }, [dispatch]);

  // track currently open product in URL across page refreshes
  useEffect(() => {
    if (productId) {
      dispatch(replace(`${loc.pathname}?product=${productId}`));
    }
  }, [dispatch, productId, loc.pathname]);

  // open the project loaded by the query and consider the product id specified in the URL
  // this allows the legacy client to link to specific products opened in a design as well as
  // allows reopening the correct product if the page is reloaded
  useEffect(() => {
    if (isSuccess && data) {
      const params = new URLSearchParams(window.location.search);
      const activeProduct = params.get('product') || null;
      dispatch(openProject(data, activeProduct));
    }
  }, [dispatch, data, isSuccess]);

  return (
    <QueryLoader
      query={query}
      renderError={(query) => <LoadDesignError query={query} />}
      renderLoading={() => <Loader />}>
      <Designer mode={DesignerMode.Cover} />
    </QueryLoader>
  );
};
