import { FC, useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import {
  useDeletePaletteMutation,
  useGetPalettesSharedWithMeQuery,
  useSavePaletteMutation,
} from '../../api/treditionApi';
import { ISaveTreditionPalette, ITreditionPalette } from '../palette/TreditionPalette';
import { useActiveProduct } from '../project/productSelectors';
import { Modal } from '../../modal/Modal';
import { PaletteCreator } from '../palette/PaletteCreator';
import { PaletteList } from '../palette/PaletteList';
import { Confirm } from '../../modal/Confirm';
import { QueryLoader } from '../../loader/QueryLoader';
import styled from 'styled-components/macro';
import { CircleIconButton } from '../../uiComponents/CircleIconButton';
import { useTranslation } from 'react-i18next';
import { PaletteFromImageModal } from '../palette/PaletteFromImageModal';
import { toast } from 'react-hot-toast';
import { noop } from '../../lib/noop';
import { Palette } from '../palette/Palette';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';
import { setPalette } from '../project/setPalette';

export const PalettesPanel: FC = () => {
  const dispatch = useAppDispatch();
  const activeProduct = useActiveProduct();
  const [isPaletteModalOpen, setIsPaletteModalOpen] = useState<boolean>(false);
  const [isCreatingPaletteFromImage, setIsCreatingPaletteFromImage] = useState<boolean>(false);
  const [editingPalette, setEditingPalette] = useState<ITreditionPalette | null>(null);
  const [deletionPalette, setDeletionPalette] = useState<ITreditionPalette | null>(null);
  const palettesQuery = useGetPalettesSharedWithMeQuery();
  const [savePalette] = useSavePaletteMutation();
  const [deletePalette] = useDeletePaletteMutation();
  const { t } = useTranslation();

  const onSelectPalette = useCallback(
    (palette: ITreditionPalette | null) => {
      if (activeProduct) {
        dispatch(setPalette(palette));
      }
    },
    [dispatch, activeProduct],
  );

  const closePaletteModal = useCallback(() => {
    setIsPaletteModalOpen(false);
  }, []);

  const savePaletteWithToast = useCallback(
    (palette: ISaveTreditionPalette) => {
      const saveTask = savePalette(palette).unwrap();
      toast
        .promise(saveTask, {
          loading: t('CreatePaletteLoading'),
          error: t('CreatePaletteError'),
          success: t('CreatePaletteSuccess'),
        })
        .then(() => {
          setIsPaletteModalOpen(false);
        })
        .catch(noop);
      return saveTask;
    },
    [t, savePalette],
  );

  const onClickCreatePaletteFromCreator = useCallback(
    (palette: ISaveTreditionPalette) => {
      savePaletteWithToast(palette)
        .then(() => {
          setIsPaletteModalOpen(false);
        })
        .catch(noop);
    },
    [savePaletteWithToast],
  );

  const onClickCreatePalette = useCallback(() => {
    setEditingPalette(null);
    setIsPaletteModalOpen(true);
  }, []);

  const onClickCreatePaletteFromImage = useCallback(() => {
    setIsCreatingPaletteFromImage(true);
  }, []);

  const onClickEditPalette = useCallback((palette: ITreditionPalette) => {
    setEditingPalette(palette);
    setIsPaletteModalOpen(true);
  }, []);

  const onClickDeletePalette = useCallback((palette: ITreditionPalette) => {
    setDeletionPalette(palette);
  }, []);

  const onConfirmDeletePalette = useCallback(() => {
    if (!deletionPalette) {
      return;
    }
    toast
      .promise(deletePalette(deletionPalette.id).unwrap(), {
        loading: t('DeletePaletteLoading'),
        error: t('DeletePaletteError'),
        success: t('DeletePaletteSuccess'),
      })
      .then(() => {
        setDeletionPalette(null);
      })
      .catch(noop);
  }, [deletionPalette, deletePalette, t]);

  const onAcceptPaletteFromImage = useCallback(
    (palette: ISaveTreditionPalette) => {
      savePaletteWithToast(palette)
        .then(() => {
          setIsCreatingPaletteFromImage(false);
        })
        .catch(noop);
    },
    [savePaletteWithToast],
  );

  const paletteLists = useMemo(() => {
    const view = {
      createdByMe: [] as ITreditionPalette[],
      createdByTredition: [] as ITreditionPalette[],
    };
    if (!palettesQuery.data) {
      return view;
    }
    palettesQuery.data.forEach((palette) => {
      if (palette.userId) {
        view.createdByMe.push(palette);
      } else {
        view.createdByTredition.push(palette);
      }
    });
    return view;
  }, [palettesQuery.data]);

  if (!activeProduct) {
    return null;
  }

  return (
    <SidePanelContentWrapper>
      <h2>{t('OwnColorPalettes')}</h2>
      <CircleIconButton icon="plus-control" onClick={onClickCreatePalette}>
        {t('NewColorPalette')}
      </CircleIconButton>
      <CircleIconButton icon="bilder" onClick={onClickCreatePaletteFromImage}>
        {t('CreatePalette')}
      </CircleIconButton>
      <Palettes>
        <QueryLoader query={palettesQuery}>
          <PaletteList
            palettes={paletteLists.createdByMe}
            activePaletteId={activeProduct.palette?.id || null}
            onSelectPalette={onSelectPalette}
            onEditPalette={onClickEditPalette}
            onDeletePalette={onClickDeletePalette}
          />
          <h2>{t('TreditionPalettes')}</h2>
          {/* TODO: Wenn Sebastian zurück ist, noch einmal das verhalten besprechen. */}
          {/*<CustomLabel className={!activeProduct.palette ? 'active' : undefined}>*/}
          {/*    <span>{t('UseNoPalette')}</span>*/}
          {/*    <StyledRadioButton name='palette' checked={!activeProduct.palette} onChange={() => onSelectPalette(null)} />*/}
          {/*</CustomLabel>*/}
          <PaletteList
            palettes={paletteLists.createdByTredition}
            activePaletteId={activeProduct.palette?.id || null}
            onSelectPalette={onSelectPalette}
          />
        </QueryLoader>
      </Palettes>
      <Modal
        title={t(editingPalette ? 'EditPalette' : 'CreateColorPalette')}
        isOpen={isPaletteModalOpen}
        onRequestClose={closePaletteModal}>
        <PaletteCreator
          onCreate={onClickCreatePaletteFromCreator}
          onCancel={closePaletteModal}
          initialPalette={editingPalette}
        />
      </Modal>
      {!!deletionPalette && (
        <Confirm
          isOpen
          title={t('Delete')}
          onYes={onConfirmDeletePalette}
          onNo={() => setDeletionPalette(null)}>
          <DeletePaletteContent>
            <div>{t('DeleteColorPaletteConfirmation')}</div>
            <Palette palette={deletionPalette} />
          </DeletePaletteContent>
        </Confirm>
      )}
      {isCreatingPaletteFromImage && (
        <PaletteFromImageModal
          onRequestClose={() => setIsCreatingPaletteFromImage(false)}
          onAcceptPalette={onAcceptPaletteFromImage}
        />
      )}
    </SidePanelContentWrapper>
  );
};

const DeletePaletteContent = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  gap: 1rem;
`;

const Palettes = styled.div`
  padding: 1rem 0;

  & h2 {
    padding: 1rem 0;
  }
`;
