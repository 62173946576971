import { IImageLayer, LayerError } from './LayersState';
import { FC, Fragment, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setImageDimensions, setLayerError } from './liveSheetSlice';
import { useAppDispatch } from '../../redux/hooks';
import { ImageFallback } from '../../error/ImageFallback';
import { useActiveProduct } from '../project/productSelectors';

export interface IImageLoaderProps {
  /**
   * The image layer to manage
   */
  layer: IImageLayer;

  /**
   * The final url as determined by {@link useImageBlobUrl}
   */
  url: string;
}

export const ImageLoader: FC<IImageLoaderProps> = ({ layer, url }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = layer;
  const product = useActiveProduct();

  const onLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      let width = rect.width;
      let height = rect.height;
      const aspect = width / height;
      // limit the larger side of the image to half of the product's width for better control
      if (product) {
        if (width >= height && width > product.measures.width / 2) {
          width = product.measures.width / 2;
          height = width / aspect;
        } else if (height >= width && height > product.measures.height / 2) {
          height = product.measures.height / 2;
          width = aspect * height;
        }
      }
      dispatch(setImageDimensions({ id, width, height }));
    },
    [dispatch, id, product],
  );

  const onLoadError = useCallback(
    (_: SyntheticEvent<HTMLImageElement>) => {
      const width = layer.display.width || 128;
      const height = layer.display.height || 128;
      dispatch(setImageDimensions({ id, width, height }));
      dispatch(setLayerError({ id, error: LayerError.ImageFailedToLoad }));
    },
    [dispatch, id, layer.display.width, layer.display.height],
  );

  if (layer.error === LayerError.ImageFailedToLoad) {
    return <ImageFallback width={layer.display.width} height={layer.display.height} />;
  }
  return (
    <Fragment>
      <span>{t('Loading')}</span>
      <img
        src={url}
        alt=""
        onLoad={onLoad}
        onError={onLoadError}
        style={{ visibility: 'hidden' }}
      />
    </Fragment>
  );
};
