import { FC, useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { determineProblems } from './determineProblems';
import { useRevision } from '../history/historySelectors';
import { useActiveProductId } from '../project/projectSelectors';

const CHECK_DELAY_MS = 300;

export const ProblemController: FC = () => {
  const dispatch = useAppDispatch();
  const productId = useActiveProductId();
  const revision = useRevision();

  // determine problems after CHECK_DELAY_MS if not already checking
  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    function unregister() {
      if (timerId !== null) {
        clearTimeout(timerId);
        timerId = null;
      }
    }
    if (!productId) {
      return unregister;
    }
    timerId = setTimeout(() => {
      dispatch(determineProblems());
    }, CHECK_DELAY_MS);

    return unregister;
  }, [dispatch, revision, productId]);

  return null;
};
