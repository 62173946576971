import { FC } from 'react';
import styles from './GlobalError.module.css';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { emptyArray, ensureArray } from '../lib/emptyArray';
import { AlertBox, AlertBoxMood } from '../alertBox/AlertBox';

export interface IGlobalErrorProps {
  formik?: {
    formikErrors: FormikErrors<unknown>;
    submitCount?: number;
  };
  customErrors?: string[] | null;
}

function extractGlobalErrors(errors: FormikErrors<unknown>): string[] {
  if (!errors) {
    return emptyArray;
  }
  const globalErrors = (errors as Record<string, string[]>).__GLOBAL;
  if (!Array.isArray(globalErrors) || !globalErrors.length) {
    return emptyArray;
  }
  return globalErrors;
}

export const GlobalError: FC<IGlobalErrorProps> = ({ formik, customErrors }) => {
  const { formikErrors = emptyArray, submitCount = 1 } = formik || {};

  const { t } = useTranslation();

  const globalErrors = [...extractGlobalErrors(formikErrors), ...ensureArray(customErrors)];

  if (!globalErrors.length) {
    return null;
  }
  if (submitCount === 0) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <AlertBox mood={AlertBoxMood.danger}>{globalErrors.map((e) => t(e))}</AlertBox>
    </div>
  );
};
