import { HistoryRecordType } from './historySlice';
import { v4 } from 'uuid';
import { ITreditionPalette } from '../palette/TreditionPalette';
import { IHistoryRecord } from './IHistoryState';
import { ISheet } from '../project/ProductState';

export interface ICreateHistoryRecordData extends Partial<IHistoryRecord> {
  type: HistoryRecordType;
  sheets: ISheet[];
  palette: ITreditionPalette | null;
  activeSheetId: string | null;
}

export function createHistoryRecord(data: ICreateHistoryRecordData): IHistoryRecord {
  return {
    id: v4(),
    createdAt: Date.now(),
    ...data,
  };
}
