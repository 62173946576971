import { ILayerTransform } from '../../layer/LayersState';
import { modDeg } from '../../../lib/modDeg';
import { degToRad } from '../../../lib/degToRad';

export abstract class MatrixCompat {
  /**
   * Takes the matrix string stored in the database (which is the content of the CSS attribute `transform`) and extracts
   * the necessary information to create an ILayerTransform object.
   *
   * Only supports rotation as in the legacy customer just the rotation is expressed by the matrix.
   *
   * Example value: `matrix(-0.999993, 0.00320082, -0.00320082, -0.999993,0,0)`
   *
   * @see https://jsfiddle.net/nK2u7/150/
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
   */
  public static matrixToLayerTransform(cssMatrixPropertyValue?: string | null): ILayerTransform {
    const transform: ILayerTransform = {
      rotate: 0,
    };
    if (typeof cssMatrixPropertyValue !== 'string') {
      return transform;
    }
    const match = cssMatrixPropertyValue.match(/matrix\(([^)]+)\)/);
    if (!match || match.length < 2) {
      return transform;
    }
    const parameters = match[1]
      .split(',')
      .map(Number.parseFloat)
      .filter(($) => !Number.isNaN($));
    if (parameters.length !== 6) {
      return transform;
    }
    const [a, b, c, d, tx, ty] = parameters;
    // if you had to extract the scaling, this would be the way:
    // transform.scale.x = Math.round(Math.sign(a) * Math.sqrt(a * a + c * c));
    // transform.scale.y = Math.round(Math.sign(d) * Math.sqrt(b * b + d * d));

    // clockwise rotation in whole degrees
    const rotationCWDeg = Math.round((Math.atan2(b, d) * 180) / Math.PI);
    // convert to CCW rotation and constrain value between 0 and 360 (covers negative angles)
    transform.rotate = modDeg(-rotationCWDeg);
    return transform;
  }

  public static layerTransformToMatrix(transform: ILayerTransform): string {
    const rad = degToRad(transform.rotate);
    const mat: [number, number, number, number, number, number] = [
      Math.cos(rad),
      -Math.sin(rad),
      Math.sin(rad),
      Math.cos(rad),
      0,
      0,
    ];
    return `matrix(${mat.join(',')})`;
  }
}
