import { IDesignerTemplateState } from './IDesignerTemplateState';
import { PayloadAction } from '@reduxjs/toolkit';

export function toggleTemplateModalReducer(
  state: IDesignerTemplateState,
  action: PayloadAction<boolean>,
) {
  state.isTemplateModalOpen = action.payload;
  state.isTemplateSuggestionEnabled = false;
}
