import { useEffect } from 'react';
import { historyEffect } from './historyEffect';
import { useAppDispatch } from '../../redux/hooks';
import { addListener } from '../../redux/store';

/**
 * Takes care of adding the history listener. The implementation expects this component to be
 * rendered by the designer so as the designer unmounts this component can perform its cleanup tasks
 * such as unsubscribing the history listener.
 */
export const HistoryController = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribeHistoryListener = dispatch(
      addListener({
        // if this component is mounted (i.e. the designer is open) the listener will be active
        predicate: () => true,
        effect: historyEffect,
      }),
    );
    return () => {
      unsubscribeHistoryListener();
    };
  }, [dispatch]);

  return null;
};
