import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface ISetStyleActionPayload {
  style: string;
}

export const setStyleReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetStyleActionPayload>,
) => {
  state.style = action.payload.style;
};
