import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetGenreOptionsQuery,
  useGetTagOptionsQuery,
  useSearchDesignTemplatesQuery,
} from '../../api/treditionApi';

import { QueryLoader } from '../../loader/QueryLoader';
import { TemplateList } from './TemplateList';
import { TemplatePreview } from './TemplatePreview';
import { DesignerMode } from '../designerMode';
import styled from 'styled-components/macro';
import { Button } from '../../uiComponents/Button';
import { BackButton } from '../../uiComponents/BackButton';
import { IDesignTemplateSearchFilter, IDesignTemplateSearchResult } from './IDesignerTemplateState';
import { useTranslation } from 'react-i18next';
import { PlainModal } from '../../modal/PlainModal';
import { StyledCheckBox } from '../../uiComponents/StyledCheckBox';
import { Select } from '../../select/Select';
import { ensureArray } from '../../lib/emptyArray';

export interface ITemplateModalProps {
  onRequestClose: () => unknown;
  onSelectTemplate: (templateId: string) => unknown;
  filter: IDesignTemplateSearchFilter;
  setFilter: (filter: IDesignTemplateSearchFilter) => unknown;
  onResetTemplate: () => unknown;
}

export const TemplateModal: FC<ITemplateModalProps> = ({
  onRequestClose,
  onSelectTemplate,
  filter,
  setFilter,
  onResetTemplate,
}) => {
  const { t } = useTranslation();
  const area = 1; // see backend: enum TagArea.CoverDesigner
  const genreOptionsQuery = useGetGenreOptionsQuery();
  const tagOptionsQuery = useGetTagOptionsQuery({ area });
  const searchQuery = useSearchDesignTemplatesQuery({ filter, mode: DesignerMode.Cover });

  const [createdByUserOnly, setCreatedByUserOnly] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);

  const onChangeGenres = useCallback(
    (genreIds: string[]) => {
      setFilter({ ...filter, genreIds });
    },
    [filter, setFilter],
  );

  const onChangeTags = useCallback(
    (tagIds: string[]) => {
      setFilter({ ...filter, tagIds });
    },
    [filter, setFilter],
  );

  const opts = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ];

  const templates = useMemo(() => {
    if (!searchQuery.data || !searchQuery.isSuccess) {
      return [];
    }
    if (createdByUserOnly) {
      // currently the user can only see tredition templates or their own - the list is already filtered server side
      return searchQuery.data.filter(($) => $.userId);
    }
    return searchQuery.data;
  }, [searchQuery, createdByUserOnly]);

  const activeTemplate = useMemo<IDesignTemplateSearchResult | null>(() => {
    if (!activeId) {
      return null;
    }
    return templates.find(($) => $.id === activeId) || null;
  }, [activeId, templates]);

  useEffect(() => {
    // clear active id if search results changed and the currently selected template is not among them
    if (searchQuery.data && !searchQuery.data.some(($) => $.id === activeId)) {
      setActiveId(null);
    }
  }, [searchQuery.data, activeId]);

  return (
    <PlainModal isOpen onRequestClose={onRequestClose}>
      <StyledModalContent>
        <Header>
          <BackButton onClick={onRequestClose}> Zurück</BackButton>
          <div>
            <Button cancel onClick={onResetTemplate}>
              {t('StartWithoutTemplate')}
            </Button>
            <Button onClick={() => activeId && onSelectTemplate(activeId)} disabled={!activeId}>
              {t('StartLabel')}
            </Button>
          </div>
        </Header>

        <Filter>
          <StyledLabel>
            <span>{t('Style')}</span>
            <Select
              isMulti
              options={ensureArray(tagOptionsQuery.data)}
              isLoading={tagOptionsQuery.isLoading}
              onChange={onChangeTags}
            />
          </StyledLabel>
          <StyledLabel>
            <span>{t('Genre')}</span>
            <Select
              isMulti
              options={ensureArray(genreOptionsQuery.data)}
              isLoading={genreOptionsQuery.isLoading}
              onChange={onChangeGenres}
            />
          </StyledLabel>
          <StyledCheckBox
            checked={createdByUserOnly}
            onChange={(e) => setCreatedByUserOnly(e.target.checked)}>
            <StyledSpan>{t('ShowOnlyOwnTemplates')}</StyledSpan>
          </StyledCheckBox>
        </Filter>

        <TemplateListWrapper>
          <QueryLoader query={searchQuery}>
            <TemplateList
              templates={templates}
              activeId={activeId}
              isFetching={searchQuery.isFetching}
              onClickTemplate={setActiveId}
            />
          </QueryLoader>
          {!!activeTemplate && (
            <TemplatePreview template={activeTemplate} onClickClose={() => setActiveId(null)} />
          )}
        </TemplateListWrapper>
      </StyledModalContent>
    </PlainModal>
  );
};

const StyledModalContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  padding: 0.25rem;
  display: grid;
  gap: 10px;
  grid-template-rows: 50px 75px 1fr;
  overflow-y: hidden;
`;

const Header = styled.div`
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    gap: 1rem;
  }
`;

const Filter = styled.div`
  padding: 0.25rem;
  display: flex;
  gap: 1rem;
  align-items: end;
  color: white;
`;

const TemplateListWrapper = styled.div`
  display: flex;
`;

const StyledLabel = styled.label`
  display: grid;
  gap: 0.3rem;
  color-scheme: dark;
  color: white;

  & > span {
    font-weight: 600;
  }

  & .react-select__group-heading {
    font-size: 1rem;
  }

  & .react-select__control {
    background-color: black;
    color: white;
    border: 2px solid var(--color-cta-dark);
    box-shadow: var(--color-cta-dark);
  }

  & .react-select__control:hover {
    border-color: var(--color-cta-dark);
  }

  & .react-select__menu,
  & .react-select__placeholder,
  & .indicatorContainer,
  & .react-select__dropdown-indicator,
  & .react-select__dropdown-indicator:hover {
    background-color: black;
    color: white;
    font-size: 0.9rem;
  }

  & .react-select__multi-value,
  & .react-select__multi-value__label {
    background-color: var(--color-cta);
    color: black;
  }

  & .react-select__option {
    background-color: black;
    color: white;
  }

  & .react-select__option--is-focused {
    background-color: var(--color-cta-dark);
  }
`;

const StyledSpan = styled.span`
  color: white;
`;
