import { ElementType, FC, Fragment, useMemo } from 'react';
import { ILayer, LayerType } from './LayersState';
import { ImageLayer } from './ImageLayer';
import { TextLayer } from './TextLayer';
import { PlainLayer } from './PlainLayer';
import { BoundingBox } from './BoundingBox';

export interface ILayerRendererProps {
  /**
   * The layers to render
   */
  layers: ILayer[];

  /**
   * Whether layers clipping outside the sheet should be visible.
   *
   * Taken from the designer state
   */
  showOverflow: boolean;

  /**
   * If false will not render any bounding boxes which are used to interact with layers
   */
  isReadonly: boolean;
}

// required by external event handling outside of React components
export const layersClassName = 'layers';

export const LayerRenderer: FC<ILayerRendererProps> = ({ layers, showOverflow, isReadonly }) => {
  const renderResult = useMemo(() => {
    const boundingBoxElements: JSX.Element[] = [];
    const layerElements: JSX.Element[] = [];

    layers.forEach((layer) => {
      let LayerComponent: ElementType;

      switch (layer.type) {
        case LayerType.Text:
          LayerComponent = TextLayer;
          break;
        case LayerType.Image:
          LayerComponent = ImageLayer;
          break;
        default:
          LayerComponent = PlainLayer;
      }

      layerElements.push(<LayerComponent key={layer.id} layer={layer} />);
      if (!isReadonly) {
        boundingBoxElements.push(<BoundingBox key={layer.id} layer={layer} />);
      }
    });
    return {
      layerElements,
      boundingBoxElements,
    };
  }, [layers, isReadonly]);

  const overflow = showOverflow ? 'visible' : 'hidden';

  return (
    <Fragment>
      <div className={layersClassName} style={{ overflow }}>
        {renderResult.layerElements}
      </div>
      {renderResult.boundingBoxElements}
    </Fragment>
  );
};
