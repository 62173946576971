import { FC, useCallback, useMemo } from 'react';
import { useActiveHistoryPage } from '../../history/historySelectors';
import { useAppDispatch } from '../../../redux/hooks';
import { HistoryEntry } from './HistoryEntry';
import './HistoryPanel.css';
import { restoreProductFromHistoryRecord } from '../../history/restoreProductFromHistoryRecord';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { SidePanelContentWrapper } from '../SidePanelContentWrapper';

type EntrySequence = {
  /**
   * The history record id
   */
  id: string;

  /**
   * The date of creation of this record
   */
  createdAt: number;

  /**
   * The index in the original, unsorted history array. Required for interacting with the history state api
   */
  index: number;
}[];

export const HistoryPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const page = useActiveHistoryPage();
  const activeIndex = page?.index || 0;
  const entrySequence = useMemo<EntrySequence>(() => {
    if (!page) {
      return [];
    }
    return page.records
      .map((record, index) => ({
        id: record.id,
        createdAt: record.createdAt,
        index,
      }))
      .sort((a, b) => b.createdAt - a.createdAt);
  }, [page]);

  const onClickHistoryEntry = useCallback(
    (index: number) => {
      if (index === activeIndex) {
        return;
      }
      dispatch(restoreProductFromHistoryRecord(index));
    },
    [dispatch, activeIndex],
  );

  return (
    <SidePanelContentWrapper>
      <h2>{t('TimeMachine')}</h2>
      {entrySequence.length > 0 ? (
        <StyledList>
          {entrySequence.map((entry) => {
            // note: do not use index param because the list might be sorted so the index would be invalid
            const isActive = entry.index === activeIndex;
            const isSaved = entry.index === (page?.savedAtIndex || 0);
            const record = page?.records[entry.index] || null;
            if (!record) {
              return null; // failsafe - should not be branched into unless entrySequence is bugged
            }
            return (
              <StyledListEntry
                key={record.id}
                isActive={isActive}
                onClick={() => onClickHistoryEntry(entry.index)}
                title={isActive ? undefined : t('DesignHistoryRestoreThisEntry')}>
                <HistoryEntry record={record} isSaved={isSaved} />
              </StyledListEntry>
            );
          })}
        </StyledList>
      ) : (
        <div>{t('NoEntries')}</div>
      )}
    </SidePanelContentWrapper>
  );
};

interface IStyledListEntry {
  isActive: boolean;
}

const StyledListEntry = styled.li.attrs<IStyledListEntry>((props) => ({
  style: {
    backgroundColor: props.isActive ? 'var(--color-cta-light)' : undefined,
    cursor: props.isActive ? undefined : 'pointer',
  },
}))<IStyledListEntry>`
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  padding: 0.25rem 0.5rem;
  transition: background-color ease-in-out 0.2s;

  &:hover {
    background-color: var(--color-cta-light) !important;
  }
`;

const StyledList = styled.ul`
  display: grid;
  align-content: start;
  gap: 0.5rem;
`;
