import { AppThunk } from '../../redux/store';
import { getActiveWorkSpaceOffset, getSheetOffset } from '../sheet/getSheetOffset';
import { getZoom } from '../view/viewSelectors';
import { setInput } from '../input/inputSlice';

/**
 * Only fires if the work space is active
 */
export const handleWorkSpacePointerMove =
  (e: PointerEvent): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const workSpaceOffset = getActiveWorkSpaceOffset();
    const sheetOffset = getSheetOffset();
    const zoomFactor = getZoom(state);

    dispatch(
      setInput({
        mouse: {
          sheet: {
            x: (e.clientX - sheetOffset.x) / zoomFactor,
            y: (e.clientY - sheetOffset.y) / zoomFactor,
          },
          workSpace: {
            x: e.clientX - workSpaceOffset.x,
            y: e.clientY - workSpaceOffset.y,
          },
          client: {
            x: e.clientX,
            y: e.clientY,
          },
        },
        keyboard: {
          shift: e.shiftKey,
          control: e.ctrlKey,
        },
      }),
    );
  };
