/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback } from 'react';
import { ErrorWrapper, IErrorWrapper } from '../forms/errorWrapper/ErrorWrapper';
import { ISelectProps, Select } from './Select';
import { useField } from 'formik';
import { FormError } from '../forms/formError/FormError';
import { OptionLike } from '../../@types/IOption';

export interface ISelectFormikProps<OptionType extends OptionLike<any>, IsMulti extends boolean>
  extends ISelectProps<OptionType, IsMulti>,
    IErrorWrapper {
  name: string;
}

export const SelectFormik = <OptionType extends OptionLike<any>, IsMulti extends boolean>(
  props: ISelectFormikProps<OptionType, IsMulti>,
) => {
  const { name, onChange, className, ...rest } = props;
  const [, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const { setTouched, setValue } = helper;
  const hasError = !!error && touched;

  const onBlurInternal = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const onChangeInternal = useCallback(
    (value: ISelectFormikProps<OptionType, IsMulti>['value']) => {
      setValue(value);
      onChange && onChange(value as any);
    },
    [setValue, onChange],
  );

  return (
    <ErrorWrapper data-type="select" className={className}>
      <Select<OptionType, IsMulti>
        {...rest}
        hasError={hasError}
        onBlur={onBlurInternal}
        onChange={onChangeInternal}
        value={value}
      />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
