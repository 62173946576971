import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavMenu } from '../../navigation/NavMenu';
import { NavItem, NavState } from '../../navigation/NavItem';
import { NavGroup } from '../../navigation/NavGroup';
import { EditorContext } from '../EditorContext';
import styles from './EditorNavigation.module.css';
import { motion } from 'framer-motion';
import { MenuViewConfig } from './MenuViewConfig';

export const EditorNavigation: FC = () => {
  const { goToStep, state } = useContext(EditorContext);
  const { isReadonly } = state;
  const { t } = useTranslation();

  const menu = useMemo(() => {
    let forceDisabled = false;

    return MenuViewConfig.groups.map((groupView) => {
      const isActiveGroup = groupView.steps.some((s) => s.key === state.step.current);
      const firstVisibleStep = groupView.steps.find((s) => {
        return state.step.byKey[s.key]?.isVisible;
      });
      if (!firstVisibleStep) {
        return null;
      }
      return (
        <NavGroup
          key={groupView.key}
          label={t(groupView.label)}
          onClick={() => {
            if (firstVisibleStep) {
              goToStep(firstVisibleStep.key);
            }
          }}
          disabled={forceDisabled}>
          {groupView.steps.map((stepView) => {
            const step = state.step.byKey[stepView.key];
            const isDisabled = forceDisabled;
            if (step && !step.isValid && !isReadonly) {
              // disable every step after the first invalid one
              forceDisabled = true;
            }
            if (!step || !step.isVisible || !isActiveGroup) {
              return null;
            }

            return (
              <NavItem
                key={step.key}
                state={NavState.None}
                active={state.step.current === step.key}
                disabled={isDisabled}
                onClick={() => goToStep(step.key)}
                label={t(stepView.label)}
              />
            );
          })}
        </NavGroup>
      );
    });
  }, [state.step, goToStep, t, isReadonly]);

  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ type: 'tween', duration: 0.25 }}
      className={styles.wrapper}>
      <NavMenu>{menu}</NavMenu>
    </motion.div>
  );
};
