/**
 * Returns a random number that is greater than or equal zero and less than the given maximum.
 *
 * Equivalent to C# Random.Next(int)
 */
export function randomMax(max: number) {
  // C# Random.Next(int)
  return Math.floor(Math.random() * max); // Math.random never returns 1
}

/**
 * Returns a positive integer (or zero) that is _greater than or equal_ min and _less than_ max,
 * i.e. min is _inclusive_ and max is _exclusive_.
 *
 * Equivalent to C# Random.Next(int, int)
 */
export function randomMinMax(min: number, max: number) {
  return min + Math.floor(Math.random() * (max - min)); // Math.random never returns 1
}
