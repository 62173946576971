import { DragEvent, FC, useState } from 'react';
import styled from 'styled-components/macro';

export interface ILayerItemDropperProps {
  /**
   * Whether this dropper is responsible to move the layer above the drop target
   */
  isAbove: boolean;

  /**
   * Invoked when the dragged layer is being dropped onto this dropper
   */
  onDrop: (e: DragEvent<HTMLElement>) => void;
}

export const LayerItemDropper: FC<ILayerItemDropperProps> = ({ isAbove, onDrop }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <StyledDiv
      isAbove={isAbove}
      isActive={isActive}
      onDrop={(e) => {
        e.preventDefault();
        onDrop(e);
      }}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setIsActive(true)}
      onDragLeave={() => setIsActive(false)}
    />
  );
};

interface IStyledDiv {
  isAbove: boolean;
  isActive: boolean;
}

const StyledDiv = styled.div.attrs<IStyledDiv>((props) => ({
  style: {
    background: props.isActive ? 'var(--color-cta)' : undefined,
    top: props.isAbove ? '0' : '50%',
  },
}))<IStyledDiv>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
`;
