import { ErrorWrapper, IErrorWrapper } from '../errorWrapper/ErrorWrapper';
import { useField } from 'formik';
import { FormError } from '../formError/FormError';
import { IToggleButton, ToggleButton } from './ToggleButton';

export interface IToggleButtonFormik extends IToggleButton, IErrorWrapper {
  name: string;
}

export const ToggleButtonFormik = ({ name, className, ...rest }: IToggleButtonFormik) => {
  const [field, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const { setTouched, setValue } = helper;
  const hasError = !!error && touched;

  return (
    <ErrorWrapper data-type="radioGroup" className={className}>
      <ToggleButton value={value} onChange={setValue} onBlur={() => setTouched(true)} {...rest} />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
