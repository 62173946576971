import { AppListenerEffect } from '../../redux/store';
import {
  addImageLayer,
  addPlainLayer,
  addTextLayer,
  adjustDepth,
  deleteLayers,
  formatTextLayer,
  moveLayers,
  pasteLayers,
  releaseLayers,
  replaceImage,
  setBackground,
  setBorder,
  setEffect,
  setImageOptions,
  setPadding,
  setTextContent,
} from '../layer/liveSheetSlice';
import { recordLayerTransform } from './recorders/recordLayerTransform';
import { recordAsIs } from './recorders/recordAsIs';
import { HistoryRecordType } from './historySlice';
import { swapSheets } from '../project/projectSlice';
import {
  addSheet,
  applyTemplateInternal,
  deleteSheetInternal,
  importProductInternal,
  setPaletteInternal,
} from '../project/projectActions';

const throttleOneSecond = { throttleMs: 1000 };

const handlers = {
  [addTextLayer.type]: recordAsIs(HistoryRecordType.LayerCreated),
  [addPlainLayer.type]: recordAsIs(HistoryRecordType.LayerCreated),
  [addImageLayer.type]: recordAsIs(HistoryRecordType.LayerCreated),
  [releaseLayers.type]: recordLayerTransform,
  [deleteLayers.type]: recordAsIs(HistoryRecordType.LayerDeleted),
  [importProductInternal.type]: recordAsIs(HistoryRecordType.ProductImported),
  [applyTemplateInternal.type]: recordAsIs(HistoryRecordType.TemplateApplied),
  [replaceImage.type]: recordAsIs(HistoryRecordType.ImageReplaced),
  [moveLayers.type]: recordAsIs(HistoryRecordType.LayerMoved, throttleOneSecond),
  [pasteLayers.type]: recordAsIs(HistoryRecordType.LayerPasted),
  [adjustDepth.type]: recordAsIs(HistoryRecordType.LayerDepthChanged),
  [setPaletteInternal.type]: recordAsIs(HistoryRecordType.PaletteChanged),
  [setTextContent.type]: recordAsIs(HistoryRecordType.LayerChanged),
  [formatTextLayer.type]: recordAsIs(HistoryRecordType.LayerChanged),
  [setBackground.type]: recordAsIs(HistoryRecordType.LayerChanged),
  [setBorder.type]: recordAsIs(HistoryRecordType.LayerChanged, throttleOneSecond),
  [setEffect.type]: recordAsIs(HistoryRecordType.LayerChanged, throttleOneSecond),
  [setImageOptions.type]: recordAsIs(HistoryRecordType.LayerChanged, throttleOneSecond),
  [setPadding.type]: recordAsIs(HistoryRecordType.LayerChanged, throttleOneSecond),
  [deleteSheetInternal.type]: recordAsIs(HistoryRecordType.SheetDeleted),
  [swapSheets.type]: recordAsIs(HistoryRecordType.SheetReordered),
  [addSheet.type]: recordAsIs(HistoryRecordType.SheetCreated),
};

export const historyEffect: AppListenerEffect = async (action, listenerApi) => {
  const handler = handlers[action.type];
  if (handler) {
    handler(action, listenerApi);
  }
};
