import styled from 'styled-components/macro';

export const TopWrapper = styled.div`
  padding: 0 23rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  height: 2rem;
  background-color: var(--color-background);
`;
