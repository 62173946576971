import { AppThunk } from '../../redux/store';
import { getDesignerSlice } from '../designerSelectors';
import { grabLayer } from './liveSheetSlice';
import { TransformationType } from './ILiveSheetState';
import { getLayerById } from './liveSheetSelectors';

export const startRotationTransform =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const layer = getLayerById(state, id);
    if (!layer) {
      return;
    }
    const {
      input: { mouse },
    } = getDesignerSlice(state);
    const startPoint = mouse.sheet;
    const centerPoint = {
      x: layer.x + layer.width / 2,
      y: layer.y + layer.height / 2,
    };
    dispatch(
      grabLayer({
        id,
        transform: {
          type: TransformationType.Rotate,
          grabbedLayerId: id,
          startPoint,
          centerPoint,
          endPoint: startPoint,
        },
      }),
    );
  };
