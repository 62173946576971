import { FC, useCallback, useState } from 'react';
import {
  IPixabayFilter,
  PixabayOrientation,
  useGetDesignerImagesQuery,
} from '../../api/treditionApi';
import { useAppDispatch } from '../../redux/hooks';
import { DesignerImageSource, DragContextType, dragStart } from '../drag/dragSlice';
import { QueryLoader } from '../../loader/QueryLoader';
import styled from 'styled-components/macro';
import { MenuButton } from '../../uiComponents/MenuButton';
import { useTranslation } from 'react-i18next';
import { ImagePreview } from '../imagePreview/ImagePreview';
import { ColorDisplay } from '../palette/ColorDisplay';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';
import { Rgba } from '../../lib/Color';

export const initialFilter = Object.freeze<IPixabayFilter>({ page: 0 });

const filterSwatches = [
  ['#D3D3D3FF', 'lightgray'],
  ['#00000000', 'transparent'],
  ['#FF0000FF', 'red'],
  ['#FFA500FF', 'orange'],
  ['#FFFF00FF', 'yellow'],
  ['#00FF00FF', 'green'],
  ['#00FFFFFF', 'turquoise'],
  ['#0000FFFF', 'blue'],
  ['#8A2BE2FF', 'blueviolet'],
  ['#FFC0CBFF', 'pink'],
  ['#FFFFFFFF', 'white'],
  ['#808080FF', 'gray'],
  ['#000000FF', 'black'],
  ['#A52A2AFF', 'brown'],
];

export const GalleryPanel: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [filter, setFilter] = useState<IPixabayFilter>(initialFilter);
  const query = useGetDesignerImagesQuery(filter);

  const onClickPrevPage = useCallback(() => {
    setFilter({ ...filter, page: Math.max(filter.page - 1, 0) });
  }, [filter]);

  const onClickNextPage = useCallback(() => {
    setFilter({ ...filter, page: filter.page + 1 });
  }, [filter]);

  return (
    <Wrapper>
      <h2>{t('ImagesAndLogo')}</h2>
      <h3>{t('Images')}</h3>

      <input
        type="text"
        placeholder={t('Search')}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !query.isFetching) {
            e.preventDefault();
            setFilter({ ...filter, searchTerm: e.currentTarget.value });
          }
        }}
        onBlur={(e) => {
          if (!query.isFetching) {
            setFilter({ ...filter, searchTerm: e.target.value });
          }
        }}
        disabled={query.isFetching}
      />

      <FilterSegment>
        <span className="filter-label">{t('Colors')}</span>
        <ColorDisplay
          className="no-color"
          rgba={Rgba.white()}
          onClick={() => {
            if (!query.isFetching) {
              setFilter({ ...filter, color: undefined });
            }
          }}
          isSelected={typeof filter.color === 'undefined'}
        />
        {filterSwatches.map(([hex, filterName]) => (
          <ColorDisplay
            key={hex}
            hex={hex}
            onClick={() => {
              if (!query.isFetching) {
                setFilter({ ...filter, color: filterName });
              }
            }}
            isSelected={filter.color === filterName}
          />
        ))}
      </FilterSegment>

      <FilterSegment>
        <span className="filter-label">{t('Alignment')}</span>
        <select
          defaultValue="a"
          onChange={(e) => {
            let orientation: PixabayOrientation | undefined;
            switch (e.target.value) {
              case 'a':
              default:
                orientation = undefined;
                break;
              case 'h':
                orientation = PixabayOrientation.Horizontal;
                break;
              case 'v':
                orientation = PixabayOrientation.Vertical;
            }
            setFilter({ ...filter, orientation });
          }}>
          <option value="h">{t('Horizontal')}</option>
          <option value="v">{t('Vertical')}</option>
          <option value="a">{t('All')}</option>
        </select>
      </FilterSegment>

      {query.isSuccess && (
        <Pagination>
          <MenuButton onClick={onClickPrevPage} disabled={query.isFetching}>
            ←
          </MenuButton>
          <span>
            {t('Page')} {filter.page + 1}
          </span>
          <MenuButton onClick={onClickNextPage} disabled={query.isFetching}>
            →
          </MenuButton>
        </Pagination>
      )}

      <ImageWrapper isBusy={query.isFetching}>
        <QueryLoader query={query}>
          {(query.data || []).map(({ image, thumbnail }) => (
            <img
              key={image}
              src={thumbnail}
              alt=""
              className="element image"
              onDragStart={() => {
                dispatch(
                  dragStart({
                    type: DragContextType.DesignerImage,
                    fileId: image,
                    source: DesignerImageSource.Pixabay,
                  }),
                );
              }}
              onClick={() => {
                setPreviewUrl(image);
              }}
              draggable
              role="button"
            />
          ))}
        </QueryLoader>
      </ImageWrapper>
      {!!previewUrl && (
        <ImagePreview imageSrc={previewUrl} onRequestClose={() => setPreviewUrl(null)} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(SidePanelContentWrapper)`
  span.filter-label {
    display: block;
    width: 100%;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
`;

const FilterSegment = styled.div`
  display: flex;
  flex-wrap: wrap;

  .no-color ::after {
    content: 'X';
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 200%;
  }
`;

const ImageWrapper = styled.div.attrs<{ isBusy: boolean }>((props) => ({
  style: {
    opacity: props.isBusy ? 0.5 : 1,
  },
}))<{ isBusy: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7px;

  & > img {
    max-width: 40%;
    max-height: 110px;
    border-radius: var(--border-radius);
    box-shadow: 2px 2px 5px var(--box-shadow-color);
    cursor: pointer;
  }

  & > img:hover {
    outline: 5px solid var(--color-cta);
    outline-offset: -2px;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;
