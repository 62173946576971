import { ProductType } from './ProductType';

export interface IBookType {
  /**
   * The type of the book
   */
  type: Exclude<ProductType, ProductType.None>;
  name: string;
  hasFormatSettings: boolean;
  labelKey: string;
  imageUrl: string;
}

export const bookTypes: IBookType[] = [
  {
    type: ProductType.SoftCover,
    name: 'softCover',
    hasFormatSettings: true,
    labelKey: 'ProductSoftcover',
    imageUrl:
      'https://treditionworkerstaging.blob.core.windows.net/codeimages/3dddf262-754f-4a47-9884-e14adfefb762.png',
  },
  {
    type: ProductType.HardCover,
    name: 'hardCover',
    hasFormatSettings: true,
    labelKey: 'ProductHardcover',
    imageUrl:
      'https://treditionworkerstaging.blob.core.windows.net/codeimages/c3512c40-e2af-4506-be5d-59b048cfaa08.png',
  },
  {
    type: ProductType.Ebook,
    name: 'e-book',
    hasFormatSettings: false,
    labelKey: 'ProductEbook',
    imageUrl:
      'https://treditionworkerstaging.blob.core.windows.net/codeimages/668868e7-1e05-4900-b6c5-02ef6758a483.png',
  },
  {
    type: ProductType.LargePrint,
    name: 'largePrint',
    hasFormatSettings: true,
    labelKey: 'ProductLargePrint',
    imageUrl:
      'https://treditionworkerstaging.blob.core.windows.net/codeimages/3dddf262-754f-4a47-9884-e14adfefb762.png',
  },
];
