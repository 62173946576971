import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { lookupProductByIdReducer } from './lookupProductByIdReducer';
import { applyPaletteToLayers } from '../layer/applyPaletteToLayersReducer';
import { ISetPaletteActionPayload } from './projectActions';

export function setPaletteReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<ISetPaletteActionPayload>,
) {
  if (!state) {
    return;
  }
  const { productId, palette } = action.payload;
  const product = lookupProductByIdReducer(state, productId);
  if (!product) {
    return;
  }
  const currentPalette = product.palette;
  product.palette = palette;
  product.sheets.forEach((sheet) => {
    applyPaletteToLayers(sheet.layers, palette, currentPalette);
  });
}
