import { AppThunk } from '../redux/store';
import { treditionApi } from '../api/treditionApi';
import { setUser } from './userSlice';
import { IUser } from './IUserState';
import { getUserId } from '../auth/authSelectors';

export const loadImplicitUser = (): AppThunk => async (dispatch, getState) => {
  if (getUserId(getState()) === null) {
    return null; // no need to load a user if no JWT is present - not logged in
  }
  // TODO: no need to load users if the JWT expired - add this check here
  let user: IUser | null = null;
  try {
    user = await dispatch(treditionApi.endpoints.getImplicitUser.initiate()).unwrap();
    dispatch(setUser(user));
  } catch (error) {
    dispatch(setUser(null));
  }
  return user;
};
