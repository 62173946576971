import { Root } from 'react-dom/client';
import TRC_UNMOUNT = globalThis.TRC_UNMOUNT;

/**
 * Creates the `TRC_UNMOUNT` function which is used by the legacy tredition client
 * to unmount the React App before navigating to a different client side route.
 * Must be called exactly once each time the React App should be unmounted otherwise event listeners
 * will not be properly cleaned up.
 */
export function createTrcUnmount(root: Root): typeof TRC_UNMOUNT {
  return function () {
    root.unmount();
  };
}
