import { FC, useCallback, useRef, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import {
  useDeleteCoverTemplateMutation,
  useRenameCoverTemplateMutation,
  useSearchDesignTemplatesQuery,
} from '../../api/treditionApi';
import { TemplateList } from '../templates/TemplateList';
import { QueryLoader } from '../../loader/QueryLoader';
import { noop } from '../../lib/noop';
import { useDesignerMode } from '../view/viewSelectors';
import { DesignerMode } from '../designerMode';
import { MenuButton } from '../../uiComponents/MenuButton';
import styled from 'styled-components/macro';
import { CircleIconButton } from '../../uiComponents/CircleIconButton';
import { useTranslation } from 'react-i18next';
import { saveAsTemplate } from '../project/saveAsTemplate';
import { toggleTemplateModal } from '../templates/templateSlice';
import { useTemplateSlice } from '../templates/templateSelectors';
import { Icon } from '../../icons/Icon';
import { ActionModal } from '../../modal/ActionModal';
import { toast } from 'react-hot-toast';
import { Confirm } from '../../modal/Confirm';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';
import { applyTemplateWithConfirm } from '../templates/applyTemplateWithConfirm';

export const TemplatesPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const mode = useDesignerMode();
  const { filter } = useTemplateSlice();
  const [renameTemplateState, setRenameTemplateState] = useState<{
    id: string | null;
    name: string;
  }>({
    id: null,
    name: '',
  });
  const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>(null);
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] = useState<boolean>(false);
  const openSaveAsTemplateModal = useCallback(() => setIsSaveAsTemplateModalOpen(true), []);
  const closeSaveAsTemplateModal = useCallback(() => setIsSaveAsTemplateModalOpen(false), []);
  const openTemplateModal = useCallback(() => dispatch(toggleTemplateModal(true)), [dispatch]);
  const saveAsTemplateNameInputRef = useRef<HTMLInputElement>(null);
  const searchQuery = useSearchDesignTemplatesQuery({ filter, mode });
  const [renameTemplateMutation] = useRenameCoverTemplateMutation();
  const [deleteTemplateMutation] = useDeleteCoverTemplateMutation();

  const onRequestApplyTemplate = useCallback(
    (templateId: string) => {
      dispatch(applyTemplateWithConfirm(templateId));
    },
    [dispatch],
  );

  const closeDeleteTemplateModal = useCallback(() => {
    setDeleteTemplateId(null);
  }, []);

  const closeRenameTemplateModal = useCallback(() => {
    setRenameTemplateState({ id: null, name: '' });
  }, []);

  const deleteTemplate = useCallback((): Promise<void> => {
    if (!deleteTemplateId) {
      closeDeleteTemplateModal();
      return Promise.resolve();
    }
    const deleteTask = deleteTemplateMutation(deleteTemplateId).unwrap();

    return toast
      .promise(deleteTask, {
        error: t('DeleteTemplateError'),
        loading: t('DeleteTemplateLoading'),
        success: t('DeleteTemplateSuccess'),
      })
      .then(() => {
        closeDeleteTemplateModal();
      })
      .catch(noop);
  }, [closeDeleteTemplateModal, deleteTemplateId, deleteTemplateMutation, t]);

  const renameTemplate = useCallback((): Promise<void> => {
    if (!renameTemplateState.id) {
      closeRenameTemplateModal();
      return Promise.resolve();
    }
    const renameTask = renameTemplateMutation({
      id: renameTemplateState.id,
      name: renameTemplateState.name,
    }).unwrap();

    return toast
      .promise(renameTask, {
        error: t('RenameTemplateError'),
        loading: t('RenameTemplateLoading'),
        success: t('RenameTemplateSuccess'),
      })
      .then(() => {
        closeRenameTemplateModal();
      })
      .catch(noop);
  }, [closeRenameTemplateModal, renameTemplateState, renameTemplateMutation, t]);

  const showRenameTemplateModal = useCallback((templateId: string, currentName: string) => {
    setRenameTemplateState({ id: templateId, name: currentName });
  }, []);

  const onSubmitSaveAsTemplate = useCallback((): Promise<void> => {
    if (!saveAsTemplateNameInputRef.current) {
      setIsSaveAsTemplateModalOpen(false);
      return Promise.resolve();
    }
    const saveTask = dispatch(saveAsTemplate(saveAsTemplateNameInputRef.current.value));
    return toast
      .promise(saveTask, {
        error: t('SaveAsTemplateError'),
        loading: t('SaveAsTemplateLoading'),
        success: t('SaveAsTemplateSuccess'),
      })
      .then(() => {
        setIsSaveAsTemplateModalOpen(false);
      })
      .catch(noop);
  }, [dispatch, saveAsTemplateNameInputRef, t]);

  if (mode !== DesignerMode.Cover) {
    return null;
  }

  return (
    <SidePanelContentWrapper className="templates-panel">
      <CircleIconButton iconSize="large" icon="ansehen-control" onClick={openTemplateModal}>
        {t('TemplatePreview')}
      </CircleIconButton>
      <CircleIconButton iconSize="large" icon="plus-control" onClick={openSaveAsTemplateModal}>
        {t('SaveAsNewTemplate')}
      </CircleIconButton>
      <ListHeader className="list-header">
        <h2>{t('YourChoice')}</h2>
        <MenuButton onClick={openTemplateModal}>
          <Icon name="filter" />
        </MenuButton>
      </ListHeader>

      <QueryLoader query={searchQuery}>
        {searchQuery.data?.some((tp) => tp.userId !== null) && (
          <>
            <h2>{t('OwnTemplates')}</h2>
            <TemplateList
              templates={searchQuery.data?.filter((tp) => tp.userId !== null) || []}
              activeId={null}
              isFetching={searchQuery.isFetching}
              onClickTemplate={onRequestApplyTemplate}
              onClickRenameTemplate={showRenameTemplateModal}
              onClickDeleteTemplate={setDeleteTemplateId}
            />
          </>
        )}
        <h2>{t('CoverTemplatesTredition')}</h2>
        <TemplateList
          templates={searchQuery.data?.filter((tp) => tp.userId === null) || []}
          activeId={null}
          isFetching={searchQuery.isFetching}
          onClickTemplate={onRequestApplyTemplate}
          onClickRenameTemplate={showRenameTemplateModal}
          onClickDeleteTemplate={setDeleteTemplateId}
        />
      </QueryLoader>

      <ActionModal
        isOpen={isSaveAsTemplateModalOpen}
        onRequestClose={closeSaveAsTemplateModal}
        title={t('NewTemplate')}
        onOk={onSubmitSaveAsTemplate}
        onCancel={closeSaveAsTemplateModal}>
        <input ref={saveAsTemplateNameInputRef} type="text" placeholder={t('TemplateName')} />
      </ActionModal>

      <ActionModal
        isOpen={!!renameTemplateState.id}
        onOk={renameTemplate}
        onCancel={closeRenameTemplateModal}
        title={t('EditTemplate')}>
        <input
          type="text"
          autoFocus
          placeholder={t('TemplateName')}
          value={renameTemplateState.name}
          onChange={(e) => {
            setRenameTemplateState({ ...renameTemplateState, name: e.target.value });
          }}
        />
      </ActionModal>

      <Confirm
        title={t('Delete')}
        isOpen={!!deleteTemplateId}
        onYes={deleteTemplate}
        onNo={closeDeleteTemplateModal}>
        {t('DeleteTemplateConfirmation')}
      </Confirm>
    </SidePanelContentWrapper>
  );
};

const ListHeader = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;
