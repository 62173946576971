import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * Creates a reducer which simply overwrites the current state with the given action payload.
 *
 * TypeScript cannot infer the type without this factory approach when using it in the RTK slice factory
 * even though the function is always the same and only the type differs.
 */
export function createOverwriteStateReducer<State>() {
  return (state: Draft<State>, action: PayloadAction<State>): State => action.payload;
}
