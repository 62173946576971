/**
 * Identifies a side panel of the designer
 */
export enum SidePanelId {
  None,
  Layers,
  TextLayers,
  Gallery,
  ImageTiling,
  Palettes,
  Effects,
  Colors,
  Measures,
  Templates,
  Patterns,
  Depth,
  History,
  Uploads,
}
