import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { ILiveSheetState } from './ILiveSheetState';

export interface IDeselectLayerActionPayload {
  /**
   * Id of layer to deselect
   */
  id: string;
}

/**
 * Deselects one layer based on the given criteria. Does nothing if the layer was not found.
 */
export function deselectLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IDeselectLayerActionPayload>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (layer) {
    layer.isSelected = false;
  }
}
