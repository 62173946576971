import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { deselectLayersReducer } from './deselectLayersReducer';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISelectLayerActionPayload {
  id: string;
  exclusive: boolean;
  toggle?: boolean;
}

export function selectLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISelectLayerActionPayload>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (!layer) {
    return;
  }
  const wasSelected = layer.isSelected;
  if (action.payload.exclusive) {
    // exclusive should overwrite toggle, otherwise it is a bit weird to have multiple layers selected
    // and clicking on a single one will not select the single layer, but deselect all of them
    // (because the exclusive one is also toggled from selected → deselected)
    deselectLayersReducer(state);
    layer.isSelected = true;
    return;
  }
  layer.isSelected = action.payload.toggle ? !wasSelected : true;
}
