import styled from 'styled-components/macro';

export const CKToolbarWrapper = styled.div`
  .ck-color-ui-dropdown,
  .ck-dropdown-line-height,
  .ck-dropdown-character-spacing {
    .ck-dropdown__arrow {
      display: none;
    }
  }

  .ck.ck-toolbar > .ck-toolbar__items {
    height: 32px;
  }

  .ck .ck-character-spacing-wrapper,
  .ck-line-height-wrapper {
    width: 16rem;

    .ck.ck-input {
      padding: 0;
      border: 0;
    }

    padding: 0.3rem;
    display: grid;
    grid-template-columns: 1fr 1.5rem;

    & input[type='range'] {
      accent-color: var(--color-cta);
      grid-column: span 2;
    }
  }

  .ck-font-size-dropdown .ck-dropdown__panel,
  .ck-font-family-dropdown .ck-dropdown__panel {
    overflow: scroll;
    max-height: 30rem;
  }

  .ck-dropdown__panel .ck-toolbar__items {
    display: flex;
    flex-flow: initial !important;
    flex-grow: initial !important;
    flex-direction: initial !important;
    gap: 0.1rem;
  }

  .ck.ck-toolbar .ck.ck-toolbar__items .ck-font-size-dropdown {
    max-width: 5rem;

    .ck.ck-button__label {
      font-size: 16px !important;
      width: min-content;
    }
  }

  .ck.ck-toolbar .ck.ck-toolbar__items .ck-color-ui-dropdown {
    .ck-tooltip {
      display: none;
    }

    .ck-dropdown__button {
      > svg:first-child {
        left: 8px;
        position: relative;
      }

      > .ck.ck-button__label {
        position: absolute;
        width: 20px;
        height: 3px;
        bottom: 4px;
      }
    }
  }

  & .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    top: calc(100% + 1px);
  }

  & .ck.ck-toolbar .ck.ck-toolbar__items .ck-button {
    &.ck.ck-color-grid__tile {
      &:hover {
        background-color: inherit;
      }
    }

    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: var(--border-radius);

    font-size: 0.9rem !important;
    font-family: 'Barlow', sans-serif;
    transition: background-color 0.2s;
    cursor: pointer;

    background-color: transparent;

    &.ck-on {
      background-color: var(--color-cta);
    }

    &:hover {
      background-color: var(--color-cta-light);
    }

    svg.ck-dropdown__arrow {
      font-size: 12px !important;
    }

    & > svg:not(.ck-dropdown__arrow) {
      height: 12px;
      width: auto;
    }

    &:nth-child(3) > svg {
      height: 16px;
    }

    &:nth-child(4) > svg {
      height: 11px;
    }
  }
`;
