import { ILayer } from './LayersState';

/**
 * Writes the current preview display data to its actual properties
 */
export function commitDisplay(layer: ILayer): void {
  layer.x = layer.display.x;
  layer.y = layer.display.y;
  layer.width = layer.display.width;
  layer.height = layer.display.height;
  layer.transform.rotate = layer.display.rotation;
  layer.updatedAt = Date.now();
}
