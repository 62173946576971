import React, { CSSProperties } from 'react';
import styles from './AlertBox.module.css';
import classNames from 'classnames';
import { Icon } from '../icons/Icon';

export interface IAlertBox {
  children: React.ReactNode;
  mood: AlertBoxMood;
  className?: string;
}

export enum AlertBoxMood {
  danger = 'danger',
  warning = 'warning',
  hint = 'hint',
}

export const AlertBox: React.FC<IAlertBox> = ({ children, mood, className }) => {
  const moodStyles: { [key in AlertBoxMood]: string } = {
    [AlertBoxMood.danger]: 'var(--color-danger)',
    [AlertBoxMood.warning]: 'var(--color-warning)',
    [AlertBoxMood.hint]: 'var(--color-hint)',
  };

  const moodIcons: { [key in AlertBoxMood]: string } = {
    [AlertBoxMood.danger]: 'achtung',
    [AlertBoxMood.warning]: 'achtung',
    [AlertBoxMood.hint]: 'info',
  };

  return (
    <div
      style={{ '--local-outline-color': moodStyles[mood] } as CSSProperties}
      className={classNames(styles.wrapper, className)}>
      <Icon name={moodIcons[mood]} size={'24px'} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
