import styled from 'styled-components/macro';
import { MenuRightWrapper } from '../designer/menu/MenuRightWrapper';

interface IMenuButtonDesigner {
  gap?: number;
  isActive?: boolean;
  className?: string;
}

export const MenuButtonBookType = styled.button.attrs<IMenuButtonDesigner>((props) => ({
  style: {
    backgroundColor: props.isActive ? 'var(--color-cta)' : 'white',
    fontWeight: props.isActive ? '600' : '400',
  },
}))<IMenuButtonDesigner>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem 0.5rem;
  border: none;

  font-size: 0.8rem;
  line-height: normal;
  font-family: 'Barlow', sans-serif;
  transition: background-color 0.2s;
  cursor: pointer;

  width: 80px;
  height: 80px;

  &:hover {
    background-color: var(--color-cta-light) !important;
    font-weight: 600 !important;
  }
`;
