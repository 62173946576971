import { IPoint } from '../../@types/IPoint';

export interface IRect {
  topLeft: IPoint;
  bottomRight: IPoint;
}

/**
 * Returns true if the given two rectangles intersect
 */
export function rectsIntersect(rectA: IRect, rectB: IRect) {
  return !(
    rectB.topLeft.x > rectA.bottomRight.x ||
    rectB.bottomRight.x < rectA.topLeft.x ||
    rectB.topLeft.y > rectA.bottomRight.y ||
    rectB.bottomRight.y < rectA.topLeft.y
  );
}
