import { AppThunk } from '../../redux/store';
import { deleteSheetInternal } from './projectActions';
import { getActiveProduct } from './productSelectors';

/**
 * Deletes the sheet of the currently active product having the given index
 */
export const deleteSheet =
  (sheetIndex: number): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const product = getActiveProduct(state);
    if (!product) {
      return;
    }
    const sheetToDelete = product.sheets[sheetIndex];
    if (!sheetToDelete) {
      return;
    }
    if (sheetToDelete.id !== product.activeSheetId) {
      dispatch(deleteSheetInternal({ sheetIndex }));
      return;
    }
    let nextSheet = product.sheets[sheetIndex + 1];
    if (!nextSheet) {
      nextSheet = product.sheets[sheetIndex - 1];
    }
    if (nextSheet) {
      // found a successor
      dispatch(
        deleteSheetInternal({
          sheetIndex,
          nextActiveSheetId: nextSheet.id,
          nextLayers: nextSheet.layers,
        }),
      );
    } else {
      // no sheets left
      dispatch(
        deleteSheetInternal({
          sheetIndex,
          nextActiveSheetId: null,
          nextLayers: [],
        }),
      );
    }
  };
