import { IQuery } from '../../@types/IQuery';

export enum QueryErrorType {
  Internal,
  Connection,
  NotFound,
  AccessDenied,
}

export interface IQueryErrorResolution {
  type: QueryErrorType;
  isRetryable: boolean;
}

export const defaultRetryConfig = new Set([QueryErrorType.Connection]);

export function resolveQueryError(
  query: IQuery,
  retryConfig = defaultRetryConfig,
): IQueryErrorResolution {
  const status = (query.error as Record<string, unknown>)?.status;
  let type = QueryErrorType.Internal;

  if (status === 'FETCH_ERROR') {
    type = QueryErrorType.Connection;
  } else if (status === 404) {
    type = QueryErrorType.NotFound;
  } else if (status === 401 || status === 403) {
    type = QueryErrorType.AccessDenied;
  }

  return {
    type,
    isRetryable: retryConfig.has(type),
  };
}
