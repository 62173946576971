export enum FormattingEnumeration {
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  TEXT_ALIGN = 'text-align',
}

export type IFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: string;
  [FormattingEnumeration.FONT_SIZE]: number;
  [FormattingEnumeration.BOLD]: boolean;
  [FormattingEnumeration.ITALIC]: boolean;
  [FormattingEnumeration.UNDERLINED]: boolean;
  [FormattingEnumeration.TEXT_ALIGN]: string;
};

export enum FontsEnumeration {
  ARIAL = 'arial',
  BOOK_ANTIQUA = 'book-antiqua',
  COURIER_NEW = 'courier-new',
  TIMES_NEW_ROMAN = 'times-new-roman',
}

export enum TextAlignEnumeration {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  BLOCK = 'justify',
}

export const FontOptions = [
  {
    value: FontsEnumeration.ARIAL,
    label: 'Arial',
  },
  {
    value: FontsEnumeration.BOOK_ANTIQUA,
    label: 'Book Antiqua',
  },
  {
    value: FontsEnumeration.COURIER_NEW,
    label: 'Courier New',
  },
  {
    value: FontsEnumeration.TIMES_NEW_ROMAN,
    label: 'Times New Roman',
  },
];

interface IFontSizeOptions {
  value: number;
  label: string;
}

export const FontSizeOptions: IFontSizeOptions[] = [
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 14, label: '14' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 22, label: '22' },
  { value: 24, label: '24' },
  { value: 26, label: '26' },
  { value: 28, label: '28' },
  { value: 36, label: '36' },
  { value: 48, label: '48' },
  { value: 72, label: '72' },
];
