import { Action, ListenerEffectAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../redux/store';
import { HistoryRecordType } from '../historySlice';
import { IWriteHistoryRecordOptions, writeHistoryRecord } from '../writeHistoryRecord';

/**
 * Generates a recording function without further constraints for the given type
 */
export const recordAsIs =
  (type: HistoryRecordType, options?: IWriteHistoryRecordOptions) =>
  (action: Action<string>, listenerApi: ListenerEffectAPI<RootState, AppDispatch>) => {
    listenerApi.dispatch(writeHistoryRecord({ type }, options));
  };
