import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILayer } from './LayersState';
import { ILiveSheetState } from './ILiveSheetState';

/**
 * The new layers to overwrite the current state with
 */
type ISetLayersActionPayload = ILayer[];

/**
 * Assigns the given layers and resets any currently active layer transform
 *
 * Caution when using the {@link setLayers} action as the live sheet must always display the layers
 * of the currently active sheet
 */
export function setLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetLayersActionPayload>,
) {
  state.layers = action.payload;
  state.transform = null;
}
