import React from 'react';
import { IEditorState, StepDirtyFn, StepSubmitFn, StepValidateFn } from './useEditorState';
import { EditorStepKey } from './EditorStepKey';

export interface IEditorContext {
  state: Readonly<IEditorState>;

  /**
   * Invoked by every editor page component to register their submit and validate functions
   */
  registerStep(submit: StepSubmitFn, validate: StepValidateFn, checkDirty: StepDirtyFn): void;

  /**
   * Causes the editor to navigate to the next step, including any validation or submission logic
   */
  goToNextStep(): void;

  /**
   * Causes the editor to navigate to the previous step, including any validation or submission logic
   */
  goToPreviousStep(): void;

  /**
   * Triggers the logic to finish the project
   */
  finishProject(): void;

  /**
   * Causes the editor to navigate to the given step, including any validation or submission logic
   */
  goToStep(step: EditorStepKey): void;

  /**
   * Sets the isVisible flag for a step. If no step is provided, the current step is assumed
   */
  setStepVisibility(isVisible: boolean, step?: EditorStepKey): void;
}

export const EditorContext = React.createContext<IEditorContext>(undefined!);
