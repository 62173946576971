import { ColorPickerModal } from '../palette/ColorPickerModal';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CKEContext } from '../textEditor/CKEditorProvider';
import { hslToCssString } from '../../lib/hslToCssString';
import { rgbToHsl } from '../../lib/rgbToHsl';
import { IHSLA, IRGBA, Rgba } from '../../lib/Color';
import { parseHSLString } from '../../lib/parseHSLString';
import { hslToRgb } from '../../lib/hslToRgb';
import { useActiveProduct } from '../project/productSelectors';
import { rgbToHex } from '../../lib/rgbToHex';
import omit from 'lodash/omit';
import { IProduct } from '../project/ProductState';

const INITIAL_COLOR = Rgba.black();
const INITIAL_COLOR_HSLA = { h: 0, s: 0, l: 0, a: 1 };

export const CKCustomColorPickerInjector = () => {
  const { editor } = useContext(CKEContext);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState<'fontColor' | 'fontBackgroundColor'>(
    'fontColor',
  );
  const [color, setColor] = useState<IRGBA>(INITIAL_COLOR);
  const { palette } = useActiveProduct() || ({} as IProduct);

  const indexOfPalette = useMemo(() => {
    const activeColorHex = rgbToHex(omit(color, 'a'), true, false).toLowerCase();
    const colorsOfPalette: [string, string][] = Object.entries(palette || {}).filter(
      ([k, v]) => k.startsWith('color') && typeof v === 'string',
    );
    const indexOfPalette = colorsOfPalette.findIndex(([k, v]) => v === activeColorHex);
    return indexOfPalette || -1;
  }, [color, palette]);

  useEffect(() => {
    if (!editor) return;
    // on every color change we focus again the editor, otherwise the saving mechanism will not be triggered
    editor.focus();
  }, [color, editor]);

  useEffect(() => {
    const eventName = 'ckeditor:custom:openDialog';
    const listener = ((
      evt: CustomEvent<{ component: 'fontColor' | 'fontBackgroundColor'; color: string }>,
    ) => {
      setPickerOpen(true);
      setActiveColorPicker(evt.detail.component);
      let hslColor: IHSLA;
      try {
        hslColor = parseHSLString(evt.detail.color);
      } catch (e) {
        hslColor = INITIAL_COLOR_HSLA;
      }
      const rgba = {
        ...hslToRgb(hslColor),
        a: hslColor.a,
      };
      setColor(rgba);
    }) as EventListener;

    document.addEventListener(eventName, listener);

    return () => {
      document.removeEventListener(eventName, listener);
    };
  }, []);

  if (!pickerOpen || !editor) return null;

  return (
    <ColorPickerModal
      onPickColor={(color) => {
        const hsla = { ...rgbToHsl(color.rgba), a: color.rgba.a };
        editor.execute(activeColorPicker, { value: hslToCssString(hsla) });
        editor.editing.view.focus();
        setPickerOpen(false);
      }}
      palette={palette}
      initialColor={{ rgba: color, paletteIndex: indexOfPalette }}
      onCancel={() => setPickerOpen(false)}
    />
  );
};
