import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { IModalProps, Modal } from './Modal';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { isPromise } from '../lib/isPromise';
import { BasicButton, ButtonType } from '../buttons/basic/BasicButton';

export interface IActionModalProps extends IModalProps {
  /**
   * Invoked when the ok button is clicked. If the onOk invocation returned a Promise
   * the buttons will be disabled until the promise is resolved.
   */
  onOk: () => unknown;

  /**
   * Translated button label to display on the OK button
   */
  okButtonLabel?: string;

  /**
   * Translated button label to display on the cancel button
   */
  cancelButtonLabel?: string;

  /**
   * Invoked when the cancel button is clicked. Should ideally close the modal.
   */
  onCancel: () => unknown;

  /**
   * Title of the modal
   */
  title?: string;
  /**
   * If true, the ok button will be disabled
   */
  okButtonDisabled?: boolean;
}

/**
 * Renders a modal which renders an OK and a cancel button. The cancel button should close the modal
 * and execute no action.
 */
export const ActionModal: FC<PropsWithChildren<IActionModalProps>> = (props) => {
  const { t } = useTranslation();
  const {
    children,
    onOk,
    onCancel,
    okButtonLabel,
    cancelButtonLabel,
    okButtonDisabled,
    ...baseModalProps
  } = props;
  const [isBusy, setBusy] = useState<boolean>(false);
  const okLabel = okButtonLabel || t('OK');
  const cancelLabel = cancelButtonLabel || t('Cancel');

  const onClickYesButton = useCallback(() => {
    const result = onOk();
    if (isPromise(result) && typeof result.finally === 'function') {
      setBusy(true);
      result.finally(() => {
        setBusy(false);
      });
    }
  }, [onOk]);

  return (
    <Modal onRequestClose={onCancel} {...baseModalProps}>
      {children}
      <Footer>
        <BasicButton
          label={cancelLabel || ''}
          onClick={onCancel}
          mood={ButtonType.Link}
          disabled={isBusy}
        />
        <BasicButton
          label={okLabel || ''}
          onClick={onClickYesButton}
          disabled={isBusy || okButtonDisabled}
        />
      </Footer>
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--color-border);
  gap: 0.5rem;
  justify-content: flex-end;

  button {
    min-width: 150px;
    padding: 0.5rem 1rem;
    font-weight: 600;
  }
`;
