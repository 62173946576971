import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { lookupProductByIdReducer } from './lookupProductByIdReducer';
import { IRestoreFromHistoryActionPayload } from '../history/restoreFromHistoryReducer';

export function applyRestoreFromHistoryToProjectReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IRestoreFromHistoryActionPayload>,
) {
  const { productId, record } = action.payload;
  if (!state || state.activeProduct !== productId) {
    return;
  }
  const product = lookupProductByIdReducer(state, productId);
  if (!product) {
    return;
  }
  product.palette = record.palette;
  product.sheets = record.sheets;
  product.activeSheetId = record.activeSheetId;
}
