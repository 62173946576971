import styled from 'styled-components/macro';

interface IMenuButtonSidePanel {
  isActive?: boolean;
  isBeforeActive?: boolean;
  isAfterActive?: boolean;
}

export const MenuButtonSidePanel = styled.button.attrs<IMenuButtonSidePanel>((props) => ({
  style: {
    borderBottomLeftRadius: props.isBeforeActive ? '1rem' : undefined,
    borderTopLeftRadius: props.isAfterActive ? '1rem' : undefined,
    backgroundColor: props.isActive ? 'white' : 'var(--color-background-accent)',
    color: props.isActive ? 'var(--color-background-accent)' : 'white',
    borderBottom: props.isActive ? 'none' : '1px solid rgba(255, 255, 255, 0.3)',
  },
}))<IMenuButtonSidePanel>`
  display: grid;
  justify-items: center;
  align-content: center;
  gap: 0.75rem;
  border: none;
  padding-top: 10px;
  width: 70px;
  height: 70px;

  font-size: 0.7rem;
  line-height: 1.5;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    color: black !important;
    background-color: white !important;
  }
`;
