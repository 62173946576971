import React from 'react';
import styles from './TextInput.module.css';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import { Label } from '../label/Label';
import classNames from 'classnames';
import { IconButton } from '../../buttons/icon/IconButton';
import { useTranslation } from 'react-i18next';

export interface ITextInput {
  label: string;
  labelHidden?: boolean;
  password?: boolean;
  hasError?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => string;
}

const TextInput = ({
  label,
  labelHidden,
  password,
  hasError,
  name,
  value,
  onChange,
  disabled = false,
  onBlur,
  onFocus,
  onPaste,
  ...rest
}: ITextInput) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Label className={styles.label}>
      {labelHidden ? <VisuallyHidden>{label}</VisuallyHidden> : label}
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={classNames(styles.input, {
          [styles.error]: hasError,
        })}
        onPaste={onPaste}
        {...rest}
        type={password && !showPassword ? 'password' : 'text'}
      />
      {password && (
        <IconButton
          labelHidden
          label={t('ShowPassword')}
          iconName={'ansehen-control'}
          onClick={() => setShowPassword(!showPassword)}
          className={styles.eye}
        />
      )}
    </Label>
  );
};

export default TextInput;
