import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ClipboardDataType, IClipboardState } from './clipboardSlice';

export interface IAddToClipboardActionPayload {
  dataType: ClipboardDataType; // !Void
  data: unknown;
}

export function addToClipboardReducer(
  state: Draft<IClipboardState>,
  action: PayloadAction<IAddToClipboardActionPayload>,
) {
  state.data = action.payload.data;
  state.dataType = action.payload.dataType;
  state.pasteCount = 0;
}
