import { PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';
import { DesignerMode } from '../designerMode';

export function setDesignerModeReducer(
  state: IDesignerViewState,
  action: PayloadAction<DesignerMode>,
) {
  state.mode = action.payload;
}
