import * as Sentry from '@sentry/react';
import { FC, useCallback } from 'react';
import { useShowCopyDesignModal } from '../view/viewSelectors';
import { CopyDesignModal } from './CopyDesignModal';
import { copyDesign } from './copyDesign';
import { useAppDispatch } from '../../redux/hooks';
import { toast } from 'react-hot-toast';
import { showCopyDesignModal } from '../view/viewSlice';
import { useTranslation } from 'react-i18next';

/**
 * Renders and controls the copy design modal based on the view state value of `showCopyDesignModal`
 *
 * @see IDesignerViewState
 */
export const CopyDesignController: FC = () => {
  const dispatch = useAppDispatch();
  const isCopyDesignModalShown = useShowCopyDesignModal();
  const { t } = useTranslation();

  const hideModal = useCallback(() => {
    dispatch(showCopyDesignModal(false));
  }, [dispatch]);

  const onRequestImport = useCallback(
    (srcProductId: string): Promise<void> => {
      const importTask = dispatch(copyDesign(srcProductId));
      return toast
        .promise(importTask, {
          error: t('CopyDesignError'),
          loading: t('CopyDesignLoading'),
          success: t('CopyDesignSuccess'),
        })
        .then(() => {
          hideModal();
        })
        .catch((e) => {
          Sentry.captureEvent({
            message: 'Design could not be imported',
            extra: {
              error: e,
              SrcProductId: srcProductId,
            },
            level: 'error',
          });
        });
    },
    [dispatch, hideModal, t],
  );

  if (!isCopyDesignModalShown) {
    return null;
  }
  return <CopyDesignModal onClickImport={onRequestImport} onClickCancel={hideModal} />;
};
