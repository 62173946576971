import { IRGBA } from '../../lib/Color';
import { hexStringToRgba } from '../../lib/hexToRgba';

export interface ISaveTreditionPalette {
  /**
   * If not null, will cause a palette having this id to be overwritten
   */
  id: string | null;

  name: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
}

export interface ITreditionPalette {
  id: string;
  name: string;
  userId: string | null;
  color1: string | null;
  color2: string | null;
  color3: string | null;
  color4: string | null;
  color5: string | null;
  color6: string | null;
  //searchColor: string;
  order: number;
  //hashCode: string;
}

/**
 * Helper function to return a specific color of a legacy tredition palette as a hex string.
 *
 * Returns null if the color is not set.
 */
export function getHexColorAt(palette: ITreditionPalette, index: number): string | null {
  switch (index) {
    case 0:
      return palette.color1 || null;
    case 1:
      return palette.color2 || null;
    case 2:
      return palette.color3 || null;
    case 3:
      return palette.color4 || null;
    case 4:
      return palette.color5 || null;
    case 5:
      return palette.color6 || null;
    default:
      return null;
  }
}

/**
 * Helper function to return a specific color of a legacy tredition palette as RGBA.
 *
 * Returns null if the color does not exist or could not be parsed.
 */
export function getRgbaColorAt(palette: ITreditionPalette, index: number): IRGBA | null {
  const hex = getHexColorAt(palette, index);
  if (!hex) {
    return null;
  }
  return hexStringToRgba(hex);
}
