import {
  IImageLayer,
  IImageLayerReplacement,
  ImageLayerReplacementTarget,
} from '../designer/layer/LayersState';
import { useMemo } from 'react';
import { rgbToHex } from '../lib/rgbToHex';
import { useTreditionApiUrl } from '../config/configSelectors';

const coverUserImagesBasePath = 'designImages/render';

export const getImageBlobUrlWithoutReplacements = (apiUrl: string, layer: IImageLayer) => {
  return `${apiUrl}${coverUserImagesBasePath}/${window.btoa(
    layer.fileId,
  )}/Kg==/Kg==/${!layer.keepAspectRatio}/1`;
};

export const useImageBlobUrl = (layer: IImageLayer) => {
  const apiUrl = useTreditionApiUrl();

  return useMemo(() => {
    const fillReplacements = getImageReplacePart(
      ImageLayerReplacementTarget.FillColor,
      layer.replacements,
    );
    const strokeReplacements = getImageReplacePart(
      ImageLayerReplacementTarget.StrokeColor,
      layer.replacements,
    );
    return `${apiUrl}${coverUserImagesBasePath}/${window.btoa(layer.fileId)}/${window.btoa(
      fillReplacements,
    )}/${window.btoa(strokeReplacements)}/true/${layer.strokeWidth}`; // not sure why this is not affected by aspect ratio settings
  }, [apiUrl, layer.replacements, layer.fileId, layer.strokeWidth]);
};

function getImageReplacePart(
  target: ImageLayerReplacementTarget,
  replacements: IImageLayerReplacement[],
) {
  return (
    replacements
      .filter(($) => $.target === target)
      .map(($) => `${$.colorOld};#${rgbToHex($.colorNew.rgba)}`)
      .join('|') || '*'
  );
}
