import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import styles from './ProjectDataPage.module.css';
import { initialProjectDataValues, ProjectDataForm } from './ProjectDataForm';
import { createProjectDataFormSchema, IProjectDataFormValues } from './ProjectDataForm.schema';
import { useFormikStep } from '../useFormikStep';
import { useGetProjectDataQuery, useSetProjectDataMutation } from '../../api/treditionApi';
import { IEditorStepPageProps } from '../getStepComponent';
import { fromServerProjectData, toServerProjectData } from './ProjectDataForm.transform';
import { LazyLoader } from '../../loader/LazyLoader';
import { AxiosError, AxiosResponse } from 'axios';

export const ProjectDataPage: FC<IEditorStepPageProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const projectDataQuery = useGetProjectDataQuery(projectId);
  const { data: projectData } = projectDataQuery;
  const [setProjectData] = useSetProjectDataMutation();
  const validationSchema = useMemo(() => createProjectDataFormSchema(), [t]);
  const [hasDuplicateSeries, setDuplicateSeries] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (values: IProjectDataFormValues): Promise<boolean> => {
      setDuplicateSeries(false);
      await setProjectData({ projectId, data: toServerProjectData(values) })
        .unwrap()
        .catch((response: AxiosResponse) => {
          if (response?.status === 409) {
            setDuplicateSeries(true);
            return false;
          }
          return Promise.reject(response);
        });
      return true;
    },
    [setProjectData, projectId, t],
  );

  const initialValues = useMemo(() => {
    if (!projectData) {
      return initialProjectDataValues;
    }
    return fromServerProjectData(projectData);
  }, [projectData]);

  const { formik } = useFormikStep({
    formikConfig: {
      initialValues,
      validationSchema,
      enableReinitialize: true,
    },
    onSubmit,
  });

  return (
    <MainBookWizardLayout headline={t('ProjectDataTitle')}>
      <div className={styles.wrapper}>
        <h4>{t('Book')}</h4>
        {projectDataQuery.isLoading && <LazyLoader />}
        <ProjectDataForm
          formik={formik}
          hasDuplicateSeries={hasDuplicateSeries}
          projectId={projectId}
        />
      </div>
    </MainBookWizardLayout>
  );
};
