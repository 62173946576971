import { IProjectDataFormValues } from './ProjectDataForm.schema';

export interface IApiProjectDataSeriesOptions {
  /**
   * Id of series having the largest number
   */
  id: string;

  /**
   * All numbers existing in the series referenced by id
   */
  numbers: number[];

  /**
   * The number of the entry referenced by id
   */
  number: string;

  /**
   * The title of the series referenced by id
   */
  title: string;
}

export interface IApiProjectDataSeries {
  id: string | null;
  title: string;
  number: string;
  subtitle: string;
  editorId: string | null;
}

export interface IApiProjectData {
  title: string;
  subtitle: string;
  languages: string[];
  hasAiGeneratedContent: boolean | null;
  edition: IApiProjectDataEdition | null;
  series: IApiProjectDataSeries | null;
}

export interface IApiProjectDataEdition {
  number: string;

  /**
   * Date string
   */
  date: string;
}

export function fromServerProjectData(data: IApiProjectData): IProjectDataFormValues {
  return {
    title: data.title || '',
    subtitle: data.subtitle || '',
    languages: data.languages,
    hasEdition: !!data.edition,
    hasSeries: !!data.series,
    hasAiGeneratedContent: data.hasAiGeneratedContent,
    existingSeriesId: data.series?.id || '',
    edition: data.edition
      ? {
          number: Number.parseInt(data.edition.number) || 1,
          date: new Date(Number.parseInt(data.edition.date), 1, 1),
        }
      : null,
    series: data.series
      ? {
          id: data.series.id,
          number: Number.parseInt(data.series.number) || 1,
          title: data.series.title || '',
          subtitle: data.series.subtitle || '',
          editorId: data.series.editorId,
          hasEditor: !!data.series.editorId,
        }
      : null,
  };
}

export function toServerProjectData(values: IProjectDataFormValues): IApiProjectData {
  return {
    title: values.title,
    subtitle: values.subtitle || '',
    languages: values.languages,
    hasAiGeneratedContent: values.hasAiGeneratedContent,
    edition:
      values.edition && values.hasEdition
        ? {
            number: values.edition.number.toString(),
            date: values.edition.date.getFullYear().toString(),
          }
        : null,
    series: seriesToServer(values),
  };
}

function seriesToServer(values: IProjectDataFormValues): IApiProjectDataSeries | null {
  if (!values.series || !values.hasSeries) {
    return null;
  }
  return {
    id: values.series.id,
    number: values.series.number.toString(),
    title: values.series.title,
    subtitle: values.series.subtitle,
    editorId: values.series.editorId,
  };
}
