import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IManipulationMenuState } from './ManipulationMenuState';
import { ManipulationMenuEnumeration } from '../model/ManipulationMenuEnumeration';

export interface ISetContentActionPayload {
  /**
   * Active menu item as enum
   */
  activeMenu: ManipulationMenuEnumeration;
}

/**
 * Sets the active manipulation menu
 */
export const setActiveManipulationMenuReducer = (
  state: Draft<IManipulationMenuState>,
  action: PayloadAction<ISetContentActionPayload>,
) => {
  state.activeMenu = action.payload.activeMenu;
};
