import { useJwt } from '../auth/authSelectors';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';

export const AuthenticatedRoutes: FC = () => {
  const navigate = useNavigate();
  const jwt = useJwt();

  useEffect(() => {
    if (!jwt) {
      navigate('/login');
    }
  }, [jwt, navigate]);

  if (!jwt) {
    return null;
  }
  return <Outlet />;
};
