import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IImageLayer, LayerType } from './LayersState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetImageOptionsActionPayload {
  /**
   * An optional, new image size
   */
  imageSize?: number;

  /**
   * An optional, new image rotation
   */
  imageRotation?: number;

  /**
   * Whether the image scaling should be proportional
   */
  keepAspectRatio?: boolean;

  /**
   * An optional, new tiling mode
   */
  repeat?: boolean;

  /**
   * An optional stroke width override. Supported only by SVGs which have at least one stroke color.
   */
  strokeWidth?: number;

  /**
   * Layers which should have their options overwritten
   *
   * Note that layer types other than `Image` will be filtered out in all cases.
   */
  filter: ILayerFilter;
}

/**
 * Changes options of all image layers satisfying the given filter
 */
export function setImageOptionsReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetImageOptionsActionPayload>,
) {
  const { imageRotation, imageSize, keepAspectRatio, repeat, strokeWidth, filter } = action.payload;
  filterLayers(state.layers, filter).forEach((layer) => {
    if (layer.type !== LayerType.Image) {
      return;
    }
    const l = layer as IImageLayer;
    let wasUpdated = false;
    if (typeof imageRotation === 'number' && !Number.isNaN(imageRotation)) {
      l.imageRotation = imageRotation % 360;
      wasUpdated = true;
    }
    if (typeof imageSize === 'number' && !Number.isNaN(imageSize)) {
      l.imageSize = imageSize;
      wasUpdated = true;
    }
    if (typeof repeat === 'boolean') {
      l.repeat = repeat;
      wasUpdated = true;
    }
    if (typeof keepAspectRatio === 'boolean') {
      l.keepAspectRatio = keepAspectRatio;
      wasUpdated = true;
    }
    if (typeof strokeWidth === 'number') {
      l.strokeWidth = strokeWidth;
      wasUpdated = true;
    }
    if (wasUpdated) {
      layer.updatedAt = Date.now();
    }
  });
}
