import { AxiosResponse } from 'axios';

export function getResponseErrorReason(
  response: AxiosResponse | null | undefined,
  on422?: () => string,
): string {
  switch (response?.status) {
    case 422:
      if (on422) {
        return on422();
      }
      return 'ErrorInvalidData'; // TODO
    case 404:
      return 'ErrorResourceNotFound'; // TODO
    case 401:
    case 403:
      return 'ErrorUnauthorized'; // TODO
    default:
      if (!response) {
        return 'ErrorConnection'; // TODO
      }
      if (response.status >= 500) {
        return 'ErrorServer'; // TODO
      }
      return 'ErrorUnknown'; // TODO
  }
}
