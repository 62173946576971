import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { IImageLayer, IImageLayerReplacement, LayerType } from './LayersState';

export interface IReplaceColorActionPayload {
  /**
   * A filter which will determine which layers should have their color replaced.
   *
   * Note that layers which do not support image replacements will be skipped in all cases.
   */
  filter: ILayerFilter;

  /**
   * The replacement to perform. If the replacement does not exist, it will be created instead.
   */
  replacement: IImageLayerReplacement;
}

export function replaceColorReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IReplaceColorActionPayload>,
) {
  filterLayers(state.layers, action.payload.filter).forEach((layer) => {
    if (layer.type !== LayerType.Image) {
      return;
    }
    const l = layer as IImageLayer;
    if (!l.fileId || !l.fileId.toUpperCase().endsWith('.SVG')) {
      // TODO: please add something like 'supportsColorReplacement' on layer info
      return;
    }
    const { replacement } = action.payload;
    const existingReplacement = l.replacements.find(
      ($) => $.target === replacement.target && $.colorOld === replacement.colorOld,
    );
    if (existingReplacement) {
      existingReplacement.colorNew = replacement.colorNew;
    } else {
      l.replacements.push(replacement);
    }
  });
}
