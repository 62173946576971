import { ProductType } from '../format/constants/ProductType';
import validator from '../../validator';
import { normalizeIsbn } from './utils/IsbnUtils';
import { IIsbnProduct } from './interfaces/IIsbnPageData';
import { ensureArray } from '../../lib/emptyArray';

export interface IIsbnFormData {
  customIsbn: {
    [ProductType.SoftCover]: string;
    [ProductType.HardCover]: string;
    [ProductType.Ebook]: string;
    [ProductType.LargePrint]: string;
  };
  needIsbn: boolean | null;
  hasImprintLabel: boolean | null;
  imprintProfileId: string | null;
  publisherProfileId: string | null;
}

const createIsbnFormValidationSchema = (products: IIsbnProduct[]) => {
  const shape = products.reduce((acc, product) => {
    return {
      ...acc,
      [product.bookType]: validator.string().isbnCharacters().isbnLength().isbnValid().required(),
    };
  }, {} as { [key in ProductType]: validator.StringSchema });

  return validator.object().shape(shape);
};

export const createIsbnSchema = (products?: IIsbnProduct[]) =>
  validator
    .object()
    .shape({
      needIsbn: validator.boolean().required(),
      hasImprintLabel: validator.boolean().required(),
      customIsbn: validator.object().when('needIsbn', {
        is: false,
        then: () => createIsbnFormValidationSchema(ensureArray(products)).required(),
        otherwise: () => createIsbnFormValidationSchema(ensureArray(products)).strip(),
      }),
      imprintProfileId: validator.string().when('hasImprintLabel', {
        is: true,
        then: () => validator.string().required(),
        otherwise: () => validator.string().notRequired(),
      }),
      publisherProfileId: validator.string().when('needIsbn', {
        is: false,
        then: () => validator.string().required(),
        otherwise: () => validator.string().nullable(),
      }),
    })
    .test('DuplicateISBN', (value) => {
      if (!value) {
        return true;
      }
      if (value.needIsbn === null || value.needIsbn) {
        return true;
      }
      const customIsbnValues = Object.values(value.customIsbn)
        .filter((v) => !!v)
        .map((v) => normalizeIsbn(v!.trim()));

      return (
        new Set(customIsbnValues).size === Object.values(value.customIsbn).filter((v) => !!v).length
      );
    });
