import { FC, Fragment } from 'react';
import { useSelectedLayers } from '../../layer/liveSheetSelectors';
import { MultiScaler } from './MultiScaler';
import { SingleScaler } from './SingleScaler';
import { SingleMover } from './SingleMover';
import { MultiMover } from './MultiMover';
import styled from 'styled-components/macro';
import { AlertBox } from '../../../uiComponents/AlertBox';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../icons/Icon';
import { SidePanelContentWrapper } from '../SidePanelContentWrapper';

export const MeasuresPanel: FC = () => {
  const { t } = useTranslation();
  const layers = useSelectedLayers();

  return (
    <SidePanelContentWrapper>
      <h2>{t('Dimensions')}</h2>
      {layers.length > 0 ? (
        <Fragment>
          <ContentWrapper>
            <span>{t('Size')}</span>
            {layers.length > 1 ? (
              <MultiScaler selectedLayers={layers} />
            ) : (
              <SingleScaler layer={layers[0]} />
            )}
          </ContentWrapper>
          <ContentWrapper>
            <span>{t('Position')}</span>
            {layers.length > 1 ? <MultiMover /> : <SingleMover layer={layers[0]} />}
          </ContentWrapper>
        </Fragment>
      ) : (
        <AlertBox type={'warning'}>
          <Icon name="info-control" />️ {t('NoLayerSelected')}
        </AlertBox>
      )}
    </SidePanelContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: grid;

  & > span {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
`;
