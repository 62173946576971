import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ISetProjectActionPayload } from '../project/projectActions';
import { ILiveSheetState } from './ILiveSheetState';
import { createInitialLiveSheetState } from './liveSheetSlice';

/**
 * Sets the layers based on the newly assigned project
 */
export function applySetProjectToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetProjectActionPayload>,
): ILiveSheetState {
  const { payload: project } = action;
  const nextState: ILiveSheetState = createInitialLiveSheetState();
  if (!project) {
    return nextState; // reset all layers on unload
  }
  if (!project.activeProduct) {
    return nextState; // no active product was selected so no layers should be displayed
  }
  const activeProduct = project.products.find(($) => $.id === project.activeProduct);
  if (!activeProduct) {
    return nextState;
  }
  const activeSheets = activeProduct.sheets.find(($) => $.id === activeProduct.activeSheetId);
  if (!activeSheets) {
    return nextState;
  }
  nextState.layers = activeSheets.layers;
  return nextState;
}
