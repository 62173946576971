import { AppStore } from '../redux/store';
import { getConfigurationByEnv } from './getConfigurationByEnv';
import { configure } from './configSlice';
import { IConfig } from './IConfig';

export function setupConfig(store: AppStore, config?: IConfig): IConfig {
  if (process.env.NODE_ENV === 'development' && !config) {
    config = getConfigurationByEnv();
    store.dispatch(configure(config));
    return config;
  }
  if (!config) {
    throw new Error('No configuration present');
  }
  store.dispatch(configure(config));
  return config;
}
