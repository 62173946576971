import { DetailedHTMLProps, FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components/macro';

export interface ITextInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /**
   * An optional addendum to display adjacent to the input
   */
  addendum?: ReactNode | string;
}

export const Input = forwardRef<HTMLInputElement, ITextInputProps>((props, ref) => {
  const { addendum, ...nativeProps } = props;

  return (
    <InputWrapper>
      <input {...nativeProps} ref={ref} />
      {!!addendum && <span className="addendum">{addendum}</span>}
    </InputWrapper>
  );
});

Input.displayName = 'Input';

const InputWrapper = styled.div`
  display: flex;

  > .addendum {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    background-color: var(--color-background-unit);
    border-radius: 0 3px 3px 0;
    border: 1px solid var(--color-border);
    border-left: none;
  }
`;
