import { AppThunk } from '../../redux/store';
import { getClipboard } from './clipboardSelectors';
import { ClipboardDataType } from './clipboardSlice';
import { ILayer } from '../layer/LayersState';
import { pasteLayers } from '../layer/liveSheetSlice';
import { v4 as uuid } from 'uuid';

export const pasteLayersFromClipboard = (): AppThunk => (dispatch, getState) => {
  const clipboardState = getClipboard(getState());
  if (
    clipboardState.dataType !== ClipboardDataType.Layer ||
    !Array.isArray(clipboardState.data) ||
    !(clipboardState.data as ILayer[]).length
  ) {
    return;
  }
  const layers = (clipboardState.data as ILayer[]).map((layer) => {
    const x = layer.x + 32 * (clipboardState.pasteCount + 1);
    const y = layer.y + 32 * (clipboardState.pasteCount + 1);
    return {
      ...layer,
      id: uuid(),
      x,
      y,
      display: {
        ...layer.display,
        x,
        y,
      },
    };
  });
  dispatch(pasteLayers(layers));
};

/*
Does not work in firefox which has a very restrictive clipboard api. Need to find a workaround
export const pasteLayersFromClipboard = (): AppThunk => async (dispatch, getState) => {
  try {
    if (!navigator || !navigator.clipboard) {
      return;
    }
    const rawData: string = await navigator.clipboard.readText();
    if (!rawData || !rawData.length) {
      return;
    }
    const layers = extractLayersFromClipboardText(rawData)
      .map((layer) => ({
        ...layer,
        id: `${layer.id}-copy`,
        x: layer.x + 64,
        y: layer.y + 64,
      }));

    if (layers.length) {
      dispatch(addLayers(layers));
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.debug('Error pasting layers from clipboard', error);
    }
  }
}

// TODO: This needs versioning / validation or we will be in trouble (commit hash strict comparison)
function extractLayersFromClipboardText(clipboardText: string): ILayer[] {
  // if further data types are added, please refactor the following meta part to a new function
  let dangerousData = decodeDesignerClipboardData(clipboardText) as IDesignerClipboardPayload<ILayer[]>;
  if (!dangerousData || typeof dangerousData !== 'object') {
    return [];
  }
  const dataTypeKey: keyof typeof dangerousData = 'dataType';
  if (!Object.prototype.hasOwnProperty.call(dangerousData, dataTypeKey) || Number.isNaN(dangerousData.dataType)) {
    return [];
  }
  // meta end
  if (dangerousData.dataType !== DesignerClipboardDataType.Layers) {
    return [];
  }
  const dataKey: keyof typeof dangerousData = 'data';
  if (!Object.prototype.hasOwnProperty.call(dangerousData, dataKey) || !Array.isArray(dangerousData.data)) {
    return [];
  }
  return (dangerousData.data as ILayer[]).filter($ => $.type); // future validation here
}

export interface IDesignerClipboardPayload<T> {
  dataType: DesignerClipboardDataType;
  data: T | null;
}

export enum DesignerClipboardDataType {
  Void,
  Layers,
}


export function encodeDesignerClipboardData<T>(data: T): string {
  return btoa(JSON.stringify(data));
}

export function decodeDesignerClipboardData(data: string): unknown {
  try {
    return JSON.parse(atob(data));
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.debug('Parsing clipboard data failed', error);
    }
    return null;
  }
}

export const addSelectionToClipboard = (): AppThunk => async (dispatch, getState) => {
  const data = getSelectedLayers(getState());
  if (!data.length) {
    return;
  }
  try {
    if (!navigator || !navigator.clipboard) {
      return;
    }
    await navigator.clipboard.writeText(encodeDesignerClipboardData(data));
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.debug('Error copying selected layers to clipboard', error);
    }
  }
}
*/
