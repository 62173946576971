import { filterLayers, ILayerFilter } from './layerFilter';
import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { deselectLayersReducer } from './deselectLayersReducer';

export interface ISelectLayersActionPayload {
  filter: ILayerFilter;

  mode: LayerSelectionMode;
}

export enum LayerSelectionMode {
  /**
   * The matching layers will be added to the current selection of layers
   */
  Add,

  /**
   * The matching layers will become the new selection
   */
  Set,

  /**
   * The matching layers will be removed from an en existing selection if intersecting
   */
  Remove,
}

export function selectLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISelectLayersActionPayload>,
) {
  if (action.payload.mode === LayerSelectionMode.Set) {
    deselectLayersReducer(state);
  }
  const isSelected = action.payload.mode !== LayerSelectionMode.Remove;
  filterLayers(state.layers, action.payload.filter).forEach((layer) => {
    layer.isSelected = isSelected;
  });
}
