export const SuggestedColorsHex = [
  '#00000000',
  '#000000FF',
  '#222222FF',
  '#444444FF',
  '#666666FF',
  '#999999FF',
  '#AAAAAAFF',
  '#CCCCCCFF',
  '#FFFFFFFF',
  '#E71717FF',
  '#FF0013FF',
  '#FF002EFF',
  '#FF0046FF',
  '#FF005FFF',
  '#FF0076FF',
  '#FF008EFF',
  '#FF00A7FF',
  '#FF00BFFF',
  '#FF00D8FF',
  '#FF00F0FF',
  '#F800FFFF',
  '#E000FFFF',
  '#C700FFFF',
  '#AF00FFFF',
  '#9800FFFF',
  '#7F00FFFF',
  '#6700FFFF',
  '#4E00FFFF',
  '#3700FFFF',
  '#1F00FFFF',
  '#0600FFFF',
  '#0011FFFF',
  '#0029FFFF',
  '#0041FFFF',
  '#0059FFFF',
  '#0071FFFF',
  '#0089FFFF',
  '#00A1FFFF',
  '#00B8FFFF',
  '#00D2FFFF',
  '#00EAFFFF',
  '#00FEFCFF',
  '#00FFE5FF',
  '#00FFCDFF',
  '#00FFB5FF',
  '#00FF9CFF',
  '#00FF85FF',
  '#00FF6DFF',
  '#00FF55FF',
  '#00FF3CFF',
  '#00FF24FF',
  '#00FF0BFF',
  '#0BFF00FF',
  '#23FF00FF',
  '#3CFF00FF',
  '#54FF00FF',
  '#6BFF00FF',
  '#84FF00FF',
  '#9BFF00FF',
  '#B5FF00FF',
  '#CBFF00FF',
  '#E4FF00FF',
  '#FCFE00FF',
];
