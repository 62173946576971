import { FC } from 'react';

export interface IImageFallbackProps {
  /**
   * Width in pixels
   */
  width: number;

  /**
   * Height in pixels
   */
  height: number;

  /**
   * SVG compatible stroke color, for example 'red' or '#FF0000'
   *
   * Default: 'red'
   */
  strokeColor?: string;

  /**
   * Stroke width in pixels.
   *
   * Default: 2
   */
  strokeWidth?: number;
}

/**
 * Renders a fallback image SVG which is by default a transparent area containing a red cross
 * spanning the area's diagonals. This component renders as an inline SVG and does not require
 * an internet connection therefore it is also suited to display images which failed to load due to
 * connection errors.
 */
export const ImageFallback: FC<IImageFallbackProps> = ({
  width,
  height,
  strokeColor,
  strokeWidth,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      stroke={strokeColor || 'red'}
      strokeWidth={Number.isFinite(strokeWidth) ? `${strokeWidth}px` : `2px`}>
      <path d={`M 0,0 ${width},${height}`} />
      <path d={`M 0,${height} ${width},0`} />
    </svg>
  );
};
