import * as yup from 'yup';

export const createPublisherSchema = () =>
  yup.object().shape({
    name: yup.string().required(),
    organisation: yup.string().required(),
    registerNo: yup.string().required(),
    street: yup.string().required(),
    streetNumber: yup.string().required(),
    zip: yup.string().required(),
    city: yup.string().required(),
    countryCode: yup.string().required(),
  });

export interface IPublisherSchema {
  name: string;
  organisation: string;
  registerNo: string;
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  countryCode: string;
}
