import { IRulers } from './ILiveSheetState';
import { IPoint } from '../../../@types/IPoint';
import { ISize } from '../../../@types/ISize';
import { rotatePointDegFast } from '../../lib/rotatePointDeg';
import { degToRad } from '../../lib/degToRad';

/**
 * Returns the new position of a layer after it would have been snapped. The original position
 * will not be mutated.
 */
export function getSnappedLayerPosition(
  position: IPoint,
  size: ISize,
  rotation: number,
  snap: IRulers,
): IPoint {
  const strength = 5; // distance in pixels up until where snap will trigger
  const end: IPoint = {
    x: position.x + size.width,
    y: position.y + size.height,
  };
  const center: IPoint = {
    x: position.x + size.width / 2,
    y: position.y + size.height / 2,
  };
  // seems extremely slow - if this becomes a perf problem the rotated bounding box anchor's position
  // must be cached in the layer display data or the magnets should be persisted in `startTranslationTransform`
  const rad = degToRad(-rotation);
  const sin = Math.sin(rad);
  const cos = Math.cos(rad);
  const magnets: IPoint[] = [
    // top left
    rotatePointDegFast(position, center, sin, cos),
    // top
    rotatePointDegFast({ x: center.x, y: position.y }, center, sin, cos),
    // top right
    rotatePointDegFast({ x: end.x, y: position.y }, center, sin, cos),
    // left
    rotatePointDegFast({ x: position.x, y: center.y }, center, sin, cos),
    // center
    center,
    // right
    rotatePointDegFast({ x: end.x, y: center.y }, center, sin, cos),
    // bottom-left
    rotatePointDegFast({ x: position.x, y: end.y }, center, sin, cos),
    // bottom
    rotatePointDegFast({ x: center.x, y: end.y }, center, sin, cos),
    // bottom-right
    rotatePointDegFast(end, center, sin, cos),
  ];

  const snappedPosition: IPoint = {
    x: position.x,
    y: position.y,
  };

  snapXIteration: for (const magnet of magnets) {
    for (const v of snap.v) {
      if (Math.abs(magnet.x - v.start.x) <= strength) {
        snappedPosition.x = v.start.x - (magnet.x - position.x);
        break snapXIteration;
      }
    }
  }

  for (const magnet of magnets) {
    for (const h of snap.h) {
      if (Math.abs(magnet.y - h.start.y) <= strength) {
        snappedPosition.y = h.start.y - (magnet.y - position.y);
        return snappedPosition;
      }
    }
  }

  return snappedPosition;
}
