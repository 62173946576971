import axios from 'axios';
import { ICSSObject } from '../components/form_constant/CSSObject';

export interface IGetToc {
  bookContent: string;
  formValues: ICSSObject;
}

export const getToc = async (data: IGetToc) => {
  const baseUrl = process.env.REACT_APP_CONTENT_DESIGNER;
  const query = 'style/toc';

  const response = await axios.post(baseUrl + query, data);
  return response.data;
};
