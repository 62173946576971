import { createSlice } from '@reduxjs/toolkit';
import { createOverwriteStateReducer } from '../../lib/createOverwriteStateReducer';
import { TextTemplateType } from '../layer/LayersState';

export enum DragContextType {
  DesignerText,
  DesignerImage,
  DesignerPlain,
}

export interface IDragContext {
  type: DragContextType;
}

export interface IDesignerPlainDragContext extends IDragContext {
  type: DragContextType.DesignerPlain;
}

export interface IDesignerTextDragContext extends IDragContext {
  type: DragContextType.DesignerText;

  /**
   * The template type of the text layer which should be created
   */
  templateType: TextTemplateType;
}

export interface IDesignerImageDragContext extends IDragContext {
  type: DragContextType.DesignerImage;

  /**
   * In case of source Pattern & Upload this must be the fileId, otherwise a full url to the pixabay image
   */
  fileId: string;

  source: DesignerImageSource;
}

export enum DesignerImageSource {
  Pattern = 1,
  Pixabay = 2,
  Upload = 0,
}

export type IDragContextUnion =
  | IDesignerTextDragContext
  | IDesignerImageDragContext
  | IDesignerPlainDragContext;

/**
 * Contains complex data which cannot be transmitted using the d&d api
 */
export type IDragState = IDragContextUnion | null;

export const createInitialDragState = (): IDragState => null;

export const dragSlice = createSlice({
  name: 'drag',
  initialState: Object.seal(createInitialDragState()),
  reducers: {
    dragStart: createOverwriteStateReducer<IDragState>(),
    dragEnd: () => null,
  },
});

export const { dragStart, dragEnd } = dragSlice.actions;
