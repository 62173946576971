import { IAuthState } from './IAuthState';
import { PayloadAction } from '@reduxjs/toolkit';
import { IConfig } from '../config/IConfig';
import { setJwtReducer } from './setJwtReducer';
import { createInitialAuthState, setJwt } from './authSlice';

/**
 * Reacts to {@link configure} and applies the configured JWT to the auth state.
 *
 * Note that if a JWT is present and the next configuration does not contain a jwt
 *      the state will be overwritten, which renders the state as `logged out`.
 */
export function applyConfigureToAuthReducer(state: IAuthState, action: PayloadAction<IConfig>) {
  if (action.payload.jwt) {
    setJwtReducer(state, setJwt(action.payload.jwt));
  } else {
    return createInitialAuthState();
  }
}
