import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';
import { IPoint } from '../../../@types/IPoint';

export function repositionBarcodeReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IPoint>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product || !product.barcode) {
    return;
  }
  product.barcode.x = action.payload.x;
  product.barcode.y = action.payload.y;
}
