import React from 'react';
import styles from './AddContributorModal.module.css';
import { useTranslation } from 'react-i18next';
import { ActionModal } from '../../modal/ActionModal';
import CheckBox from '../../forms/checkBox/CheckBox';
import { IUserContributor } from './interfaces/IUserContributor';
import { ContributorHeader } from './ContributorHeader';

export interface IAddContributorModalProps {
  unselectedContributors: IUserContributor[];
  onCancel: () => void;
  onAddContributor: (contributorIds: string[]) => void;
}

export const AddContributorModal: React.FC<IAddContributorModalProps> = ({
  unselectedContributors,
  onCancel,
  onAddContributor,
}) => {
  const [selectedContributors, setSelectedContributors] = React.useState<string[]>([]);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    if (selectedContributors.includes(id)) {
      setSelectedContributors(selectedContributors.filter((contributor) => contributor !== id));
    } else {
      setSelectedContributors([...selectedContributors, id]);
    }
  };

  return (
    <ActionModal
      onOk={() => onAddContributor(selectedContributors)}
      onCancel={onCancel}
      shouldCloseOnOverlayClick={false}
      isOpen
      onRequestClose={onCancel}
      okButtonDisabled={false}
      title={t('SelectContributor')}>
      <ul className={styles.list}>
        {unselectedContributors.map((contributor) => (
          <li key={contributor.id}>
            <CheckBox
              label={
                <div className={styles.text}>
                  <ContributorHeader contributor={contributor} />
                  <span className={styles.role}>{t(`Role${contributor.role}`)}</span>
                </div>
              }
              value={contributor.id}
              onChange={handleChange}
            />
          </li>
        ))}
      </ul>
    </ActionModal>
  );
};
