import { createSlice } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { setActiveProductReducer } from './setActiveProductReducer';
import { setPaletteReducer } from './setPaletteReducer';
import { applyRestoreFromHistoryToProjectReducer } from './applyRestoreFromHistoryToProjectReducer';
import { restoreFromHistory } from '../history/historySlice';
import {
  activateSheetInternal,
  addSheet,
  applyTemplateInternal,
  clearProject,
  deleteSheetInternal,
  importProductInternal,
  openProductInternal,
  setPaletteInternal,
  setProject,
  setReadonly,
} from './projectActions';
import { openProductReducer } from './openProductReducer';
import { activateSheetReducer } from './activateSheetReducer';
import { deleteSheetReducer } from './deleteSheetReducer';
import { addSheetReducer } from './addSheetReducer';
import { swapSheetsReducer } from './swapSheetsReducer';
import { persistLiveSheetReducer } from './persistLiveSheetReducer';
import { createOverwriteStateReducer } from '../../lib/createOverwriteStateReducer';
import { setReadonlyReducer } from './setReadonlyReducer';
import { repositionBarcodeReducer } from './repositionBarcodeReducer';
import { clearProjectReducer } from './clearProjectReducer';
import { replaceProductReducer } from './replaceProductReducer';

export const projectSlice = createSlice({
  name: 'project',
  initialState: null as IProjectState,
  reducers: {
    persistLiveSheet: persistLiveSheetReducer,
    setActiveProduct: setActiveProductReducer,
    swapSheets: swapSheetsReducer,
    repositionBarcode: repositionBarcodeReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(activateSheetInternal, activateSheetReducer)
      .addCase(addSheet, addSheetReducer)
      .addCase(deleteSheetInternal, deleteSheetReducer)
      .addCase(importProductInternal, replaceProductReducer)
      .addCase(applyTemplateInternal, replaceProductReducer)
      .addCase(openProductInternal, openProductReducer)
      .addCase(restoreFromHistory, applyRestoreFromHistoryToProjectReducer)
      .addCase(setProject, createOverwriteStateReducer<IProjectState>())
      .addCase(setReadonly, setReadonlyReducer)
      .addCase(clearProject, clearProjectReducer)
      .addCase(setPaletteInternal, setPaletteReducer),
});

export const { swapSheets, persistLiveSheet, repositionBarcode } = projectSlice.actions;
