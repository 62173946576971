import { AppThunk } from '../../redux/store';
import { activateSheetInternal } from './projectActions';
import { getActiveProduct } from './productSelectors';
import { getAllLayers } from '../layer/liveSheetSelectors';
import { persistLiveSheet } from './projectSlice';
import { deselectLayers } from '../layer/liveSheetSlice';

export const activateSheet =
  (sheetId: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(deselectLayers());
    const state = getState();
    const product = getActiveProduct(state);
    if (!product) {
      return;
    }
    const sheet = product.sheets.find(($) => $.id === sheetId);
    if (!sheet) {
      return;
    }
    dispatch(persistLiveSheet({ layers: getAllLayers(state) })); // OPTIMIZE
    dispatch(activateSheetInternal({ sheetId, layers: sheet.layers }));
  };
