import { FC, useCallback } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { deleteSheet } from '../project/deleteSheet';
import { addSheet } from '../project/projectActions';
import { v4 } from 'uuid';
import { swapSheets } from '../project/projectSlice';
import { DesignerMode } from '../designerMode';

export interface IWorkSpaceSeparatorProps {
  designerMode: DesignerMode;
  pageIndex: number;
  pageCount: number;
}

export const WorkSpaceSeparator: FC<IWorkSpaceSeparatorProps> = ({
  designerMode,
  pageIndex,
  pageCount,
}) => {
  const dispatch = useAppDispatch();

  const onClickAddSheetHere = useCallback(() => {
    dispatch(
      addSheet({
        index: pageIndex + 1,
        sheet: {
          id: v4(),
          layers: [],
        },
      }),
    );
  }, [dispatch, pageIndex]);

  const onClickDeleteNextSheet = useCallback(() => {
    dispatch(deleteSheet(pageIndex + 1));
  }, [dispatch, pageIndex]);

  const onClickRaise = useCallback(() => {
    dispatch(swapSheets({ srcIndex: pageIndex, destIndex: pageIndex - 1 }));
  }, [dispatch, pageIndex]);

  const onClickLower = useCallback(() => {
    dispatch(swapSheets({ srcIndex: pageIndex, destIndex: pageIndex + 1 }));
  }, [dispatch, pageIndex]);

  if (designerMode !== DesignerMode.FrontMatter) {
    return null;
  }
  const isLast = pageIndex === pageCount - 1;

  return (
    <div className="work-space-separator">
      <button onClick={onClickRaise} disabled={pageIndex <= 1}>
        ⬆
      </button>
      <button onClick={onClickAddSheetHere}>➕ Doppelseite hier einfügen</button>
      {!isLast && (
        <button onClick={onClickDeleteNextSheet}>🗑️ Nachfolgende Doppelseite entfernen</button>
      )}
      <button onClick={onClickLower} disabled={pageIndex <= 0 || isLast}>
        ⬇
      </button>
    </div>
  );
};
