import { FormattingEnumeration } from './Formatting';

export enum HeaderFormEnumeration {
  HEADER = 'header',
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  VIEW = 'view',
  SEPARATOR = 'separator',
  DISTANCE_TEXT = 'distance-text',
  DISTANCE_HEADER = 'distance-header',
  HEADER_CONTENT = 'header-content',
  HEADER_CONTENT_INNER = 'header-content-inner',
  HEADER_CONTENT_OUTER = 'header-content-outer',
  FONT_FAMILY_INNER = 'font-family-inner',
  FONT_SIZE_INNER = 'font-size-inner',
  BOLD_INNER = 'bold-inner',
  ITALIC_INNER = 'italic-inner',
  UNDERLINED_INNER = 'underlined-inner',
  FONT_FAMILY_OUTER = 'font-family-outer',
  FONT_SIZE_OUTER = 'font-size-outer',
  BOLD_OUTER = 'bold-outer',
  ITALIC_OUTER = 'italic-outer',
  UNDERLINED_OUTER = 'underlined-outer',
  FREE_TEXT = 'free-text',
  FREE_TEXT_INNER = 'free-text-inner',
  FREE_TEXT_OUTER = 'free-text-outer',
}

export type IHeaderForm = {
  [HeaderFormEnumeration.HEADER]: boolean;
  [HeaderFormEnumeration.FONT_FAMILY]: string;
  [HeaderFormEnumeration.FONT_FAMILY_INNER]: string;
  [HeaderFormEnumeration.FONT_FAMILY_OUTER]: string;
  [HeaderFormEnumeration.FONT_SIZE]: number;
  [HeaderFormEnumeration.FONT_SIZE_INNER]: number;
  [HeaderFormEnumeration.FONT_SIZE_OUTER]: number;
  [HeaderFormEnumeration.BOLD]: boolean;
  [HeaderFormEnumeration.BOLD_INNER]: boolean;
  [HeaderFormEnumeration.BOLD_OUTER]: boolean;
  [HeaderFormEnumeration.ITALIC]: boolean;
  [HeaderFormEnumeration.ITALIC_INNER]: boolean;
  [HeaderFormEnumeration.ITALIC_OUTER]: boolean;
  [HeaderFormEnumeration.UNDERLINED]: boolean;
  [HeaderFormEnumeration.UNDERLINED_INNER]: boolean;
  [HeaderFormEnumeration.UNDERLINED_OUTER]: boolean;
  [HeaderFormEnumeration.HEADER_CONTENT]: string;
  [HeaderFormEnumeration.HEADER_CONTENT_INNER]: string;
  [HeaderFormEnumeration.HEADER_CONTENT_OUTER]: string;
  [HeaderFormEnumeration.VIEW]: string;
  [HeaderFormEnumeration.SEPARATOR]: boolean;
  [HeaderFormEnumeration.DISTANCE_TEXT]: number;
  [HeaderFormEnumeration.DISTANCE_HEADER]: number;
  [HeaderFormEnumeration.FREE_TEXT]: string;
  [HeaderFormEnumeration.FREE_TEXT_INNER]: string;
  [HeaderFormEnumeration.FREE_TEXT_OUTER]: string;
};

type IHeaderFormatting = {
  [key in FormattingEnumeration]: string;
};

export const headerFormatting: IHeaderFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: HeaderFormEnumeration.FONT_FAMILY,
  [FormattingEnumeration.FONT_SIZE]: HeaderFormEnumeration.FONT_SIZE,
  [FormattingEnumeration.BOLD]: HeaderFormEnumeration.BOLD,
  [FormattingEnumeration.ITALIC]: HeaderFormEnumeration.ITALIC,
  [FormattingEnumeration.UNDERLINED]: HeaderFormEnumeration.UNDERLINED,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};

export const headerFormattingInner: IHeaderFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: HeaderFormEnumeration.FONT_FAMILY_INNER,
  [FormattingEnumeration.FONT_SIZE]: HeaderFormEnumeration.FONT_SIZE_INNER,
  [FormattingEnumeration.BOLD]: HeaderFormEnumeration.BOLD_INNER,
  [FormattingEnumeration.ITALIC]: HeaderFormEnumeration.ITALIC_INNER,
  [FormattingEnumeration.UNDERLINED]: HeaderFormEnumeration.UNDERLINED_INNER,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};

export const headerFormattingOuter: IHeaderFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: HeaderFormEnumeration.FONT_FAMILY_OUTER,
  [FormattingEnumeration.FONT_SIZE]: HeaderFormEnumeration.FONT_SIZE_OUTER,
  [FormattingEnumeration.BOLD]: HeaderFormEnumeration.BOLD_OUTER,
  [FormattingEnumeration.ITALIC]: HeaderFormEnumeration.ITALIC_OUTER,
  [FormattingEnumeration.UNDERLINED]: HeaderFormEnumeration.UNDERLINED_OUTER,
  [FormattingEnumeration.TEXT_ALIGN]: 'unused',
};
