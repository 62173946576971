/**
 * Route key used to identify the step param in the editor route.
 */
export const StepRouteKey = 'step';

/**
 * Base path from root to the editor page which renders all steps
 */
export const BasePath = '/newbook';

/**
 * Route key used to identify the projectId param in the editor route
 */
export const ProjectIdRouteKey = 'projectId';

/**
 * Compiled route string for the editor page used by the router
 */
export const EditorPageRoute = getEditorRoute(`:${StepRouteKey}`, `:${ProjectIdRouteKey}`);

/**
 * Computes the editor page route for the given step and project id
 */
export function getEditorRoute(step: string, projectId: string) {
  return `${BasePath}/${step}/${projectId}`;
}
