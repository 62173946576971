import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { IDeleteSheetActionPayload } from '../project/projectActions';

export function applyDeleteSheetToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IDeleteSheetActionPayload>,
) {
  if (typeof action.payload.nextLayers !== 'undefined') {
    state.layers = action.payload.nextLayers;
  }
}
