import { ILayer } from '../layer/LayersState';
import { ITreditionPalette } from '../palette/TreditionPalette';

export interface IProduct {
  /**
   * Product id
   */
  id: string;

  /**
   * RTTI
   */
  type: ProductType;

  /**
   * A list of all sheets this project contains. For cover designs usually only one sheet exists, but the front
   * matter design may have multiple pages so each double page requires a separate sheet.
   */
  sheets: ISheet[];

  /**
   * The id of the currently active sheet
   */
  activeSheetId: string | null;

  /**
   * Measures for a project which will vary depending on the product type
   */
  measures: IProductMeasures;

  /**
   * Regions present in this product
   *
   * Note that the regions may appear in arbitrary order and are supposed to be positioned `absolute`ly.
   */
  regions: IRegion[];

  /**
   * The currently active palette
   */
  palette: ITreditionPalette | null;

  /**
   * Contains information about the barcode if existing
   */
  barcode: IProductBarcode | null;
}

export interface IProductBarcode {
  /**
   * X-coordinate of the barcode relative to the cover sheet
   */
  x: number;

  /**
   * Y-coordinate of the barcode relative to the cover sheet
   */
  y: number;

  /**
   * Width of the barcode in pixels
   */
  width: number;

  /**
   * Height of the barcode in pixels
   */
  height: number;
}

export interface IProductMeasures {
  /**
   * Bleed margin on all sides
   */
  bleed: number;

  /**
   * Total width of the cover, including bleed
   */
  width: number;

  /**
   * Total height of the cover, including bleed
   */
  height: number;
}

export enum ProductType {
  Invalid = 0,
  SoftCover = 1,
  HardCover = 2,
  Ebook = 3,
  LargePrint = 4,
}

export interface ISheet {
  id: string;

  layers: ILayer[];
}

/**
 * Identifies which part of the book this region describes
 */
export enum RegionId {
  /**
   * Left page of a front matter sheet
   */
  PageLeft = 'PageLeft',

  /**
   * Right page of a front matter sheet
   */
  PageRight = 'PageRight',

  /**
   * The front of the cover or the first page of an ebook
   */
  CoverFront = 'CoverFront',

  /**
   * Cover back
   */
  CoverBack = 'CoverBack',

  /**
   * Book spine / book back
   */
  Spine = 'Spine',

  /**
   * Only hardcover: front of the jacket / leaflet around the book
   */
  JacketFront = 'JacketFront',

  /**
   * Only hardcover: back of the jacket / leaflet around the book
   */
  JacketBack = 'JacketBack',

  /**
   * Only hardcover: joint transitioning from cover-back to spine
   */
  JointBack = 'JointBack',

  /**
   * Only hardcover: joint transitioning from spine to cover-front
   */
  JointFront = 'JointFront',
}

export interface IRegion {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  bleedTop: number;
  bleedRight: number;
  bleedBottom: number;
  bleedLeft: number;
}
