import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setGrid } from '../view/viewSlice';
import { useGrid } from '../view/viewSelectors';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { MenuButtonDesigner } from '../../uiComponents/MenuButtonDesigner';
import { SubMenu } from './SubMenu';
import { IDropDown } from './DesignerMenu';
import { useClickOutside } from '../../lib/useClickOutside';

export interface ISnapControl {
  dropDown: IDropDown;
  setDropDown: React.Dispatch<React.SetStateAction<IDropDown>>;
}

export const SnapControl: React.FC<ISnapControl> = ({ dropDown, setDropDown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const grid = useGrid();
  const snapControlRef = useRef<HTMLDivElement>(null);
  const onChangeGridXInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const width = Number.parseInt(e.target.value);
      if (!Number.isNaN(width)) {
        dispatch(setGrid({ size: { width } }));
      }
    },
    [dispatch],
  );

  const onChangeGridYInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const height = Number.parseInt(e.target.value);
      if (!Number.isNaN(height)) {
        dispatch(setGrid({ size: { height } }));
      }
    },
    [dispatch],
  );

  const handleDropDown = useCallback(
    (showDropdown: boolean) => {
      setDropDown({ snapControl: showDropdown, viewControl: false });
    },
    [setDropDown],
  );
  const onClickOutside = useCallback(() => {
    handleDropDown(false);
  }, [handleDropDown]);

  useClickOutside(snapControlRef.current, onClickOutside, dropDown.snapControl);

  const onClickSetMagnetLinesRadio = useCallback(() => {
    dispatch(setGrid({ isEnabled: false }));
    handleDropDown(false);
  }, [handleDropDown, dispatch]);

  const onClickSetGridRadio = useCallback(() => {
    dispatch(setGrid({ isEnabled: true }));
  }, [dispatch]);

  return (
    <Wrapper ref={snapControlRef}>
      <MenuButtonDesigner
        title={t('SnapOnLines')}
        onClick={() => handleDropDown(!dropDown.snapControl)}
        className="designer-menu">
        <DropdownControl>
          <Icon name="seitenraender-control" />
          <Icon name="dropdown-control" />
        </DropdownControl>
        <span className="designer-menu-label">{t('Guides')}</span>
      </MenuButtonDesigner>
      {dropDown.snapControl && (
        <SubMenu onClose={() => handleDropDown(false)}>
          <StyledLabel isActive={!grid.isEnabled}>
            <RadioButton
              type="radio"
              name="snapping-mode"
              checked={!grid.isEnabled}
              onChange={onClickSetMagnetLinesRadio}
            />
            <span>{t('MagneticLines')}</span>
          </StyledLabel>
          <StyledLabel isActive={grid.isEnabled}>
            <RadioButton
              type="radio"
              name="snapping-mode"
              checked={grid.isEnabled}
              onChange={onClickSetGridRadio}
            />
            <span>{t('Grid')}</span>
            <GridInput>
              <StyledInput
                type="number"
                onChange={onChangeGridXInput}
                defaultValue={grid.size.width}
                disabled={!grid.isEnabled}
              />
              <span>x</span>
              <StyledInput
                type="number"
                onChange={onChangeGridYInput}
                defaultValue={grid.size.height}
                disabled={!grid.isEnabled}
              />
            </GridInput>
          </StyledLabel>
        </SubMenu>
      )}
    </Wrapper>
  );
};

interface IStyledLabel {
  isActive: boolean;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const GridInput = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

const StyledLabel = styled.label.attrs<IStyledLabel>((props) => ({
  style: {
    background: props.isActive ? 'var(--color-cta)' : 'white',
    fontWeight: props.isActive ? '600' : '400',
  },
}))<IStyledLabel>`
  font-size: 0.9rem;
  height: 33px;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-standard);
  align-items: center;
  border-radius: var(--border-radius);
  cursor: pointer;

  & > span {
    width: max-content;
  }

  & svg {
    height: 0.875rem;
  }

  & > ${GridInput} {
    ${(props) => !props.isActive && 'display: none'}
  }

  &:hover {
    background: var(--color-cta-light) !important;
  }
`;

const RadioButton = styled.input`
  display: none;
`;

const StyledInput = styled.input`
  text-align: center;
  width: 30px;
  background-color: white;
  border: 1px solid var(--color-border);
  border-radius: 3px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  :disabled {
    background-color: var(--color-background-cancel);
  }
`;

const DropdownControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > .tredition-icon:first-child {
    font-size: 1rem;
  }

  & > .tredition-icon:last-child {
    padding-top: 0.25rem;
    font-size: 10px;
  }
`;
