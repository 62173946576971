import { ActionModal } from '../../../modal/ActionModal';
import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PublisherFormContent.module.css';
import { TextInputFormik } from '../../../forms/textInput/TextInput.formik';
import { SelectFormik } from '../../../select/Select.formik';
import { ensureArray } from '../../../lib/emptyArray';
import { Form, FormikProvider, useFormik } from 'formik';
import { createPublisherSchema, IPublisherSchema } from '../PublisherSchema';
import {
  useAddProjectPublisherProfileMutation,
  useGetCountriesQuery,
  useGetOrganizationsQuery,
} from '../../../api/treditionApi';
import { toast } from 'react-hot-toast';
import { EditorContext } from '../../EditorContext';
import { FormikState } from 'formik/dist/types';

export interface INewPublisherProps {
  onNewPublisher: (publisherProfileId: string) => void;
  onCancel: () => void;
}

export const NewPublisher: FC<INewPublisherProps> = ({ onNewPublisher, onCancel }) => {
  const { t } = useTranslation();

  const [addProjectPublisher] = useAddProjectPublisherProfileMutation();

  const {
    state: { projectId },
  } = useContext(EditorContext);

  const onFormSubmit = useCallback(
    (
      values: IPublisherSchema,
      { resetForm }: { resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void },
    ) => {
      const promise = addProjectPublisher({ projectId, publisher: values });

      return toast
        .promise(promise.unwrap(), {
          loading: t('CreatingPublisher'),
          success: t('PublisherCreatedAndAdded'),
          error: t('PublisherCreationFailed'),
        })
        .then((val) => {
          onNewPublisher(val);
          resetForm();
          return true;
        })
        .catch((e) => {
          // TODO: handle error
          console.error(e);
          return false;
        });
    },
    [addProjectPublisher, onNewPublisher, projectId, t],
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      organisation: '',
      registerNo: '',
      street: '',
      streetNumber: '',
      zip: '',
      city: '',
      countryCode: '',
    },
    onSubmit: onFormSubmit,
    validationSchema: createPublisherSchema(),
    validateOnMount: true,
  });

  const { data: countriesData, isLoading: countriesLoading } = useGetCountriesQuery();
  const { data: organizationsData, isLoading: organizationsLoading } = useGetOrganizationsQuery();

  const { handleSubmit, isValid, resetForm, values } = formik;

  return (
    <div>
      <ActionModal
        onOk={() => {
          handleSubmit();
        }}
        onCancel={() => {
          resetForm();
          onCancel();
        }}
        isOpen={true}
        title={t('NewPublisherProfile')}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          resetForm();
          onCancel();
        }}
        okButtonDisabled={!isValid}>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <div className={styles.wrapper}>
              <TextInputFormik name="name" label={`${t('PublisherName')}*`} />
              <div className={styles.flex}>
                <SelectFormik
                  options={ensureArray(organizationsData)}
                  isLoading={organizationsLoading}
                  className={styles.large}
                  name="organisation"
                  label={`${t('Organisation')}*`}
                />
                <TextInputFormik
                  className={styles.small}
                  name="registerNo"
                  label={`${t('RegisterNo')}*`}
                />
              </div>
              <div className={styles.flex}>
                <TextInputFormik className={styles.large} name="street" label={`${t('Street')}*`} />
                <TextInputFormik
                  className={styles.small}
                  name="streetNumber"
                  label={`${t('Number')}*`}
                />
              </div>
              <div className={styles.flex}>
                <TextInputFormik className={styles.small} name="zip" label={`${t('Zip')}*`} />
                <TextInputFormik className={styles.large} name="city" label={`${t('City')}*`} />
              </div>
              <SelectFormik
                options={ensureArray(countriesData)}
                isLoading={countriesLoading}
                name="countryCode"
                label={`${t('Country')}*`}
              />
            </div>
          </Form>
        </FormikProvider>
      </ActionModal>
    </div>
  );
};
