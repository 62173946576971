import { AppThunk } from '../../redux/store';
import { getAllLayers } from '../layer/liveSheetSelectors';
import { ITextLayer, LayerType } from '../layer/LayersState';
import {
  DesignProblemLevel,
  DesignProblemType,
  DesignProblemUnion,
  LayerProblems,
  ProductProblems,
} from './IDesignerProblem';
import { setProductProblems, setLayerProblems } from './problemSlice';
import { IRect } from '../../lib/rectsIntersect';
import { getActiveProduct } from '../project/productSelectors';
import { checkTextLayerClipping } from './checkTextLayerClipping';
import { checkLayerOutOfBounds } from './checkLayerOutOfBounds';
import { getProjectSlice } from '../project/projectSelectors';

export const determineProblems = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const layers = getAllLayers(state);
  const project = getProjectSlice(state);
  const activeProduct = getActiveProduct(state);

  if (!project || project.isReadonly) {
    return;
  }

  const productProblems: ProductProblems = {};
  for (const product of project.products) {
    if (!product.sheets.length) {
      continue; // data corruption - products should always have at least one sheet
    }
    if (!product.sheets[0].layers.length) {
      maybeAddProductProblem(product.id, {
        type: DesignProblemType.DesignEmpty,
        level: DesignProblemLevel.Warning,
      });
    }
  }

  function maybeAddProductProblem(productId: string, problem: DesignProblemUnion | null): boolean {
    if (!problem) {
      return false;
    }
    if (!productProblems[productId]) {
      productProblems[productId] = [];
    }
    productProblems[productId].push(problem);
    return true;
  }

  dispatch(setProductProblems(productProblems));

  if (!activeProduct) {
    return;
  }
  const { measures } = activeProduct;
  const designRect: IRect = {
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: measures.width, y: measures.height },
  };

  const layerProblems: LayerProblems = {};

  function maybeAddLayerProblem(layerId: string, problem: DesignProblemUnion | null): boolean {
    if (!problem) {
      return false;
    }
    if (!layerProblems[layerId]) {
      layerProblems[layerId] = [];
    }
    layerProblems[layerId].push(problem);
    return true;
  }

  layers.forEach((layer) => {
    maybeAddLayerProblem(layer.id, checkLayerOutOfBounds(designRect, layer));
    if (layer.type === LayerType.Text) {
      maybeAddLayerProblem(layer.id, checkTextLayerClipping(layer as ITextLayer));
    }
  });

  dispatch(setLayerProblems(layerProblems));
};
