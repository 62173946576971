import { Command } from "ckeditor5/src/core";
import { LINE_HEIGHT } from "./LineHeightUI";
import { first } from "ckeditor5/src/utils";

export default class LineHeightCommand extends Command {
  constructor(editor) {
    super(editor);
    this.attributeKey = LINE_HEIGHT;
  }

  /**
   * @inheritDoc
   */
  refresh() {
    const firstBlock = first(
      this.editor.model.document.selection.getSelectedBlocks()
    );

    // As first check whether to enable or disable the command as the value will always be false if the command cannot be enabled.
    this.isEnabled = !!firstBlock && this._canBeLineHeighted(firstBlock);

    /**
     * A value of the current block's alignment.
     *
     * @observable
     * @readonly
     * @member {String} #value
     */
    if (this.isEnabled && firstBlock.hasAttribute(LINE_HEIGHT)) {
      this.value = firstBlock.getAttribute(LINE_HEIGHT);
    }
  }

  _canBeLineHeighted(block) {
    return this.editor.model.schema.checkAttribute(block, LINE_HEIGHT);
  }

  /**
   * Executes the command. Applies the `value` of the {@link #attributeKey} to the selection.
   * If no `value` is passed, it removes the attribute from the selection.
   *
   * @protected
   * @param {Object} [options] Options for the executed command.
   * @param {String} [options.value] The value to apply.
   * @fires execute
   */
  execute(options = {}) {
    const editor = this.editor;
    const model = editor.model;
    const doc = model.document;

    const value = options.value;

    model.change((writer) => {
      // Get only those blocks from selected that can have alignment set
      const blocks = Array.from(doc.selection.getSelectedBlocks()).filter(
        (block) => this._canBeLineHeighted(block)
      );
      const removeAttribute = value === "0";

      if (removeAttribute) {
        removeLineHeightFromSelection(blocks, writer);
      } else {
        setLineHeightOnSelection(blocks, writer, value);
      }
    });
  }
}

// @private
function removeLineHeightFromSelection(blocks, writer) {
  for (const block of blocks) {
    writer.removeAttribute(LINE_HEIGHT, block);
  }
}

// @private
function setLineHeightOnSelection(blocks, writer, alignment) {
  for (const block of blocks) {
    writer.setAttribute(LINE_HEIGHT, alignment, block);
  }
}
