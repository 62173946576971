import { ILayer } from './LayersState';

export function getLayerCssFilter(layer: ILayer): string {
  if (!layer.allowEffects) {
    return '';
  }
  let filters = '';
  if (layer.effect.blur) {
    filters += ` blur(${layer.effect.blur}px)`;
  }
  if (layer.effect.dropShadow) {
    filters += ` drop-shadow(${layer.effect.dropShadow}px ${layer.effect.dropShadow}px 4px #000000)`;
  }
  if (layer.effect.sepia) {
    filters += ` sepia(${layer.effect.sepia}%)`;
  }
  if (layer.effect.transparency) {
    filters += ` opacity(${100 - layer.effect.transparency}%)`;
  }
  if (layer.effect.grayscale) {
    filters += ` grayscale(${layer.effect.grayscale}%)`;
  }
  if (layer.effect.hueRotate) {
    filters += ` hue-rotate(${layer.effect.hueRotate}deg)`;
  }
  return filters;
}
