import React from 'react';
import styles from './UnderlinedButton.module.css';
import classNames from 'classnames';

export interface IUnderlinedButton {
  onClick: () => unknown;
  className?: string;
  label: string;
}

export const UnderlinedButton = ({ onClick, label, className, ...rest }: IUnderlinedButton) => {
  return (
    <button {...rest} onClick={onClick} className={classNames(styles.wrapper, className)}>
      {label}
    </button>
  );
};
