import React, { FC, MouseEvent, useMemo, useState } from 'react';
import { adjustDepth, deleteLayers, selectLayer } from '../../layer/liveSheetSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getAllLayers } from '../../layer/liveSheetSelectors';
import { LayerThumbnail } from './LayerThumbnail';
import { ILayer, ITextLayer, LayerType } from '../../layer/LayersState';
import { LayerItemDropper } from './LayerItemDropper';
import { AdjustDepthMode } from '../../layer/adjustDepthReducer';
import { MenuButton } from '../../../uiComponents/MenuButton';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../icons/Icon';
import { SidePanelContentWrapper } from '../SidePanelContentWrapper';

export const LayersPanel = (): JSX.Element => {
  const layersUnsorted = useAppSelector(getAllLayers);
  const layers = useMemo(() => {
    return [...layersUnsorted].sort((a, b) => b.depth - a.depth);
  }, [layersUnsorted]);
  const [moveId, setMoveId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <SidePanelContentWrapper>
      <h2>{t('Objects')}</h2>
      {layers.length ? (
        <ul className="layer-list" aria-label="Ebenen">
          {layers.map((layer) => (
            <LayerListEntry
              key={layer.id}
              layer={layer}
              onMoveStart={() => setMoveId(layer.id)}
              onMoveEnd={(targetId: string, mode: AdjustDepthMode) => {
                if (!moveId) {
                  return;
                }
                dispatch(adjustDepth({ sourceId: moveId, targetId, mode }));
                setMoveId(null);
              }}
              moveId={moveId}
            />
          ))}
        </ul>
      ) : (
        <span>{t('NoElement')}</span>
      )}
    </SidePanelContentWrapper>
  );
};

export interface ILayerListEntryProps {
  layer: ILayer;
  onMoveStart: () => void;
  onMoveEnd: (targetId: string, mode: AdjustDepthMode) => void;
  moveId: string | null;
}

// TODO: clean up / naming / etc

export const LayerListEntry: FC<ILayerListEntryProps> = ({
  layer,
  onMoveEnd,
  onMoveStart,
  moveId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onDropAbove = () => {
    onMoveEnd(layer.id, AdjustDepthMode.AboveTarget);
  };

  const onDropBelow = () => {
    onMoveEnd(layer.id, AdjustDepthMode.BelowTarget);
  };

  const onClickDelete = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(deleteLayers({ filter: { id: layer.id } }));
  };
  const onClickSelectLayer = (e: MouseEvent) => {
    dispatch(selectLayer({ id: layer.id, exclusive: !e.ctrlKey, toggle: true }));
  };

  const showDroppers = moveId && moveId !== layer.id;

  const layerText = (layer as ITextLayer).content || undefined;
  const label = useMemo<string>(() => {
    if (layer.type === LayerType.Text) {
      const textPreviewMaxLength = 32;
      const dummy = document.createElement('DIV');
      dummy.innerHTML = layerText || '';
      let text = dummy.textContent || dummy.innerText || '';
      if (text.length > textPreviewMaxLength) {
        text = text.substring(0, textPreviewMaxLength) + '...';
      }
      return `„${text}”`;
    }
    return t(layer.label);
  }, [t, layer.label, layer.type, layerText]);

  let title = '';
  if (process.env.NODE_ENV === 'development') {
    title = layer.id;
  }

  return (
    <StyledListEntry
      title={title}
      isSelected={layer.isGrabbed || layer.isSelected}
      role="button"
      onClick={onClickSelectLayer}
      aria-label={label}
      key={layer.id}
      draggable
      onDragStart={onMoveStart}>
      {showDroppers && <LayerItemDropper isAbove onDrop={onDropAbove} />}
      <LayerThumbnail layer={layer} />
      <span>{label}</span>
      <MenuButton style={{ zIndex: 2 }} onClick={onClickDelete} disabled={!layer.isDeletable}>
        <Icon name="loeschen" />
      </MenuButton>
      <LayerItemDropperBelowWrapper />
      {showDroppers && <LayerItemDropper isAbove={false} onDrop={onDropBelow} />}
    </StyledListEntry>
  );
};

interface IStyledListEntry {
  isSelected?: boolean;
}

const StyledListEntry = styled.li.attrs<IStyledListEntry>((props) => ({
  style: {
    backgroundColor: props.isSelected ? 'var(--color-cta-light)' : undefined,
  },
}))<IStyledListEntry>`
  border: 0.1rem solid var(--color-border);
  padding: 0.5rem;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0.2rem;
  position: relative;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-cta-light) !important;
  }
`;

const LayerItemDropperBelowWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
`;
