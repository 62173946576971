import styles from './NavItem.module.css';
import classNames from 'classnames';
import { Icon, IIconName } from '../icons/Icon';
import React from 'react';
import { noop } from '../lib/noop';

export enum NavState {
  None = 'none',
  Invalid = 'invalid',
  Complete = 'complete',
  Dirty = 'dirty',
}

export interface INavItemProps {
  state?: NavState;
  active?: boolean;
  label: string;

  disabled?: boolean;

  onClick?: React.MouseEventHandler<HTMLElement>;
}

const StateToIcon: { [key in NavState]: [string, IIconName] | [] } = {
  [NavState.None]: [],
  [NavState.Complete]: [styles.iconComplete, 'check-control'],
  [NavState.Invalid]: [styles.iconWarning, 'achtung-control'],
  [NavState.Dirty]: [styles.iconDirty, 'dreipunkte-control'],
};

export const NavItem = ({
  label,
  active = false,
  state = NavState.None,
  onClick = noop,
  disabled = false,
}: INavItemProps) => {
  const [iconClass, iconName] = StateToIcon[state];

  return (
    <li
      className={classNames(styles.navItem, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? noop : onClick}>
      {iconName != null && <Icon className={classNames(styles.icon, iconClass)} name={iconName} />}
      {label}
    </li>
  );
};
