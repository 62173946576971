import { IDesignerTemplateState } from './IDesignerTemplateState';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ISetApplyIdActionPayload {
  /**
   * The template id to apply.
   */
  id: string | null;

  /**
   * Whether the template application must be confirmed
   */
  confirm: boolean;
}

export function setApplyIdReducer(
  state: IDesignerTemplateState,
  action: PayloadAction<ISetApplyIdActionPayload>,
) {
  state.applyId = action.payload.id;
  state.confirm = action.payload.confirm;
}
