import { useAppSelector } from '../redux/hooks';
import { FC, PropsWithChildren } from 'react';
import { getLocaleSlice } from './localeSelectors';

export const LanguageLoader: FC<PropsWithChildren> = ({ children }) => {
  const locale = useAppSelector(getLocaleSlice);
  if (locale.languageResourceLoaded) {
    return <>{children}</>;
  }
  return null;
};
