import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';

/**
 * Zoom-factor as a fraction of one. Values less than 0.1 will be adjusted to 0.1
 */
export type IZoomActionPayload = number;

export function zoomReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<IZoomActionPayload>,
) {
  state.zoom = Math.max(action.payload, 0.1);
}
