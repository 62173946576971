import { AppThunk } from '../../redux/store';
import { openProductInternal } from './projectActions';
import { getProductById } from './productSelectors';
import { getAllLayers } from '../layer/liveSheetSelectors';
import { showCopyDesignModal } from '../view/viewSlice';

export const openProduct =
  (id: string, suppressCopyDesignModal = false): AppThunk =>
  (dispatch, getState) => {
    const currentState = getState();
    const productToOpen = getProductById(currentState, id);
    if (!productToOpen) {
      return;
    }
    const layers = getAllLayers(currentState);

    dispatch(openProductInternal({ product: productToOpen, layers }));

    if (
      !suppressCopyDesignModal &&
      layers.length &&
      productToOpen.sheets.every(($) => !$.layers.length)
    ) {
      dispatch(showCopyDesignModal(true));
    }
  };
