import { createGlobalStyle } from 'styled-components';
import globalStyle from './globalStyle.module.css';

export default createGlobalStyle`
  ${globalStyle}
  
  // Style for out portal
  .text-editor :not(.ck, .ck *) {
    height: inherit;
    width: inherit;
  }

  .ck-vertical-alignment {
    &.top {
      margin-bottom: auto !important;
    }

    &.center {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    &.bottom {
      margin-top: auto !important;
    }

    display: inline-block !important;
    align-items: initial !important;
  }

  .text-editor .ck {
    /* important because on the 'text-editor' container has pointer-events: none to keep layer translation input */
    pointer-events: auto;
  }


  .text-editor .ck.ck-content {
    display: flex;
    flex-direction: column;
    /* height must be set to make vertical alignment work in editing mode */
    height: 100%;
  }

  .text-editor .ck-blurred {
    background: none;
  }

  .text-editor .ck {
    cursor: text;
    outline: 1px dashed var(--color-secondary) !important;
  }

  .ck.ck-color-ui-dropdown {
    .ck-dropdown__panel {
      display: none !important;
    }
  }

  .ck.ck-editor__editable_inline {
    border: none !important; /* causes unnecessary indentation */
  }

  .dwByw .ck.ck-toolbar .ck.ck-toolbar__items .ck-font-size-dropdown {
    width: min-content;
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button .ck-button__label {
    width: min-content;
  }

  .dwByw .ck.ck-toolbar .ck.ck-toolbar__items .ck-font-size-dropdown .ck.ck-button__label {
    width: min-content;
  }

  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    padding: 0.25rem;
    top: calc(100% + 9px);
  }

  // __________ end ck editor styles __________

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body, #root, .trc {
    height: 100%;
    overflow: clip;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Barlow', sans-serif;
  }
  
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  
  input, button, textarea, select {
    font: inherit;
  }
  
  figure {
    margin: 0;
  }

  #root, #__next {
    //isolation: isolate;
  }
  
  .trc {
    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
    }
    
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    h2 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    h3 {
      font-size: 0.9rem;
      font-weight: 600;
    }
    
    h4 {
      font-size: 1.2rem;
      font-weight: 500;
    }

    //input[type="number"] {
    //  appearance: textfield;
    //}
    //
    //input[type="text"] {
    //  border: 1px solid var(--color-cta);
    //  border-radius: 2px;
    //  outline: none;
    //  font-weight: 600;
    //  font-size: 1rem;
    //  color: #000000;
    //  padding: .375rem .75rem;
    //}
    //
    //input[type="text"]:hover, input[type="text"]:focus {
    //  background: var(--color-cta-light);
    //}

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
`;
