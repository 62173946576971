import { TextTemplateType } from './LayersState';
import { IProject } from '../project/ProjectState';

export function getDynamicTextContent(templateType: TextTemplateType, project: IProject) {
  switch (templateType) {
    case TextTemplateType.None:
    default:
      return '';
    case TextTemplateType.Author:
      return project.authorsSeparated;
    case TextTemplateType.AuthorProfileText:
      return project.authorProfileText;
    case TextTemplateType.BookTitle:
      return project.title;
    case TextTemplateType.BookSubtitle:
      return project.subtitle;
    case TextTemplateType.Imprint:
      return project.imprintText;
  }
}
