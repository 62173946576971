import React, { useContext, useLayoutEffect, useRef } from 'react';
import { ITextLayer } from '../layer/LayersState';
import { LayerMenu } from './LayerMenu';
import { Menu } from '../../menu/Menu';
import styled from 'styled-components/macro';
import { CKToolbarWrapper } from './CKToolbarWrapper';
import { CKEContext } from '../textEditor/CKEditorProvider';
import { CKCustomColorPickerInjector } from './CKCustomColorPickerInjector';

export interface ITextLayerMenuProps {
  layers: ITextLayer[];
}

export const TextLayerMenu: React.FC<ITextLayerMenuProps> = ({ layers }) => {
  const { editor, layerId } = useContext(CKEContext);
  const ckToolbarWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (layerId && ckToolbarWrapperRef.current && editor) {
      ckToolbarWrapperRef.current.innerHTML = '';
      ckToolbarWrapperRef.current.append(editor.ui.view.toolbar.element);
    }
  }, [editor, ckToolbarWrapperRef, layerId]);

  return (
    <LayerMenu layers={layers}>
      {!!layerId && (
        <Menu>
          <MenuWrapper>
            <CKCustomColorPickerInjector />
            <CKToolbarWrapper ref={ckToolbarWrapperRef} />
          </MenuWrapper>
        </Menu>
      )}
    </LayerMenu>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  grid-row: 2 / span 1;
  border-right: 1px solid var(--color-border);
  height: 32px;
`;
