import { IProduct, RegionId } from '../project/ProductState';

export interface ICoverDimensions {
  spineWidth: number;
  coverWidth: number;
  coverHeight: number;
  jacketWidth: number;
  shiftX: number;
  shiftY: number;
}

/**
 * Calculates how large in pixels each face on the 3d cover preview has to be. The cover front
 * determines both cover sizes, and it expects the cover back to be as large as the cover front.
 */
export function calculateCoverDimensions(product: IProduct): ICoverDimensions {
  const dim: ICoverDimensions = {
    spineWidth: 1,
    coverWidth: 0,
    coverHeight: 0,
    jacketWidth: 0,
    shiftX: 0,
    shiftY: 0,
  };
  const spineRegion = product.regions.find(($) => $.id === RegionId.Spine);
  const coverFront = product.regions.find(($) => $.id === RegionId.CoverFront);
  const jacketBack = product.regions.find(($) => $.id === RegionId.JacketBack);

  if (spineRegion && spineRegion.width > 0) {
    dim.spineWidth = spineRegion.width;
  }
  if (coverFront && coverFront.width > 0) {
    dim.coverWidth = coverFront.width - product.measures.bleed;
  }
  if (jacketBack && jacketBack.width > 0) {
    dim.jacketWidth = jacketBack.width;
  }
  dim.coverHeight = product.measures.height - product.measures.bleed * 2;
  dim.shiftX = -product.measures.bleed;
  dim.shiftY = -product.measures.bleed;
  return dim;
}
