import { ILayer } from '../designer/layer/LayersState';

export interface IRGB {
  /**
   * Red channel ranging from 0 to 1
   */
  r: number;

  /**
   * Blue channel ranging from 0 to 1
   */
  b: number;

  /**
   * Green channel ranging from 0 to 1
   */
  g: number;
}

export interface IHSL {
  /**
   * Hue ranging from 0 to 1
   */
  h: number;

  /**
   * Saturation ranging from 0 to 1
   */
  s: number;

  /**
   * Lightness ranging from 0 to 1
   */
  l: number;
}

export interface IHSLA extends IHSL {
  /**
   * Alpha value (opacity) ranging from 0 to 1
   */
  a: number;
}

export interface IRGBA extends IRGB {
  /**
   * Alpha value (opacity) ranging from 0 to 1
   */
  a: number;
}

export interface IHSV {
  /**
   * Hue ranging from 0 to 1
   */
  h: number;

  /**
   * Saturation ranging from 0 to 1
   */
  s: number;

  /**
   * Brightness / Value ranging from 0 to 1
   */
  v: number;
}

export interface ICMYK {
  /**
   * Cyan ranging from 0 to 1
   */
  c: number;

  /**
   * Magenta ranging from 0 to 1
   */
  m: number;

  /**
   * Yellow ranging from 0 to 1
   */
  y: number;

  /**
   * Key ranging from 0 to 1
   */
  k: number;
}

export class Rgba {
  public static black(): IRGBA {
    return { r: 0, g: 0, b: 0, a: 1 };
  }

  public static devoid(): IRGBA {
    return { r: 0, g: 0, b: 0, a: 0 };
  }

  public static white(): IRGBA {
    return { r: 1, g: 1, b: 1, a: 1 };
  }
}

export function getCssRgb({ r, g, b }: IRGB): string {
  return `rgb(${Math.round(r * 100)}%, ${Math.round(g * 100)}%, ${Math.round(b * 100)}%)`;
}

export function getCssRgba({ r, g, b, a }: IRGBA): string {
  return `rgba(${Math.round(r * 100)}%, ${Math.round(g * 100)}%, ${Math.round(b * 100)}%, ${a})`;
}

export function getLayerBackgroundColorCss(layer: ILayer): string | undefined {
  return layer.background.color?.rgba ? getCssRgba(layer.background.color!.rgba) : undefined;
}

export function getLayerBorderCss(layer: ILayer): string | undefined {
  return layer.border.color
    ? `${layer.border.size}px solid ${getCssRgba(layer.border.color!.rgba)}`
    : undefined;
}
