import { RootState } from '../../redux/store';
import { IHistoryPage, IHistoryRecord } from './IHistoryState';
import { useAppSelector } from '../../redux/hooks';
import { getActiveProductId } from '../project/productSelectors';
import { getDesignerSlice } from '../designerSelectors';
import { historySlice } from './historySlice';

/**
 * Returns the state slice of the designer's history
 */
export const getHistorySlice = (state: RootState) => getDesignerSlice(state)[historySlice.name];

/**
 * Returns the history page of the given productId or null if the page does not exist
 */
export const getHistoryPageByProductId = (state: RootState, productId: string) =>
  getHistorySlice(state).byProductId[productId] || null;

/**
 * Returns all history records of the given product id. Returns an empty array if the product does not exist,
 * the history page for that product does not exist of the history is empty.
 */
export const getHistoryRecordsByProductId = (state: RootState, productId: string) => {
  const db = getHistoryPageByProductId(state, productId);
  if (!db) {
    return [];
  }
  return db.records;
};

/**
 * Returns whether the user made any change after last saving the project
 */
export const isSavePointDifferent = (state: RootState) => {
  for (const page of Object.values(getHistorySlice(state).byProductId)) {
    if (page.savedAtIndex !== page.index) {
      return true;
    }
  }
  return false;
};

/**
 * Returns the current design history revision
 */
export const getRevision = (state: RootState) => getHistorySlice(state).revision;

/**
 * Returns the last entry of the history records for the given product or null if the product does not exist
 * or no history page exists for that product or the history for that product is empty.
 */
export const getLastHistoryRecord = (
  state: RootState,
  productId: string,
): IHistoryRecord | null => {
  const records = getHistoryRecordsByProductId(state, productId);
  if (!records.length) {
    return null;
  }
  return records[records.length - 1];
};

/**
 * Provides the currently active history page to a React component which depends on the currently loaded product as
 * the history is stored per-product. Returns null if no product is currently active or no history page exists for that
 * product.
 */
export const useActiveHistoryPage = (): IHistoryPage | null =>
  useAppSelector((state) => {
    const productId = getActiveProductId(state);
    if (!productId) {
      return null;
    }
    return getHistoryPageByProductId(state, productId);
  });

/**
 * Provides the current design history revision to a React component
 */
export const useRevision = () => useAppSelector(getRevision);
