import { RootState } from '../../redux/store';
import { getDesignerSlice } from '../designerSelectors';
import { inputSlice } from './inputSlice';
import { useAppSelector } from '../../redux/hooks';

/**
 * Returns the designer's input state slice
 */
export const getDesignerInput = (state: RootState) => getDesignerSlice(state)[inputSlice.name];

/**
 * Returns the mouse position relative to the currently active sheet
 */
export const getSheetMousePosition = (state: RootState) => getDesignerInput(state).mouse.sheet;

/**
 * Provides the designer's input state to a React component
 */
export const useDesignerInput = () => useAppSelector(getDesignerInput);
