import { useAppDispatch } from '../../redux/hooks';
import { DragContextType, dragStart } from '../drag/dragSlice';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { TextTemplateType } from '../layer/LayersState';
import { IProject } from '../project/ProjectState';
import { useEnsuredProject } from '../project/projectSelectors';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';

const dragButtonDef: [TextTemplateType, (p: IProject) => boolean, string][] = [
  [TextTemplateType.None, () => true, 'NewTextObject'],
  [TextTemplateType.Author, (p) => !!p.authorsSeparated, 'Author'],
  [TextTemplateType.BookSubtitle, (p) => !!p.subtitle, 'Subtitle'],
  [TextTemplateType.BookTitle, (p) => !!p.title, 'Title'],
];

export const TextLayersPanel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useEnsuredProject();

  return (
    <SidePanelContentWrapper>
      <h2>{t('TextBoxesPrompt')}</h2>
      {dragButtonDef
        .filter(([_, filter]) => filter(p))
        .map(([templateType, _, label]) => (
          <TextElement
            key={templateType}
            onDragStart={() => {
              dispatch(
                dragStart({
                  type: DragContextType.DesignerText,
                  templateType,
                }),
              );
            }}
            draggable
            role="button">
            {t(label)}
          </TextElement>
        ))}
    </SidePanelContentWrapper>
  );
};

const TextElement = styled.div`
  padding: 1rem 1rem;
  border: 2px dashed var(--color-cta);
  border-radius: 3px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-cta-light);
  }
`;
