import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IRestoreFromHistoryActionPayload } from '../history/restoreFromHistoryReducer';
import { ILiveSheetState } from './ILiveSheetState';
import { createInitialLiveSheetState } from './liveSheetSlice';

/**
 * Reacts to the {@link restoreFromHistory} action and assigns the layers from the history record to
 * the current layer state.
 */
export function applyRestoreFromHistoryToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IRestoreFromHistoryActionPayload>,
): ILiveSheetState {
  state = createInitialLiveSheetState();
  const {
    record: { activeSheetId, sheets },
  } = action.payload;
  const sheet = sheets.find(($) => $.id === activeSheetId);
  if (!sheet) {
    return state;
  }
  state.layers = sheet.layers;
  return state;
}
