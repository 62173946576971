import { AppThunk } from '../../redux/store';
import { getHistoryPageByProductId } from './historySelectors';
import { getActiveProduct } from '../project/productSelectors';
import { restoreProductFromHistoryRecord } from './restoreProductFromHistoryRecord';

/**
 * Restores the previous state of the currently active product, if existing.
 *
 * Cannot undo past the initial history entry.
 */
export const undo = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const product = getActiveProduct(state);
  if (!product) {
    return;
  }
  const page = getHistoryPageByProductId(state, product.id);
  if (page) {
    dispatch(restoreProductFromHistoryRecord(page.index - 1));
  }
};
