import { createAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { ILayer } from '../layer/LayersState';
import { IProduct, ISheet } from './ProductState';
import { ITreditionPalette } from '../palette/TreditionPalette';

export const clearProjectActionType = 'project/clearProject';
export const setProjectActionType = 'project/setProject';
export const setReadonlyActionType = 'project/setReadonly';
export const openProductActionType = 'project/openProduct';
export const activateSheetActionType = 'project/activateSheet';
export const addSheetActionType = 'project/addSheetActionType';
export const deleteSheetActionType = 'project/deleteSheet';
export const importProductActionType = 'project/importProduct';
export const applyTemplateActionType = 'project/applyTemplate';
export const setPaletteActionType = 'project/setPalette';

export type ISetProjectActionPayload = IProjectState;

/**
 * Removes all sheets in all project (except for the initial sheet) and removes all layers
 */
export const clearProject = createAction<void>(clearProjectActionType);

/**
 * Overwrites the entire project state
 */
export const setProject = createAction<ISetProjectActionPayload>(setProjectActionType);

export interface IOpenProductActionPayload {
  /**
   * The layers of the currently active canvas
   */
  layers: ILayer[];

  /**
   * The product data which should be loaded
   */
  product: IProduct;
}

/**
 * @internal Do not dispatch from React, use the {@link openProduct} thunk instead.
 */
export const openProductInternal = createAction<IOpenProductActionPayload>(openProductActionType);

export interface ISetPaletteActionPayload {
  /**
   * The id of the product
   */
  productId: string;

  /**
   * The palette to apply to the product
   *
   * If null all palette indices in all layers will be cleared, but their current color will be preserved.
   */
  palette: ITreditionPalette | null;

  /**
   * The currently active palette
   */
  currentPalette: ITreditionPalette | null;
}

/**
 * @internal Do not dispatch from React, use the {@link setPalette} thunk instead.
 */
export const setPaletteInternal = createAction<ISetPaletteActionPayload>(setPaletteActionType);

export interface IAddSheetActionPayload {
  /**
   * Where to insert the new sheet in the list of sheets
   */
  index: number;

  /**
   * The sheet to add
   */
  sheet: ISheet;
}

/**
 * Adds a sheet to the list of sheets of the currently active product at the given index
 */
export const addSheet = createAction<IAddSheetActionPayload>(addSheetActionType);

export interface IActivateSheetActionPayload {
  /**
   * The layers of the sheet that will be activated
   */
  layers: ILayer[];

  /**
   * The id of the sheet which will activate. Must exist in the currently open product.
   */
  sheetId: string;
}

/**
 * @internal Do not dispatch from React, use the {@link activateSheet} thunk instead.
 */
export const activateSheetInternal =
  createAction<IActivateSheetActionPayload>(activateSheetActionType);

export interface IDeleteSheetActionPayload {
  /**
   * The index of the sheet in the list of sheets to delete
   */
  sheetIndex: number;

  /**
   * The sheet which will now be active after deletion.
   *
   * The active sheet must be reassigned in case the user deletes the currently active sheet.
   */
  nextActiveSheetId?: string | null;

  /**
   * If given will reassign the given layers to the current live sheet.
   *
   * This happens in case the user deletes the currently active sheet.
   */
  nextLayers?: ILayer[];
}

/**
 * @internal Do not dispatch from React, use the {@link deleteSheet} thunk instead.
 */
export const deleteSheetInternal = createAction<IDeleteSheetActionPayload>(deleteSheetActionType);

export type IApplyTemplateActionPayload = IProduct;

/**
 * Replaces the current product with the payload
 *
 * @internal Do not dispatch from React, use the {@link applyTemplate} thunk instead.
 */
export const applyTemplateInternal =
  createAction<IApplyTemplateActionPayload>(applyTemplateActionType);

/**
 * Sets the `isReadonly` flag to the given value
 */
export const setReadonly = createAction<boolean>(setReadonlyActionType);

export type IImportProductActionPayload = IProduct;

/**
 * @internal Do not dispatch from React, use the {@link importProduct} thunk instead
 */
export const importProductInternal =
  createAction<IImportProductActionPayload>(importProductActionType);
