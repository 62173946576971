import { DeepPartial, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IImageLayer, ILayer, IPlainLayer, ITextLayer } from './LayersState';
import { LayerFactory } from './LayerFactory';
import { deselectLayersReducer } from './deselectLayersReducer';
import { recalculateDepthReducer } from './recalculateDepthReducer';
import { ILiveSheetState } from './ILiveSheetState';

export interface IAddLayersActionPayload extends Array<ILayer> {}

export interface IAddTextLayerActionPayload extends DeepPartial<ITextLayer> {
  /**
   * Initial text for the new layer
   */
  content?: string;
}

export interface IAddImageLayerActionPayload extends DeepPartial<IImageLayer> {
  /**
   * @see IImageLayer.fileId
   */
  fileId: string;
}

export interface IAddPlainLayerActionPayload extends DeepPartial<IPlainLayer> {}

/**
 * Adds a plain layer to the current state
 */
export function addPlainLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAddPlainLayerActionPayload>,
) {
  addLayer(state, LayerFactory.PlainLayer(action.payload));
}

/**
 * Adds a text layer to the current state
 */
export function addTextLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAddTextLayerActionPayload>,
) {
  addLayer(state, LayerFactory.TextLayer(action.payload));
}

/**
 * Adds an image layer to the current state
 */
export function addImageLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAddImageLayerActionPayload>,
) {
  addLayer(state, LayerFactory.ImageLayer(action.payload));
}

/**
 * Adds multiple complete layers (usually from the clipboard)
 */
export function addLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAddLayersActionPayload>,
) {
  deselectLayersReducer(state);
  for (const layer of action.payload) {
    state.layers.push(layer);
  }
  recalculateDepthReducer(state);
}

/**
 * @internal
 *
 * Takes care of deselecting all other layers calculating the depth for the new layer.
 *
 * Do not call from other modules.
 */
export function addLayer(state: Draft<ILiveSheetState>, layer: ILayer) {
  deselectLayersReducer(state);
  state.layers.push(layer);
  recalculateDepthReducer(state);
}
