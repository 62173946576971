/**
 * Triggers the save project logic which for some steps still resides in the blazor app.
 *
 * Resolves to false if the save project call failed (due to business logic)
 * or because somehow the interop endpoint was not available.
 */
export function saveProjectBlazor(): Promise<boolean> {
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve(true);
  }
  if (typeof window.BLZ_EDITOR_SAVE !== 'function') {
    return Promise.resolve(false);
  }
  return window.BLZ_EDITOR_SAVE();
}
