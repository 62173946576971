import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILayerBorder } from './LayersState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetBorderActionPayload {
  /**
   * The new border configuration to set to all layers matching the given filter.
   * Note that you can supply a partial configuration which will cause only the given arguments to overwrite
   * the current configuration.
   */
  border: Partial<ILayerBorder>;

  /**
   * Layers which should receive the new border configuration.
   *
   * Note that layers which do not support borders will be filtered out in all cases.
   */
  filter: ILayerFilter;
}

/**
 * Modifies the border of all layers satisfying the given filter
 */
export function setBorderReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetBorderActionPayload>,
) {
  filterLayers(state.layers, action.payload.filter).forEach((layer) => {
    if (!layer.allowEffects) {
      return;
    }
    let wasUpdated = false;
    if (
      typeof action.payload.border.size === 'number' &&
      !Number.isNaN(action.payload.border.size)
    ) {
      layer.border.size = action.payload.border.size;
      wasUpdated = true;
    }
    if (action.payload.border.color) {
      layer.border.color = action.payload.border.color;
      wasUpdated = true;
    }
    if (wasUpdated) {
      layer.updatedAt = Date.now();
    }
  });
}
