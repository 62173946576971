import { FC, Fragment, ReactNode, useMemo } from 'react';
import { QueryErrorType, resolveQueryError } from './resolveQueryError';
import { IQuery } from '../../@types/IQuery';
import { Alert } from '../alert/Alert';
import { QueryErrorDescription } from './QueryErrorDescription';
import { useTranslation } from 'react-i18next';
import { Button } from '../uiComponents/Button';

export interface ILoadErrorProps {
  query: IQuery;
  renderMessage?: (errorType: QueryErrorType) => ReactNode;
}

/**
 * A general purpose query error component
 */
export const LoadError: FC<ILoadErrorProps> = ({ query, renderMessage }) => {
  const { t } = useTranslation();
  const { isRetryable, type } = resolveQueryError(query);
  const message = useMemo<ReactNode>(() => {
    if (renderMessage) {
      return renderMessage(type);
    }
    return (
      <Fragment>
        {t('LoadError')}: <QueryErrorDescription type={type} />
      </Fragment>
    );
  }, [renderMessage, type, t]);

  return (
    <Alert level="error">
      {message}
      {isRetryable && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
          <Button onClick={query.refetch} disabled={query.isFetching}>
            {t(query.isFetching ? 'PleaseWait' : 'Retry')}
          </Button>
        </div>
      )}
    </Alert>
  );
};
