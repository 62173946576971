import React, { FC } from 'react';
import styles from './Loader.module.css';
import classNames from 'classnames';

export interface ILoaderProps {
  showBackdrop?: boolean;
}

export const Loader: FC<ILoaderProps> = ({ showBackdrop = true }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.backdrop]: showBackdrop,
      })}>
      <div className={styles.loader} />
    </div>
  );
};
