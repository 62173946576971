import { createSlice } from '@reduxjs/toolkit';
import { setActiveManipulationMenuReducer } from './setActiveManipulationMenuReducer';
import { IManipulationMenuState } from './ManipulationMenuState';
import { ManipulationMenuEnumeration } from '../model/ManipulationMenuEnumeration';

export const createInitialManipulationMenuState = (): IManipulationMenuState => ({
  activeMenu: ManipulationMenuEnumeration.TOC,
});

export const manipulationMenuSlice = createSlice({
  name: 'manipulationMenu',
  initialState: Object.freeze(createInitialManipulationMenuState()),
  reducers: {
    setActiveManipulationMenu: setActiveManipulationMenuReducer,
  },
});

export const { setActiveManipulationMenu } = manipulationMenuSlice.actions;

export const manipulationMenuReducer = manipulationMenuSlice.reducer;
