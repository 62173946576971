import React, { ChangeEvent, Fragment, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { IImageLayer } from '../layer/LayersState';
import { LayerMenu } from './LayerMenu';
import { Menu } from '../../menu/Menu';
import { useAppDispatch } from '../../redux/hooks';
import { consolidate } from '../../lib/consolidate';
import { replaceImage, setImageOptions } from '../layer/liveSheetSlice';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { Modal } from '../../modal/Modal';
import { ImageCropper, IOnCropData } from '../../crop/ImageCropper';
import { useTreditionApiUrl } from '../../config/configSelectors';
import { getImageBlobUrlWithoutReplacements } from '../../api/useImageBlobUrl';
import { useCropDesignImageMutation } from '../../api/treditionApi';
import { noop } from '../../lib/noop';
import { useEnsuredProjectId } from '../project/projectSelectors';
import { toast } from 'react-hot-toast';
import { ImageReplacer } from './ImageReplacer';
import { Mime } from '../../lib/mime';
import { SidePanelId } from '../sidePanel/sidePanelId';
import { openSidePanel } from '../view/viewSlice';
import { useSidePanel } from '../view/viewSelectors';
import { MenuItem } from '../../menu/MenuItem';

export interface IImageLayerMenuProps {
  layers: IImageLayer[];
}

export const ImageLayerMenu: React.FC<IImageLayerMenuProps> = ({ layers }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const apiUrl = useTreditionApiUrl();
  const sideMenu = useSidePanel();
  const replaceImageInputRef = useRef<HTMLInputElement>(null);
  const [replaceFile, setReplaceFile] = useState<File | null>(null);
  const [cropTarget, setCropTarget] = useState<string | null>(null);
  const [cropImage, { isLoading: isCroppedImageUploading }] = useCropDesignImageMutation();
  const projectId = useEnsuredProjectId();
  const conKeepAspectRatio = consolidate(layers, (l) => l.keepAspectRatio);
  const singleImageLayer = layers[0] || null;

  const onClickTilingButton = useCallback(() => {
    dispatch(openSidePanel(SidePanelId.ImageTiling));
  }, [dispatch]);

  const onClickKeepAspectRatioButton = useCallback(() => {
    dispatch(
      setImageOptions({ filter: { selected: true }, keepAspectRatio: !conKeepAspectRatio.value }),
    );
  }, [dispatch, conKeepAspectRatio]);

  const onClickCropImageMenuButton = useCallback(() => {
    if (layers.length) {
      setCropTarget(getImageBlobUrlWithoutReplacements(apiUrl, layers[0]));
    }
  }, [apiUrl, layers]);

  const onClickReplaceImageMenuButton = useCallback(() => {
    if (replaceImageInputRef.current) {
      replaceImageInputRef.current.click();
    }
  }, []);

  const onSubmitCropImage = useCallback(
    (cropData: IOnCropData) => {
      if (!layers.length) {
        return;
      }
      const fileId = layers[0].fileId;

      const cropTask = cropImage({ area: cropData.area, projectId, fileId }).unwrap();

      toast
        .promise(cropTask, {
          error: t('CropImageError'),
          loading: t('CropImageLoading'),
          success: t('CropImageSuccess'),
        })
        .then(({ fileId: newFileId }) => {
          const { width, height } = cropData.area;
          dispatch(replaceImage({ fileId, newFileId, width, height }));
          setCropTarget(null);
        })
        .catch(noop);
    },
    [cropImage, dispatch, layers, projectId, t],
  );

  const onChangeReplaceInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      if (![Mime.Jpg, Mime.Png].includes(file.type as Mime)) {
        toast.error(t('ImagePleaseSubmitJpegOrPng'));
        return;
      }
      setReplaceFile(file);
    },
    [t],
  );

  const unsetReplaceFile = useCallback(() => {
    setReplaceFile(null);
  }, []);

  if (layers.length === 0) {
    return null;
  }

  return (
    <LayerMenu layers={layers}>
      <Menu>
        <MenuWrapper>
          {!!singleImageLayer && (
            <MenuItem title={t('UploadImage')} onClick={onClickReplaceImageMenuButton}>
              <Icon name="upload-control" />
              <input
                ref={replaceImageInputRef}
                type="file"
                accept="image/jpeg,image/png"
                style={{ display: 'none' }}
                onChange={onChangeReplaceInput}
              />
            </MenuItem>
          )}
          <MenuItem
            title={t(conKeepAspectRatio.value ? 'Proportional' : 'FreeScale')}
            onClick={onClickKeepAspectRatioButton}
            className={classNames({
              active: conKeepAspectRatio.value,
              mixed: !conKeepAspectRatio.isEqual && conKeepAspectRatio.count > 0,
            })}>
            <Icon name={conKeepAspectRatio.value ? 'schlosszu-control' : 'schlossauf-control'} />
          </MenuItem>
          {layers.length === 1 && (
            <Fragment>
              <MenuItem onClick={onClickCropImageMenuButton} title={t('CropImage')}>
                <Icon name="zuschneiden-control" />
              </MenuItem>
            </Fragment>
          )}
          <MenuItem
            title={t('Repeat')}
            onClick={onClickTilingButton}
            isActive={sideMenu === SidePanelId.ImageTiling}>
            <Icon name="kacheln1-control" />
          </MenuItem>
        </MenuWrapper>
      </Menu>
      {!!replaceFile && !!singleImageLayer && (
        <ImageReplacer
          file={replaceFile}
          fileId={singleImageLayer.fileId}
          layerId={singleImageLayer.id}
          aspect={singleImageLayer.width / singleImageLayer.height || 1}
          projectId={projectId}
          onCancel={unsetReplaceFile}
          onImageReplaced={unsetReplaceFile}
        />
      )}
      {!!cropTarget && (
        <Modal title={t('CropImage')} isOpen onRequestClose={() => setCropTarget(null)}>
          <ImageCropper
            onCrop={onSubmitCropImage}
            imageSrc={cropTarget}
            isSubmitDisabled={isCroppedImageUploading}
          />
        </Modal>
      )}
    </LayerMenu>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  grid-row: 2 / span 1;
  border-right: 1px solid var(--color-border);
`;
