import { AppThunk } from '../../redux/store';
import { zoom } from './viewSlice';
import { getZoom } from './viewSelectors';

/**
 * Adds the given zoom amount to the current zoom factor.
 *
 * Note the zoom limits imposed by the {@link zoomReducer}
 */
export const zoomRelative =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const zoomFactor = getZoom(getState());
    dispatch(zoom(zoomFactor + amount));
  };
