import { AppThunk } from '../../redux/store';
import { treditionApi } from '../../api/treditionApi';
import { getProjectSlice } from '../project/projectSelectors';
import { getDesignerMode } from '../view/viewSelectors';
import { DesignerMode } from '../designerMode';
import { ProjectCompat } from '../project/compat/ProjectCompat';
import { getLanguageCode } from '../../locale/localeSelectors';
import { importProductInternal } from '../project/projectActions';

/**
 * Imports the layers of `srcProductId` into the currently active project (server side) and adjusts
 * their size and position.
 *
 * NOTE: Only implemented for cover designs
 */
export const copyDesign =
  (srcProductId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const mode = getDesignerMode(state);
    const languageCode = getLanguageCode(state);
    const project = getProjectSlice(state);
    if (mode !== DesignerMode.Cover) {
      return;
    }
    if (!project || !project.activeProduct) {
      return;
    }
    const srcProduct = project.products.find(($) => $.id === srcProductId);
    if (!srcProduct) {
      return;
    }
    const designProduct = await dispatch(
      treditionApi.endpoints.copyCoverDesign.initiate({
        projectId: project.id,
        srcProduct: ProjectCompat.designProductToSaveData(srcProduct),
        destProductId: project.activeProduct,
      }),
    ).unwrap();

    const product = ProjectCompat.designProductToState(project, designProduct, languageCode);
    dispatch(importProductInternal(product));
  };
