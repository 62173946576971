import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { getDesignerSlice } from '../designerSelectors';
import { projectSlice } from './projectSlice';
import { IProject } from './ProjectState';
import { getActiveProductId } from './productSelectors';

/**
 * Returns the entire designer's project slice
 */
export const getProjectSlice = (state: RootState) => getDesignerSlice(state)[projectSlice.name];

/**
 * Guarantees a project to be loaded and returns it
 *
 * @throws Error if no project is currently loaded
 */
export const ensureProject = (state: RootState): IProject => {
  const project = getProjectSlice(state);
  if (!project) {
    throw new Error('No project is loaded');
  }
  return project;
};

/**
 * Guarantees a project to be loaded and returns its id
 */
export const ensureProjectId = (state: RootState): string => ensureProject(state).id;

/**
 * Returns the current project id or null if no project is currently opened
 */
export const getCurrentProjectId = (state: RootState) => getProjectSlice(state)?.id || null;

/**
 * Provides the project slice to a React component
 */
export const useProject = () => useAppSelector(getProjectSlice);

/**
 * Provides a guaranteed non-null project to a React component
 *
 * @throws Error if no project is currently loaded
 */
export const useEnsuredProject = () => useAppSelector(ensureProject);

/**
 * Provides the current project id to a React component
 */
export const useProjectId = () => useAppSelector(getCurrentProjectId);

/**
 * Provides the currently active product id to a React component
 */
export const useActiveProductId = () => useAppSelector(getActiveProductId);

/**
 * Provides a guaranteed loaded project id to a React component
 *
 * @throws Error if no project is currently loaded
 */
export const useEnsuredProjectId = () => useAppSelector(ensureProjectId);
