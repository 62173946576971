import { Action, ListenerEffectAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../redux/store';
import { getActiveProduct } from '../../project/productSelectors';
import { getLiveSheetSlice } from '../../layer/liveSheetSelectors';
import { isPointEqual } from '../../../lib/isPointEqual';
import { HistoryRecordType } from '../historySlice';
import { writeHistoryRecord } from '../writeHistoryRecord';
import { TransformationType } from '../../layer/ILiveSheetState';

/**
 * Records {@link HistoryRecordType.LayerMoved},
 *  {@link HistoryRecordType.LayerRotated},
 *  {@link HistoryRecordType.LayerScaled}
 */
export function recordLayerTransform(
  action: Action<string>,
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
): void {
  const { dispatch, getState, getOriginalState } = listenerApi;
  const state = getState();
  const product = getActiveProduct(state);
  if (!product) {
    return;
  }
  const originalState = getOriginalState();
  const originalLayersState = getLiveSheetSlice(originalState);
  const transform = originalLayersState.transform;
  if (!transform) {
    return; // nothing to record because no transform was active
  }
  if (isPointEqual(transform.startPoint, transform.endPoint)) {
    return; // nothing to record because the user only tapped
  }
  switch (transform.type) {
    case TransformationType.Translate:
      dispatch(writeHistoryRecord({ type: HistoryRecordType.LayerMoved }, { throttleMs: 1000 }));
      return;
    case TransformationType.Rotate:
      dispatch(writeHistoryRecord({ type: HistoryRecordType.LayerRotated }));
      return;
    case TransformationType.Scale:
      dispatch(writeHistoryRecord({ type: HistoryRecordType.LayerScaled }));
      return;
  }
}
