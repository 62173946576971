import React from 'react';
import styles from './SanitizedHtmlElement.module.css';
import sanitizeHtml from 'sanitize-html';

export interface ISanitizedHtmlElementProps {
  as?: keyof JSX.IntrinsicElements;
  htmlMessage: string;
}

export const SanitizedHtmlElement: React.FC<ISanitizedHtmlElementProps> = ({
  htmlMessage,
  as = 'span',
}) => {
  const As = as;
  const sanitizedHtml = sanitizeHtml(htmlMessage);
  return <As className={styles.wrapper} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
