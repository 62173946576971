import { FormattingEnumeration } from './Formatting';

export enum BodyTextFormEnumeration {
  FONT_FAMILY = 'font-family',
  FONT_SIZE = 'font-size',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
  TEXT_ALIGN = 'text-align',
  SPACING_AFTER = 'spacing-after-paragraph',
  LINE_HEIGHT = 'line-height',
  FIRST_LINE_INDENTION = 'first-line-indention',
}

export type IBodyTextForm = {
  [BodyTextFormEnumeration.FONT_FAMILY]: string;
  [BodyTextFormEnumeration.FONT_SIZE]: number;
  [BodyTextFormEnumeration.BOLD]: boolean;
  [BodyTextFormEnumeration.ITALIC]: boolean;
  [BodyTextFormEnumeration.UNDERLINED]: boolean;
  [BodyTextFormEnumeration.TEXT_ALIGN]: string;
  [BodyTextFormEnumeration.SPACING_AFTER]: number;
  [BodyTextFormEnumeration.LINE_HEIGHT]: number;
  [BodyTextFormEnumeration.FIRST_LINE_INDENTION]: number;
};

type IBodyTextFormatting = {
  [key in FormattingEnumeration]: string;
};

export const bodyTextFormatting: IBodyTextFormatting = {
  [FormattingEnumeration.FONT_FAMILY]: BodyTextFormEnumeration.FONT_FAMILY,
  [FormattingEnumeration.FONT_SIZE]: BodyTextFormEnumeration.FONT_SIZE,
  [FormattingEnumeration.BOLD]: BodyTextFormEnumeration.BOLD,
  [FormattingEnumeration.ITALIC]: BodyTextFormEnumeration.ITALIC,
  [FormattingEnumeration.UNDERLINED]: BodyTextFormEnumeration.UNDERLINED,
  [FormattingEnumeration.TEXT_ALIGN]: BodyTextFormEnumeration.TEXT_ALIGN,
};
