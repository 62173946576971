import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';

export function toggleScaffoldReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<boolean | undefined>,
) {
  if (!state) {
    return;
  }
  if (typeof action.payload === 'boolean') {
    state.showScaffold = action.payload;
  } else {
    state.showScaffold = !state.showScaffold;
  }
}
