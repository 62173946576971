import { Params } from 'react-router';
import { EditorStepKey } from './EditorStepKey';
import { StepRouteKey } from './getEditorRoute';
import { SegmentByStepKeyMapping } from './getEditorRouteByStep';

export const StepByRouteKeyMapping = Object.entries(SegmentByStepKeyMapping).reduce<
  Record<string, EditorStepKey>
>((acc, [k, v]) => {
  acc[v] = k as EditorStepKey;
  return acc;
}, {});

export const defaultStep = EditorStepKey.Category;

/**
 * Maps raw route params retrieved using `useParams` to an EditorStep.
 * Guarantees to return an editor step and falls back to {@link defaultStep} if no valid step was found.
 */
export const getStepFromRouteParams = (params: Readonly<Params>): EditorStepKey => {
  if (!params || typeof params[StepRouteKey] !== 'string') {
    return defaultStep;
  }
  // hard cast to string required because the type assertion above is somehow not detected by TS
  return StepByRouteKeyMapping[params[StepRouteKey] as unknown as string] || defaultStep;
};
