import validator from '../../validator';

export const createSeriesSchema = () => {
  return validator.object().shape({
    id: validator.string().nullable(),
    number: validator.number().required().integer().positive(),
    title: validator.string().required().min(2).max(200),
    hasEditor: validator.boolean(),
    editorId: validator.string().when('hasEditor', {
      is: true,
      then: () => validator.string().required(),
      otherwise: () => validator.string().strip(),
    }),
  });
};

export const createEditionSchema = () =>
  validator.object().shape({
    number: validator.number().integer().positive().required(),
    date: validator.string().required(),
  });

export const createProjectDataFormSchema = () => {
  const editionSchema = createEditionSchema();
  const seriesSchema = createSeriesSchema();

  return validator.object().shape({
    title: validator.string().min(2).max(200).required(),
    subtitle: validator.string().max(200).notRequired(),
    hasSeries: validator.boolean().required(),
    hasEdition: validator.boolean().required(),
    hasAiGeneratedContent: validator.boolean().required(),
    languages: validator.array().of(validator.string().defined().nonNullable()).min(1).required(),
    series: validator.mixed().when('hasSeries', {
      is: true,
      then: () => seriesSchema.nonNullable(),
      otherwise: () => seriesSchema.strip(),
    }),
    edition: validator.object().when('hasEdition', {
      is: true,
      then: () => editionSchema.nonNullable(),
      otherwise: () => editionSchema.strip(),
    }),
  });
};

export interface ISeriesSchema {
  id: string | null;
  number: number;
  title: string;
  subtitle: string;
  hasEditor: boolean;
  editorId: string | null;
}

export interface IEditionSchema {
  number: number;
  date: Date;
}

export type IProjectDataFormValues = {
  title: string;
  subtitle: string;
  languages: string[];
  hasEdition: boolean | null;
  hasSeries: boolean | null;
  hasAiGeneratedContent: boolean | null;
  edition: IEditionSchema | null;
  series: ISeriesSchema | null;

  // not part of payload - used to prefill series information
  existingSeriesId: string;
};
