import { RootState } from '../../redux/store';
import { getDesignerSlice } from '../designerSelectors';
import { problemSlice } from './problemSlice';
import { IDesignProblem, ProductProblems } from './IDesignerProblem';
import { useAppSelector } from '../../redux/hooks';

const noProblems: IDesignProblem[] = [];

/**
 * Returns the entire designer's problem slice
 */
export const getProblemSlice = (state: RootState) => getDesignerSlice(state)[problemSlice.name];

export const getProblemsByProductId = (state: RootState): ProductProblems =>
  getProblemSlice(state).products;

export const getProblemsByLayerId = (state: RootState, id: string): IDesignProblem[] =>
  getProblemSlice(state).layers[id] || noProblems;

export const getFirstProblemByLayerId = (state: RootState, id: string): IDesignProblem | null => {
  const problemsByLayer = getProblemsByLayerId(state, id);
  if (!problemsByLayer.length) {
    return null;
  }
  return problemsByLayer[0] || null;
};

export const useLayerProblemsById = (id: string) =>
  useAppSelector((s) => getProblemsByLayerId(s, id));

export const useFirstProblemByLayerId = (id: string) =>
  useAppSelector((s) => getFirstProblemByLayerId(s, id));

export const useProblemsByProductId = () => useAppSelector(getProblemsByProductId);
