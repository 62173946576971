import { FC, ReactNode } from 'react';
import styles from './CreateOrUpdateSelectLayout.module.css';
import classNames from 'classnames';
import { noop } from '../../lib/noop';
import { CircleButton } from '../../buttons/circleButton/CircleButton';

export interface ICreateOrUpdateSelectWrapperProps {
  hideSelect: boolean;
  isReadonly: boolean;
  selectComponent: ReactNode;
  buttonLabel: string;
  onNewClick: () => void;
}

export const CreateOrUpdateSelectLayout: FC<ICreateOrUpdateSelectWrapperProps> = ({
  selectComponent,
  hideSelect = false,
  isReadonly = false,
  buttonLabel,
  onNewClick,
}) => {
  return (
    <div className={styles.wrapper}>
      {!hideSelect && <div className={styles.editorOptionsWrapper}>{selectComponent}</div>}
      <div
        className={classNames(styles.addNew, { [styles.disabled]: isReadonly })}
        role={'button'}
        onClick={isReadonly ? noop : onNewClick}>
        <CircleButton
          disabled={isReadonly}
          className={styles.addContributorButton}
          iconName="plus-control"
          labelHidden
          label={buttonLabel}
          onClick={isReadonly ? noop : onNewClick}
        />
        {buttonLabel}
      </div>
    </div>
  );
};
