import { IRGBA } from './Color';

/**
 * Tries to parse an RGBA value from the given hex string.
 *
 * If the hex string does not mention the alpha channel, i.e.
 * the string is only 6 characters long the alpha channel is
 * assumed to be opaque. Ignores the `#` if mentioned and the
 * hex digits will be upper-cased.
 *
 * Returns null if the parsing failed.
 */
export function hexStringToRgba(v: string): IRGBA | null {
  if (typeof v !== 'string' || !v.length) {
    return null;
  }
  if (v.startsWith('#')) {
    v = v.substring(1);
  } else if (v.startsWith('0x')) {
    v = v.substring(2);
  }
  v = v
    .substring(0, 8) // trim it down to max 8 chars
    .padEnd(8, 'FF') // assume opaque if omitted
    .toUpperCase(); // normalize the casing
  const num = Number.parseInt(v, 16);
  if (Number.isNaN(num)) {
    return null;
  }
  return {
    r: ((num >> 24) & 255) / 255,
    g: ((num >> 16) & 255) / 255,
    b: ((num >> 8) & 255) / 255,
    a: (num & 255) / 255,
  };
}
