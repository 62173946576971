import { ITableOfContentForm } from './TableOfContentForm';
import { IHeadlineForm } from './HeadlineForm';
import { IBodyTextForm } from './BodyTextForm';
import { IFooterForm } from './FooterForm';
import { IPageMarginsForm } from './PageMarginsForm';
import { IHeaderForm } from './HeaderForm';

export enum ICSSEnumeration {
  TABLE_OF_CONTENT = 'table-of-content',
  HEADLINE = 'headline',
  HEADLINE2 = 'headline2',
  BODY_TEXT = 'body-text',
  HEADER = 'header',
  FOOTER = 'footer',
  PAGE_MARGINS = 'page-margins',
}

export interface ICSSObject {
  [ICSSEnumeration.TABLE_OF_CONTENT]: ITableOfContentForm;
  [ICSSEnumeration.HEADLINE]: IHeadlineForm;
  [ICSSEnumeration.HEADLINE2]: IHeadlineForm;
  [ICSSEnumeration.BODY_TEXT]: IBodyTextForm;
  [ICSSEnumeration.HEADER]: IHeaderForm;
  [ICSSEnumeration.FOOTER]: IFooterForm;
  [ICSSEnumeration.PAGE_MARGINS]: IPageMarginsForm;
}
