import React from 'react';
import styles from './NavGroup.module.css';
import classNames from 'classnames';
import { noop } from '../lib/noop';

export interface INavGroupProps {
  children: React.ReactNode;
  label: string;
  disabled?: boolean;
  onClick?: () => unknown;
}

export const NavGroup = ({
  children = [],
  label,
  disabled = false,
  onClick = noop,
}: INavGroupProps) => {
  return (
    <li className={classNames(styles.navGroup, { [styles.disabled]: disabled })}>
      <span className={styles.groupName} onClick={disabled ? noop : onClick} role="button">
        {label}
      </span>
      <ul className={styles.subMenu}>{children}</ul>
    </li>
  );
};
