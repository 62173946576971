import React, { CSSProperties, useCallback } from 'react';
import styles from './NumberInput.module.css';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import { Label } from '../label/Label';
import { noop } from '../../lib/noop';
import classNames from 'classnames';

export interface INumberInput {
  inputWidth?: string;
  addendum?: '%' | 'Pixel' | 'Degree' | 'cm' | 'mm' | '';
  label: string;
  labelHidden?: boolean;
  labelPosition?: 'top' | 'bottom' | 'left' | 'right';
  disabled?: boolean;
  hasError?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNumberChange?: (value: number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const NumberInput = ({
  inputWidth = '150px',
  label,
  addendum = '',
  labelHidden = false,
  labelPosition,
  hasError,
  onChange = noop,
  onNumberChange = noop,
  onBlur,
  onFocus,
  value,
  disabled = false,
  ...rest
}: INumberInput) => {
  const { t } = useTranslation();
  const labelPositionStyles = () => {
    switch (labelPosition) {
      case 'top':
        return { flexDirection: 'column-reverse', alignItems: 'flex-start' };
      case 'bottom':
        return { flexDirection: 'column', alignItems: 'flex-start' };
      case 'left':
        return { flexDirection: 'bookFormat-reverse', alignItems: 'center' };
      case 'right':
        return { flexDirection: 'row', alignItems: 'center' };
      default:
        return { flexDirection: 'column', alignItems: 'flex-start' };
    }
  };

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      if (event.target.value === '') {
        onNumberChange(0);
        return;
      }
      const number = Number.parseInt(event.target.value, 10);
      onNumberChange(number);
    },
    [onChange, onNumberChange],
  );

  return (
    <Label style={labelPositionStyles() as CSSProperties} className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <input
          {...rest}
          style={{ width: inputWidth }}
          onFocus={onFocus}
          value={value || ''}
          onChange={onChangeCallback}
          onBlur={onBlur}
          type="number"
          disabled={disabled}
          className={classNames(
            styles.input,
            { [styles.error]: hasError },
            { [styles.withAddendum]: addendum !== '' },
          )}
        />
        {addendum !== '' && <span className={styles.addendum}>{t(addendum)}</span>}
      </div>
      {labelHidden ? (
        <VisuallyHidden>
          <span className={styles.labels}>{label}</span>
        </VisuallyHidden>
      ) : (
        label
      )}
    </Label>
  );
};
