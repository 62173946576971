import React from 'react';
import styled from 'styled-components/macro';

interface IDanger {
  children: React.ReactNode;
  Icon?: SVGComponent;
  type: 'danger' | 'warning' | 'hint';
}

export const AlertBox: React.FC<IDanger> = ({ children, Icon, type }) => {
  const selectType = () => {
    switch (type) {
      case 'danger': {
        return (
          <RedColorBox>
            {Icon && <Icon />}
            {children}
          </RedColorBox>
        );
      }
      case 'warning': {
        return (
          <YellowColorBox>
            {Icon && <Icon />}
            {children}
          </YellowColorBox>
        );
      }
      case 'hint': {
        return (
          <GreenColorBox>
            {Icon && <Icon />}
            {children}
          </GreenColorBox>
        );
      }
    }
  };

  return selectType();
};

const ColorBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  border-radius: var(--border-radius);

  & > svg {
    height: auto;
    min-width: 2rem;
    max-width: 2rem;
  }
`;

const RedColorBox = styled(ColorBox)`
  background-color: var(--color-danger);
`;

const YellowColorBox = styled(ColorBox)`
  background-color: var(--color-warning);
`;

const GreenColorBox = styled(ColorBox)`
  background-color: var(--color-cta-light);
`;
