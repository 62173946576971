import { createApi } from '@reduxjs/toolkit/query/react';
import { ISaveTreditionPalette, ITreditionPalette } from '../designer/palette/TreditionPalette';
import {
  IDesignProject,
  ISaveDesignProduct,
  ISaveDesignProject,
} from '../designer/project/IDesignProject';
import { IOption, IOptionGroup } from '../../@types/IOption';
import { DesignerMode } from '../designer/designerMode';
import { baseQuery } from './baseQuery';
import { DesignerImageSource } from '../designer/drag/dragSlice';
import { ICropArea } from '../crop/ImageCropper';
import {
  IDesignTemplateSearchFilter,
  IDesignTemplateSearchResult,
} from '../designer/templates/IDesignerTemplateState';
import { IDesignProduct } from '../designer/project/IDesignProduct';
import { ITagList } from '../designer/project/ITagList';
import { IApiProjectEditorMetadata } from './IApiProjectEditorMetadata';
import { IGenreGroup } from '../editor/GenreGroups';
import { IBookFormatResponse } from '../editor/format/interfaces/IBookFormatResponse';
import { IFormatPageRequest } from '../editor/format/interfaces/IFormatPageRequest';
import { IFormatPageResponse } from '../editor/format/interfaces/IFormatPageResponse';
import { IRawFormatResponse } from '../editor/format/interfaces/IRawFormatResponse';
import { IUserContributor } from '../editor/contributors/interfaces/IUserContributor';
import { IProjectContributor } from '../editor/contributors/interfaces/IProjectContributor';
import { IAddContributorPayload } from '../editor/contributors/interfaces/IAddContributorPayload';
import {
  IApiProjectData,
  IApiProjectDataSeries,
  IApiProjectDataSeriesOptions,
} from '../editor/projectData/ProjectDataForm.transform';
import { ProductType } from '../editor/format/constants/ProductType';
import { ICreatePublisherPayload } from '../editor/isbnImprint/interfaces/ICreatePublisherPayload';
import { IImprintRole } from '../editor/isbnImprint/interfaces/IImprintRole';
import { IPublisherRole } from '../editor/isbnImprint/interfaces/IPublisherRole';
import { IIsbnPageData } from '../editor/isbnImprint/interfaces/IIsbnPageData';
import { IIsbnFormData } from '../editor/isbnImprint/IsbnSchema';
import { IRegistration } from '../account/register/RegistrationPage';
import { transformApiUser } from '../user/userApi';
import { IUser } from '../user/IUserState';

export type IDesignerImagesApiResponse = {
  /**
   * A link to a thumbnail version of the image
   */
  thumbnail: string;

  /**
   * A link to the original version of the image
   */
  image: string;
}[];

export interface ILoadImageIntoProjectApiResponse {
  fileId: string;
}

export interface IGetUploadedImagesListEntry {
  /**
   * Id of the file (which is the file name in its blob container)
   */
  fileId: string;

  /**
   * Absolute url to display the image
   */
  fileUrl: string;
}

export interface ILoadImageIntoProjectData {
  projectId: string;
  fileId: string;
  source: DesignerImageSource;
}

export interface ISearchDesignTemplatesQueryArg {
  filter: IDesignTemplateSearchFilter;
  mode: DesignerMode;
}

export interface ICreatePaletteFromFileArg {
  image: File;
  area: ICropArea;
}

export interface IApplyCoverTemplateArg {
  projectId: string;
  productId: string;
  templateId: string;
}

export interface ICropDesignImageArg {
  projectId: string;
  fileId: string;
  area: ICropArea;
}

export interface IReplaceDesignImageArg extends ICropDesignImageArg {
  image: File;
}

export interface ISaveCoverDesignAsTemplateArg {
  productId: string;
  projectId: string;
  name: string;
  products: ISaveDesignProduct[];
}

export interface ISvgInfo {
  /**
   * A list of hex strings of all identified `fill` colors in the svg
   */
  fillColors: string[];

  /**
   * A list of hex strings of all identified `stroke` colors in the svg
   */
  strokeColors: string[];
}

export interface IRegionOption extends IOption<string> {
  /**
   * Currency code of the region
   */
  currency: string;
}

export interface IPixabayFilter {
  /**
   * Zero-based page index
   */
  page: number;

  /**
   * A search term to categorize images
   */
  searchTerm?: string;

  /**
   * A color string ('red', 'transparent', ...) to filter images by color
   */
  color?: string;

  /**
   * Image orientation. When undefined, both horizontal and vertical will be returned
   */
  orientation?: PixabayOrientation;
}

export enum PixabayOrientation {
  Horizontal = 2,
  Vertical = 1,
}

export enum CacheTag {
  ColorPalette = 'ColorPalette',
  BookFormat = 'BookFormat',
  GenreOptions = 'GenreOptions',
  RegionOptions = 'RegionOptions',
  GenreGroups = 'GenreGroups',
  TagOptions = 'TagOptions',
  DesignPatterns = 'DesignPatterns',
  DesignUploads = 'DesignUploads',
  DesignTemplateSearchResults = 'DesignTemplateSearchResults',
  EditorMetadata = 'EditorMetadata',

  BookTypeAndFormat = 'BookTypeAndFormat',

  UserContributors = 'UserContributors',
  ProjectContributors = 'ProjectContributors',
  UserPublisherProfiles = 'UserPublisherProfiles',
  UserImprintProfiles = 'UserPublisherProfiles',
  IsbnData = 'IsbnData',
}

export const treditionApi = createApi({
  reducerPath: 'treditionApi',
  baseQuery,
  tagTypes: [
    CacheTag.ColorPalette,
    CacheTag.GenreOptions,
    CacheTag.RegionOptions,
    CacheTag.GenreGroups,
    CacheTag.TagOptions,
    CacheTag.DesignPatterns,
    CacheTag.DesignUploads,
    CacheTag.DesignTemplateSearchResults,
    CacheTag.BookFormat,
    CacheTag.EditorMetadata,
    CacheTag.BookTypeAndFormat,
    CacheTag.UserContributors,
    CacheTag.ProjectContributors,
    CacheTag.UserImprintProfiles,
    CacheTag.UserPublisherProfiles,
    CacheTag.IsbnData,
  ],
  endpoints: (builder) => ({
    getProjectEditorMetadata: builder.query<IApiProjectEditorMetadata, string>({
      query: (projectId) => ({ url: `projects/${projectId}/editorMetadata` }),
      providesTags: [CacheTag.EditorMetadata],
      keepUnusedDataFor: 0,
    }),
    applyCoverTemplate: builder.mutation<IDesignProduct, IApplyCoverTemplateArg>({
      query: ({ projectId, productId, templateId }) => ({
        url: `projects/${projectId}/cover/applyTemplate`,
        method: 'POST',
        body: { templateId, productId },
      }),
    }),
    deletePalette: builder.mutation<ITreditionPalette, string>({
      query: (id) => ({ url: `colorPalettes/${id}`, method: 'DELETE' }),
      invalidatesTags: [CacheTag.ColorPalette],
    }),
    deleteCoverTemplate: builder.mutation<void, string>({
      query: (arg) => ({
        url: `coverTemplates/${arg}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTag.DesignTemplateSearchResults],
    }),
    getDesignerImages: builder.query<IDesignerImagesApiResponse, IPixabayFilter>({
      query: (filter) => {
        const query = new URLSearchParams();
        query.set('page', filter.page.toString());
        if (filter.color) {
          query.append('color', filter.color);
        }
        if (filter.searchTerm) {
          query.append('searchTerm', filter.searchTerm);
        }
        if (filter.orientation) {
          query.append('orientation', filter.orientation.toString());
        }
        return `designImages?${query}`;
      },
    }),
    getCoverDesign: builder.query<IDesignProject, { projectId: string }>({
      query: ({ projectId }) => `projects/${projectId}/cover`,
    }),
    getCountries: builder.query<{ value: string; label: string }[], void>({
      query: () => 'countries',
    }),
    getOrganizations: builder.query<{ value: string; label: string }[], void>({
      query: () => 'organizations',
    }),
    getUserImprintProfiles: builder.query<IImprintRole[], { projectId: string }>({
      query: ({ projectId }) => `publisherprofiles/${projectId}/imprint/allProfilesOnUser`,
      providesTags: [CacheTag.UserImprintProfiles],
    }),
    getUserPublisherProfiles: builder.query<IPublisherRole[], { projectId: string }>({
      query: ({ projectId }) => `publisherprofiles/${projectId}/publisher/allProfilesOnUser`,
      providesTags: [CacheTag.UserPublisherProfiles],
    }),
    addProjectPublisherProfile: builder.mutation<
      string,
      { projectId: string; publisher: ICreatePublisherPayload }
    >({
      query: ({ projectId, publisher }) => ({
        url: `publisherprofiles/${projectId}/publisher`,
        body: publisher,
        method: 'POST',
      }),
      invalidatesTags: [CacheTag.UserPublisherProfiles],
    }),
    addProjectImprintProfile: builder.mutation<
      string,
      { projectId: string; imprintRole: { name: string } }
    >({
      query: ({ projectId, imprintRole }) => ({
        url: `publisherprofiles/${projectId}/imprint`,
        body: imprintRole,
        method: 'POST',
      }),
      invalidatesTags: [CacheTag.UserImprintProfiles],
    }),
    getIsbnPageData: builder.query<IIsbnPageData, { projectId: string }>({
      query: ({ projectId }) => `isbnData/${projectId}`,
      providesTags: [CacheTag.IsbnData],
      keepUnusedDataFor: 0,
    }),
    updateIsbnPageData: builder.mutation<IIsbnPageData, { projectId: string; data: IIsbnFormData }>(
      {
        query: ({ projectId, data }) => ({
          url: `isbnData/${projectId}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [CacheTag.IsbnData],
      },
    ),
    getBookFormats: builder.query<IBookFormatResponse, void>({
      query: () => `formats`,
      providesTags: [CacheTag.BookFormat],
    }),
    getFrontMatterDesign: builder.query<IDesignProject, { projectId: string }>({
      query: ({ projectId }) => `projects/${projectId}/frontMatter`,
    }),
    getGenreOptions: builder.query<IOptionGroup<string>[], void>({
      query: () => 'genreOptions',
      providesTags: [CacheTag.GenreOptions],
    }),
    getGenreGroups: builder.query<IGenreGroup[], void>({
      query: () => 'genreOptions/genreGroups',
      providesTags: [CacheTag.GenreGroups],
    }),
    getProjectGenre: builder.query<string, { projectId: string }>({
      query: ({ projectId }) => `genre/${projectId}`,
      keepUnusedDataFor: 0,
    }),
    updateProjectGenre: builder.mutation<string, { projectId: string; genreId: string }>({
      query: ({ genreId, projectId }) => ({
        url: `genre/${projectId}`,
        method: 'PUT',
        body: {
          genreId,
        },
      }),
      invalidatesTags: [CacheTag.EditorMetadata],
    }),
    getRegionOptions: builder.query<IRegionOption[], void>({
      query: () => 'region',
      keepUnusedDataFor: 30,
      providesTags: [CacheTag.RegionOptions],
    }),
    getLanguageResources: builder.query<Record<string, string>, string>({
      query: (languageCode) => `languageResources/${languageCode}`, // language is determined on X-LANG header (see baseQuery.ts},
    }),
    getDesignerPatterns: builder.query<string[], void>({
      query: () => 'designPatterns',
      providesTags: [CacheTag.DesignPatterns],
    }),
    getDesignerPatternsFilterByTagId: builder.query<string[], { tagId: string }>({
      query: ({ tagId }) => `designPatterns/filterByTags/${tagId}`,
      providesTags: [CacheTag.DesignPatterns],
      keepUnusedDataFor: 30,
    }),
    getTagsForArea: builder.query<ITagList, { area: number }>({
      query: (payload) => `tags/area/${payload.area}`,
    }),
    getPalettesSharedWithMe: builder.query<ITreditionPalette[], void>({
      query: () => 'colorPalettes/shared',
      providesTags: [CacheTag.ColorPalette],
    }),
    getSvgInfo: builder.query<ISvgInfo, { projectId: string; fileId: string }>({
      query: (arg) => `designImages/${arg.projectId}/svg/${arg.fileId}`,
      keepUnusedDataFor: 0,
    }),
    getTagOptions: builder.query<IOptionGroup<string>[], { area: number }>({
      query: (payload) => `tagOptions?area=${payload.area}`,
      providesTags: [CacheTag.TagOptions],
    }),
    getUploadedDesignerImages: builder.query<IGetUploadedImagesListEntry[], string>({
      query: (projectId) => `designImages/${projectId}`,
      keepUnusedDataFor: 0,
      providesTags: [CacheTag.DesignUploads],
    }),
    copyCoverDesign: builder.mutation<
      IDesignProduct,
      { projectId: string; srcProduct: ISaveDesignProduct; destProductId: string }
    >({
      query: (arg) => ({
        url: `projects/${arg.projectId}/cover/copyDesign`,
        method: 'POST',
        body: {
          srcProduct: arg.srcProduct,
          destProductId: arg.destProductId,
        },
      }),
    }),
    loadImageIntoProject: builder.mutation<
      ILoadImageIntoProjectApiResponse,
      ILoadImageIntoProjectData
    >({
      query: (payload) => ({
        url: 'designImages',
        method: 'POST',
        body: payload,
      }),
    }),
    renameCoverTemplate: builder.mutation<void, { id: string; name: string }>({
      query: (arg) => ({
        url: `coverTemplates/${arg.id}/rename`,
        method: 'POST',
        body: { name: arg.name },
      }),
      invalidatesTags: [CacheTag.DesignTemplateSearchResults],
    }),
    resetCoverTemplate: builder.mutation<IDesignProject, string>({
      query: (projectId: string) => ({
        url: `projects/${projectId}/cover/reset`,
        method: 'POST',
      }),
    }),
    cropDesignImage: builder.mutation<{ fileId: string }, ICropDesignImageArg>({
      query: (arg) => ({
        url: `designImages/${arg.projectId}/crop`,
        method: 'POST',
        body: {
          shape: arg.area.type === 'round' ? 1 : 0,
          width: arg.area.width,
          height: arg.area.height,
          leftOffset: arg.area.x,
          topOffset: arg.area.y,
          imageFileId: arg.fileId,
        },
      }),
    }),
    replaceDesignImage: builder.mutation<{ fileId: string }, IReplaceDesignImageArg>({
      query: (arg) => {
        const body = new FormData();
        body.set('shape', arg.area.type === 'round' ? '1' : '0');
        body.set('width', arg.area.width.toString());
        body.set('height', arg.area.height.toString());
        body.set('leftOffset', arg.area.x.toString());
        body.set('topOffset', arg.area.y.toString());
        body.set('imageFileId', arg.fileId);
        body.set('image', arg.image);

        return {
          url: `designImages/${arg.projectId}/replace`,
          method: 'POST',
          body,
        };
      },
    }),
    createPaletteFromImage: builder.mutation<ITreditionPalette, ICreatePaletteFromFileArg>({
      query: (arg) => {
        const body = new FormData();
        body.append('image', arg.image);
        body.append('shape', arg.area.type === 'round' ? '1' : '0');
        body.append('width', arg.area.width.toString());
        body.append('height', arg.area.height.toString());
        body.append('leftOffset', arg.area.x.toString());
        body.append('topOffset', arg.area.y.toString());

        return {
          url: 'colorPalettes/fromImage',
          method: 'POST',
          body,
        };
      },
    }),
    getAllUserContributors: builder.query<IUserContributor[], { projectId: string }>({
      query: (arg) => `contributors/${arg.projectId}/allProfilesOnUser`,
      providesTags: [CacheTag.UserContributors],
      keepUnusedDataFor: 0,
    }),
    getProjectContributors: builder.query<IProjectContributor[], { projectId: string }>({
      query: (arg) => `contributors/${arg.projectId}`,
      providesTags: [CacheTag.ProjectContributors],
    }),
    addProjectContributor: builder.mutation<IUserContributor, IAddContributorPayload>({
      query: ({ projectId, contributor }) => {
        return {
          url: `contributors/${projectId}/addProjectContributor`,
          method: 'POST',
          body: contributor,
        };
      },
      invalidatesTags: [
        CacheTag.ProjectContributors,
        CacheTag.UserContributors,
        CacheTag.EditorMetadata,
      ],
    }),
    linkProjectContributor: builder.mutation<void, { projectId: string; contributorId: string }>({
      query: ({ projectId, contributorId }) => {
        return {
          url: `contributors/${projectId}/link/${contributorId}`,
          method: 'POST',
        };
      },
      invalidatesTags: [CacheTag.ProjectContributors, CacheTag.EditorMetadata],
    }),
    linkProjectContributors: builder.mutation<
      void,
      { projectId: string; contributorIds: string[] }
    >({
      query: ({ projectId, contributorIds }) => {
        return {
          url: `contributors/${projectId}/link`,
          method: 'POST',
          body: contributorIds,
        };
      },
      invalidatesTags: [CacheTag.ProjectContributors, CacheTag.EditorMetadata],
    }),
    unlinkProjectContributor: builder.mutation<void, { projectId: string; contributorId: string }>({
      query: ({ projectId, contributorId }) => {
        return {
          url: `contributors/${projectId}/unlink/${contributorId}`,
          method: 'POST',
        };
      },
      invalidatesTags: [CacheTag.ProjectContributors, CacheTag.EditorMetadata],
    }),
    getFormatAndType: builder.query<IRawFormatResponse, { projectId: string }>({
      query: (data) => {
        return `book-type-and-format/${data.projectId}`;
      },
      providesTags: [CacheTag.BookTypeAndFormat],
    }),
    saveFormatAndType: builder.mutation<IFormatPageResponse, IFormatPageRequest>({
      query: (data) => {
        return { url: 'book-type-and-format', method: 'POST', body: data };
      },
      invalidatesTags: [CacheTag.BookTypeAndFormat, CacheTag.EditorMetadata],
    }),
    savePalette: builder.mutation<ITreditionPalette, ISaveTreditionPalette>({
      query: (data) => {
        if (data.id) {
          return { url: `colorPalettes/${data.id}`, method: 'PUT', body: data };
        }
        return { url: 'colorPalettes', method: 'POST', body: data };
      },
      invalidatesTags: [CacheTag.ColorPalette],
    }),
    saveFrontMatterDesign: builder.mutation<
      IDesignProject,
      { projectId: string; data: ISaveDesignProject }
    >({
      query: ({ projectId, data }) => ({
        url: `projects/${projectId}/frontMatter/save`,
        method: 'POST',
        body: data,
      }),
    }),
    saveCoverDesign: builder.mutation<
      IDesignProject,
      { projectId: string; data: ISaveDesignProject }
    >({
      query: ({ projectId, data }) => ({
        url: `projects/${projectId}/cover/save`,
        method: 'POST',
        body: data,
      }),
    }),
    searchDesignTemplates: builder.query<
      IDesignTemplateSearchResult[],
      ISearchDesignTemplatesQueryArg
    >({
      query: ({ filter, mode }) => ({
        url: `${mode === DesignerMode.FrontMatter ? 'frontMatter' : 'cover'}Templates/search`,
        body: filter,
        method: 'POST',
      }),
      keepUnusedDataFor: 0,
      providesTags: [CacheTag.DesignTemplateSearchResults],
    }),
    deleteDesignImage: builder.mutation<void, { projectId: string; fileId: string }>({
      query: (arg) => ({
        url: `designImages/${arg.projectId}/${arg.fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTag.DesignUploads],
    }),
    saveCoverAsTemplate: builder.mutation<void, ISaveCoverDesignAsTemplateArg>({
      query: (arg) => ({
        url: `coverTemplates`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: [CacheTag.DesignTemplateSearchResults],
    }),
    uploadDesignImage: builder.mutation<
      IGetUploadedImagesListEntry[],
      { file: File; projectId: string }
    >({
      query: ({ file, projectId }) => {
        const formData = new FormData();
        formData.set('file', file, file.name);
        return {
          url: `designImages/${projectId}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [CacheTag.DesignUploads],
    }),
    setRegion: builder.mutation<void, string>({
      query: (regionId) => ({
        url: `region/setRegion`,
        method: 'POST',
        body: { regionId },
      }),
    }),
    listIsbns: builder.query<Record<string, { isbn: string; bookType: ProductType }>, string>({
      query: (arg) => `/projects/${arg}/isbns`,
    }),
    reserveIsbns: builder.mutation<IsbnByProductId[], string>({
      query: (projectId) => {
        return {
          url: `/projects/${projectId}/reserveIsbns`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: [CacheTag.IsbnData],
    }),
    provideIsbns: builder.mutation<void, IProvideIsbnsArg>({
      query: (arg) => {
        return {
          url: `/projects/${arg.projectId}/provideIsbns`,
          method: 'POST',
          body: arg.isbnByProductId,
        };
      },
      invalidatesTags: [CacheTag.EditorMetadata],
    }),
    createUser: builder.mutation<void, { data: IRegistration }>({
      query: (arg) => {
        return {
          url: `/userregistration`,
          method: 'POST',
          body: arg.data,
        };
      },
    }),
    getProjectLanguageOptions: builder.query<IOption<string>[], string>({
      query: (projectId) => `/projectLanguageOptions?projectId=${projectId}`,
    }),
    getSeries: builder.query<IApiProjectDataSeriesOptions[], string>({
      query: (projectId) => `/projects/${projectId}/series`,
      keepUnusedDataFor: 0,
    }),
    getSeriesEditorOptions: builder.query<IOption<string>[], string>({
      query: (projectId) => `/projects/${projectId}/seriesEditorOptions`,
      keepUnusedDataFor: 0,
    }),
    createSeriesEditor: builder.mutation<
      string,
      { projectId: string; editor: ICreateSeriesEditor }
    >({
      query: (arg) => {
        return {
          url: `/projects/${arg.projectId}/seriesEditor`,
          method: 'POST',
          body: arg.editor,
        };
      },
    }),
    getProjectData: builder.query<IApiProjectData, string>({
      query: (arg: string) => `/projects/${arg}/projectData`,
      keepUnusedDataFor: 0,
    }),
    createProject: builder.mutation<string, void>({
      query: () => {
        return {
          url: `/projects`,
          method: 'POST',
        };
      },
    }),
    setProjectData: builder.mutation<void, { projectId: string; data: IApiProjectData }>({
      query: (arg) => {
        return {
          url: `/projects/${arg.projectId}/projectData`,
          method: 'PUT',
          body: arg.data,
        };
      },
      invalidatesTags: [CacheTag.EditorMetadata],
    }),
    listProjects: builder.query<IListProjectsResult[], IListProjectsFilter>({
      query(arg) {
        return {
          url: `projects/list`,
          method: 'POST',
          body: arg,
        };
      },
      keepUnusedDataFor: 5,
    }),
    getImplicitUser: builder.query<IUser | null, void>({
      query: () => `/users/me`,
      transformResponse: transformApiUser,
    }),
  }),
});

export interface ICreateSeriesEditor {
  isCompany: boolean;
  title: string;
  firstName: string;
  lastName: string;
  companyName: string;
  website: string;
}

export interface IListProjectsResult {
  /**
   * Id of the project
   */
  id: string;

  /**
   * Date of last modification
   */
  updatedAt: string;

  /**
   * Title of the book. May be null or empty
   */
  title: string | null;

  /**
   * Subtitle of the book. May be null or empty
   */
  subtitle: string | null;

  /**
   * Version of this project. Only the last version will be returned by the server
   */
  version: number;

  /**
   * Absolute url to the cover image. May be null if no cover has been created yet
   */
  coverUrl: string | null;

  /**
   * Status of the project. See backend CodeSource 3000
   */
  status: string;
}

export enum ListProjectsOrder {
  Title = 'title',
  Date = 'date',
}

export interface IListProjectsFilter {
  /**
   * Page number, starting at 0. Currently unused by the frontend.
   *
   * Note that the server will provide 100 results at most.
   */
  page: number;

  /**
   * Whether to order by title asc or modification date desc
   */
  order: ListProjectsOrder;
}

export interface IIsbnListEntry {
  productId: string;
  isbn: string;
}

export type IsbnByProductId = {
  bookType: Exclude<ProductType, ProductType.None>;
  isbn: string;
  productId: string;
};

export interface IProvideIsbnsArg {
  projectId: string;
  isbnByProductId: IsbnByProductId;
}

export const {
  useApplyCoverTemplateMutation,
  useCreatePaletteFromImageMutation,
  useDeletePaletteMutation,
  useGetSvgInfoQuery,
  useGetCoverDesignQuery,
  useGetFrontMatterDesignQuery,
  useGetDesignerImagesQuery,
  useGetDesignerPatternsFilterByTagIdQuery,
  useGetTagsForAreaQuery,
  useGetDesignerPatternsQuery,
  useGetGenreOptionsQuery,
  useGetRegionOptionsQuery,
  useGetGenreGroupsQuery,
  useGetProjectGenreQuery,
  useUpdateProjectGenreMutation,
  useGetPalettesSharedWithMeQuery,
  useGetTagOptionsQuery,
  useGetUploadedDesignerImagesQuery,
  useLoadImageIntoProjectMutation,
  useResetCoverTemplateMutation,
  useSavePaletteMutation,
  useSaveCoverAsTemplateMutation,
  useRenameCoverTemplateMutation,
  useDeleteCoverTemplateMutation,
  useSearchDesignTemplatesQuery,
  useDeleteDesignImageMutation,
  useCropDesignImageMutation,
  useReplaceDesignImageMutation,
  useGetProjectEditorMetadataQuery,
  useSetRegionMutation,
  useReserveIsbnsMutation,
  useCreateUserMutation,
  useListProjectsQuery,
  useCreateProjectMutation,
  useListIsbnsQuery,
  useGetBookFormatsQuery,
  useSaveFormatAndTypeMutation,
  useGetFormatAndTypeQuery,
  useGetAllUserContributorsQuery,
  useGetProjectContributorsQuery,
  useGetProjectLanguageOptionsQuery,
  useGetProjectDataQuery,
  useSetProjectDataMutation,
  useAddProjectContributorMutation,
  useUnlinkProjectContributorMutation,
  useLinkProjectContributorMutation,
  useLinkProjectContributorsMutation,
  useGetCountriesQuery,
  useGetOrganizationsQuery,
  useAddProjectPublisherProfileMutation,
  useAddProjectImprintProfileMutation,
  useGetUserImprintProfilesQuery,
  useGetUserPublisherProfilesQuery,
  useGetIsbnPageDataQuery,
  useUpdateIsbnPageDataMutation,
  useGetSeriesQuery,
  useGetSeriesEditorOptionsQuery,
  useCreateSeriesEditorMutation,
  useGetImplicitUserQuery,
} = treditionApi;
