import { FC, useCallback, useState } from 'react';
import { Button } from '../../uiComponents/Button';
import { Modal } from '../../modal/Modal';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { noop } from '../../lib/noop';
import { toast } from 'react-hot-toast';

export interface IImagePreviewProps {
  /**
   * Absolute url to the image, suitable for an img tag
   */
  imageSrc: string;

  /**
   * Invoked when the image preview should be closed
   */
  onRequestClose: () => void;

  /**
   * Invoked when the clicks the delete button which will only be displayed if this prop is set
   */
  onRequestDelete?: () => Promise<unknown>;
}

// TODO: competent styling

export const ImagePreview: FC<IImagePreviewProps> = ({
  imageSrc,
  onRequestDelete,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onClickDelete = useCallback(() => {
    if (typeof onRequestDelete !== 'function') {
      return;
    }
    setIsDeleting(true);
    toast
      .promise(onRequestDelete(), {
        error: t('DeleteImageError'),
        loading: t('DeleteImageLoading'),
        success: t('DeleteImageSuccess'),
      })
      .finally(() => setIsDeleting(false))
      .catch(noop);
  }, [onRequestDelete, t]);

  return (
    <Modal title={t('Preview')} isOpen onRequestClose={onRequestClose}>
      <div style={{ width: '80vw', height: '80vh' }}>
        <div
          style={{
            background: `url(${imageSrc}) no-repeat scroll center center`,
            backgroundSize: 'contain',
            margin: '2rem',
            height: '100%',
          }}></div>
      </div>
      {!!onRequestDelete && (
        <Button onClick={onClickDelete} disabled={isDeleting}>
          <Icon name="loeschen" />
        </Button>
      )}
    </Modal>
  );
};
