import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPoint } from '../../../@types/IPoint';
import { pointDirection } from '../../lib/pointDirection';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { rotatePointDeg } from '../../lib/rotatePointDeg';
import { calculateRotationShift } from './calculateRotationShift';
import {
  ILiveSheetState,
  IRotateTransform,
  IScaleTransform,
  ITranslateTransform,
  TransformationType,
} from './ILiveSheetState';
import { getSnappedLayerPosition } from './getSnappedLayerPosition';
import { IDesignerInputState } from '../input/IDesignerInputState';
import { BoundingBoxAnchor } from './BoundingBoxAnchor';

export function applyInputToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IDesignerInputState>,
) {
  if (!state.transform) {
    return;
  }
  // first update transform struct
  state.transform.endPoint = action.payload.mouse.sheet;
  // then calculate display data based on the updated transform struct
  switch (state.transform.type) {
    case TransformationType.Rotate:
      return rotateLayers(state, action);
    case TransformationType.Scale:
      return scaleLayers(state, action);
    case TransformationType.Translate:
      return translateLayers(state, action);
    default:
      return;
  }
}

function translateLayers(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IDesignerInputState>,
) {
  const { startPoint, endPoint, rulers, grabbedLayerId, grid } =
    state.transform as ITranslateTransform;

  const primaryLayer = state.layers.find(($) => $.id === grabbedLayerId);
  if (!primaryLayer) {
    return;
  }
  const oldPosition: IPoint = {
    x: primaryLayer.x,
    y: primaryLayer.y,
  };
  let newPosition: IPoint = {
    x: oldPosition.x + (endPoint.x - startPoint.x),
    y: oldPosition.y + (endPoint.y - startPoint.y),
  };
  // snapping
  if (!action.payload.keyboard.shift) {
    if (grid.width > 1 || grid.height > 1) {
      newPosition.x -= newPosition.x % grid.width;
      newPosition.y -= newPosition.y % grid.height;
    } else {
      newPosition = getSnappedLayerPosition(
        newPosition,
        { width: primaryLayer.display.width, height: primaryLayer.display.height },
        primaryLayer.transform.rotate,
        rulers,
      );
    }
  }
  // calculate translation vector to the primary grabbed layer
  const delta = {
    x: newPosition.x - oldPosition.x,
    y: newPosition.y - oldPosition.y,
  };
  // translate all selected layer by that amount
  state.layers.forEach((layer) => {
    if (!layer.isSelected) {
      return;
    }
    layer.display.x = layer.x + delta.x;
    layer.display.y = layer.y + delta.y;
  });
}

function rotateLayers(state: Draft<ILiveSheetState>, action: PayloadAction<IDesignerInputState>) {
  const transform = state.transform as IRotateTransform;
  let degrees = pointDirection(transform.centerPoint, state.transform!.endPoint);

  if (action.payload.keyboard.shift) {
    degrees -= degrees % 5;
  }
  state.layers.forEach((layer) => {
    if (!layer.isSelected || !layer.isRotatable) {
      return;
    }
    layer.display.rotation = degrees;
  });
}

function scaleLayers(state: Draft<ILiveSheetState>, action: PayloadAction<IDesignerInputState>) {
  const transform = state.transform as IScaleTransform;
  const layer = lookupLayerByIdReducer(state, transform.grabbedLayerId);
  if (!layer || !layer.isScalable) {
    return;
  }
  const oldPos: IPoint = { x: layer.display.x, y: layer.display.y };
  const oldPivot = {
    x: layer.display.x + layer.display.width / 2,
    y: layer.display.y + layer.display.height / 2,
  };
  // shift the mouse position to where it would be if the layer was not rotated. note that the layer data is
  // not rotated by itself, so it is easier to calculate the drag distance vector that way
  const pMouseUnrot = layer.display.rotation
    ? rotatePointDeg(action.payload.mouse.sheet, oldPivot, -layer.display.rotation)
    : action.payload.mouse.sheet;

  const keepAspectRatio = Boolean(layer.keepAspectRatio || action.payload.keyboard.shift);
  const aspectRatio = layer.width / layer.height;
  // depending on the anchor, the scale axes will be constrained
  switch (transform.anchor) {
    case BoundingBoxAnchor.Left:
      layer.display.width = layer.display.width - pMouseUnrot.x + layer.display.x;
      layer.display.x = pMouseUnrot.x;
      if (keepAspectRatio) {
        layer.display.height = layer.display.width / aspectRatio;
      } else {
        layer.display.height = layer.height;
      }
      break;
    case BoundingBoxAnchor.Right:
      layer.display.width = pMouseUnrot.x - layer.display.x;
      if (keepAspectRatio) {
        layer.display.height = layer.display.width / aspectRatio;
      } else {
        layer.display.height = layer.height;
      }
      break;
    case BoundingBoxAnchor.Bottom:
      layer.display.height = pMouseUnrot.y - layer.display.y;
      if (keepAspectRatio) {
        layer.display.width = layer.display.height * aspectRatio;
      } else {
        layer.display.width = layer.width;
      }
      break;
    case BoundingBoxAnchor.Top:
      layer.display.height = layer.display.height - pMouseUnrot.y + layer.display.y;
      layer.display.y = pMouseUnrot.y;
      if (keepAspectRatio) {
        layer.display.width = layer.display.height * aspectRatio;
      } else {
        layer.display.width = layer.width;
      }
      break;
    case BoundingBoxAnchor.BottomRight:
      layer.display.width = pMouseUnrot.x - layer.display.x;
      if (keepAspectRatio) {
        layer.display.height = layer.display.width / aspectRatio;
      } else {
        layer.display.height = pMouseUnrot.y - layer.display.y;
      }
      break;
    case BoundingBoxAnchor.TopRight:
      layer.display.height = layer.display.height - pMouseUnrot.y + layer.display.y;
      layer.display.y = pMouseUnrot.y;
      if (keepAspectRatio) {
        layer.display.width = layer.display.height * aspectRatio;
      } else {
        layer.display.width = pMouseUnrot.x - layer.display.x;
      }
      break;
    case BoundingBoxAnchor.TopLeft:
      layer.display.width = layer.display.width - pMouseUnrot.x + layer.display.x;
      layer.display.x = pMouseUnrot.x;
      if (keepAspectRatio) {
        const oldHeight = layer.display.height;
        layer.display.height = layer.display.width / aspectRatio;
        layer.display.y -= layer.display.height - oldHeight;
      } else {
        layer.display.height = layer.display.height - pMouseUnrot.y + layer.display.y;
        layer.display.y = pMouseUnrot.y;
      }
      break;
    case BoundingBoxAnchor.BottomLeft:
      layer.display.width = layer.display.width - pMouseUnrot.x + layer.display.x;
      layer.display.x = pMouseUnrot.x;
      if (keepAspectRatio) {
        layer.display.height = layer.display.width / aspectRatio;
      } else {
        layer.display.height = pMouseUnrot.y - layer.display.y;
      }
      break;
  }

  layer.display.width = Math.max(1, layer.display.width);
  layer.display.height = Math.max(1, layer.display.height);

  if (layer.display.rotation) {
    // scaling a rotated layer requires extra steps (see calculateRotationShift)
    const newPos = { x: layer.display.x, y: layer.display.y };
    const newPivot = {
      x: layer.display.x + layer.display.width / 2,
      y: layer.display.y + layer.display.height / 2,
    };
    const shift = calculateRotationShift(
      oldPos,
      newPos,
      oldPivot,
      newPivot,
      layer.display.rotation,
    );
    layer.display.x -= shift.x;
    layer.display.y -= shift.y;
    // yes this needs to be done twice
    layer.display.width = Math.max(1, layer.display.width);
    layer.display.height = Math.max(1, layer.display.height);
  }
}
