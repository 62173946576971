/**
 * Informs the blazor app about a navigation change for it to rerender properly.
 * Changing the browser history does not trigger a rerender of the blazor app.
 *
 * You need to call this function after changing the browser history into a
 * blazor route (but not from a blazor route to react).
 */
export function forceRefreshBlazor() {
  // inform blazor app about the navigation change for it to rerender properly
  if (typeof window.BLZ_FORCE_REFRESH === 'function') {
    try {
      window.BLZ_FORCE_REFRESH();
    } catch {
      // ignore external exceptions
    }
  }
}
