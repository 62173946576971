import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';
import { swap } from '../../lib/swap';

export interface ISwapSheetsActionPayload {
  /**
   * The element index to swap
   */
  srcIndex: number;

  /**
   * The element index to swap src with
   */
  destIndex: number;
}

export function swapSheetsReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<ISwapSheetsActionPayload>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product) {
    return;
  }
  swap(product.sheets, action.payload.srcIndex, action.payload.destIndex);
}
