import { FC, PointerEvent, useCallback, useContext, useMemo } from 'react';
import './BoundingBox.css';
import { ILayer, LayerType } from './LayersState';
import { BoundingBoxHandle } from './BoundingBoxHandle';
import { useAppDispatch } from '../../redux/hooks';
import { startTranslationTransform } from './startTranslationTransform';
import { useIsTransformingLayers } from './liveSheetSelectors';
import { startScaleTransform } from './startScaleTransform';
import { getLayerTransformCss } from './getLayerTransformCss';
import { px } from '../../lib/px';
import { startRotationTransform } from './startRotationTransform';
import { selectLayer } from './liveSheetSlice';
import classNames from 'classnames';
import { CKEContext } from '../textEditor/CKEditorProvider';
import { BoundingBoxAnchor } from './BoundingBoxAnchor';
import { useFirstProblemByLayerId } from '../problem/problemSelectors';
import { DesignProblemLevel } from '../problem/IDesignerProblem';
import { ProblemIndicator } from '../problem/ProblemIndicator';
import { useShowProblems } from '../view/viewSelectors';

export interface IBoundingBoxProps {
  layer: ILayer;
}

const handleSize = 12;

export const BoundingBox: FC<IBoundingBoxProps> = ({ layer }) => {
  const dispatch = useAppDispatch();
  const isTransforming = useIsTransformingLayers();
  const showProblems = useShowProblems();
  const { setLayerId } = useContext(CKEContext);
  // in first iteration only display the first problem as of now we do not expect many `problems` to display next to the layer
  const problem = useFirstProblemByLayerId(layer.id);

  const { id, isSelected } = layer;
  const onClick = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (e.button !== 0) {
        return;
      }
      e.stopPropagation(); // do not select other layers below this one
      if (!isSelected || e.ctrlKey) {
        dispatch(selectLayer({ id, exclusive: !e.ctrlKey, toggle: e.ctrlKey }));
      }
    },
    [dispatch, id, isSelected],
  );

  const onScaleBegin = useCallback(
    (anchor: BoundingBoxAnchor) => {
      dispatch(startScaleTransform(id, anchor));
    },
    [dispatch, id],
  );

  const onBoundingBoxPointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (e.button === 0 && isSelected) {
        e.stopPropagation();
        dispatch(startTranslationTransform(id));
      }
    },
    [dispatch, id, isSelected],
  );

  const onRotationHandlePointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (e.button === 0) {
        e.stopPropagation();
        dispatch(startRotationTransform(id));
      }
    },
    [dispatch, id],
  );

  const onDoubleClick = useCallback(() => {
    if (layer.type === LayerType.Text) {
      setLayerId(layer.id);
    }
  }, [setLayerId, layer.id, layer.type]);

  // only show handles of the primary layer which is grabbed to avoid visual clutter
  const showHandles = layer.isSelected && (!isTransforming || layer.isGrabbed);

  const problemClass = useMemo(() => {
    if (!showProblems) {
      return undefined;
    }
    switch (problem?.level) {
      case DesignProblemLevel.Warning:
        return 'problem-warning';
      case DesignProblemLevel.Error:
        return 'problem-error';
      default:
        return undefined;
    }
  }, [problem, showProblems]);

  return (
    <div
      className={classNames('bounding-box', { active: layer.isSelected }, problemClass)}
      onPointerDown={onBoundingBoxPointerDown}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={{
        left: layer.display.x,
        top: layer.display.y,
        width: layer.display.width,
        height: layer.display.height,
        transform: getLayerTransformCss(layer.display.rotation),
      }}>
      {showProblems && !!problem && <ProblemIndicator problem={problem} />}
      {layer.isScalable &&
        showHandles &&
        [
          BoundingBoxAnchor.Top,
          BoundingBoxAnchor.TopRight,
          BoundingBoxAnchor.Right,
          BoundingBoxAnchor.BottomRight,
          BoundingBoxAnchor.Bottom,
          BoundingBoxAnchor.BottomLeft,
          BoundingBoxAnchor.Left,
          BoundingBoxAnchor.TopLeft,
        ].map((anchor) => (
          <BoundingBoxHandle
            key={anchor}
            layer={layer}
            anchor={anchor}
            onScaleBegin={onScaleBegin}
            size={handleSize}
          />
        ))}
      {layer.isRotatable && showHandles && (
        <div
          className="rotation-handle"
          role="button"
          onPointerDown={onRotationHandlePointerDown}
          onClick={(e) => e.stopPropagation()}
          style={{
            width: px(handleSize),
            height: px(handleSize),
            top: '50%',
            left: '100%',
            transform: 'translate(50px, -50%)',
          }}
        />
      )}
    </div>
  );
};
