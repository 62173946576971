import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { EditorContext } from '../EditorContext';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import { useTranslation } from 'react-i18next';
import { BasicButton, ButtonSize, ButtonType } from '../../buttons/basic/BasicButton';
import styles from './CategoryPage.module.css';
import { ImageTileContainer } from '../../categories/ImageTileContainer';
import { ImageTile } from '../../categories/ImageTile';
import {
  useGetGenreGroupsQuery,
  useGetProjectGenreQuery,
  useUpdateProjectGenreMutation,
} from '../../api/treditionApi';
import { LazyLoader } from '../../loader/LazyLoader';
import { localeCompare } from '../../lib/localeCompare';

export const CategoryPage: FC = () => {
  const { t } = useTranslation();
  const context = useContext(EditorContext);

  const {
    registerStep,
    state: { isReadonly, projectId },
  } = context;

  const genreChanged = useRef<boolean>(false);
  const genreOptionsQuery = useGetGenreGroupsQuery();
  const projectGenreQuery = useGetProjectGenreQuery({ projectId });
  const [updateGenreMutation] = useUpdateProjectGenreMutation();

  const [activeGenreGroup, setActiveGenreGroup] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');

  const onSubmit = useCallback(async () => {
    await updateGenreMutation({ projectId, genreId: selectedGenre }).unwrap();
    return true;
  }, [projectId, selectedGenre, updateGenreMutation]);

  const onValidate = useCallback(async () => !!selectedGenre, [selectedGenre]);

  useEffect(() => {
    registerStep(onSubmit, onValidate, () => genreChanged.current);
  }, [registerStep, onSubmit, onValidate]);

  const { data: genreOptions, isLoading: genreOptionsLoading } = genreOptionsQuery;
  const { data: genreId, isLoading: projectDataLoading } = projectGenreQuery;

  useEffect(() => {
    if (genreOptionsLoading || !genreOptions || projectDataLoading) return;

    if (genreId) {
      const x = genreOptions.find((g) => g.genres.some((g) => g.id == genreId));
      if (!x) return;
      setSelectedGenre(genreId);
      setActiveGenreGroup(x.id);
    } else {
      const [first] = genreOptions;
      if (!first) return;
      setActiveGenreGroup(first.id);
    }
  }, [genreId, genreOptions, genreOptionsLoading, projectDataLoading]);

  if (genreOptionsQuery.isLoading) return <LazyLoader />;
  if (!genreOptions) return null;

  const options = genreOptions.find((d) => d.id === activeGenreGroup)?.genres || [];

  return (
    <MainBookWizardLayout infoText={t('GenreInfo')} headline={t('GenreTitle')}>
      <div className={styles.wrapper}>
        {genreOptions.map((d) => {
          return (
            <BasicButton
              key={d.id}
              mood={ButtonType.Secondary}
              onClick={() => setActiveGenreGroup(d.id)}
              label={d.name}
              disabled={isReadonly}
              size={ButtonSize.large}
              active={activeGenreGroup === d.id}
              className={styles.genreButton}
            />
          );
        })}
        <h4 className={styles.headline}>{t('GenreSelectQuestion')}</h4>
        {options.length > 0 && (
          <ImageTileContainer className={styles.tileContainer}>
            {[...options]
              .sort((a, b) => localeCompare(a.name, b.name))
              .map(({ name, id, imageUrl }) => (
                <ImageTile
                  key={id}
                  selected={selectedGenre === id}
                  disabled={isReadonly}
                  imageSrc={imageUrl}
                  name={name}
                  onClick={() => {
                    if (id === selectedGenre) return;
                    setSelectedGenre(id);
                    genreChanged.current = true;
                  }}
                />
              ))}
          </ImageTileContainer>
        )}
      </div>
    </MainBookWizardLayout>
  );
};
