import { createSlice } from '@reduxjs/toolkit';
import { IConfig } from './IConfig';
import { createOverwriteStateReducer } from '../lib/createOverwriteStateReducer';

export const configSlice = createSlice({
  name: 'config',
  initialState: Object.freeze({}) as unknown as IConfig, // note: this lie relies on the bootstrapping process not allowing the app to start without a config
  reducers: {
    configure: createOverwriteStateReducer<IConfig>(),
  },
});

export const { configure } = configSlice.actions;
