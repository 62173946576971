import React from 'react';
import styles from './NavMenu.module.css';

export interface INavMenuProps {
  children: React.ReactNode;
}

export const NavMenu = ({ children }: INavMenuProps) => {
  return (
    <nav className={styles.navMenu}>
      <ul className={styles.subMenu}>{children}</ul>
    </nav>
  );
};
