export const normalizeIsbn = (isbn: string) => {
  return isbn.replace(/-/g, '');
};

export const formatIsbn = (isbn: string) => {
  const isbnNormalized = normalizeIsbn(isbn);

  const isbnArray = isbnNormalized.split('');
  const first = isbnArray.slice(0, 3).join('');
  const second = isbnArray.slice(3, 4).join('');
  const third = isbnArray.slice(4, 7).join('');
  const fourth = isbnArray.slice(7, 12).join('');
  const fifth = isbnArray.slice(12, 13).join('');
  return [first, second, third, fourth, fifth].join('-');
};
