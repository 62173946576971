import React, { useContext } from 'react';
import styles from './ContributorListEntry.module.css';
import { useTranslation } from 'react-i18next';
import { ContributorRole, IContributor } from './interfaces/IContributor';
import classNames from 'classnames';
import { EditorContext } from '../EditorContext';
import { IconButton, IconButtonSize } from '../../buttons/icon/IconButton';
import { noop } from '../../lib/noop';
import { ContributorHeader } from './ContributorHeader';
import { Icon } from '../../icons/Icon';

export interface IContributorAccordion {
  contributor: IContributor;
  onUnlinkContributor: (id: string) => void;
  isReadonly?: boolean;
}

export const ContributorListEntry: React.FC<IContributorAccordion> = ({
  contributor,
  onUnlinkContributor,
  isReadonly,
}) => {
  const { t } = useTranslation();

  const id =
    'profileId' in contributor
      ? contributor.profileId
      : 'id' in contributor
      ? contributor.id
      : null;

  const profileId = id ? id.toString() : '';

  const roleName = (() => {
    if (!contributor) return '';
    if (contributor.role === ContributorRole.FreeText) {
      return contributor.additionalDescription;
    }
    return t(`Role${contributor.role}`);
  })();

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: isReadonly })}>
      <IconButton
        disabled={isReadonly}
        size={IconButtonSize.medium}
        className={styles.close}
        labelHidden
        label={t('close')}
        iconName={'schliessen-control'}
        onClick={isReadonly ? noop : () => onUnlinkContributor(profileId)}
      />
      {!isReadonly && (
        <a
          target={'_blank'}
          href={
            contributor.role === ContributorRole.Author
              ? `/myaccount/profiles/edit/${profileId}`
              : '/myaccount/profiles'
          }
          rel="noreferrer">
          <Icon name={'editieren-control'} className={styles.edit} />
        </a>
      )}
      <ContributorHeader contributor={contributor} />
      <div className={styles.role}>{roleName}</div>
    </div>
  );
};
