import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, WheelEvent } from 'react';
import { CoverPreviewActions, useCoverPreviewState } from './useCoverPreviewState';
import { px } from '../../lib/px';
import { useActiveProduct } from '../project/productSelectors';
import './CoverPreview.css';
import { Alert } from '../../alert/Alert';
import { calculateCoverDimensions } from './calculateCoverDimensions';
import { useTranslation } from 'react-i18next';
import { D3Cover } from './D3Cover';
import { useHotkeys } from 'react-hotkeys-hook';

export interface ICoverPreviewProps {
  /**
   * Invoked when the user clicks on the backdrop or presses ESC
   */
  onRequestClose: () => void;
}

export const CoverPreview: FC<ICoverPreviewProps> = ({ onRequestClose }) => {
  const product = useActiveProduct();
  const bookRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [state, dispatch] = useCoverPreviewState();
  const { isRotating, rotation, zoom, isZoomInitialized } = state;
  if (!product) {
    throw new Error('No product is currently active');
  }
  const dimensions = useMemo(() => calculateCoverDimensions(product), [product]);

  const html = useMemo<string>(() => {
    return document.querySelector('.designer .sheet:first-child .render .layers')?.innerHTML || '';
  }, []);

  const onWheel = useCallback(
    (e: WheelEvent<HTMLElement>) => {
      e.stopPropagation();
      dispatch({ type: CoverPreviewActions.AddZoom, zoom: Math.sign(-e.deltaY) * 0.1 });
    },
    [dispatch],
  );

  useHotkeys(
    'escape',
    () => {
      onRequestClose();
    },
    [onRequestClose],
  );

  useEffect(() => {
    if (!isRotating) {
      return;
    }

    function onMove(e: PointerEvent) {
      dispatch({
        type: CoverPreviewActions.AddRotation,
        rotation: { x: e.movementY / 2, y: e.movementX / 2 },
      });
    }

    function onUp() {
      dispatch({ type: CoverPreviewActions.SetIsRotating, isRotating: false });
    }

    window.addEventListener('pointermove', onMove);
    window.addEventListener('pointerup', onUp);
    return () => {
      window.removeEventListener('pointermove', onMove);
      window.removeEventListener('pointerup', onUp);
    };
  }, [dispatch, isRotating]);

  useEffect(() => {
    // determine initial zoom so there is 50% of `THRESHOLD` in px space above and below the 3d view
    if (isZoomInitialized || !dimensions.coverHeight) {
      return;
    }
    let zoom = 1;
    const THRESHOLD = 200;
    if (window.innerHeight - dimensions.coverHeight < THRESHOLD) {
      zoom = (window.innerHeight - THRESHOLD) / dimensions.coverHeight;
    }
    if (!Number.isFinite(zoom)) {
      zoom = 1; // just a sanity check
    }
    dispatch({ type: CoverPreviewActions.InitializeZoom, zoom });
  }, [dispatch, dimensions, isZoomInitialized]);

  const onCoverPointerDown = useCallback(() => {
    dispatch({ type: CoverPreviewActions.SetIsRotating, isRotating: true });
  }, [dispatch]);

  const bookStyle = useMemo<CSSProperties>(() => {
    const { coverWidth, coverHeight } = dimensions;
    return {
      width: px(coverWidth),
      height: px(coverHeight),
      transform: `translate(-50%, -50%) scale(${zoom}) rotateX(${-rotation.x}deg) rotateY(${
        rotation.y
      }deg)`,
    };
  }, [rotation, dimensions, zoom]);

  const d3Cover = useMemo(
    () => <D3Cover dimensions={dimensions} html={html} productType={product.type} />,
    [dimensions, html, product.type],
  );

  if (!dimensions.coverWidth || !dimensions.coverHeight || !dimensions.spineWidth) {
    return <Alert level="error">{t('3DViewInvalidMeasures')}</Alert>;
  }

  if (!isZoomInitialized) {
    return null;
  }

  return (
    <div className="cover-preview" onWheel={onWheel}>
      <div onClick={onRequestClose} className="cover-preview-backdrop" />
      <div style={bookStyle} ref={bookRef} className="book" onPointerDown={onCoverPointerDown}>
        {d3Cover}
      </div>
    </div>
  );
};
