import { IsbnListElement } from './IsbnListElement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IsbnList.module.css';
import { IIsbnProduct } from '../interfaces/IIsbnPageData';

export interface IOwnedIsbnListProps {
  products: IIsbnProduct[];
}

export const TreditionISBNList: React.FC<IOwnedIsbnListProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.wrapper}>
      <h3>{t('ISBN')}</h3>
      {products.map(({ isbn, bookType, id }) => (
        <IsbnListElement isbn={isbn} bookType={bookType} key={id} />
      ))}
    </ul>
  );
};
