import React from 'react';
import styles from './ImageTile.module.css';
import { Icon } from '../icons/Icon';
import classNames from 'classnames';
import { noop } from '../lib/noop';

export interface IImageTileProps {
  imageSrc: string;

  imageAlt?: string;
  selected?: boolean;

  onClick?: () => unknown;

  name: string;

  disabled?: boolean;
}

export const ImageTile: React.FC<IImageTileProps> = ({
  name,
  imageSrc,
  selected = false,
  imageAlt = '',
  onClick = noop,
  disabled = false,
}: IImageTileProps) => {
  return (
    <div
      className={classNames(styles.imageTile, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? noop : onClick}>
      <div style={{ backgroundImage: `url(${imageSrc})` }} className={styles.imageWrapper} />
      <div className={styles.name}>{name}</div>
      {selected && <Icon className={styles.selectionCheckmark} name={'check-control'} />}
    </div>
  );
};
