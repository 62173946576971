import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';

export function applySetReadonlyToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<boolean>,
) {
  if (!action.payload) {
    return; // nothing to do if toggled from readonly to non-readonly
  }
  state.layers.forEach((layer) => {
    layer.isSelected = false;
    layer.isGrabbed = false;
  });
  state.transform = null;
}
