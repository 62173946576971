import React, { useCallback } from 'react';
import TextInput, { ITextInput } from './TextInput';
import { ErrorWrapper, IErrorWrapper } from '../errorWrapper/ErrorWrapper';
import { FormError } from '../formError/FormError';
import { useField } from 'formik';

export interface ITextInputFormik extends ITextInput, IErrorWrapper {
  name: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  pasteFormatter?: (event: React.ClipboardEvent<HTMLInputElement>) => string;
}

const NO_FORMAT = (event: React.ClipboardEvent<HTMLInputElement>) =>
  event.clipboardData.getData('text');

export const TextInputFormik: React.FC<ITextInputFormik> = ({
  pasteFormatter,
  name,
  disabled,
  className,
  ...rest
}) => {
  const [field, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const { setTouched, setValue } = helper;
  const hasError = !!error && touched;

  const onBlur = useCallback(() => {
    if (!touched) {
      // do not set touched if already touched - this will produce unnecessary re-renders by formik
      setTouched(true);
    }
  }, [setTouched, touched]);

  return (
    <ErrorWrapper data-type="text" className={className}>
      <TextInput
        hasError={hasError}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        onPaste={(event) => {
          if (typeof pasteFormatter === 'undefined') return NO_FORMAT(event);
          const text = pasteFormatter(event);
          event.preventDefault();
          setValue(text);
          return text;
        }}
        {...rest}
      />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
