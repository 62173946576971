import { FC } from 'react';
import { ILayerProps, Layer } from './Layer';
import { IPlainLayer } from './LayersState';
import { getLayerCssFilter } from './getLayerCssFilter';
import { getLayerBackgroundColorCss, getLayerBorderCss } from '../../lib/Color';
import { px } from '../../lib/px';

export interface IPlainLayerProps extends ILayerProps {
  layer: IPlainLayer;
}

export const PlainLayer: FC<IPlainLayerProps> = ({ layer, style }) => {
  return (
    <Layer layer={layer} className="plain" style={style}>
      <div
        className="texture"
        style={{
          backgroundColor: getLayerBackgroundColorCss(layer),
          filter: getLayerCssFilter(layer),
          border: getLayerBorderCss(layer),
          boxSizing: 'border-box',
          padding: px(layer.padding),
          width: '100%',
          height: '100%',
        }}
      />
    </Layer>
  );
};
