import { FC } from 'react';
import { ProductType } from './ProductState';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HardCover } from '../../icons/tredition-cover-h.svg';
import { ReactComponent as SoftCover } from '../../icons/tredition-cover-s.svg';
import { ReactComponent as EBook } from '../../icons/tredition-cover-e.svg';
import { ReactComponent as LargePrint } from '../../icons/tredition-cover-xl.svg';

export interface IProductTypeLabelProps {
  type: ProductType;
}

export const ProductTypeLabel: FC<IProductTypeLabelProps> = ({ type }) => {
  const { t } = useTranslation();
  switch (type) {
    case ProductType.Ebook:
      return (
        <StyledProductTypeLabel>
          <TempIcon>
            <EBook />
          </TempIcon>
          {/*<Icon name="ebook-control" />*/}
          <span>{t('ProductEbook')}</span>
        </StyledProductTypeLabel>
      );
    case ProductType.HardCover:
      return (
        <StyledProductTypeLabel>
          <TempIcon>
            <HardCover />
          </TempIcon>
          {/*<Icon name="hardcover-control" />*/}
          <span>{t('ProductHardcover')}</span>
        </StyledProductTypeLabel>
      );
    case ProductType.SoftCover:
      return (
        <StyledProductTypeLabel>
          <TempIcon>
            <SoftCover />
          </TempIcon>
          {/*<Icon name="paperback-control" />*/}
          <span>{t('ProductSoftcover')}</span>
        </StyledProductTypeLabel>
      );
    case ProductType.LargePrint:
      return (
        <StyledProductTypeLabel>
          <TempIcon>
            <LargePrint />
          </TempIcon>
          {/*<Icon name="grossdruck-control" />*/}
          <span>{t('ProductLargePrint')}</span>
        </StyledProductTypeLabel>
      );
    default:
      return null;
  }
};

const StyledProductTypeLabel = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  > .tredition-icon {
    font-size: 32px;
    height: min-content;
  }
`;

const TempIcon = styled.div`
  & > svg {
    height: 46px;
  }
`;
