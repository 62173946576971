import React from 'react';
import styled from 'styled-components/macro';
import { MenuButton } from './MenuButton';
import { Icon, IIconName } from '../icons/Icon';

export interface ICircleIconButton {
  icon: IIconName;
  iconSize?: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const CircleIconButton: React.FC<ICircleIconButton> = ({
  icon,
  iconSize,
  children,
  onClick,
}) => {
  return (
    <StyledButton onClick={onClick}>
      <Circle>
        <Icon name={icon} size={iconSize} />
      </Circle>
      <StyledText>{children}</StyledText>
    </StyledButton>
  );
};

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-cta);
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 33px;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  background: white;

  font-size: 0.9rem;
  line-height: normal;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover > ${Circle} {
    background: var(--color-cta-dark);
  }
`;

const StyledText = styled.span`
  font-weight: 600;
  font-size: 0.9rem;
  text-align: left;
`;
