import { IconButton } from '../buttons/icon/IconButton';
import { noop } from '../lib/noop';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { Icon } from '../icons/Icon';
import classNames from 'classnames';

export interface INavbarProps {
  bookTitle?: string;
}

export const HeaderNavItems = [
  {
    label: 'Dashboard',
    href: 'dashboard',
    iconName: 'dashboard',
  },
  {
    label: 'Projects',
    href: 'projects',
    iconName: 'buchgestaltung-projekt',
  },
  {
    label: 'Profiles',
    href: 'myaccount/profiles',
    iconName: 'autor',
  },
  {
    label: 'Provisions',
    href: 'myaccount/provision/author',
    iconName: 'berichte',
  },
  {
    label: 'Marketing',
    href: 'dashboard_marketing',
    iconName: 'marketingplan',
  },
  {
    label: 'AffiliateProgram',
    href: 'dashboard_affiliate',
    iconName: 'affiliate-programm',
  },
  {
    label: 'Shop',
    href: 'https://shop.tredition.com/',
    iconName: 'online-shops',
    external: true,
  },
];

export const Header: FC<INavbarProps> = ({ bookTitle }) => {
  const { t } = useTranslation();

  const [navOpen, setNavOpen] = useState(false);
  return (
    <div className={styles.wrapper}>
      <IconButton
        label={''}
        iconName={'app-switch'}
        onClick={() => setNavOpen((v) => !v)}
        className={styles.menuButton}
      />
      <nav className={classNames(styles.menu, { [styles.close]: !navOpen })}>
        <div className={styles.closeWrapper}>
          <IconButton
            label={'close'}
            iconName={'schliessen-control'}
            labelHidden
            className={styles.closeButton}
            onClick={() => setNavOpen(false)}
          />
        </div>
        <ul className={styles.nav}>
          {HeaderNavItems.map(({ href, label, external, iconName }) => (
            <li key={label} className={styles.navItem}>
              {/* error is handled but eslint doesn't get it. */}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={href}
                rel={external ? 'noreferrer noopener' : ''}
                target={external ? '_blank' : ''}>
                <Icon name={iconName} className={styles.icon} />
                {t(label)}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.left}>
        <span className={styles.location}>{`${t('BookApp')}${bookTitle ? ':' : ''}`}</span>
        {bookTitle && <span>{bookTitle}</span>}
      </div>
      <div className={styles.logoWrapper}>
        {/* FIXME: Link logo */}
        <img
          src={'https://my.staging.tredition.it/img/tredition-logo-final.svg'}
          alt="Tredition"
          className={styles.logo}
        />
      </div>
      <div className={styles.icons}>
        <IconButton
          label={''}
          iconName={'mitteilungen-control'}
          onClick={noop}
          className={styles.newNotification}
        />
        <IconButton label={''} iconName={'avatar'} onClick={noop} />
      </div>
    </div>
  );
};
