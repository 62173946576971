import { Plugin } from "@ckeditor/ckeditor5-core";
import {
  ButtonView,
  createDropdown,
  addToolbarToDropdown,
} from "ckeditor5/src/ui";
import topIcon from "../../icons/tredition-ausrichtenoben-control.svg";
import centerIcon from "../../icons/tredition-ausrichtenmitte-control.svg";
import bottomIcon from "../../icons/tredition-ausrichtenunten-control.svg";
import VerticalAlignmentCommand from "./VerticalAlignmentCommand";

const iconsMap = new Map([
  ["top", topIcon],
  ["center", centerIcon],
  ["bottom", bottomIcon],
]);

const flexValues = Object.freeze({
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
});

export const VERTICAL_ALIGNMENT_DEFAULT_VALUE = "top";
export const VERTICAL_ALIGNMENT = "vertical-alignment";
export const VERTICAL_ALIGNMENT_ELEMENT = "valign";

const SUPPORTED_VALIGN_ITEMS = ["top", "center", "bottom"];
const isSupported = (item) => SUPPORTED_VALIGN_ITEMS.includes(item);

class VerticalAlignmentUI extends Plugin {
  static get pluginName() {
    return "vAlignmentUI";
  }

  get localizedOptionTitles() {
    const t = this.editor.t;

    return {
      top: t("Top"),
      center: t("Center"),
      bottom: t("Bottom"),
    };
  }

  _addButton(option) {
    const editor = this.editor;

    editor.ui.componentFactory.add(`vertical-alignment:${option}`, (locale) => {
      const buttonView = new ButtonView(locale);
      const command = editor.commands.get(VERTICAL_ALIGNMENT);

      buttonView.set({
        label: this.localizedOptionTitles[option],
        icon: iconsMap.get(option),
        tooltip: true,
        isToggleable: true,
      });

      // Bind button model to command.
      buttonView.bind("isEnabled").to(command);
      buttonView.bind("isOn").to(command, "value", (value) => value === option);

      // Execute command.
      this.listenTo(buttonView, "execute", () => {
        editor.execute(VERTICAL_ALIGNMENT, { value: option });
        editor.editing.view.focus();
      });

      return buttonView;
    });
  }

  init() {
    const editor = this.editor;
    const componentFactory = editor.ui.componentFactory;
    const t = editor.t;
    const options =
      editor.config.get("vertical-alignment.options") ||
      Object.keys(flexValues);
    const schema = editor.model.schema;

    editor.commands.add(
      VERTICAL_ALIGNMENT,
      new VerticalAlignmentCommand(editor)
    );

    schema.register(VERTICAL_ALIGNMENT_ELEMENT, {
      allowIn: "$root",
      inheritAllFrom: "$container",
      allowAttributes: [VERTICAL_ALIGNMENT],
    });

    editor.conversion.for("upcast").elementToElement({
      model: (viewElement, { writer }) => {
        const classNames = [...viewElement.getClassNames()];
        const modelVal = SUPPORTED_VALIGN_ITEMS.find((item) =>
          classNames.includes(item)
        );
        return writer.createElement(VERTICAL_ALIGNMENT_ELEMENT, {
          [VERTICAL_ALIGNMENT]: modelVal,
        });
      },
      view: {
        name: "div",
        classes: ["ck-vertical-alignment"],
      },
    });

    editor.conversion.for("downcast").elementToElement({
      model: {
        name: VERTICAL_ALIGNMENT_ELEMENT,
        attributes: [VERTICAL_ALIGNMENT],
      },
      view: (modelElement, { writer }) => {
        const attributeValue = modelElement.getAttribute(VERTICAL_ALIGNMENT);
        const flexValue = flexValues[attributeValue];

        return writer.createContainerElement("div", {
          class: `ck-vertical-alignment ${attributeValue}`,
        });
      },
    });

    options.filter(isSupported).forEach((option) => this._addButton(option));

    componentFactory.add("vertical-alignment", (locale) => {
      const dropdownView = createDropdown(locale);

      // Add existing alignment buttons to dropdown's toolbar.
      const buttons = options.map((option) =>
        componentFactory.create(`vertical-alignment:${option}`)
      );
      addToolbarToDropdown(dropdownView, buttons);

      // Configure dropdown properties a behavior.
      dropdownView.buttonView.set({
        label: t("Vertical text alignment"),
        tooltip: true,
      });

      dropdownView.toolbarView.isVertical = true;
      dropdownView.toolbarView.ariaLabel = t("Vertical text alignment toolbar");

      dropdownView.extendTemplate({
        attributes: {
          class: "ck-vertical-alignment-dropdown",
        },
      });

      const defaultIcon = iconsMap.get(VERTICAL_ALIGNMENT_DEFAULT_VALUE);

      // Change icon to reflect current selection's alignment.
      dropdownView.buttonView
        .bind("icon")
        .toMany(buttons, "isOn", (...areActive) => {
          // Get the index of an active button.
          const index = areActive.findIndex((value) => value);

          // If none of the commands is active, display either defaultIcon or the first button's icon.
          if (index < 0) {
            return defaultIcon;
          }

          // Return active button's icon.
          return buttons[index].icon;
        });
      return dropdownView;
    });
  }
}

export default VerticalAlignmentUI;
