import { t } from 'i18next';
import { setLocale } from 'yup';

export function setYupTranslations() {
  setLocale({
    mixed: {
      required: () => t('ValidationRequired'),
      defined: () => t('ValidationRequired'),
      notNull: () => t('ValidationRequired'),
    },
    array: {
      min: ({ min }) => t('ValidationArrayMin', { min }),
      max: ({ max }) => t('ValidationArrayMax', { max }),
    },
    string: {
      min: ({ min }) => t('ValidationMin', { min }),
      max: ({ max }) => t('ValidationMax', { max }),
      email: () => t('ValidationEmail'),
      url: () => t('ValidationUrl'),
    },
    number: {
      min: ({ min }) => t('ValidationMinNumber', { min }),
      max: ({ max }) => t('ValidationMaxNumber', { max }),
      integer: () => t('ValidationInteger'),
      positive: () => t('ValidationPositive'),
    },
  });
}
