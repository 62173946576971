import { FC, useMemo } from 'react';
import { getCssRgba, IRGBA, Rgba } from '../../lib/Color';
import { hexStringToRgba } from '../../lib/hexToRgba';
import './ColorDisplay.css';
import styled from 'styled-components/macro';

export interface IColorDisplayProps {
  /**
   * The color to display as RGBA
   */
  rgba?: IRGBA | null;

  /**
   * The color to display as a hex string
   */
  hex?: string | null;

  /**
   * Invoked when the user clicks on this color display. Will be called with the color as RGBA.
   */
  onClick?: (color: IRGBA) => unknown;

  /**
   * If true, marks this color display as selected
   */
  isSelected?: boolean;

  /**
   * Additional class names
   */
  className?: string;
}

export const ColorDisplay: FC<IColorDisplayProps> = ({
  rgba,
  hex,
  onClick,
  isSelected,
  className,
}) => {
  const color = useMemo<IRGBA>(() => {
    const fallback = Rgba.devoid();
    if (typeof hex === 'string') {
      return hexStringToRgba(hex) || fallback;
    }
    if (typeof rgba === 'object' && rgba) {
      return rgba;
    }
    return fallback;
  }, [rgba, hex]);

  const backgroundColor = useMemo<string>(() => getCssRgba(color), [color]);

  const optionalOnClick = useMemo(() => {
    return onClick ? () => onClick(color) : undefined;
  }, [onClick, color]);

  return (
    <Wrapper
      className={className}
      onClick={optionalOnClick}
      role={optionalOnClick ? 'button' : undefined}
      isSelected={isSelected}>
      <ColorFill backgroundColor={backgroundColor} />
    </Wrapper>
  );
};

interface IWrapperProps {
  onClick?: () => unknown;
  isSelected?: boolean;
}

const Wrapper = styled.div.attrs<IWrapperProps>((props) => ({
  style: {
    outlineOffset: '-2px',
    outline: props.isSelected ? '2px solid var(--color-cta)' : undefined,
    cursor: props.onClick ? 'pointer' : undefined,
  },
}))<IWrapperProps>`
  width: 35px;
  height: 35px;
  border: 1px solid var(--color-border);
  overflow: hidden;
  background: rgba(0, 0, 0, 0)
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAK0lEQVQ4y2P8////fwY84OTJk/ikGZgYKASjBgwGA1gIxbO5ufloIA5/AwCvtQl5itE62AAAAABJRU5ErkJggg==')
    repeat scroll left center;

  &.round {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: auto;
  }
`;

interface IColorFill {
  backgroundColor: string;
}

const ColorFill = styled.div.attrs<IColorFill>((props) => ({
  style: {
    backgroundColor: props.backgroundColor,
  },
}))<IColorFill>`
  display: block;
  width: 100%;
  height: 100%;
`;
