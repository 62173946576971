import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITextLayer, LayerType, TextTemplateType } from './LayersState';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetTextContentActionPayload {
  /**
   * Id of the layer to edit. Must be a text layer
   */
  id: string;

  /**
   * The new HTML content of the text layer
   */
  content: string;
}

export function setTextContentReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetTextContentActionPayload>,
) {
  const textLayer = state.layers.find(
    ($): $ is ITextLayer => $.id === action.payload.id && $.type === LayerType.Text,
  );
  if (!textLayer) {
    return;
  }
  textLayer.content = action.payload.content;
  textLayer.template = TextTemplateType.None;
  textLayer.updatedAt = Date.now();
}
