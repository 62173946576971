import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface ISetLoadingActionPayload {
  /**
   * isLoading as a boolean
   */
  isLoading: boolean;
}

/**
 * Set the content of isLoading with a bool
 */
export const setIsLoadingReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetLoadingActionPayload>,
) => {
  state.isLoading = action.payload.isLoading;
};
