import {
  DesignProblemLevel,
  DesignProblemType,
  ILayerOutOfBoundsDesignProblem,
} from './IDesignerProblem';
import { ILayer } from '../layer/LayersState';
import { IRect, rectsIntersect } from '../../lib/rectsIntersect';

export function checkLayerOutOfBounds(
  designRect: IRect,
  layer: ILayer,
): ILayerOutOfBoundsDesignProblem | null {
  const layerRect: IRect = {
    topLeft: { x: layer.x, y: layer.y },
    bottomRight: { x: layer.x + layer.width, y: layer.y + layer.height },
  };
  if (rectsIntersect(layerRect, designRect)) {
    return null;
  }
  return {
    type: DesignProblemType.LayerOutOfBounds,
    level: DesignProblemLevel.Warning,
  };
}
