import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewerState } from './PreviewerState';

export interface ISetShowEditorActionPayload {
  showEditor: boolean;
}

export const setShowEditorReducer = (
  state: Draft<IPreviewerState>,
  action: PayloadAction<ISetShowEditorActionPayload>,
) => {
  state.showEditor = action.payload.showEditor;
};
