import { FormikConfig, FormikErrors, FormikValues, useFormik } from 'formik';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { EditorContext } from './EditorContext';
import { noop } from '../lib/noop';
import { ValidationError } from 'yup';

export interface IUseFormikStepOptions<Values extends FormikValues> {
  formikConfig: Omit<FormikConfig<Values>, 'onSubmit'>;
  onSubmit: (values: Values) => Promise<boolean>;
}

export const useFormikStep = <Values extends FormikValues>(
  options: IUseFormikStepOptions<Values>,
) => {
  const editorContext = useContext(EditorContext);
  const { registerStep } = editorContext;
  const {
    onSubmit,
    formikConfig: { validationSchema },
  } = options;

  const isValidRef = useRef<boolean>(false);
  const formikValidateOverride = useCallback(
    (values: Values): Promise<FormikErrors<Values>> => {
      return validationSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          isValidRef.current = true;
          return {};
        })
        .catch((error: ValidationError) => {
          isValidRef.current = false;
          // values we return here are _added_ to the current formik errors
          return {
            __GLOBAL: error.inner.filter((e) => !e.path).map((e) => e.type),
          };
        });
    },
    [validationSchema],
  );

  const formik = useFormik({
    validateOnMount: true, // sane default otherwise validation will be skipped
    onSubmit: noop,
    validate: formikValidateOverride,
    ...options.formikConfig,
  });

  const { values, dirty, submitForm } = formik;
  const valuesRef = useRef<Values>(values as Values);
  const dirtyRef = useRef<boolean>(formik.dirty);

  const internalIsDirty = useCallback(() => dirtyRef.current, []);
  const internalOnValidate = useCallback((): Promise<boolean> => {
    // note: remember the formik submit function is a noop. since formik calls
    //       the formikValidateOverride prior to calling the
    //       given noop submit function we can access the isValid ref to determine
    //       if the validation passed or not.
    //       submitForm() does not reject or return false in that case
    return submitForm()
      .then(() => isValidRef.current)
      .catch(() => false);
  }, [submitForm, validationSchema]);

  const internalOnSubmit = useCallback(async (): Promise<boolean> => {
    if (!isValidRef.current) {
      // only call submit if currently valid
      return Promise.resolve(false);
    }
    return onSubmit(valuesRef.current).catch((error) => {
      console.error(error);
      return Promise.resolve(false);
    });
  }, [onSubmit, internalOnValidate]);

  useEffect(() => {
    valuesRef.current = values;
    dirtyRef.current = dirty;
  }, [dirty, values]);

  useEffect(() => {
    registerStep(internalOnSubmit, internalOnValidate, internalIsDirty);
  }, [registerStep, internalOnSubmit, internalOnValidate, internalIsDirty]);

  return {
    formik,
    editor: editorContext,
  };
};
