import React, { FC, useCallback, useMemo, useState } from 'react';
import { ProductTypeLabel } from '../project/ProductTypeLabel';
import { useTranslation } from 'react-i18next';
import { ActionModal } from '../../modal/ActionModal';
import { useEnsuredProject } from '../project/projectSelectors';
import { isProductDesignEmpty } from '../project/isProductDesignEmpty';
import { Alert } from '../../alert/Alert';
import { Select } from '../../select/Select';

export interface ICopyDesignModalProps {
  /**
   * Invoked when the user clicks on the import button after selecting a product to import from
   */
  onClickImport: (srcProductId: string) => Promise<unknown>;

  /**
   * Invoked when the user clicks on the cancel button
   */
  onClickCancel: () => unknown;
}

export const CopyDesignModal: FC<ICopyDesignModalProps> = ({ onClickCancel, onClickImport }) => {
  const { t } = useTranslation();
  const { products, activeProduct } = useEnsuredProject();
  const [productId, setProductId] = useState<string | null>(null);
  const sourceProduct = products.find(($) => $.id === productId) || null;
  const onClickImportInternal = useCallback(async () => {
    if (productId) {
      await onClickImport(productId);
    }
  }, [productId, onClickImport]);

  const productOptions = useMemo(() => {
    return products
      .filter(($) => $.id !== activeProduct)
      .map(($) => ({ value: $.id, label: <ProductTypeLabel type={$.type} /> }));
  }, [products, activeProduct]);

  return (
    <ActionModal
      isOpen
      title={t('CopyDesignProductTitle')}
      onOk={onClickImportInternal}
      onCancel={onClickCancel}>
      <div>{t('CopyDesignDescription')}</div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', margin: '1rem 0' }}>
        {t('CopyDesignLabel')}
        <Select options={productOptions} onChange={(v) => setProductId(v || null)} />
      </div>
      {!!sourceProduct && isProductDesignEmpty(sourceProduct) && (
        <Alert level="warning">{t('CopyDesignSourceDesignEmpty')}</Alert>
      )}
    </ActionModal>
  );
};
