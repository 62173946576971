import styled from 'styled-components/macro';
import { Icon } from '../icons/Icon';
import React, { useState } from 'react';

export interface IStyledCheckBox {
  children?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  checked: boolean;
}

export const StyledCheckBox: React.FC<IStyledCheckBox> = ({ children, onChange, checked }) => {
  return (
    <StyledLabel>
      <CheckBox>{checked && <Icon name={'check-control'} />}</CheckBox>
      <StyledInput type="checkbox" onChange={onChange} />
      {children}
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const StyledInput = styled.input`
  appearance: none;
  display: none;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  border: 2px solid var(--color-cta);
  border-radius: var(--border-radius);
  padding: var(--spacing-small);
  height: 26px;
  width: 26px;
  background-color: transparent;
  color: var(--color-cta);
`;
