import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';
import { IDesignerInputState } from '../input/IDesignerInputState';

/**
 * Reacts to the {@link setInput} action to update the selection if active.
 */
export function applyInputToSelectionReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<IDesignerInputState>,
) {
  if (state.selection.start) {
    state.selection.end = action.payload.mouse.workSpace;
  }
}
