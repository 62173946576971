import { IHSLA } from './Color';

const parseH = (rawH: string) => {
  const match = /(\d*\.?\d+)(.*)/.exec(rawH);
  if (!match) throw new Error('malformed H value in hsl string');
  const [_, value, unit] = match;
  if (unit === 'turn') {
    return Number(value);
  } else {
    return Number(value) / 360;
  }
};

const parsePercentage = (valueWithPercentage: string, value: string) => {
  const match = /(\d*\.?\d+)/.exec(valueWithPercentage);
  if (!match) throw new Error(`malformed ${value} value in hsl string`);
  return Number(match[1]) / 100;
};

export const parseHSLString = (hslString: string): IHSLA => {
  let hasA = false;
  if (hslString.startsWith('hsla')) {
    hasA = true;
  }

  const matches = /^hsla?\((.*)\)/g.exec(hslString);
  if (!matches || matches.length !== 2) {
    throw new Error('malformed hsl(a) string');
  }
  const [rawH, rawS, rawL, rawA] = matches[1].split(',').map((s) => s.trim());

  parseH(rawH);

  return {
    h: parseH(rawH),
    s: parsePercentage(rawS, 's'),
    l: parsePercentage(rawL, 'l'),
    a: hasA ? Number(rawA) : 1,
  };
};
