import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import styles from './IsbnImprintPage.module.css';
import { useGetIsbnPageDataQuery, useUpdateIsbnPageDataMutation } from '../../api/treditionApi';
import { LazyLoader } from '../../loader/LazyLoader';
import { IsbnImprintForm } from './IsbnImprintForm';
import { FormikProvider } from 'formik';
import { useFormikStep } from '../useFormikStep';
import { createIsbnSchema, IIsbnFormData } from './IsbnSchema';
import { toFormData } from './RequestFormDataMapping';
import { IEditorStepPageProps } from '../getStepComponent';
import { isTreditionIsbn } from '../../shared/yupHelper/isbnValidation';

export const IsbnImprintPage: FC<IEditorStepPageProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const [updateIsbnPageData] = useUpdateIsbnPageDataMutation();
  const { data, isLoading } = useGetIsbnPageDataQuery({ projectId });

  const [errors, setErrors] = useState<string[] | null>(null);

  const onSubmit = useCallback(
    async (values: IIsbnFormData) => {
      if (values.needIsbn == false && Object.values(values.customIsbn).some(isTreditionIsbn)) {
        setErrors([t('ISBNBelongsToTredition')]);
        return false;
      }

      await updateIsbnPageData({ projectId, data: values }).unwrap();
      return true;
    },
    [projectId, t, updateIsbnPageData],
  );

  const validationSchema = useMemo(() => createIsbnSchema(data?.products), [data?.products]);

  const { formik } = useFormikStep<IIsbnFormData>({
    formikConfig: {
      initialValues: toFormData(data),
      validationSchema,
      enableReinitialize: true,
    },
    onSubmit,
  });

  if (isLoading || !data) return <LazyLoader />;

  return (
    <MainBookWizardLayout
      infoText={t('ISBNInfo')}
      formik={{
        formikErrors: formik.errors,
        submitCount: formik.submitCount,
      }}
      headline={t('ISBNTitle')}
      customErrors={errors}>
      <FormikProvider value={formik}>
        <div className={styles.wrapper}>
          <IsbnImprintForm projectId={projectId} formik={formik} products={data.products} />
        </div>
      </FormikProvider>
    </MainBookWizardLayout>
  );
};
