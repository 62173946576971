import React, { useCallback } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { zoomRelative } from '../view/zoomRelative';
import { useZoom } from '../view/viewSelectors';
import { MenuButton } from '../../uiComponents/MenuButton';
import styled from 'styled-components/macro';
import { Icon } from '../../icons/Icon';

export const ZoomControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentZoom = useZoom();
  const increaseZoom = useCallback(() => {
    dispatch(zoomRelative(0.1));
  }, [dispatch]);
  const decreaseZoom = useCallback(() => {
    dispatch(zoomRelative(-0.1));
  }, [dispatch]);

  return (
    <Wrapper>
      <FlexContainer>
        <MenuButton style={{ fontSize: '1.2rem' }} onClick={decreaseZoom}>
          –
        </MenuButton>
        <span className="percentage">{Math.round(currentZoom * 100)}%</span>
        <MenuButton style={{ fontSize: '1.2rem' }} onClick={increaseZoom}>
          +
        </MenuButton>
      </FlexContainer>
      <span className="designer-menu-label">Zoom</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: none;

  width: 110px;
  height: 60px;

  .percentage {
    min-width: 2.5rem;
    text-align: center;
  }

  & > span {
    font-size: 0.5rem;
    font-family: 'Barlow', sans-serif;
    font-weight: normal;
    line-height: normal;
    padding-right: 5px;
  }

  @media screen and (min-width: 1600px) {
    & > span {
      font-size: 0.7rem;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
`;
