import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { moveLayers, rotateLayers } from '../../layer/liveSheetSlice';
import styled from 'styled-components/macro';
import { MenuButton } from '../../../uiComponents/MenuButton';
import { NumberInputWrapper } from '../../../uiComponents/NumberInputWrapper';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../icons/Icon';
import { Input } from '../../../uiComponents/Input';

export const MultiMover: FC = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<number>(1);
  const dispatch = useAppDispatch();
  const rotRef = useRef<HTMLInputElement>(null);
  const onChangeAmountInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAmount(Number.parseInt(e.target.value) || 0);
  }, []);

  const onBlurRotInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const degrees = Number.parseInt(e.target.value);
      if (!Number.isNaN(degrees)) {
        dispatch(rotateLayers({ degrees, filter: { selected: true } }));
      }
    },
    [dispatch],
  );

  return (
    <Wrapper>
      <Hint>
        <Icon name="info" />
        <span>{t('UseArrowKeys')}</span>
      </Hint>
      <div>
        <FlexWrapper>
          <StyledMenuButton onClick={() => dispatch(moveLayers({ x: -amount, isRelative: true }))}>
            ←
          </StyledMenuButton>
          <div>
            <StyledMenuButton
              onClick={() => dispatch(moveLayers({ y: -amount, isRelative: true }))}>
              ↑
            </StyledMenuButton>
            <StyledMenuButton onClick={() => dispatch(moveLayers({ y: amount, isRelative: true }))}>
              ↓
            </StyledMenuButton>
          </div>
          <StyledMenuButton onClick={() => dispatch(moveLayers({ x: amount, isRelative: true }))}>
            →
          </StyledMenuButton>
        </FlexWrapper>
        <NumberInputWrapper>
          <span>{t('StepSize')}</span>
          <Input
            value={amount}
            onChange={onChangeAmountInput}
            type="number"
            step={1}
            min={0}
            addendum={t('Pixel')}
          />
        </NumberInputWrapper>
      </div>

      <div>
        <NumberInputWrapper>
          {t('Rotation')}
          <Input
            type="number"
            ref={rotRef}
            onBlur={onBlurRotInput}
            step={1}
            min={0}
            max={360}
            addendum={t('Degree')}
          />
        </NumberInputWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  align-content: start;
  padding-right: 1rem;
  gap: 1rem;
`;

const Hint = styled.div`
  background-color: var(--color-cta-light);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  border-radius: var(--border-radius);
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const StyledMenuButton = styled(MenuButton)`
  &:hover {
    background-color: var(--color-cta-light) !important;
  }
`;
