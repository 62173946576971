import { useReducer } from 'react';
import { IRGBA, Rgba } from '../../lib/Color';
import { mixPalette } from '../../lib/mixPalette';
import { ITreditionPalette } from './TreditionPalette';
import { hexStringToRgba } from '../../lib/hexToRgba';
import { swap } from '../../lib/swap';

export enum PaletteCreatorActionType {
  Mix,
  SetColorAt,
  SetColorPickTarget,
  SetBias,
  SetIntensity,
  SetName,
  SetPalette,
  SwapColors,
}

export interface ISwapColorsAction {
  type: PaletteCreatorActionType.SwapColors;

  /**
   * Element index `a` to swap with `b`
   */
  a: number;

  /**
   * Element index `b` to swap with `a`
   */
  b: number;
}

export interface ISetEditingColorAction {
  type: PaletteCreatorActionType.SetColorPickTarget;

  /**
   * Marks the index of the palette as the target for the color picker
   * or null to clear the target.
   */
  index: number | null;
}

export interface ISetPaletteAction {
  type: PaletteCreatorActionType.SetPalette;

  /**
   * The new palette state
   */
  palette: IRGBA[];
}

export interface ISetColorAtAction {
  type: PaletteCreatorActionType.SetColorAt;

  /**
   * The color at the index of the palette to overwrite
   */
  index: number;

  /**
   * The new color
   */
  color: IRGBA;
}

export interface ISetIntensityAction {
  type: PaletteCreatorActionType.SetIntensity;

  /**
   * The new intensity
   */
  intensity: number;
}

export interface ISetBiasAction {
  type: PaletteCreatorActionType.SetBias;

  /**
   * The new bias
   */
  bias: PaletteCreatorBias;
}

export interface IMixAction {
  type: PaletteCreatorActionType.Mix;
}

export interface ISetNameAction {
  type: PaletteCreatorActionType.SetName;

  /**
   * The new name of the palette
   */
  name: string;
}

export enum PaletteCreatorBias {
  None,
  MoreRed,
  MoreGreen,
  MoreBlue,
}

export type IPaletteCreatorAction =
  | ISetEditingColorAction
  | ISetPaletteAction
  | ISetColorAtAction
  | ISetIntensityAction
  | ISetBiasAction
  | IMixAction
  | ISetNameAction
  | ISwapColorsAction;

export interface IPaletteCreatorState {
  /**
   * The state of the palette. May be empty if the user has not
   * selected a primary color yet.
   */
  palette: IRGBA[];

  /**
   * An optional id targeting an existing palette which will be updated if saved.
   */
  id: string | null;

  /**
   * The name of the palette
   */
  name: string;

  /**
   * Value of the intensity select. Will be used when generating the palette
   * based on the primary color. A higher intensity means a higher contrast among
   * the palette colors (that's what it looks like at least).
   */
  intensity: number;

  /**
   * Favor a specific color channel when creating the palette.
   */
  bias: PaletteCreatorBias;

  /**
   * The color index of the palette currently being edited or null
   */
  editingColor: number | null;
}

export function colorPaletteCreatorInitializer(
  initialPalette?: ITreditionPalette | null,
): IPaletteCreatorState {
  const convertColor = (treditionPaletteColor: string | null): IRGBA => {
    const fallback = Rgba.black();
    if (!treditionPaletteColor) {
      return fallback;
    }
    return hexStringToRgba(treditionPaletteColor) || fallback;
  };

  const defaults: IPaletteCreatorState = {
    id: null,
    name: '',
    palette: [],
    editingColor: null,
    bias: PaletteCreatorBias.None,
    intensity: 3,
  };

  if (initialPalette) {
    return {
      ...defaults,
      id: initialPalette.id,
      name: initialPalette.name,
      palette: [
        convertColor(initialPalette.color1),
        convertColor(initialPalette.color2),
        convertColor(initialPalette.color3),
        convertColor(initialPalette.color4),
        convertColor(initialPalette.color5),
        convertColor(initialPalette.color6),
      ],
    };
  }
  return defaults;
}

export function colorPaletteReducer(
  state: IPaletteCreatorState,
  action: IPaletteCreatorAction,
): IPaletteCreatorState {
  switch (action.type) {
    case PaletteCreatorActionType.Mix: {
      if (!state.palette.length) {
        return state;
      }
      return {
        ...state,
        palette: mixPalette({
          color: state.palette[0],
          intensity: state.intensity,
          bias: state.bias,
        }),
      };
    }
    case PaletteCreatorActionType.SetColorPickTarget: {
      return {
        ...state,
        editingColor: action.index,
      };
    }
    case PaletteCreatorActionType.SetIntensity: {
      return {
        ...state,
        intensity: action.intensity,
      };
    }
    case PaletteCreatorActionType.SetBias: {
      return {
        ...state,
        bias: action.bias,
      };
    }
    case PaletteCreatorActionType.SetColorAt: {
      if (!state.palette.length) {
        return {
          ...state,
          palette: mixPalette({
            color: action.color,
            intensity: state.intensity,
            bias: state.bias,
          }),
          editingColor: null,
        };
      }
      if (!state.palette[action.index]) {
        return state;
      }
      return {
        ...state,
        palette: state.palette.map((color, index) => {
          if (index === action.index) {
            return action.color;
          }
          return color;
        }),
        editingColor: null,
      };
    }
    case PaletteCreatorActionType.SetPalette: {
      return {
        ...state,
        palette: action.palette,
      };
    }
    case PaletteCreatorActionType.SetName: {
      return {
        ...state,
        name: action.name,
      };
    }
    case PaletteCreatorActionType.SwapColors: {
      const palette = [...state.palette];
      if (!swap(palette, action.a, action.b)) {
        return state;
      }
      return { ...state, palette };
    }
    default:
      return state;
  }
}

export function usePaletteCreatorState(initialPalette?: ITreditionPalette | null) {
  const init = colorPaletteCreatorInitializer(initialPalette);
  return useReducer(colorPaletteReducer, init);
}
