import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IsbnImprintPage.module.css';
import { BarcodeSection } from './components/BarcodeSection';
import { InfoButton } from '../../buttons/info/InfoButton';
import { IsbnsForm } from './components/IsbnsForm';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useGetUserImprintProfilesQuery,
  useGetUserPublisherProfilesQuery,
} from '../../api/treditionApi';
import { NewPublisher } from './components/NewPublisher';
import { NewImprintLabel } from './components/NewImprintLabel';
import { emptyArray } from '../../lib/emptyArray';
import { Form, FormikProps } from 'formik';
import { IIsbnFormData } from './IsbnSchema';
import { ToggleButtonFormik } from '../../forms/toggleButton/ToggleButton.formik';
import { SelectFormik } from '../../select/Select.formik';
import { EditorContext } from '../EditorContext';
import { CreateOrUpdateSelectLayout } from '../../layout/select/CreateOrUpdateSelectLayout';
import { requiredField } from '../stringUtils';
import { IIsbnProduct } from './interfaces/IIsbnPageData';
import { TreditionIsbnForm } from './TreditionIsbnForm';

export interface IIsbnImprintForm {
  formik: FormikProps<IIsbnFormData>;
  projectId: string;
  products: IIsbnProduct[];
}

export const IsbnImprintForm: FC<IIsbnImprintForm> = ({ formik, projectId, products }) => {
  const { t } = useTranslation();
  const { data: userPublishingProfiles, isLoading: userPublishingProfilesLoading } =
    useGetUserPublisherProfilesQuery({ projectId });

  const publishingProfiles = useMemo(() => {
    if (!userPublishingProfiles) return emptyArray;
    return userPublishingProfiles.map((profile) => {
      return {
        value: profile.id,
        label: profile.name,
      };
    });
  }, [userPublishingProfiles]);

  const { data: userImprintProfiles } = useGetUserImprintProfilesQuery({ projectId });

  const imprintProfiles = useMemo(() => {
    if (!userImprintProfiles) return emptyArray;
    return userImprintProfiles.map((profile) => {
      return {
        value: profile.id,
        label: profile.name,
      };
    });
  }, [userImprintProfiles]);

  const { values, setValues } = formik;
  const { needIsbn, hasImprintLabel } = values;

  const context = useContext(EditorContext);
  const {
    state: { isReadonly },
  } = context;

  const handlePublisherProfileChange = useCallback(
    (publisherProfileId: string) => {
      setValues((values) => ({ ...values, publisherProfileId }));
      setPublisherCreating(false);
    },
    [setValues],
  );
  const handleImprintProfileChange = useCallback(
    (imprintProfileId: string) => {
      setValues((values) => ({ ...values, imprintProfileId }));
      setImprintLabelCreating(false);
    },
    [setValues],
  );

  const [isPublisherCreating, setPublisherCreating] = useState<boolean>(false);
  const [isImprintLabelCreating, setImprintLabelCreating] = useState<boolean>(false);

  return (
    <Form>
      <div className={styles.questionGroup}>
        <div className={styles.headline}>
          <div className={styles.infoWrapper}>
            <h2>{requiredField(t('ISBNQuestion1'))}</h2>
            <InfoButton infoText={`${t('ISBNQuestion1Info')}*`} />
          </div>
          <ToggleButtonFormik
            disabled={isReadonly}
            name={'needIsbn'}
            label="tredition isbn"
            labelHidden
            yesText={t('Yes')}
            noText={t('No')}
          />
        </div>
        <AnimatePresence>
          {/* do not open accordion if no values are set */}
          {needIsbn !== null && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              transition={{ type: 'tween', duration: 0.25 }}
              variants={{
                open: { height: 'auto' },
                collapsed: { height: 0 },
              }}>
              <motion.div
                variants={{
                  collapsed: { opacity: 0 },
                  open: { opacity: 1, transition: { delay: 0.1 } },
                }}
                transition={{ duration: 0 }}
                className={styles.container}>
                <span>{t('ISBNsMessage')}</span>
                <div className={styles.flex}>
                  {needIsbn ? (
                    <TreditionIsbnForm projectId={projectId} products={products} />
                  ) : (
                    <IsbnsForm products={products} />
                  )}
                  <BarcodeSection products={products} />
                </div>
              </motion.div>
              {!needIsbn && (
                <>
                  <CreateOrUpdateSelectLayout
                    hideSelect={publishingProfiles.length === 0}
                    isReadonly={isReadonly}
                    selectComponent={
                      <SelectFormik
                        isDisabled={isReadonly}
                        name="publisherProfileId"
                        options={publishingProfiles}
                        isLoading={userPublishingProfilesLoading}
                        label={`${t('PublisherProfile')}*`}
                        className={styles.select}
                      />
                    }
                    buttonLabel={t('NewPublisherProfile')}
                    onNewClick={() => setPublisherCreating(true)}
                  />
                  {isPublisherCreating && (
                    <NewPublisher
                      onNewPublisher={handlePublisherProfileChange}
                      onCancel={() => setPublisherCreating(false)}
                    />
                  )}
                </>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={styles.questionGroup}>
        <div className={styles.headline}>
          <div className={styles.infoWrapper}>
            <h2>{requiredField(t('ISBNQuestion2'))}</h2>
            <InfoButton infoText={t('OwnImprintDescription')} />
          </div>
          <ToggleButtonFormik
            disabled={isReadonly}
            name={'hasImprintLabel'}
            label="own imprint"
            labelHidden
            yesText={t('Yes')}
            noText={t('No')}
          />
        </div>
        <AnimatePresence>
          {hasImprintLabel === true && (
            <>
              <CreateOrUpdateSelectLayout
                hideSelect={imprintProfiles.length === 0}
                isReadonly={isReadonly}
                selectComponent={
                  <SelectFormik
                    isDisabled={isReadonly}
                    name="imprintProfileId"
                    options={imprintProfiles}
                    isLoading={userPublishingProfilesLoading}
                    label={`${t('ImprintProfile')}*`}
                    className={styles.select}
                  />
                }
                buttonLabel={t('NewImprintProfile')}
                onNewClick={() => setImprintLabelCreating(true)}
              />
              {isImprintLabelCreating && (
                <NewImprintLabel
                  onImprintLabelAdded={handleImprintProfileChange}
                  onCancel={() => setImprintLabelCreating(false)}
                />
              )}
            </>
          )}
        </AnimatePresence>
      </div>
    </Form>
  );
};
