import { FC, PropsWithChildren, useMemo } from 'react';
import './Alert.css';
import { Icon, IIconName } from '../icons/Icon';

export interface IAlertProps {
  level: 'error' | 'warning' | 'info';
}

interface IMemo {
  className: string;
  icon: IIconName | null;
}

export const Alert: FC<PropsWithChildren<IAlertProps>> = ({ children, level }) => {
  const { className, icon } = useMemo((): IMemo => {
    switch (level) {
      case 'error':
        return { className: 'alert error', icon: 'achtung-control' };
      case 'warning':
        return { className: 'alert warning', icon: 'achtung-control' };
      case 'info':
      default:
        return { className: 'alert error', icon: 'info-control' };
    }
  }, [level]);

  return (
    <div role="alert" className={className}>
      {!!icon && <Icon name={icon} />}
      <div className="alert-content">{children}</div>
    </div>
  );
};
