import { FC, ReactNode, useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { Button } from '../uiComponents/Button';
import { Slider } from '../uiComponents/Slider';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

/**
 * Payload passed to the `onCrop` handler
 */
export interface IOnCropData {
  /**
   * The crop area as requested by the user
   */
  area: ICropArea;
}

export interface IImageCropperProps {
  /**
   * Invoked when the submit button of the cropper is pressed
   */
  onCrop: (data: IOnCropData) => unknown;

  /**
   * Customizable render prop of the footer. Provides the `onSubmitCrop` function which can be
   * invoked by the rendered footer to submit the cropping area.
   */
  renderFooter?: (props: { onSubmitCrop: () => void; isSubmitDisabled?: boolean }) => ReactNode;

  /**
   * Initial aspect ratio of the crop shape. Defaults to 1
   */
  initialAspect?: number;

  /**
   * Object URL of the image to crop.
   */
  imageSrc: string;

  /**
   * If true disables the submit button. Passed to the `renderFooter` method, so you can disable
   * your customized submit button.
   */
  isSubmitDisabled?: boolean;
}

export type ICropAreaType = 'rect' | 'round';

/**
 * Cropping area as requested by the user
 */
export interface ICropArea {
  type: ICropAreaType;
  x: number;
  y: number;
  width: number;
  height: number;
}

export const ImageCropper: FC<IImageCropperProps> = ({
  imageSrc,
  isSubmitDisabled,
  initialAspect,
  onCrop,
  renderFooter,
}) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(initialAspect);
  const [cropShape, setCropShape] = useState<ICropAreaType>('rect');
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (!croppedAreaPixels) {
      return;
    }
    onCrop({ area: { ...croppedAreaPixels, type: cropShape } });
  }, [croppedAreaPixels, cropShape, onCrop]);

  return (
    <div className="image-cropper">
      {/* FIXME: Round shape currently broken server-side (DEV-I172)
        <Button
          onClick={() => setCropShape('round')}
          cancel={cropShape !== 'round'}
          style={{ borderRadius: 0, display: 'inline-block' }}>
          {t('CropShapeRound')}
        </Button>
        <Button
          onClick={() => setCropShape('rect')}
          cancel={cropShape !== 'rect'}
          style={{ borderRadius: 0, display: 'inline-block' }}>
          {t('CropShapeRect')}
        </Button>
         */}
      <CropArea>
        <Cropper
          image={imageSrc}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          cropShape={cropShape}
          showGrid={false}
          aspect={aspect}
          crop={crop}
          zoom={zoom}
          minZoom={0.1}
          maxZoom={5}
          rotation={rotation}
          onRotationChange={setRotation}
          onZoomChange={setZoom}
        />
      </CropArea>
      <CropControls>
        <label>
          <div>{t('Zoom')}</div>
          <Slider min={0.1} max={5} step={0.1} value={zoom} onChange={(v) => setZoom(v)} />
        </label>
        <label>
          <div>{t('AspectRatio')}</div>
          <Slider min={0.01} max={5} step={0.1} value={aspect} onChange={(v) => setAspect(v)} />
        </label>
      </CropControls>
      {renderFooter ? (
        renderFooter({ onSubmitCrop: onClickSubmit, isSubmitDisabled })
      ) : (
        <CropFooter>
          <Button onClick={onClickSubmit} disabled={isSubmitDisabled}>
            {t('OK')}
          </Button>
        </CropFooter>
      )}
    </div>
  );
};

const CropArea = styled.div`
  position: relative;
  width: 100%;
  min-width: 50vw;
  min-height: 50vh;
`;

const CropControls = styled.div`
  display: grid;
  justify-items: center;

  & > label {
    margin-top: 1rem;
    text-align: center;
    font-weight: 600;
    width: 100%;
  }

  input[type='range'] {
    width: 100%;
    max-width: 50%;
  }
`;

const CropFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    min-width: 5rem;
  }
`;
