import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { emptyArray } from '../lib/emptyArray';
import { IListProjectsFilter, ListProjectsOrder, useListProjectsQuery } from '../api/treditionApi';
import styles from './ProjectsPage.module.css';
import { ProjectsListView } from './ProjectsListView';
import { MainBookWizardLayout } from '../layout/mainBookWizard/MainBookWizardLayout';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../redux/hooks';
import { push } from 'redux-first-history';
import { Loader } from '../loader/Loader';
import { RadioGroup } from '../forms/radioGroup/RadioGroup';
import { IOption } from '../../@types/IOption';
import { Button } from '../uiComponents/Button';
import { forceRefreshBlazor } from '../interop/forceRefreshBlazor';

export const defaultFilter = Object.seal<IListProjectsFilter>({
  order: ListProjectsOrder.Date,
  page: 0,
});

export const ProjectsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IListProjectsFilter>(defaultFilter);
  const query = useListProjectsQuery(filter);
  const { data: projects = emptyArray } = query;

  const orderOptions = useMemo<IOption<ListProjectsOrder>[]>(
    () => [
      { value: ListProjectsOrder.Date, label: t('Date') },
      { value: ListProjectsOrder.Title, label: t('Title') },
    ],
    [t],
  );

  const onChangeOrder = useCallback(
    (order: ListProjectsOrder) => {
      setFilter({ ...filter, order });
    },
    [filter],
  );

  const onClickEntry = useCallback(
    (projectId: string) => {
      dispatch(push(`/projects/${projectId}`));
    },
    [dispatch],
  );

  const onClickCreateProject = useCallback(() => {
    dispatch(push('/newbook'));
    forceRefreshBlazor();
  }, [dispatch]);

  return (
    <MainBookWizardLayout headline={t('Projects')}>
      <div className={styles.wrapper}>
        {query.isFetching && <Loader />}
        {!query.isLoading && (
          <Fragment>
            <div className={styles.aboveList}>
              <div className={styles.filter}>
                <span>{t('Sort')}:</span>
                <RadioGroup
                  onChange={onChangeOrder}
                  value={filter.order}
                  name="order"
                  options={orderOptions}
                />
              </div>
              <Button onClick={onClickCreateProject}>{t('NewProject')}</Button>
            </div>
            <ProjectsListView entries={projects} onClickEntry={onClickEntry} />
          </Fragment>
        )}
      </div>
    </MainBookWizardLayout>
  );
};
