import { ICMYK, IRGB, IRGBA } from './Color';

/**
 * Converts an RGB(A) value to CMYK. Note that the alpha channel is
 * not considered.
 */
export function rgbToCmyk({ r, g, b }: IRGB | IRGBA): ICMYK {
  const k = 1 - Math.max(r, g, b);
  if (k === 1) {
    return { c: 0, m: 0, y: 0, k };
  }
  const invK = 1 - k;
  return {
    c: (invK - r) / invK,
    m: (invK - g) / invK,
    y: (invK - b) / invK,
    k,
  };
}
