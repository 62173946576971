import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';

export function toggleOverflowReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<boolean | undefined>,
) {
  if (typeof action.payload === 'boolean') {
    state.showOverflow = action.payload;
  } else {
    state.showOverflow = !state.showOverflow;
  }
}
