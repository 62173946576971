import { BasicButton, ButtonSize } from '../../buttons/basic/BasicButton';
import { Header } from '../../header/Header';
import { FC } from 'react';
import styles from './ProjectEditPage.module.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export interface IProjectEditPage {}

export const ProjectEditPage: FC<IProjectEditPage> = () => {
  const { t } = useTranslation();

  const { projectId } = useParams();

  return (
    <div className={styles.wrapper}>
      <Header />
      <h1>ProjectPage</h1>
      <BasicButton
        size={ButtonSize.large}
        label={t('Edit')}
        onClick={() => {
          window.open(`/newbook/category/${projectId}`, '_self');
        }}
      />
    </div>
  );
};
