import * as yup from 'yup';
import { t } from 'i18next';

export const createRegistrationSchema = () =>
  yup.object().shape({
    email: yup.string().email().required(),
    emailConfirm: yup
      .string()
      .email()
      .oneOf([yup.ref('email')], t('ConfirmEmailNotMatchesEmail'))
      .required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], '')
      .required(),
    generalTerms: yup.boolean().oneOf([true], t('GTMustBeAccepted')),
  });
