import { Command } from "ckeditor5/src/core";
import { LETTER_SPACING } from "./CharacterSpacingUI";

export default class CharacterSpacingCommand extends Command {
  constructor(editor) {
    super(editor);
    this.attributeKey = LETTER_SPACING;
  }

  /**
   * @inheritDoc
   */
  refresh() {
    const model = this.editor.model;
    const doc = model.document;

    this.value = doc.selection.getAttribute(this.attributeKey);
    this.isEnabled = model.schema.checkAttributeInSelection(
      doc.selection,
      this.attributeKey
    );
  }

  /**
   * Executes the command. Applies the `value` of the {@link #attributeKey} to the selection.
   * If no `value` is passed, it removes the attribute from the selection.
   *
   * @protected
   * @param {Object} [options] Options for the executed command.
   * @param {String} [options.value] The value to apply.
   * @fires execute
   */
  execute(options = {}) {
    const model = this.editor.model;
    const document = model.document;
    const selection = document.selection;

    const value = options.value;

    model.change((writer) => {
      if (selection.isCollapsed) {
        if (value) {
          writer.setSelectionAttribute(this.attributeKey, value);
        } else {
          writer.removeSelectionAttribute(this.attributeKey);
        }
      } else {
        const ranges = model.schema.getValidRanges(
          selection.getRanges(),
          this.attributeKey
        );

        for (const range of ranges) {
          if (value) {
            writer.setAttribute(this.attributeKey, value, range);
          } else {
            writer.removeAttribute(this.attributeKey, range);
          }
        }
      }
    });
  }
}
