import { FC, PropsWithChildren } from 'react';
import { IRegion } from '../project/ProductState';
import { px } from '../../lib/px';
import classNames from 'classnames';

export interface ISheetRegionProps {
  region: IRegion;
  className?: string;
}

export const SheetRegion: FC<PropsWithChildren<ISheetRegionProps>> = ({
  region,
  children,
  className,
}) => {
  return (
    <div
      className={classNames('region', region.id, className)}
      key={region.id}
      style={{
        position: 'absolute',
        left: px(region.x),
        top: px(region.y),
        width: px(region.width),
        height: px(region.height),
      }}>
      {children}
    </div>
  );
};
