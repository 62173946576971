import { FC } from 'react';
import './Icon.css';
import classNames from 'classnames';

export interface IIconProps {
  name: IIconName;
  small?: boolean;
  large?: boolean;
  size?: string;
  className?: string;
  title?: string;
}

export const Icon: FC<IIconProps> = ({ className, name, small, large, size, title }) => {
  return (
    <span
      className={classNames(
        'tredition-icon',
        `ti-${name}`,
        {
          small,
          large,
        },
        className,
      )}
      title={title}
      style={{ fontSize: size || undefined }}
    />
  );
};

export const IconNames: string[] = [
  '3d-ansicht',
  '3d-ansicht2',
  '3d-ansicht-control',
  'abstand-nach-absatz',
  'abstand-nach-ueberschrift',
  'acht',
  'achtung',
  'achtung-control',
  'affiliate-programm',
  'aktualisieren',
  'aktualisieren-control',
  'ansehen-control',
  'ansehen-vorschau',
  'ansprechpartner-mitarbeiten',
  'app-switch',
  'audiobuch',
  'ausrichtenmitte-control',
  'ausrichtenoben-control',
  'ausrichtenunten-control',
  'auto-satz',
  'autor',
  'avatar',
  'avatar-control',
  'barcode',
  'barcode-control',
  'berichte',
  'bilder',
  'blocksatz-control',
  'bold-control',
  'buch',
  'bucherfolg',
  'buchgestaltung-projekt',
  'buchhandel',
  'buchmarketing',
  'buchproduktion',
  'buchpruefung',
  'buchtypen',
  'buchvertrieb',
  'chat',
  'check-control',
  'cloud',
  'coverdesigner',
  'dashboard',
  'diagrammansicht-control',
  'diagrammansicht-kreis',
  'dokument',
  'download1',
  'download-control',
  'drei',
  'drei-punkte',
  'dreipunkte-control',
  'dropdown-control',
  'dropup-control',
  'druckansicht',
  'druckansicht-control',
  'e-book',
  'ebenen',
  'ebenen-control',
  'ebenenachhinten-control',
  'ebenenachvorne-control',
  'ebenenanordnung-control',
  'ebook-control',
  'eco-control',
  'editieren',
  'editieren-control',
  'einkaufstasche',
  'einkaufstasche-control',
  'eins',
  'einzug-erste-zeile',
  'elemente',
  'empfohlen',
  'empfohlen-control',
  'farbpalette',
  'farbpalette-control',
  'filter',
  'filter-control',
  'flaeche-control',
  'fliesstext',
  'fliesstext-control',
  'fragezeichen',
  'fragezeichen-control',
  'fuellfarbe1-control',
  'fuellfarbe2-control',
  'fuenf',
  'fusszeile',
  'fusszeile-control',
  'fusszeile-gerade-seiten-kopie-2',
  'fusszeile-ungerade-seiten-kopie-3',
  'ganznachhinten-control',
  'ganznachvorne2-control',
  'ganznachvorne-control',
  'glueckliche-kunden',
  'grafiken-control',
  'grossdruck-control',
  'grossschreibung-control',
  'grundeinstellungen1',
  'grundeinstellungen2',
  'grundeinstellungen-control',
  'hardcover',
  'hardcover-control',
  'herz2-control',
  'herz-control',
  'herz-hamburg',
  'hilfebereich',
  'hilfebereich-control',
  'individualisierte-sonderausgabe',
  'info',
  'info-control',
  'international',
  'international-check-control',
  'international-control',
  'internationaler-vertrieb',
  'italic-control',
  'kachelansicht',
  'kacheln1-control',
  'kacheln2-control',
  'kamera-control',
  'kommentar',
  'kontakt',
  'kontur-control',
  'kopfzeile',
  'kopfzeile-control',
  'kopfzeile-gerade-seiten',
  'kopfzeile-ungerade-seiten-kopie',
  'kopieren',
  'kopieren-control',
  'korrektorat',
  'kreative-freiheit',
  'layout',
  'lektorat',
  'lineal1',
  'lineal2',
  'lineal-control',
  'link-control',
  'linksbuendig-control',
  'listenansicht',
  'listenansicht-control',
  'listenansicht-kreis',
  'loeschen',
  'loeschen-control',
  'lupe1',
  'lupe2',
  'lupe-control',
  'marker-control',
  'marketingplan',
  'marketingvorlagen-marketing-app',
  'materialauswahl',
  'metadaten',
  'minus-control',
  'mitteilungen',
  'mitteilungen-control',
  'module',
  'muster-formen',
  'neuauflage',
  'neun',
  'objekte-control',
  'online-shops',
  'ordner-geschlossen',
  'ordner-offen',
  'paperback',
  'paperback-control',
  'papier1',
  'papier2',
  'pfeillinks2',
  'pfeillinks-control',
  'pfeilnachoben-control',
  'pfeilrechts2',
  'pfeilrechts-control',
  'plus-control',
  'pressemitteilung',
  'proof1',
  'proof2',
  'proof3',
  'radiergummi-control',
  'raender',
  'rahmen1-control',
  'rahmen2-control',
  'raster',
  'raster2',
  'raster-control',
  'rechtsbuendig-control',
  'rueckgaengig-control',
  'schliessen-control',
  'schlossauf-control',
  'schlosszu-control',
  'schriftfarbe1-control',
  'schriftfarbe2-control',
  'sechs',
  'seitenraender',
  'seitenraender-aussen',
  'seitenraender-control',
  'seitenraender-innen',
  'seitenraender-oben',
  'seitenraender-unten',
  'shop-buchtypengrafiken-e-book',
  'shop-buchtypengrafiken-hardcover',
  'shop-buchtypengrafiken-paperback',
  'sieben',
  'skalieren1-control',
  'skalieren2-control',
  'sortieren',
  'sortieren-control',
  'soziales-engagement',
  'speichern',
  'spiegeln-horizontal1-control',
  'spiegeln-horizontal2-controlavav',
  'spiegeln-vertikal1-control',
  'spiegeln-vertikal2-control',
  'sprachauswahl-nur-sprache',
  'sprachauswahl-sprache-land',
  'taschenrechner-control',
  'texte-control',
  'textelemente',
  'tipp',
  'tipp-control',
  'tools',
  'ueberschriften',
  'ueberschriften-control',
  'uebersetzung-de-en',
  'underlined-control',
  'upload-control',
  'uploads-control',
  'verkaufsdaten',
  'vertriebsdeals-einkaufsrabatte',
  'verzeichnis',
  'verzeichnis-control',
  'vier',
  'vorlagen-control',
  'wagen-control',
  'warenkorb-shop',
  'wechsel2-control',
  'wechsel-control',
  'weiterlesen1-control',
  'weiterlesen2-control',
  'werbematerialien',
  'wuerfel-control',
  'zeichenabstand-control',
  'zeilenabstand',
  'zeilenabstand-control',
  'zentriert-control',
  'zielgruppe',
  'zoom-in',
  'zoom-out',
  'zuschneiden-control',
  'zwei',
];

export type IIconName = (typeof IconNames)[number];
