import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryState } from '../IHistoryState';
import { createInitialHistoryState, HistoryRecordType, record } from '../historySlice';
import { recordReducer } from '../recordReducer';
import { createHistoryRecord } from '../createHistoryRecord';
import { ISetProjectActionPayload } from '../../project/projectActions';

/**
 * Reacts to {@link setProject} by initializing a new history state
 */
export function recordBySetProjectActionReducer(
  state: Draft<IHistoryState>,
  action: PayloadAction<ISetProjectActionPayload>,
): IHistoryState {
  const { payload: project } = action;
  if (!project) {
    return state;
  }
  const history = createInitialHistoryState();
  ++history.revision;
  for (const product of project.products) {
    const r = createHistoryRecord({
      type: HistoryRecordType.ProjectInitialized,
      palette: product.palette,
      sheets: product.sheets,
      activeSheetId: product.activeSheetId,
    });
    recordReducer(history, record({ record: r, productId: product.id }));
  }
  return history;
}
