import { IContributor, TitleFromApi } from './interfaces/IContributor';
import React, { FC } from 'react';
import styles from './ContributorHeader.module.css';

export interface IContributorHeader {
  contributor: IContributor;
}

export const ContributorHeader: FC<IContributorHeader> = ({ contributor }) => {
  const { lastName, firstName, companyName, isPerson, title: personTitle } = contributor;

  const title = (() => {
    switch (personTitle) {
      case TitleFromApi.Dr:
        return 'Dr.';
      case TitleFromApi.Prof:
        return 'Prof.';
      case TitleFromApi.ProfDr:
        return 'Prof. Dr.';
      default:
        return '';
    }
  })();

  return isPerson ? (
    <h4 className={styles.truncate}>
      {title} {firstName} {lastName}
    </h4>
  ) : (
    <h4>{companyName}</h4>
  );
};
