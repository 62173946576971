import * as yup from 'yup';

export interface IImprintSchema {
  name: string;
}

export const createImprintSchema = () =>
  yup.object().shape({
    name: yup.string().required(),
  });
