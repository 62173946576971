import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, FormikProps, FormikProvider } from 'formik';
import { IProjectDataFormValues } from './ProjectDataForm.schema';
import styles from './ProjectDataForm.module.css';
import { TextInputFormik } from '../../forms/textInput/TextInput.formik';
import { Accordion } from '../../shared/accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { NumberInputFormik } from '../../forms/numberInput/NumberInput.formik';
import { DatePickerFormik } from '../../date/DatePicker.formik';
import { SelectFormik } from '../../select/Select.formik';
import { AlertBox, AlertBoxMood } from '../../alertBox/AlertBox';
import { InfoButton } from '../../buttons/info/InfoButton';
import { ToggleButtonFormik } from '../../forms/toggleButton/ToggleButton.formik';
import {
  useGetProjectLanguageOptionsQuery,
  useGetSeriesEditorOptionsQuery,
  useGetSeriesQuery,
} from '../../api/treditionApi';
import { emptyArray, ensureArray } from '../../lib/emptyArray';
import { NewEditor } from './NewEditor';
import { CheckBoxFormik } from '../../forms/checkBox/CheckBox.formik';
import { EditorContext } from '../EditorContext';
import { CreateOrUpdateSelectLayout } from '../../layout/select/CreateOrUpdateSelectLayout';
import { IOption } from '../../../@types/IOption';
import { Spacer } from '../../shared/spacer/Spacer';
import { Link } from '../../shared/link/Link';

export const initialProjectDataValues: IProjectDataFormValues = {
  title: '',
  subtitle: '',
  series: null,
  hasEdition: false,
  hasSeries: false,
  hasAiGeneratedContent: null,
  edition: null,
  languages: [],
  existingSeriesId: '',
};

interface IFormikAware {
  formik: FormikProps<IProjectDataFormValues>;
}

export interface IProjectDataFormProps extends IFormikAware {
  hasDuplicateSeries: boolean;
  projectId: string;
}

export const EditionSection: FC<IFormikAware> = ({ formik }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;
  const hasEdition = values.hasEdition;
  const edition = values.edition || null;

  const context = useContext(EditorContext);
  const {
    state: { isReadonly },
  } = context;

  useEffect(() => {
    if (hasEdition && !edition) {
      setFieldValue(
        'edition',
        {
          number: 1,
          date: null,
        },
        false,
      );
    }
  }, [hasEdition, edition, setFieldValue]);

  if (!edition) {
    return null;
  }
  return (
    <Fragment>
      <NumberInputFormik
        labelPosition="top"
        name="edition.number"
        label={`${t('CurrentEditionNumber')}*`}
        disabled={isReadonly}
      />
      <DatePickerFormik
        dateFormat="yyyy"
        showYearPicker
        name="edition.date"
        label={`${t('PreviousEditionYear')}*`}
        disabled={isReadonly}
      />
    </Fragment>
  );
};

export interface ISeriesSectionProps extends IFormikAware {
  hasDuplicateSeries: boolean;
  projectId: string;
}

export const SeriesSection: FC<ISeriesSectionProps> = ({
  formik,
  hasDuplicateSeries,
  projectId,
}) => {
  const { t } = useTranslation();
  const [isCreatingEditor, setCreatingEditor] = useState<boolean>(false);
  const { values, setFieldValue } = formik;
  const hasSeries = values.hasSeries;
  const series = values.series || null;
  const hasEditor = series?.hasEditor || false;

  const seriesQuery = useGetSeriesQuery(projectId);
  const { isFetching: areSeriesLoading, data: existingSeries = emptyArray } = seriesQuery;
  const editorOptionsQuery = useGetSeriesEditorOptionsQuery(projectId);
  const { isFetching: areEditorOptionsLoading, data: editorOptions = emptyArray } =
    editorOptionsQuery;

  const seriesOptions = useMemo<IOption<string>[]>(() => {
    const options: IOption<string>[] = [{ value: '', label: t('NewBookSeries') }];
    if (!existingSeries.length) {
      return options;
    }
    return [
      ...options,
      ...existingSeries.map<IOption<string>>((s) => ({
        value: s.id,
        label: s.title,
      })),
    ];
  }, [existingSeries, t]);

  const context = useContext(EditorContext);
  const {
    state: { isReadonly },
  } = context;

  useEffect(() => {
    if (hasSeries && !series) {
      setFieldValue(
        'series',
        {
          number: 1,
          title: '',
          subtitle: '',
          hasEditor: false,
          editorId: null,
        },
        false,
      );
    }
  }, [hasSeries, series, setFieldValue]);

  const onEditorCreated = useCallback(
    (id: string) => {
      editorOptionsQuery.refetch();
      setFieldValue('series.hasEditor', true);
      setFieldValue('series.editorId', id);
      setCreatingEditor(false);
    },
    [setFieldValue, editorOptionsQuery],
  );

  const onChangeSeriesId = useCallback(
    (existingSeriesId: string | null) => {
      if (!existingSeriesId) {
        setFieldValue('series', {
          number: 1,
          title: '',
          hasEditor: false,
          editorId: null,
        });
      }
      const series = existingSeries.find(($) => $.id === existingSeriesId);
      if (!series) {
        return;
      }
      setFieldValue('series', {
        id: existingSeriesId,
        number: Number.parseInt(series.number),
        title: series.title || '',
        hasEditor: series.hasEditor,
        editorId: series.editorId,
      });
    },
    [setFieldValue, existingSeries],
  );

  const onCancelCreateEditor = useCallback(() => {
    setCreatingEditor(false);
  }, []);

  if (!series) {
    return null;
  }

  return (
    <Fragment>
      {hasDuplicateSeries && (
        <div style={{ marginTop: '1rem' }}>
          <AlertBox mood={AlertBoxMood.danger}>{t('DuplicateSeriesError')}</AlertBox>
        </div>
      )}
      {seriesOptions.length > 1 && (
        <SelectFormik
          options={seriesOptions}
          isLoading={areSeriesLoading}
          isDisabled={isReadonly}
          isMulti={false}
          onChange={onChangeSeriesId}
          label={`${t('BookSeries')}*`}
          name="existingSeriesId"
        />
      )}
      <NumberInputFormik
        disabled={isReadonly}
        labelPosition="top"
        name="series.number"
        label={`${t('BookSeriesNumber')}*`}
      />
      {!series.id && (
        <Fragment>
          <TextInputFormik
            disabled={isReadonly}
            name="series.title"
            label={`${t('BookSeriesTitle')}*`}
          />
          <CheckBoxFormik
            disabled={isReadonly}
            name="series.hasEditor"
            label={t('BookSeriesEditorLabel')}
          />
          {hasEditor && (
            <CreateOrUpdateSelectLayout
              hideSelect={editorOptions.length === 0}
              isReadonly={isReadonly}
              selectComponent={
                <SelectFormik
                  isDisabled={isReadonly}
                  options={editorOptions}
                  name="series.editorId"
                  label={`${t('Editor')}*`}
                  isLoading={areEditorOptionsLoading}
                />
              }
              buttonLabel={t('CreateNewProfile')}
              onNewClick={() => setCreatingEditor(true)}
            />
          )}
          {isCreatingEditor && (
            <NewEditor
              projectId={projectId}
              onEditorCreated={onEditorCreated}
              onCancel={onCancelCreateEditor}
            />
          )}
        </Fragment>
      )}
      {seriesOptions.length > 1 && !isReadonly && (
        <>
          <Link href={'https://my.tredition.com/myaccount/profiles'}>{t('EditBookSeries')}</Link>
          <Spacer size={8} />
        </>
      )}
    </Fragment>
  );
};

export const ProjectDataForm: FC<IProjectDataFormProps> = ({
  formik,
  hasDuplicateSeries,
  projectId,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, values } = formik;
  const { data: options, isLoading } = useGetProjectLanguageOptionsQuery(projectId);

  const context = useContext(EditorContext);
  const {
    state: { isReadonly, isBookUpdate },
  } = context;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit} className={styles.container}>
        <div className={styles.basicData}>
          <TextInputFormik
            disabled={isReadonly || isBookUpdate}
            name="title"
            label={`${t('Title')}*`}
          />
          <TextInputFormik
            disabled={isReadonly || isBookUpdate}
            name="subtitle"
            label={t('Subtitle')}
          />
          <SelectFormik
            isDisabled={isReadonly}
            options={ensureArray(options)}
            name="languages"
            label={`${t('BookLanguages')}*`}
            isLoading={isLoading}
            isMulti
          />
        </div>
        <div className={styles.series}>
          <Spacer size={8} />
          <div className={styles.flexColumn}>
            <div className={styles.question}>
              <h4>{t('BookSeriesQuestion')}</h4>
              <InfoButton className={styles.info} infoText={t('BookSeriesInfo')} />
              <ToggleButtonFormik
                disabled={isReadonly}
                labelHidden
                yesText={t('Yes')}
                noText={t('No')}
                name="hasSeries"
                label={t('BookSeriesQuestion')}
              />
            </div>
            <Accordion className={styles.accordion} controlled isOpen={!!values.hasSeries}>
              <SeriesSection
                formik={formik}
                hasDuplicateSeries={hasDuplicateSeries}
                projectId={projectId}
              />
            </Accordion>
          </div>
          <Spacer size={16} />
          <div className={styles.flexColumn}>
            <div className={styles.question}>
              <h4>{t('NewEditionQuestion')}</h4>
              <InfoButton
                className={styles.info}
                infoText={t('NewEditionInfo')}
                dangerouslySetInnerHTML
              />
              <ToggleButtonFormik
                disabled={isReadonly}
                labelHidden
                yesText={t('Yes')}
                noText={t('No')}
                label={t('NewEditionQuestion  ')}
                name="hasEdition"
              />
            </div>
            <Accordion className={styles.accordion} controlled isOpen={!!values.hasEdition}>
              <EditionSection formik={formik} />
            </Accordion>
          </div>
          <Spacer size={16} />
          <div className={styles.flexColumn}>
            <div className={styles.question}>
              <h4>{t('AiGeneratedContentQuestion')}</h4>
              <InfoButton
                className={styles.info}
                infoText={t('AiGeneratedContentQuestionInfo')}
                dangerouslySetInnerHTML
              />
              <ToggleButtonFormik
                disabled={isReadonly}
                labelHidden
                yesText={t('Yes')}
                noText={t('No')}
                label={t('AiGeneratedContentQuestion')}
                name="hasAiGeneratedContent"
              />
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
