import { IColor } from '../palette/IColor';

export enum LayerError {
  None,
  ImageFailedToLoad,
}

export type ILayerUnion = ITextLayer | IImageLayer;

/**
 * Layer base type
 */
export interface ILayer {
  /**
   * GUID of this layer
   */
  id: string;

  /**
   * Epoch timestamp of creation of this layer
   */
  createdAt: number;

  /**
   * Epoch timestamp of the latest change to this layer.
   *
   * Will be equal to {@link createdAt} if the layer has not been changed since its creation.
   */
  updatedAt: number;

  /**
   * The type which is used to infer the type of this layer at runtime
   */
  type: LayerType;

  error: LayerError;

  /**
   * Layer Z depth. Higher values will cause the layer to appear above others.
   */
  depth: number;

  /**
   * Label to be displayed in various parts of the designer, for example in the layer list.
   */
  label: string;

  /**
   * Whether the user is currently holding this layer, typically by the main mouse button or pointer.
   */
  isGrabbed: boolean;

  /**
   * Whether this layer is considered for layer actions, such as translation.
   */
  isSelected: boolean;

  /**
   * Whether the pointer is currently hovering this layer.
   */
  isHovered: boolean;

  /**
   * Whether this layer can be deleted
   */
  isDeletable: boolean;

  /**
   * Whether this layer can be scaled using the bounding box handles
   */
  isScalable: boolean;

  /**
   * Whether this layer can be rotated
   */
  isRotatable: boolean;

  /**
   * Whether the layer has initialized its size after being created. This is required because we do
   * not always know the size which depends on the content the user supplied. The layer has to be rendered once in that
   * case to determine its dimensions.
   */
  hasDimensions: boolean;

  /**
   * If true will cause the layer to be scaled proportionally when scaled into one direction.
   *
   * Note: In production this is only supported on image template objects (`imageMode`). Changing this
   *       behaviour is breaking because the UI does not support proportional scaling on other template objects.
   */
  keepAspectRatio: boolean;

  /**
   * X-Coordinate relative to the designer area
   */
  x: number;

  /**
   * Y-Coordinate relative to the designer area
   */
  y: number;

  /**
   * Width of the layer
   */
  width: number;

  /**
   * Height of the layer
   */
  height: number;

  /**
   * @see ILayerTransform
   */
  transform: ILayerTransform;

  /**
   * @see ILayerBackground
   */
  background: ILayerBackground;

  /**
   * @see ILayerEffect
   */
  effect: ILayerEffect;

  /**
   * Whether this layer is allowed to have effects.
   *
   * TODO: add allow list (rotation, effects, translation, move, etc.)
   */
  allowEffects: boolean;

  /**
   * @see ILayerBorder
   */
  border: ILayerBorder;

  /**
   * @see ILayerDisplay
   */
  display: ILayerDisplay;

  /**
   * Padding amount in pixels
   */
  padding: number;
}

/**
 * Intermediate rendering data of layers, might be different from the actual layer data
 *
 * This is used to provide transformation previews without live-manipulating layer data which is required
 * for relative transforms.
 */
export interface ILayerDisplay {
  /**
   * Relates to {@link ILayerDisplay.x}
   */
  x: number;

  /**
   * Relates to {@link ILayerDisplay.y}
   */
  y: number;

  /**
   * Relates to {@link ILayerDisplay.width}
   */
  width: number;

  /**
   * Relates to {@link ILayerDisplay.height}
   */
  height: number;

  /**
   * Relates to {@link ILayerDisplay.rotation}
   */
  rotation: number;
}

export interface ILayerBorder {
  /**
   * Size of the border in pixels
   */
  size: number;

  /**
   * Border color
   */
  color: ILayerColor | null;
}

export interface ILayerEffect {
  /**
   * Blur percentage from 0% to 100%
   */
  blur: number;

  /**
   * Drop shadow offset (yes, legacy) percentage from 0 to 50
   */
  dropShadow: number;

  /**
   * Sepia percentage from 0% to 100%
   */
  sepia: number;

  /**
   * Transparency percentage from 0% to 100% with 0% being opaque and 100% being hidden
   */
  transparency: number;

  /**
   * Hue rotation in degrees from 0 to 360
   */
  hueRotate: number;

  /**
   * Grayscale percentage from 0% to 100% with 100% being fully grayscale
   */
  grayscale: number;
}

export interface ILayerBackground {
  /**
   * Plain background color as `#RRGGBBAA`. If null, no color will be drawn.
   */
  color: ILayerColor | null;
}

/**
 * Transformation applied after positioning and sizing the layer.
 *
 * This adds the property `transform` to the container.
 */
export interface ILayerTransform {
  /**
   * Rotation in degrees
   */
  rotate: number;
}

export interface ILayerColor extends IColor {}

export interface ITextFormatting {
  /**
   * Color formatting
   */
  color: {
    /**
     * Foreground color, i.e. text color. Can be null in which case no explicit text color formatting is desired
     */
    foreground: ILayerColor | null;

    /**
     * Background color which will only affect the written text, not the entire layer area.
     */
    background: ILayerColor | null;
  };

  /**
   * Font size in px
   */
  fontSize: number;

  /**
   * Whether the text should be bold
   */
  bold: boolean;

  /**
   * Whether the text should be in italics
   */
  italic: boolean;

  /**
   * Whether the text should be underlined
   */
  underline: boolean;

  /**
   * Whether the text should be upper-cased
   */
  uppercase: boolean;

  /**
   * Horizontal alignment
   */
  align: TextAlign;

  /**
   * Vertical alignment
   */
  valign: TextValign;

  /**
   * Line height as a decimal (1.0 being 100% which is the default)
   */
  lineHeight: number;

  /**
   * Letter spacing as a decimal with 0 being no additional space between the characters which is the default
   */
  letterSpacing: number;
}

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}

export enum TextValign {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export interface ITextLayer extends ILayer {
  type: LayerType.Text;

  /**
   * HTML content of this text layer
   */
  content: string;

  /**
   * Default formatting if not overridden by rich editing
   */
  formatting: ITextFormatting;

  /**
   * @see TextTemplateType
   */
  template: TextTemplateType;

  /**
   * Placeholders to display if the template type is `None` and the text content is empty
   */
  placeholders: ITextPlaceholder[];

  /**
   * Legacy flag for book backs
   */
  rotateForBookBack: boolean;
}

export interface ITextPlaceholder {
  /**
   * The language code of this text, for example 'ger' or 'eng'
   */
  languageCode: string;

  /**
   * The text content of this placeholder
   */
  text: string;
}

/**
 * If the text of this layer was not overridden by the user, the text content will be determined based on project data.
 */
export enum TextTemplateType {
  /**
   * The text is not pre-generated in any way
   */
  None,

  /**
   * Content will be set to the title of the book
   */
  BookTitle,

  /**
   * Content will be set to the subtitle of the book
   */
  BookSubtitle,

  /**
   * Content will be set to the author(s) name(s)
   */
  Author,

  /**
   * Content will be set to the author profile text
   */
  AuthorProfileText,

  /**
   * Content will be set to the impressum content
   */
  Imprint,
}

export interface IImageLayer extends ILayer {
  type: LayerType.Image;

  /**
   * Size of the image within its container (as `background-size`)
   */
  imageSize: number;

  /**
   * Rotation in degrees of the image in its container
   */
  imageRotation: number;

  /**
   * Whether the image should repeat when the container is larger than the image itself
   */
  repeat: boolean;

  /**
   * Colors to replace in SVG images
   */
  replacements: IImageLayerReplacement[];

  /**
   * Stroke width to override in SVG images (only applies to the first stroke width found - server side limitation)
   */
  strokeWidth: number;

  /**
   * The blob file id of this image
   */
  fileId: string;
}

export enum ImageLayerReplacementTarget {
  FillColor,
  StrokeColor,
}

export type IImageLayerReplacement = {
  target: ImageLayerReplacementTarget;

  /**
   * Color to replace in the vector image
   *
   * TODO: Pretty sure we have compatibility problems in case the user selects a color with alpha
   */
  colorOld: string;

  /**
   * Color replacement
   */
  colorNew: ILayerColor;
};

export interface IPlainLayer extends ILayer {
  type: LayerType.Plain;
}

export enum LayerType {
  /**
   * This layer is invalid and likely a serialization or construction error
   */
  Invalid,

  /**
   * @see ITextLayer
   */
  Text,

  /**
   * @see IImageLayer
   */
  Image,

  /**
   * @see IPlainLayer
   */
  Plain,
}
