/**
 * Use this constant to avoid re-renders when defaulting falsy array values.
 * This array is immutable (frozen) and will therefore throw if it is mutated
 *
 * Note we are casting away the type of the array to `any[]` to avoid casting
 * it every time on the consumer side. Keep in mind that this array is immutable!
 */
export const emptyArray = Object.freeze([]) as unknown as any[];

export const ensureArray = <T>(value: T[] | undefined | null) => value || (emptyArray as T[]);
