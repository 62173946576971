import { EditorStepKey } from './EditorStepKey';
import { IApiProjectEditorMetadata, IApiProjectEditorStep } from '../api/IApiProjectEditorMetadata';
import { useReducer } from 'react';

export type StepDirtyFn = () => boolean;
export type StepSubmitFn = () => Promise<boolean>;
export type StepValidateFn = () => Promise<boolean>;

export interface INavigationPromptData {
  /**
   * The message to display to the user as a prompt
   */
  message: string;

  /**
   * The target step to jump to if confirmed
   */
  targetStep: EditorStepKey;
}

export enum EditorActionType {
  /**
   * @see ISetMetadataAction
   */
  SetMetadata = 0,

  /**
   * @see IPreviousStepAction
   */
  PreviousStep = 1,

  /**
   * @see INextStepAction
   */
  NextStep = 2,

  /**
   * @see ISetStepAction
   */
  SetStep = 3,

  /**
   * @see ISetVisibilityAction
   */
  SetVisibility = 4,

  /**
   * @see ISetPromptAction
   */
  SetPrompt = 5,

  /**
   * @see IRegisterStepAction
   */
  RegisterStep = 6,

  /**
   * @see ISetBusyAction
   */
  SetBusy = 7,
}

/**
 * Base interface implemented by all editor actions
 */
export interface IEditorAction {
  type: EditorActionType;
}

/**
 * Assigns the metadata taken from the server
 */
export interface ISetMetadataAction extends IEditorAction {
  type: EditorActionType.SetMetadata;
  projectId: string;
  metadata: IApiProjectEditorMetadata;
}

/**
 * Jumps to the previous step, if existing
 */
export interface IPreviousStepAction extends IEditorAction {
  type: EditorActionType.PreviousStep;
}

/**
 * Jumps to the next step, if existing
 */
export interface INextStepAction extends IEditorAction {
  type: EditorActionType.NextStep;
}

/**
 * Jumps to the given step
 */
export interface ISetStepAction extends IEditorAction {
  type: EditorActionType.SetStep;

  /**
   * The step to jump to
   */
  step: EditorStepKey;
}

/**
 * Sets the visibility of a step
 */
export interface ISetVisibilityAction extends IEditorAction {
  type: EditorActionType.SetVisibility;

  /**
   * Step to set visibility state for. If not provided, the current step is assumed
   */
  step?: EditorStepKey;

  /**
   * Whether the step is visible
   */
  isVisible: boolean;
}

/**
 * Assigns the prompt to display to the user
 */
export interface ISetPromptAction extends IEditorAction {
  type: EditorActionType.SetPrompt;

  /**
   * The prompt to display to the user
   */
  prompt: INavigationPromptData | null;
}

export interface IRegisterStepAction extends IEditorAction {
  type: EditorActionType.RegisterStep;

  /**
   * The function to call to submit the current step
   */
  onSubmit: StepSubmitFn;

  /**
   * The function to call to validate the current step
   */
  onValidate: StepValidateFn;

  /**
   * The function to call to check whether the current step is dirty
   */
  onCheckDirty: StepDirtyFn;
}

export interface ISetBusyAction extends IEditorAction {
  type: EditorActionType.SetBusy;

  /**
   * Whether the editor is now busy
   */
  isBusy: boolean;
}

export type EditorActionUnion =
  | ISetMetadataAction
  | INextStepAction
  | IPreviousStepAction
  | ISetStepAction
  | ISetVisibilityAction
  | IRegisterStepAction
  | ISetPromptAction
  | ISetBusyAction;

export interface IEditorState {
  /**
   * Id of the project that is currently being edited
   */
  projectId: string;

  /**
   * Whether initial data required to initialize the editor has been loaded from the server
   */
  isReady: boolean;

  /**
   * Whether the editor is in readonly mode. The editor may be locked for users if the project is
   * submitted or published
   */
  isReadonly: boolean;

  /**
   * Whether the editor is in book update mode. This may cause some steps to be disabled
   */
  isBookUpdate: boolean;

  /**
   * Whether the editor is in new edition mode. This may cause some steps to be disabled
   */
  isNewEdition: boolean;

  /**
   * Whether the editor is currently busy (e.g. saving or loading data).
   *
   * This will cause a blocking Loader to be rendered in all editor pages.
   */
  isBusy: boolean;

  /**
   * Function to call to check if the current step is dirty
   */
  onCheckDirty: StepDirtyFn;

  /**
   * Function to call to save changes of the current step
   */
  onSubmit: StepSubmitFn;

  /**
   * Function to call to validate the current step
   */
  onValidate: StepValidateFn;

  /**
   * Prompt to display to the user
   */
  prompt: INavigationPromptData | null;

  /**
   * Aggregated information about editor steps
   */
  step: IEditorStepInfo;
}

export interface IEditorStepInfo {
  /**
   * Current step being active - might not reflect the browser navigation state
   */
  current: EditorStepKey;

  /**
   * Previous step convenience accessor (may also be obtainable by using index magic on {@link steps})
   */
  previous: EditorStepKey | null;

  /**
   * Next step convenience accessor (may also be obtainable by using index magic on {@link steps})
   */
  next: EditorStepKey | null;

  /**
   * Owning step container which maps step keys to their respective data
   */
  byKey: IStepDictionary;

  /**
   * Order of steps represented by an array of step keys
   */
  sequence: EditorStepKey[];
}

export type IStepDictionary = { [key in EditorStepKey]?: IEditorStep };

export interface IEditorStep {
  /**
   * Step identity
   */
  key: EditorStepKey;

  /**
   * Whether this step is visible. Initially set by the api and later updated by the corresponding
   * step component (or Blazor). If a step is not visible, it is not considered in the step sequence.
   */
  isVisible: boolean;

  /**
   * Whether this step is considered valid. Initially set by the api and later updated by the
   * corresponding step component.
   */
  isValid: boolean;
}

function createStepInfo(
  steps: IApiProjectEditorStep[],
  currentStep: EditorStepKey,
): IEditorStepInfo {
  const info: IEditorStepInfo = {
    byKey: {},
    next: null,
    previous: null,
    current: currentStep,
    sequence: [],
  };

  for (const step of steps) {
    info.byKey[step.key] = {
      key: step.key,
      isValid: step.isValid,
      isVisible: step.isVisible,
    };
    if (step.isVisible) {
      info.sequence.push(step.key);
    }
  }
  const currentIndex = info.sequence.findIndex((step) => step === currentStep);
  if (currentIndex > 0) {
    info.previous = info.sequence[currentIndex - 1] || null;
  }
  if (currentIndex < info.sequence.length - 1) {
    info.next = info.sequence[currentIndex + 1] || null;
  }
  return info;
}

export function stepInfoReducer(
  state: IEditorStepInfo,
  action: EditorActionUnion,
): IEditorStepInfo {
  function mutateStep(
    key: EditorStepKey,
    mutator: (step: Readonly<IEditorStep>) => IEditorStep,
  ): IEditorStepInfo {
    const step = state.byKey[key];
    if (!step) {
      return state;
    }
    return {
      ...state,
      byKey: {
        ...state.byKey,
        [key]: mutator(step),
      },
    };
  }

  switch (action.type) {
    case EditorActionType.SetMetadata: {
      return createStepInfo(action.metadata.steps, state.current);
    }
    case EditorActionType.NextStep: {
      const currentIndex = state.sequence.findIndex(($) => $ === state.current);
      if (currentIndex === -1 || currentIndex >= state.sequence.length - 1) {
        return state;
      }
      const newCurrent = state.sequence[currentIndex + 1];
      return {
        ...state,
        ...recalcSequence(state.byKey, state.sequence, newCurrent),
      };
    }
    case EditorActionType.PreviousStep: {
      const currentIndex = state.sequence.findIndex(($) => $ === state.current);
      if (currentIndex === -1 || currentIndex <= 0) {
        return state;
      }
      const newCurrent = state.sequence[currentIndex - 1];

      return {
        ...state,
        ...recalcSequence(state.byKey, state.sequence, newCurrent),
      };
    }
    case EditorActionType.SetStep: {
      if (!state.sequence.includes(action.step)) {
        return state;
      }
      return {
        ...state,
        ...recalcSequence(state.byKey, state.sequence, action.step),
      };
    }
    case EditorActionType.SetVisibility: {
      state = mutateStep(action.step ?? state.current, (step) => ({
        ...step,
        isVisible: action.isVisible,
      }));
      // TODO sequence may have changed
      // TODO: refactor
      const seq = Object.keys(state.byKey).filter(
        (stepKey) => state.byKey[stepKey as EditorStepKey]!.isVisible,
      ) as EditorStepKey[];
      return {
        ...state,
        ...recalcSequence(state.byKey, seq, state.current),
      };
    }
    default:
      return state;
  }
}

export function editorReducer(state: IEditorState, action: EditorActionUnion): IEditorState {
  switch (action.type) {
    case EditorActionType.SetMetadata: {
      return {
        ...state,
        isReady: true,
        projectId: action.projectId,
        isReadonly: action.metadata.isReadonly,
        isNewEdition: action.metadata.isNewEdition,
        isBookUpdate: action.metadata.isBookUpdate,
        step: stepInfoReducer(state.step, action),
      };
    }
    case EditorActionType.SetBusy:
      return {
        ...state,
        isBusy: action.isBusy,
      };
    case EditorActionType.SetPrompt:
      return {
        ...state,
        prompt: action.prompt,
      };
    case EditorActionType.RegisterStep:
      return {
        ...state,
        onValidate: action.onValidate,
        onSubmit: action.onSubmit,
        onCheckDirty: action.onCheckDirty,
      };
    // pure step actions
    case EditorActionType.NextStep:
    case EditorActionType.PreviousStep:
    case EditorActionType.SetStep:
    case EditorActionType.SetVisibility: {
      const nextStepState = stepInfoReducer(state.step, action);
      if (state.step === nextStepState) {
        return state;
      }
      return {
        ...state,
        step: nextStepState,
      };
    }
    default:
      return state;
  }
}

function recalcSequence(steps: IStepDictionary, newSeq: EditorStepKey[], newStep: EditorStepKey) {
  const seq = newSeq.filter(($) => steps[$]?.isVisible);
  const newIndex = seq.findIndex(($) => $ === newStep);
  if (newIndex === -1) {
    return undefined;
  }
  const previous = seq[newIndex - 1] || null;
  const next = seq[newIndex + 1] || null;
  return {
    current: newStep,
    previous,
    next,
  };
}

export function editorInitializer(): IEditorState {
  return {
    projectId: '',
    isReady: false,
    isReadonly: false,
    isNewEdition: false,
    isBookUpdate: false,
    isBusy: false,
    step: {
      byKey: {},
      current: EditorStepKey.Category,
      next: null,
      previous: null,
      sequence: [],
    },
    prompt: null,
    onCheckDirty: () => false,
    onSubmit: () => Promise.resolve(false),
    onValidate: () => Promise.resolve(false),
  };
}

export const useEditorState = (initializer = editorInitializer) =>
  useReducer(editorReducer, initializer());
