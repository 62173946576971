import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IDesignerViewState } from './IDesignerViewState';

export function toggleProblemsReducer(
  state: Draft<IDesignerViewState>,
  action: PayloadAction<boolean | undefined>,
) {
  if (typeof action.payload === 'boolean') {
    state.showProblems = action.payload;
  } else {
    state.showProblems = !state.showProblems;
  }
}
