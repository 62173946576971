import React, { useCallback, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { ZoomControl } from './ZoomControl';
import './DesignerMenu.css';
import { SnapControl } from './SnapControl';
import { useDesignerMode, useDesignerView } from '../view/viewSelectors';
import { openSidePanel, toggleOverflow, toggleProblems, toggleScaffold } from '../view/viewSlice';
import { DesignerMode } from '../designerMode';
import { CoverPreview } from '../coverPreview/CoverPreview';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useActiveProduct } from '../project/productSelectors';
import { ProductType } from '../project/ProductState';
import { Icon } from '../../icons/Icon';
import { IProject } from '../project/ProjectState';
import { ProductMenu } from './ProductMenu';
import { SidePanelId } from '../sidePanel/sidePanelId';
import { MenuButtonDesigner } from '../../uiComponents/MenuButtonDesigner';
import { MenuRightWrapper } from './MenuRightWrapper';
import { ViewControl } from './ViewControl';

export interface IDesignerMenuProps {
  project: IProject;
}

export interface IDropDown {
  [key: string]: boolean;
}

export const DesignerMenu: React.FC<IDesignerMenuProps> = ({ project }) => {
  const [dropDown, setDropDown] = useState<IDropDown>({ viewControl: false, snapControl: false });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Wrapper role="menu" aria-roledescription="Main Menu">
      <ProductMenu project={project} />
      <MenuRightWrapper>
        <ZoomControl />
        <ViewControl dropDown={dropDown} setDropDown={setDropDown} />
        <SnapControl dropDown={dropDown} setDropDown={setDropDown} />
        <MenuButtonDesigner
          onClick={() => dispatch(openSidePanel(SidePanelId.History))}
          title={t('UndoTitle')}>
          <Icon name="rueckgaengig-control" />
          <span className="designer-menu-label">{t('TimeMachine')}</span>
        </MenuButtonDesigner>
      </MenuRightWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  background-color: white;
  user-select: none;
  height: 100%;
`;
