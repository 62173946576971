import { FC, useEffect, useRef } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { DesignerInput } from './input/DesignerInput';
import { DesignerMenu } from './menu/DesignerMenu';
import { ContextMenu } from './menu/ContextMenu';
import './Designer.css';
import { setDesignerMode } from './view/viewSlice';
import { DesignerMode } from './designerMode';
import { useProject } from './project/projectSelectors';
import styled from 'styled-components/macro';
import classNames from 'classnames';
import { TemplateController } from './templates/TemplateController';
import { CKEditorProvider } from './textEditor/CKEditorProvider';
import { WorkSpaceAggregator } from './workSpace/WorkSpaceAggregator';
import { ProblemController } from './problem/ProblemController';
import { HistoryController } from './history/HistoryController';
import { CopyDesignController } from './copyDesign/CopyDesignController';
import { SidePanel } from './sidePanel/SidePanel';

export interface IDesignerProps {
  /**
   * The desired mode the designer should run in
   */
  mode: DesignerMode;
}

export const Designer: FC<IDesignerProps> = ({ mode }) => {
  const dispatch = useAppDispatch();
  const project = useProject();
  const pageContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(setDesignerMode(mode));
  }, [dispatch, mode]);

  if (!project) {
    return null;
  }

  return (
    <Grid className={classNames('designer standalone-100vh', { readonly: project.isReadonly })}>
      <CKEditorProvider>
        <TopContent>
          <DesignerMenu project={project} />
          <ContextMenu />
        </TopContent>
        <PageContent ref={pageContentRef}>
          <WorkSpaceAggregator />
        </PageContent>
        {!project.isReadonly && (
          <MenuContent className="standalone-100vh">
            <SidePanel />
          </MenuContent>
        )}
        <DesignerInput isReadonly={project.isReadonly} />
        <CopyDesignController />
        <HistoryController />
        <TemplateController />
        <ProblemController />
      </CKEditorProvider>
    </Grid>
  );
};

const Grid = styled.div`
  height: calc(100vh - 77px);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto 1fr;
  background-color: var(--color-background-secondary);
  position: relative;
  z-index: 0;
  overflow: hidden;
`;

const TopContent = styled.div`
  grid-column: 1 / span 2;
  align-self: center;
`;

const PageContent = styled.div`
  border-top: 1px solid var(--color-border);
  grid-column: 1 / span 1;
  overflow: auto;
  position: relative;
`;

const MenuContent = styled.div`
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
  height: calc(100vh - 77px);
  overflow: hidden;
  background-color: var(--color-background);
`;
