import { createSlice } from '@reduxjs/toolkit';
import { setDesignerModeReducer } from './setDesignerModeReducer';
import { setGridReducer } from './setGridReducer';
import { toggleScaffoldReducer } from './toggleScaffoldReducer';
import { toggleOverflowReducer } from './toggleOverflowReducer';
import { zoomReducer } from './zoomReducer';
import { IDesignerViewState } from './IDesignerViewState';
import { applyInputToSelectionReducer } from './applyInputToSelectionReducer';
import { setInput } from '../input/inputSlice';
import { DesignerMode } from '../designerMode';
import { setSelectionReducer } from './setSelectionReducer';
import { toggleProblemsReducer } from './toggleProblemsReducer';
import { showCopyDesignModalReducer } from './showCopyDesignModalReducer';
import { openSidePanelReducer } from './openSidePanelReducer';
import { SidePanelId } from '../sidePanel/sidePanelId';

export const createInitialViewState = (): IDesignerViewState => ({
  mode: DesignerMode.None,
  showScaffold: true,
  showOverflow: false,
  showProblems: true,
  showCopyDesignModal: false,
  grid: { size: { width: 16, height: 16 }, isEnabled: false },
  zoom: 1,
  selection: { start: null, end: null },
  sidePanel: SidePanelId.Layers,
});

export const viewSlice = createSlice({
  name: 'view',
  initialState: Object.freeze(createInitialViewState()),
  reducers: {
    openSidePanel: openSidePanelReducer,
    setDesignerMode: setDesignerModeReducer,
    setGrid: setGridReducer,
    setSelection: setSelectionReducer,
    showCopyDesignModal: showCopyDesignModalReducer,
    toggleScaffold: toggleScaffoldReducer,
    toggleOverflow: toggleOverflowReducer,
    toggleProblems: toggleProblemsReducer,
    zoom: zoomReducer,
  },
  extraReducers: (builder) => builder.addCase(setInput, applyInputToSelectionReducer),
});

export const {
  openSidePanel,
  setDesignerMode,
  setGrid,
  setSelection,
  showCopyDesignModal,
  toggleScaffold,
  toggleOverflow,
  toggleProblems,
  zoom,
} = viewSlice.actions;
