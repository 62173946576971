import { createSlice } from '@reduxjs/toolkit';
import { IDesignerProblemsState } from './IDesignerProblem';
import { setLayerProblemsReducer } from './setLayerProblemsReducer';
import { setProductProblemsReducer } from './setProductProblemsReducer';

export const createInitialProblemState = (): IDesignerProblemsState => ({
  layers: {},
  products: {},
  showProblems: true,
});

export const problemSlice = createSlice({
  name: 'problems',
  initialState: Object.freeze(createInitialProblemState()),
  reducers: {
    setLayerProblems: setLayerProblemsReducer,
    setProductProblems: setProductProblemsReducer,
  },
});

export const { setLayerProblems, setProductProblems } = problemSlice.actions;
