import { RootState } from '../../redux/store';
import { templateSlice } from './templateSlice';
import { useAppSelector } from '../../redux/hooks';

/**
 * Returns the entire designer template slice
 */
export const getTemplateSlice = (state: RootState) => state.designer[templateSlice.name];

/**
 * Provides the entire designer template slice to a React component
 */
export const useTemplateSlice = () => useAppSelector(getTemplateSlice);
