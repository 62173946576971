export enum EditorStepKey {
  Category = 'category',
  ProjectData = 'projectData',
  Format = 'format',
  Contributors = 'contributors',
  Isbn = 'isbn',
  PrepareContentFile = 'prepareContentFile',
  UploadContentFile = 'uploadContentFile',
  ViewContentFile = 'viewContentFile',
  ColorPages = 'colorPages',
  Material = 'material',
  CoverTool = 'coverTool',
  CoverDesigner = 'coverDesigner',
  PrepareCoverFile = 'prepareCoverFile',
  UploadCoverFile = 'uploadCoverFile',
  ViewCoverFile = 'viewCoverFile',
  Provision = 'provision',
  ProvisionContribution = 'provisionContribution',
  SearchAndFind = 'searchAndFind',
  BookInterview = 'bookInterview',
  AuthorProfile = 'authorProfile',
  ShopView = 'shopView',
}
