import { AppThunk } from '../../redux/store';
import { getProjectSlice } from './projectSelectors';
import { getDesignerMode } from '../view/viewSelectors';
import { persistLiveSheet } from './projectSlice';
import { getAllLayers } from '../layer/liveSheetSelectors';
import { ProjectCompat } from './compat/ProjectCompat';
import { openProduct } from './openProduct';
import { getSaveEndpoint } from './getSaveEndpoint';
import { ISaveDesignProject } from './IDesignProject';

export const getSaveDesignProjectData =
  (): AppThunk<Promise<ISaveDesignProject | undefined>> => async (dispatch, getState) => {
    let currentState = getState();
    let project = getProjectSlice(currentState);
    if (!project || project.isReadonly) {
      return;
    }
    const endpoint = getSaveEndpoint(getDesignerMode(currentState));
    const select = endpoint.select('saveDesign')(currentState);
    const now = Date.now();
    if (select && select.startedTimeStamp && now - select.startedTimeStamp < 5000) {
      return;
    }
    dispatch(persistLiveSheet({ layers: getAllLayers(currentState) }));
    currentState = getState();
    project = getProjectSlice(currentState);
    if (!project) {
      return;
    }
    const activeProductBeforeSave = project.activeProduct;
    const data = ProjectCompat.toSaveData(project);

    for (const product of data.products) {
      await dispatch(openProduct(product.id, true));
      // TODO this only works for covers
      let sheetHtml = document.querySelector('.sheet .render .layers')?.innerHTML || '';
      sheetHtml += document.querySelector('.sheet .render .ext')?.innerHTML || '';
      product.sheets[0].html = sheetHtml;
    }
    if (activeProductBeforeSave) {
      dispatch(openProduct(activeProductBeforeSave, true));
    }
    return data;
  };
