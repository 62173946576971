import { useActiveProduct } from '../project/productSelectors';
import { useAllLayers } from '../layer/liveSheetSelectors';
import { WorkSpace } from './WorkSpace';
import { FC, Fragment } from 'react';
import { WorkSpaceSeparator } from './WorkSpaceSeparator';
import { useDesignerMode, useZoom } from '../view/viewSelectors';

export const WorkSpaceAggregator: FC = () => {
  const product = useActiveProduct();
  const liveSheetLayers = useAllLayers();
  const zoom = useZoom();
  const mode = useDesignerMode();

  if (!product) {
    return null;
  }
  return (
    <Fragment>
      {product.sheets.map((sheet, index) => {
        const isActive = sheet.id === product!.activeSheetId;
        const pageCount = product!.sheets.length;
        return (
          <Fragment key={sheet.id}>
            <WorkSpace
              sheetId={sheet.id}
              index={index}
              isActive={isActive}
              layers={isActive ? liveSheetLayers : sheet.layers}
              hasMultipleSheets={pageCount !== 1}
              measures={product.measures}
              zoom={zoom}
            />
            <WorkSpaceSeparator designerMode={mode} pageIndex={index} pageCount={pageCount} />
          </Fragment>
        );
      })}
    </Fragment>
  );
};
