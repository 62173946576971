import { IRGBA } from './Color';
import { randomMax, randomMinMax } from './random';
import { PaletteCreatorBias } from '../designer/palette/usePaletteCreatorState';

export interface IMixPaletteOptions {
  /**
   * The primary color which the palette gradient will be based on
   */
  color: IRGBA;

  /**
   * Favors a specific color channel when creating the gradient.
   * Note that the implementation is stupid and does not do what it intends to do.
   */
  bias: PaletteCreatorBias;

  /**
   * How fast the gradient approaches bright values
   */
  intensity: number;
}

/**
 * Creates a new palette for the given primary color.
 *
 * Code port from `Customer → ColorPaletteDetails.razor.cs → GenerateNewScheme` as accurate as possible
 */
export function mixPalette(options: IMixPaletteOptions): IRGBA[] {
  const colors: IRGBA[] = [options.color];

  const redFactor = getColorFactor(options.bias === PaletteCreatorBias.MoreRed, options.intensity);
  const greenFactor = getColorFactor(
    options.bias === PaletteCreatorBias.MoreGreen,
    options.intensity,
  );
  const blueFactor = getColorFactor(
    options.bias === PaletteCreatorBias.MoreBlue,
    options.intensity,
  );

  // note: the implementation of the legacy customer uses 0 to 255.
  //       to keep the code port simple we do it as well and normalize in the end
  let r = options.color.r * 255;
  let g = options.color.g * 255;
  let b = options.color.b * 255;

  for (let i = 0; i < 5; ++i) {
    r += randomMax(redFactor * options.intensity);
    g += randomMax(greenFactor * options.intensity);
    b += randomMax(blueFactor * options.intensity);

    if (r > 255) {
      r = randomMinMax(240, 255);
    }
    if (g > 255) {
      g = randomMinMax(240, 255);
    }
    if (b > 255) {
      b = randomMinMax(240, 255);
    }
    // normalize and push
    colors.push({
      r: r / 255,
      g: g / 255,
      b: b / 255,
      a: 1,
    });
  }
  return colors;
}

function getColorFactor(biased: boolean, intensity: number): number {
  return biased ? 30 + 3 * intensity : 20;
}
