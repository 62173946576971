import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { IOpenProductActionPayload } from './projectActions';

/**
 * Performs state changes on the project slice when a product should be opened.
 */
export function openProductReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IOpenProductActionPayload>,
) {
  const { product, layers } = action.payload;
  if (!state || !product) {
    return;
  }
  if (!state.products.some(($) => $.id === product.id)) {
    // ensure the product to open actually exists in the project
    return;
  }
  const currentProduct =
    state.activeProduct && state.products.find(($) => $.id === state.activeProduct);
  if (currentProduct && currentProduct.activeSheetId) {
    const currentCanvas = currentProduct.sheets.find(($) => $.id === currentProduct.activeSheetId);
    if (currentCanvas) {
      currentCanvas.layers = layers;
    }
  }
  state.activeProduct = product.id;
}
