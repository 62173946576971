import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styles from './DatePicker.module.css';

import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import VisuallyHidden from '../shared/visuallyHidden/VisuallyHidden';
import { Label } from '../forms/label/Label';

export interface IDatePickerProps {
  selectedDate: Date;
  label: string;
  labelHidden?: boolean;
  onChange: (date: Date | null) => void;
  dateFormat: string;
  yearItemNumber?: number;
  showYearPicker: boolean;
  disabled?: boolean;
  onBlur?: () => void;
}

export const DatePicker = ({
  selectedDate,
  label,
  labelHidden = false,
  onChange,
  dateFormat,
  yearItemNumber,
  disabled = false,
  showYearPicker,
  onBlur,
}: IDatePickerProps) => {
  return (
    <div
      className={classNames(styles.datePicker, {
        [styles.yearOnly]: dateFormat == 'yyyy',
      })}>
      {labelHidden ? <VisuallyHidden>{label}</VisuallyHidden> : <Label>{label}</Label>}
      <ReactDatePicker
        disabled={disabled}
        wrapperClassName={styles.wrapper}
        selected={selectedDate}
        popperClassName={styles.popper}
        onChange={onChange}
        showYearPicker={showYearPicker}
        dateFormat={dateFormat}
        yearItemNumber={yearItemNumber}
        onBlur={onBlur}
      />
    </div>
  );
};
