import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILayerBackground } from './LayersState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface ISetBackgroundActionPayload {
  /**
   * The new background configuration to set to all layers matching the given filter.
   * Note that you can supply a partial configuration which will cause only the given arguments to overwrite
   * the current configuration.
   */
  background: Partial<ILayerBackground>;

  /**
   * Layers which should receive the new background configuration.
   *
   * Note that layers which do not support backgrounds will be filtered out in all cases.
   */
  filter: ILayerFilter;
}

/**
 * Modifies the background of all layers satisfying the given filter
 */
export function setBackgroundReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetBackgroundActionPayload>,
) {
  filterLayers(state.layers, action.payload.filter).forEach((layer) => {
    if (!layer.allowEffects) {
      return;
    }
    let wasUpdated = false;
    if (action.payload.background.color) {
      layer.background.color = action.payload.background.color;
      wasUpdated = true;
    }
    if (wasUpdated) {
      layer.updatedAt = Date.now();
    }
  });
}
