import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IImageLayer, ILayer, ILayerColor, ITextLayer, LayerType } from './LayersState';
import { getRgbaColorAt, ITreditionPalette } from '../palette/TreditionPalette';
import { IColor } from '../palette/IColor';
import { isRgbaEqual } from '../../lib/isRgbaEqual';
import { ILiveSheetState } from './ILiveSheetState';
import { ISetPaletteActionPayload } from '../project/projectActions';

/**
 * Applies the given palette to all layers of the live sheet.
 *
 * @see ISetPaletteInternalActionPayload.palette
 */
export function applyPaletteToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<ISetPaletteActionPayload>,
) {
  applyPaletteToLayers(state.layers, action.payload.palette, action.payload.currentPalette);
}

/**
 * Applies a palette to the given list of layers. The given layers will be mutated.
 */
export function applyPaletteToLayers(
  layers: ILayer[],
  palette: ITreditionPalette | null,
  currentPalette: ITreditionPalette | null,
): void {
  layers.forEach((layer): void => {
    writeToColor(layer.border.color, palette);
    writeToColor(layer.background.color, palette);

    switch (layer.type) {
      case LayerType.Image:
        return applyToImageLayer(layer as IImageLayer, palette);
      case LayerType.Text:
        return applyToTextLayer(layer as ITextLayer, palette, currentPalette);
      default:
    }
  });
}

function applyToImageLayer(layer: IImageLayer, palette: ITreditionPalette | null): void {
  let replacementsUpdated = false;

  layer.replacements.forEach(({ colorNew }) => {
    if (writeToColor(colorNew, palette)) {
      replacementsUpdated = true;
    }
  });
  if (replacementsUpdated) {
    layer.updatedAt = Date.now();
  }
}

function changeContentBasedOnPreviousPalette(
  content: string,
  newPalette: ITreditionPalette | null,
  currentPalette: ITreditionPalette | null,
) {
  if (!currentPalette || !newPalette) return content;
  const colorKeys = [
    'color1',
    'color2',
    'color3',
    'color4',
    'color5',
    'color6',
  ] as (keyof ITreditionPalette)[];
  let newContent = content;
  colorKeys.forEach((k) => {
    const colorValue = currentPalette[k];
    const newColor = newPalette[k];
    if (typeof colorValue !== 'string' || typeof newColor !== 'string') return;
    newContent = newContent.replace(new RegExp(colorValue, 'gi'), newColor.toLowerCase());
  });

  return newContent;
}

function applyToTextLayer(
  layer: ITextLayer,
  palette: ITreditionPalette | null,
  currentPalette: ITreditionPalette | null,
): void {
  const {
    formatting: {
      color: { foreground },
    },
  } = layer;
  layer.content = changeContentBasedOnPreviousPalette(layer.content, palette, currentPalette);
  if (foreground && updateColorByPalette(foreground, palette)) {
    layer.updatedAt = Date.now();
  }
}

function updateColorByPalette(color: ILayerColor, palette: ITreditionPalette | null): boolean {
  if (color.paletteIndex === null) {
    return false; // nothing to do
  }
  if (palette) {
    color.rgba = getRgbaColorAt(palette, color.paletteIndex) || color.rgba;
  } else {
    // clearing
    color.paletteIndex = null;
  }
  return true;
}

/**
 * Returns true if the color was somehow modified
 */
function writeToColor(color: IColor | null, palette: ITreditionPalette | null): boolean {
  if (!color) {
    // only write color info from palette if a color was explicitly defined by the user otherwise everything will get very colorful
    return false;
  }
  if (!palette) {
    const indexBefore = color.paletteIndex;
    color.paletteIndex = null;
    return indexBefore !== null;
  }
  if (color.paletteIndex === null) {
    return false;
  }
  const oldRgba = color.rgba;
  const newRgba = getRgbaColorAt(palette, color.paletteIndex);
  if (!newRgba) {
    return false; // invalid color
  }
  color.rgba = newRgba;
  return !isRgbaEqual(oldRgba, newRgba);
}
