import { IHistoryState } from './IHistoryState';
import { Draft } from '@reduxjs/toolkit';

/**
 * Resets the save index in all pages to -1 to force an `unsaved` state.
 */
export function invalidateSaveIndicesReducer(state: Draft<IHistoryState>) {
  for (const page of Object.values(state.byProductId)) {
    page.savedAtIndex = -1;
  }
}
