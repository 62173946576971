import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { IActivateSheetActionPayload } from '../project/projectActions';

export function applyActivateSheetToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IActivateSheetActionPayload>,
) {
  state.layers = action.payload.layers;
}
