import { Command, Plugin } from "@ckeditor/ckeditor5-core";
import LineHeightEditing from "./LineHeightEditing";
import { renderUpcastAttribute } from "@ckeditor/ckeditor5-font/src/utils";
import LineHeightCommand from "./LineHeightCommand";

export const LINE_HEIGHT = "line-height";

function renderDowncastElement(styleAttr, element) {
  return (modelAttributeValue, { writer }) =>
    writer.createAttributeElement(
      element,
      {
        style: `${styleAttr}:${modelAttributeValue}`,
      },
      { priority: 7 }
    );
}

class LineHeightUI extends Plugin {
  static get pluginName() {
    return "LineHeightUI";
  }

  init() {
    const editor = this.editor;
    const {
      ui: { componentFactory },
    } = editor;

    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "p",
        styles: {
          "line-height": /[\s\S]+/,
        },
      },
      model: {
        key: LINE_HEIGHT,
        value: renderUpcastAttribute("line-height"),
      },
    });

    editor.conversion.for("downcast").attributeToAttribute({
      model: LINE_HEIGHT,
      view: (modelAttributeValue) => {
        return {
          key: "style",
          value: {
            "line-height": modelAttributeValue,
          },
        };
      },
    });

    editor.model.schema.extend("$block", { allowAttributes: LINE_HEIGHT });

    editor.model.schema.setAttributeProperties(LINE_HEIGHT, {
      isFormatting: true,
      copyOnEnter: false,
    });

    componentFactory.add("line-height", (locale) => {
      const inputView = new LineHeightEditing(locale);
      inputView.on("change:value", (v) => {
        const lineHeightValue = (() => {
          if (v.source.value === 0) return 0;
          return (Number(v.source.value) / 100) * 1.2;
        })();
        editor.execute(LINE_HEIGHT, { value: lineHeightValue });
      });

      return inputView;
    });

    editor.commands.add(LINE_HEIGHT, new LineHeightCommand(editor));
  }
}

export default LineHeightUI;
