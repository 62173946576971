import { EditorStepKey } from './EditorStepKey';

const BlazorSteps: EditorStepKey[] = [
  EditorStepKey.AuthorProfile,
  EditorStepKey.BookInterview,
  EditorStepKey.ColorPages,
  EditorStepKey.CoverTool,
  EditorStepKey.Material,
  EditorStepKey.PrepareContentFile,
  EditorStepKey.Provision,
  EditorStepKey.ProvisionContribution,
  EditorStepKey.SearchAndFind,
  EditorStepKey.ShopView,
  EditorStepKey.PrepareCoverFile,
  EditorStepKey.UploadContentFile,
  EditorStepKey.UploadCoverFile,
  EditorStepKey.ViewContentFile,
  EditorStepKey.ViewCoverFile,
];

export function isStepBlazorManaged(step: EditorStepKey) {
  return BlazorSteps.includes(step);
}
