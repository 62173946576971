import { ChangeEvent, FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useSelectedLayers } from '../layer/liveSheetSelectors';
import { IImageLayer, LayerType } from '../layer/LayersState';
import { consolidate } from '../../lib/consolidate';
import { setImageOptions } from '../layer/liveSheetSlice';
import { NumberInputWrapper } from '../../uiComponents/NumberInputWrapper';
import { useTranslation } from 'react-i18next';
import { Input } from '../../uiComponents/Input';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';

export const ImageTilingPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedLayers = useSelectedLayers().filter(
    ($) => $.type === LayerType.Image,
  ) as IImageLayer[];
  const conImageSize = consolidate(selectedLayers, ($) => $.imageSize);
  const conImageRotation = consolidate(selectedLayers, ($) => $.imageRotation);
  const conRepeat = consolidate(selectedLayers, ($) => $.repeat);
  const sizeInputRef = useRef<HTMLInputElement>(null);
  const rotationInputRef = useRef<HTMLInputElement>(null);
  const isTilingActive = !conRepeat.isEqual || conRepeat.value === true;

  const onChangeSizeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const imageSize = Number.parseInt(e.currentTarget.value);
      if (!Number.isNaN(imageSize)) {
        dispatch(setImageOptions({ imageSize, filter: { selected: true } }));
      }
    },
    [dispatch],
  );

  const onChangeRotationInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const imageRotation = Number.parseInt(e.currentTarget.value);
      if (!Number.isNaN(imageRotation)) {
        dispatch(setImageOptions({ imageRotation, filter: { selected: true } }));
      }
    },
    [dispatch],
  );

  const toggleTiling = useCallback(() => {
    dispatch(setImageOptions({ repeat: !isTilingActive, filter: { selected: true } }));
  }, [dispatch, isTilingActive]);

  useEffect(() => {
    if (!sizeInputRef.current) {
      return;
    }
    if (typeof conImageSize.value === 'number') {
      sizeInputRef.current.value = conImageSize.value.toString();
    } else {
      sizeInputRef.current.value = '';
    }
  }, [conImageSize.value]);

  useEffect(() => {
    if (!rotationInputRef.current) {
      return;
    }
    if (typeof conImageRotation.value === 'number') {
      rotationInputRef.current.value = conImageRotation.value.toString();
    } else {
      rotationInputRef.current.value = '';
    }
  }, [conImageRotation.value]);

  return (
    <SidePanelContentWrapper>
      <h2>{t('Repeat')}</h2>
      {selectedLayers.length > 0 ? (
        <Fragment>
          <label>
            <input type="checkbox" checked={isTilingActive} onChange={toggleTiling} />
            {t('Repeat')}
          </label>
          <NumberInputWrapper>
            {t('PatternSize')}
            <Input
              ref={sizeInputRef}
              type="number"
              min={0}
              max={100}
              step={1}
              onBlur={onChangeSizeInput}
              addendum="%"
              disabled={!isTilingActive}
            />
          </NumberInputWrapper>

          <NumberInputWrapper>
            {t('PatternRotation')}
            <Input
              ref={rotationInputRef}
              type="number"
              min={0}
              max={360}
              step={1}
              onBlur={onChangeRotationInput}
              addendum={t('Degree')}
              disabled={!isTilingActive}
            />
          </NumberInputWrapper>
        </Fragment>
      ) : (
        <div>{t('NoImageLayer')}</div>
      )}
    </SidePanelContentWrapper>
  );
};
