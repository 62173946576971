import { IProject } from '../project/ProjectState';
import { FC, useMemo } from 'react';
import { IProduct, ProductType } from '../project/ProductState';
import styled from 'styled-components/macro';
import { useAppDispatch } from '../../redux/hooks';
import { openProduct } from '../project/openProduct';
import { ProductTypeLabel } from '../project/ProductTypeLabel';
import { useProblemsByProductId } from '../problem/problemSelectors';
import { IDesignProblem } from '../problem/IDesignerProblem';
import { ProblemIndicator } from '../problem/ProblemIndicator';
import { useShowProblems } from '../view/viewSelectors';
import { useTranslation } from 'react-i18next';
import { showCopyDesignModal } from '../view/viewSlice';
import { Icon } from '../../icons/Icon';
import { MenuButtonDesigner } from '../../uiComponents/MenuButtonDesigner';
import { MenuButtonBookType } from '../../uiComponents/MenuButtonBookType';

export interface IProductMenuProps {
  project: IProject;
}

export const ProductMenu: FC<IProductMenuProps> = ({ project }) => {
  const dispatch = useAppDispatch();
  const problemByProductId = useProblemsByProductId();
  const showProblems = useShowProblems();
  const { t } = useTranslation();

  return (
    <ProductList className="product-list">
      {project.products.map((product) => {
        const isActive = project.activeProduct === product.id;

        let firstProductProblem: IDesignProblem | null = null;
        if (
          showProblems &&
          problemByProductId[product.id] &&
          problemByProductId[product.id].length
        ) {
          firstProductProblem = problemByProductId[product.id][0];
        }

        return (
          <li key={product.id}>
            <MenuButtonBookType
              className={product.type === ProductType.Ebook ? 'ebook book-type' : 'book-type'}
              aria-disabled={isActive}
              onClick={() => {
                if (!isActive) {
                  dispatch(openProduct(product.id));
                }
              }}
              isActive={isActive}>
              <ProductTypeLabel type={product.type} />
              {firstProductProblem && (
                <ProblemIndicator className="book-type" problem={firstProductProblem} />
              )}
            </MenuButtonBookType>
          </li>
        );
      })}
      <li>
        {project.products.length > 1 && (
          <MenuButtonDesigner
            className="designer-menu book-type"
            onClick={() => dispatch(showCopyDesignModal(true))}
            title={t('CopyDesignTitle')}>
            <Icon name="kopieren" />
            <span>{t('Import')}</span>
          </MenuButtonDesigner>
        )}
      </li>
    </ProductList>
  );
};

const ProductList = styled.ul`
  display: flex;
  align-items: center;
  border-right: thick double var(--color-border);

  li {
    display: flex;
    align-items: center;
    position: relative;
  }

  li:last-child {
    padding: 0 1rem;
  }

  .problem {
    color: var(--color-danger);
    cursor: help;
    position: absolute;
    right: 11px;
    top: 10px;
  }

  .ebook .problem {
    right: 31px;
  }

  .problem > span {
    font-size: 20px;
  }
`;
