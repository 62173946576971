/**
 * A dictionary of layer ids mapped to a list of design problems
 */
export type LayerProblems = Record<string, DesignProblemUnion[]>;

export type ProductProblems = Record<string, DesignProblemUnion[]>;

export interface IDesignerProblemsState {
  /**
   * Warnings scoped to a layer. Maps layer id to a list of problems
   */
  layers: LayerProblems;

  /**
   * Warnings scoped to a product. Maps product id to a list of problems.
   */
  products: ProductProblems;

  /**
   * Whether problems should be shown in the designer
   */
  showProblems: boolean;
}

export interface IDesignProblem {
  /**
   * A problem type identifying this problem
   */
  type: DesignProblemType;

  /**
   * The severity of this problem
   */
  level: DesignProblemLevel;
}

export type DesignProblemUnion =
  | ITextContentClippingDesignProblem
  | ILayerOutOfBoundsDesignProblem
  | IProductDesignEmptyDesignProblem;

/**
 * The text layer's content is clipping due to the layer being smaller than the text content container
 */
export interface ITextContentClippingDesignProblem extends IDesignProblem {
  type: DesignProblemType.TextContentClipping;
}

/**
 * The layer is completely out of bounds and would not be visible
 */
export interface ILayerOutOfBoundsDesignProblem extends IDesignProblem {
  type: DesignProblemType.LayerOutOfBounds;
}

export interface IProductDesignEmptyDesignProblem extends IDesignProblem {
  type: DesignProblemType.DesignEmpty;
}

export enum DesignProblemType {
  /**
   * @see ILayerOutOfBoundsDesignProblem
   */
  TextContentClipping,

  /**
   * @see ILayerOutOfBoundsDesignProblem
   */
  LayerOutOfBounds,

  /**
   * @see IProductDesignEmptyDesignProblem
   */
  DesignEmpty,
}

export enum DesignProblemLevel {
  Warning = 0,
  Error = 1,
}
