import { useActiveProduct } from '../project/productSelectors';

import React from 'react';
import styled from 'styled-components/macro';
import { IRegion, RegionId } from '../project/ProductState';
import { useTranslation } from 'react-i18next';

export const CoverDesignerRegions: React.FC = () => {
  const { t } = useTranslation();
  const product = useActiveProduct();

  if (!product) {
    return null;
  }

  const getRegionColumns = (regions: IRegion[]): string => {
    const hasJoint = regions.some((region) => region.id === RegionId.JointBack);
    if (hasJoint) {
      const coverBackWidth = regions.find((region) => region.id === RegionId.CoverBack)?.width || 0;
      const jointBackWidth = regions.find((region) => region.id === RegionId.JointBack)?.width || 0;
      const coverFrontWidth =
        regions.find((region) => region.id === RegionId.CoverFront)?.width || 0;
      const jointFrontWidth =
        regions.find((region) => region.id === RegionId.JointFront)?.width || 0;
      return (
        [...regions]
          .map((region) => {
            switch (region.id) {
              case RegionId.CoverBack:
                return coverBackWidth - jointBackWidth;
              case RegionId.CoverFront:
                return coverFrontWidth - jointFrontWidth;
              default:
                return region.width;
            }
          })
          .join('px ') + 'px'
      );
    } else {
      return regions.map((region) => region.width).join('px ') + 'px';
    }
  };

  const regionColumns = getRegionColumns(product.regions);
  const hasJoint = product.regions.some((region) => region.id === RegionId.JointBack);

  return (
    <Regions regionColumns={regionColumns}>
      {product?.regions.map((region) => {
        switch (region.id) {
          case RegionId.JointFront:
          case RegionId.JointBack:
            return (
              <Region key={region.id} regionId={region.id} hasJoint={hasJoint}>
                <span>{t('Joint')}</span>
              </Region>
            );
          default:
            return (
              <Region key={region.id} regionId={region.id as RegionId} hasJoint={hasJoint}>
                <Label>{t(region.id)}</Label>
              </Region>
            );
        }
      })}
    </Regions>
  );
};

interface IWrapper {
  regionColumns: string;
}

const Regions = styled.div.attrs<IWrapper>((props) => ({
  style: {
    gridTemplateColumns: props.regionColumns,
  },
}))<IWrapper>`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  user-select: none;
`;

type IRegionDivProps = {
  regionId: RegionId;
  hasJoint: boolean;
};

const Region = styled.div<IRegionDivProps>`
  border-left: ${(props) =>
    [RegionId.JointBack, RegionId.CoverFront].includes(props.regionId) && props.hasJoint
      ? `1px dashed var(--color-border)`
      : `1px solid var(--color-border)`};
  border-right: ${(props) =>
    [RegionId.JointFront, RegionId.CoverBack].includes(props.regionId) && props.hasJoint
      ? `1px dashed var(--color-border)`
      : `1px solid var(--color-border)`};
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > span {
    transform: rotate(-90deg);
    min-width: 20rem;
    font-size: 0.7rem;
  }
`;

const Label = styled.div`
  position: absolute;
  top: -25px;
  font-size: 0.9rem;
  font-weight: 600;
  user-select: none;
  text-shadow: -1px 0 rgb(242, 242, 242), 0 1px rgb(242, 242, 242), 1px 0 rgb(242, 242, 242),
    0 -1px rgb(242, 242, 242);
`;
