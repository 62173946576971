import { IProjectState } from './ProjectState';
import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from './ProductState';

export function replaceProductReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IProduct>,
) {
  if (!state) {
    return;
  }
  const index = state.products.findIndex(($) => $.id === action.payload.id);
  if (index === -1) {
    state.products.push(action.payload);
  } else {
    state.products[index] = action.payload;
  }
  state.activeProduct = action.payload.id;
}
