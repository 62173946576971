import { FC, useMemo } from 'react';
import { DesignProblemType, DesignProblemUnion } from './IDesignerProblem';
import { Icon } from '../../icons/Icon';
import { useTranslation } from 'react-i18next';

export interface IProblemIndicatorProps {
  problem: DesignProblemUnion;
  className?: string;
}

export const ProblemIndicator: FC<IProblemIndicatorProps> = ({ problem, className }) => {
  const { t } = useTranslation();
  const title = useMemo(() => {
    switch (problem.type) {
      case DesignProblemType.TextContentClipping:
        return 'DesignProblemTextContentClipping';
      case DesignProblemType.LayerOutOfBounds:
        return 'DesignProblemLayerOutOfBounds';
      case DesignProblemType.DesignEmpty:
        return 'DesignProblemDesignEmpty';
      default:
        return 'DesignProblemUnknown';
    }
  }, [problem.type]);

  return (
    <div className="problem">
      {className?.includes('book-type') ? (
        <Icon name="aktualisieren-control" title={t(title)} />
      ) : (
        <Icon name="achtung-control" title={t(title)} />
      )}
    </div>
  );
};
