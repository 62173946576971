import { ActionModal } from '../../../modal/ActionModal';
import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputFormik } from '../../../forms/textInput/TextInput.formik';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { EditorContext } from '../../EditorContext';
import { FormikState } from 'formik/dist/types';
import { createImprintSchema, IImprintSchema } from '../ImprintSchema';
import { useAddProjectImprintProfileMutation } from '../../../api/treditionApi';

export interface INewImprintLabelProps {
  onImprintLabelAdded: (imprintLabelId: string) => void;
  onCancel: () => void;
}

export const NewImprintLabel: FC<INewImprintLabelProps> = ({ onImprintLabelAdded, onCancel }) => {
  const { t } = useTranslation();

  const [addProjectImprintProfile] = useAddProjectImprintProfileMutation();

  const {
    state: { projectId },
  } = useContext(EditorContext);

  const onFormSubmit = useCallback(
    (
      values: IImprintSchema,
      { resetForm }: { resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void },
    ) => {
      const promise = addProjectImprintProfile({ projectId, imprintRole: values });

      return toast
        .promise(promise.unwrap(), {
          loading: t('CreatingPublisherLabel'),
          success: t('PublisherLabelCreatedAndAdded'),
          error: t('PublisherLabelCreationFailed'),
        })
        .then((val) => {
          onImprintLabelAdded(val);
          resetForm();
          return true;
        })
        .catch((e) => {
          // TODO: handle error
          console.error(e);
          return false;
        });
    },
    [addProjectImprintProfile, onImprintLabelAdded, projectId, t],
  );

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: onFormSubmit,
    validationSchema: createImprintSchema(),
    validateOnMount: true,
  });

  const { handleSubmit, isValid, resetForm } = formik;

  return (
    <div>
      <ActionModal
        onOk={() => {
          handleSubmit();
        }}
        onCancel={() => {
          resetForm();
          onCancel();
        }}
        isOpen
        title={t('NewImprintProfile')}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          resetForm();
          onCancel();
        }}
        okButtonDisabled={!isValid}>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit}>
            <TextInputFormik name="name" label={t('PublisherLabelName')} />
          </Form>
        </FormikProvider>
      </ActionModal>
    </div>
  );
};
