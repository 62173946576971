import React from 'react';
import styles from './FormError.module.css';

export interface IFormError {
  error: string;
}

export const FormError: React.FC<IFormError> = ({ error }) => {
  if (typeof error !== 'string') {
    // FIXME: arrives as object sometimes (for example on complex select options)
    return null;
  }
  return <div className={styles.wrapper}>{error}</div>;
};
