import { AppThunk, RootState } from '../../redux/store';
import { getDesignerSlice } from '../designerSelectors';
import { defaultRulers, grabLayer } from './liveSheetSlice';
import { IRuler, IRulers, TransformationType } from './ILiveSheetState';
import { getActiveProduct } from '../project/productSelectors';

export const startTranslationTransform =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      input: { mouse, keyboard },
      view: { grid },
    } = getDesignerSlice(state);
    const startPoint = mouse.sheet;
    const rulers = grid.isEnabled ? defaultRulers : getSnap(state);

    dispatch(
      grabLayer({
        id,
        transform: {
          type: TransformationType.Translate,
          grabbedLayerId: id,
          startPoint,
          endPoint: startPoint,
          grid: grid.isEnabled ? grid.size : { width: 1, height: 1 },
          rulers,
        },
        isAdditive: keyboard.control,
      }),
    );
  };

function getSnap(state: RootState): IRulers {
  const h: IRuler[] = [];
  const v: IRuler[] = [];
  const product = getActiveProduct(state);
  if (!product) {
    return { h, v };
  }
  for (const region of product.regions) {
    const regionCenterX =
      region.bleedLeft + (region.x + (region.width - (region.bleedLeft + region.bleedRight)) / 2);
    const regionCenterY =
      region.bleedTop + (region.y + (region.height - (region.bleedTop + region.bleedBottom)) / 2);

    h.push({
      start: { x: region.x, y: regionCenterY },
      end: { x: region.x + region.width, y: regionCenterY },
    });
    v.push({
      start: { x: regionCenterX, y: region.y },
      end: { x: regionCenterX, y: region.y + region.height },
    });
  }
  return { h, v };
}
