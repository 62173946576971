import { Plugin } from "@ckeditor/ckeditor5-core";

const DEFAULT_SIZE = 16;

export class CustomFontSizeUI extends Plugin {
  init() {
    this.editor.ui.componentFactory.add("fontSizeDropdown", () => {
      const editor = this.editor;

      const command = editor.commands.get("fontSize");

      // Use original fontSize button - we only changes its behavior.
      const dropdownView = editor.ui.componentFactory.create("fontSize");

      // Show label on dropdown's button.
      dropdownView.buttonView.set("withText", true);

      // Disable icon on the button.
      dropdownView.buttonView.set("icon", false);

      // To hide the icon uncomment below.
      // dropdownView.buttonView.set( 'icon', false );

      const getCleanedFontSize = (value) => {
        if (!value) return DEFAULT_SIZE;

        const matches = /\d+/.exec(value);
        return matches[0] || DEFAULT_SIZE;
      };

      // Bind dropdown's button label to fontSize value.
      dropdownView.buttonView
        .bind("label")
        .to(command, "value", getCleanedFontSize);

      return dropdownView;
    });
  }
}
