import { QueryErrorType } from './resolveQueryError';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface IQueryErrorDescriptionProps {
  type: QueryErrorType;
}

export const QueryErrorDescriptions: Record<Partial<QueryErrorType>, string> = {
  [QueryErrorType.AccessDenied]: 'ErrorDescriptionAccessDenied',
  [QueryErrorType.Connection]: 'ErrorDescriptionConnection',
  [QueryErrorType.NotFound]: 'ErrorDescriptionNotFound',
  [QueryErrorType.Internal]: 'ErrorDescriptionInternalError',
};

export const QueryErrorDescription: FC<IQueryErrorDescriptionProps> = ({ type }) => {
  const { t } = useTranslation();
  const str = QueryErrorDescriptions[type] || QueryErrorDescriptions[QueryErrorType.Internal];
  return <span>{t(str)}</span>;
};
