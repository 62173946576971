import styles from './BookMeasurementProportions.module.css';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export enum KnownMeasurements {
  DinA4 = 'DIN_A4',
  USLetter = 'US_LETTER',
}

export interface IBookMeasurementProportionsProps {
  /**
   * format of the outer box
   */
  format: KnownMeasurements;
  /**
   * Label of the component
   */
  label: string;
  /**
   * Width of the current measurement in millimeters
   */
  width: number;
  /**
   * Height of the current measurement in millimeters
   */
  height: number;

  /**
   * If true, the current measurement will be disabled
   */
  disabled: boolean;

  /**
   * If true the size cannot be lower than 0 and cannot be higher than the format
   */
  ensureMeasurementWithinBounds?: boolean;
}

export const MeasurementMeta = {
  [KnownMeasurements.DinA4]: {
    className: styles.dina4,
    translationKey: 'DIN_A4',
  },
  [KnownMeasurements.USLetter]: {
    className: styles.usLetter,
    translationKey: 'US_LETTER',
  },
};

export const MaxSizeOfMeasurements = {
  [KnownMeasurements.DinA4]: {
    width: 210,
    height: 297,
  },
  [KnownMeasurements.USLetter]: {
    width: 216,
    height: 279,
  },
};

const ensureInBounds = (value: number, type: 'width' | 'height', format: KnownMeasurements) => {
  if (value > MaxSizeOfMeasurements[format][type]) return MaxSizeOfMeasurements[format][type];
  if (value < 0) return 0;
  return value;
};

export const BookMeasurementProportions = ({
  format = KnownMeasurements.DinA4,
  label,
  width,
  height,
  disabled = false,
  ensureMeasurementWithinBounds = true,
}: IBookMeasurementProportionsProps) => {
  const { t } = useTranslation();

  const { className, translationKey } = MeasurementMeta[format];
  const name = t(translationKey);

  const widthInBounds = ensureMeasurementWithinBounds
    ? ensureInBounds(width, 'width', format)
    : width;
  const heightInBounds = ensureMeasurementWithinBounds
    ? ensureInBounds(height, 'height', format)
    : height;

  return (
    <>
      <span className={styles.formatTitle}>{name}</span>
      <div className={classNames(styles.proportions, className)}>
        <div
          style={
            {
              ['--width']: `${widthInBounds}px`,
              ['--height']: `${heightInBounds}px`,
            } as CSSProperties
          }
          className={classNames(styles.currentProportion, { [styles.disabled]: disabled })}>
          {width && height && <div className={styles.label}>{label}</div>}
        </div>
      </div>
    </>
  );
};
