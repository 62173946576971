import React, { PropsWithChildren, useCallback } from 'react';
import { ILayer } from '../layer/LayersState';
import { useAppDispatch } from '../../redux/hooks';
import { Menu } from '../../menu/Menu';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../icons/Icon';
import { deleteLayers, setPadding } from '../layer/liveSheetSlice';
import { Slider } from '../../uiComponents/Slider';
import { consolidate } from '../../lib/consolidate';
import { SidePanelId } from '../sidePanel/sidePanelId';
import { openSidePanel } from '../view/viewSlice';
import { useSidePanel } from '../view/viewSelectors';
import { MenuItem } from '../../menu/MenuItem';

export interface ILayerMenuProps {
  layers: ILayer[];
}

export const LayerMenu: React.FC<PropsWithChildren<ILayerMenuProps>> = ({ layers, children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sideMenu = useSidePanel();

  const conPadding = consolidate(layers, ($) => $.padding);

  const onClickDeleteLayers = useCallback(() => {
    dispatch(deleteLayers({ filter: { selected: true } }));
  }, [dispatch]);

  const onChangePaddingSlider = useCallback(
    (padding: number) => {
      dispatch(setPadding({ filter: { selected: true }, padding }));
    },
    [dispatch],
  );

  if (layers.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <Menu>
        <MenuWrapper>
          <MenuItem
            title={t('Padding')}
            subMenu={() => (
              <Slider
                min={0}
                max={100}
                step={1}
                value={conPadding.isEqual ? conPadding.value || 0 : 0}
                onChange={onChangePaddingSlider}
              />
            )}>
            <Icon name="seitenraender" />
          </MenuItem>
          <MenuItem
            title={t('Objects')}
            isActive={sideMenu === SidePanelId.Depth}
            onClick={() => dispatch(openSidePanel(SidePanelId.Depth))}>
            <Icon name="objekte-control" />
          </MenuItem>
          <MenuItem
            title={t('Colors')}
            isActive={sideMenu === SidePanelId.Colors}
            onClick={() => dispatch(openSidePanel(SidePanelId.Colors))}>
            <Icon name="farbpalette-control" />
          </MenuItem>
          <MenuItem
            title={t('Dimensions')}
            isActive={sideMenu === SidePanelId.Measures}
            onClick={() => dispatch(openSidePanel(SidePanelId.Measures))}>
            <Icon name="lineal1" />
          </MenuItem>
          <MenuItem
            isActive={sideMenu === SidePanelId.Effects}
            onClick={() => dispatch(openSidePanel(SidePanelId.Effects))}>
            {t('Effects')}
          </MenuItem>
          <MenuItem onClick={onClickDeleteLayers} title={t('Delete')}>
            <Icon name="loeschen-control" />
          </MenuItem>
        </MenuWrapper>
      </Menu>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid var(--color-border);
  padding: 0 0.5rem;
`;
