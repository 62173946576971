import { createSlice } from '@reduxjs/toolkit';
import { setBookContentReducer } from './setBookContentReducer';
import { setStyleReducer } from './setStyleReducer';
import { IPreviewerState } from './PreviewerState';
import { setIsLoadingReducer } from './setIsLoadingReducer';
import { formDefaultValues } from '../components/form_constant/formDefaultValues';
import { setFormValuesReducer } from './setFormValuesReducer';
import { setFormValuesThunk } from './setFormValuesThunk';
import { setShowEditorReducer } from './setShowEditorReducer';
import { setZoomReducer } from './setZoomReducer';
import { setFormHistoryEntryReducer } from './setFormHistoryEntryReducer';
import { shortenFormHistoryReducer } from './shortenFormHistoryReducer';
import { setShowTemplatesReducer } from './setShowTemplatesReducer';

export const createInitialPreviewerState = (): IPreviewerState => ({
  bookContent: '',
  tocContent: '',
  style: '',
  isLoading: false,
  formValues: formDefaultValues,
  formHistory: [],
  showEditor: false,
  showTemplates: false,
  zoom: 100,
});

export const previewerSlice = createSlice({
  name: 'previewer',
  initialState: Object.freeze(createInitialPreviewerState()),
  reducers: {
    setBookContent: setBookContentReducer,
    setStyle: setStyleReducer,
    setIsLoading: setIsLoadingReducer,
    setFormValues: setFormValuesReducer,
    setFormHistoryEntry: setFormHistoryEntryReducer,
    shortenFormHistory: shortenFormHistoryReducer,
    setShowEditor: setShowEditorReducer,
    setShowTemplates: setShowTemplatesReducer,
    setZoom: setZoomReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(setFormValuesThunk.fulfilled, setFormValuesReducer);
  },
});

export const {
  setBookContent,
  setStyle,
  setIsLoading,
  setFormValues,
  setFormHistoryEntry,
  setShowEditor,
  setShowTemplates,
  setZoom,
  shortenFormHistory,
} = previewerSlice.actions;

export const previewerReducer = previewerSlice.reducer;
