import { createHistoryRecord, ICreateHistoryRecordData } from './createHistoryRecord';
import { AppThunk } from '../../redux/store';
import { getActiveProduct } from '../project/productSelectors';
import { record } from './historySlice';
import { ITreditionPalette } from '../palette/TreditionPalette';
import { getLastHistoryRecord } from './historySelectors';
import { ISheet } from '../project/ProductState';
import { getLiveSheetSlice } from '../layer/liveSheetSelectors';

export interface IWriteHistoryRecordData
  extends Omit<ICreateHistoryRecordData, 'sheets' | 'palette' | 'activeSheetId'> {
  sheets?: ISheet[];
  palette?: ITreditionPalette | null;
}

export interface IWriteHistoryRecordOptions {
  /**
   * If set to a number greater than zero the entry will not be written if the previous record is of the same type and
   * was created not later than the amount in milliseconds ago.
   */
  throttleMs?: number;
}

export const defaultOptions: IWriteHistoryRecordOptions = {};

/**
 * Creates and writes a history record to the currently active project.
 *
 * Does nothing if no product is currently active.
 */
export const writeHistoryRecord =
  (data: IWriteHistoryRecordData, options: IWriteHistoryRecordOptions = defaultOptions): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const product = getActiveProduct(state);
    if (!product) {
      return;
    }
    const now = Date.now();
    if (options.throttleMs) {
      const lastEntry = getLastHistoryRecord(state, product.id);
      if (
        lastEntry &&
        lastEntry.type === data.type &&
        now - lastEntry.createdAt < options.throttleMs
      ) {
        return;
      }
    }
    const liveSheet = getLiveSheetSlice(state);
    const r = createHistoryRecord({
      // need to write the live sheet data into the project snapshot before creating the record
      sheets: product.activeSheetId
        ? product.sheets.map(($) => {
            if ($.id === product.activeSheetId) {
              return { ...$, layers: liveSheet.layers };
            }
            return $;
          })
        : product.sheets,
      palette: product.palette,
      activeSheetId: product.activeSheetId,
      ...data,
    });
    dispatch(record({ productId: product.id, record: r }));
  };
