import { IDesignerViewState } from './IDesignerViewState';
import { PayloadAction } from '@reduxjs/toolkit';
import { SidePanelId } from '../sidePanel/sidePanelId';

/**
 * Opens a specific side panel
 */
export function openSidePanelReducer(
  state: IDesignerViewState,
  action: PayloadAction<SidePanelId>,
) {
  state.sidePanel = action.payload;
}
