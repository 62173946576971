import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Params, useParams } from 'react-router';
import { EditorNavigation } from './navigation/EditorNavigation';
import { EditorStepKey } from './EditorStepKey';
import { getStepFromRouteParams } from './getStepFromRouteParams';
import { getStepComponent } from './getStepComponent';
import { treditionApi, useGetProjectEditorMetadataQuery } from '../api/treditionApi';
import { EditorContext, IEditorContext } from './EditorContext';
import {
  EditorActionType,
  editorInitializer,
  INavigationPromptData,
  StepDirtyFn,
  StepSubmitFn,
  StepValidateFn,
  useEditorState,
} from './useEditorState';
import { QueryLoader } from '../loader/QueryLoader';
import { useAppDispatch } from '../redux/hooks';
import { push } from 'redux-first-history';
import { getEditorRouteByStep } from './getEditorRouteByStep';
import { saveStepChanges } from './saveStepChanges';
import { noop } from '../lib/noop';
import { isStepBlazorManaged } from './isStepBlazorManaged';
import { ActionBar } from './ActionBar';
import { forceRefreshBlazor } from '../interop/forceRefreshBlazor';
import { finishProjectBlazor } from '../interop/finishProjectBlazor';
import { saveProjectBlazor } from '../interop/saveProjectBlazor';
import { LazyLoader } from '../loader/LazyLoader';
import { LoadError } from '../error/LoadError';
import styles from './EditorPage.module.css';
import { ActionModal } from '../modal/ActionModal';
import { useTranslation } from 'react-i18next';

function stateInitializer(projectId: string, params: Readonly<Params>) {
  const state = editorInitializer();
  state.projectId = projectId;
  // allows to initialize the editor with a specific step (URL)
  state.step.current = getStepFromRouteParams(params);
  return state;
}

export const EditorPage: FC = () => {
  const params = useParams();
  const reduxDispatch = useAppDispatch();
  const projectId = useMemo<string>(() => params.projectId || '', [params.projectId]);
  const [state, dispatch] = useEditorState(() => stateInitializer(projectId, params));
  const metadataQuery = useGetProjectEditorMetadataQuery(projectId);
  const routeStep = useMemo<EditorStepKey>(() => getStepFromRouteParams(params), [params]);
  const lastRouteStep = useRef<EditorStepKey>(routeStep);
  const { onValidate, onCheckDirty, onSubmit, step } = state;
  const stepPage = useMemo(() => getStepComponent(step.current), [step.current]);

  useEffect(() => {
    window.TRC_SET_EDITOR_STEP_VISIBILITY = (step: EditorStepKey, isVisible: boolean) => {
      dispatch({ type: EditorActionType.SetVisibility, step, isVisible });
    };
    return () => {
      window.TRC_SET_EDITOR_STEP_VISIBILITY = noop;
    };
  }, [dispatch]);

  // triggers every time the step changes which causes a navigation to a different step.
  // this is the inverse way of how we would usually do it, but we need to do it this way because
  // it steps should not unmount before validating or submitting their changes
  // before they could prompt for saving when going back and forth using the browser navigation controls
  useEffect(() => {
    forceRefreshBlazor();

    // prevent initial push when mounting - the initial step and its route is covered in the editor state initializer
    if (lastRouteStep.current === routeStep) {
      return;
    }
    lastRouteStep.current = routeStep;
    dispatch({ type: EditorActionType.SetStep, step: routeStep });
  }, [routeStep, dispatch]);

  // triggers every time the metadata query fetched new data
  useEffect(() => {
    if (!metadataQuery.originalArgs || !metadataQuery.fulfilledTimeStamp || !metadataQuery.data) {
      return;
    }
    dispatch({
      type: EditorActionType.SetMetadata,
      metadata: metadataQuery.data,
      projectId: metadataQuery.originalArgs,
    });
  }, [metadataQuery, dispatch]);

  const registerStep = useCallback(
    (onSubmit: StepSubmitFn, onValidate: StepValidateFn, onCheckDirty: StepDirtyFn) => {
      dispatch({ type: EditorActionType.RegisterStep, onSubmit, onValidate, onCheckDirty });
    },
    [dispatch],
  );

  const goToStep = useCallback(
    (key: EditorStepKey, save = true) => {
      if (step.current === key) {
        return;
      }
      const prompt = (promptData: INavigationPromptData) =>
        dispatch({
          type: EditorActionType.SetPrompt,
          prompt: promptData,
        });
      const navigate = () => {
        lastRouteStep.current = key; // silence the route difference effect
        dispatch({ type: EditorActionType.SetStep, step: key });
        reduxDispatch(push(getEditorRouteByStep(key, projectId)));
      };

      if (state.isReadonly) {
        return navigate();
      }

      if (isStepBlazorManaged(step.current)) {
        dispatch({ type: EditorActionType.SetBusy, isBusy: true });
        saveProjectBlazor()
          .then((success) => {
            if (!success) {
              return;
            }
            return reduxDispatch(
              treditionApi.endpoints.getProjectEditorMetadata.initiate(projectId),
            )
              .then(() => {
                navigate();
              })
              .catch((error) => {
                console.error('Error fetching metadata after saving step', step.current, error);
              });
          })
          .catch((error) => {
            console.error('Error saving step', error);
          })
          .finally(() => {
            dispatch({ type: EditorActionType.SetBusy, isBusy: false });
          });
        return;
      }

      if (save) {
        dispatch({ type: EditorActionType.SetBusy, isBusy: true });
        saveStepChanges({
          stepInfo: step,
          targetStepKey: key,
          onSubmit: onSubmit,
          onValidate: onValidate,
          onCheckDirty: onCheckDirty,
          prompt,
        })
          .then((mayNavigate) => {
            if (mayNavigate) {
              navigate();
            }
          })
          .finally(() => {
            dispatch({ type: EditorActionType.SetBusy, isBusy: false });
          });
      } else {
        navigate();
      }
    },
    [dispatch, reduxDispatch, step, onSubmit, onValidate, onCheckDirty, projectId],
  );

  const goToNextStep = useCallback(() => {
    if (state.step.next) {
      goToStep(state.step.next);
    }
  }, [goToStep, state.step.next]);

  const goToPreviousStep = useCallback(() => {
    if (state.step.previous) {
      goToStep(state.step.previous);
    }
  }, [goToStep, state.step.previous]);

  const setStepVisibility = useCallback(
    (isVisible: boolean, step?: EditorStepKey) => {
      dispatch({ type: EditorActionType.SetVisibility, isVisible, step });
    },
    [dispatch],
  );
  const context = useMemo<IEditorContext>((): IEditorContext => {
    return {
      state,
      setStepVisibility,
      goToNextStep,
      goToPreviousStep,
      goToStep,
      registerStep,
      finishProject: finishProjectBlazor,
    };
  }, [state, registerStep, setStepVisibility, goToNextStep, goToPreviousStep, goToStep]);

  const { t } = useTranslation();

  const showLoader = !state.isReady || state.isBusy;

  return (
    <EditorContext.Provider value={context}>
      {showLoader && <LazyLoader />}
      <QueryLoader
        query={metadataQuery}
        renderError={(query) => <LoadError query={query} />}
        renderLoading={() => null}>
        <div className={styles.wrapper}>
          <EditorNavigation />
          {React.createElement(stepPage, { projectId })}
          {!!state.prompt && (
            <ActionModal
              title={t('ModalDiscardChanges')}
              okButtonLabel={t('YesDiscardChanges')}
              cancelButtonLabel={t('Cancel')}
              onOk={() => {
                if (state.prompt) {
                  goToStep(state.prompt.targetStep, false);
                  dispatch({ type: EditorActionType.SetPrompt, prompt: null });
                }
              }}
              onCancel={() => {
                dispatch({ type: EditorActionType.SetPrompt, prompt: null });
              }}
              isOpen>
              {t(state.prompt.message)}
            </ActionModal>
          )}
          <ActionBar />
        </div>
      </QueryLoader>
    </EditorContext.Provider>
  );
};
