import styled from 'styled-components/macro';

export const StyledRadioButton = styled.input.attrs((props) => ({
  type: 'radio',
}))`
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: var(--color-cta);
  width: 20px;
  height: 20px;
  border-radius: 50%;

  border: 2px solid var(--color-cta);

  &:checked {
    background-color: var(--color-background-accent);
    border: 3px solid var(--color-cta);
  }
`;
