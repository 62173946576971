import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { IImageLayer, LayerType } from './LayersState';

export interface IReplaceImageActionPayload {
  /**
   * File id which should be replaced in all matching image layers. Mutually exclusive with layerId
   */
  fileId?: string;

  /**
   * Layer id which should have its fileId replaced. Mutually exclusive with fileId
   */
  layerId?: string;

  /**
   * New file id to assign
   */
  newFileId: string;

  /**
   * New width of the file referenced by {@link newFileId}. If undefined the width will be kept.
   */
  width?: number;

  /**
   * New height of the file referenced by {@link newFileId}. If undefined the height will be kept.
   */
  height?: number;
}

export function replaceImageReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IReplaceImageActionPayload>,
) {
  if (action.payload.layerId) {
    const layer = state.layers.find(($) => $.id === action.payload.layerId);
    if (!layer || layer.type !== LayerType.Image) {
      return;
    }
    replaceInLayer(layer as IImageLayer);
  } else if (action.payload.fileId) {
    state.layers.forEach((layer) => {
      if (layer.type !== LayerType.Image) {
        return;
      }
      const l = layer as IImageLayer;
      if (l.fileId !== action.payload.fileId) {
        return;
      }
      replaceInLayer(l);
    });
  }

  function replaceInLayer(layer: IImageLayer) {
    layer.fileId = action.payload.newFileId;
    if (
      typeof action.payload.width !== 'undefined' &&
      typeof action.payload.height !== 'undefined'
    ) {
      layer.width = action.payload.width;
      layer.height = action.payload.height;
      layer.display.width = action.payload.width;
      layer.display.height = action.payload.height;
    }
  }
}
