import { createSlice } from '@reduxjs/toolkit';
import { addToClipboardReducer } from './addToClipboardReducer';
import { pasteLayers } from '../layer/liveSheetSlice';
import { applyPasteLayersToPasteCountReducer } from './applyPasteLayersToPasteCountReducer';

export interface IClipboardState {
  dataType: ClipboardDataType;
  data: unknown | null;
  pasteCount: number;
}

export enum ClipboardDataType {
  Void,
  Layer,
}

export const createInitialClipboardState = (): IClipboardState => ({
  dataType: ClipboardDataType.Void,
  data: null,
  pasteCount: 0,
});

export const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState: Object.freeze(createInitialClipboardState()),
  reducers: {
    addToClipboard: addToClipboardReducer,
  },
  extraReducers: (builder) => builder.addCase(pasteLayers, applyPasteLayersToPasteCountReducer),
});

export const { addToClipboard } = clipboardSlice.actions;
