import { useReducer } from 'react';
import { IPoint } from '../../../@types/IPoint';

export enum CoverPreviewActions {
  AddRotation,
  AddZoom,
  InitializeZoom,
  SetIsRotating,
}

export interface IAddRotationAction {
  type: CoverPreviewActions.AddRotation;
  rotation: IPoint;
}

export interface IAddZoomAction {
  type: CoverPreviewActions.AddZoom;
  zoom: number;
}

export interface IInitializeZoomAction {
  type: CoverPreviewActions.InitializeZoom;
  zoom: number;
}

export interface ISetIsRotatingAction {
  type: CoverPreviewActions.SetIsRotating;
  isRotating: boolean;
}

export type ICoverPreviewAction =
  | IAddRotationAction
  | ISetIsRotatingAction
  | IAddZoomAction
  | IInitializeZoomAction;

export interface ICoverPreviewState {
  /**
   * Whether the user is currently rotating the book
   */
  isRotating: boolean;

  /**
   * The current rotation in degrees for both axes
   */
  rotation: IPoint;

  /**
   * The current zoom as a fraction with 0.1 being 10%
   */
  zoom: number;

  /**
   * Whether the optimal initial zoom has been initialized
   */
  isZoomInitialized: boolean;
}

export const coverPreviewReducer = (
  state: ICoverPreviewState,
  action: ICoverPreviewAction,
): ICoverPreviewState => {
  switch (action.type) {
    case CoverPreviewActions.AddRotation:
      return {
        ...state,
        rotation: {
          x: state.rotation.x + action.rotation.x,
          y: state.rotation.y + action.rotation.y,
        },
      };
    case CoverPreviewActions.AddZoom:
      return {
        ...state,
        zoom: Math.max(state.zoom + action.zoom, 0.1),
      };
    case CoverPreviewActions.InitializeZoom:
      return { ...state, zoom: action.zoom, isZoomInitialized: true };
    case CoverPreviewActions.SetIsRotating:
      return {
        ...state,
        isRotating: action.isRotating,
      };
    default:
      return state;
  }
};

export function coverPreviewInitializer(): ICoverPreviewState {
  return {
    isRotating: false,
    rotation: { x: 10, y: -45 },
    zoom: 1,
    isZoomInitialized: false,
  };
}

export const useCoverPreviewState = (initialState = coverPreviewInitializer()) => {
  return useReducer(coverPreviewReducer, initialState);
};
