/**
 * Reusable delegate to keep an event from propagating
 */
import { EventHandler } from 'react';

interface IPropagationStoppable {
  stopPropagation: () => unknown;
}

export const stopPropagation = (e: IPropagationStoppable) => e.stopPropagation();
