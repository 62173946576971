import { FC, Fragment } from 'react';
import { px } from '../../lib/px';
import { ICoverDimensions } from './calculateCoverDimensions';
import { ProductType } from '../project/ProductState';

export interface ID3CoverProps {
  /**
   * Cover dimensions determined by {@link calculateCoverDimensions}
   */
  dimensions: ICoverDimensions;

  /**
   * Current HTML of the designer's layer render
   */
  html: string;

  /**
   * On frontcovers will render the book as a box whereas on hardcovers the page faces will be
   * shrunk by a certain amount, so it looks more like a hard cover.
   */
  productType: ProductType;
}

export const D3Cover: FC<ID3CoverProps> = ({ dimensions, html, productType }) => {
  const { coverWidth, coverHeight, jacketWidth, spineWidth, shiftX, shiftY } = dimensions;
  const pageDepression = productType === ProductType.HardCover ? 8 : 0;

  return (
    <Fragment>
      <div
        className="book-top"
        style={{
          width: px(coverWidth - pageDepression),
          height: px(spineWidth),
          transform: `rotateX(90deg) translateZ(${px(spineWidth / 2 - pageDepression)})`,
          backgroundImage: 'repeating-linear-gradient(lightgray, white 4px)',
        }}
      />
      <div
        className="book-bottom"
        style={{
          width: px(coverWidth - pageDepression),
          height: px(spineWidth),
          transform: `rotateX(-90deg) translateZ(${px(
            coverHeight - spineWidth / 2 - pageDepression,
          )}`,
          backgroundImage: 'repeating-linear-gradient(lightgray, white 4px)',
        }}
      />

      <div
        className="book-front"
        style={{
          width: px(spineWidth),
          height: px(coverHeight - 2 * pageDepression),
          transform: `rotateY(-270deg) translateZ(${px(
            coverWidth - spineWidth / 2 - pageDepression,
          )}) translateY(${px(pageDepression)})`,
          backgroundImage: 'repeating-linear-gradient(90deg, lightgray, white 4px)',
        }}
      />
      <div
        className="cover-front"
        style={{
          width: px(coverWidth),
          height: px(coverHeight),
          transform: `translateZ(${px(spineWidth / 2)})`,
        }}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          style={{
            // shift all content so only the cover front is visible
            transform: `translateX(${px(
              -(coverWidth + spineWidth + jacketWidth) + shiftX,
            )}) translateY(${px(shiftY)})`,
          }}></div>
      </div>
      <div
        className="cover-back"
        style={{
          width: px(coverWidth),
          height: px(coverHeight),
          transform: `translateZ(${px(-spineWidth / 2)}) rotateY(180deg)`,
        }}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          style={{
            // shift all content so only the cover back is visible
            transform: `translateX(${px(-jacketWidth + shiftX)}) translateY(${px(shiftY)})`,
          }}
        />
      </div>
      <div
        className="spine"
        style={{
          width: px(spineWidth),
          height: px(coverHeight),
          transform: `rotateY(270deg) translateZ(${px(spineWidth / 2)}`,
        }}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          style={{
            transform: `translateX(${px(-(coverWidth + jacketWidth) + shiftX)}) translateY(${px(
              shiftY,
            )})`,
          }}
        />
      </div>
    </Fragment>
  );
};
