import { AppThunk } from '../../redux/store';
import { getActiveSheetElement } from '../sheet/getSheetOffset';
import { releaseLayers, selectLayersInArea } from '../layer/liveSheetSlice';
import { getSelection, getZoom } from '../view/viewSelectors';
import { setSelection } from '../view/viewSlice';
import { getLiveSheetSlice } from '../layer/liveSheetSelectors';
import { getProjectSlice } from '../project/projectSelectors';

/**
 * Only fires if the work space is active
 */
export const handleWorkSpacePointerUp =
  (e: PointerEvent): AppThunk =>
  (dispatch, getState) => {
    if (e.button !== 0) {
      return;
    }
    let state = getState();
    const project = getProjectSlice(state);
    if (!project || project.isReadonly) {
      return;
    }
    const liveSheet = getLiveSheetSlice(state);
    if (liveSheet.transform) {
      dispatch(releaseLayers());
    }
    state = getState();
    const selection = getSelection(state);
    const zoomFactor = getZoom(state);
    const { start, end } = selection;
    if (start) {
      dispatch(setSelection({ start: null, end: null }));
    }
    if (start && end) {
      const sheetElement = getActiveSheetElement();
      if (sheetElement) {
        // translate work space coordinates to sheet element coordinates
        const startNormalized = {
          x: (start.x - sheetElement.offsetLeft) / zoomFactor,
          y: (start.y - sheetElement.offsetTop) / zoomFactor,
        };
        const endNormalized = {
          x: (end.x - sheetElement.offsetLeft) / zoomFactor,
          y: (end.y - sheetElement.offsetTop) / zoomFactor,
        };
        dispatch(selectLayersInArea({ regionStart: startNormalized, regionEnd: endNormalized }));
      }
    }
  };
