import { combineReducers } from '@reduxjs/toolkit';
import { viewSlice } from './view/viewSlice';
import { inputSlice } from './input/inputSlice';
import { historySlice } from './history/historySlice';
import { liveSheetSlice } from './layer/liveSheetSlice';
import { dragSlice } from './drag/dragSlice';
import { projectSlice } from './project/projectSlice';
import { clipboardSlice } from './clipboard/clipboardSlice';
import { templateSlice } from './templates/templateSlice';
import { problemSlice } from './problem/problemSlice';

export const designerReducer = combineReducers({
  [viewSlice.name]: viewSlice.reducer,
  [inputSlice.name]: inputSlice.reducer,
  [historySlice.name]: historySlice.reducer,
  [liveSheetSlice.name]: liveSheetSlice.reducer,
  [dragSlice.name]: dragSlice.reducer,
  [projectSlice.name]: projectSlice.reducer,
  [problemSlice.name]: problemSlice.reducer,
  [clipboardSlice.name]: clipboardSlice.reducer,
  [templateSlice.name]: templateSlice.reducer,
});
