import { createSlice } from '@reduxjs/toolkit';
import { ILocaleState } from './ILocaleState';
import { IsoLanguageCode, isoToLegacyLanguageCode } from './language';
import { setLanguageReducer } from './setLanguageReducer';
import { configure } from '../config/configSlice';
import { applyConfigureToLocaleReducer } from './applyConfigureToLocaleReducer';

export function createInitialLocaleState(): ILocaleState {
  const defaultCode = IsoLanguageCode.DE;
  return {
    languageCode: defaultCode,
    legacyLanguageCode: isoToLegacyLanguageCode(defaultCode),
    languageResourceLoaded: false,
  };
}

export const localeSlice = createSlice({
  name: 'locale',
  initialState: Object.seal(createInitialLocaleState()),
  reducers: {
    setLanguage: setLanguageReducer,
    setLanguageResourceLoaded: (state, action) => {
      state.languageResourceLoaded = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(configure, applyConfigureToLocaleReducer),
});

export const { setLanguage, setLanguageResourceLoaded } = localeSlice.actions;
