import { createSlice } from '@reduxjs/toolkit';
import { IHistoryState } from './IHistoryState';
import { recordReducer } from './recordReducer';
import { setHistoryIndexReducer } from './setHistoryIndexReducer';
import { recordBySetProjectActionReducer } from './recorders/recordBySetProjectActionReducer';
import { restoreFromHistoryReducer } from './restoreFromHistoryReducer';
import { setProject } from '../project/projectActions';
import { treditionApi } from '../../api/treditionApi';
import { applySaveProjectToHistoryReducer } from './applySaveProjectToHistoryReducer';
import { invalidateSaveIndicesReducer } from './invalidateSaveIndicesReducer';

/**
 * Keep this list in sync with {@link HistoryEntry}'s description generator
 */
export enum HistoryRecordType {
  Unknown = 0,
  ProjectInitialized,
  LayerMoved,
  LayerScaled,
  LayerRotated,
  LayerCreated,
  LayerDeleted,
  LayerChanged,
  LayerDepthChanged,
  ImageReplaced,
  PaletteChanged,
  ProductImported,
  LayerPasted,
  SheetCreated,
  SheetReordered,
  SheetDeleted,
  TemplateApplied,
}

export const createInitialHistoryState = (): IHistoryState => ({
  revision: 0,
  byProductId: {},
});

export const historySlice = createSlice({
  name: 'history',
  initialState: Object.seal(createInitialHistoryState()),
  reducers: {
    invalidateSaveIndices: invalidateSaveIndicesReducer,
    record: recordReducer,
    restoreFromHistory: restoreFromHistoryReducer,
    setHistoryIndex: setHistoryIndexReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(setProject, recordBySetProjectActionReducer)
      .addMatcher(
        treditionApi.endpoints.saveCoverDesign.matchFulfilled,
        applySaveProjectToHistoryReducer,
      )
      .addMatcher(
        treditionApi.endpoints.saveFrontMatterDesign.matchFulfilled,
        applySaveProjectToHistoryReducer,
      ),
});

export const { invalidateSaveIndices, record, restoreFromHistory, setHistoryIndex } =
  historySlice.actions;
