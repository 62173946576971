/**
 * Mutates the given array by swapping two elements based on the indices a and b.
 *
 * Does nothing if the indices are the same or out of bounds.
 */
export function swap<T>(array: T[], a: number, b: number): boolean {
  if (a === b || a < 0 || b < 0 || a >= array.length || b >= array.length) {
    return false;
  }
  const tmp = array[b];
  array[b] = array[a];
  array[a] = tmp;
  return true;
}
