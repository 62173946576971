import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILocaleState } from './ILocaleState';
import { IsoLanguageCode, isoToLegacyLanguageCode } from './language';

export function setLanguageReducer(
  state: Draft<ILocaleState>,
  action: PayloadAction<IsoLanguageCode>,
) {
  const legacy = isoToLegacyLanguageCode(action.payload);
  state.languageCode = action.payload;
  state.legacyLanguageCode = legacy;
}
