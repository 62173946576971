import { RootState } from '../../redux/store';
import { getProjectSlice } from './projectSelectors';
import { useAppSelector } from '../../redux/hooks';
import { IProduct } from './ProductState';
import { ITreditionPalette } from '../palette/TreditionPalette';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';

/**
 * Returns the product contained by the currently opened project.
 *
 * Returns null if the project was not found or no project is currently opened.
 */
export const getProductById = (state: RootState, id: string): IProduct | null => {
  const project = getProjectSlice(state);
  if (!project) {
    return null;
  }
  return project.products.find(($) => $.id === id) || null;
};

/**
 * Returns the id of the currently active product or null if no project is opened
 * or no product is active.
 */
export const getActiveProductId = (state: RootState): string | null => {
  return getProjectSlice(state)?.activeProduct || null;
};

/**
 * Returns the currently opened product if existing or null
 */
export const getActiveProduct = (state: RootState): IProduct | null => {
  return lookupActiveProductReducer(getProjectSlice(state));
};

export const getActiveSheetId = (state: RootState): string | null => {
  const product = getActiveProduct(state);
  return product ? product.activeSheetId : null;
};

/**
 * Returns whether the opened design project is readonly. Returns false if no project is opened.
 */
export const getIsReadonly = (state: RootState): boolean => {
  const project = getProjectSlice(state);
  return project ? project.isReadonly : false;
};

/**
 * Returns the active palette of the currently active project or null
 */
export const getActivePalette = (state: RootState): ITreditionPalette | null =>
  getActiveProduct(state)?.palette || null;

/**
 * Provides the currently opened product to a React component or null if not existing
 */
export const useActiveProduct = () => useAppSelector(getActiveProduct);

/**
 * Provides the active palette for the currently open project or null if no palette is currently active to a React component
 */
export const useActivePalette = () => useAppSelector(getActivePalette);

/**
 * Provides whether the project is readonly to a React component
 */
export const useIsReadonly = () => useAppSelector(getIsReadonly);
