import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainBookWizardLayout } from '../../layout/mainBookWizard/MainBookWizardLayout';
import { useGetRegionOptionsQuery, useSetRegionMutation } from '../../api/treditionApi';
import { BasicButton } from '../../buttons/basic/BasicButton';
import { toast } from 'react-hot-toast';
import { AxiosResponse } from 'axios';
import { noop } from '../../lib/noop';
import { getResponseErrorReason } from '../../error/getResponseErrorReason';
import { Select } from '../../select/Select';
import { ensureArray } from '../../lib/emptyArray';
import styles from './RegionPage.module.css';
import { InfoButton } from '../../buttons/info/InfoButton';

/**
 * If the user has not locked in their region this page is blocking the customer
 */
export const RegionPage: FC = () => {
  const { t } = useTranslation();
  const [region, setRegion] = useState<string | null>(null);
  const regionOptionsQuery = useGetRegionOptionsQuery();
  const [setRegionMutation] = useSetRegionMutation();
  const onClickSubmitRegion = useCallback(() => {
    if (!region) {
      return;
    }
    const task = setRegionMutation(region).unwrap();
    toast
      .promise(task, {
        loading: t('FixRegionLoading'), // TODO
        success: t('FixRegionSuccess'), // TODO
        error: (response: AxiosResponse) => {
          const reason = getResponseErrorReason(
            response,
            () => t('ErrorRegionAlreadyFixed'), // TODO
          );
          return t('FixRegionError: {reason}', { reason }); // TODO
        },
      })
      .catch(noop);
  }, [t, region, setRegionMutation]);

  return (
    <div className={styles.wrapper}>
      <section className={styles.section}>
        <h1>{t('SelectProjectCurrency')}</h1>
        <Select
          options={ensureArray(regionOptionsQuery.data)}
          onChange={setRegion}
          value={region}
        />
        <div className={styles.flex}>
          <span>{t('SelectCurrencyQuestion')}</span>
          <InfoButton infoText={t('SelectCurrencyInfo')} />
        </div>
        <BasicButton
          className={styles.button}
          onClick={onClickSubmitRegion}
          disabled={!region}
          label={t('FixRegion')}
        />
      </section>
      <img
        src="/images/Auswahl-Waehrung_2500.jpg"
        alt=""
        srcSet="/images/Auswahl-Waehrung_800.jpg 800w,
                     /images/Auswahl-Waehrung_1200.jpg 1200w,
                     /images/Auswahl-Waehrung_2500.jpg "
        sizes="(max-width: 1200px) 800px, (max-width: 1600px) 1200px, 2500px"
        className={styles.image}
      />
    </div>
  );
};
