import { FC } from 'react';
import './TemplateList.css';
import styled from 'styled-components/macro';
import { IDesignTemplateSearchResult } from './IDesignerTemplateState';
import { Icon } from '../../icons/Icon';
import { useTranslation } from 'react-i18next';

export interface ITemplateListProps {
  /**
   * Results of the template search query
   */
  templates: IDesignTemplateSearchResult[];

  /**
   * The currently selected template id
   */
  activeId: string | null;

  /**
   * Whether the list is currently busy loading new entries (also on subsequent loads)
   */
  isFetching: boolean;

  /**
   * Invoked when the user clicks on one of the search result entries
   */
  onClickTemplate: (templateId: string) => unknown;

  /**
   * Invoked when the user clicks on the rename button. If no callback is given, the button will
   * not be shown.
   */
  onClickRenameTemplate?: (templateId: string, currentName: string) => unknown;

  /**
   * Invoked when the user clicks on the delete button of an owned template. If no callback is
   * given, the button will not be shown
   */
  onClickDeleteTemplate?: (templateId: string) => unknown;
}

export const TemplateList: FC<ITemplateListProps> = ({
  activeId,
  isFetching,
  onClickTemplate,
  onClickRenameTemplate,
  onClickDeleteTemplate,
  templates,
}) => {
  const { t } = useTranslation();

  return (
    <StyledTemplateList isFetching={isFetching}>
      {templates.map((tp) => {
        if (!tp.thumb) {
          return null;
        }
        const showPanel = Boolean(tp.userId && (onClickRenameTemplate || onClickDeleteTemplate));

        return (
          <StyledListEntry key={tp.id} isActive={tp.id === activeId}>
            <img
              src={tp.thumb}
              alt={tp.name}
              role="button"
              onClick={() => onClickTemplate(tp.id)}
            />
            {showPanel && (
              <TemplateBottomPanel>
                <TemplateName>{tp.name}</TemplateName>
                <TemplateListEntryPanel>
                  {!!onClickDeleteTemplate && (
                    <button onClick={() => onClickDeleteTemplate(tp.id)} title={t('Delete')}>
                      <Icon name="loeschen-control" />
                    </button>
                  )}
                  {!!onClickRenameTemplate && (
                    <button onClick={() => onClickRenameTemplate(tp.id, tp.name)} title={t('Edit')}>
                      <Icon name="editieren-control" />
                    </button>
                  )}
                </TemplateListEntryPanel>
              </TemplateBottomPanel>
            )}
          </StyledListEntry>
        );
      })}
    </StyledTemplateList>
  );
};

const TemplateListEntryPanel = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  justify-content: flex-end;

  > button {
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 0.5rem;
  }
`;

const TemplateBottomPanel = styled.div`
  display: flex;
`;
const TemplateName = styled.div`
  display: flex;
  align-items: flex-end;
`;

interface IStyledTemplateListProps {
  isFetching: boolean;
}

const StyledTemplateList = styled.ul.attrs<IStyledTemplateListProps>((props) => ({
  style: {
    opacity: props.isFetching ? 0.5 : 1,
  },
}))<IStyledTemplateListProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 2rem;
  overflow-y: auto;
  height: calc(100vh - 145px);
  color-scheme: dark;
  flex-grow: 1;

  > li > img {
    width: 180px;
  }

  .templates-panel > & {
    color-scheme: normal;
    padding: 0;
    justify-content: center;
    height: auto;
    overflow-y: inherit;
  }

  .templates-panel > & > li {
    background-color: white;
    border: 1px solid var(--color-border);
  }

  .templates-panel > & > li > img {
    width: 260px;
    cursor: pointer;
  }
`;

interface IStyledListEntryProps {
  isActive: boolean;
}

const StyledListEntry = styled.li.attrs<IStyledListEntryProps>((props) => ({
  style: {
    outline: props.isActive ? '2px solid var(--color-cta)' : undefined,
  },
}))<IStyledListEntryProps>`
  background: #2c2c2c;
  padding: 0.5rem;
  border-radius: 3px;
  height: max-content;
  cursor: pointer;
`;
