import { FC } from 'react';
import { SuggestedColorsHex } from './ColorConstants';
import { ColorDisplay } from './ColorDisplay';
import { IRGBA } from '../../lib/Color';

export interface ISuggestedColorsProps {
  /**
   * Invoked when the user clicks on a suggested color
   */
  onSelectColor: (rgba: IRGBA) => unknown;
}

export const SuggestedColors: FC<ISuggestedColorsProps> = ({ onSelectColor }) => {
  return (
    <div className="suggested-colors">
      {SuggestedColorsHex.map((hexColor) => {
        return <ColorDisplay key={hexColor} hex={hexColor} onClick={onSelectColor} />;
      })}
    </div>
  );
};
