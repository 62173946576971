import merge from 'lodash/merge';
import { DeepPartial, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITextFormatting, ITextLayer, LayerType } from './LayersState';
import { filterLayers, ILayerFilter } from './layerFilter';
import { ILiveSheetState } from './ILiveSheetState';

export interface IFormatTextLayerActionPayload {
  /**
   * The filter which will be used to format multiple layers.
   *
   * Note that the layer type `Text` will be enforced.
   */
  filter: ILayerFilter;

  /**
   * The text formatting to apply
   */
  format: DeepPartial<ITextFormatting>;
}

export function formatTextLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IFormatTextLayerActionPayload>,
) {
  const { filter, format } = action.payload;
  const textLayers = filterLayers(state.layers, filter) as unknown as ITextLayer[];
  textLayers.forEach((layer) => {
    if (layer.type !== LayerType.Text) {
      return;
    }
    merge(layer.formatting, format);
    layer.updatedAt = Date.now();
  });
}
