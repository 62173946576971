import * as yup from 'yup';
import { isbnCharacters, isbnLength, isbnValid } from './shared/yupHelper/isbnValidation';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
yup.addMethod<yup.StringSchema>(yup.string, 'isbnLength', isbnLength);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
yup.addMethod<yup.StringSchema>(yup.string, 'isbnValid', isbnValid);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
yup.addMethod<yup.StringSchema>(yup.string, 'isbnCharacters', isbnCharacters);

export default yup;
