import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';
import { IAddSheetActionPayload } from '../project/projectActions';

export function applyAddSheetToLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IAddSheetActionPayload>,
) {
  state.layers = action.payload.sheet.layers;
}
