import { IAuthState } from './IAuthState';
import { PayloadAction } from '@reduxjs/toolkit';
import { decodeJwt } from './decodeJwt';

export type ISetJwtActionPayload = string | null;

export function setJwtReducer(state: IAuthState, action: PayloadAction<ISetJwtActionPayload>) {
  if (!action.payload) {
    state.jwt = null;
    state.userId = null;
    return;
  }
  const decodedJwt = decodeJwt(action.payload);
  if (!decodedJwt) {
    return;
  }
  state.jwt = action.payload;
  state.userId = decodedJwt.userId;
}
