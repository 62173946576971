import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { addPlainLayer } from '../layer/liveSheetSlice';
import { DesignerImageSource, DragContextType, dragStart } from '../drag/dragSlice';
import { QueryLoader } from '../../loader/QueryLoader';
import { useBlobUrl } from '../../config/configSelectors';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ImagePreview } from '../imagePreview/ImagePreview';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';
import {
  useGetDesignerPatternsFilterByTagIdQuery,
  useGetTagsForAreaQuery,
} from '../../api/treditionApi';

interface ICatalogItem {
  id: number;
  thumbnailUrl: string;
  imageUrl: string;
  fileId: string;
  isLogo: boolean;
}

export const PATTERNS_TAG_AREA = 6;

export const PatternsPanel: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>('');

  const onChangeImageFilter = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value || '');
  }, []);

  const getTagsQuery = useGetTagsForAreaQuery({
    area: PATTERNS_TAG_AREA,
  });

  const entries = useMemo(() => {
    if (getTagsQuery.isLoading || !getTagsQuery.data) return [];
    return getTagsQuery.data.tags
      .map((t) => {
        const getLanguageByLanguageCode = (languageCode: string) => {
          return t.descriptions.find((d) => d.languageCode.toLowerCase() === languageCode);
        };

        const preferredDescriptionOfUserLanguage = getLanguageByLanguageCode(i18n.language);

        return {
          id: t._id,
          // fallback to english if there's no translation of user language
          description: preferredDescriptionOfUserLanguage || getLanguageByLanguageCode('en'),
        };
      })
      .filter((d) => d.description != null)
      .sort((a, b) => a.description!.text.localeCompare(b.description!.text));
  }, [getTagsQuery.data, getTagsQuery.isLoading, i18n.language]);

  const getDesignerPatternsQuery = useGetDesignerPatternsFilterByTagIdQuery({
    tagId: filter,
  });
  const blobStorageUri = useBlobUrl();
  const catalog = useMemo(() => {
    if (!getDesignerPatternsQuery.data) {
      return [];
    }
    const catalog = getDesignerPatternsQuery.data.map((f, index) => {
      // NOTE: this is how the current customer does it
      const fileId = f.replace(/.svg.jpg$/i, '.svg');
      return {
        id: index,
        thumbnailUrl:
          fileId === '15fe8511-75c5-4209-8f18-994d1ebaf787.svg'
            ? `${blobStorageUri}coverimages/${fileId}`
            : `${blobStorageUri}thumbnails/${f}`,
        imageUrl: `${blobStorageUri}coverimages/${fileId}`,
        fileId,
        isLogo:
          fileId === 'ef7c8877-ff4f-4747-8d85-bab1a2afa10b.svg' ||
          fileId === '15fe8511-75c5-4209-8f18-994d1ebaf787.svg' ||
          fileId === '3a9ba964-cd95-4f2d-ba87-28a18b9a06db.svg',
      };
    });
    const trueFirst = (a: ICatalogItem, b: ICatalogItem) => Number(b.isLogo) - Number(a.isLogo);
    return catalog.sort(trueFirst);
  }, [getDesignerPatternsQuery.data, blobStorageUri]);

  const onClickPreviewPattern = useCallback((url: string) => {
    setPreviewUrl(url);
  }, []);

  return (
    <SidePanelContentWrapper>
      <h2>{t('ElementsHeader')}</h2>

      <DragElement
        onDragStart={() => {
          dispatch(dragStart({ type: DragContextType.DesignerPlain }));
        }}
        onClick={() => {
          dispatch(addPlainLayer({ x: 100, y: 100 }));
        }}
        draggable
        role="button">
        {t('Box')}
      </DragElement>

      <StyledLabel>
        <span className="filter-label">{t('Filter')}</span>
        <QueryLoader query={getTagsQuery}>
          <select onChange={onChangeImageFilter} value={filter}>
            <option value={''}>{t('All')}</option>
            {entries.map((entry) => (
              <option key={entry.id} value={entry.id}>
                {entry.description!.text}
              </option>
            ))}
          </select>
        </QueryLoader>
      </StyledLabel>

      <QueryLoader query={getDesignerPatternsQuery}>
        <TileList className="patterns">
          {catalog.map((entry) => (
            <li key={entry.id}>
              <img
                src={entry.thumbnailUrl}
                alt=""
                onDragStart={() => {
                  dispatch(
                    dragStart({
                      type: DragContextType.DesignerImage,
                      fileId: entry.fileId,
                      source: DesignerImageSource.Pattern,
                    }),
                  );
                }}
                onClick={() => onClickPreviewPattern(entry.imageUrl)}
              />
            </li>
          ))}
        </TileList>
      </QueryLoader>
      {!!previewUrl && (
        <ImagePreview imageSrc={previewUrl} onRequestClose={() => setPreviewUrl(null)} />
      )}
    </SidePanelContentWrapper>
  );
};

const DragElement = styled.div`
  width: max-content;
  padding: 1rem;
  border: 2px dashed var(--color-cta);
  border-radius: 3px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-cta-light);
  }
`;

const TileList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(0.97);
  }

  li {
    width: 4.5rem;
    height: 4.5rem;
    margin: 0.5rem;

    &:hover {
      outline: 2px solid #8ce699;
      cursor: pointer;
    }
  }
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  span.filter-label {
    display: block;
    width: 100%;
    font-size: initial;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
  }

  select {
    font-size: initial;
  }
`;
