import { ComponentType } from 'react';
import { EditorStepKey } from './EditorStepKey';
import { CategoryPage } from './category/CategoryPage';
import { ProjectDataPage } from './projectData/ProjectDataPage';
import { FormatPage } from './format/FormatPage';
import { IsbnImprintPage } from './isbnImprint/IsbnImprintPage';
import { ContributorsPage } from './contributors/ContributorsPage';
import { CoverDesignerPage } from '../designer/CoverDesignerPage';

const NullComponent = () => null;

export interface IEditorStepPageProps {
  /**
   * Id of the project being edited. Guaranteed to be set
   */
  projectId: string;
}

/**
 * Maps project editor steps to their respective page component types
 */
export const PageByStepKeyMapping: Record<EditorStepKey, ComponentType<IEditorStepPageProps>> = {
  [EditorStepKey.Category]: CategoryPage,
  [EditorStepKey.ProjectData]: ProjectDataPage,
  [EditorStepKey.Format]: FormatPage,
  [EditorStepKey.Contributors]: ContributorsPage,
  [EditorStepKey.Isbn]: IsbnImprintPage,
  [EditorStepKey.PrepareContentFile]: NullComponent,
  [EditorStepKey.UploadContentFile]: NullComponent,
  [EditorStepKey.ViewContentFile]: NullComponent,
  [EditorStepKey.ColorPages]: NullComponent,
  [EditorStepKey.Material]: NullComponent,
  [EditorStepKey.CoverTool]: NullComponent,
  [EditorStepKey.CoverDesigner]: CoverDesignerPage,
  [EditorStepKey.PrepareCoverFile]: NullComponent,
  [EditorStepKey.UploadCoverFile]: NullComponent,
  [EditorStepKey.ViewCoverFile]: NullComponent,
  [EditorStepKey.Provision]: NullComponent,
  [EditorStepKey.ProvisionContribution]: NullComponent,
  [EditorStepKey.SearchAndFind]: NullComponent,
  [EditorStepKey.BookInterview]: NullComponent,
  [EditorStepKey.AuthorProfile]: NullComponent,
  [EditorStepKey.ShopView]: NullComponent,
};

export const defaultPage = NullComponent;

/**
 * Returns the page component designated for a given step or {@link defaultPage} if no page is found.
 */
export const getStepComponent = (step: EditorStepKey): ComponentType<IEditorStepPageProps> => {
  return PageByStepKeyMapping[step] || defaultPage;
};
