import * as Sentry from '@sentry/react';
import { TemplateModal } from './TemplateModal';
import { useTemplateSlice } from './templateSelectors';
import { Fragment, useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setApplyId, setFilter, toggleTemplateModal } from './templateSlice';
import { IDesignTemplateSearchFilter } from './IDesignerTemplateState';
import { Confirm } from '../../modal/Confirm';
import { applyTemplate as applyTemplateAction } from './applyTemplate';
import { noop } from '../../lib/noop';
import { useTranslation } from 'react-i18next';
import { clearProject } from '../project/projectActions';
import toast from 'react-hot-toast';
import { applyTemplateWithConfirm } from './applyTemplateWithConfirm';

export const TemplateController = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const state = useTemplateSlice();

  const applyTemplate = useCallback(
    (id: string) => {
      const applyTask = dispatch(applyTemplateAction(id));
      return toast
        .promise(applyTask, {
          loading: t('ApplyTemplateLoading'),
          success: t('ApplyTemplateSuccess'),
          error: t('ApplyTemplateError'),
        })
        .then(() => {
          dispatch(setApplyId({ id: null, confirm: false }));
          dispatch(toggleTemplateModal(false));
        })
        .catch((e) => {
          Sentry.captureEvent({
            message: 'Template could not be applied',
            extra: {
              error: e,
              TemplateId: id,
            },
            level: 'error',
          });
        });
    },
    [dispatch, t],
  );

  useEffect(() => {
    // apply template without confirmation
    if (state.applyId && !state.confirm) {
      applyTemplate(state.applyId).catch(noop);
    }
  }, [applyTemplate, state.applyId, state.confirm]);

  const onConfirmTemplate = useCallback((): Promise<void> => {
    if (!state.applyId) {
      return Promise.resolve();
    }
    return applyTemplate(state.applyId);
  }, [applyTemplate, state.applyId]);

  const onCancelConfirmTemplate = useCallback(() => {
    dispatch(setApplyId({ id: null, confirm: false }));
  }, [dispatch]);

  const onRequestCloseTemplateModal = useCallback(() => {
    dispatch(toggleTemplateModal(false));
  }, [dispatch]);

  const onSelectTemplate = useCallback(
    (templateId: string) => {
      dispatch(applyTemplateWithConfirm(templateId));
    },
    [dispatch],
  );

  const onRequestSetFilter = useCallback(
    (filter: IDesignTemplateSearchFilter) => {
      dispatch(setFilter(filter));
    },
    [dispatch],
  );

  const onRequestResetTemplate = useCallback(() => {
    dispatch(clearProject());
  }, [dispatch]);

  return (
    <Fragment>
      <Confirm
        isOpen={!!state.applyId && state.confirm}
        onYes={onConfirmTemplate}
        onNo={onCancelConfirmTemplate}>
        {t('SwitchTemplateConfirmation')}
      </Confirm>
      {state.isTemplateModalOpen && (
        <TemplateModal
          onRequestClose={onRequestCloseTemplateModal}
          onSelectTemplate={onSelectTemplate}
          filter={state.filter}
          setFilter={onRequestSetFilter}
          onResetTemplate={onRequestResetTemplate}
        />
      )}
    </Fragment>
  );
};
