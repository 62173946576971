import { Command, Plugin } from "@ckeditor/ckeditor5-core";
import CharacterSpacingEditing from "./CharacterSpacingEditing";
import {
  renderDowncastElement,
  renderUpcastAttribute,
} from "@ckeditor/ckeditor5-font/src/utils";
import CharacterSpacingCommand from "./CharacterSpacingCommand";

export const LETTER_SPACING = "letter-spacing";

class CharacterSpacingUI extends Plugin {
  static get pluginName() {
    return "CharacterSpacingUI";
  }

  init() {
    const editor = this.editor;
    const {
      ui: { componentFactory },
      t,
    } = editor;

    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "span",
        styles: {
          "letter-spacing": /[\s\S]+/,
        },
      },
      model: {
        key: LETTER_SPACING,
        value: renderUpcastAttribute("letter-spacing"),
      },
    });

    editor.conversion.for("downcast").attributeToElement({
      model: LETTER_SPACING,
      view: renderDowncastElement("letter-spacing"),
    });

    // Allow the font color attribute on text nodes.
    editor.model.schema.extend("$text", { allowAttributes: LETTER_SPACING });

    editor.model.schema.setAttributeProperties(LETTER_SPACING, {
      isFormatting: true,
      copyOnEnter: true,
    });

    componentFactory.add("letter-spacing", (locale) => {
      const inputView = new CharacterSpacingEditing(locale);
      inputView.on("change:value", (v) => {
        const value = Number(v.source.value) / 100;
        const letterSpacingValue = value.toFixed(2) + "rem";
        editor.execute(LETTER_SPACING, { value: letterSpacingValue });
      });

      return inputView;
    });

    editor.commands.add(LETTER_SPACING, new CharacterSpacingCommand(editor));
  }
}

export default CharacterSpacingUI;
