import { IHSV, IRGB, IRGBA } from './Color';

/**
 * Converts an RGB(A) value to HSV. Note that the alpha channel is not considered.
 */
export function rgbToHsv({ r, g, b }: IRGB | IRGBA): IHSV {
  const cMax = Math.max(r, g, b);
  const cMin = Math.min(r, g, b);
  const delta = cMax - cMin;

  const s = cMax ? delta / cMax : 0;
  const v = cMax;

  let h = 0;

  if (cMax === cMin) {
    return { h, s, v };
  }
  switch (cMax) {
    case r:
      h = (g - b) / delta + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / delta + 2;
      break;
    case b:
      h = (r - g) / delta + 4;
      break;
  }
  h /= 6;
  return { h, s, v };
}
