import styled from 'styled-components/macro';
import { MenuRightWrapper } from '../designer/menu/MenuRightWrapper';

interface IMenuButtonDesigner {
  isActive?: boolean;
  className?: string;
}

export const MenuButtonDesigner = styled.button.attrs<IMenuButtonDesigner>((props) => ({
  style: {
    backgroundColor: props.isActive ? 'var(--color-cta-light)' : 'white',
  },
}))<IMenuButtonDesigner>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: none;

  font-size: 0.5rem;
  line-height: normal;
  font-family: 'Barlow', sans-serif;
  font-weight: normal;
  transition: background-color 0.2s;
  cursor: pointer;

  width: max-content;
  height: 60px;

  &:hover {
    background-color: var(--color-cta-light) !important;
  }

  & > .tredition-icon {
    pointer-events: none;
    font-size: 1rem;
  }

  @media screen and (min-width: 1600px) {
    font-size: 0.7rem;

    & > .tredition-icon {
      font-size: 1.2rem;
    }
  }
`;
