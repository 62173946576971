import { IPoint } from '../../@types/IPoint';
import { degToRad } from './degToRad';

/**
 * Performs a CCW rotation of a point around a given pivot point and an angle in degrees
 *
 * Returns the rotated point and does not mutate the given input point.
 */
export function rotatePointDeg(point: IPoint, pivot: IPoint, angle: number): IPoint {
  const rad = degToRad(-angle);
  const sin = Math.sin(rad);
  const cos = Math.cos(rad);
  return rotatePointDegFast(point, pivot, sin, cos);
}

/**
 * Like {@link rotatePointDeg} but instead uses precomputed sine and cosine values. Useful when
 * rotating multiple points by the same angle.
 */
export function rotatePointDegFast(point: IPoint, pivot: IPoint, sin: number, cos: number): IPoint {
  const p: IPoint = { x: point.x, y: point.y };
  // translate point back to origin
  p.x -= pivot.x;
  p.y -= pivot.y;
  // rotate point
  const x = p.x * cos - p.y * sin;
  const y = p.x * sin + p.y * cos;
  // translate point back
  p.x = x + pivot.x;
  p.y = y + pivot.y;
  return p;
}
