import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ActionModal, IActionModalProps } from './ActionModal';

export interface IConfirmProps
  extends Omit<IActionModalProps, 'onOk' | 'onCancel' | 'okButtonLabel' | 'cancelButtonLabel'> {
  /**
   * Invoked when the yes button is clicked. If the onYes invocation returned a Promise
   * the yes button will be disabled until the promise is resolved.
   */
  onYes: () => unknown;

  /**
   * Invoked when the no button is clicked
   */
  onNo: () => unknown;
}

/**
 * Renders a 'confirm' modal with a 'yes', a 'no' button and the given content as children which
 * is a shortcut to rendering an `ActionModal` while overriding the onOk and onCancel label manually.
 * Pressing no should cancel the impending operation.
 */
export const Confirm: FC<PropsWithChildren<IConfirmProps>> = (props) => {
  const { t } = useTranslation();
  const { onYes, onNo, children, ...actionModalProps } = props;

  return (
    <ActionModal
      title={t('Confirmation')}
      {...actionModalProps}
      onOk={onYes}
      onCancel={onNo}
      okButtonLabel={t('Yes')}
      cancelButtonLabel={t('No')}>
      {children}
    </ActionModal>
  );
};

const Footer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--color-border);
  gap: 0.5rem;
  justify-content: flex-end;

  button {
    min-width: 150px;
  }
`;
