import { ITextTemplateObject, TemplateObjectJustificationType } from '../IDesignProduct';

const TextAlignNumberToValue = {
  0: 'top',
  1: 'center',
  2: 'bottom',
};

const TextJustificationToValue = {
  1: 'left',
  2: 'center',
  3: 'right',
  4: 'block',
};

export const convertTextLayerAttributesToHtml = (o: ITextTemplateObject, dynamicText: string) => {
  const wrapIf = (
    wrapper: string,
    content: string,
    ifTrue: boolean,
    styleAttr?: string,
    className?: string,
  ) => {
    if (!ifTrue) return content;

    const style = (() => {
      if (!styleAttr) return '';
      return ` style="${styleAttr}"`;
    })();

    const classNameString = className ? ` class="${className}"` : '';

    return `<${wrapper}${classNameString}${style}>${content}</${wrapper}>`;
  };

  const escapeFontFamily = (fontName?: string) => {
    if (!fontName) return undefined;
    if (fontName.includes(' ')) {
      return `'${o.fontName}'`;
    }
    return o.fontName;
  };

  const spanStyle = [
    ['background-color', o.textBackgroundColor.color],
    ['color', o.textColor.color],
    ['font-family', escapeFontFamily(o.fontName)],
    ['font-size', o.fontSize ? `${o.fontSize}px` : undefined],
    ['letter-spacing', o.charSpacing !== 0 ? `${o.charSpacing / 100}rem` : undefined],
  ];

  const styleAsText = spanStyle
    .filter(([k, v]) => v != null)
    .map(([k, v]) => [k, v].join(':'))
    .join(';');

  dynamicText = wrapIf('u', dynamicText, o.underline);
  dynamicText = wrapIf('strong', dynamicText, o.bold);
  dynamicText = wrapIf('span', dynamicText, o.uppercase, 'text-transform:uppercase;');
  dynamicText = wrapIf('i', dynamicText, o.italic);

  const span = `<span style="${styleAsText}">${dynamicText}</span>`;

  let pStyleString = 'style="';
  if (o.justification != null && o.justification != TemplateObjectJustificationType.None) {
    pStyleString += `text-align:${TextJustificationToValue[o.justification]};`;
  }
  if (o.lineHeight != null) {
    pStyleString += `line-height:${(o.lineHeight / 100) * 1.2};`;
  }
  pStyleString += '"';

  dynamicText = `<p ${pStyleString}>${span}</p>`;

  dynamicText = wrapIf(
    'div',
    dynamicText,
    !!o.alignment,
    undefined,
    `ck-vertical-alignment ${TextAlignNumberToValue[o.alignment]}`,
  );

  return dynamicText;
};
