import React, { FC } from 'react';
import styled from 'styled-components/macro';

export interface ISliderProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  onChange?: (value: number) => unknown;
}

export const Slider: FC<ISliderProps> = (props) => {
  return (
    <StyledSlider
      {...props}
      onChange={(e) => {
        props.onChange && props.onChange(Number.parseFloat(e.target.value));
      }}
      type="range"
    />
  );
};

const StyledSlider = styled.input`
  accent-color: var(--color-cta);
`;
