import React, { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAppDispatch } from '../../redux/hooks';
import { deleteLayers, deselectLayers, moveLayers, selectAllLayers } from '../layer/liveSheetSlice';
import { addSelectionToClipboard } from '../clipboard/addSelectionToClipboard';
import { pasteLayersFromClipboard } from '../clipboard/pasteLayersFromClipboard';
import { saveProject } from '../project/saveProject';
import { undo } from '../history/undo';
import { redo } from '../history/redo';
import { zoomRelative } from '../view/zoomRelative';
import { setKeyboardInput } from './inputSlice';
import { noop } from '../../lib/noop';
import { isSavePointDifferent } from '../history/historySelectors';

export interface IDesignerInputProps {
  isReadonly: boolean;
}

export const DesignerInput: React.FC<IDesignerInputProps> = ({ isReadonly }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    function onKeyEvent(e: KeyboardEvent) {
      dispatch(setKeyboardInput({ shift: e.shiftKey, control: e.ctrlKey }));
    }

    document.addEventListener('keydown', onKeyEvent);
    document.addEventListener('keyup', onKeyEvent);
    return () => {
      document.removeEventListener('keydown', onKeyEvent);
      document.removeEventListener('keyup', onKeyEvent);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isReadonly) {
      return;
    }
    function onUnload(e: BeforeUnloadEvent) {
      const prompt = dispatch((_, getState) => isSavePointDifferent(getState()));
      if (prompt) {
        e.preventDefault(); // firefox only (HTML spec compliant)
        return (e.returnValue = ' '); // all browsers
      }
    }
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [dispatch, isReadonly]);

  // NOTE: please split hotkeys modifying the designer's content from the rest so we can cleanly
  //       disable them with `isReadonly`

  useHotkeys(
    'ctrl+a,ctrl+d,ctrl+c,ctrl+v,del,ctrl+s,ctrl+z,ctrl+y,cmd+a,cmd+d,cmd+c,cmd+v,cmd+s,cmd+z,cmd+y',
    (e, hotkeyEvent) => {
      e.preventDefault();

      if (isReadonly) {
        return;
      }

      switch (hotkeyEvent.key) {
        case 'ctrl+c':
        case 'cmd+c':
          dispatch(addSelectionToClipboard());
          return;
        case 'ctrl+v':
        case 'cmd+v':
          dispatch(pasteLayersFromClipboard());
          return;
        case 'ctrl+a':
        case 'cmd+a':
          dispatch(selectAllLayers());
          return;
        case 'ctrl+d':
        case 'cmd+d':
          dispatch(deselectLayers());
          return;
        case 'ctrl+s':
        case 'cmd+s':
          dispatch(saveProject()).catch(noop);
          return;
        case 'ctrl+z':
        case 'cmd+z':
          dispatch(undo());
          return;
        case 'ctrl+y':
        case 'cmd+y':
          dispatch(redo());
          return;
        case 'del':
          dispatch(deleteLayers({ filter: { selected: true } }));
          return;
      }
    },
    [dispatch, isReadonly],
  );

  // zooming requires an extrawurst
  useEffect(() => {
    if (!document) {
      return;
    }

    function onWheel(e: WheelEvent) {
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault();
        dispatch(zoomRelative(0.1 * -Math.sign(e.deltaY)));
      }
    }

    document.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      document.removeEventListener('wheel', onWheel);
    };
  }, [dispatch]);

  useHotkeys(
    '*',
    (e /* , hotkeyEvent*/) => {
      const velocity = e.shiftKey ? 10 : 1;

      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          dispatch(moveLayers({ x: 0, y: -1 * velocity, isRelative: true }));
          break;
        case 'ArrowLeft':
          e.preventDefault();
          dispatch(moveLayers({ x: -1 * velocity, y: 0, isRelative: true }));
          break;
        case 'ArrowDown':
          e.preventDefault();
          dispatch(moveLayers({ x: 0, y: 1 * velocity, isRelative: true }));
          break;
        case 'ArrowRight':
          e.preventDefault();
          dispatch(moveLayers({ x: 1 * velocity, y: 0, isRelative: true }));
          break;
        default:
      }
    },
    [dispatch],
  );
  return null;
};
