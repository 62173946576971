import { CSSProperties, FC, PropsWithChildren } from 'react';
import { ILayer } from './LayersState';
import './Layer.css';
import classNames from 'classnames';
import { px } from '../../lib/px';
import { getLayerTransformCss } from './getLayerTransformCss';
import { getLayerDomId } from './getLayerDomId';

export interface ILayerProps {
  layer: ILayer;

  /**
   * Additional class names for this layer
   */
  className?: string;

  /**
   * Additional style properties for this layer
   */
  style?: CSSProperties;
}

export const Layer: FC<PropsWithChildren<ILayerProps>> = ({
  children,
  layer,
  className,
  style,
}): JSX.Element => {
  return (
    <article
      id={getLayerDomId(layer.id)}
      className={classNames(
        'layer',
        {
          error: layer.error,
        },
        className,
      )}
      style={{
        position: 'absolute',
        overflow: 'hidden !important',
        display: 'inline-block',
        left: px(layer.display.x),
        top: px(layer.display.y),
        width: px(layer.display.width),
        height: px(layer.display.height),
        transform: getLayerTransformCss(layer.display.rotation),
        ...style,
      }}>
      {children}
    </article>
  );
};
