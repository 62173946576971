import { ICSSEnumeration, ICSSObject } from './CSSObject';
import {
  ITableOfContentForm,
  LiningOptionEnumeration,
  TableOfContentFormEnumeration,
} from './TableOfContentForm';
import { HeadlineFormEnumeration, IHeadlineForm } from './HeadlineForm';
import { BodyTextFormEnumeration, IBodyTextForm } from './BodyTextForm';
import { FooterFormEnumeration, IFooterForm } from './FooterForm';
import { IPageMarginsForm, PageMarginsFormEnumeration } from './PageMarginsForm';
import { FontsEnumeration, TextAlignEnumeration } from './Formatting';
import { PageBreakEnumeration } from './PageBreak';
import { HeaderFormEnumeration, IHeaderForm } from './HeaderForm';
import { ViewEnumeration } from './View';
import { ContentEnumeration } from './Content';

const tableOfContent: ITableOfContentForm = {
  [TableOfContentFormEnumeration.TOC]: true,
  [TableOfContentFormEnumeration.LINING]: LiningOptionEnumeration.DOTTED,
  [TableOfContentFormEnumeration.HEADLINE]: 'Inhaltsverzeichnis',
  [TableOfContentFormEnumeration.FONT_FAMILY_HEADLINE]: FontsEnumeration.TIMES_NEW_ROMAN,
  [TableOfContentFormEnumeration.FONT_SIZE_HEADLINE]: 18,
  [TableOfContentFormEnumeration.BOLD_HEADLINE]: false,
  [TableOfContentFormEnumeration.ITALIC_HEADLINE]: false,
  [TableOfContentFormEnumeration.UNDERLINED_HEADLINE]: false,
  [TableOfContentFormEnumeration.TEXT_ALIGN_HEADLINE]: TextAlignEnumeration.LEFT,
  [TableOfContentFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [TableOfContentFormEnumeration.FONT_SIZE]: 14,
  [TableOfContentFormEnumeration.BOLD]: false,
  [TableOfContentFormEnumeration.ITALIC]: false,
  [TableOfContentFormEnumeration.UNDERLINED]: false,
  [TableOfContentFormEnumeration.TEXT_ALIGN]: TextAlignEnumeration.LEFT,
};

const headline: IHeadlineForm = {
  [HeadlineFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [HeadlineFormEnumeration.FONT_SIZE]: 18,
  [HeadlineFormEnumeration.BOLD]: true,
  [HeadlineFormEnumeration.ITALIC]: false,
  [HeadlineFormEnumeration.UNDERLINED]: false,
  [HeadlineFormEnumeration.TEXT_ALIGN]: TextAlignEnumeration.CENTER,
  [HeadlineFormEnumeration.SPACING_AFTER]: 2.8,
  [HeadlineFormEnumeration.INDENTION]: 0,
  [HeadlineFormEnumeration.PAGE_BREAK]: PageBreakEnumeration.NEXT_PAGE,
  [HeadlineFormEnumeration.SHOW_IN_TOC]: true,
  [HeadlineFormEnumeration.CONTAIN_HEADLINES]: false,
  [HeadlineFormEnumeration.HEADLINE_CHARS]: 50,
};

const headline2: IHeadlineForm = {
  [HeadlineFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [HeadlineFormEnumeration.FONT_SIZE]: 14,
  [HeadlineFormEnumeration.BOLD]: true,
  [HeadlineFormEnumeration.ITALIC]: false,
  [HeadlineFormEnumeration.UNDERLINED]: false,
  [HeadlineFormEnumeration.TEXT_ALIGN]: TextAlignEnumeration.LEFT,
  [HeadlineFormEnumeration.SPACING_AFTER]: 2.8,
  [HeadlineFormEnumeration.INDENTION]: 0,
  [HeadlineFormEnumeration.PAGE_BREAK]: PageBreakEnumeration.NO_PAGE_BREAK,
  [HeadlineFormEnumeration.SHOW_IN_TOC]: true,
  [HeadlineFormEnumeration.CONTAIN_HEADLINES]: false,
  [HeadlineFormEnumeration.HEADLINE_CHARS]: 50,
};

const bodyText: IBodyTextForm = {
  [BodyTextFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [BodyTextFormEnumeration.FONT_SIZE]: 14,
  [BodyTextFormEnumeration.BOLD]: false,
  [BodyTextFormEnumeration.ITALIC]: false,
  [BodyTextFormEnumeration.UNDERLINED]: false,
  [BodyTextFormEnumeration.TEXT_ALIGN]: TextAlignEnumeration.BLOCK,
  [BodyTextFormEnumeration.SPACING_AFTER]: 3,
  [BodyTextFormEnumeration.LINE_HEIGHT]: 5.3,
  [BodyTextFormEnumeration.FIRST_LINE_INDENTION]: 0,
};

const header: IHeaderForm = {
  [HeaderFormEnumeration.HEADER]: true,
  [HeaderFormEnumeration.VIEW]: ViewEnumeration.CENTERED,
  [HeaderFormEnumeration.SEPARATOR]: true,
  [HeaderFormEnumeration.DISTANCE_TEXT]: 5,
  [HeaderFormEnumeration.DISTANCE_HEADER]: 5,
  [HeaderFormEnumeration.HEADER_CONTENT]: ContentEnumeration.CHAPTER,
  [HeaderFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [HeaderFormEnumeration.FONT_SIZE]: 9,
  [HeaderFormEnumeration.BOLD]: false,
  [HeaderFormEnumeration.ITALIC]: false,
  [HeaderFormEnumeration.UNDERLINED]: false,
  [HeaderFormEnumeration.HEADER_CONTENT_INNER]: ContentEnumeration.NO_CONTENT,
  [HeaderFormEnumeration.FONT_FAMILY_INNER]: FontsEnumeration.TIMES_NEW_ROMAN,
  [HeaderFormEnumeration.FONT_SIZE_INNER]: 9,
  [HeaderFormEnumeration.BOLD_INNER]: false,
  [HeaderFormEnumeration.ITALIC_INNER]: false,
  [HeaderFormEnumeration.UNDERLINED_INNER]: false,
  [HeaderFormEnumeration.HEADER_CONTENT_OUTER]: ContentEnumeration.NO_CONTENT,
  [HeaderFormEnumeration.FONT_FAMILY_OUTER]: FontsEnumeration.TIMES_NEW_ROMAN,
  [HeaderFormEnumeration.FONT_SIZE_OUTER]: 9,
  [HeaderFormEnumeration.BOLD_OUTER]: false,
  [HeaderFormEnumeration.ITALIC_OUTER]: false,
  [HeaderFormEnumeration.UNDERLINED_OUTER]: false,
  [HeaderFormEnumeration.FREE_TEXT]: '',
  [HeaderFormEnumeration.FREE_TEXT_INNER]: '',
  [HeaderFormEnumeration.FREE_TEXT_OUTER]: '',
};

const footer: IFooterForm = {
  [FooterFormEnumeration.FOOTER]: true,
  [FooterFormEnumeration.VIEW]: ViewEnumeration.CENTERED,
  [FooterFormEnumeration.SEPARATOR]: false,
  [FooterFormEnumeration.DISTANCE_TEXT]: 5,
  [FooterFormEnumeration.DISTANCE_FOOTER]: 5,
  [FooterFormEnumeration.FOOTER_CONTENT]: ContentEnumeration.NO_CONTENT,
  [FooterFormEnumeration.FONT_FAMILY]: FontsEnumeration.TIMES_NEW_ROMAN,
  [FooterFormEnumeration.FONT_SIZE]: 9,
  [FooterFormEnumeration.BOLD]: false,
  [FooterFormEnumeration.ITALIC]: false,
  [FooterFormEnumeration.UNDERLINED]: false,
  [FooterFormEnumeration.FOOTER_CONTENT_INNER]: ContentEnumeration.PAGE_NUMBER,
  [FooterFormEnumeration.FONT_FAMILY_INNER]: FontsEnumeration.TIMES_NEW_ROMAN,
  [FooterFormEnumeration.FONT_SIZE_INNER]: 9,
  [FooterFormEnumeration.BOLD_INNER]: false,
  [FooterFormEnumeration.ITALIC_INNER]: false,
  [FooterFormEnumeration.UNDERLINED_INNER]: false,
  [FooterFormEnumeration.FOOTER_CONTENT_OUTER]: ContentEnumeration.FREE_TEXT,
  [FooterFormEnumeration.FONT_FAMILY_OUTER]: FontsEnumeration.TIMES_NEW_ROMAN,
  [FooterFormEnumeration.FONT_SIZE_OUTER]: 9,
  [FooterFormEnumeration.BOLD_OUTER]: false,
  [FooterFormEnumeration.ITALIC_OUTER]: false,
  [FooterFormEnumeration.UNDERLINED_OUTER]: false,
  [FooterFormEnumeration.FREE_TEXT]: '',
  [FooterFormEnumeration.FREE_TEXT_INNER]: '',
  [FooterFormEnumeration.FREE_TEXT_OUTER]: '',
};

const pageMargins: IPageMarginsForm = {
  [PageMarginsFormEnumeration.DISTANCE_TOP]: 15,
  [PageMarginsFormEnumeration.DISTANCE_BOTTOM]: 15,
  [PageMarginsFormEnumeration.DISTANCE_INNER]: 20,
  [PageMarginsFormEnumeration.DISTANCE_OUTER]: 17,
};

export const formDefaultValues: ICSSObject = {
  [ICSSEnumeration.TABLE_OF_CONTENT]: tableOfContent,
  [ICSSEnumeration.HEADLINE]: headline,
  [ICSSEnumeration.HEADLINE2]: headline2,
  [ICSSEnumeration.BODY_TEXT]: bodyText,
  [ICSSEnumeration.HEADER]: header,
  [ICSSEnumeration.FOOTER]: footer,
  [ICSSEnumeration.PAGE_MARGINS]: pageMargins,
};
