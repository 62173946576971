import { useField } from 'formik';
import { RadioGroup, IRadioGroupProps, RadioValueType } from './RadioGroup';
import { ErrorWrapper, IErrorWrapper } from '../errorWrapper/ErrorWrapper';
import { FormError } from '../formError/FormError';

export interface IRadioGroupFormikProps<VAL extends RadioValueType>
  extends Omit<IRadioGroupProps<VAL>, 'value'>,
    IErrorWrapper {}

export const RadioGroupFormik = <VAL extends RadioValueType>({
  name,
  className,
  ...rest
}: IRadioGroupFormikProps<VAL>) => {
  const [field, meta, helper] = useField(name);
  const { value, error = '', touched } = meta;
  const { setTouched, setValue } = helper;
  const hasError = !!error && touched;
  return (
    <ErrorWrapper data-type="radioGroup" className={className}>
      <RadioGroup
        {...rest}
        name={name}
        value={value}
        onChange={(val) => {
          setValue(val);
          setTouched(true);
          rest.onChange && rest.onChange(val);
        }}
      />
      {hasError && <FormError error={error} />}
    </ErrorWrapper>
  );
};
