import { AppThunk } from '../../redux/store';
import { getActivePalette, getActiveProductId, getProductById } from './productSelectors';
import { setPaletteInternal } from './projectActions';
import { ITreditionPalette } from '../palette/TreditionPalette';

export const setPalette =
  (newPalette: ITreditionPalette | null): AppThunk =>
  (dispatch, getState) => {
    const currentState = getState();
    const currentPalette = getActivePalette(currentState);

    const productId = getActiveProductId(currentState);

    if (!productId) {
      return;
    }

    dispatch(
      setPaletteInternal({
        currentPalette,
        palette: newPalette,
        productId,
      }),
    );
  };
