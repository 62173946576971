import React, { CSSProperties, useEffect, useState } from 'react';

export interface IVisuallyHidden {
  children: React.ReactNode;
}

const hiddenStyles = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
} as CSSProperties;

const textStyle = {
  position: 'absolute',
  top: 'calc(var(--spacing-standard) * -1)',
  fontSize: 'var(--font-size-small)',
  fontWeight: '400',
} as CSSProperties;

const VisuallyHidden = ({ children, ...delegated }: IVisuallyHidden): JSX.Element => {
  const [forceShow, setForceShow] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === 'Alt') {
          setForceShow(true);
        }
      };

      const handleKeyUp = (ev: KeyboardEvent) => {
        if (ev.key === 'Alt') {
          setForceShow(false);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, []);
  if (forceShow) {
    return <span style={textStyle}>{children}</span>;
  }

  return (
    <span style={hiddenStyles} {...delegated}>
      {children}
    </span>
  );
};
export default VisuallyHidden;
