import { FC, Fragment, useCallback, useState } from 'react';
import './ColorPicker.css';
import { HueSlider } from './HueSlider';
import { ColorPickerActionType, useColorPickerState } from './useColorPickerState';
import { SaturationValueArea } from './SaturationValueArea';
import { AlphaSlider } from './AlphaSlider';
import { ColorDisplay } from './ColorDisplay';
import { ColorInput } from './ColorInput';
import { ICMYK, IHSV, IRGBA } from '../../lib/Color';
import { SuggestedColors } from './SuggestedColors';
import { ITreditionPalette } from './TreditionPalette';
import { Palette } from './Palette';
import { IColor } from './IColor';
import styled from 'styled-components/macro';
import { MenuButton } from '../../uiComponents/MenuButton';
import { Button } from '../../uiComponents/Button';
import { useTranslation } from 'react-i18next';

export type OnPickFn = (color: IColor) => unknown;

export interface IColorPickerProps {
  /**
   * Invoked when the user picked a color. The color will be provided as RGBA
   */
  onPick: OnPickFn;

  /**
   * Invoked when the user cancels the color picking process.
   */
  onCancel: () => unknown;

  /**
   * An optional color to preselect
   */
  initialColor?: IColor | null;

  /**
   * An optional palette the user can pick a color from
   */
  palette?: ITreditionPalette | null;
}

export const ColorPicker: FC<IColorPickerProps> = ({ onPick, onCancel, initialColor, palette }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useColorPickerState({
    color: initialColor,
    palette: palette,
  });

  enum Tabs {
    NONE,
    COLOR_FIELDS,
    CUSTOM,
    INPUT,
  }

  const [activeTab, setActiveTab] = useState(Tabs.COLOR_FIELDS);

  const onChangeHue = useCallback(
    (hue: number) => {
      dispatch({ type: ColorPickerActionType.SetHue, hue });
    },
    [dispatch],
  );

  const onPickColor = useCallback(
    (saturation: number, value: number) => {
      dispatch({ type: ColorPickerActionType.PickColor, saturation, value });
    },
    [dispatch],
  );

  const onChangeAlpha = useCallback(
    (alpha: number) => {
      dispatch({ type: ColorPickerActionType.SetAlpha, alpha });
    },
    [dispatch],
  );

  const onChangeRgba = useCallback(
    (rgba: IRGBA) => {
      dispatch({ type: ColorPickerActionType.SetRgba, rgba });
    },
    [dispatch],
  );

  const onChangeCmyk = useCallback(
    (cmyk: ICMYK) => {
      dispatch({ type: ColorPickerActionType.SetCmyk, cmyk });
    },
    [dispatch],
  );

  const onChangeHsv = useCallback(
    (hsv: IHSV) => {
      dispatch({ type: ColorPickerActionType.SetHsv, hsv });
    },
    [dispatch],
  );

  const onChangeHex = useCallback(
    (hex: string) => {
      dispatch({ type: ColorPickerActionType.SetHex, hex });
    },
    [dispatch],
  );

  const onClickPaletteColor = useCallback(
    (color: IRGBA, index: number) => {
      dispatch({ type: ColorPickerActionType.SetFromPalette, paletteIndex: index });
    },
    [dispatch],
  );

  return (
    <Wrapper className="color-picker">
      <TabWrapper>
        <MenuButton
          isActive={activeTab === Tabs.COLOR_FIELDS}
          onClick={() => setActiveTab(Tabs.COLOR_FIELDS)}>
          {t('ColorFields')}
        </MenuButton>
        <MenuButton isActive={activeTab === Tabs.CUSTOM} onClick={() => setActiveTab(Tabs.CUSTOM)}>
          {t('ColorRange')}
        </MenuButton>
        <MenuButton isActive={activeTab === Tabs.INPUT} onClick={() => setActiveTab(Tabs.INPUT)}>
          {t('ColorInput')}
        </MenuButton>
      </TabWrapper>
      <TabContent isActive={activeTab === Tabs.COLOR_FIELDS}>
        {!!state.palette && (
          <Fragment>
            <Separator>
              <hr />
              <span>{t('ChooseColorFromPalette')}</span>
              <hr />
            </Separator>
            <Palette
              selectedIndex={state.paletteIndex}
              palette={state.palette}
              onClickColor={onClickPaletteColor}
            />
          </Fragment>
        )}
        <Separator>
          <hr />
          <span>{t('ColorSuggestions')}</span>
          <hr />
        </Separator>
        <SuggestedColors onSelectColor={onChangeRgba} />
      </TabContent>
      <TabContent isActive={activeTab === Tabs.CUSTOM}>
        <Separator>
          <hr />
          <span>{t('ChooseColorFromSpectrum')}</span>
          <hr />
        </Separator>
        <div className="pointer">
          <SaturationValueArea hsv={state.hsv} onPickColor={onPickColor} />
          <HueSlider onChangeHue={onChangeHue} hue={state.hsv.h} />
          <AlphaSlider onChangeAlpha={onChangeAlpha} rgba={state.rgba} />
        </div>
      </TabContent>
      <TabContent isActive={activeTab === Tabs.INPUT}>
        <Separator>
          <hr />
          <span>{t('ColorFromInput')}</span>
          <hr />
        </Separator>
        <ColorInput
          rgba={state.rgba}
          hsv={state.hsv}
          cmyk={state.cmyk}
          hex={state.hex}
          onChangeHex={onChangeHex}
          onChangeRgba={onChangeRgba}
          onChangeCmyk={onChangeCmyk}
          onChangeHsv={onChangeHsv}
        />
      </TabContent>
      <Footer>
        <ColorDisplay className="round" rgba={state.rgba} />
        <Button cancel onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button onClick={() => onPick({ rgba: state.rgba, paletteIndex: state.paletteIndex })}>
          {t('ColorPickerSubmit')}
        </Button>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-rows: 2rem 1fr 100px;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  padding: 0.5rem;
  height: 550px;
  width: 350px;
  border-radius: var(--border-radius);
`;

interface ITabContent {
  isActive?: boolean;
}

const TabContent = styled.div.attrs<ITabContent>((props) => ({
  style: {
    display: props.isActive ? undefined : 'none',
  },
}))<ITabContent>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 0.5rem;
`;

// const StyledColorDisplay = styled(ColorDisplay)`
//   border-radius: 50%;
//   width: 100px;
//   height: 100px;
//   margin-right: auto;
// `;

const Separator = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0 1rem;

  & > span {
    white-space: nowrap;
  }

  & > hr {
    width: 100%;
    overflow: hidden;
  }
`;
