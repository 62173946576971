import { IRGB, IRGBA } from './Color';
import { boolean } from 'yup';

/**
 * Converts the given RGB or RGBA value to a hex string having the format `RRGGBBAA`
 *
 * Always returns an 8 digit hex string unless `includeAlpha` is set to `false`.
 * If alpha is omitted an alpha value of 1 is assumed.
 *
 * OPTIMIZE
 */
export function rgbToHex(rgba: IRGB | IRGBA, includeHash = false, includeAlpha = true): string {
  const a = typeof (rgba as unknown as IRGBA).a === 'undefined' ? 1 : (rgba as unknown as IRGBA).a;
  const hexStr = [
    Math.round(rgba.r * 255)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase(),
    Math.round(rgba.g * 255)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase(),
    Math.round(rgba.b * 255)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase(),
    includeAlpha
      ? Math.round(a * 255)
          .toString(16)
          .padStart(2, '0')
          .toUpperCase()
      : null,
  ]
    .filter((v) => Boolean(v))
    .join('');
  return includeHash ? '#' + hexStr : hexStr;
}
