import styles from './BookFormatElement.module.css';
import { CheckBoxMood } from '../../../forms/checkBox/CheckBox';
import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { FormatProductTypeSchema, FormatSetting, IFormatPageValueType } from '../FormatPage.schema';
import { FormatSelection } from './FormatSelection';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../constants/ProductType';
import { CheckBoxFormik } from '../../../forms/checkBox/CheckBox.formik';
import { Spacer } from '../../../shared/spacer/Spacer';
import { EditorContext } from '../../EditorContext';

export interface IBookFormatElementProps {
  /**
   * The image source of the format
   */
  imgSrc: string;
  /**
   * The name of the format
   */
  name: string;

  /**
   * The type of the format
   */
  type: Exclude<ProductType, ProductType.None>;
}

export const getFormatProductTypeSchemaAttributeAsString = (
  type: ProductType,
  attribute: keyof FormatProductTypeSchema,
) => `${type}.${attribute}`;

export const BookFormatElement = ({ imgSrc, name, type }: IBookFormatElementProps) => {
  const { t } = useTranslation();
  const { values, setValues, setFieldValue } = useFormikContext<IFormatPageValueType>();
  const isEnabled = values[type].enabled;
  const isOutdated = values[type].outdated;

  const {
    state: { isReadonly: isReadonlyState, isBookUpdate },
  } = useContext(EditorContext);

  const isReadonly = isReadonlyState || isOutdated;
  const subElementsDisabled = !isEnabled || isReadonly;

  const resetCurrentFormat = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValues({
          ...values,
          [type]: {
            ...values[type],
            enabled: true,
            format: type === ProductType.Ebook ? FormatSetting.None : FormatSetting.Individual,
          },
        });
      } else {
        setValues({
          ...values,
          [type]: {
            ...values[type],
            enabled: false,
            // sizeInMM: MaxSizeOfMeasurements[KnownMeasurements.DinA4],
          },
        });
      }
    },
    [setValues, type, values],
  );

  const toggleActive = () => {
    const name = getFormatProductTypeSchemaAttributeAsString(type, 'enabled');
    setFieldValue(name, !values[type].enabled);
  };

  if (!values) return null;

  const getFormatDescription = (type: ProductType) => {
    switch (type) {
      case ProductType.Ebook:
        return t('EbookFormatDescription');
      case ProductType.HardCover:
        return t('HardcoverFormatDescription');
      case ProductType.SoftCover:
        return t('PaperbackFormatDescription');
      case ProductType.LargePrint:
        return t('LargePrintBookFormatDescription');
      default:
        return '';
    }
  };
  return (
    <article
      className={classNames(styles.wrapper, styles.row, {
        [styles.disabled]: subElementsDisabled,
        [styles.bookUpdate]: isBookUpdate,
      })}>
      <header className={styles.header}>
        <button
          disabled={isReadonly || isBookUpdate}
          className={styles.headerButton}
          onClick={toggleActive}
          type="button">
          <div className={styles.checkbox}>
            <CheckBoxFormik
              mood={CheckBoxMood.Secondary}
              labelHidden
              onChange={resetCurrentFormat}
              disabled={isReadonly || isBookUpdate}
              label={t('SelectBookType')}
              name={getFormatProductTypeSchemaAttributeAsString(type, 'enabled')}
            />
          </div>
          {name}
        </button>
      </header>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={imgSrc} alt={''} />
        </div>
        <Spacer size={16} />
        {isEnabled ? (
          type != ProductType.Ebook ? (
            <FormatSelection type={type} disabled={isReadonly} name={name} />
          ) : (
            <p
              style={{
                margin: 'auto 0',
                paddingBottom: 'var(--spacing-xlarge)',
                lineHeight: 'var(--line-height)',
              }}>
              {t('EbookFormatSelectedDescription')}
            </p>
          )
        ) : (
          <p
            style={{
              paddingBottom: 'var(--spacing-xlarge)',
              lineHeight: 'var(--line-height)',
            }}
            dangerouslySetInnerHTML={{ __html: getFormatDescription(type) }}
          />
        )}
      </div>
    </article>
  );
};
