import { IPoint } from '../../../@types/IPoint';

// OPTIMIZE: these are extremely inefficient. find a better way to translate global pointer move events to local element coordinates

export function getActiveSheetElement(): HTMLElement | null {
  return document.querySelector('.work-space.active .sheet');
}

export function getSheetOffset(): IPoint {
  // OPTIMIZE: extremely inefficient (used in pointer move events)
  return getElementOffset(getActiveSheetElement());
}

export function getActiveWorkSpaceOffset(): IPoint {
  return getElementOffset(document.querySelector('.work-space.active'));
}

export function getElementOffset(element: Element | null | undefined): IPoint {
  if (!element) {
    return { x: 0, y: 0 };
  }
  const rect = element.getBoundingClientRect();
  return { x: rect.x, y: rect.y };
}
