import { FC, useCallback, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useEnsuredProjectId } from '../project/projectSelectors';
import {
  IGetUploadedImagesListEntry,
  treditionApi,
  useDeleteDesignImageMutation,
  useGetUploadedDesignerImagesQuery,
} from '../../api/treditionApi';
import { QueryLoader } from '../../loader/QueryLoader';
import { DesignerImageSource, DragContextType, dragStart } from '../drag/dragSlice';
import { Uploader } from '../../upload/Uploader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ImagePreview } from '../imagePreview/ImagePreview';
import { toast } from 'react-hot-toast';
import { noop } from '../../lib/noop';
import { SidePanelContentWrapper } from './SidePanelContentWrapper';

export const UploadsPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useEnsuredProjectId();
  const [previewEntry, setPreviewEntry] = useState<IGetUploadedImagesListEntry | null>(null);
  const query = useGetUploadedDesignerImagesQuery(projectId);
  const [deleteMutation] = useDeleteDesignImageMutation();

  const onBeginUpload = useCallback(
    (file: File) => {
      return dispatch(
        treditionApi.endpoints.uploadDesignImage.initiate({ projectId, file }),
      ).unwrap();
    },
    [dispatch, projectId],
  );

  const onRequestDelete = useCallback((): Promise<void> => {
    if (!previewEntry) {
      return Promise.resolve();
    }
    const deleteTask = deleteMutation({ projectId, fileId: previewEntry.fileId }).unwrap();
    return toast
      .promise(deleteTask, {
        error: t('DeleteFileError'),
        loading: t('DeleteFileLoading'),
        success: t('DeleteFileSuccess'),
      })
      .then(() => {
        setPreviewEntry(null);
      })
      .catch(noop);
  }, [deleteMutation, projectId, previewEntry, t]);

  return (
    <SidePanelContentWrapper>
      <h2>{t('Uploads')}</h2>

      <Uploader onBeginUpload={onBeginUpload} />

      <QueryLoader query={query}>
        <h3>{t('UploadsHeader')}</h3>
        <ImageWrapper>
          {query.isSuccess &&
            query.data.map((entry) => (
              <img
                key={entry.fileId}
                src={entry.fileUrl}
                alt=""
                onClick={() => setPreviewEntry(entry)}
                onDragStart={() => {
                  dispatch(
                    dragStart({
                      type: DragContextType.DesignerImage,
                      fileId: entry.fileId,
                      source: DesignerImageSource.Upload,
                    }),
                  );
                }}
              />
            ))}
        </ImageWrapper>
      </QueryLoader>
      {!!previewEntry && (
        <ImagePreview
          imageSrc={previewEntry.fileUrl}
          onRequestClose={() => setPreviewEntry(null)}
          onRequestDelete={onRequestDelete}
        />
      )}
    </SidePanelContentWrapper>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7px;

  & > img {
    max-height: 110px;
    max-width: 100%;
    border-radius: var(--border-radius);
    box-shadow: 2px 2px 5px var(--box-shadow-color);
    cursor: pointer;
  }

  & > img:hover {
    outline: 5px solid var(--color-cta);
    outline-offset: -2px;
  }
`;
