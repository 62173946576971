import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICSSEnumeration, ICSSObject } from '../components/form_constant/CSSObject';
import { setBookContent, setFormHistoryEntry, setIsLoading, setStyle } from './previewerSlice';
import { updateStyle } from '../services/updateStyle';
import { getToc } from '../services/getToc';
import { IPreviewerState } from './PreviewerState';
import { TableOfContentFormEnumeration } from '../components/form_constant/TableOfContentForm';

/**
 * Sets the form values and save it to local storage
 */
export const setFormValuesThunk = createAsyncThunk(
  'formValues/setFormValues',
  async (newFormValues: ICSSObject, { dispatch, getState }) => {
    dispatch(setFormHistoryEntry({ formValues: newFormValues, timeStamp: Date.now() }));
    dispatch(setIsLoading({ isLoading: true }));
    const { previewer } = getState() as { previewer: IPreviewerState };
    const isTocHeadlineEqual =
      newFormValues[ICSSEnumeration.TABLE_OF_CONTENT][TableOfContentFormEnumeration.HEADLINE] ===
      previewer.formValues[ICSSEnumeration.TABLE_OF_CONTENT][
        TableOfContentFormEnumeration.HEADLINE
      ];
    if (!isTocHeadlineEqual) {
      const newToc = await getToc({
        bookContent: previewer.bookContent,
        formValues: newFormValues,
      });
      dispatch(setBookContent({ bookContent: previewer.bookContent, tocContent: newToc }));
    }
    const newStyle = await updateStyle(newFormValues);
    if (newStyle) {
      dispatch(setStyle({ style: newStyle }));
    }
    return { formValues: newFormValues };
  },
);
