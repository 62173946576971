import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { calculateRotationShift } from './calculateRotationShift';
import { IPoint } from '../../../@types/IPoint';
import { ILiveSheetState } from './ILiveSheetState';

export interface IScaleLayersActionPayload {
  /**
   * Scaling on the x-axis. May be omitted to only scale on y.
   */
  x?: number;

  /**
   * Scaling on the y-axis. May be omitted to only scale on x.
   */
  y?: number;

  /**
   * Specifies the operation of how x and y will interact with width and height. Note that at least one coordinate must be present.
   *
   * Examples:
   *  - Percentage based (relative) scaling - half size of layers: `{ operation: ScalingOperation.Multiply, x: 0.5, y: 0.5 }`
   *  - Absolute scaling - set layers to 20x20 : `{ operation: ScalingOperation.Set, x: 20, y: 20 }`
   *  - Relative shrink - shrink layers by 5x5: `{ operation: ScalingOperation.Add, x: -5, y: -5 }`
   */
  operation: ScalingOperation;

  /**
   * Whether a scaling should preserve the aspect ratio. Will check for presence of X first and then Y.
   * If both coordinates are supplied, the latter will be ignored.
   *
   * Default: false
   */
  keepAspectRatio?: boolean;
}

export enum ScalingOperation {
  /**
   * Provided values will be added to the current ones
   */
  Add,

  /**
   * Current values will be overwritten by the given ones
   */
  Set,

  /**
   * Current values will be multiplied by the given ones
   */
  Multiply,
}

export function scaleLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IScaleLayersActionPayload>,
) {
  const hasX = typeof action.payload.x === 'number';
  const hasY = typeof action.payload.y === 'number';
  if (!hasX && !hasY) {
    return;
  }
  state.layers.forEach((layer) => {
    if (!layer.isSelected || !layer.isScalable) {
      return;
    }
    const { operation, x, y, keepAspectRatio } = action.payload;
    const aspectRatio = layer.height === 0 ? 1 : layer.width / layer.height;

    switch (operation) {
      case ScalingOperation.Add: {
        if (hasX) {
          layer.width += x!;
        }
        if (hasY) {
          layer.height += y!;
        }
        break;
      }
      case ScalingOperation.Set:
        if (hasX) {
          layer.width = x!;
        }
        if (hasY) {
          layer.height = y!;
        }
        break;
      case ScalingOperation.Multiply:
        if (hasX) {
          layer.width *= x!;
        }
        if (hasY) {
          layer.height *= y!;
        }
        break;
      default:
        return;
    }

    if (keepAspectRatio) {
      if (hasX) {
        layer.height = Math.round(layer.width / aspectRatio);
      } else if (hasY) {
        layer.width = Math.round(layer.height * aspectRatio);
      }
    }
    if (layer.width <= 1) {
      layer.width = 1;
    }
    if (layer.height <= 1) {
      layer.height = 1;
    }

    if (layer.display.rotation) {
      const oldPos: IPoint = { x: layer.display.x, y: layer.display.y };
      const oldPivot: IPoint = {
        x: layer.display.x + layer.display.width / 2,
        y: layer.display.y + layer.display.height / 2,
      };
      const newPos = { x: layer.x, y: layer.y };
      const newPivot = { x: layer.x + layer.width / 2, y: layer.y + layer.height / 2 };
      const shift = calculateRotationShift(
        oldPos,
        newPos,
        oldPivot,
        newPivot,
        layer.transform.rotate,
      );
      layer.x -= shift.x;
      layer.y -= shift.y;
    }

    layer.display.x = layer.x;
    layer.display.y = layer.y;
    layer.display.width = layer.width;
    layer.display.height = layer.height;

    layer.updatedAt = Date.now();
  });
}
