import { FC, useState } from 'react';
import { IRGBA } from '../../lib/Color';
import { ColorDisplay } from './ColorDisplay';
import styled from 'styled-components/macro';
import { Icon } from '../../icons/Icon';

export interface IPalettePreviewProps {
  /**
   * Colors contained by the palette
   */
  colors: IRGBA[];

  /**
   * Invoked when one of the colors of the palette is clicked.
   *
   * Provides the index of the color and the current color value as RGBA.
   */
  onClickColor: (index: number, color: IRGBA) => unknown;

  /**
   * Invoked when the user dragged one color over another to swap them
   */
  onSwapColors: (a: number, b: number) => unknown;
}

export const PalettePreview: FC<IPalettePreviewProps> = ({
  colors,
  onClickColor,
  onSwapColors,
}) => {
  const [dragIndex, setDragIndex] = useState<number | null>(null);

  return (
    <Wrapper>
      {colors.map((color, index) => (
        <PaletteEntry
          key={index}
          className={index ? undefined : 'primary'}
          draggable
          onDragStart={() => setDragIndex(index)}
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => {
            if (dragIndex !== null) {
              onSwapColors(dragIndex, index);
              setDragIndex(null);
            }
          }}>
          <ColorDisplay rgba={color} onClick={(c) => onClickColor(index, c)} />
          <EditIcon>
            <Icon name="editieren-control" />
          </EditIcon>
        </PaletteEntry>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: end;
`;

const PaletteEntry = styled.div`
  display: grid;
  position: relative;
  cursor: move;

  &.primary {
    outline: 3px solid var(--color-cta);
    outline-offset: -3px;
  }
`;

const EditIcon = styled.span`
  position: absolute;
  bottom: 5px;
  left: 10px;
  pointer-events: none;
`;
