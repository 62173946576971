import { createSlice } from '@reduxjs/toolkit';
import { setJwtReducer } from './setJwtReducer';
import { IAuthState } from './IAuthState';
import { configure } from '../config/configSlice';
import { applyConfigureToAuthReducer } from './applyConfigureToAuthReducer';

export function createInitialAuthState(): IAuthState {
  return {
    jwt: null,
    userId: null,
  };
}

export const authSlice = createSlice({
  initialState: Object.freeze(createInitialAuthState()),
  name: 'auth',
  reducers: {
    setJwt: setJwtReducer,
  },
  extraReducers: (builder) => builder.addCase(configure, applyConfigureToAuthReducer),
});

export const { setJwt } = authSlice.actions;
