import * as Sentry from '@sentry/react';
import { AppThunk } from '../../redux/store';
import { toast } from 'react-hot-toast';
import { noop } from '../../lib/noop';
import { getSaveDesignProjectData } from './getSaveDesignProjectData';
import { getSaveEndpoint } from './getSaveEndpoint';
import { getDesignerMode } from '../view/viewSelectors';
import { getProjectSlice } from './projectSelectors';
import i18n from 'i18next';
import { isSavePointDifferent } from '../history/historySelectors';

/**
 * Saves the designs of all products.
 *
 * @param skipIfUnchanged if true, will not save if the project did not change since the last save.
 *                        Defaults to `false` so without explicitly setting this to `true` it will
 *                        always save the project.
 */
export const saveProject =
  (skipIfUnchanged = false): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const project = getProjectSlice(getState());
    if (!project || project.isReadonly) {
      return false;
    }
    if (skipIfUnchanged && !isSavePointDifferent(getState())) {
      return true;
    }
    const data = await dispatch(getSaveDesignProjectData());
    if (!data) {
      return false;
    }
    const endpoint = getSaveEndpoint(getDesignerMode(getState()));
    const rpc = dispatch(
      endpoint.initiate({ projectId: project.id, data }, { fixedCacheKey: 'saveDesign' }),
    ).unwrap();

    return toast
      .promise(rpc, {
        error: i18n.t('SaveDesignError'),
        loading: i18n.t('SaveDesignLoading'),
        success: i18n.t('SaveDesignSuccess'),
      })
      .then(() => {
        return true;
      })
      .catch((e) => {
        Sentry.captureEvent({
          message: 'Cover Design could not be saved',
          extra: {
            error: e,
            ProjectId: project.id,
            UserId: getState().auth.userId,
          },
          level: 'error',
        });
        return false;
      });
  };
