import { AppThunk } from '../../redux/store';
import { ProjectCompat } from './compat/ProjectCompat';
import { IDesignProject } from './IDesignProject';
import { setProject } from './projectActions';
import { getLanguageCode } from '../../locale/localeSelectors';

export const openProject =
  (designProject: IDesignProject, activeProduct?: string | null): AppThunk =>
  (dispatch, getState) => {
    const language = getLanguageCode(getState());
    const project = ProjectCompat.toState(designProject, language);
    if (activeProduct && project.products.some(($) => $.id === activeProduct)) {
      project.activeProduct = activeProduct;
    }
    dispatch(setProject(project));
  };
