import { ILayer } from './LayersState';

export interface ILayerFilter {
  /**
   * Only matches a layer with identical id.
   *
   * Highest priority filter, will reduce the set to at most one entry.
   */
  id?: string;

  /**
   * Matches all layers which are selected.
   *
   * Can be combined with {@link id}.
   */
  selected?: boolean;

  /**
   * Customized filter function to reduce the result set even further.
   *
   * Can be combined with the other filters.
   */
  filterFn?: ILayerFilterFn;

  /**
   * If `true`, returns all elements not matching the criteria.
   *
   * Default: `false`
   */
  not?: boolean;
}

export type ILayerFilterFn = (layer: ILayer) => boolean;

/**
 * Filters the given layers array based on the given constraints and returns a new array with
 * the matching results.
 */
export function filterLayers(layers: ILayer[], constraints: ILayerFilter): ILayer[] {
  const hasIdConstraint = typeof constraints.id === 'string';
  const hasSelectedConstraint = typeof constraints.selected === 'boolean';
  const hasFilterFnConstraint = typeof constraints.filterFn === 'function';

  if (!hasIdConstraint && !hasSelectedConstraint && !hasFilterFnConstraint) {
    return [];
  }
  const isNot = constraints.not;

  return layers.filter((layer) => {
    if (hasIdConstraint && layer.id !== constraints.id) {
      return isNot; // only include this entry if the filter said `id does **not** match`
    }
    if (hasSelectedConstraint && layer.isSelected !== constraints.selected) {
      return isNot; // only include this entry if the filter said `is **not** selected`
    }
    if (hasFilterFnConstraint && !constraints.filterFn!(layer)) {
      return isNot; // only include this entry if the filterFn result is **not** satisfied
    }
    return !isNot;
  });
}
