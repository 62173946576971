import { Draft } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { v4 } from 'uuid';

export function clearProjectReducer(state: Draft<IProjectState>) {
  if (!state) {
    return;
  }
  state.products.forEach((product) => {
    product.sheets = [
      {
        id: product.sheets[0]?.id || v4(),
        layers: [],
      },
    ];
  });
}
