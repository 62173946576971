import { FC } from 'react';
import { ColorPicker, IColorPickerProps } from './ColorPicker';
import { Modal } from '../../modal/Modal';
import { ITreditionPalette } from './TreditionPalette';
import { IColor } from './IColor';
import { useTranslation } from 'react-i18next';

export interface IColorPickerModal {
  onPickColor: IColorPickerProps['onPick'];

  onCancel: () => void;

  initialColor?: IColor | null;

  palette?: ITreditionPalette | null;
}

export const ColorPickerModal: FC<IColorPickerModal> = ({
  onPickColor,
  onCancel,
  initialColor,
  palette,
}) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('SelectColor')} isOpen onRequestClose={onCancel}>
      <ColorPicker
        onPick={onPickColor}
        onCancel={onCancel}
        initialColor={initialColor}
        palette={palette}
      />
    </Modal>
  );
};
