import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { ILiveSheetState } from './ILiveSheetState';

export interface IMoveLayersActionPayload {
  /**
   * Translation on the x-axis. May be omitted to only translate on y.
   */
  x?: number;

  /**
   * Translation on the y-axis. May be omitted to only translate on x.
   */
  y?: number;

  /**
   * Whether translation is relative or not. A relative translation will add the values instead of overwriting them.
   */
  isRelative: boolean;
}

export function moveLayersReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IMoveLayersActionPayload>,
) {
  const hasX = typeof action.payload.x === 'number';
  const hasY = typeof action.payload.y === 'number';

  state.layers.forEach((layer) => {
    if (!layer.isSelected) {
      return;
    }
    if (action.payload.isRelative) {
      if (hasX) {
        layer.x += action.payload.x!;
      }
      if (hasY) {
        layer.y += action.payload.y!;
      }
    } else {
      if (hasX) {
        layer.x = action.payload.x!;
      }
      if (hasY) {
        layer.y = action.payload.y!;
      }
    }
    layer.display.x = layer.x;
    layer.display.y = layer.y;
    layer.updatedAt = Date.now();
  });
}
