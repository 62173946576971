import { FC, Fragment, PointerEvent as ReactPointerEvent, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Sheet } from '../sheet/Sheet';
import { IProductMeasures } from '../project/ProductState';
import { useAppDispatch } from '../../redux/hooks';
import { WorkSpaceSelection } from './WorkSpaceSelection';
import { handleWorkSpacePointerMove } from './handleWorkSpacePointerMove';
import { handleWorkSpacePointerUp } from './handleWorkSpacePointerUp';
import { WorkSpaceDropzone } from './WorkSpaceDropzone';
import './WorkSpace.css';
import styled from 'styled-components/macro';
import { ILayer } from '../layer/LayersState';
import { handleWorkSpacePointerDown } from './handleWorkSpacePointerDown';

export interface IWorkSpaceProps {
  /**
   * The sheet id contained by this work space
   */
  sheetId: string;

  /**
   * The layers which will be rendered in this work space
   */
  layers: ILayer[];

  /**
   * Whether this work space is currently considered active
   */
  isActive: boolean;

  /**
   * The index of the sheet contained by this work space
   */
  index: number;

  /**
   * Decides between single sheet or multi sheet view
   */
  hasMultipleSheets: boolean;

  /**
   * Measures of the product owning the sheet
   */
  measures: IProductMeasures;

  /**
   * Current zoom of the designer
   */
  zoom: number;
}

// required for external event handling outside of React components
export const workSpaceClassName = 'work-space';

export const WorkSpace: FC<IWorkSpaceProps> = ({
  sheetId,
  layers,
  isActive,
  index,
  hasMultipleSheets,
  measures,
  zoom,
}) => {
  const dispatch = useAppDispatch();

  const onPointerDown = useCallback(
    (e: ReactPointerEvent<HTMLDivElement>) => {
      dispatch(handleWorkSpacePointerDown(sheetId, e.nativeEvent));
    },
    [sheetId, dispatch],
  );

  useEffect(() => {
    if (!isActive) {
      return;
    }

    function onMove(e: PointerEvent) {
      dispatch(handleWorkSpacePointerMove(e));
    }

    function onUp(e: PointerEvent) {
      dispatch(handleWorkSpacePointerUp(e));
    }

    // remember, this must be registered on the document because the user may expand the selection further than
    // the work space container actually spans
    document.addEventListener('pointermove', onMove);
    document.addEventListener('pointerup', onUp);
    return () => {
      document.removeEventListener('pointermove', onMove);
      document.removeEventListener('pointerup', onUp);
    };
  }, [dispatch, isActive]);

  return (
    <Wrapper
      zoom={zoom}
      prodHeight={measures.height}
      prodWidth={measures.width}
      isActive={isActive}
      isSingle={!hasMultipleSheets}
      className={classNames(workSpaceClassName, { active: isActive, single: !hasMultipleSheets })}
      onPointerDown={onPointerDown}>
      <Sheet index={index} isActive={isActive} layers={layers} />
      {isActive && (
        <Fragment>
          <WorkSpaceSelection />
          <WorkSpaceDropzone />
        </Fragment>
      )}
      {!isActive && <WorkspaceOverlay />}
    </Wrapper>
  );
};

interface IWrapper {
  isActive: boolean;
  isSingle: boolean;
  prodHeight: number;
  prodWidth: number;
  zoom: number;
}

const Wrapper = styled.div.attrs<IWrapper>((props) => ({
  style: {
    width: `${Math.floor(props.prodWidth * props.zoom) + 240}px`,
    height: `${Math.floor(props.prodHeight * props.zoom) + 240}px`,
    background: props.isActive ? '#f2f2f2' : '#cccccc',
    outline: props.isActive && !props.isSingle ? '2px solid #8ce699' : undefined,
  },
}))<IWrapper>`
  position: relative;
  min-width: 100%;
  min-height: calc(100vh - 177px);
  outline-offset: -2px;

  &:not(.active):hover {
    background: #cfcfcf;
  }
`;

const WorkspaceOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
