import { createSlice } from '@reduxjs/toolkit';
import { IApp } from './IApp';

export const initialAppState = Object.freeze<IApp>({ error: null });

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setAppError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setAppError } = appSlice.actions;
