import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { ILayer } from '../layer/LayersState';
import { lookupActiveProductReducer } from './lookupActiveProductReducer';

export interface IPersistLiveSheetActionPayload {
  /**
   * Layers of the front buffer
   */
  layers: ILayer[];
}

export function persistLiveSheetReducer(
  state: Draft<IProjectState>,
  action: PayloadAction<IPersistLiveSheetActionPayload>,
) {
  const product = lookupActiveProductReducer(state);
  if (!product) {
    return;
  }
  const targetSheet = product.sheets.find(($) => $.id === product.activeSheetId);
  if (!targetSheet) {
    return;
  }
  targetSheet.layers = action.payload.layers;
}
