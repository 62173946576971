export enum ViewEnumeration {
  CENTERED = 'centered',
  TWO_COLUMNS = 'two-columns',
}

// label should be a translation key
export const ViewOptions = [
  {
    value: ViewEnumeration.TWO_COLUMNS,
    label: 'OBSViewCenter',
  },
  {
    value: ViewEnumeration.CENTERED,
    label: 'OBSViewTwoColumns',
  },
];
