import { EditorStepKey } from './EditorStepKey';
import { getEditorRoute } from './getEditorRoute';

/**
 * Maps project editor steps to their respective page component types
 */
export const SegmentByStepKeyMapping: Record<EditorStepKey, string> = {
  [EditorStepKey.Category]: 'genre',
  [EditorStepKey.ProjectData]: 'projectdata',
  [EditorStepKey.Format]: 'format',
  [EditorStepKey.Contributors]: 'projectcontributor',
  [EditorStepKey.Isbn]: 'isbn',
  [EditorStepKey.PrepareContentFile]: 'innercheck',
  [EditorStepKey.UploadContentFile]: 'innerupload',
  [EditorStepKey.ViewContentFile]: 'innerresult',
  [EditorStepKey.ColorPages]: 'colorpages',
  [EditorStepKey.Material]: 'material',
  [EditorStepKey.CoverTool]: 'coverbasics',
  [EditorStepKey.PrepareCoverFile]: 'covercheck',
  [EditorStepKey.CoverDesigner]: 'coverdesigner',
  [EditorStepKey.UploadCoverFile]: 'coverupload',
  [EditorStepKey.ViewCoverFile]: 'coverresult',
  [EditorStepKey.Provision]: 'provision',
  [EditorStepKey.ProvisionContribution]: 'provisioncontribution',
  [EditorStepKey.SearchAndFind]: 'searchandfind',
  [EditorStepKey.BookInterview]: 'bookinterview',
  [EditorStepKey.AuthorProfile]: 'authorportrait',
  [EditorStepKey.ShopView]: 'shopview',
};

export const defaultSegment = SegmentByStepKeyMapping[EditorStepKey.Category];

export function getEditorRouteByStep(step: EditorStepKey, projectId: string) {
  return getEditorRoute(SegmentByStepKeyMapping[step] || defaultSegment, projectId);
}
