import { FC } from 'react';
import { useSelection } from '../view/viewSelectors';
import './WorkSpaceSelection.css';

export const WorkSpaceSelection: FC = () => {
  const selection = useSelection();
  if (!selection.start || !selection.end) {
    return null;
  }
  return (
    <div
      className="selection"
      style={{
        left: `${Math.min(selection.start.x, selection.end.x)}px`,
        top: `${Math.min(selection.start.y, selection.end.y)}px`,
        width: `${Math.abs(selection.end.x - selection.start.x)}px`,
        height: `${Math.abs(selection.end.y - selection.start.y)}px`,
      }}
    />
  );
};
