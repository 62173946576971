export enum ContentEnumeration {
  NO_CONTENT = 'no-content',
  PAGE_NUMBER = 'page-number',
  CHAPTER = 'chapter',
  FREE_TEXT = 'free-text',
}

export const ContentOptions = [
  {
    value: ContentEnumeration.NO_CONTENT,
    label: 'OBSContentTypeNone',
  },
  {
    value: ContentEnumeration.PAGE_NUMBER,
    label: 'OBSContentTypePageNumber',
  },
  {
    value: ContentEnumeration.CHAPTER,
    label: 'OBSContentTypeChapter',
  },
  {
    value: ContentEnumeration.FREE_TEXT,
    label: 'OBSContentTypeFreeText',
  },
];
