import { Plugin } from "@ckeditor/ckeditor5-core";
import { TRANSPARENT_IN_HSLA } from "./CustomFontColorEditing";

export class CustomFontBackgroundColorEditing extends Plugin {
  currentColor = TRANSPARENT_IN_HSLA;

  init() {
    this.editor.ui.componentFactory.add(
      "customFontBackgroundColorEditing",
      () => {
        const editor = this.editor;
        const t = editor.t;

        const command = editor.commands.get("fontBackgroundColor");

        const getValueOrDefault = (value) => value || TRANSPARENT_IN_HSLA;

        // Use original fontFamily button - we only changes its behavior.
        const dropdownView = editor.ui.componentFactory.create(
          "fontBackgroundColor"
        );

        // Show label on dropdown's button.
        dropdownView.buttonView.set("withText", true);

        const eventOptions =
          editor.config.get("fontBackgroundColor.eventOptions") || {};

        if (eventOptions && eventOptions.emitEventOnDialogOpen) {
          const eventName =
            eventOptions.eventName || "ckeditor:custom:openDialog";
          dropdownView.on("change:isOpen", (evt, name, isVisible) => {
            // prevent dialog to open up
            if (eventOptions.preventDialogOpening) {
              dropdownView.isOpen = false;
            }
            if (isVisible) {
              const event = new CustomEvent(eventName, {
                detail: {
                  component: "fontBackgroundColor",
                  color: this.currentColor,
                },
              });
              document.dispatchEvent(event);
            }
          });
        }

        // Bind dropdown's button label to fontFamily value.
        dropdownView.buttonView
          .bind("labelStyle")
          .to(command, "value", (value) => {
            // If no value is set on the command show 'Default' text.
            // Use t() method to make that string translatable.
            return `background-color:${getValueOrDefault(value)} !important`;
          });

        // Bind dropdown's button label to fontFamily value.
        dropdownView.buttonView.bind("label").to(command, "value", (value) => {
          // If no value is set on the command show 'Default' text.
          // Use t() method to make that string translatable.
          const color = getValueOrDefault(value);
          this.currentColor = color;
          return color;
        });

        return dropdownView;
      }
    );
  }
}
