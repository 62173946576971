import { IPoint } from '../../../@types/IPoint';
import { ILayer } from './LayersState';
import { ISize } from '../../../@types/ISize';
import { BoundingBoxAnchor } from './BoundingBoxAnchor';

export type ITransformUnion = ITranslateTransform | IScaleTransform | IRotateTransform;

export enum TransformationType {
  Rotate,
  Scale,
  Translate,
}

/**
 * Live-Sheet state slice
 */
export interface ILiveSheetState {
  /**
   * All layers currently existing in the designer, ordered by depth.
   */
  layers: ILayer[];

  /**
   * Whether the canvas currently subject to a transform operation
   */
  transform: ITransformUnion | null;
}

export interface ITransform {
  type: TransformationType;

  /**
   * Id of layer which was grabbed
   */
  grabbedLayerId: string;

  /**
   * Exact working area mouse position where the transform was initiated at
   */
  startPoint: IPoint;

  /**
   * Last working area mouse position during the transform
   */
  endPoint: IPoint;
}

export interface ITranslateTransform extends ITransform {
  type: TransformationType.Translate;

  /**
   * Rulers used for snapping during translation
   */
  rulers: IRulers;

  /**
   * Grid size for snapping during translation. To disable grid snapping, provide a size of 1x1 or less
   */
  grid: ISize;
}

/**
 * Note that the scale transform only affects the grabbed layer.
 *
 * Scaling multiple selected layers by handle does not make sense as it would produce different results based
 * on their rotation. It would involve very complicated math to translate the scale from one layer to another to
 * make it look consistent.
 */
export interface IScaleTransform extends ITransform {
  type: TransformationType.Scale;

  /**
   * The anchor along which the layer will be scaled
   */
  anchor: BoundingBoxAnchor;
}

export interface IRotateTransform extends ITransform {
  type: TransformationType.Rotate;

  /**
   * Center point of the layer which was grabbed for rotation
   */
  centerPoint: IPoint;
}

/**
 * Represents snapping rulers when translating or (TODO) scaling a layer
 */
export interface IRulers {
  h: IRuler[];
  v: IRuler[];
}

export interface IRuler {
  start: IPoint;
  end: IPoint;
}
