import { Draft } from '@reduxjs/toolkit';
import { commitDisplay } from './commitDisplay';
import { ILiveSheetState } from './ILiveSheetState';

export function releaseLayersReducer(state: Draft<ILiveSheetState>) {
  state.layers.forEach((layer) => {
    layer.isGrabbed = false;
    commitDisplay(layer);
  });
  state.transform = null;
}
