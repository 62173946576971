import { IPoint } from '../../../../@types/IPoint';
import { ISize } from '../../../../@types/ISize';
import { clamp } from '../../../lib/clamp';
import { IProduct, RegionId } from '../../project/ProductState';

/**
 * Boundary used to constrain the position of the barcode
 */
export interface IBarcodeBoundary {
  min: IPoint;
  max: IPoint;
}

export interface IBarcodeExtensionState {
  /**
   * Snapshot of the pointer position as moving is initiated
   */
  pointerStart: IPoint;

  /**
   * Snapshot of the position as moving is initiated
   */
  positionStart: IPoint;

  /**
   * @see IBarcodeBoundary
   */
  boundary: IBarcodeBoundary | null;

  /**
   * Current position of the barcode
   */
  position: IPoint;

  /**
   * Size of the barcode
   */
  size: ISize;

  /**
   * Whether the barcode is currently being moved by the user
   */
  isMoving: boolean;
}

export enum BarcodeExtensionActionType {
  Move,
  StartMoving,
  StopMoving,
  CreateBoundary,
}

/**
 * Initiates moving the barcode
 */
export interface IStartMovingAction {
  type: BarcodeExtensionActionType.StartMoving;

  /**
   * Current position of the pointer
   */
  pointerPos: IPoint;
}

/**
 * Stops moving the barcode
 */
export interface IStopMovingAction {
  type: BarcodeExtensionActionType.StopMoving;
}

/**
 * Moves the barcode based on the position delta of the pointer. Ignored if called before
 * dispatching the `StartMoving` action.
 */
export interface IMoveAction {
  type: BarcodeExtensionActionType.Move;

  /**
   * The current position of the pointer
   */
  pointerPos: IPoint;
}

/**
 * Creates a boundary based on the given product
 */
export interface ICreateBoundaryAction {
  type: BarcodeExtensionActionType.CreateBoundary;
  product: IProduct | null;
}

export type IBarcodeExtensionAction =
  | IStartMovingAction
  | IStopMovingAction
  | IMoveAction
  | ICreateBoundaryAction;

function createBoundary(size: ISize, product: IProduct | null): IBarcodeBoundary | null {
  if (!product) {
    return null;
  }
  const r = product.regions.find(($) => $.id === RegionId.CoverBack);
  if (!r) {
    return null;
  }
  const boundary: IBarcodeBoundary = {
    min: { x: r.x + r.bleedLeft, y: r.y + r.bleedTop },
    max: {
      x: r.x + r.width - size.width - r.bleedRight,
      y: r.y + (r.height - r.bleedBottom) - size.height,
    },
  };
  // joint is contained in the cover back region so its width must be subtracted separately
  const jointBack = product.regions.find(($) => $.id === RegionId.JointBack);
  if (jointBack) {
    boundary.max.x -= jointBack.width;
  }
  return boundary;
}

function getConstrainedPosition(
  position: IPoint,
  size: ISize,
  boundary: IBarcodeBoundary | null,
): IPoint {
  const pos = { ...position };
  if (!boundary) {
    return pos;
  }
  pos.x = clamp(pos.x, boundary.min.x, boundary.max.x);
  pos.y = clamp(pos.y, boundary.min.y, boundary.max.y);
  return pos;
}

export function barcodeExtensionReducer(
  state: IBarcodeExtensionState,
  action: IBarcodeExtensionAction,
): IBarcodeExtensionState {
  switch (action.type) {
    case BarcodeExtensionActionType.Move: {
      if (!state.isMoving) {
        return state;
      }
      const newPosition = {
        x: state.positionStart.x + (action.pointerPos.x - state.pointerStart.x),
        y: state.positionStart.y + (action.pointerPos.y - state.pointerStart.y),
      };
      return {
        ...state,
        position: getConstrainedPosition(newPosition, state.size, state.boundary),
      };
    }
    case BarcodeExtensionActionType.StartMoving:
      return {
        ...state,
        isMoving: true,
        pointerStart: action.pointerPos,
        positionStart: state.position,
      };
    case BarcodeExtensionActionType.StopMoving:
      if (!state.isMoving) {
        return state;
      }
      return { ...state, isMoving: false };
    case BarcodeExtensionActionType.CreateBoundary: {
      const boundary = createBoundary(state.size, action.product);
      return {
        ...state,
        boundary: boundary,
        position: boundary
          ? getConstrainedPosition(state.position, state.size, state.boundary)
          : state.positionStart,
      };
    }
    default:
      return state;
  }
}

export function barcodeExtensionInitializer(product?: IProduct | null): IBarcodeExtensionState {
  const state: IBarcodeExtensionState = {
    pointerStart: { x: 0, y: 0 },
    positionStart: { x: 0, y: 0 },
    position: { x: 0, y: 0 },
    isMoving: false,
    boundary: null,
    size: { width: 0, height: 0 },
  };
  if (product && product.barcode) {
    state.position = {
      x: product.barcode.x,
      y: product.barcode.y,
    };
    state.size = {
      width: product.barcode.width,
      height: product.barcode.height,
    };
  }
  return state;
}
