import { FC, Fragment } from 'react';
import { IRulers } from '../layer/ILiveSheetState';
import { px } from '../../lib/px';

export interface ISnapLinesProps {
  snap: IRulers;
}

export const SnapLines: FC<ISnapLinesProps> = ({ snap }) => {
  return (
    <Fragment>
      {snap.h.map(($, i) => (
        <div
          key={i}
          className="snap-line horizontal"
          style={{
            left: px($.start.x),
            top: px($.start.y),
            width: px($.end.x - $.start.x),
            height: '1px',
          }}
        />
      ))}
      {snap.v.map(($, i) => (
        <div
          key={i}
          className="snap-line vertical"
          style={{
            left: px($.start.x),
            top: px($.start.y),
            width: '1px',
            height: px($.end.y - $.start.y),
          }}
        />
      ))}
    </Fragment>
  );
};
