export enum TitleFromApi {
  None = 'None',
  ProfDr = 'ProfDr',
  Dr = 'Dr',
  Prof = 'Prof',
}

export enum ContributorRole {
  Author = 'Author',
  ComicIllustrator = 'ComicIllustrator',
  CoverDesign = 'CoverDesign',
  Photographer = 'Photographer',
  FreeText = 'FreeText',
  Publisher = 'Publisher',
  Illustrator = 'Illustrator',
  Composer = 'Composer',
  Lector = 'Lector',
  TypeSetting = 'TypeSetting',
  Translator = 'Translator',
}

export interface IContributor {
  role: ContributorRole;
  firstName: string;
  lastName: string;
  title: TitleFromApi;
  website: string;
  isPerson: boolean;
  companyName: string;
  /**
   * Additional description for the contributor role
   * currently only used for the role FreeText
   */
  additionalDescription: string;
}
