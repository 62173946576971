import { FC } from 'react';
import styled from 'styled-components/macro';
import { IDesignTemplateSearchResult } from './IDesignerTemplateState';
import { Icon } from '../../icons/Icon';

export interface ITemplatePreviewProps {
  template: IDesignTemplateSearchResult;
  onClickClose: () => unknown;
}

export const TemplatePreview: FC<ITemplatePreviewProps> = ({ template, onClickClose }) => {
  if (!template.thumb) {
    return null;
  }
  return (
    <Wrapper>
      <CloseButton onClick={onClickClose}>
        <Icon name="schliessen-control" />
      </CloseButton>
      {
        // NOTE: this relies on the fact that the given thumbs are not actually thumbs. this mirrors the production state
        <img src={template.thumb} alt={template.name} />
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
  min-width: 800px;
  max-width: 800px;

  & > img {
    width: 100%;
    height: auto;
  }
`;

const CloseButton = styled.button`
  background-color: black;
  color: white;
  margin-top: 0.25rem;
  padding: 0.25rem;
  position: fixed;
  right: 2.25rem;
  transition: color 0.2s ease-in-out;

  :hover {
    color: black;
  }

  & > svg {
    width: 1rem;
  }
`;
