import { useCallback, useMemo, useReducer } from 'react';
import { ColorPickerModal } from './ColorPickerModal';
import { ColorPicker, OnPickFn } from './ColorPicker';
import { ITreditionPalette } from './TreditionPalette';
import { IColor } from './IColor';

export enum ColorPickerModalActionType {
  Open,
  Close,
}

/**
 * @see IColorPickerModalState
 */
export interface IOpenAction {
  type: ColorPickerModalActionType.Open;

  onPick: OnPickFn;

  initialColor?: IColor | null;

  palette?: ITreditionPalette | null;
}

export interface ICloseAction {
  type: ColorPickerModalActionType.Close;
}

export type IColorPickerModalActionUnion = IOpenAction | ICloseAction;

export interface IColorPickerModalState {
  /**
   * The function that will be called when the user picks a color
   */
  onPick: OnPickFn;

  /**
   * Whether the modal is currently shown
   */
  isOpen: boolean;

  /**
   * The initial color of the color picker
   */
  initialColor: IColor | null;

  /**
   * The palette displayed by the color picker
   */
  palette: ITreditionPalette | null;
}

export function colorPickerModalInitializer(): IColorPickerModalState {
  return {
    initialColor: null,
    palette: null,
    onPick: () => void 0,
    isOpen: false,
  };
}

export function colorPickerModalReducer(
  state: IColorPickerModalState,
  action: IColorPickerModalActionUnion,
): IColorPickerModalState {
  switch (action.type) {
    case ColorPickerModalActionType.Open:
      return {
        onPick: action.onPick,
        isOpen: true,
        palette: action.palette || null,
        initialColor: action.initialColor || null,
      };
    case ColorPickerModalActionType.Close:
      return colorPickerModalInitializer();
    default:
      return state;
  }
}

export interface IOpenColorPickerOptions {
  /**
   * Invoked when the user submitted a color to pick
   */
  onPick: OnPickFn;

  /**
   * The initial color to set as the color picker modal opens
   */
  initialColor?: IColor | null;

  /**
   * The palette to assign as the color picker modal opens
   */
  palette?: ITreditionPalette | null;
}

export const useColorPickerModal = () => {
  const [state, dispatch] = useReducer(colorPickerModalReducer, colorPickerModalInitializer());

  const openColorPicker = useCallback(
    (options: IOpenColorPickerOptions) => {
      dispatch({
        type: ColorPickerModalActionType.Open,
        onPick: options.onPick,
        initialColor: options.initialColor,
        palette: options.palette,
      });
    },
    [dispatch],
  );

  const { onPick, initialColor, palette } = state;
  const onPickColorInternal = useCallback(
    (color: IColor) => {
      // invoke user-defined callback
      onPick(color);
      // close the callback afterwards
      dispatch({ type: ColorPickerModalActionType.Close });
    },
    [onPick, dispatch],
  );

  const onCancelInternal = useCallback(() => {
    dispatch({ type: ColorPickerModalActionType.Close });
  }, [dispatch]);

  const render = useCallback(
    () => (
      <ColorPickerModal
        onPickColor={onPickColorInternal}
        onCancel={onCancelInternal}
        initialColor={initialColor}
        palette={palette}
      />
    ),
    [onPickColorInternal, onCancelInternal, initialColor, palette],
  );

  return useMemo(
    () => ({
      isOpen: state.isOpen,
      open: openColorPicker,
      render,
    }),
    [state.isOpen, openColorPicker, render],
  );
};
