import { Draft } from '@reduxjs/toolkit';
import { IProjectState } from './ProjectState';
import { IProduct } from './ProductState';

export function lookupProductByIdReducer(state: Draft<IProjectState>, id: string): IProduct | null {
  if (!state) {
    return null;
  }
  return state.products.find(($) => $.id === id) || null;
}
