import { AppThunk } from '../../redux/store';
import { CKEditorInstance } from './CKEditorProvider';
import { getLayerById } from '../layer/liveSheetSelectors';
import { ITextLayer, LayerType } from '../layer/LayersState';

export const activateEditorForLayer =
  (editor: CKEditorInstance, layerId: string): AppThunk =>
  (dispatch, getState) => {
    const layer = getLayerById(getState(), layerId);
    if (!layer || layer.type !== LayerType.Text) {
      return;
    }
    editor.setData((layer as ITextLayer).content);
    editor.execute('selectAll');
    editor.editing.view.focus();
  };
