import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { lookupLayerByIdReducer } from './lookupLayerByIdReducer';
import { deselectLayersReducer } from './deselectLayersReducer';
import { ILiveSheetState, ITransformUnion } from './ILiveSheetState';

export interface IGrabLayerActionPayload {
  /**
   * Id of the layer which should be affected by the isGrabbed state change
   */
  id: string;

  /**
   * If false will cause other layers to be deselected before.
   *
   * Default: false
   */
  isAdditive?: boolean;

  /**
   * What kind of transformation the grabbing will achieve
   */
  transform?: ITransformUnion;
}

/**
 * Updates the `isGrabbed` flag of a layer
 */
export function grabLayerReducer(
  state: Draft<ILiveSheetState>,
  action: PayloadAction<IGrabLayerActionPayload>,
) {
  const layer = lookupLayerByIdReducer(state, action.payload.id);
  if (!layer) {
    return;
  }
  if (!layer.isSelected && !action.payload.isAdditive) {
    deselectLayersReducer(state);
  }
  state.layers.forEach(($) => ($.isGrabbed = false));
  state.transform = action.payload.transform || null;
  layer.isGrabbed = true;
  layer.isSelected = true;
}
