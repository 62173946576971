import { IIsbnPageData } from './interfaces/IIsbnPageData';
import { IIsbnFormData } from './IsbnSchema';
import { ProductType } from '../format/constants/ProductType';

const initialValues: IIsbnPageData = {
  products: [],
  hasImprintLabel: null,
  needIsbn: false,
  publisherProfileId: null,
  imprintProfileId: null,
};

export const toFormData = (data?: IIsbnPageData): IIsbnFormData => {
  const ensureData = data || initialValues;

  const productTypeIsbn = Object.values(ProductType)
    .filter((pt) => pt !== ProductType.None)
    .reduce((acc, productType) => {
      const product = ensureData.products.find((p) => p.bookType === productType);

      return {
        ...acc,
        [productType]: data?.needIsbn === false ? product?.isbn || '' : '',
      };
    }, {} as IIsbnFormData['customIsbn']);

  return {
    needIsbn: ensureData.needIsbn,
    customIsbn: productTypeIsbn,
    hasImprintLabel: ensureData.hasImprintLabel,
    imprintProfileId: ensureData.imprintProfileId || null,
    publisherProfileId: ensureData.publisherProfileId || null,
  };
};
