import { FC, useCallback, useMemo } from 'react';
import { useReplaceDesignImageMutation } from '../../api/treditionApi';
import { Modal } from '../../modal/Modal';
import { ImageCropper, IOnCropData } from '../../crop/ImageCropper';
import { replaceImage as replaceImageAction } from '../layer/liveSheetSlice';
import { toast } from 'react-hot-toast';
import { useAppDispatch } from '../../redux/hooks';
import { noop } from '../../lib/noop';
import { useTranslation } from 'react-i18next';

export interface IImageReplacerProps {
  /**
   * Invoked when the user wishes to close the cropper and abort the image replacement
   */
  onCancel: () => unknown;

  /**
   * Invoked after the image has been replaced successfully be the server and in the layer data
   */
  onImageReplaced: () => unknown;

  /**
   * The file blob which is used by the cropper and during upload
   */
  file: File;

  /**
   * The fle id which is going to be replaced
   */
  fileId: string;

  /**
   * If set, will replace the image only in the specified layer
   */
  layerId?: string;

  /**
   * The project id this file belongs to
   */
  projectId: string;

  /**
   * Aspect of the cropping area. Defaults to 1
   */
  aspect?: number;
}

export const ImageReplacer: FC<IImageReplacerProps> = ({
  onCancel,
  file,
  fileId,
  layerId,
  aspect,
  onImageReplaced,
  projectId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [replaceImage, { isLoading: isReplaceImageUploading }] = useReplaceDesignImageMutation();
  const imageSrc = useMemo(() => URL.createObjectURL(file), [file]);

  const onSubmitCropImage = useCallback(
    (cropData: IOnCropData) => {
      const cropTask = replaceImage({
        area: cropData.area,
        projectId,
        fileId,
        image: file,
      }).unwrap();

      toast
        .promise(cropTask, {
          error: t('ImageUploadError'),
          loading: t('ImageUploadLoading'),
          success: t('ImageUploadSuccess'),
        })
        .then(({ fileId: newFileId }) => {
          dispatch(
            replaceImageAction({
              layerId,
              fileId: layerId ? undefined : fileId, // do not replace all files if a specific layer is given
              newFileId,
            }),
          );
          onImageReplaced();
        })
        .catch(noop);
    },
    [dispatch, projectId, t, file, fileId, layerId, onImageReplaced, replaceImage],
  );

  return (
    <Modal title={t('CropImage')} isOpen onRequestClose={onCancel}>
      <ImageCropper
        onCrop={onSubmitCropImage}
        imageSrc={imageSrc}
        isSubmitDisabled={isReplaceImageUploading}
        initialAspect={aspect}
      />
    </Modal>
  );
};
