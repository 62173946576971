import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";
import uppercaseIcon from "../../icons/tredition-grossschreibung-control.svg";
import AttributeCommand from "@ckeditor/ckeditor5-basic-styles/src/attributecommand";

const UPPERCASE = "uppercase";

class UppercaseUI extends Plugin {
  static get pluginName() {
    return "UppercaseUI";
  }

  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.model.schema.extend("$text", { allowAttributes: UPPERCASE });
    editor.conversion.attributeToElement({
      model: UPPERCASE,
      view: {
        name: "span",
        styles: {
          "text-transform": "uppercase",
        },
      },
    });

    editor.ui.componentFactory.add(UPPERCASE, (locale) => {
      const command = editor.commands.get(UPPERCASE);
      const view = new ButtonView(locale);

      view.set({
        label: t("Uppercase"),
        icon: uppercaseIcon,
        keystroke: "CTRL+E",
        tooltip: true,
        isToggleable: true,
      });

      view.bind("isOn", "isEnabled").to(command, "value", "isEnabled");

      // Callback executed once the button is clicked.
      view.on("execute", () => {
        const selection = editor.model.document.selection;
        const range = selection.getFirstRange();
        const isCapitalised = selection.hasAttribute(UPPERCASE);

        if (!isCapitalised) {
          editor.model.change((writer) => {
            writer.setAttribute(UPPERCASE, true, range);
          });
        } else {
          editor.model.change((writer) => {
            writer.removeAttribute(UPPERCASE, range);
          });
        }
      });
      return view;
    });

    // Create bold command.
    editor.commands.add(UPPERCASE, new AttributeCommand(editor, UPPERCASE));

    // Set the Ctrl+B keystroke.
    editor.keystrokes.set("CTRL+E", UPPERCASE);
  }
}

export default UppercaseUI;
