/**
 * Used by tredition service
 *
 * NOTE: please only use this code when communicating with the legacy tredition service.
 *       The {@link IsoLanguageCode} should be used in all other cases. When using language in
 *       the frontend, convert this code to the iso code and work with that instead.
 */
export enum LegacyLanguageCode {
  DE = 'DE',
  EN = 'EN',
}

/**
 * ISO-639-1 language code
 */
export enum IsoLanguageCode {
  DE = 'de',
  EN = 'en',
}

/**
 * @internal Use {@link isoToLegacyLanguageCode}
 */
export const IsoToLegacyLanguageCodeMapping = {
  [IsoLanguageCode.EN]: LegacyLanguageCode.EN,
  [IsoLanguageCode.DE]: LegacyLanguageCode.DE,
};

/**
 * Converts an ISO-639-1 language code to the legacy language code format the tredition service uses.
 *
 * @throws The iso code is unknown.
 */
export function isoToLegacyLanguageCode(isoCode: IsoLanguageCode): LegacyLanguageCode {
  const legacyCode = IsoToLegacyLanguageCodeMapping[isoCode];
  if (!legacyCode) {
    throw new Error(`Unknown legacy language code: ${legacyCode}`);
  }
  return legacyCode;
}

/**
 * @internal Use {@link legacyLanguageCodeToIso}
 */
export const LegacyLanguageCodeToIsoMapping = {
  [LegacyLanguageCode.EN]: IsoLanguageCode.EN,
  [LegacyLanguageCode.DE]: IsoLanguageCode.DE,
};

/**
 * Converts legacy language code to the ISO-639-1 language code.
 *
 * @throws The legacy language code unknown.
 */
export function legacyLanguageCodeToIso(legacyLanguageCode: LegacyLanguageCode): IsoLanguageCode {
  const isoCode = LegacyLanguageCodeToIsoMapping[legacyLanguageCode];
  if (!isoCode) {
    throw new Error(`Unknown iso language code: ${legacyLanguageCode}`);
  }
  return isoCode;
}
