import React from 'react';
import styles from './IconButton.module.css';
import { Icon, IIconName } from '../../icons/Icon';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import classNames from 'classnames';

export interface IIconButton {
  label: string;
  iconName: IIconName;
  onClick: () => unknown;
  size?: IconButtonSize;
  labelHidden?: boolean;
  labelLeft?: boolean;
  className?: string;
  disabled?: boolean;
}

export enum IconButtonSize {
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export const IconButton = ({
  label,
  iconName,
  onClick,
  disabled = false,
  className = '',
  size = IconButtonSize.large,
  labelHidden,
  labelLeft,
  ...rest
}: IIconButton) => {
  const sizes: { [key in IconButtonSize]: string } = {
    medium: styles.medium,
    large: styles.large,
    xlarge: styles.xlarge,
  };

  return (
    <button
      {...rest}
      onClick={onClick}
      type={'button'}
      className={classNames(styles.wrapper, sizes[size], className, {
        [styles.disabled]: disabled,
      })}
      style={{
        flexDirection: labelLeft ? 'row-reverse' : 'row',
      }}>
      <Icon name={iconName} />
      {labelHidden ? <VisuallyHidden>{label}</VisuallyHidden> : <div>{label}</div>}
    </button>
  );
};
